import request from '@/utils/axios.tool';
//晋升说明列表
export const promoteExplainList = (data) => {
  return request.post(`/roleapi/sundry/promoteExplainList`, data)
}

//修改晋升说明
export const promoteExplain = (data) => {
    return request.post(`/roleapi/sundry/promoteExplain`, data)
  }
  //添加晋升说明
export const addPromoteExplain = (data) => {
    return request.post(`/roleapi/sundry/addPromoteExplain`, data)
  }
    //晋升说明排序
export const setOrderBy = (data) => {
    return request.post(`/roleapi/sundry/setOrderBy`, data)
  }

      //删除
export const delPromoteExplain = (data) => {
  return request.post(`/roleapi/sundry/delPromoteExplain`, data)
}

