<template>
  <div class="add_big_big_box">
    <div class="add_cutomer">
      <div class="add_cutomer_head">
        添加客户
        <i
            class="pointer el-icon-close"
            style="padding: 10px"
            @click="handleClose"
        ></i>
      </div>
      <div class="add_cutomer_box">
        <div class="add_cutomer_box_info">
          <div class="add_cutomer_box_info_tit"><i>*</i> 客户所属事业部：</div>
          <el-select v-model="business_company_id" placeholder="客户所属事业部">
            <el-option
                v-for="item in businessList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
          <i
              class="el-icon-remove-outline pointer"
              style="color: #fff; font-size: 19px; margin-left: 30px"
          ></i>
        </div>
        <div class="add_cutomer_box_head"><i></i>基础类信息</div>
        <div class="add_cutomer_box_info">
          <div class="add_cutomer_box_info_tit"><i>*</i> 公司名称：</div>
          <el-autocomplete
              class="inline-input"
              v-model="company_name"
              :fetch-suggestions="querySearch"
              placeholder="请输入公司名称"
              :trigger-on-focus="false"
              @select="handleSelect"
              @input="changeInput"
              :maxlength="maxlength"
          ></el-autocomplete>
          <!-- <el-input v-model="company_name" placeholder="请输入公司名称"></el-input> -->
        </div>
        <div class="add_cutomer_box_contacts" style="padding-right: 60px">
          <div class="add_cutomer_box_info">
            <div class="add_cutomer_box_info_tit"><i>*</i> 联系人：</div>
            <el-input
                v-model="contact"
                placeholder="请输入联系人名称"
                maxlength="10"
            ></el-input>
            <i
                class="el-icon-remove-outline pointer"
                style="color: #fff; font-size: 19px; margin-left: 30px"
            ></i>
          </div>
          <div class="add_cutomer_box_info">
            <div class="add_cutomer_box_info_tit"><i>*</i> 职位：</div>
            <el-select v-model="position" placeholder="请选择职位">
              <el-option
                  v-for="item in positionList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
            <i
                class="el-icon-remove-outline pointer"
                style="color: #fff; font-size: 19px; margin-left: 30px"
            ></i>
          </div>
          <div class="add_cutomer_box_info">
            <div class="add_cutomer_box_info_tit"><i>*</i>电话：</div>
            <el-input
                v-model="phone"
                placeholder="请输入联系人电话"
                @blur="handlegetProduct"
                maxlength="11"
            ></el-input>
            <i
                class="el-icon-remove-outline pointer"
                style="color: #fff; font-size: 19px; margin-left: 30px"
            ></i>
          </div>
          <div class="add_cutomer_box_info">
            <div class="add_cutomer_box_info_tit"><i>*</i> 公司地址：</div>
            <el-cascader
                v-model="city_name_id"
                :props="props"
                :options="cityList"
                separator=" - "
                :disabled="isChoose_customer == 1"
                placeholder="请选择省市区"
                @change="handleChange($event, 1)"
            >
            </el-cascader>
          </div>
          <div class="add_cutomer_box_info">
            <div class="add_cutomer_box_info_tit"><i></i> 详细地址：</div>
            <el-input
                v-model="company_address"
                placeholder="请输入详细地址"
                :disabled="isChoose_customer == 1"
                @focus="is_map = true"
            ></el-input>
          </div>
          <Bmap v-if="is_map" @closeMap="closeMap"/>
        </div>
        <div class="add_cutomer_box_head" style="margin-top: 40px"><i></i>扩展类信息</div>
        <div class="add_cutomer_box_info">
          <div class="add_cutomer_box_info_tit"><i>*</i> 客户来源：</div>
          <el-select v-model="source" placeholder="请选择客户来源">
            <el-option
                v-for="item in sourceList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="isChoose_customer == 1"
            >
            </el-option>
          </el-select>
        </div>
        <div class="add_cutomer_box_info">
          <div class="add_cutomer_box_info_tit">
            <i style="color: #fff">*</i> 成立时间：
          </div>
          <el-date-picker
              v-model="time"
              type="date"
              clear-icon="sss"
              :picker-options="pickerOptions"
              :disabled="isChoose_customer == 1 && time != ''"
              placeholder="请选择成立时间"
          >
          </el-date-picker>
        </div>
        <div class="add_cutomer_box_info">
          <div class="add_cutomer_box_info_tit">
            <i style="color: #fff">*</i> 注册资本：
          </div>
          <el-input
              v-model="registered_capital"
              :disabled="
              isChoose_customer == 1 && Boolean(choose_val.registered_capital)
            "
              placeholder="请输入注册资本（万元）"
          ></el-input>
        </div>
        <div class="add_cutomer_box_info">
          <div class="add_cutomer_box_info_tit">
            <i style="color: #fff">*</i> 网址：
          </div>
          <el-input
              v-model="website"
              placeholder="请输入网址"
              :disabled="isChoose_customer == 1 && Boolean(choose_val.website)"
          ></el-input>
        </div>
        <div class="add_cutomer_box_info" style="align-items: flex-start">
          <div class="add_cutomer_box_info_tit"><i>*</i> 经营产品：</div>
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 2 }"
              placeholder="经营产品不能超过20个字符"
              :disabled="isChoose_customer == 1"
              v-model="management_product"
          >
          </el-input>
        </div>

        <div class="add_cutomer_box_bottom">
          <div @click="handleClose">取消</div>
          <div @click="handleAdd">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bmap from "./Bmap";
import {
  getPosition,
  getProduct,
  getCity,
  lookupCustomer,
  selectBusinessCompany
} from "@/api/User/customer";
import {
  addPublicCustomer
} from "@/api/User/data";

export default {
  components: {
    Bmap: Bmap,
  },
  data() {
    return {
      business_company_id: '',
      is_map: false, //展开地图
      isChoose_customer: 0, //  0  为新增客户  1 为点击选择客户
      choose_name: "", //点击选择的客户  选择后有值
      company_name: "", //公司名称
      maxlength: 20, //最大字数限制   必须为number
      contact: "", //联系人
      positionList: [],
      position: "", //职位
      phone: "",
      other_contact: [], //其他联系人
      time: "", //成立时间
      management_product: "", //经营产品
      cityList: [], // 地址
      city_name_id: [],
      province_name: "", //省名称
      city_name: "", //市名称
      area_name: "", //区名称
      company_address: "", //公司详细地址
      latitude: 0, //维度
      longitude: 0, //经度
      prooptions: [], //产品列表
      product_name: "", //选择产品名
      businessList: [],
      sourceList: [
        {value: 1, label: "网络查找"},
        {value: 2, label: "阿里资源库"},
        {value: 3, label: "人脉介绍"},
        {value: 4, label: "老客户转介绍"},
        {value: 5, label: "陌生面访"},
        {value: 6, label: "其他"},
      ],
      source: "", //客户来源
      intentionList: [
        {value: 1, label: "A"},
        {value: 2, label: "B"},
        {value: 3, label: "C"},
        {value: 4, label: "D"},
      ],
      intention: "", //单个产品意向
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },

      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          if (node.data) {
            let node_data = node.data;
            getCity({
              type: node.level + 1,
              parent_id: node_data.id,
            }).then((res) => {
              let list = res.data.data;
              list.forEach((item) => {
                item.label = item.name;
                item.value = item.id;
                item.leaf = node.level >= 2;
              });
              resolve(list);
            });
          }
        },
      },
      registered_capital: "", //注册资金
      website: "", //网址
      customer_analysis: "", //客户分析
      choose_val: {},
    };
  },
  created() {
    getPosition().then((res) => {
      if (res.data.code == 200) {
        this.positionList = res.data.data;
      }
    });
    getCity({type: 1}).then((res) => {
      let list = res.data.data;
      list.forEach((item) => {
        item.label = item.name;
        item.value = item.id;
      });
      this.cityList = list;
    });

    //    事业部
    selectBusinessCompany().then(res => {
      if (res.data.code == 200) {
        this.businessList = res.data.data
      }

    })
  },

  methods: {
    handleAdd() {
      //确定新增
      let company_add_time = "";
      if (this.time != "") {
        company_add_time = this.getwaytime(this.time); //成立时间
      } else {
        company_add_time = "";
      }
      let {
        registered_capital,
        website,
        management_product,
        company_name,
        contact,
        phone,
        customer_analysis,
        intention,
        cityList,
        city_name_id,
        company_address,
        product_name,
        position,
        province_name,
        city_name,
        area_name,
        source,
        other_contact,
        longitude,
        latitude,
        business_company_id
      } = this;
      let one_contact = "";
      let onde_position = "";
      let one_phone = "";
      let other_contacts = [];
      if (other_contact.length == 0) {
        //只有一个联系人
        one_contact = contact;
        onde_position = position;
        one_phone = phone;
      } else {
        one_contact = other_contact[0].contact;
        onde_position = other_contact[0].position;
        one_phone = other_contact[0].phone;
        if (contact != "" && position != "" && phone != "") {
          let obj = {
            contacts: contact,
            position_id: position,
            phone,
          };
          other_contacts.push(obj);
        }
      }
      let is_phone = false
      var partten = /^1[3,4,6,9,5,8,7]\d{9}$/;
      if (other_contacts.length > 0) {
        other_contacts.forEach((item) => {
          if (!partten.test(item.phone)) {
            is_phone = true;
            this.$message({
              type: "error",
              message: "电话填写错误，请检查后输入",
            });
            return;
          }
        });
      }

      if (is_phone) {
        return
      }
      let proarr = [];
      // console.log(proarr);
      //产品
      let data = {};

      if (this.isChoose_customer == 1) {
        //选择新增
        data = {
          name: company_name, //公司名称
          contacts: one_contact, //联系人单个
          phone: one_phone, //电话
          position_id: onde_position, //职位id
          province_name,
          city_name,
          area_name,
          province_id: city_name_id[0],
          city_id: city_name_id[1],
          area_id: city_name_id[2],
          address: company_address, //详细地址
          longitude, //经度
          latitude, //维度
          management_product, //客户情况
          customer_source: source, //客户来源
          company_add_time, //成立时间
          products: JSON.stringify(proarr), //产品
          other_contacts: JSON.stringify(other_contacts), //其他联系人
          registered_capital,
          website,
          customer_id: this.choose_val.id,
          business_company_id
        };
      } else {
        data = {
          name: company_name, //公司名称
          contacts: one_contact, //联系人单个
          phone: one_phone, //电话
          position_id: onde_position, //职位id
          province_name,
          city_name,
          area_name,
          province_id: city_name_id[0],
          city_id: city_name_id[1],
          area_id: city_name_id[2],
          address: company_address, //详细地址
          longitude, //经度
          latitude, //维度
          management_product, //客户情况
          customer_source: source, //客户来源
          company_add_time, //成立时间
          products: JSON.stringify(proarr), //产品
          other_contacts: JSON.stringify(other_contacts), //其他联系人
          registered_capital,
          website,
          business_company_id
        };
      }
      addPublicCustomer(data).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.$emit("Close");
        }
      });

    },
    handleOther() {
      let {contact, position, phone, positionList} = this;
      if (contact != "" && position != "" && phone != "") {
        let position_text = "";
        positionList.forEach((item) => {
          if (item.id == position) {
            position_text = item.name;
          }
        });
        this.other_contact = [
          {
            contact,
            phone,
            position,
            position_text,
          },
        ];
        this.contact = "";
        this.position = "";
        this.phone = "";
      }
    },
    handleDelother() {
      this.other_contact = [];
    },


    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
          (date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1) + "-";
      var D =
          (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
      var h =
          (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
          (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
          ":";
      var s =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let strDate = Y + M + D;
      // let strDate = Y + M + D + h + m + '00';
      return strDate;
    },
    handleClose() {
      this.$emit("Close");
    },
    closeMap(i, obj, keyword) {
      //地图
      if (i == 1) {
        this.longitude = obj.lng;
        this.latitude = obj.lat;
        this.company_address = keyword;
      }
      this.is_map = false;
    },
    handlegetProduct() {
      //获取产品列表
      let data = {};
      if (this.other_contact.length > 0) {
        data = {
          name: this.company_name,
          phone: this.other_contact[0].phone,
        };
      } else {
        data = {
          name: this.company_name,
          phone: this.phone,
        };
      }
      if (this.isChoose_customer == 1) {
        return;
      }
      var partten = /^1[3,4,6,9,5,8,7]\d{9}$/;
      if (!partten.test(data.phone)) {
        this.$message({
          type: "error",
          message: "电话填写错误，请检查后输入",
        });
        return;
      }
      getProduct(data).then((res) => {
        if (res.data.code == 200) {
          let list = res.data.data;
          for (let i = 0; i < list.length; i++) {
            list[i].label = list[i].class_name;
            list[i].value = list[i].id;
            list[i].children = list[i].product_name;
            for (let j = 0; j < list[i].product_name.length; j++) {
              list[i].product_name[j].label =
                  list[i].product_name[j].product_name;
              list[i].product_name[j].value = list[i].product_name[j].id;
            }
          }
          this.prooptions = list;
        }
      });
      return
    },
    querySearch(queryString, cb) {
        if(queryString.length>5){
            lookupCustomer({name: queryString}).then((res) => {
                let list = res.data.data;
                list.forEach((item) => {
                    item.value = item.name;
                });
                this.fluterArr = list;
                var restaurants = this.loadAll();
                var results = queryString
                    ? restaurants.filter(this.createFilter(queryString))
                    : restaurants;
                // // 调用 callback 返回建议列表的数据
                cb(results);
            });
        }

    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
            restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
            0
        );
      };
    },
    loadAll() {
      return this.fluterArr;
    },
    handleSelect(val) {
      // console.log(val);
      this.choose_val = val;
      // console.log(this.choose_val);
      // console.log(Boolean(val.registered_capital));

      this.isChoose_customer = 1;
      // console.log(this.isChoose_customer==1 && Boolean(val.website));

      this.choose_name = val.name;
      getProduct({customer_id: val.id}).then((res) => {
        if (res.data.code == 200) {
          let list = res.data.data;
          for (let i = 0; i < list.length; i++) {
            list[i].label = list[i].class_name;
            list[i].value = list[i].id;
            list[i].children = list[i].product_name;
            for (let j = 0; j < list[i].product_name.length; j++) {
              list[i].product_name[j].label =
                  list[i].product_name[j].product_name;
              list[i].product_name[j].value = list[i].product_name[j].id;
            }
          }
          this.prooptions = list;
        }
      });
      //  this.check_contract = item
      this.time = val.company_add_time;
      this.source = val.customer_source * 1;
      this.city_name_id.push(val.province_id);
      this.city_name_id.push(val.city_id);
      this.city_name_id.push(val.area_id);
      this.company_address = val.address;
      this.latitude = val.latitude;
      this.longitude = val.longitude;
      this.province_name = val.province_name;
      this.city_name = val.city_name;
      this.area_name = val.area_name;
      this.registered_capital = val.registered_capital;
      this.management_product = val.management_product;
      this.website = val.website;
      getCity({type: 1}).then((res) => {
        //省
        if (res.data.code == 200) {
          let list = res.data.data;
          list.forEach((item) => {
            item.label = item.name;
            item.value = item.id;
          });
          getCity({
            //市
            type: 2,
            parent_id: val.province_id,
          }).then((ress) => {
            let listt = ress.data.data;
            listt.forEach((item) => {
              item.label = item.name;
              item.value = item.id;
            });
            list.forEach((item) => {
              if (item.id == val.province_id) {
                item.children = listt;
              }
            });
            getCity({
              type: 3,
              parent_id: val.city_id,
            }).then((resss) => {
              let listtt = resss.data.data;
              listtt.forEach((item) => {
                item.label = item.name;
                item.value = item.id;
                item.leaf = true;
              });
              listt.forEach((item) => {
                if (item.id == val.city_id) {
                  item.children = listtt;
                }
              });
              this.cityList = list;
            });
          });
        }
      });
    },
    changeInput(val) {
      if (this.choose_name == val) {
        if (val != "") {
          this.isChoose_customer = 1;
        }
      } else {
        this.time = "";
        this.source = "";
        this.city_name_id = [];
        this.company_address = "";
        this.latitude = "";
        this.longitude = "";
        this.management_product = "";
        this.registered_capital = "";
        this.isChoose_customer = 0;
        this.choose_val = null;

      }
    },
    handleChange(e, i) {
      let {cityList, prooptions} = this;
      if (i == 1) {
        //城市
        cityList.forEach((item) => {
          if (item.id == e[0]) {
            this.province_name = item.name;
          }
        });
        // console.log(e);
        getCity({type: 2, parent_id: e[0]}).then((res) => {
          let list = res.data.data;
          list.forEach((item) => {
            if (item.id == e[1]) {
              this.city_name = item.name;
            }
          });
        });
        getCity({type: 3, parent_id: e[1]}).then((res) => {
          let list = res.data.data;
          list.forEach((item) => {
            if (item.id == e[2]) {
              this.area_name = item.name;
            }
          });
        });
      }
      if (i == 2) {
        let arr = this.prooptions;
        arr.forEach((item) => {
          if (item.id == e[0]) {
            item.children.forEach((itm) => {
              itm.disabled = false;
              if (itm.id == e[1]) {
                itm.disabled = true;
              }
            });
          } else {
            item.children.forEach((itm) => {
              itm.disabled = false;
              if (itm.id == e[1]) {
                itm.disabled = true;
              }
            });
          }
        });
        this.prooptions = [...arr];
      }
    },
  },
};
</script>

<style lang="scss">
.add_big_big_box {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .add_cutomer {
    position: relative;
    z-index: 99;
    // width: 50%;
    width: 650px;
    height: 90%;
    background: #fff;
    margin: auto;
    border-radius: 10px;
    min-width: 650px;
    margin-top: 50px;

    .add_cutomer_head {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.8);
      opacity: 1;
      border: 1px solid rgba(0, 0, 0, 0.2);
      position: relative;

      i {
        position: absolute;
        right: 20px;
      }
    }

    // .add_cutomer_box::-webkit-scrollbar {
    // /*隐藏滚轮*/
    // display: none;
    // }
    .add_cutomer_box {
      box-sizing: border-box;
      padding: 30px;
      height: 90%;
      overflow: auto;

      .add_cutomer_box_head {
        display: flex;
        align-items: center;
        font-size: 16px;

        i {
          width: 6px;
          height: 6px;
          background: #3a85fc;
          border-radius: 50%;
          opacity: 1;
          margin-right: 7px;
        }
      }

      .add_cutomer_box_info {
        /*padding-left: 60px;*/
        padding-right: 70px;
        display: flex;
        align-items: center;
        min-height: 40px;
        margin: 15px 0;

        .add_cutomer_box_info_tit {
          width: 140px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-right: 10px;

          i {
            display: flex;
            height: 10px;
            width: 10px;
            align-items: center;
            justify-content: center;
            color: red;
          }
        }

        .el-autocomplete {
          flex: 1;
        }

        .el-input {
          flex: 1;
          height: 32px;

          input {
            height: 32px;
          }

          .el-input__prefix {
            .el-icon-date {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .el-date-editor {
          flex: 1;
          height: 32px;

          input {
            height: 100%;
            padding-left: 15px;
          }
        }

        .el-select {
          flex: 1;

          .el-input__suffix {
            display: flex;
            align-items: center;
          }
        }

        .el-input__prefix {
          left: initial;
          right: 5px;
        }

        &.date-close {
          .el-icon-date {
            display: none;
          }
        }

        .el-textarea {
          flex: 1;

          .el-textarea__inner {
            //el_input中的隐藏属性
            resize: none; //主要是这个样式
          }
        }

        .el-cascader {
          flex: 1;
        }
      }

      .other_contact {
        flex: 1;
        height: 32px;
        display: flex;
        align-items: center;
        padding-left: 15px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
      }

      .add_cutomer_box_contacts {
        .add_cutomer_box_info {
          padding-right: 21px;
        }
      }

      .add_more_contacts {
        margin: 0 70px 0 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        color: #3A85FC;
        background: #F1F5FF;
        /*border: 1px dashed rgba(0, 0, 0, 0.2);*/
        opacity: 1;
        border-radius: 2px;
      }

      .add_cutomer_box_bottom {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;

        div {
          width: 80px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          opacity: 1;
          cursor: pointer;
        }

        div:first-child {
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
          opacity: 1;
          color: rgba(0, 0, 0, 0.5);
        }

        div:last-child {
          background: #3a85fc;
          opacity: 1;
          color: #fff;
          margin-left: 20px;
        }
      }
    }
  }
}

.add_cutomer_box_info_check {
  height: 32px;
  display: flex;
  align-items: center;

  label {
    display: flex;
    align-items: center;
    margin-right: 110px;

    span:first-child {
      display: flex;
      align-items: center;
    }
  }
}
</style>