<template>
  <div class="launch">
    <!-- 启动页 -->
    <div class="launch_table">
      <el-table
          :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
          :data="tableData"
          style="width: 100%"
          border
          :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column label="名称">
          <template slot-scope="scope">
            {{ scope.row.companys.name }}
          </template>
        </el-table-column>
        <el-table-column label="展示平台" width="180">
          <template slot-scope="scope">
            <div
                v-for="(item, index) in scope.row.show_platform"
                :key="index"
                style="display: flex"
            >
              <span>{{ item == 1 ? "PC端" : "小程序端" }}</span>
            </div>
            <div v-if="scope.row.show_platform.length == 0">--</div>
          </template>
        </el-table-column>
        <el-table-column label="PC端图片">
          <template slot-scope="scope">
            <el-image
                v-if="scope.row.complete_applets_img_url"
                style="width: 100px; height: 100px"
                v-for="(item, index) in scope.row.complete_img_url_arr"
                :key="index"
                :src="item"
                :preview-src-list="scope.row.complete_img_url_arr"
            >
            </el-image>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="小程序端图片">
          <template slot-scope="scope">
            <el-image
                v-if="scope.row.complete_applets_img_url"
                style="width: 100px; height: 100px"
                v-for="(item, index) in scope.row.complete_applets_img_url_arr"
                :key="index"
                :src="item"
                :preview-src-list="scope.row.complete_applets_img_url_arr"
            >
            </el-image>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="展示时间">
          <template slot-scope="scope">
            {{ scope.row.start_time }}--{{ scope.row.end_time }}
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <el-switch
                v-model="scope.row.status"
                active-color="#13ce66"
                inactive-color="#ccc"
                @change="changeswitch($event, scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.created_at }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- <div
              style="color: #3a85fc"
              class="pointer"
              @click="add_launch_page(scope.row, 1)"
            >
              添加
            </div> -->
            <div
                style="color: #3a85fc"
                class="pointer"
                @click="add_launch_page(scope.row, 2)"
                v-if="role_what.is_edit"
            >
              编辑
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="10"
          el-pagination
          layout="total,prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
    <div class="add_launch" v-if="add_launch">
      <div class="add_launch_box">
        <div class="add_launch_box_title">
          添加启动页配置
          <i
              class="el-icon-close pointer"
              style="color: #999999"
              @click="add_launch = false"
          ></i>
        </div>
        <div class="add_launch_box_body">
          <div class="add_box_body_title">
            <i></i>启动页展示信息
            <!-- <span style="color: rgba(0, 0, 0, 0.3); font-weight: normal"
              >（建议上传GIF格式，尺寸120*120）</span
            > -->
          </div>
          <div class="add_box_body_line" style="margin-top: 18px">
            <div class="add_box_body_line_tit">名称：</div>
            {{ row.companys.name }}
          </div>
          <div class="add_box_body_line">
            <div class="add_box_body_line_tit">展示平台：</div>
            <el-select
                v-model="platform_id"
                multiple
                placeholder="请选择展示平台"
            >
              <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="add_box_body_line">
            <div class="add_box_body_line_tit">展示时间：</div>
            <el-date-picker
                v-model="date"
                type="datetimerange"
                range-separator="至"
                prefix-icon="sss"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
          <div class="add_box_body_line">
            <div class="add_box_body_line_tit">状态：</div>
            <el-radio v-model="status_radio" label="1">开</el-radio>
            <el-radio v-model="status_radio" label="2">关</el-radio>
          </div>
          <div class="add_box_body_title">
            <i></i>PC启动页展示信息
            <span style="color: rgba(0, 0, 0, 0.3); font-weight: normal"
            >（建议上传JPG,PNG格式, 尺寸宽1920*1080）</span
            >
          </div>
          <div class="add_box_body_img">
            <div
                class="add_box_body_img_box"
                v-for="(item, index) in complete_img_url_arr"
                :key="index"
            >
              <!-- <img
                :src="item.file_url"
                alt=""
                @click="
                  is_show = true;
                  show_img = item.file_url;
                "
              /> -->
              <el-image
                  style="width: 100px; height: 100px; border-radius: 5px"
                  :src="item"
                  :preview-src-list="complete_img_url_arr"
              >
              </el-image>
              <i
                  class="el-icon-circle-close"
                  style="position: absolute; top: -7px; right: -7px"
                  @click="del_img(index, 1)"
              ></i>
            </div>
            <div
                class="image_info"
                @click="chooseFile(1)"
                v-show="PC_img.length == 0"
            >
              <img src="@/assets/img/upload_img.png" alt=""/>
              <span>上传相关图片</span>
              <input
                  type="file"
                  style="display: none"
                  ref="choosePhoto"
                  id="upload_file"
                  @change="photoChange($event)"
                  v-if="upload_img"
              />
            </div>
          </div>
          <div class="add_box_body_title">
            <i></i>小程序端启动页信息
            <span style="color: rgba(0, 0, 0, 0.3); font-weight: normal"
            >（建议上传图片：JPG,PNG格式 尺寸宽750*1334）</span
            >
          </div>
          <div class="add_box_body_img">
            <div
                class="add_box_body_img_box"
                v-for="(item, index) in complete_applets_img_url_arr"
                :key="index"
            >
              <el-image
                  style="width: 100px; height: 100px; border-radius: 5px"
                  :src="item"
                  :preview-src-list="complete_applets_img_url_arr"
              >
              </el-image>
              <i
                  class="el-icon-circle-close"
                  style="position: absolute; top: -7px; right: -7px"
                  @click="del_img(index, 2)"
              ></i>
            </div>
            <div
                class="image_info"
                @click="chooseFile(2)"
                v-show="Applets_img.length == 0"
            >
              <img src="@/assets/img/upload_img.png" alt=""/>
              <span>上传相关图片</span>
              <input
                  type="file"
                  style="display: none"
                  ref="choosePhoto"
                  id="upload_file"
                  @change="photoChange($event)"
                  v-if="upload_img"
              />
            </div>
          </div>
        </div>
        <div class="add_launch_box_bottom">
          <div @click="add_launch = false">取消</div>
          <div @click="add_popupImg">确定</div>
        </div>
      </div>
    </div>
    <div class="show_img" v-if="is_show" @click="is_show = false">
      <img :src="show_img" alt=""/>
    </div>
  </div>
</template>

<script>
import {uploadFile} from "../../api/User/index";
import {list, edit} from "../../api/User/poster";

export default {
  data() {
    return {
      total: 0,
      currentPage: 0,

      tableData: [],
      options: [
        {
          id: "1",
          name: "PC端",
        },
        {
          id: "2",
          name: "小程序端",
        },
      ],
      platform_id: [],
      value: "",
      add_launch: false,
      show_img: "", //放大图片路径
      is_show: false,

      row: {}, //添加编辑选择的rwo
      add_edit: "", //1 添加 2 编辑
      date: [], //时间
      status_radio: "1",
      chooseFile_what: "", //1 pc  2 小程序
      upload_img: true,
      PC_img: [], //pc图片
      complete_applets_img_url_arr: [], //小程序图片  (放大)
      complete_img_url_arr: [], //pc图片 (放大)
      Applets_img: [], //小程序
      role_what: {
        is_edit: false,
        edit_str: "",
      },
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      let roleList = JSON.parse(localStorage.getItem("token")).role;
      roleList.forEach((item) => {
        if (item.permissions == "Poster_Launch_page_edit") {
          this.role_what.is_edit = true;
          this.role_what.edit_str = item.permissions_id_string;
        }
      });
    }
    this.getList({
      permissions_id_string: this.role_what.edit_str,
    });
  },
  methods: {
    getList(data) {
      list(data).then((res) => {
        if (res.data.code == 200) {
          res.data.data.forEach((item) => {
            if (item.show_platform) {
              item.show_platform = item.show_platform.split(",");
            } else {
              item.show_platform = [];
            }
            if (item.status) {
              item.status = item.status == 1 ? true : false;
            } else {
              item.status = false;
            }
          });
          let list = res.data.data;
          list.forEach((item) => {
            item.complete_applets_img_url_arr = [];
            item.complete_applets_img_url_arr.push(
                item.complete_applets_img_url
            );
            item.complete_img_url_arr = [];
            item.complete_img_url_arr.push(item.complete_img_url);
          });
          this.tableData = list;
        }
      });
    },
    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
          (date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1) + "-";
      var D =
          (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
          (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
          (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
          ":";
      var s =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // let strDate = Y + M + D;
      let strDate = Y + M + D + h + m + "00";
      return strDate;
    },
    handleCurrentChange(val) {
      this.getList({
        page: val,
        permissions_id_string: this.role_what.edit_str,
      });
    },
    chooseFile: function (i) {
      this.chooseFile_what = i;
      this.$refs.choosePhoto.addEventListener('change',this.photoChange);
this.$refs.choosePhoto.dispatchEvent(new MouseEvent("click"));
      this.upload_img = false;
      setTimeout(() => {
        this.upload_img = true;
      }, 10);
    },
    photoChange(el) {
      var file = el.target.files[0]; //name: "dangqi1.png" || type: "image/png"
      var type = "";
      type = file.type.split("/")[0];
      if (type === "image") {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        var that = this;
        reader.onloadend = function () {
          var dataURL = reader.result;
          var blob = that.dataURItoBlob(dataURL);
          that.upload(blob); //执行上传接口
        };
      } else {
        this.$message.error("请上传图片~~");
      }
    },
    dataURItoBlob(dataURI) {
      // base64 解码
      let byteString = window.atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let T = mimeString.split("/")[1];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], {type: mimeString});
    },
    upload(imgUrl) {
      let {chooseFile_what} = this;
      let formData = new FormData();
      this.$message({
        message: "上传中，请稍后",
      });
      formData.append("file", imgUrl);
      uploadFile(formData).then((res) => {
        if (chooseFile_what == "1") {
          this.PC_img.push(res.data.data);
          this.complete_img_url_arr.push(res.data.data.file_url);
        }
        if (chooseFile_what == "2") {
          this.Applets_img.push(res.data.data);
          this.complete_applets_img_url_arr.push(res.data.data.file_url);
        }
        if (res.data.code == 200) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
        }
      });
    },
    del_img(index, i) {
      if (i == 1) {
        //删除pc
        this.PC_img.splice(index, 1);
        this.complete_img_url_arr.splice(index, 1);
      } else {
        //删除小程序
        this.Applets_img.splice(index, 1);
        this.complete_applets_img_url_arr.splice(index, 1);
      }
    },
    changeswitch(e, row) {
      let data = {
        popup_img_id: row.id,
        start_time: row.start_time, //开始时间
        end_time: row.end_time, //结束时间
        status: e ? 1 : 2,
        show_platform: row.show_platform.join(","),
        img_url: row.img_url,
        applets_img_url: row.applets_img_url,
        permissions_id_string: this.role_what.edit_str,
      };
      edit(data).then((res) => {
        if (res) {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.add_launch = false;
            this.getList({
              page: this.currentPage,
              permissions_id_string: this.role_what.edit_str,
            });
          }
        } else {
          this.tableData.forEach((item) => {
            if (item.id == row.id) {
              item.status = !row.status;
            }
          });
        }
      });
    },
    add_launch_page(row, i) {
      //
      this.row = JSON.parse(JSON.stringify(row));
      this.add_launch = true;
      this.add_edit = i;
      this.platform_id = [];
      this.date = [];
      this.status_radio = "1";
      this.PC_img = [];
      this.Applets_img = [];
      if (i == "2") {
        if (row.complete_img_url) {
          this.complete_img_url_arr = JSON.parse(
              JSON.stringify(row.complete_img_url_arr)
          );
          row.complete_img_url.split(",").forEach((item) => {
            let obj = {
              file_url: item,
              url: item.split("com")[1],
            };
            this.PC_img.push(obj);
          });
        }
        if (row.complete_applets_img_url) {
          this.complete_applets_img_url_arr = JSON.parse(
              JSON.stringify(row.complete_applets_img_url_arr)
          );
          row.complete_applets_img_url.split(",").forEach((item) => {
            let objj = {
              file_url: item,
              url: item.split("com")[1],
            };
            this.Applets_img.push(objj);
          });
        }
        this.platform_id = row.show_platform;
        this.date[0] = row.start_time === null ? "" : row.start_time;
        this.date[1] = row.end_time === null ? "" : row.end_time;
        this.status_radio = row.status ? "1" : "2";
      }
    },
    add_popupImg() {
      let {date, status_radio, platform_id, PC_img, Applets_img, row} = this;
      if (date) {
        if (date.length == 0) {
          this.$message({
            message: "请选择时间",
            type: "error",
          });
          return;
        }
      } else {
        this.$message({
          message: "请选择时间",
          type: "error",
        });
        return;
      }
      if (PC_img.length == 0 || Applets_img.length == 0) {
        this.$message({
          message: "图片不能为空",
          type: "error",
        });
        return;
      }
      let data = {
        popup_img_id: row.id,
        start_time: this.getwaytime(date[0]), //开始时间
        end_time: this.getwaytime(date[1]), //结束时间
        status: status_radio,
        show_platform: platform_id.join(","),
        img_url: PC_img[0].url,
        applets_img_url: Applets_img[0].url,
        permissions_id_string: this.role_what.edit_str,
      };
      edit(data).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.add_launch = false;
          this.getList({
            permissions_id_string: this.role_what.edit_str,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.launch {
  margin: 40px 16px;

  .block {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .add_launch {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    .add_launch_box {
      z-index: 99;
      width: 40%;
      height: 75%;
      background: #ffff;
      margin: auto;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
      overflow: hidden;
      min-width: 672px;
      min-height: 650px;

      .add_launch_box_title {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 60px;
        font-size: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        i {
          position: absolute;
          right: 50px;
          padding: 10px;
        }
      }

      .add_launch_box_body {
        height: 75%;
        overflow: auto;
        margin: 16px 20px;

        .add_box_body_title {
          height: 40px;
          background: #fafafa;
          display: flex;
          align-items: center;
          padding: 0 32px;
          font-size: 14px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.8);

          i {
            width: 2px;
            height: 12px;
            background: #3a85fc;
            opacity: 1;
            display: block;
            margin-right: 5px;
          }
        }

        .add_box_body_line {
          height: 32px;
          display: flex;
          align-items: center;
          margin: 0 46px;
          margin-bottom: 18px;

          .el-range-separator {
            line-height: 23px;
          }

          .el-range-editor {
            height: 32px;
            width: 340px;

            input {
              width: 150px;
              text-align: left;
              padding-left: 15px;
            }

            .sss {
              width: 0;
            }

            .el-input__icon {
              display: flex;
              align-items: center;
              height: 100%;
            }
          }

          .add_box_body_line_tit {
            white-space: nowrap;
            width: 70px;
            text-align: end;
          }

          .el-input {
            height: 32px;
            width: 340px;

            input {
              width: 340px;
              height: 32px;
            }

            .el-input__icon {
              line-height: 32px;
            }
          }
        }

        .add_box_body_img {
          margin: 0 46px;
          display: flex;
          flex-wrap: wrap;

          .add_box_body_img_box {
            width: 100px;
            height: 100px;
            opacity: 1;
            border-radius: 8px;
            margin: 10px 0;
            margin-right: 15px;
            position: relative;
            // img {
            //   width: 100px;
            //   height: 100px;
            //   border-radius: 5px;
            // }
            i {
              cursor: pointer;
              color: rgb(204, 204, 204);
            }

            .check_img {
              position: absolute;
              top: 5px;
              right: 5px;
              height: 10px;
              width: 10px;
            }
          }

          .add_box_body_img_box:nth-child(5) {
            margin-right: 0;
          }

          .add_box_body_img_more {
            color: #3a85fc;
            width: 14px;
            font-size: 14px;
            cursor: pointer;
            height: 100px;
            display: flex;
            align-items: center;
            position: absolute;
            right: 46px;
          }

          .image_info {
            width: 100px;
            height: 100px;
            margin: 10px 0;
            position: relative;
            display: flex;
            background: rgba(0, 0, 0, 0.1);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            opacity: 0.6;
            overflow: hidden;
            cursor: pointer;

            img {
              height: 30px;
              width: 30px;
            }

            span {
              margin-top: 10px;
              color: rgba(0, 0, 0, 0.3);
            }
          }
        }
      }

      .add_launch_box_bottom {
        display: flex;
        justify-content: flex-end;
        margin: 0 20px;

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 40px;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
        }

        div:first-child {
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
          color: rgba(0, 0, 0, 0.5);
        }

        div:last-child {
          margin-left: 20px;
          background: #3a85fc;
          color: #fff;
        }
      }
    }
  }

  .show_img {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    img {
      z-index: 99;
      width: 350px;
      background: #ffff;
      margin: auto;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
      overflow: hidden;
    }
  }
}
</style>