<template>
  <div class="history_box" style="flex: 1">
    <el-main>
      <div class="history_box_dep">
        <div v-for="(item, index) in dep_arr" :key="index" :class="[depth_check == item.id ? 'active_dep' : '']" @click="checkdep(item.id)">
          {{ item.label }}
        </div>
      </div>
      <div class="history_box_data">
        <div class="history_box_data_line">
          <div class="time_check pointer">
            <div
              v-for="(item, index) in time_arr"
              @click="change_timecheck(item.id)"
              :class="[check_time_id == item.id ? 'acc_time' : '']"
              :key="index"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="serch_big_box">
            <div class="info">
              <span class="demonstration">部门：</span>
              <el-cascader
                placeholder="请选择部门名称"
                :options="options"
                ref="myCascader"
                @change="handleClick"
                :key="isResouceShow"
                clearable
                :show-all-levels="false"
                collapse-tags
                :props="{ multiple: true, checkStrictly: true }"
              ></el-cascader>
            </div>
            <div class="info">
              <span class="demonstration">年：</span>
              <el-date-picker v-model="year" :clearable="false" type="year" @change="changeyear" placeholder="选择年"> </el-date-picker>
            </div>
            <div class="info" v-if="check_time_id == 2">
              <span class="demonstration">季度：</span>
              <el-select @change="changequarter" v-model="quarter" placeholder="请选择季度">
                <el-option v-for="(item, index) in quarter_list" :key="index" :label="item.label" :value="item.id"> </el-option>
              </el-select>
            </div>
            <div class="info" v-if="check_time_id == 1">
              <span class="demonstration">月：</span>
              <el-select @change="changemonth" v-model="month" placeholder="请选择月份">
                <el-option v-for="(item, index) in month_options" :key="index" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </div>
            <div class="info">
              <span class="demonstration">产品：</span>
              <el-cascader
                placeholder="请选择产品名称"
                :options="product_arr"
                ref="myCascader"
                @change="handleProduct"
                :key="isproductShow"
                clearable
                collapse-tags
                :props="{ multiple: true }"
              ></el-cascader>
            </div>
            <div class="info" v-if="depth_check == 3">
              <span class="demonstration" style="width: 68px">事业线：</span>
              <el-select @change="changbusiness" v-model="business_line_id" placeholder="请选择事业线">
                <el-option v-for="item in business_Line_arr" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </div>
            <div class="info">
              <div class="serch pointer" @click="serch">查询</div>
              <div class="reset pointer" @click="reset">重置</div>
            </div>
          </div>
          <div class="data_echarts" v-if="depth_check < 5">
            <div id="mycharts" class="mycharts"></div>
          </div>
        </div>
        <div class="history_box_data_table">
          <div class="export">
            <div class="all_num" v-if="tableData.length != 0">
              <div>任务总计：{{ all_task + "元" }}</div>
              <div>业绩总计：{{ all_do + "元" }}</div>
              <div>总完成率：{{ all_rate + "%" }}</div>
            </div>
            <div @click="export_list" class="export_bottom">
              <i class="el-icon-printer" style="margin-right: 5px"></i>
              导出
            </div>
          </div>
          <div class="table">
            <el-table
              :data="tableData"
              border
              fixed
              :show-overflow-tooltip="true"
              :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
              style="width: 100%"
              ref="table"
            >
              <el-table-column
                :label="
                  depth_check == 1
                    ? '大区'
                    : depth_check == 2
                    ? '分公司'
                    : depth_check == 3
                    ? '事业部'
                    : depth_check == 4
                    ? '战区'
                    : depth_check == 5
                    ? '军团'
                    : '姓名'
                "
                prop="name"
                :key="1"
                fixed
                width="100"
                :show-overflow-tooltip="true"
              >
                <template scope="scope">
                  <div>
                    {{ scope.row.name }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="领导"
                :key="21"
                width="100"
                fixed
                :show-overflow-tooltip="true"
                v-if="depth_check > 2 && depth_check < 6"
              >
                <template scope="scope">
                  <div>
                    <div>{{ scope.row.leader ? scope.row.leader : "--" }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="任务合计" width="100" fixed :show-overflow-tooltip="true" :key="2">
                <template scope="scope">
                  <div>
                    <div>{{ scope.row.task }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="业绩" width="100" fixed :show-overflow-tooltip="true" :key="3">
                <template scope="scope">
                  <div>
                    {{ scope.row.achievement }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="任务完成率" width="100" fixed :show-overflow-tooltip="true" :key="4">
                <template scope="scope">
                  <div>
                    <div>{{ scope.row.task_rate + "%" }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="所属大区" :key="20" width="100" v-if="depth_check > 1">
                <template scope="scope">
                  <div>
                    {{ scope.row.company_info["1"].name ? scope.row.company_info["1"].name : "--" }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="所属分公司" :key="22" width="100" v-if="depth_check > 2">
                <template scope="scope">
                  <div>
                    {{ scope.row.company_info["2"].name ? scope.row.company_info["2"].name : "--" }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="所属事业部" :key="23" width="100" v-if="depth_check > 3">
                <template scope="scope">
                  <div>
                    {{ scope.row.company_info["3"].name ? scope.row.company_info["3"].name : "--" }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="所属战区" :key="24" width="100" v-if="depth_check > 4">
                <template scope="scope">
                  <div>
                    {{ scope.row.company_info["4"].name ? scope.row.company_info["4"].name : "--" }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="所属军团" :key="28" v-if="depth_check == 6">
                <template scope="scope">
                  <div>
                    {{ scope.row.company_info["5"] ? scope.row.company_info["5"].name : "--" }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="所属事业线" :key="26" width="100" v-if="depth_check > 2">
                <template scope="scope">
                  <div>
                    <div>
                      {{ scope.row.business_line ? scope.row.business_line : "--" }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="分公司数" :key="8" width="100" v-if="depth_check == 1">
                <template scope="scope">
                  <div>
                    {{ scope.row.company_info["2"].count }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="分公司部均业绩" :key="16" width="100" v-if="depth_check == 1">
                <template scope="scope">
                  <div>
                    {{ scope.row.company_info["2"].average }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="事业部数" width="100" :key="9" v-if="depth_check < 3">
                <template scope="scope">
                  <div>
                    {{ scope.row.company_info["3"].count }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="事业部均业绩" :key="17" width="100" v-if="depth_check < 3">
                <template scope="scope">
                  <div>
                    {{ scope.row.company_info["3"].average }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="战区数" width="100" :key="5" v-if="depth_check < 4">
                <template scope="scope">
                  <div>
                    {{ scope.row.company_info["4"].count }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="战区部均业绩" :key="18" width="100" v-if="depth_check < 4">
                <template scope="scope">
                  <div>
                    {{ scope.row.company_info["4"].average }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="军团数" width="100" :key="6" v-if="depth_check < 5">
                <template scope="scope">
                  <div>
                    {{ scope.row.company_info["5"].count }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="军团部均业绩" :key="19" width="100" v-if="depth_check < 5">
                <template scope="scope">
                  <div>
                    {{ scope.row.company_info["5"].average }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="总人数" width="100" :key="7" v-if="depth_check < 6">
                <template scope="scope">
                  <div>
                    <div>
                      {{ scope.row.people_num }}
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="到账笔数" width="100" :key="10">
                <template scope="scope">
                  <div>
                    {{ scope.row.achievement_count }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="签单量" width="100" :key="11">
                <template scope="scope">
                  <div>
                    {{ scope.row.signed_count }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="件均业绩" width="100" :key="12">
                <template scope="scope">
                  <div>
                    {{ scope.row.signed_achievement }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="人均业绩" width="100" :key="13" v-if="depth_check < 6">
                <template scope="scope">
                  <div>
                    {{ scope.row.people_achievement }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="日均" width="100" :key="27">
                <template scope="scope">
                  <div>
                    {{ scope.row.day_achievement }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="面访量" width="100" :key="14">
                <template scope="scope">
                  <div>
                    {{ scope.row.visit_num }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="面访签单比" width="100" :key="15">
                <template scope="scope">
                  <div>{{ scope.row.visit_rate }}</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="15"
              el-pagination
              layout="total,prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
import {
  statistics,
  timeStatisticsChart,
  timeStatisticsList,
  export_sta,
  seeDepth,
  selectCompanyTree,
  statisticsall,
  businessLine,
} from "../../api/User/data";
import { productList } from "../../api/User/finance";

export default {
  data() {
    return {
      dep_arr: [
        { label: "大区", id: 1 },
        { label: "分公司", id: 2 },
        { label: "事业部", id: 3 },
        { label: "战区", id: 4 },
        { label: "军团", id: 5 },
        { label: "个人", id: 6 },
      ],
      depth_check: "1",
      tableData: [],
      currentPage: 1,
      total: 0,
      time_arr: [
        // { id: 1, name: "周" },
        { id: 1, name: "月" },
        { id: 2, name: "季" },
        { id: 3, name: "年" },
      ],
      check_time_id: "1",
      year: "", //年
      options: [],
      month_options: [],
      month: "", //月份

      quarter: "", //季度
      quarter_list: [
        { label: "第一季度", id: "1" },
        { label: "第二季度", id: "2" },
        { label: "第三季度", id: "3" },
        { label: "第四季度", id: "4" },
      ],
      shadow_show: false,
      is_add: false,
      isResouceShow: 0,
      search_company_id: [],

      //charts相关
      xAxisData: [],
      achievement_arr_arr: [],
      task_arr: [],
      list_str: "",

      all_task: 0,
      all_do: 0,
      all_rate: 0,

      product_arr: [],
      check_prodect: "", //所选产品
      isproductShow: 1,
      business_Line_arr: [], //事业线
      business_line_id: "", //所选事业线
    };
  },
  created() {
    let token = JSON.parse(localStorage.getItem("token"));
    for (let i in token.role) {
      if (token.role[i].permissions == "achievement_analysis") {
        //分配列表
        this.list_str = token.role[i].permissions_id_string;
      }
    }
    seeDepth({
      permissions_id_string: this.list_str,
    }).then((res) => {
      if (res.data.data != 1) {
        this.dep_arr.splice(0, res.data.data - 1);
      }
      this.checkdep(this.dep_arr[0].id);
    });

    for (let i = 1; i <= 12; i++) {
      this.month_options.push({ label: i + "月", value: i < 9 ? "0" + i : i });
    }
    this.year = new Date().getFullYear();
    var currQuarter = new Date().getMonth() + 1;
    var month = new Date().getMonth();
    if (month < 3) {
      this.quarter = "1";
    } else if (month < 6) {
      this.quarter = "2";
    } else if (month < 9) {
      this.quarter = "3";
    } else if (month < 12) {
      this.quarter = "4";
    }
    this.month = month + 1;
    this.month = this.month < 9 ? "0" + this.month : this.month;
    this.year = this.year.toString();
    productList().then((res) => {
      // console.log(res.data);
      if (res.data.code == 200) {
        function dg(list) {
          list.forEach((item) => {
            item.label = item.class_name;
            item.value = item.id;
            if (item.product_name) {
              for (let i in item.product_name) {
                item.product_name[i].label = item.product_name[i].product_name;
                item.product_name[i].value = item.product_name[i].id;
              }
              item.children = item.product_name;
            }
          });
          return list;
        }
        let list = res.data.data;
        dg(list);
        this.product_arr = list;
      }
    });
    businessLine().then((res) => {
      if (res.data.code == 200) {
        this.business_Line_arr = res.data.data;
      }
    });
  },
  mounted() {
    this.getmyecharts();
    window.onresize = () => {
      this.getmyecharts();
    };
  },
  methods: {
    getselectCompanyTree(data) {
      selectCompanyTree(data).then((res) => {
        if (res.data.code == 200) {
          function dg(list) {
            list.forEach((item) => {
              item.label = item.name;
              item.value = item.id;
              if (item.data) {
                item.children = item.data;
                dg(item.data);
              }
            });
            return list;
          }
          let list = res.data.data;
          dg(list);
          this.options = list;
        }
      });
    },
    checkdep(id) {
      if (this.is_dep) {
        return;
      }
      this.check_prodect = "";
      this.business_line_id = "";
      this.is_dep = true;
      this.currentPage = 1;
      this.depth_check = id;
      ++this.isResouceShow;
      this.search_company_id = [];
      this.tableData = [];
      let list = this.options;
      function dg(list) {
        list.forEach((item) => {
          item.label = item.name;
          item.value = item.id;
          if (item.data) {
            item.children = item.data;
            dg(item.data);
          }
        });
        return list;
      }
      dg(list);
      this.options = list;
      this.serch();
      this.getselectCompanyTree({
        permissions_id_string: this.list_str,
        depth: this.depth_check,
      });
    },
    change_timecheck(id) {
      //切换年月日
      this.currentPage = 1;
      this.check_time_id = id;
      this.serch();
    },
    changeyear(e) {
      let heihei = this.getwaytime(e);
      this.year = heihei.toString();
    },
    changequarter(e) {
      this.quarter = e;
    },
    changemonth(e) {
      this.month = e;
    },
    changbusiness(e) {
      this.business_line_id = e;
    },
    handleClick(value) {
      //选择 删除下级children
      //展开可选择不同层级
      if (value.length == 0) {
        ++this.isResouceShow;
        this.search_company_id = [];
        return;
      }
      let options = JSON.parse(JSON.stringify(this.options));
      function dg(list) {
        list.forEach((item) => {
          if (item.data) {
            item.children = item.data;
            dg(item.data);
          }
        });
        return list;
      }
      dg(options);
      this.options = options;
      if (value.length == 0) {
        ++this.isResouceShow;
        return;
      }
      let check_arr = this.$refs["myCascader"].getCheckedNodes();
      this.search_company_id = [];
      let arr = this.search_company_id;
      function depdg(options, check_arr) {
        for (let i = 0; i < options.length; i++) {
          for (let j = 0; j < check_arr.length; j++) {
            if (options[i].id == check_arr[j].value) {
              if (options[i].data) {
                delete options[i].children;
              }
            } else {
              if (options[i].data) {
                depdg(options[i].data, check_arr);
              }
            }
          }
        }
      }
      depdg(options, check_arr);
      this.options = JSON.parse(JSON.stringify(options));
      check_arr.forEach((item) => {
        arr.push(item.value);
      });
      this.search_company_id = arr;
    },
    serch() {
      console.log(this.depth_check);
      let data = {};
      if (this.check_time_id == 1) {
        //月
        data = {
          permissions_id_string: this.list_str,
          month: this.year + "-" + this.month,
          depth: this.depth_check,
          company_id: this.depth_check < 6 ? this.search_company_id.join(",") : "",
          page: this.currentPage,
          product_name_id: this.check_prodect,
          business_line_id: this.business_line_id,
          search_company_id: this.depth_check == 6 ? this.search_company_id.join(",") : "",
        };
      } else if (this.check_time_id == 2) {
        //季度
        data = {
          permissions_id_string: this.list_str,
          quarter: this.quarter,
          depth: this.depth_check,
          year: this.year,
          company_id: this.depth_check < 6 ? this.search_company_id.join(",") : "",
          page: this.currentPage,
          product_name_id: this.check_prodect,
          business_line_id: this.business_line_id,
          search_company_id: this.depth_check == 6 ? this.search_company_id.join(",") : "",
        };
      } else if (this.check_time_id == 3) {
        //年
        data = {
          year: this.year,
          permissions_id_string: this.list_str,
          company_id: this.depth_check < 6 ? this.search_company_id.join(",") : "",
          depth: this.depth_check,
          page: this.currentPage,
          product_name_id: this.check_prodect,
          business_line_id: this.business_line_id,
          search_company_id: this.depth_check == 6 ? this.search_company_id.join(",") : "",
        };
      }
      console.log(data);
      this.getall(data);
      this.gettimeStatisticsChart(data);
      this.gettimeStatisticsList(data);
    },
    reset() {
      //重置
      this.isproductShow++;
      this.check_prodect = "";
      this.business_line_id = "";
      this.tableData = [];
      for (let i = 1; i <= 12; i++) {
        this.month_options.push({
          label: i + "月",
          value: i < 9 ? "0" + i : i,
        });
      }
      this.is_dep = false;
      this.year = new Date().getFullYear();
      var currQuarter = new Date().getMonth() + 1;
      var month = new Date().getMonth();
      if (month < 3) {
        this.quarter = "1";
      } else if (month < 6) {
        this.quarter = "2";
      } else if (month < 9) {
        this.quarter = "3";
      } else if (month < 12) {
        this.quarter = "4";
      }
      this.month = month + 1;
      this.month = this.month < 9 ? "0" + this.month : this.month;
      this.year = this.year.toString();
      this.search_company_id = [];
      ++this.isResouceShow;
      this.currentPage = 1;
      this.serch();
    },
    getall(data) {
      statisticsall(data).then((res) => {
        if (res.data.code == 200) {
          this.all_task = res.data.data.total_mission;
          this.all_do = res.data.data.total_achievement;
          this.all_rate = res.data.data.rate;
        }
      });
    },
    gettimeStatisticsChart(data) {
      timeStatisticsChart(data).then((res) => {
        this.is_dep = false;
        if (res.data.code == 200) {
          let xAxisData = [];
          let achievement_arr = [];
          let task_arr = [];
          res.data.data.forEach((item) => {
            xAxisData.push(item.name);
            achievement_arr.push(item.achievement);
            task_arr.push(item.task);
          });
          this.xAxisData = xAxisData;
          this.achievement_arr = achievement_arr;
          this.task_arr = task_arr;
          this.getmyecharts();
        }
      });
    },
    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear();
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
      var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let strDate = Y;
      return strDate;
    },
    getmyecharts() {
      let mycharts = this.$echarts.init(document.getElementById("mycharts"));
      mycharts.resize();
      mycharts.setOption({
        color: ["#E2E7F2", "#3E66FF"],
        legend: {
          data: ["任务额", "完成额"],
          bottom: 10,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#3C8FF4",
        },
        xAxis: {
          data: this.xAxisData,
          axisLine: {
            onZero: true,
            lineStyle: {
              color: "#ccc",
            },
          },
          splitArea: { show: false },
          // axisLabel: {
          //   interval: "0",
          // },
        },
        yAxis: [
          {
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "#ccc",
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#ccc",
              },
            },
            axisLabel: {
              color: "#262626 ",
            },
          },
        ],
        grid: {
          top: 20,
          bottom: 60,
          left: 80,
          right: 0,
        },
        series: [
          {
            name: "任务额",
            type: "bar",
            stack: "one",
            barWidth: 20,
            data: this.task_arr,
          },
          {
            name: "完成额",
            type: "bar",
            barWidth: 20,
            barGap: "-100%" /*这里设置包含关系，只需要这一句话*/,
            data: this.achievement_arr,
          },
        ],
      });
    },
    gettimeStatisticsList(data) {
      timeStatisticsList(data).then((res) => {
        this.is_dep = false;
        if (res.data.code == 200) {
          this.total = res.data.data.total;
          this.tableData = res.data.data.data;
          if (this.$refs.table) {
            this.$nextTick(() => {
              this.$refs.table.doLayout();
            });
          }
        }
      });
    },
    export_list() {
      let data = {};
      if (this.check_time_id == 1) {
        //月
        data = {
          permissions_id_string: this.list_str,
          month: this.year + "-" + this.month,
          depth: this.depth_check,
          company_id: this.search_company_id.join(","),
          page: this.currentPage,
        };
      } else if (this.check_time_id == 2) {
        //季度
        data = {
          permissions_id_string: this.list_str,
          quarter: this.quarter,
          depth: this.depth_check,
          year: this.year,
          company_id: this.search_company_id.join(","),
          page: this.currentPage,
        };
      } else if (this.check_time_id == 3) {
        //年
        data = {
          year: this.year,
          permissions_id_string: this.list_str,
          company_id: this.search_company_id.join(","),
          depth: this.depth_check,
          page: this.currentPage,
        };
      }
      export_sta(data).then((res) => {
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = res.data.data;
        link.setAttribute("download", "courman" + ".xls");
        document.body.appendChild(link);
        link.click();
      });
    },
    handleProduct(value) {
      if (value.length == 0) {
        this.check_prodect = "";
        return;
      } else {
        let str = "";
        for (let i in value) {
          str += value[i][1] + ",";
        }
        if (str.length > 0) {
          str = str.slice(0, str.length - 1);
          this.check_prodect = str;
        }
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.serch();
    },
  },
};
</script>

<style lang="scss">
.history_box {
  // padding: 20px;
  box-sizing: border-box;
  background: #eef5f9;
  .el-main {
    width: 100%;
    height: 100%;
    // background: #fff;
    display: flex;
    .history_box_dep {
      background: #ffffff;
      width: 150px;
      margin-right: 10px;
      padding-top: 20px;
      height: 100%;
      max-height: 800px;
      position: absolute;
      top: 80px;
      left: 270px;
      div {
        // height: 45px;
        line-height: 45px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #595959;
        opacity: 1;
        cursor: pointer;
      }
      div:hover {
        background: #3a85fc;
        color: #ffffff;
      }
      .active_dep {
        background: #3a85fc;
        color: #ffffff;
      }
    }
    .history_box_data {
      flex: 1;
      height: 100%;
      margin-left: 170px;
      .history_box_data_line {
        // height: 50%;
        padding: 20px;
        background: #ffffff;
        .time_check {
          width: 280px;
          height: 40px;
          display: flex;
          border-radius: 5px;
          border: 1px solid #ccc;
          div {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #ccc;
          }
          div:nth-last-of-type(1) {
            border-right: 0px;
          }
          .acc_time {
            background: #3a85fc;
            color: #fff;
          }
        }
        .serch_big_box {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .info {
            width: 23%;
            margin-top: 20px;
            margin-left: 2%;
            display: flex;
            align-items: center;
            .demonstration {
              // margin-right: 10px;
              width: 50px;
              min-width: 42px;
            }
            .el-cascader {
              height: 32px;
              line-height: 32px;
              .el-input {
                height: 32px;
                line-height: 32px;
                input {
                  height: 32px !important;
                }
              }
              .el-cascader__tags {
                flex-wrap: nowrap;
              }
            }
            .el-input__inner {
              height: 32px;
              line-height: 32px;
            }
            .el-input__icon {
              line-height: 34px;
            }
            .el-input {
              // width: 240px;
              width: 210px;
            }
            .serch {
              width: 70px;
              height: 32px;
              background: #3a85fc;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 2px;
            }
            .reset {
              width: 70px;
              height: 32px;
              background: #fff;
              border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
              color: rgba(0, 0, 0, 0.6);
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 2px;
              margin-left: 20px;
            }
          }
          .info:nth-of-type(4n + 1) {
            margin-left: 0;
          }
        }
        .data_echarts {
          width: 100%;
          height: 300px;
          .mycharts {
            width: 100%;
            height: 300px;
          }
        }
      }
      .history_box_data_table {
        margin-top: 10px;
        width: 100%;
        background: #ffffff;
        min-width: 1000px;
        max-width: 1470px;
        .export {
          width: 100%;
          height: 60px;
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          padding-right: 40px;
          .export_bottom {
            width: 80px;
            height: 32px;
            background: #3a85fc;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 19px;
            color: #ffffff;
            opacity: 1;
            border-radius: 4px;
            cursor: pointer;
          }
          .all_num {
            margin-left: 20px;
            display: inline-block;
            div {
              display: inline-block;
              margin-right: 20px;
            }
          }
        }
        .table {
          margin: 0 20px;
          .el-table .el-table__fixed {
            height: auto !important;
            bottom: 16px;
          }
        }
        .block {
          margin: 0 20px;
          margin-top: 20px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
</style>
