import request from '@/utils/axios.tool';
//产品列表
export const getProductList = (data) => {
  return request.post(`/roleapi/product/getProductList`, data)
}
//排序
export const editProductBy = (data) => {
  return request.post(`/roleapi/product/editProductBy`, data)
}
// 新增产品分类
export const addProductClass = (data) => {
  return request.post(`/roleapi/product/addProductClass`, data)
}
//新增产品
export const addProductName = (data) => {
  return request.post(`/roleapi/product/addProductName`, data)
}
// 修改产品
export const editProductName = (data) => {
  return request.post(`/roleapi/product/editProductName`, data)
}
// 修改产品类
export const editProductClass = (data) => {
  return request.post(`/roleapi/product/editProductClass`, data)
}