<template>
  <div class="order_center">
    <el-main>
      <div class="customer_achi_head" v-if="customerdetail.customer_res">
        <div class="customer_achi_head_name">
          {{ customerdetail.customer_res.name }}
        </div>
        <div class="customer_achi_head_info">
          <div class="">
            客户姓名：{{ customerdetail.customer_res.contacts }}
          </div>
          <div class="">手机号: {{ customerdetail.customer_res.phone }}</div>
        </div>
        <div class="customer_achi_head_back pointer" @click="handleBack">
          返回上一级
        </div>
      </div>
      <div style="margin-top: 20px" v-if="orderList.length > 0">
        <div
          class="order_center_box"
          v-for="(item, index) in orderList"
          :key="index"
        >
          <div class="order_center_box_head">
            <div class="order_center_box_head_line">
              <div
                class=""
                style="
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  line-height: 19px;
                  color: rgba(0, 0, 0, 0.8);
                  opacity: 1;
                "
              >
                <div>合同号：{{ item.contract_number }}</div>
              </div>
            </div>
            <div class="order_center_box_head_line">
              <div class="order_center_box_head_info">
                <div>
                  产品：{{
                    item.product_name ? item.product_name.product_name : "--"
                  }}
                </div>
                <div>合同金额：￥{{ item.contract_money }}</div>
                <div class="file_url_showbox">
                  合同照片：
                  <!-- <img
                    :src="itm.file_url"
                    alt=""
                    v-for="(itm, idx) in item.contract_file_arr"
                    :key="idx"
                    @click="show_img(itm.file_url)"
                  /> -->
                  <el-image
                    style="
                      width: 120px;
                      height: 70px;
                      cursor: pointer;
                      margin-right: 10px;
                    "
                    v-for="(itm, idx) in item.contract_file_arr"
                    :src="itm"
                    :key="idx"
                    :preview-src-list="item.contract_file_arr"
                  >
                  </el-image>
                </div>
              </div>
              <div class="order_center_box_head_bottom" v-if="type == 1">
                <!-- <div @click="handlePlace(item)">下单{{ item.detail }}</div> -->
                <div @click="handleAdd_Arr(item)">新增到账</div>
              </div>
            </div>
          </div>
          <div class="order_center_box_tit" v-if="item.type == 1">
            <i></i>下单详情
          </div>
          <div class="order_center_box_line" v-if="item.type == 1">
            <div style="width: 25%">
              客户类型：{{ item.customer_type == 1 ? "新客户" : "老客户" }}
            </div>
            <div style="width: 25%">
              交接方式：{{
                item.handover == 1
                  ? "上门交接"
                  : item.handover == 2
                  ? "客户前来交接"
                  : "无需交接"
              }}
            </div>
            <div style="width: 25%">
              合作类型：{{ item.cooperation == 1 ? "新签" : "续签" }}
            </div>
            <div style="width: 25%">
              是否加急：{{ item.is_urgent == 1 ? "是" : "否" }}
            </div>
          </div>
          <div class="order_center_box_line" v-if="item.type == 1">
            <div style="width: 25%">服务开始时间：{{ item.service_time }}</div>
            <div style="width: 25%">
              服务结束时间：{{ item.service_time_end }}
            </div>
            <div
              style="
                width: 50%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              备注：

              {{ item.remarks }}
            </div>
          </div>
          <!-- <div class="order_center_box_tit" v-if="item.Promotion">
            <i></i>促销信息
          </div>
          <div
            class="order_center_box_table"
            style="width: 70%"
            v-if="item.Promotion"
          >
            <el-table
              border
              :data="item.Promotion"
              :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
              style="width: 100%"
            >
              <el-table-column prop="date" width="170" label="申请时间">
                <template scope="scope">
                  {{ scope.row.created_at }}
                </template>
              </el-table-column>
              <el-table-column prop="date" label="促销类型">
                <template scope="scope">
                  {{
                    scope.row.status == 1
                      ? "实物"
                      : scope.row.status == 2
                      ? "返现"
                      : scope.row.status == 3
                      ? "打折"
                      : scope.row.status == 4
                      ? "赠送服务时长"
                      : scope.row.status == 5
                      ? "赠送其它服务"
                      : ""
                  }}
                </template>
              </el-table-column>
              <el-table-column prop="date" label="促销成本">
                <template scope="scope">
                  {{ scope.row.money }}
                </template>
              </el-table-column>
              <el-table-column prop="date" label="备注">
                <template scope="scope">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 2; //多行在这里修改数字即可
                      overflow: hidden;
                      -webkit-box-orient: vertical;
                    "
                  >
                    <el-popover
                      placement="bottom"
                      trigger="hover"
                      width="400"
                      :content="
                        scope.row.apply_explain ? scope.row.apply_explain : '--'
                      "
                    >
                      <div slot="reference">
                        {{
                          scope.row.apply_explain
                            ? scope.row.apply_explain
                            : "--"
                        }}
                      </div>
                    </el-popover>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="状态">
                <template scope="scope">
                  {{
                    scope.row.status == 1
                      ? "待审核"
                      : scope.row.status == 2
                      ? "已使用"
                      : scope.row.status == 3
                      ? "审核通过"
                      : scope.row.status == 4
                      ? "驳回"
                      : ""
                  }}
                </template>
              </el-table-column>
            </el-table>
          </div> -->
          <!-- <div class="order_center_box_line">
                        <div>促销类型：实物</div>
                        <div>促销成本：￥200.00</div>
                    </div>
                    <div class="order_center_box_line_one">
                        <div>备注：展示备注内容展示备注内容展示备注内容展示备注内容展示备注内容展示备注内容展示备注内容展示备注内容</div>
                    </div> -->
          <div class="order_center_box_tit"><i></i>到账信息</div>
          <div class="order_center_box_table">
            <el-table
              border
              :data="item.order_data"
              :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
              style="width: 100%"
            >
              <el-table-column label="到账类型" :key="1">
                <template scope="scope">
                  {{
                    scope.row.type == 1
                      ? "定金"
                      : scope.row.type == 2
                      ? "尾款"
                      : scope.row.type == 3
                      ? "全款"
                      : "退款"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="到账金额(元)" :key="2">
                <template scope="scope">
                  {{ scope.row.to_money }}
                </template>
              </el-table-column>
              <el-table-column width="170" label="到账时间" :key="3">
                <template scope="scope">
                  {{ scope.row.to_time ? scope.row.to_time : "--" }}
                </template>
              </el-table-column>

              <el-table-column label="促销" :key="4">
                <template scope="scope">
                  {{ scope.row.promotion ? scope.row.promotion.money : "--" }}
                </template>
              </el-table-column>

              <el-table-column label="收款方式" :key="5" width="80">
                <template scope="scope">
                  {{
                    scope.row.collection_id_text
                      ? scope.row.collection_id_text
                      : "--"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="收款凭证" :key="6" width="200">
                <template scope="scope">
                  <div class="file_url_box">
                    <el-image
                      style="width: 100px; height: 100px; margin-right: 5px"
                      v-for="(itm, idx) in scope.row.collection_file_arr"
                      :key="idx"
                      :src="itm"
                      :preview-src-list="scope.row.collection_file_arr"
                    >
                    </el-image>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="分单人" :key="7" width="120">
                <template scope="scope">
                  <div
                    class="order_center_status"
                    style="display: flex; flex-direction: column"
                    v-if="scope.row.order_data_branch.length > 0"
                  >
                    <div
                      v-for="(item, index) in scope.row.order_data_branch"
                      :key="index"
                      style="justify-content: flex-start"
                    >
                      {{ item.user.name }}
                    </div>
                  </div>
                  <div v-else>----</div>
                </template>
              </el-table-column>
              <el-table-column label="分单金额(元)" :key="8">
                <template scope="scope">
                  <div
                    class="order_center_status"
                    style="display: flex; flex-direction: column"
                    v-if="scope.row.order_data_branch.length > 0"
                  >
                    <div
                      v-for="(item, index) in scope.row.order_data_branch"
                      :key="index"
                      style="justify-content: flex-start"
                    >
                      {{ item.money }}
                    </div>
                  </div>
                  <div v-else>----</div>
                </template>
              </el-table-column>
              <el-table-column label="状态" :key="9" width="80">
                <template scope="scope">
                  <div class="order_center_status">
                    <i
                      :class="
                        scope.row.status == 0
                          ? 'wait_status'
                          : scope.row.status == 1
                          ? 'well_status'
                          : scope.row.status == 2
                          ? 'reject_status'
                          : 'cx_status'
                      "
                    ></i>
                    {{
                      scope.row.status == 0
                        ? "待审核"
                        : scope.row.status == 1
                        ? "通过"
                        : scope.row.status == 2
                        ? "驳回"
                        : "撤销"
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="备注" :key="10">
                <template scope="scope">
                  <div>
                    <el-popover
                      placement="bottom"
                      width="400"
                      trigger="click"
                      v-if="scope.row.remarks"
                    >
                      <div
                        slot="reference"
                        class="pointer acc_table_company_click"
                      >
                        点击查看
                      </div>
                      <div v-html="scope.row.remarks"></div>
                    </el-popover>
                    <div v-else>--</div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="order_center_box_bottom">
            <div class="order_center_box_bottom_one">
              <div>累计收款：￥{{ item.all_money }},</div>
            </div>
            <div class="order_center_box_bottom_one">
              <div>分单金额：￥{{ item.fd_money }},</div>
            </div>
            <div class="order_center_box_bottom_one">
              <div>促销金额：￥{{ item.cx_money }},</div>
            </div>
            <div class="order_center_box_bottom_one">
              <div>退款金额：￥{{ Math.abs(item.tk_money) }},</div>
            </div>
            <div class="order_center_box_bottom_one">
              <div>业绩金额：￥{{ item.achi_money }},</div>
            </div>
            <div class="order_center_box_bottom_one">
              <div>剩余未收：￥{{ item.none_money }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="orderList_none" v-else>暂无订单信息</div>
    </el-main>
    <div class="login_mc" v-if="isMc"></div>
    <div
      class="show_img"
      v-if="is_show_img"
      @click="
        is_show_img = false;
        show_img_src = '';
      "
    >
      <img :src="show_img_src" alt="" />
    </div>
    <div class="place_box" v-if="is_place">
      <div class="place_box_head">
        下单
        <i
          class="pointer el-icon-close"
          style="padding: 10px"
          @click="
            isMc = false;
            is_place = false;
          "
        ></i>
      </div>
      <div class="place_box_info_head"><i></i>客户信息</div>
      <div class="place_box_line">
        <div class="place_box_line_tlt">客户类型：</div>
        <el-select v-model="type" placeholder="请选择客户类型">
          <el-option
            v-for="item in Customer_type"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="place_box_line">
        <div class="place_box_line_tlt">交接方式：</div>
        <el-select v-model="Handover_id" placeholder="请选择交接方式">
          <el-option
            v-for="item in Handover"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="place_box_line">
        <div class="place_box_line_tlt">合作类型：</div>
        <el-select v-model="cooperation" placeholder="请选择合作类型">
          <el-option
            v-for="item in cooperation_type"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="place_box_line">
        <div class="place_box_line_tlt">开始时间：</div>
        <el-date-picker
          v-model="start_time"
          type="date"
          clear-icon="sss"
          placeholder="请选择开始时间"
        >
        </el-date-picker>
      </div>
      <div class="place_box_line">
        <div class="place_box_line_tlt">结束时间：</div>
        <el-date-picker
          v-model="end_time"
          type="date"
          clear-icon="sss"
          placeholder="请选择结束时间"
        >
        </el-date-picker>
      </div>
      <div class="place_box_line">
        <div class="place_box_line_tlt">是否加急：</div>
        <div class="place_box_line_info">
          <el-radio v-model="radio" label="1">是</el-radio>
          <el-radio v-model="radio" label="2">否</el-radio>
        </div>
      </div>
      <div class="place_box_line" style="align-items: flex-start">
        <div class="place_box_line_tlt">备注：</div>
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入内容"
          v-model="order_remarks"
        >
        </el-input>
      </div>
      <div class="place_box_bottom">
        <div
          @click="
            is_place = false;
            isMc = false;
          "
        >
          取消
        </div>
        <div @click="handlePlace_Ok">确定</div>
      </div>
    </div>
    <div class="add_Arrival_box" v-if="is_Addarrival">
      <div class="add_Arrival">
        <!-- 新增到账 -->
        <div class="add_Arrival_head">
          新增到账
          <i
            class="pointer el-icon-close"
            @click="
              isMc = false;
              is_Addarrival = false;
              radio_id = '1';
            "
          ></i>
        </div>
        <div class="add_Arrival_box_info">
          <div class="add_Arrival_head_tit"><i></i>公司信息</div>
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">公司名称：</div>
            <div class="add_Arrival_line_inp">
              {{ customerdetail.customer_res ? customerdetail.customer_res.name : "" }}
            </div>
          </div>
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">客户姓名：</div>
            <div class="add_Arrival_line_inp">
              {{ customerdetail.customer_res ? customerdetail.customer_res.contacts : "" }}
            </div>
          </div>
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">产品名称：</div>
            <el-select v-model="pro_id" placeholder="请选择产品">
              <el-option
                v-for="item in prooption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="add_Arrival_head_tit"><i></i>到账信息</div>
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">本次实收金额：</div>
            <el-input
              type="text"
              v-model="Arrival_money"
              placeholder="请输入到账金额"
            />
          </div>
          <!-- <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">到账类型：</div>
            <el-select v-model="Arrival_type" placeholder="请选择到账类型">
              <el-option
                v-for="item in Arrivaloption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div> -->
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">收款方式：</div>
            <el-select v-model="collection" placeholder="请选择收款方式">
              <el-option
                v-for="item in collectionList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="add_Arrival_line" v-if="0">
            <div class="add_Arrival_line_tit">到账时间：</div>
            <el-date-picker
              v-model="time"
              type="datetime"
              prefix-icon="el-icon-date"
              clear-icon="sss"
              :picker-options="pickerOptions"
              placeholder="请选择到账时间"
            >
            </el-date-picker>
          </div>
          <!-- <div class="add_Arrival_head_tit"><i></i>收款凭证</div> -->
          <div
            class="add_Arrival_line"
            style="align-items: flex-start; height: 110px"
          >
            <div class="add_Arrival_line_tit">收款凭证：</div>
            <div class="contract_img">
              <div class="flow_kind_text">
                <div
                  class="flow_kind_text_img"
                  v-for="(item, index) in collection_file_list_arr"
                  :key="index"
                >
                  <el-image
                    style="width: 100px; height: 100px; border-radius: 4px"
                    :src="item"
                    :preview-src-list="collection_file_list_arr"
                  >
                  </el-image>
                  <i
                    class="el-icon-circle-close"
                    @click="handleDel_img(index)"
                    style="color: #ccc"
                  ></i>
                </div>
                <div
                  class="image_info"
                  @click="chooseFile"
                  v-if="collection_file_list.length < 3"
                >
                  <img src="@/assets/img/upload_img.png" alt="" />
                  <span>上传相关图片</span>
                  <input
                    type="file"
                    style="display: none"
                    ref="choosePhoto"
                    @change="photoChange($event)"
                    id="upload_file"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="add_Arrival_line"
            style="align-items: flex-start; height: 110px"
          >
            <div class="add_Arrival_line_tit">备注：</div>
            <el-input
              type="textarea"
              :rows="3"
              placeholder="请输入内容"
              v-model="remarks"
            >
            </el-input>
          </div>
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">是否促销：</div>
            <div class="add_Arrival_line_check">
              <el-radio v-model="radio_id" label="1" @change="handleChangePro"
                >否</el-radio
              >
              <el-radio v-model="radio_id" label="2" @change="handleChangePro"
                >是</el-radio
              >
            </div>
          </div>
          <div class="add_Arrival_line" v-if="radio_id == 2">
            <div class="add_Arrival_line_tit">促销类型：</div>
            <el-input
              type="text"
              disabled
              placeholder="暂无促销"
              v-if="PromotionList.length == 0"
            />
            <el-select
              v-model="Promotion_id"
              placeholder="请选择促销类型"
              v-else
            >
              <el-option
                v-for="(item, index) in PromotionList"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="add_Arrival_head_tit"><i></i>分单信息</div>
          <div v-for="(item, index) in contract_fd_list" :key="index">
            <div class="add_Arrival_line">
              <div class="add_Arrival_line_tit">分单人：</div>
              <el-autocomplete
                class="inline-input"
                v-model="item.user_name"
                :fetch-suggestions="querySearch"
                placeholder="请输入姓名选择分单人"
                :trigger-on-focus="false"
                @select="handleSelect"
              ></el-autocomplete>
              <i
                class="el-icon-remove-outline pointer"
                style="font-size: 30px; color: #ccc; margin-left: 20px"
                @click="handleDel_fendan(index)"
              ></i>
            </div>
            <div class="add_Arrival_line" style="padding-right: 160px">
              <div class="add_Arrival_line_tit">分单金额：</div>
              <el-input
                type="text"
                v-model="item.to_money"
                placeholder="请输入金额"
              />
            </div>
          </div>
          <div class="add_Arrival_line_add pointer" @click="handleAdd_fendan">
            <i class="el-icon-plus"></i>
            新增分单人
          </div>
          <div class="add_Arrival_bottom">
            <div
              @click="
                isMc = false;
                is_Addarrival = false;
              "
            >
              取消
            </div>
            <div @click="handleOK">确认</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { collectionList } from "../../api/User/finance";
import { uploadFile } from "../../api/User/index";
import {
  detail,
  orderUserList,
  PromotionList,
  getUserData,
  updateOrder,
  addOrderMore,
} from "../../api/User/customer";
export default {
  data() {
    return {
      isMc: false,
      is_place: false, //下单
      is_Addarrival: false, //新增到账
      my_customer_id: "",
      customerdetail: null,
      Customer_type: [
        { value: 1, label: "新客户" },
        { value: 2, label: "老客户" },
      ], //客户类型
      type: "", //1 个人 2部门
      Handover: [
        { value: 1, label: "上门交接" },
        { value: 2, label: "客户前来交接" },
        { value: 3, label: "无需交接" },
      ], //交接方式
      Handover_id: "",
      cooperation_type: [
        { value: 1, label: "新签" },
        { value: 2, label: "续签" },
      ],
      cooperation: "", //合作类型
      orderList: [], //下单列表
      tableData: [],
      start_time: "", //开始时间
      end_time: "", //结束时间
      radio: "2", // 1  加急 2 不加急
      order_remarks: "", //备注
      order_id: "", //下单id
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },

      //新增到账
      prooption: [],
      pro_id: "", //到账产品
      Arrival_money: "", //到账金额
      Arrivaloption: [
        { value: "1", label: "定金" },
        { value: "2", label: "尾款" },
        { value: "3", label: "全款" },
      ],
      Arrival_type: "", //到账类型
      collectionList: [], //收款方式列表
      collection: "", //收款方式
      time: "", //到账时间
      // contract_money:'',              //合同金额
      // contract_number:'',         //合同单号
      radio_id: "1", //是否促销
      PromotionList: [], //促销列表
      Promotion_id: "", //选择促销id
      contract_fd_list: [
        {
          user_id: "",
          user_name: "",
          to_money: "",
        },
      ],
      fluterArr: [], //分单搜索人
      // chooseFile_what:'',                 //1 合同  2 收款凭证
      collection_file_list: [], //收款文件
      collection_file_list_arr: [], //收款文件放大
      remarks: "", //备注
      add_flag: false,

      show_img_src: "", //放大图片
      is_show_img: false,
    };
  },
  created() {
    let my_customer_id = "";
    let obj = {};
    if (this.$route.params.type) {
      obj = this.$route.params;
      my_customer_id = this.$route.params.my_customer_id;
      this.my_customer_id = this.$route.params.my_customer_id;
      localStorage.setItem("detail_id", JSON.stringify(this.$route.params));
    } else {
      obj = JSON.parse(localStorage.getItem("detail_id"));
      this.my_customer_id = JSON.parse(
        localStorage.getItem("detail_id")
      ).my_customer_id;
      my_customer_id = JSON.parse(
        localStorage.getItem("detail_id")
      ).my_customer_id;
    }
    let type = obj.type;
    this.type = obj.type;
    detail(obj).then((res) => {
      if (res.data.code == 200) {
        if (type == 1) {
          this.customerdetail = res.data.data;
        }
        if (type == 2) {
          this.customerdetail = res.data.data[0];
        }
      }
    });
    let balabala = []; //收款方式
    collectionList().then((res) => {
      //收款方式
      if (res.data.code == 200) {
        this.collectionList = res.data.data;
        balabala = res.data.data;
      }
    });
    orderUserList({ my_customer_id }).then((res) => {
      if (res.data.code == 200) {
        let list = res.data.data.data;
        list.forEach((item) => {
          item.contract_file_arr = [];
          item.contract_file.forEach((itm) => {
            item.contract_file_arr.push(itm.file_url);
          });
          item.all_money = 0; //累计
          item.cx_money = 0; //促销
          item.fd_money = 0; //  分单
          item.tk_money = 0; //退款
          item.none_money = 0; //剩余未收
          item.achi_money = 0; //业绩金额
          item.order_data.forEach((itm) => {
            itm.collection_file_arr = [];
            if (itm.collection_file) {
              itm.collection_file.forEach((elem) => {
                itm.collection_file_arr.push(elem.file_url);
              });
            }
            balabala.forEach((im) => {
              if (im.id == itm.collection_id) {
                itm.collection_id_text = im.name;
              }
            });
            if (itm.status == 1) {
              if (itm.type != 4) {
                //不是退款
                item.all_money += itm.to_money * 1;
                if (itm.promotion) {
                  item.cx_money += itm.promotion.money * 1;
                  let arr = [];
                  arr.push(itm.promotion);
                  item.Promotion = arr;
                }
                if (itm.order_data_branch) {
                  itm.order_data_branch.forEach((itmm) => {
                    item.fd_money += itmm.money * 1;
                  });
                }
              } else {
                item.tk_money += itm.to_money * 1;
              }

              item.achi_money = item.all_money - item.cx_money - item.fd_money; //业绩金额
              item.none_money = item.contract_money - item.all_money; //剩余未收款
            }
          });
        });
        this.orderList = list;
      }
    });
  },
  methods: {
    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let strDate = Y + M + D;
      // let strDate = Y + M + D + h + m + '00';
      return strDate;
    },
    getwaytime_s(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // let strDate = Y + M + D;
      let strDate = Y + M + D + h + m + "00";
      return strDate;
    },
    handleOK() {
      if (this.add_flag) {
        return;
      }
      let collection_file = []; //收款凭证
      let order_data_branch_json = []; //分单人
      let to_time = "";
      let {
        Arrival_money,
        // Arrival_type,
        collection,
        time,
        pro_id,
        Promotion_id,
        contract_fd_list,
        radio,
        collection_file_list,
        remarks,
      } = this;
      // if (time == "") {
      //   this.$message({
      //     message: "请选择到账时间",
      //     type: "error",
      //   });
      //   return;
      // } else {
      //   to_time = this.getwaytime_s(time);
      // }
      if (
        Arrival_money == "" ||
     
        collection == "" ||
        pro_id == ""
      ) {
        this.$message({
          message: "请完善数据",
          type: "error",
        });
        return;
      }
      contract_fd_list.forEach((item) => {
        if (item.user_id != "") {
          // console.log(item);
          let obj = {
            user_id: item.user_id,
            to_money: item.to_money,
          };
          order_data_branch_json.push(obj);
        }
      });
      if (order_data_branch_json.length == 0) {
        order_data_branch_json = JSON.stringify([]);
      } else {
        order_data_branch_json = JSON.stringify(order_data_branch_json);
      }
      collection_file_list.forEach((item) => {
        let obj = {
          file_url: item.url,
        };
        collection_file.push(obj);
      });
      if (collection_file.length == 0) {
        this.$message({
          message: "请完善数据",
          type: "error",
        });
        return;
      }
      this.add_flag = true;
      let data = {
        order_id: this.order_id,
        product_name_id: pro_id, //产品id
        // contract_money,             //合同金额
        // contract_number,                //合同单号
        to_money: Arrival_money, //到账金额
        // type: Arrival_type, //到账类型 1定金 2尾款 3全款 4退款
        collection_id: collection, //收款方式
        // to_time, //到账时间
        promotion_id: Promotion_id, //促销id
        order_data_branch_json, //分单人
        collection_file: JSON.stringify(collection_file), //收款文件
        remarks,
      };
      addOrderMore(data).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          orderUserList({ my_customer_id: this.my_customer_id }).then(
            (ress) => {
              if (ress.data.code == 200) {
                let list = ress.data.data.data;
                list.forEach((item) => {
                  item.contract_file_arr = [];
                  item.contract_file.forEach((itm) => {
                    item.contract_file_arr.push(itm.file_url);
                  });
                  item.all_money = 0; //累计
                  item.cx_money = 0; //促销
                  item.fd_money = 0; //  分单
                  item.tk_money = 0; //退款
                  item.none_money = 0; //剩余未收
                  item.achi_money = 0; //业绩金额
                  item.order_data.forEach((itm) => {
                    itm.collection_file_arr = [];
                    if (itm.collection_file) {
                      itm.collection_file.forEach((elem) => {
                        itm.collection_file_arr.push(elem.file_url);
                      });
                    }
                    this.collectionList.forEach((im) => {
                      if (im.id == itm.collection_id) {
                        itm.collection_id_text = im.name;
                      }
                    });
                    if (itm.status == 1) {
                      if (itm.type != 4) {
                        //不是退款
                        item.all_money += itm.to_money * 1;
                        if (itm.promotion) {
                          item.cx_money += itm.promotion.money * 1;
                          let arr = [];
                          arr.push(itm.promotion);
                          item.Promotion = arr;
                        }
                        if (itm.order_data_branch) {
                          itm.order_data_branch.forEach((itmm) => {
                            item.fd_money += itmm.money * 1;
                          });
                        }
                      } else {
                        item.tk_money += itm.to_money * 1;
                      }

                      item.achi_money =
                        item.all_money - item.cx_money - item.fd_money; //业绩金额
                      item.none_money = item.contract_money - item.all_money; //剩余未收款
                    }
                  });
                });
                this.orderList = list;
              }
            }
          );
          this.isMc = false;
          this.is_Addarrival = false;
            this.add_flag = false;
        }
      }).catch(()=>{
          this.add_flag = false;
      })
    },
    handleChangePro(i) {
      //获取促销
      if (i == 2) {
        this.PromotionList = [];
        this.Promotion_id = "";
        let { customerdetail } = this;
        let data = {
          my_customer_id: customerdetail.id,
          customer_id: customerdetail.customer_id,
          product_name_id: this.pro_id,
          status: 3,
        };
        let cx_arr = [
          { value: 1, label: "实物" },
          { value: 2, label: "返现" },
          { value: 3, label: "打折" },
          { value: 4, label: "赠送服务时长" },
          { value: 5, label: "赠送其它服务" },
          { value: 6, label: "其他" },
        ];
        PromotionList(data).then((res) => {
          if (res.data.code == 200) {
            let list = res.data.data.data;
            list.forEach((item) => {
              cx_arr.forEach((itm) => {
                if (item.type == itm.value) {
                  item.name = itm.label;
                }
              });
            });
            this.PromotionList = list;
          }
        });
      }
    },
    chooseFile: function () {
      this.$refs.choosePhoto.addEventListener('change',this.photoChange);
this.$refs.choosePhoto.dispatchEvent(new MouseEvent("click"));
      this.upload_img = false;
      setTimeout(() => {
        this.upload_img = true;
      }, 10);
    },
    photoChange(el) {
      var file = el.target.files[0]; //name: "dangqi1.png" || type: "image/png"
      var type = file.type.split("/")[0];
      if (type === "image") {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        var that = this;
        reader.onloadend = function () {
          var dataURL = reader.result;
          var blob = that.dataURItoBlob(dataURL);
          that.upload(blob); //执行上传接口
        };
      } else {
        this.$message.error("请上传图片哦~~");
      }
    },
    dataURItoBlob(dataURI) {
      // base64 解码
      let byteString = window.atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let T = mimeString.split("/")[1];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    upload(imgUrl) {
      let formData = new FormData();
      this.$message({
        message: "上传中，请稍后",
      });
      formData.append("file", imgUrl);
      uploadFile(formData).then((res) => {
        if (res.data.code == 200) {
          this.collection_file_list.push(res.data.data);
          this.collection_file_list_arr.push(res.data.data.file_url);
          this.$message({
            message: "上传成功",
            type: "success",
          });
        }
      });
    },
    handleDel_img(num) {
      this.collection_file_list.forEach((item, index) => {
        if (index == num) {
          this.collection_file_list.splice(index, 1);
          this.collection_file_list_arr.splice(index, 1);
        }
      });
    },
    querySearch(queryString, cb) {
      getUserData({ user_name: queryString }).then((res) => {
        let list = res.data.data.data;
        list.forEach((item) => {
          item.value = item.user.name + " 【" + item.company.name + "】";
        });
        this.fluterArr = list;
        var restaurants = this.loadAll();
        var results = queryString
          ? restaurants.filter(this.createFilter(queryString))
          : restaurants;
        // // 调用 callback 返回建议列表的数据
        cb(results);
      });
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    loadAll() {
      return this.fluterArr;
    },
    handleSelect(val) {
      let { contract_fd_list } = this;
      contract_fd_list.forEach((item) => {
        if (item.user_name == val.value) {
          item.user_id = val.user_id;
        }
      });
    },
    handleAdd_fendan() {
      //新增分单人
      let bool = false;
      let { contract_fd_list } = this;
      contract_fd_list.forEach((item) => {
        if (item.user_name == "" || item.to_money == "") {
          this.$message({
            message: "请完善分单信息",
            type: "error",
          });
          bool = true;
        }
      });
      if (!bool) {
        let obj = {
          user_id: "",
          user_name: "",
          to_money: "",
        };
        contract_fd_list.push(obj);
      }
    },
    handleDel_fendan(val) {
      //删除分单人
      let { contract_fd_list } = this;
      if (contract_fd_list.length < 2) {
        return;
      }
      contract_fd_list.splice(val, 1);
    },
    handleBack() {
      history.go(-1);
    },
    handleAdd_Arr(item) {
      //新增到账
      this.Arrival_money = "";
      this.Arrival_type = "";
      this.collection = "";
      this.time = "";
      this.radio_id = "1";
      this.PromotionList = [];
      this.Promotion_id = "";
      (this.contract_fd_list = [
        {
          user_id: "",
          user_name: "",
          to_money: "",
        },
      ]),
        (this.collection_file_list = []);
      this.collection_file_list_arr = [];
      this.remarks = "";
      this.prooption = [];
      this.order_id = item.id;
      let obj = {
        label: item.product_name.product_name,
        value: item.product_name.id,
      };
      this.pro_id = item.product_name.id;
      this.prooption.push(obj);

      this.isMc = true;
      this.is_Addarrival = true;
    },
    handlePlace(item) {
      this.order_id = item.id;
      // console.log(item);
      this.order_remarks = "";
      this.radio = "1";
      // this.type = "";
      this.Handover_id = "";
      this.cooperation = "";
      this.start_time = "";
      this.end_time = "";
      this.isMc = true;
      this.is_place = true;
    },
    handlePlace_Ok() {
      //确定下单
      let {
        order_id,
        order_remarks,
        type,
        Handover_id,
        cooperation,
        start_time,
        end_time,
        radio,
      } = this;
      if (
        start_time == "" ||
        end_time == "" ||
        type == "" ||
        Handover_id == "" ||
        cooperation == ""
      ) {
        this.$message({
          message: "请完善填写数据",
          type: "error",
        });
        return;
      }
      if (start_time > end_time) {
        this.$message({
          message: "开始时间不能大于结束时间",
          type: "error",
        });
        return;
      }
      let service_time = this.getwaytime(start_time);
      let service_time_end = this.getwaytime(end_time);
      let data = {
        order_id,
        customer_type: type,
        handover: Handover_id,
        cooperation,
        service_time,
        service_time_end,
        is_urgent: radio,
        remarks: order_remarks,
      };
      // console.log(data);
      updateOrder(data).then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.is_place = false;
          this.isMc = false;
        }
      });
    },

    show_img(src) {
      this.show_img_src = src;
      this.is_show_img = true;
    },
  },
};
</script>

<style lang="scss">
.order_center {
  margin: 20px;
  box-sizing: border-box;
  width: 100%;
  .login_mc {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 98;
  }
  .show_img {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    img {
      z-index: 99;
      width: 350px;
      background: #ffff;
      margin: auto;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
      overflow: hidden;
    }
  }
  .el-main {
    .el-icon-circle-close {
      color: #fff;
    }
    .customer_achi_head {
      padding-left: 35px;
      background: #fff;
      width: 100%;
      height: 90px;
      box-sizing: border-box;
      position: relative;
      .customer_achi_head_name {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 21px;
        color: rgba(0, 0, 0, 0.8);
        opacity: 1;
        padding-top: 13px;
        margin-bottom: 20px;
      }
      .customer_achi_head_info {
        display: flex;
        height: 19px;
        align-items: center;
        div {
          padding-right: 50px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.6);
          opacity: 1;
        }
        div:last-child {
          padding-left: 50px;
          border-left: 1px solid rgba(0, 0, 0, 0.2);
        }
      }
      .customer_achi_head_back {
        width: 90px;
        height: 35px;
        background: #3a85fc;
        border-radius: 2px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 20px;
        right: 80px;
      }
    }
    .orderList_none {
      display: flex;
      justify-content: center;
      color: #ccc;
      margin: 40px;
    }
    .order_center_box {
      background: #fff;
      padding: 0 30px;
      padding-bottom: 50px;
      border-bottom: 1px dashed #ccc;

      .order_center_box_head {
        display: flex;
        height: 60px;
        min-height: 100px;
        padding: 30px 0;
        flex-direction: column;
        justify-content: space-around;
        .order_center_box_head_line {
          display: flex;
        }
        .order_center_box_head_info {
          width: 70%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .file_url_showbox {
            display: flex;
            align-items: center;
            // img {
            //   width: 120px;
            //   height: 70px;
            //   cursor: pointer;
            //   margin-right: 10px;
            // }
          }
          div {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            line-height: 19px;
            color: rgba(0, 0, 0, 0.8);
          }
          .el-image-viewer__actions__inner {
            font-size: 23px;
            color: #fff;
          }
        }
        .order_center_box_head_bottom {
          display: flex;
          width: 30%;
          justify-content: flex-end;
          div {
            cursor: pointer;
            font-size: 14px;
            width: 100px;
            height: 35px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          div:first-child {
            background: #ffffff;
            border: 1px solid #3a85fc;
            color: #3a85fc;
          }
          div:last-child {
            background: #3a85fc;
            color: #fff;
            margin-left: 20px;
          }
        }
      }
      .order_center_box_tit {
        display: flex;
        padding-left: 7px;
        box-sizing: border-box;
        height: 40px;
        align-items: center;
        background: #fafafa;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 19px;
        color: rgba(0, 0, 0, 0.8);
        i {
          display: block;
          width: 2px;
          height: 12px;
          background: #3a85fc;
          margin-right: 5px;
        }
      }
      .order_center_box_line {
        display: flex;
        padding: 20px 0;
        div {
          width: 50%;
        }
      }
      .order_center_box_line_one {
        padding-bottom: 20px;
      }
      .order_center_box_table {
        margin-top: 20px;
        width: 100%;
        .acc_table_company_click:hover {
          color: #3a85fc;
        }
        .order_center_status {
          display: flex;
          align-items: center;
          i {
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin-right: 5px;
          }
          .well_status {
            background: #1dd0bd;
          }
          .wait_status {
            background: #faad14;
          }
          .reject_status {
            background: #ff4d4f;
          }
          .cx_status {
            background: #3299eb;
          }
          div {
            border-bottom: 1px solid #ccc;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          div:last-child {
            border-bottom: none;
          }
        }
      }
      .order_center_box_bottom {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;
        .order_center_box_bottom_one {
          font-size: 14px;
          color: #606266;
          margin-left: 10px;
        }
      }
    }
    .el-table th.gutter {
      display: table-cell !important;
    }
    .order_center_box:last-child {
      border-bottom: none;
    }
    .file_url_box {
      display: flex;
    }
    .file_url_box > img {
      width: 100px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .place_box {
    z-index: 99;
    // width: 50%;
    width: 650px;
    height: 70%;
    background: #ffff;
    margin: auto;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    overflow-y: auto;
    .place_box_head {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.8);
      opacity: 1;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      position: relative;
      i {
        position: absolute;
        right: 20px;
      }
    }
    .place_box_info_head {
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0 30px;
      i {
        width: 6px;
        height: 6px;
        background: #3a85fc;
        border-radius: 50%;
        opacity: 1;
        margin-right: 7px;
      }
    }
    .place_box_line {
      padding: 0 110px;
      display: flex;
      height: 32px;
      margin: 20px 0;
      align-items: center;
      .place_box_line_tlt {
        width: 70px;
      }

      .place_box_line_info {
        height: 32px;
        display: flex;
        align-items: center;
        label {
          display: flex;
          align-items: center;
          margin-right: 110px;
          span:first-child {
            display: flex;
            align-items: center;
          }
        }
      }
      .el-select {
        height: 100%;
        flex: 1;
        .el-input {
          height: 100%;
          input {
            height: 100%;
          }
        }
        .el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
      .el-textarea {
        flex: 1;
        .el-textarea__inner {
          //el_input中的隐藏属性
          resize: none; //主要是这个样式
        }
      }
      .el-date-editor {
        flex: 1;
        height: 100%;
        input {
          height: 100%;
          padding-left: 15px;
        }
        .el-input__prefix {
          left: initial;
          right: 5px;
          display: flex;
          align-items: center;
          .el-input__icon {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        &.date-close {
          .el-icon-date {
            display: none;
          }
        }
      }
    }
    .place_box_bottom {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      right: 50px;
      margin-top: 40px;
      margin-bottom: 10px;
      div {
        height: 40px;
        cursor: pointer;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        border-radius: 4px;
      }
      div:first-child {
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
        color: rgba(0, 0, 0, 0.5);
      }
      div:last-child {
        background: #3a85fc;
        color: #fff;
        margin-left: 20px;
      }
    }
  }

  .add_Arrival_box {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    .add_Arrival {
      z-index: 99;
      width: 650px;
      height: 90%;
      min-width: 650px;
      background: #ffff;
      position: relative;
      margin: auto;
      margin-top: 50px;
      border-radius: 10px;
      padding-bottom: 30px;
      .add_Arrival_head_tit {
        padding: 0 30px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: rgba(0, 0, 0, 0.6);
        opacity: 1;
        display: flex;
        align-items: center;
        margin: 10px 0;
        i {
          display: block;
          width: 6px;
          height: 6px;
          background: #3a85fc;
          border-radius: 50%;
          opacity: 1;
          margin-right: 5px;
        }
      }
      .add_Arrival_box_info {
        height: 90%;
        overflow: auto;
        .add_Arrival_line {
          display: flex;
          padding: 0 110px;
          height: 32px;
          margin: 20px 0;
          align-items: center;
          .add_Arrival_line_tit {
           min-width: 70px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 19px;
            color: rgba(0, 0, 0, 0.8);
            opacity: 1;
          }
          .contract_img {
            .flow_kind_text {
              .flow_kind_text_img {
                position: relative;
                border-radius: 4px;
                margin-right: 10px;
                .el-icon-circle-close {
                  display: block;
                  position: absolute;
                  top: -5px;
                  right: -5px;
                  cursor: pointer;
                }
              }
            }
            .image_info {
              width: 100px;
              height: 100px;
              position: relative;
              display: flex;
              background: rgba(0, 0, 0, 0.1);
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
              opacity: 0.6;
              overflow: hidden;
              cursor: pointer;
              img {
                height: 30px;
                width: 30px;
              }
              span {
                margin-top: 10px;
                color: rgba(0, 0, 0, 0.3);
              }
            }

            div:first-child {
              display: flex;
              .el-upload-list {
                .el-upload-list__item {
                  width: 110px;
                  height: 110px;
                }
              }
            }
          }
          .el-autocomplete {
            flex: 1;
          }
          .el-textarea {
            flex: 1;
            .el-textarea__inner {
              //el_input中的隐藏属性
              resize: none; //主要是这个样式
            }
          }
          .el-input {
            flex: 1;
            height: 32px;
            input {
              height: 32px;
            }
            .el-input__prefix {
              .el-icon-date {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
          .el-select {
            flex: 1;
            .el-input__suffix {
              display: flex;
              align-items: center;
            }
          }
          .el-date-editor {
            flex: 1;
            height: 100%;
            input {
              height: 100%;
              padding-left: 15px;
            }
            .el-input__prefix {
              left: initial;
              right: 5px;
              display: flex;
              align-items: center;
              .el-input__icon {
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            &.date-close {
              .el-icon-date {
                display: none;
              }
            }
          }
          .add_Arrival_line_inp {
            flex: 1;
            height: 100%;
            padding-left: 15px;
            display: flex;
            align-items: center;
            border: 1px solid #ccc;
            border-radius: 4px;
          }
          .add_Arrival_line_check {
            height: 32px;
            display: flex;
            align-items: center;
            label {
              display: flex;
              align-items: center;
              margin-right: 110px;
              span:first-child {
                display: flex;
                align-items: center;
              }
            }
          }
        }
        .add_Arrival_line_add {
          margin: 0 160px 0 180px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          background: #ffffff;
          border: 1px dashed rgba(0, 0, 0, 0.2);
          opacity: 1;
          border-radius: 2px;
          i {
            margin-right: 5px;
          }
        }
        .add_Arrival_bottom {
          width: 100%;
          height: 40px;
          box-sizing: border-box;
          padding: 0 100px;
          display: flex;
          justify-content: flex-end;
          margin-top: 30px;
          div {
            width: 80px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            opacity: 1;
            cursor: pointer;
          }
          div:first-child {
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
            opacity: 1;
            color: rgba(0, 0, 0, 0.5);
          }
          div:last-child {
            background: #3a85fc;
            opacity: 1;
            color: #fff;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
</style>