import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    fullPath:''
  },
  mutations: {
    updateFullpath(state,path){
      // console.log(path);
      state.fullPath=path
    }
  },
  getters:{
    donetode:state=>{
      // console.log(state.fullPath); 
      return state.fullPath
    }
  },
  actions: {
  },
  modules: {
  }
})
