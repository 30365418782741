import request from '@/utils/axios.tool';
//获取订单列表
export const list = (data)=>{              
    return request.post(`/roleapi/order/list`,data)
}
//详情
export const detail = (data)=>{              
    return request.post(`/roleapi/order/detail`,data)
}
//增加到账
export const addOrderMore = (data)=>{              
    return request.post(`/roleapi/order/addOrderMore`,data)
}

//新增退款
export const orderRefund = (data)=>{              
    return request.post(`/roleapi/order/orderRefund`,data)
}
// 退款订单列表
export const orderManageRefundList = (data)=>{              
    return request.post(`/roleapi/order/orderManageRefundList`,data)
}


//退款申请时查询(新/老客户，退款期间类型)
export const refundQuery = (data)=>{              
    return request.post(`/roleapi/order/refundQuery`,data)
}

//修改订单下单状态
export const editOrdertype = (data)=>{
    return request.post(`/roleapi/order/editOrdertype`,data)
}

//修改订单 下单状态
export const editPlaceOrder = (data)=>{
    return request.post(`/roleapi/order/editOrdertype`,data)
}