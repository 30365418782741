
import request from '@/utils/axios.tool';

//
export const accountList = (data)=>{              
    return request.post(`/roleapi/orderData/accountList`,data)
}
//营销中心到账详情
export const detail = (data)=>{              
    return request.post(`/roleapi/orderData/detail`,data)
}

//业绩列表总到账
export const accountListMoney = (data)=>{              
    return request.post(`/roleapi/orderData/accountListMoney`,data)
}

//撤销修改--驳回软删除
export const updateRevoke = (data)=>{              
    return request.post(`/roleapi/order/updateRevoke`,data)
}