<template>
  <div class="Node_misson" style="flex: 1">
    <el-main>
        <div class="Node_misson_all">
            <div class="Node_misson_title">
                <div class="Node_misson_title_info">
                    年：
                    <el-date-picker
                    v-model="year"
                    type="year"
                    placeholder="选择年份">
                    </el-date-picker>
                </div>
                 <div class="Node_misson_title_info">
                    月：
                   <el-select v-model="month" placeholder="请选择月份">
                        <el-option
                        v-for="(item,index) in options"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="Node_misson_title_info">
                    <div class="account_serch pointer" @click="getlist(1)">查询</div>
                    <div class="account_reload pointer" @click="getlist(1,1)">重置</div>
                </div>
            </div>
            <div class="add_node_mission pointer" @click="take_shadow_show(null)" v-if="role_alldata">
                <i class="el-icon-plus"></i>
                新增周节点
            </div>
             <div class="acc_table">
                <el-table
                    :header-cell-style="{background:'#F5F5F5',color:'#606266'}"
                    :data="tableData"
                    style="width: 100%"
                    border
                    :default-sort = "{prop: 'date', order: 'descending'}"
                    >
                    <el-table-column
                    v-if="0"
                    prop="name"
                    label="序号"
                    width="50">
                    <template slot-scope="scope">
                        <div class="acc_table_company_name">{{scope.row.id}}</div>
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="name"
                    label="名称"
                    width="180">
                    <template slot-scope="scope">
                      <div class="acc_table_company_name">{{scope.row.name}}</div>
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="address"
                    label="开始时间"
                     >
                     <template slot-scope="scope">
                       <div class="acc_table_company_name">{{scope.row.start_time}}</div>
                    </template>
                    </el-table-column>
                   <el-table-column
                    prop="address"
                    label="结束时间"
                     >
                     <template slot-scope="scope">
                       <div class="acc_table_company_name">{{scope.row.end_time}}</div>
                    </template>
                    </el-table-column>
                     <el-table-column
                    prop="address"
                    label="创建时间"
                     >
                     <template slot-scope="scope">
                       <div class="acc_table_company_name">{{scope.row.created_at}}</div>
                    </template>
                    </el-table-column>
                     <el-table-column
                        prop="address"
                        label="操作"
                        v-if="role_alldata"
                     >
                     <template slot-scope="scope">
                       <div class="acc_table_company_name pointer" style="color:#3A85FC !important;" @click="take_shadow_show(scope.row)">编辑</div>
                    </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="page"
                    :page-size="10"
                    layout="total,prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
            <div class="Node_misson_shadow" v-if="shadow_show" @click.stop="shadow_noshow">
                <div class="Node_shadow" @click.stop="shadow_show=true">
                    <div class="Node_shadow_title">
                       {{is_add? '新增':'设置'}}周节点
                        <i class="el-icon-close pointer" @click.stop="shadow_show=false" style="position: absolute;right: 10px;color: rgb(153, 153, 153);"></i>
                    </div>
                    <div class="info">
                        <div class="info_i">
                            <div style="margin-bottom:10px">名称</div>
                            <el-input v-model="Node_name" placeholder="请输入节点名称" maxlength="20"></el-input>
                        </div>
                        <div class="info_i">
                        <div style="margin-bottom:10px">周期时间</div>
                        <el-date-picker
                        v-model="time"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                        </el-date-picker>
                        </div>
                        <div style="display:flex;justify-content:flex-end;margin-top:40px">
                            <div class="info_last pointer" @click="getNode">确定</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-main>
  </div>
</template>

<script>
import {
    lists,
    save
} from "../../api/User/node_mission";
export default {
     data(){
        return{
            year:'',
            month:'',
            tableData:[],
            total:0,
            page:1,
            options:[],
            shadow_show:false,
            is_add:false,
            time:[],
            Node_name:'',
            item:'',
            role_alldata:false,
            role_wt:{

            }
        }
    },
    created(){
        let role_alldata=JSON.parse(localStorage.getItem('token')).role_permissions;
        let strobj={}
         for(let i in role_alldata){
            if(role_alldata[i].permissions=='Finance'){
                strobj=role_alldata[i]
            }
        }
         let str = "";
        //  console.log(strobj);
        for(let i in strobj.data){
            if (strobj.data[i].permissions == "mission_list_edit") {
                this.role_alldata=true
                break
            }else{
                this.role_alldata=false
            }
        }
        for(let i=1; i<=12 ;i++){
            this.options.push({'label':i+'月','value':i})
        }
      this.getlist();
    },
    methods:{
        getlist(is_serch,is_reload){
            if(is_serch){
                this.page=1
            }
            if(is_reload){
                this.year='';
                this.month=''
                this.page=1
            }
            let {year,month}=this
            if(year){
                year=this.getyear(year)
            }
            let role_alldata=JSON.parse(localStorage.getItem('token')).role_permissions;
            let strobj={}
            for(let i in role_alldata){
                if(role_alldata[i].permissions=='Finance'){
                    strobj=role_alldata[i]
                }
            }
            let str = "";
            for(let i in strobj.data){
                if (strobj.data[i].permissions == "mission_list") {
                    str = strobj.data[i].permissions_id_string;
                }
            }
            lists({
                year,
                month,
                page:this.page,
                permissions_id_string: str,
            }).then(res=>{
                // console.log(res);
                this.tableData=res.data.data.data;
                this.total=res.data.data.total;
            })
        },
        take_shadow_show(item){
            if(item){
                this.is_add=false;
                this.item=item;
                this.Node_name=item.name;
                this.time[0]=item.start_time;
                this.time[1]=item.end_time
            }else{
                this.is_add=true
                this.item='';
                this.Node_name='';
                this.time=[];
            }
            this.shadow_show=true;
        },
        getNode(){
            if(this.Node_name==''){
              this.$message({
                type: "error",
                message: '请输入节点名称',
              });
              return
            }
            if(this.time==''){
              this.$message({
                type: "error",
                message: '请选择时间周期',
              });
              return
            };
            let start_time=this.get_Node(this.time[0]);
            let end_time=this.get_Node(this.time[1]);
            if(this.is_add){
                let role_alldata=JSON.parse(localStorage.getItem('token')).role_permissions;
                let strobj={}
                for(let i in role_alldata){
                    if(role_alldata[i].permissions=='Finance'){
                        strobj=role_alldata[i]
                    }
                }
                let str = "";
                for(let i in strobj.data){
                    if (strobj.data[i].permissions == "mission_list_edit") {
                        str = strobj.data[i].permissions_id_string;
                    }
                }
                save({
                    start_time,
                    end_time,
                    name:this.Node_name,
                    permissions_id_string: str,
                }).then(res=>{
                    if(res.data.code==200){
                        this.$message({
                            type: "success",
                            message: '添加成功',
                        });
                        this.shadow_show=false;
                        this.getlist()
                    }
                })
            }else{
                 let role_alldata=JSON.parse(localStorage.getItem('token')).role_permissions;
                let strobj={}
                for(let i in role_alldata){
                    if(role_alldata[i].permissions=='Finance'){
                        strobj=role_alldata[i]
                    }
                }
                let str = "";
                for(let i in strobj.data){
                    if (strobj.data[i].permissions == "mission_list_edit") {
                        str = strobj.data[i].permissions_id_string;
                    }
                }
                save({
                    start_time,
                    end_time,
                    name:this.Node_name,
                    task_make_note_id:this.item.id,
                    permissions_id_string: str,
                }).then(res=>{
                    if(res.data.code==200){
                        this.$message({
                            type: "success",
                            message: '编辑成功',
                        });
                        this.shadow_show=false;
                        this.getlist()
                    }
                })
            }
        },
        handleSizeChange(e){
        },
        handleCurrentChange(e){
            this.getlist()
        },
        shadow_noshow(){
            this.shadow_show=false
        },
        getyear(timestamp) {
            var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear();
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
            let strDate = Y;
            return strDate;
        },
        get_Node(timestamp) {
            var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
            var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
            let strDate = Y+M+D;
            return strDate;
        },
    }
}
</script>

<style lang="scss">
.Node_misson_all {
  // min-width: 1200px;
  width:100%;
  // margin: 0 auto;
  // margin-top: -11px;
  border: 1px solid #dedede;
  overflow: hidden;
  height: 800px;
  box-sizing: border-box;
  padding:31px 51px;
  padding-bottom: 0;
  background: #fff;
    .Node_misson_title{
        display: flex;
        align-items: center;
        .Node_misson_title_info{
                // display: flex;
                float: left;
                width: 300px;
                white-space:nowrap;
                align-items: center;
                margin-left: 3%;
                .el-date-editor.el-input, .el-date-editor.el-input__inner{
                    width: 280px;
                }
                
                .el-input__inner {
                    height: 32px;
                    line-height:32px;
                    
                }
                .el-input__icon {
                      
                        line-height: 32px;
                    }
                .account_serch{
                    margin-left: 20px;

                     float: left;
                    width: 70px;
                    height: 32px;
                    background: #3A85FC;
                    opacity: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #FFFFFF;
                    border-radius: 2px;
                }
                .account_reload{
                     float: left;
                    width: 70px;
                    height: 32px;
                    background: #FFFFFF;
                    border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
                    opacity: 1;
                    border-radius: 2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 20px;
                    color: #666;
                }
        }
        .Node_misson_title_info:nth-of-type(1){
            margin-left: 0%;
        }
    }
    .add_node_mission{
        i{
            margin-right: 3px;
        }
        background: #3A85FC;
        color: #fff;
        display: flex;
        border-radius: 4px;
        width: 122px;
        height: 35px;
        align-items: center;
        margin-top: 31px;
        margin-left: 13px;
        justify-content: center;
    }
    .acc_table{
        margin-top: 21px;
        .el-pagination{
                margin-top: 10px;
                margin-top: 10px;
                display: flex;
                justify-content: flex-end;
            }
    }
    .Node_misson_shadow{
        width: 100%;
        height: 100%;
        top: 0;
        position: fixed;
        background: rgba(0,0,0,.5);
        left: 0;
        z-index: 2000;
        .Node_shadow{
            width: 445px;
            height: 309px;
            background: #FFFFFF;
            border: 1px solid #707070;
            opacity: 1;
            border-radius: 10px;
            margin: 150px auto;
            position: relative;
            .Node_shadow_title{
                display: flex;
                align-items: center;
                justify-content: center;
                color: #333;
                position: relative;
                height: 40px;
                border-bottom: 1px solid #ccc;
            }
            .info{
                padding: 20px;
                .info_i{
                    margin-top: 10px;
                    .el-input__inner{
                        width: 380px;
                    }
                }
                .info_i:nth-of-type(1){
                    margin-top: 0;
                }
                .info_last{
                    width: 80px;
                    height: 40px;
                    background: #3A85FC;
                    display: flex;
                    
                    justify-content: center;
                    align-items: center;
                    opacity: 1;
                    border-radius: 4px;
                    color: #fff;
                }
            }
        }
    }
  }
</style>