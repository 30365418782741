<template>
  <!-- 我的业绩 -->
  <div class="all_myachi">
    <div class="all_myachi_tit">
      {{ position_type == 1 ? "我的" : "团队" }}业绩
    </div>
    <div class="all_myachi_tab">
      <div
        class="all_myachi_tab_one"
        v-for="(item, index) in tab_arr"
        :key="index"
        @click="checktab(item.id)"
      >
        <div :class="tab_check == item.id ? 'check_tit' : ''">
          {{ item.value }}
        </div>
        <div :class="tab_check == item.id ? 'check_line' : ''"></div>
      </div>
    </div>
    <div class="all_achi_box">
      <div class="yjje" v-if="tab_check == 1">
        <div class="yjje_box">
          <img src="../../assets/img/all_achi_toady.png" alt="" />
          <div>
            <div>{{ one_tab.today_sum_money }}</div>
            <div>今日到账</div>
          </div>
        </div>
        <div class="yjje_box">
          <img src="../../assets/img/all_achi_week.png" alt="" />
          <div>
            <div>{{ one_tab.make_note_sum_money }}</div>
            <div>本周到账</div>
          </div>
        </div>
        <div class="yjje_box" v-if="position_type==11">
          <img src="../../assets/img/all_achi_month.png" alt="" />
          <div>
            <div>{{ one_tab.month_sum_money }}</div>
            <div>本月到账</div>
          </div>
        </div>
        <div class="yjje_box">
          <img src="../../assets/img/all_achi_monthauto.png" alt="" />
          <div>
            <div>{{ one_tab.month_mean }}</div>
            <div>当月单均</div>
          </div>
        </div>
        <div class="yjje_box">
          <img src="../../assets/img/all_achi_dayauto.png" alt="" />
          <div>
            <div>{{ one_tab.day_mean }}</div>
            <div>日均到账</div>
          </div>
        </div>
        <div class="yjje_box" v-if="position_type>1">
          <img src="../../assets/img/all_achi_peo.png" alt="" />
          <div>
            <div>{{ one_tab.people_mean }}</div>
            <div>人均到账</div>
          </div>
        </div>
        <div class="yjje_box" v-if="position_type>1">
          <img src="../../assets/img/all_achi_dep.png" alt="" />
          <div>
            <div>{{ one_tab.company_mean }}</div>
            <div>部均到账</div>
          </div>
        </div>
      </div>
      <div class="pmmc" v-if="tab_check == 2">
        <div class="pmmc_box" v-if="two_tab.three">
          <div>第{{ two_tab.three.ranking }}名</div>
          <div>事业线({{ two_tab.three.count }})</div>
        </div>
        <div class="pmmc_box" v-if="two_tab.two">
          <div>第{{ two_tab.two.ranking }}名</div>
          <div>事业部({{ two_tab.two.count }})</div>
        </div>
        <div class="pmmc_box" v-if="two_tab.one">
          <div>第{{ two_tab.one.ranking }}名</div>
          <div>战区({{ two_tab.one.count }})</div>
        </div>
        <div
          v-if="two_tab.length == 0"
          style="width: 100%; text-align: center; color: #ccc"
        >
          暂无排名
        </div>
      </div>
      <div class="yxkh" v-if="tab_check == 3">
        <div class="yxkh_box">
          <img src="../../assets/img/all_yxkh_one.png" alt="" />
          <div>
            <div>{{ three_tab.today_count }}</div>
            <div>今日(个)</div>
          </div>
        </div>
        <div class="yxkh_box">
          <img src="../../assets/img/all_yxkh_two.png" alt="" />
          <div>
            <div>{{ three_tab.week_count }}</div>
            <div>本周(个)</div>
          </div>
        </div>
        <div class="yxkh_box">
          <img src="../../assets/img/all_yxkh_three.png" alt="" />
          <div>
            <div>{{ three_tab.month_count }}</div>
            <div>本月(个)</div>
          </div>
        </div>
        <div class="yxkh_box">
          <img src="@/assets/img/all_yxkh_four.png" alt="" />
          <div>
            <div>{{ three_tab.daily }}</div>
            <div>日均(个)</div>
          </div>
        </div>
      </div>
      <div class="yxkh" v-if="tab_check == 4">
        <div class="yxkh_box">
          <img src="../../assets/img/all_yxkh_one.png" alt="" />
          <div>
            <div>{{ four_tab.day_count }}</div>
            <div>今日(个)</div>
          </div>
        </div>
        <div class="yxkh_box">
          <img src="../../assets/img/all_yxkh_two.png" alt="" />
          <div>
            <div>{{ four_tab.note_count }}</div>
            <div>本周(个)</div>
          </div>
        </div>
        <div class="yxkh_box">
          <img src="../../assets/img/all_yxkh_three.png" alt="" />
          <div>
            <div>{{ four_tab.month_count }}</div>
            <div>本月(个)</div>
          </div>
        </div>
        <div class="yxkh_box">
          <img src="@/assets/img/all_yxkh_four.png" alt="" />
          <div>
            <div>{{ four_tab.daily }}</div>
            <div>日均(个)</div>
          </div>
        </div>
      </div>
      <div class="yxkh" v-if="tab_check == 5">
        <div class="yxkh_box">
          <img src="../../assets/img/all_yxkh_one.png" alt="" />
          <div>
            <div>{{ five_tab.day_count }}</div>
            <div>今日(个)</div>
          </div>
        </div>
        <div class="yxkh_box">
          <img src="../../assets/img/all_yxkh_two.png" alt="" />
          <div>
            <div>{{ five_tab.node_count }}</div>
            <div>本周(个)</div>
          </div>
        </div>
        <div class="yxkh_box">
          <img src="../../assets/img/all_yxkh_three.png" alt="" />
          <div>
            <div>{{ five_tab.month_count }}</div>
            <div>本月(个)</div>
          </div>
        </div>
        <div class="yxkh_box">
          <img src="@/assets/img/all_yxkh_four.png" alt="" />
          <div>
            <div>{{ five_tab.daily }}</div>
            <div>日均(个)</div>
          </div>
        </div>
      </div>
      <div class="khzh" v-show="tab_check == 6">
        <div class="khzh_box">
          <div class="khzh_box_one">
            <div>意向客户数</div>
            <div>{{ funnel_data.customer_count }}</div>
          </div>
          <div class="khzh_box_one">
            <div>A类客户数</div>
            <div>{{ funnel_data.a_count_customer }}</div>
          </div>
          <div class="khzh_box_one">
            <div>面访量</div>
            <div>{{ funnel_data.call_on_count }}</div>
          </div>
          <div class="khzh_box_one">
            <div>到账数</div>
            <div>{{ funnel_data.order_count }}</div>
          </div>
        </div>
        <!-- <img class="myfunnel_img" src="../../assets/img/my_funnel.png" alt=""> -->
        <div id="myfunnel" class="myfunnel" v-show="tab_check == 6"></div>
        <div class="khzh_box_r">
          <div class="khzh_box_one">
            <div>对比率</div>
            <div>
              {{
                funnel_data.order_count > 0
                  ? funnel_data.bi_array.customer_count_bi
                  : 0
              }}
            </div>
          </div>
          <div class="khzh_box_one">
            <div>对比率</div>
            <div>
              {{
                funnel_data.order_count > 0
                  ? funnel_data.bi_array.a_count_customer_bi
                  : 0
              }}
            </div>
          </div>
          <div class="khzh_box_one">
            <div>对比率</div>
            <div>
              {{
                funnel_data.order_count > 0
                  ? funnel_data.bi_array.call_on_count_bi
                  : 0
              }}
            </div>
          </div>
          <div class="khzh_box_one">
            <div>对比率</div>
            <div>1</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  performance,
  ranking,
  myCustomerNumber,
  myCustomerClinch,
  myCustomerCallOn,
  funnel,
} from "../../api/User/home";
import { seeDepth } from "../../api/User/data";
export default {
  data() {
    return {
      tab_check: "1",
      tab_arr: [
        { id: "1", value: "业绩分析" },
        { id: "2", value: "排名名次" },
        { id: "3", value: "A类意向客户数" },
        { id: "4", value: "成交客户数" },
        { id: "5", value: "面访量" },
        { id: "6", value: "客户转换率" },
      ],
      one_tab: {}, //yjje
      two_tab: {},
      three_tab: {},
      four_tab: {},
      five_tab: {},
      funnel_data: {
        bi_array: {
          customer_count_bi: 0,
        },
      },
      position_type: 1,
    };
  },
  created() {
    if (document.getElementById("myfunnel")) {
      setTimeout(() => {
        this.getfunnel();
      }, 100);
    }
    this.getperformance();
    if (localStorage.getItem("token")) {
      let token = JSON.parse(localStorage.getItem("token"));
      this.position_type = token.position_type;
    }
  },
  mounted() {
    if (document.getElementById("myfunnel")) {
      this.getfunnel();
      window.onresize = () => {
        this.getfunnel();
      };
    }
  },
  methods: {
    checktab(i) {
      this.tab_check = i;
      if (i == 1) {
        this.getperformance();
      }
      if (i == 2) {
        ranking().then((res) => {
          if (res.data.code == 200) {
            this.two_tab = res.data.data;
          }
        });
      }
      if (i == 3) {
        myCustomerNumber().then((res) => {
          if (res.data.code == 200) {
            this.three_tab = res.data.data;
          }
        });
      }
      if (i == 4) {
        myCustomerClinch().then((res) => {
          if (res.data.code == 200) {
            this.four_tab = res.data.data;
          }
        });
      }
      if (i == 5) {
        myCustomerCallOn().then((res) => {
          if (res.data.code == 200) {
            this.five_tab = res.data.data;
          }
        });
      }
      if (i == 6) {
        funnel().then((res) => {
          if (res.data.code == 200) {
            this.funnel_data = res.data.data;
          }
        });
        setTimeout(() => {
          this.getfunnel();
        }, 100);
      }
    },
    getfunnel() {
      let myfunnel = this.$echarts.init(document.getElementById("myfunnel"));
      myfunnel.resize();
      myfunnel.setOption({
        legend: {
          data: ["意向客户数", "A类客户数", "面访量", "到账数"],
          icon: "circle",
          orient: "horizontal",
          bottom: "30",
          width: "auto",
          textStyle: {
            fontSize: "10",
          },
        },
        color: ["#5684F1", "#F9BE5A", "#3AA1FF", "#3AC563"],
        series: [
          {
            type: "funnel",
            left: "0%",
            top: 0,
            bottom: 80,
            width: "100%",
            min: 0,
            max: 100,
            minSize: "0%",
            maxSize: "100%",
            sort: "descending",
            gap: 2,
            label: {
              show: true,
              position: "inside",
            },
            labelLine: {
              length: 10,
              lineStyle: {
                width: 1,
                type: "solid",
              },
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1,
            },
            emphasis: {
              label: {
                fontSize: 20,
              },
            },
            data: [
              { value: 25, name: "到账数" },
              { value: 50, name: "面访量" },
              { value: 75, name: "A类客户数" },
              { value: 100, name: "意向客户数" },
            ],
          },
        ],
      });
    },
    getperformance() {
      performance().then((res) => {
        if (res.data.code == 200) {
          this.one_tab = res.data.data;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.all_myachi {
  width: 100%;
  height: 100%;
  padding-top: 0;
  .all_myachi_tit {
    font-size: 18px;
    font-weight: bold;
    line-height: 31px;
    color: rgba(0, 0, 0, 0.8) !important;
    padding-left: 26px;
    height: 51px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .all_myachi_tab {
    padding: 0 15px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 20px;
    .all_myachi_tab_one {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      height: 30px;
      div {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        opacity: 1;
        color: rgba(0, 0, 0, 0.4);
      }
      div:last-child {
        width: 80%;
        height: 2px;
        background: #fff;
      }
      .check_tit {
        color: #3a85fc;
      }
      .check_line {
        background: #3a85fc !important;
      }
    }
  }
  .all_achi_box {
    .yjje {
      display: flex;
      flex-wrap: wrap;
      padding: 0 20px;
      .yjje_box {
        display: flex;
        align-items: center;
        width: 31%;
        height: 87px;
        background: #ffffff;
        border: 1px solid #f2f2f2;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
        opacity: 1;
        border-radius: 4px;
        margin-right: 2%;
        margin-bottom: 25px;
        min-width: 124px;
        img {
          width: 34px;
          height: 34px;
          margin-left: 15px;
          margin-right: 5px;
        }
        div {
          div {
            font-size: 14px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.8);
            opacity: 1;
          }
          div:first-child {
            font-weight: bold;
          }
        }
      }
    }
    .yxkh {
      display: flex;
      flex-wrap: wrap;
      padding: 0 20px;
      .yxkh_box {
        width: 45%;
        height: 87px;
        background: #ffffff;
        border: 1px solid #f2f2f2;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
        opacity: 1;
        border-radius: 4px;
        margin: 0 2%;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        img {
          width: 34px;
          height: 34px;
          margin-left: 40px;
          margin-right: 20px;
        }
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          div {
            font-size: 14px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.8);
            opacity: 1;
          }
          div:first-child {
            font-weight: bold;
          }
        }
      }
    }
    .pmmc {
      padding: 0 30px;
      .pmmc_box {
        width: 100%;
        height: 84px;
        border: 1px solid #e3e9fb;
        border-radius: 2px;
        background: url("../../assets/img/pmmc_bg.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 10px;
        div:first-child {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.6);
          opacity: 1;
        }
        div:first-child {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          line-height: 27px;
          color: rgba(0, 0, 0, 0.8);
          opacity: 1;
        }
      }
    }
    .khzh {
      width: 90%;
      height: 300px;
      padding: 0 10px;
      margin: 0 auto;
      display: flex;
      .khzh_box {
        height: 80%;
        .khzh_box_one {
          width: 60px;
          display: flex;
          height: 55px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.8);
          opacity: 1;
        }
      }
      .khzh_box_r {
        height: 80%;
        .khzh_box_one {
          width: 60px;
          display: flex;
          height: 55px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.8);
          opacity: 1;
        }
      }
      .myfunnel {
        width: 100%;
        height: 300px;
        div:first-child {
          width: 100% !important;
          // canvas{
          //   width: 100% !important;
          // }
        }
      }
    }
  }
}
</style>