//审核中心

import request from '@/utils/axios.tool';
// 促销审核列表
export const promotionAuditList = (data)=>{              
    return request.post(`/roleapi/Promotion/promotionAuditList`,data)
}
// 促销审核操作
export const auditOperation = (data)=>{              
    return request.post(`/roleapi/Promotion/auditOperation`,data)
}

//提交退款
export const orderRefund = (data)=>{              
    return request.post(`/roleapi/order/orderRefund`,data)
}
//审核中心退款审核列表
export const orderRefundList = (data)=>{              
    return request.post(`/roleapi/order/orderRefundList`,data)
}
//退款审核
export const orderRefundUpdate = (data)=>{              
    return request.post(`/roleapi/order/orderRefundUpdate`,data)
}

//退款业绩时间编辑
export const editAchievementTime = (data)=>{              
    return request.post(`/roleapi/order/editAchievementTime`,data)
}