<template>
  <el-dialog title="选择客户标签" append-to-body :visible.sync="dialogVisible" width="30%">
    <div v-for="(item, index) in tagList" :key="index">
      <div class="title">{{ item.title }}</div>
      <div class="button">
        <el-button
          v-for="(temp, num) in item.children"
          :key="num"
          :type="checkIdList.includes(temp.id) ? 'primary' : ''"
          size="mini"
          @click="pushTagId(temp, index)"
          >{{ temp.title }}</el-button
        >
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="update">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    tagList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      checkIdList: [],
      chiedkList: [],
    };
  },
  methods: {
    showTags(checklist) {
      this.dialogVisible = true;
      if (checklist) {
        checklist = checklist.split(",");
        let arr = [];
        for (let i in checklist) {
          checklist[i] = ~~checklist[i];
          for (let j in this.tagList) {
            for (let k in this.tagList[j]["children"]) {
              if (this.tagList[j]["children"][k]["id"] == checklist[i]) {
                arr.push(this.tagList[j]["children"][k]);
                continue;
              }
            }
          }
        }
        this.$set(this, "checkIdList", checklist);
        this.$set(this, "chiedkList", arr);
      } else {
        this.checkIdList = [];
        this.chiedkList = [];
      }
    },
    pushTagId(temp, index) {
      if (this.checkIdList.includes(temp.id)) {
        let a = [...this.checkIdList];
        a[index] = null;
        this.$set(this, "checkIdList", a);
        a = [...this.chiedkList];
        a[index] = null;
        this.$set(this, "chiedkList", a);
        return;
      }
      let a = [...this.checkIdList];
      a[index] = temp.id;
      this.$set(this, "checkIdList", a);
      a = [...this.chiedkList];
      a[index] = temp;
      this.$set(this, "chiedkList", a);
    },
    update() {
      this.dialogVisible = false;
      let arr = this.chiedkList.filter((e) => {
        return e && e;
      });
      this.$emit("confirm", arr);
    },
  },
};
</script>
