<template>
  <div style="flex: 1" class="edit_explain">
    <el-main>
      <div class="position_control_all">
        <div class="position_left">
          <div class="position_title">
            <div
              class="add_kind pointer"
              @click="show_draw(1, 1)"
              v-if="jurisdiction.system_explain_edit"
            >
              <i class="el-icon-plus" style="margin-right: 5px"></i>
              一级菜单
            </div>
            <div class="position_title_sort">操作</div>
          </div>
          <div class="position_item">
            <div
              class="position_item_info pointer"
              :class="[check_act == item.id ? 'check_act' : '']"
              v-for="(item, index) in left_list"
              :key="index"
              @click="check_kind(item.id)"
            >
              <div class="type">{{ item.name }}</div>
              <div class="setting">
                <div>
                  <i
                    v-if="index != 0 && jurisdiction.system_explain_edit"
                    class="el-icon-top"
                    @click.stop="left_up(item.id, index)"
                  ></i>
                  <i
                    v-if="
                      index !== left_list.length - 1 &&
                      jurisdiction.system_explain_edit
                    "
                    class="el-icon-bottom"
                    @click.stop="left_down(item.id, index)"
                  ></i
                  ><i
                    class="el-icon-setting"
                    @click="show_draw(1, 0, item)"
                    v-if="jurisdiction.system_explain_edit"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="position_right">
          <div class="right_title">
            <div
              class="title_right"
              @click="show_draw(2, 1)"
              v-if="jurisdiction.system_explain_edit"
            >
              <div class="pointer">
                <i class="el-icon-plus" style="margin-right: 5px"></i>二级菜单
              </div>
            </div>
          </div>
          <div class="right_body">
            <el-table :data="tableData" height="250" border style="width: 100%">
              <el-table-column prop="index" label="序号" width="50">
                <template slot-scope="scope">
                  <div>{{ scope.row.order_by * 1 }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="二级菜单"> </el-table-column>
              <el-table-column prop="created_at" label="创建时间">
              </el-table-column>
              <el-table-column prop="address" width="160" label="操作">
                <template slot-scope="scope">
                  <div class="position_change">
                    <div
                      class="pointer"
                      @click="show_draw(2, 0, scope.row)"
                      v-if="jurisdiction.system_explain_edit"
                    >
                      编辑
                    </div>
                    <div
                      class="pointer"
                      @click="right_up(scope.row)"
                      v-if="
                        scope.row.index != 0 && jurisdiction.system_explain_edit
                      "
                    >
                      上移
                    </div>
                    <div
                      class="pointer"
                      @click="right_down(scope.row)"
                      v-if="
                        scope.row.index != tableData.length - 1 &&
                        jurisdiction.system_explain_edit
                      "
                    >
                      下移
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <el-drawer
          :visible.sync="drawer"
          :with-header="false"
          size="80%"
          class="human_drawer"
        >
          <!-- 新增一级菜单 -->
          <div class="drawer_all" v-if="drawer_type == 1">
            <div class="drawer_title">
              {{ is_add ? "添加" : "编辑" }}一级菜单
               <i class="el-icon-close" @click="drawer = false"></i>
            </div>
            <div class="drawer_compony_info">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">一级菜单名称</div>
              <el-input
                type="text"
                placeholder="请输入一级菜单名称"
                v-model="truename"
                maxlength="15"
                show-word-limit
              />
            </div>
            <div class="drawer_compony_info" v-if="!is_add">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">详情编辑</div>
            </div>
            <Wang
              v-if="drawer && !is_add"
              v-model="edit_detail"
              :isClear="isClear"
              @change="change"
            />
            <div class="drawer_bottom">
              <div
                class="pointer"
                @click="check_detail"
                :class="[add_flag ? 'human_nosent' : '']"
              >
                确定
              </div>
              <div
                class="pointer"
                style="
                  color: #ff4141;
                  background: #ffffff;
                  border: 1px solid #ff4141;
                "
                @click="del_compony"
              >
                删除
              </div>
              <div
                class="pointer"
                style="
                  border: 1px solid #c3c6ce;
                  background: #ffffff;
                  color: #414141;
                "
                @click="
                  drawer = false;
                  edit_detail = '';
                "
              >
                取消
              </div>
            </div>
          </div>
          <!-- 新增二级 -->
          <div class="drawer_all" v-if="drawer_type == 2">
            <div class="drawer_title">
              {{ is_add ? "添加" : "编辑" }}二级菜单
              <i class="el-icon-close" @click="drawer = false"></i>
            </div>
            <div class="drawer_compony_info" v-if="is_add">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">上级菜单</div>
              <el-select
                v-model="kind_id"
                :disabled="!is_add"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in kind_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="drawer_compony_info">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">二级菜单名称</div>
              <el-input
                type="text"
                placeholder="请输入二级菜单名称"
                v-model="truename"
                maxlength="15"
                show-word-limit
              />
            </div>
            <div class="drawer_compony_info" v-if="!is_add">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">详情编辑</div>
            </div>
            <Wang
              v-if="drawer && !is_add"
              v-model="edit_detail"
              :isClear="isClear"
              @change="change"
            />
            <div class="drawer_bottom">
              <div
                class="pointer"
                @click="check_detail"
                :class="[add_flag ? 'human_nosent' : '']"
              >
                确定
              </div>
              <div
                class="pointer"
                style="
                  color: #ff4141;
                  background: #ffffff;
                  border: 1px solid #ff4141;
                "
                @click="deldel"
              >
                删除
              </div>
              <div
                class="pointer"
                style="
                  border: 1px solid #c3c6ce;
                  background: #ffffff;
                  color: #414141;
                "
                @click="drawer = false"
              >
                取消
              </div>
            </div>
          </div>
        </el-drawer>
      </div>
    </el-main>
  </div>
</template>
<script>
import wang_explain from "../../views/wang_explain";
import {
  oneLevel,
  list,
  add,
  edit,
  detail,
  del,
  setOrderBy,
} from "../../api/User/explain";
export default {
  components: {
    Wang: wang_explain,
  },
  data() {
    return {
      check_act: null,
      drawer: false,
      drawer_type: "",
      left_list: [],
      tableData: [],
      left_total: "",
      right_list: [],
      is_change: false,
      is_add: false,
      add_flag: false,
      truename: "",
      product_id: "",
      kind_list: [],
      kind_id: "",
      //职位
      position: "",
      jurisdiction: {
        system_explain_edit: true,
        system_explain_edit_str: "",
      },
      position_id: "", //编辑职位id
      isClear: false,
      edit_detail: "",
      Role_list: [],
      edit:false
    };
  },
  created() {
    let roleList = JSON.parse(localStorage.getItem("user")).role_permissions; //权限列表
    for (let i in roleList) {
      if (roleList[i].permissions == "system") {
        this.role_alldata = roleList[i];
      }
    }
    roleList.forEach((item) => {
      if (item.data&&item.data.length > 0) {
        item.data.forEach((itm) => {
          if (itm.permissions == "system_explain_edit") {
            this.jurisdiction.system_explain_edit = true;
            this.jurisdiction.system_explain_edit_str =
              itm.permissions_id_string;
          }
        });
      }
    });
    console.log(this.jurisdiction.system_explain_edit_str)
    oneLevel().then((res) => {
      if (res.data.code == 200) {
        this.kind_list = res.data.data;
        this.left_list = res.data.data;
        this.kind_id = res.data.data[0] ? res.data.data[0].id : "";
        this.check_kind(res.data.data[0].id);
      }
    });
  },
  methods: {
    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // let strDate = Y + M + D;
      let strDate = Y + M + D + h + m + "00";
      return strDate;
    },

    change(val) {
      //   console.log(val);
      this.edit_detail = val;
    },
    // i 是否添加分类 is_add是否添加 item内容
    async show_draw(i, is_add, item) {
      this.edit_detail = "";
      this.position_id = "";
      this.truename = "";
      this.role = [];
      if (is_add) {
        this.is_add = true;
      } else {
        this.is_add = false;
      }
      if (i == 1) {
        if (item) {
          let res = await detail({
            explain_grade_id: item.id,
            permissions_id_string: this.jurisdiction.system_explain_edit_str,
          });
          if (res.data.code == 200) {
            this.edit_detail = res.data.data.explain
              ? res.data.data.explain.explain
              : "";
          }
          this.truename = item.name;
        }
      } else {
        if (item) {
          let res = await detail({
            explain_grade_id: item.id,
            permissions_id_string: this.jurisdiction.system_explain_edit_str,
          });
          if (res.data.code == 200) {
            this.edit_detail = res.data.data.explain
              ? res.data.data.explain.explain
              : "";
          }
          this.truename = item.name;
          this.position_id = item.id;
          this.kind_id = item.parent_id == 0 ? "" : item.parent_id * 1;
        }
      }
      this.drawer_type = i;
      this.drawer = true;
    },
    check_detail() {
      if (this.add_flag) {
        return;
      }
      this.add_flag = true;
      if (this.is_add) {
        if (this.drawer_type == 1) {
          //新增一级菜单
          if (this.truename == "") {
            this.$message({
              type: "error",
              message: "一级菜单名字不能为空",
            });
            this.add_flag = false;
            return;
          }
          add({
            name: this.truename,
            permissions_id_string: this.jurisdiction.system_explain_edit_str,
          }).then((res) => {
            this.add_flag = false;
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: res.data.message,
              });
              this.drawer = false;
              oneLevel().then((ress) => {
                this.kind_list = ress.data.data;
                this.left_list = ress.data.data;
              });
            }
          });
        } else {
          //新增二级
          let data = {
            permissions_id_string: this.jurisdiction.system_explain_edit_str,
            name: this.truename,
            parent_id: this.kind_id,
            depth: 1,
          };
          for (const i in data) {
            if (data[i] === "") {
              this.$message({
                message: "请完善信息",
                type: "error",
              });
              this.add_flag = false;
              return;
            }
          }
          add(data).then((res) => {
            this.add_flag = false;
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: res.data.message,
              });
              this.drawer = false;
              this.check_kind(this.check_act);
            }
          });
        }
        //修改
      } else {
        if (this.drawer_type == 1) {
          if (this.truename == "") {
            this.$message({
              type: "error",
              message: "一级菜单名字不能为空",
            });
            this.add_flag = false;
            return;
          }
          edit({
            permissions_id_string: this.jurisdiction.system_explain_edit_str,
            name: this.truename,
            explain_grade_id: this.check_act,
            explain: this.edit_detail,
          }).then((res) => {
            this.add_flag = false;
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: res.data.message,
              });
              this.drawer = false;
              oneLevel().then((ress) => {
                this.kind_list = ress.data.data;
                this.left_list = ress.data.data;
              });
            }
          });
        } else {
          //编辑职位
          let {} = this;
          let data = {
            permissions_id_string: this.jurisdiction.system_explain_edit_str,
            name: this.truename,
            explain_grade_id: this.position_id,
            explain: this.edit_detail,
          };
          for (const i in data) {
            if (i != "explain") {
              if (data[i] === "") {
                this.$message({
                  message: "请完善信息",
                  type: "error",
                });
                this.add_flag = false;
                return;
              }
            }
          }
          edit(data).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: res.data.message,
              });
              this.drawer = false;
              this.add_flag = false;
              this.check_kind(this.check_act);
            }
          });
        }
      }
    },
    check_kind(id) {
      //点击确定种类
      if (this.is_change) {
        return;
      }
      this.kind_id = id;
      this.is_change = true;
      this.check_act = id;
      list({
        parent_id: id,
      }).then((res) => {
        this.is_change = false;
        let data = res.data.data.list;
        for (let i in data) {
          if (!data[i].created_at) {
            data[i].created_at = "--";
          } else {
            data[i].created_at = this.getwaytime(data[i].created_at);
          }
          data[i].index = i * 1;
        }
        this.tableData = data;
      });
    },
    del_compony() {
      del({
        explain_grade_id: this.kind_id,
        permissions_id_string: this.jurisdiction.system_explain_edit_str,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.kind_id = "";
          this.drawer = false;
          oneLevel().then((res) => {
            this.kind_list = res.data.data;
            this.left_list = res.data.data;
            this.kind_id = res.data.data[0] ? res.data.data[0].id : "";
            this.check_kind(res.data.data[0].id);
          });
        }
      });
    },
    deldel() {
      del({
        explain_grade_id: this.position_id,
        permissions_id_string: this.jurisdiction.system_explain_edit_str,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.drawer = false;
          this.check_kind(this.kind_id);
        }
      });
    },
    // getdetail(data) {
    //   detail(data).then((res) => {
    //     if (res.data.code == 200) {
    //       this.edit_detail = res.data.data.explain.explain;
    //     }
    //   });
    // },
    left_up(id, index) {
      // console.log(index);
      if (this.is_change) {
        return;
      }
      this.is_change = true;
      setOrderBy({
        permissions_id_string: this.jurisdiction.system_explain_edit_str,
        old_by: this.left_list[index].order_by,
        new_by: this.left_list[index - 1].order_by,
        explain_grade_id: this.left_list[index].id,
      }).then((res) => {
        this.is_change = false;
        if (res.data.code == 200) {
          let h = this.left_list.splice(index, 1);
          this.left_list.splice(index - 1, 0, h[0]);
          this.$forceUpdate();
        }
      });
    },
    left_down(id, index) {
      if (this.is_change) {
        return;
      }
      this.is_change = true;
      setOrderBy({
        permissions_id_string: this.jurisdiction.system_explain_edit_str,
        old_by: this.left_list[index].order_by,
        new_by: this.left_list[index + 1].order_by,
        explain_grade_id: this.left_list[index].id,
      }).then((res) => {
        this.is_change = false;
        if (res.data.code == 200) {
          let h = this.left_list.splice(index, 1);
          this.left_list.splice(index + 1, 0, h[0]);
          this.$forceUpdate();
        }
      });
    },
    right_up(row) {
      if (this.is_change) {
        return;
      }
      this.is_change = true;
      let { index } = row;
      if (index == 0) {
        return;
      }
      setOrderBy({
        permissions_id_string: this.jurisdiction.system_explain_edit_str,
        old_by: this.tableData[index].order_by,
        new_by: this.tableData[index - 1].order_by,
        explain_grade_id: this.tableData[index].id,
        parent_id: this.kind_id,
      }).then((res) => {
        this.is_change = false;
        if (res.data.code == 200) {
          this.check_kind(this.check_act);
        }
      });
    },
    right_down(row) {
      if (this.is_change) {
        return;
      }
      let { index } = row;
      this.is_change = true;
      // console.log(this.kind_id);
      setOrderBy({
        permissions_id_string: this.jurisdiction.system_explain_edit_str,
        old_by: this.tableData[index].order_by,
        new_by: this.tableData[index + 1].order_by,
        explain_grade_id: this.tableData[index].id,
        parent_id: this.kind_id,
      }).then((res) => {
        this.is_change = false;
        if (res.data.code == 200) {
          this.check_kind(this.check_act);
        }
      });
    },
    right_top(row) {
      let { index } = row;
      if (this.is_change) {
        return;
      }
      setOrderBy({
        permissions_id_string: this.jurisdiction.system_pos_list_move_str,
        old_by: this.tableData[index].order_by,
        new_by: this.tableData[0].order_by,
        position_id: this.tableData[index].id,
        position_classify_id: this.kind_id,
      }).then((res) => {
        this.is_change = false;
        if (res.data.code == 200) {
          this.check_kind(this.check_act);
        }
      });
    },
    right_bottom(row) {
      let { index } = row;
      if (this.is_change) {
        return;
      }
      this.is_change = true;
      setOrderBy({
        permissions_id_string: this.jurisdiction.system_pos_list_move_str,
        old_by: this.tableData[index].order_by,
        new_by: this.tableData[this.tableData.length - 1].order_by,
        position_id: this.tableData[index].id,
      }).then((res) => {
        this.is_change = false;
        if (res.data.code == 200) {
          this.check_kind(this.check_act);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.edit_explain {
  .position_control_all {
    // min-width: 1200px;
    width: 100%;
    // margin: 0 auto;
    // margin-top: -11px;
    border: 1px solid #dedede;
    overflow: hidden;
    height: 800px;
    box-sizing: border-box;
    .position_left {
      width: 20%;
      background: #fff;
      float: left;
      border-right: 1px solid #dedede;
      // min-height: 800px;
      height: 800px;
      overflow: auto;
      box-sizing: border-box;
      .position_title {
        padding: 20px 10px 0px 10px;
        font-size: 14px;
        min-height: 54px;
        color: #333;
        box-sizing: border-box;
        padding-right: 0;

        // border-bottom: 1px solid #ccc;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        .add_kind {
          flex: 1;
          align-items: center;
          justify-content: center;
          display: flex;
          background: #409eff;
          height: 35px;
          color: #fff;
          border-radius: 5px;
        }
        .position_title_sort {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          div {
            display: flex;
            flex-direction: column;
            color: #ccc;
            justify-content: space-around;
            margin-left: 5px;
          }
        }
      }
      .position_item {
        height: 35px;
        padding: 0 10px;
        padding-top: 10px;
        box-sizing: border-box;
        height: 745px;
        overflow: auto;
        padding-right: 0;
        .position_item_info {
          width: 100%;
          border-right: 3px solid #fff;
          display: flex;
          height: 40px;
          align-items: center;
          .type {
            flex: 1;
            padding-left: 10px;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }
          .inputinfo {
            display: block;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            input {
              height: 20px;
              text-align: center;
              width: 50%;
              // padding-left: 10px;
              box-sizing: border-box;
              border: 1px solid #409eff;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none !important;
            }
            input[type="number"] {
              -moz-appearance: textfield;
            }
          }
          .setting {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-around;
            box-sizing: border-box;
            // padding: 0 10px;
            // padding-left: 30px;
            div {
              display: flex;
              width: 60%;
              justify-content: space-around;
              margin: 0 auto;
              i {
                color: #333;
              }
              i:hover {
                color: #409eff;
              }
            }
          }
        }
        .check_act {
          border-right: 3px solid #409eff;
          color: #409eff;
        }
      }
    }
    .position_right {
      // width: 938px;
      width: 80%;
      background: #fff;
      padding: 26px;
      // overflow: auto;
      height: 800px;
      box-sizing: border-box;
      float: left;
      .right_title {
        display: flex;
        height: 50px;
        align-items: center;
        .title_right {
          // flex: 1.5;
          // height: 100%;
          align-items: center;
          display: flex;
          flex: 1;
          justify-content: flex-end;
          color: #fff;
          div {
            border-radius: 5px;
            color: #fff;
            align-items: center;
            display: flex;
            padding: 0 10px;
            background: #409eff;
            height: 32px;
          }
        }
      }
      .right_body {
        .position_change {
          display: flex;
          align-items: center;
          div {
            white-space: nowrap;
            margin-left: 10px;
          }
          div:hover {
            color: #409eff;
          }
          i:hover {
            color: #409eff;
          }
        }
      }
      .right_body .el-table {
        height: 707px !important;
      }
    }
  }
  .drawer_compony_info .drawer_nice {
    width: 120px;
  }
  .drawer_compony_info{
    .el-select{
      width: 100%;
    }
  }
  .human_drawer {
    min-height: 700px;
 
    .drawer_all {
      overflow: auto;
      .drawer_title{
        display: flex;
        align-items: center;
        .el-icon-close{
          position: absolute;
          right: 40px;
          cursor: pointer;
          padding: 10px;
        }
        .el-select{
          width: 100% !important;
          .el-input{
          width: 100% !important;
        }
        }
        
      }
      .el-input{
          width: 100% !important;
        }
    }
    
  }
  .el-drawer.rtl{
    height: 80%;
  min-height: 700px;
    left: 0;
    margin: auto;
  }
 
  .el-drawer.rtl {
    animation: rtl-drawer-out 0s;
  }
  .el-drawer__open .el-drawer.rtl{
    animation: rtl-drawer-in 0s 1ms;
  }

}
</style>