<template>
  <!-- 公众图片管理 -->
  <div class="public">
    <div class="public_table" style="width: 99%">
      <el-table
        :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
        :data="tableData"
        style="width: 100%"
        border
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column label="图片">
          <template slot-scope="scope">
            <el-image
              v-for="(item, index) in scope.row.complete_img_url_arr"
              :key="index"
              style="width: 100px; height: 100px"
              :src="item"
              :preview-src-list="scope.row.complete_img_url_arr"
            >
            </el-image>
          </template>
        </el-table-column>
        <!-- <el-table-column label="展示平台" width="240">
          <template slot-scope="scope">
            {{ scope.row.date }}
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="文件名">
          <template slot-scope="scope">
            {{ scope.row.img_url.split('/')[scope.row.img_url.split('/').length-1] }}
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="文件大小">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column> -->
        <el-table-column label="格式">
          <template slot-scope="scope"> GIF </template>
        </el-table-column>
        <el-table-column label="时间">
          <template slot-scope="scope">
            {{ scope.row.created_at }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div
              style="color: #3a85fc"
              class="pointer"
              @click="del(scope.row)"
              v-if="role_what.is_del"
            >
              删除
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="10"
        el-pagination
        layout="total,prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { preludeImgList, delPreludeImg } from "../../api/User/poster";
export default {
  data() {
    return {
      tableData: [],
      total: 0,
      currentPage: 0,
      role_what: {
        is_del: false,
        del_str: "",
      },
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      let roleList = JSON.parse(localStorage.getItem("token")).role;
      roleList.forEach((item) => {
        if (item.permissions == "Poster_Public_pictures_del") {
          this.role_what.is_del = true;
          this.role_what.del_str = item.permissions_id_string;
        }
      });
    }
    this.getList();
  },
  methods: {
    getList(data) {
      preludeImgList(data).then((res) => {
        if (res.data.code == 200) {
          this.total = res.data.data.total;
          let list = res.data.data.data;
          list.forEach((item) => {
            item.complete_img_url_arr = [];
            item.complete_img_url_arr.push(item.complete_img_url);
          });
          this.tableData = list;
        }
      });
    },
    handleCurrentChange(val) {
      this.getList({
        page: val,
      });
    },
    del(row) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delPreludeImg({
            prelude_img_id: row.id,
            permissions_id_string: this.role_what.del_str,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.message,
                type: "success",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss">
.public {
  margin: 40px 16px;
  .block {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .el-table__body-wrapper {
    .cell {
      height: 100px;
      display: flex;
      align-items: center;
    }
  }
  .show_img {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    img {
      z-index: 99;
      width: 350px;
      background: #ffff;
      margin: auto;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
      overflow: hidden;
    }
  }
}
</style>