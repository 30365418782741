<template>
  <div class="position_sett">
    <el-main>
      <div class="position_seach">
        <div class="">职位：</div>
        <input type="text" v-model="position" placeholder="请输入职位名字" />
        <div class="position_seach_Button">
          <div class="position_seach_Button_one pointer" @click="handleSeach">
            查询
          </div>
          <div class="position_seach_Button_two pointer" @click="handleReset">
            重置
          </div>
        </div>
      </div>
      <div
        class="position_add pointer"
        @click="Addposition"
        v-if="jurisdiction.system_pos_list_add"
      >
        <i class="el-icon-plus" style="margin-right: 5px"></i>添加职位
      </div>
      <el-table
        v-if="jurisdiction.system_pos_list"
        :data="tableData"
        border
        :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
        style="width: 100%"
      >
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="position" label="职位名"> </el-table-column>
        <el-table-column prop="status_text" label="是否管理岗">
        </el-table-column>
        <!-- <el-table-column prop="created_at" label="状态">
                    <template slot-scope="scope">
                        <el-switch
                        v-model="scope.row.isCheck"
                        @change="handleSwitch(scope.row)"
                        active-color="#13ce66"
                        inactive-color="#ccc">
                        </el-switch>
                    </template>
                </el-table-column> -->
        <el-table-column label="创建人">
          <template slot-scope="scope">
            {{ scope.row.user ? scope.row.user.name : "----" }}
          </template>
        </el-table-column>
        <el-table-column label="角色">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.position_role" :key="index">
              {{ item.role_text }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间"> </el-table-column>
        <el-table-column width="200" label="操作">
          <template slot-scope="scope">
            <div class="cz pointer">
              <div
                v-if="jurisdiction.system_pos_list_edit"
                @click="handleDetail(scope.row)"
                class="cz_detail"
              >
                编辑
              </div>
              <!-- <div @click="handleDel(scope.row)" class="cz_del">
                        删除
                    </div> -->
              <div
                class="position_move"
                v-if="jurisdiction.system_pos_list_move"
              >
                <i class="el-icon-top" @click="handleOrderBy(scope.row, 1)"></i>
                <i
                  class="el-icon-bottom"
                  @click="handleOrderBy(scope.row, 2)"
                ></i>
                <!-- <i class="el-icon-upload2" @click="handleUPUP(scope.row)"></i> -->
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="100"
          el-pagination
          layout="total,prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-main>
    <div
      class="more_company"
      v-if="isPosition"
      style="height: 520px; width: 640px; position: fixed"
    >
      <div class="more_head" style="font-size: 16px">
        {{ isadd == 1 ? "新增职位" : "编辑职位" }}
      </div>
      <div
        class="choose_box"
        style="padding: 0 60px; height: 80px; margin-top: 30px"
      >
        <div class="add_position">
          <div>职位名</div>
          <input
            type="text"
            v-model="add_position"
            placeholder="请输入职位名"
          />
        </div>
      </div>
      <div class="choose_box" style="padding: 0 60px">
        <div class="add_position role_add_position">
          <div>{{ isadd == 1 ? "添加角色" : "编辑角色" }}</div>
          <el-select v-model="role" multiple placeholder="请选择">
            <el-option
              v-for="item in role_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        class="choose_box"
        style="padding: 0 60px; height: 80px; margin-top: 10px"
      >
        <div class="add_position">
          <div>是否为管理岗</div>
          <el-select v-model="Administration" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="choose_box" style="padding: 0 60px; height: 80px">
        <div class="add_position">
          <div>请选择岗位类型</div>
          <el-select v-model="issales" placeholder="请选择">
            <el-option
              v-for="item in sales"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- <div class="choose_box" style="padding:0 60px;height:80px;">
                <div class="add_position">
                    <div>状态</div>
                      <el-select v-model="status" placeholder="请选择">
                        <el-option
                        v-for="item in statusList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div> -->
      <div class="position_bottom">
        <div class="position_no pointer" @click="handleNo">取消</div>
        <div class="position_ok pointer" @click="handleSave">保存</div>
      </div>
    </div>
    <div class="login_mc" v-if="isPosition"></div>
  </div>
</template>

<script>
import {
  List,
  addPosition,
  editPosition,
  setOrderBy,
} from "../../api/User/position";
import { selectRole } from "../../api/User/human";
export default {
  data() {
    return {
      position: "",
      isPosition: false, //编辑新增弹窗
      Administration: "", //是否管理
      add_position: "",
      position_id: "", //编辑时候的职位id
      role_list: [],
      role: [], //角色
      options: [
        { id: "1", name: "是" },
        { id: "0", name: "否" },
      ],
      status: "", //状态
      statusList: [
        { id: "1", name: "开启" },
        { id: "2", name: "关闭" },
      ],
      sales: [
        { id: "1", name: "销售岗" },
        { id: "2", name: "职能岗" },
          { id: "3", name: "客服岗" },
      ],
      issales: "",
      currentPage: 1, //页码
      page: "", //当前页码
      total: 0,
      tableData: [],
      isadd: "", // 1 新增 2 编辑
      is_flge: false, //连点
      jurisdiction: {
        system_pos_list: false,
        system_pos_list_str: "",
        system_pos_list_add: false,
        system_pos_list_add_str: "",
        system_pos_list_edit: true,
        system_pos_list_edit_str: "",
        system_pos_list_move: false,
        system_pos_list_move_str: "",
      },
      role_alldata: {},
    };
  },
  created() {
    let roleList = JSON.parse(localStorage.getItem("user")).role_permissions; //权限列表
    let check_id = "";
    if (JSON.parse(localStorage.getItem("check_id"))) {
      check_id = JSON.parse(localStorage.getItem("check_id"));
    }
    for (let i in roleList) {
      if (roleList[i].permissions == "resources") {
        this.role_alldata = roleList[i];
      }
    }
    roleList.forEach((item) => {
      if (item.data.length > 0) {
        item.data.forEach((itm) => {
          if (itm.permissions == "system_pos_list") {
            this.jurisdiction.system_pos_list = true;
            this.jurisdiction.system_pos_list_str = itm.permissions_id_string;
          }
          if (itm.permissions == "system_pos_list_add") {
            this.jurisdiction.system_pos_list_add = true;
            this.jurisdiction.system_pos_list_add_str =
              itm.permissions_id_string;
          }
          if (itm.permissions == "system_pos_list_edit") {
            this.jurisdiction.system_pos_list_edit = true;
            this.jurisdiction.system_pos_list_edit_str =
              itm.permissions_id_string;
          }
          if (itm.permissions == "system_pos_list_move") {
            this.jurisdiction.system_pos_list_move = true;
            this.jurisdiction.system_pos_list_move_str =
              itm.permissions_id_string;
          }
        });
      }
    });
    let qwq_list = [];
    selectRole({
      permissions_id_string: this.jurisdiction.system_pos_list_str,
    }).then((res) => {
      this.role_list = res.data.data;
      qwq_list = res.data.data;
    });
    List({
      permissions_id_string: this.jurisdiction.system_pos_list_str,
    }).then((res) => {
      if (res.data.code == 200) {
        let list = res.data.data.data;
        list.forEach((item) => {
          if (item.status == 1) {
            item.status_text = "是";
          } else {
            item.status_text = "否";
          }
          item.position_role.forEach((itm) => {
            qwq_list.forEach((attr) => {
              if (itm.role_id == attr.id) {
                itm.role_text = attr.name;
              }
            });
          });
        });
        this.tableData = list;
        this.total = res.data.data.total;
      }
    });
  },
  methods: {
    handleSeach() {
      //查询
      let { position } = this;
      List({
        position,
        permissions_id_string: this.jurisdiction.system_pos_list_str,
      }).then((res) => {
        if (res.data.code == 200) {
          let list = res.data.data.data;
          list.forEach((item) => {
            if (item.status == 1) {
              item.status_text = "是";
            } else {
              item.status_text = "否";
            }
            item.position_role.forEach((itm) => {
              this.role_list.forEach((attr) => {
                if (itm.role_id == attr.id) {
                  itm.role_text = attr.name;
                }
              });
            });
          });
          this.tableData = list;
        }
      });
    },
    handleReset() {
      //重置
      this.position = "";
      List({
        permissions_id_string: this.jurisdiction.system_pos_list_str,
      }).then((res) => {
        if (res.data.code == 200) {
          let list = res.data.data.data;
          list.forEach((item) => {
            if (item.status == 1) {
              item.status_text = "是";
            } else {
              item.status_text = "否";
            }
            item.position_role.forEach((itm) => {
              this.role_list.forEach((attr) => {
                if (itm.role_id == attr.id) {
                  itm.role_text = attr.name;
                }
              });
            });
          });
          this.tableData = list;
        }
      });
    },
    Addposition() {
      //新增
      this.isPosition = true;
      this.isadd = 1;
      this.issales = "";
      this.add_position = "";
      this.Administration = "";
    },
    handleDetail(row) {
      //编辑
      let rolelist = row.position_role; //选择的角色列表
      rolelist.forEach((item) => {
        this.role.push(item.role_id);
      });
      if (row.is_sales == 1) {
        this.issales = "销售岗";
      }
      if (row.is_sales == 2) {
        this.issales = "职能岗";
      }
        if (row.is_sales == 3) {
            this.issales = "客服岗";
        }
      this.add_position = row.position;
      this.Administration = row.status_text;
      this.position_id = row.id;
      this.isPosition = true;
      this.isadd = 2;
    },
    handleDel(row) {
      //删除
      let id = row.id;
      this.$confirm("是否删除此职位, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {})
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleUPUP(row) {
      //置顶
      let old_by = row.order_by;
      let position_id = row.id;
      let data = {
        old_by,
        new_by: 1,
        position_id,
        permissions_id_string: this.jurisdiction.system_pos_list_move_str,
      };
      setOrderBy(data).then((res) => {
        if (res.data.code == 200) {
          List({
            permissions_id_string: this.jurisdiction.system_pos_list_str,
          }).then((ress) => {
            if (ress.data.code == 200) {
              let list = ress.data.data.data;
              list.forEach((item) => {
                if (item.status == 1) {
                  item.status_text = "是";
                } else {
                  item.status_text = "否";
                }
                item.position_role.forEach((itm) => {
                  this.role_list.forEach((attr) => {
                    if (itm.role_id == attr.id) {
                      itm.role_text = attr.name;
                    }
                  });
                });
              });
              this.tableData = list;
              this.total = ress.data.data.total;
            }
          });
        }
      });
    },
    handleOrderBy(row, i) {
      if (this.is_flge) {
        return;
      }
      this.is_flge = true;
      let old_by = row.order_by;
      let position_id = row.id;
      let new_by = "";
      let { tableData } = this;
      if (i == 1) {
        //上移
        new_by = old_by - 1;
      }
      if (i == 2) {
        //下移
        new_by = old_by + 1;
      }

      let data = {
        old_by,
        new_by,
        position_id,
        permissions_id_string: this.jurisdiction.system_pos_list_move_str,
      };
      setOrderBy(data).then((res) => {
        if (res.data.code == 200) {
          List({
            permissions_id_string: this.jurisdiction.system_pos_list_str,
          }).then((ress) => {
            if (ress.data.code == 200) {
              let list = ress.data.data.data;
              list.forEach((item) => {
                if (item.status == 1) {
                  item.status_text = "是";
                } else {
                  item.status_text = "否";
                }
                item.position_role.forEach((itm) => {
                  this.role_list.forEach((attr) => {
                    if (itm.role_id == attr.id) {
                      itm.role_text = attr.name;
                    }
                  });
                });
              });
              this.tableData = list;
              this.total = ress.data.data.total;
            }
          });
          this.is_flge = false;
        }
      });
    },
    handleSwitch(row) {
      //状态切换
      // console.log(row);
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    handleNo() {
      //取消编辑 新增
      this.isPosition = false;
      this.add_position = "";
      this.Administration = "";
      this.status = "";
      this.role = [];
    },
    handleSave() {
      //保存编辑 新增
      let {
        add_position,
        Administration,
        role,
        isadd,
        position_id,
        issales,
      } = this;
      if (issales == "" || add_position == "") {
        this.$message({
          message: "请完善角色信息",
          type: "error",
        });
        return;
      }
      let data = {};
      let role_id = role.join(",");
      let status = "";
      if (Administration == "1" || Administration == "是") {
        status = 1;
      }
      if (Administration == "0" || Administration == "否") {
        status = 0;
      }
      let is_sales = "";
      if (issales == "1" || issales == "销售岗") {
        is_sales = "1";
      }
      if (issales == "2" || issales == "职能岗") {
        is_sales = "2";
      }
      let add_str = "";
      let edit_str = "";
      for (let i in this.role_alldata.data) {
        if (this.role_alldata.data[i].permissions == "system_pos_list_add") {
          add_str = this.role_alldata.data[i].permissions_id_string;
        }
        if (this.role_alldata.data[i].permissions == "system_pos_list_edit") {
          edit_str = this.role_alldata.data[i].permissions_id_string;
        }
      }
      if (isadd == 1) {
        //新增
        data = {
          position: add_position,
          status,
          role_id,
          is_sales,
          permissions_id_string: add_str,
        };
        addPosition(data).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.role = [];
            this.isPosition = false;
            List({
              permissions_id_string: this.jurisdiction.system_pos_list_str,
            }).then((ress) => {
              if (ress.data.code == 200) {
                let list = ress.data.data.data;
                list.forEach((item) => {
                  if (item.status == 1) {
                    item.status_text = "是";
                  } else {
                    item.status_text = "否";
                  }
                  item.position_role.forEach((itm) => {
                    this.role_list.forEach((attr) => {
                      if (itm.role_id == attr.id) {
                        itm.role_text = attr.name;
                      }
                    });
                  });
                });
                this.tableData = list;
                this.total = ress.data.data.total;
              }
            });
          }
        });
      }
      if (isadd == 2) {
        //编辑
        data = {
          position: add_position,
          status,
          role_id,
          position_id,
          is_sales,
          permissions_id_string: edit_str,
        };
        editPosition(data).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.role = [];
            this.isPosition = false;
            List({
              permissions_id_string: this.jurisdiction.system_pos_list_str,
            }).then((ress) => {
              if (ress.data.code == 200) {
                let list = ress.data.data.data;
                list.forEach((item) => {
                  if (item.status == 1) {
                    item.status_text = "是";
                  } else {
                    item.status_text = "否";
                  }
                  item.position_role.forEach((itm) => {
                    this.role_list.forEach((attr) => {
                      if (itm.role_id == attr.id) {
                        itm.role_text = attr.name;
                      }
                    });
                  });
                });
                this.tableData = list;
                this.total = ress.data.data.total;
              }
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.position_sett {
  flex: 1;
  margin: 20px;
  .login_mc {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 98;
  }
  .el-main {
    padding: 30px 50px;
    background: #fff;
    .position_seach {
      display: flex;
      height: 32px;
      align-items: center;
      width: 100%;
      margin-bottom: 40px;
      input {
        width: 460px;
        border: 1px solid #dddddd;
        height: 32px;
        padding-left: 20px;
        border-radius: 4px;
      }
      .position_seach_Button {
        display: flex;
        margin-left: 40px;
        height: 100%;
        div {
          width: 70px;
          height: 100%;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .position_seach_Button_one {
          background: #3a85fc;
          color: #ffffff;
          border-radius: 2px;
        }
        .position_seach_Button_two {
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
          border-radius: 2px;
          color: rgba(0, 0, 0, 0.6);
          margin-left: 20px;
        }
      }
    }
    .position_add {
      width: 98px;
      height: 35px;
      background: #3a85fc;
      border-radius: 4px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 20px;
    }
    .cz {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        padding: 0 10px;
        color: #3a85fc;
      }
      .cz_detail:hover {
        color: #3a85fc;
      }
      .cz_del {
        border-left: 1px solid rgba(0, 0, 0, 0.10196078431372549);
        border-right: 1px solid rgba(0, 0, 0, 0.10196078431372549);
      }
      .cz_del:hover {
        color: #3a85fc;
      }
      .position_move {
        display: flex;
        font-size: 18px;
        i {
          color: #ccc;
        }
        i:hover {
          color: #3a85fc;
        }
      }
    }
    .block {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
    .el-table {
      .el-table__body-wrapper {
        table {
          tbody {
            tr:first-child {
              td:last-child {
                .cell {
                  .cz {
                    .position_move {
                      i:first-child {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
            tr:last-child {
              td:last-child {
                .cell {
                  .cz {
                    .position_move {
                      i:nth-child(2) {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .el-table__header-wrapper {
    table {
      width: 100% !important;
      table-layout: auto;
    }
  }
  .el-table__body-wrapper {
    table {
      width: 100% !important;
      table-layout: auto;
    }
  }
  .role_add_position {
    height: auto !important;
    .el-select {
      .el-select__tags {
        position: absolute;
        top: 60%;
      }
    }
  }
  .add_position {
    height: 60px;
    div {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.8);
      opacity: 1;
    }
    input {
      width: 100%;
      height: 38px;
      background: #ffffff;
      border: 1px solid rgba(112, 112, 112, 0.2);
      opacity: 1;
      border-radius: 4px;
      padding-left: 15px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }
  .position_bottom {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    padding-right: 60px;
    div {
      height: 40px;
      width: 80px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }
    .position_no {
      background: #ffffff;
      color: rgba(0, 0, 0, 0.5);
      border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
      margin-right: 16px;
    }
    .position_ok {
      background: #3a85fc;
      color: #fff;
    }
  }
}
</style>