//海报管理
import request from '@/utils/axios.tool';  
//海报列表
export const list = (data)=>{              
    return request.post(`/roleapi/popupImg/list`,data)
}
//海报修改
export const edit = (data)=>{              
    return request.post(`/roleapi/popupImg/edit`,data)
}

//推优管理列表
export const promotingExcellencelist = (data)=>{              
    return request.post(`/roleapi/promotingExcellence/list`,data)
}
//推优管理详情
export const detail = (data)=>{              
    return request.post(`/roleapi/promotingExcellence/detail`,data)
}
//添加推优
export const add = (data)=>{              
    return request.post(`/roleapi/promotingExcellence/add`,data)
}

// 获取公共前奏图片
export const preludeImgList = (data)=>{              
    return request.post(`/roleapi/promotingExcellence/preludeImgList`,data)
}
//删除公共前奏图片
export const delPreludeImg = (data)=>{              
    return request.post(`/roleapi/promotingExcellence/delPreludeImg`,data)
}