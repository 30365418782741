<template>
  <div class="department_follow">
    <el-main>
      <div class="customer_achi_head">
        <div class="customer_achi_head_name">
          {{ department_follow.customer_res.name }}
        </div>
        <div class="customer_achi_head_info">
          <div class="" style="display: flex">
            意向产品：{{ my_customer_product_text }}
          </div>
          <div class="">跟进人: {{ department_follow.my_user.name }}</div>
          <div
            class=""
            v-if="department_follow.customer_res.company_add_time != ''"
          >
            成立时间: {{ department_follow.customer_res.company_add_time }}
          </div>
          <div class="">地址: {{ department_follow.customer_res.address }}</div>
          <div class="" v-if="department_follow.customer_res.website != ''">
            网址: {{ department_follow.customer_res.website }}
          </div>
        </div>
        <div class="customer_achi_head_back pointer" @click="handleBack">
          返回上一级
        </div>
      </div>
      <div class="department_follow_box">
        <div class="cutomer_detail_box_head">
          <div class="cutomer_detail_box_head_check">
            跟进记录
            <div></div>
          </div>
        </div>
        <div class="cutomer_detail_box_Follow">
          <div
            class="cutomer_detail_box_Follow_none"
            v-if="followList && followList.length == 0"
          >
            暂无跟进记录
          </div>
          <div v-for="(item, index) in followList" :key="index">
            <div class="Follow_tit">
              <i></i>{{ index == 0 ? "近期跟进记录" : "往期跟进记录" }}
            </div>
            <div
              class="Follow_customer_info"
              style="
                padding-bottom: 40px;
                border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
              "
            >
              <div class="Follow_customer_info_plan">
                <div class="Follow_customer_info_plan_line">
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">
                      跟进时间：
                    </div>
                    <div class="Follow_customer_info_plan_line_one_info">
                      {{ item.to_time }}
                    </div>
                    <div class="Follow_customer_info_plan_line_one_time">
                      {{ item.time }}
                    </div>
                  </div>
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">
                      跟进方式：
                    </div>
                    <div class="Follow_customer_info_plan_line_one_info">
                      {{
                        item.status == 1
                          ? "电话"
                          : item.status == 2
                          ? "微信"
                          : item.status == 3
                          ? "出访"
                          : item.status == 4
                          ? "来访"
                          : item.status == 5
                          ? "其他"
                          : ""
                      }}
                    </div>
                  </div>
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">
                      状态：
                    </div>
                    <div class="Follow_customer_info_plan_line_one_info">
                      {{ item.plan_log ? "已完成" : "待处理" }}
                    </div>
                  </div>
                </div>
                <div
                  class="Follow_customer_info_plan_line"
                  v-if="item.plan_log && item.plan_log.visit_name"
                >
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">
                      是否有人陪访：
                    </div>
                    <div class="Follow_customer_info_plan_line_one_info">
                      是
                    </div>
                  </div>
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">
                      陪访人姓名：
                    </div>
                    <div class="Follow_customer_info_plan_line_one_info">
                      {{ item.plan_log.visit_name }}
                    </div>
                  </div>
                </div>
                <div class="Follow_customer_info_plan_line">
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">
                      跟进计划：
                    </div>
                    <div
                      class="Follow_customer_info_plan_line_one_info"
                      style="white-space: initial"
                    >
                      {{ item.plan }}
                    </div>
                  </div>
                </div>
                <div
                  class="Follow_customer_info_plan_line"
                  v-if="item.plan_log"
                >
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">
                      跟进结果：
                    </div>
                    <div
                      class="Follow_customer_info_plan_line_one_info"
                      style="white-space: initial"
                    >
                      {{ item.plan_log.result }}
                    </div>
                  </div>
                </div>
                <div
                  class="Follow_customer_info_plan_line"
                  v-if="item.plan_log && item.plan_log.file_url.length > 0"
                >
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">
                      图片展示：
                    </div>
                    <div class="Follow_customer_info_plan_line_one_img">
                      <img
                        alt=""
                        v-for="(itm, idx) in item.plan_log.file_url"
                        :key="idx"
                        :src="itm.file_url"
                        @click="take_big(itm.file_url)"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="Follow_customer_info_plan_line"
                  v-show="item.plan_comment.length > 0"
                  v-for="(itm, idx) in item.plan_comment"
                  :key="idx"
                >
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">
                      {{ itm.user_name.name }}促进：
                    </div>
                    <div
                      class="Follow_customer_info_plan_line_one_info"
                      style="white-space: initial"
                    >
                      {{ itm.comment }}
                    </div>
                  </div>
                </div>
                <div
                  class="Follow_customer_info_plan_line"
                  style="justify-content: flex-end"
                >
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="personal_result" @click="handleDP(item)">
                      促进
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <!-- 促进 -->
    <div class="acc_shadow" v-if="commen_show">
      <div class="acc_commen">
        <div class="acc_commen_title">
          促进
          <i
            class="el-icon-close pointer"
            style="color: #999999"
            @click="
              commen_show = false;
              isMore = false;
            "
          ></i>
        </div>
        <div class="acc_commen_body">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入促进内容"
            v-model="commen"
            maxlength="150"
          >
          </el-input>
        </div>
        <div class="acc_commen_sent pointer" @click="sent_commen">确定</div>
      </div>
    </div>
    <div class="login_mc" v-if="isMore"></div>
    <!-- 放大图片 -->
    <div class="acc_img_shadow" v-if="big_imgshow" @click="big_imgshow = false">
      <img :src="imgsrc" alt="" />
    </div>
  </div>
</template>

<script>
import { followList, addComment } from "../../api/User/customer";
export default {
  data() {
    return {
      followList: [], //跟进计划
      department_follow: {},
      commen_show: false,
      isMore: false,
      info_detail: {},
      my_customer_product_text: "", //产品意向文字展示
      //促进
      commen: "",
      flag_commen: false,
      big_imgshow: false,
      imgsrc: "",
        permissions_id_string:''
    };
  },
  created() {
    let obj = {};
    let text = "";
    let textarr = [];
    if (this.$route.params.id) {
      obj = {
        my_customer_id: this.$route.params.id,
      };
      this.department_follow = this.$route.params;
      let my_customer_product = this.$route.params.my_customer_product;
      my_customer_product.forEach((item) => {
        text =
          item.product_name.product_name + item.status == 2
            ? "已成交"
            : item.intention == 1
            ? "A类"
            : item.intention == 2
            ? "B类"
            : item.intention == 3
            ? "C类"
            : item.intention == 4
            ? "D类"
            : "";
        textarr.push(item.product_name.product_name + "-" + text);
      });
      localStorage.setItem(
        "department_follow",
        JSON.stringify(this.$route.params)
      );
    } else {
      let department_follow = JSON.parse(
        localStorage.getItem("department_follow")
      );
      let my_customer_product = department_follow.my_customer_product;
      my_customer_product.forEach((item) => {
        text =
          item.product_name.product_name + item.status == 2
            ? "已成交"
            : item.intention == 1
            ? "A类"
            : item.intention == 2
            ? "B类"
            : item.intention == 3
            ? "C类"
            : item.intention == 4
            ? "D类"
            : "";
        textarr.push(item.product_name.product_name + "-" + text);
      });
      this.department_follow = department_follow;
      obj = {
        my_customer_id: department_follow.id,
      };
    }
    this.my_customer_product_text = textarr.join("、");
    followList(obj).then((res) => {
      if (res.data.code == 200) {
        // console.log(res.data.data.data);
        this.followList = res.data.data.data;
      }
    });
      let token = JSON.parse(localStorage.getItem("token"));
      this.position = token.position_type;
      for (let i in token.role_permissions) {
          if (token.role_permissions[i].permissions == "Marketing") {
              for (let v in token.role_permissions[i].data) {
                  if (
                      token.role_permissions[i].data[v].permissions == "Marketing_Account"
                  ) {
                      this.permissions_id_string =
                          token.role_permissions[i].data[v].permissions_id_string; //促进str
                  }
              }
          }
      }
  },
  methods: {
    handleBack() {
      history.go(-1);
    },
    handleDP(item) {
      this.commen_show = true;
      this.isMore = true;
      this.info_detail = item;
    },
    //促进
    sent_commen() {
      if (this.flag_commen) {
        return;
      }
      this.flag_commen = true;
      addComment({
          my_customer_log_id: this.info_detail.id,
        comment: this.commen,
          permissions_id_string: this.permissions_id_string
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "促进成功",
            type: "success",
          });
          this.isMore = false;
          this.commen_show = false;
          this.commen = "";
          this.flag_commen = false;
        }
      });
    },
    take_big(src) {
      this.imgsrc = src;
      this.big_imgshow = true;
    },
  },
};
</script>

<style lang="scss">
.department_follow {
  margin: 20px;
  box-sizing: border-box;
  width: 100%;
  .login_mc {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 98;
  }
  //图片放大
  .acc_img_shadow {
    height: 100%;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2000;
    height: 100%;
    img {
      display: block;
      width: 500px;
      max-height: 600px;
      margin: 200px auto;
    }
  }
  .el-main {
    .customer_achi_head {
      padding-left: 35px;
      background: #fff;
      width: 100%;
      height: 90px;
      box-sizing: border-box;
      position: relative;
      .customer_achi_head_name {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 21px;
        color: rgba(0, 0, 0, 0.8);
        opacity: 1;
        padding-top: 13px;
        margin-bottom: 20px;
      }
      .customer_achi_head_info {
        display: flex;
        height: 19px;
        align-items: center;
        div {
          padding-right: 50px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.6);
          border-right: 1px solid rgba(0, 0, 0, 0.2);
          opacity: 1;
          padding-right: 30px;
          padding-left: 30px;
        }
        div:first-child {
          padding-left: 0px;
        }
        div:last-child {
          padding-left: 30px;
          border-right: none;
        }
      }
      .customer_achi_head_back {
        width: 90px;
        height: 35px;
        background: #3a85fc;
        border-radius: 2px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 20px;
        right: 80px;
      }
    }
    .department_follow_box {
      margin-top: 10px;
      background: #fff;
      padding: 0 30px;
      .cutomer_detail_box_head {
        height: 50px;
        display: flex;
        align-items: flex-end;
        border-bottom: 1px solid rgba(0, 0, 0, 0.10196078431372549);
        .cutomer_detail_box_head_check {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: #3a85fc;
          opacity: 1;
          display: flex;
          width: 65px;
          flex-direction: column;
          align-items: center;
          height: 35px;
          justify-content: space-between;
          div:last-child {
            width: 65px;
            height: 3px;
            background: #3a85fc;
            opacity: 1;
          }
        }
      }
      .cutomer_detail_box_Follow {
        .cutomer_detail_box_Follow_none {
          color: #ccc;
          margin: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .Follow_tit {
          padding: 0 30px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.8);
          opacity: 1;
          display: flex;
          align-items: center;
          margin: 20px 0;
          i {
            display: block;
            width: 6px;
            height: 6px;
            background: #3a85fc;
            border-radius: 50%;
            opacity: 1;
            margin-right: 10px;
          }
        }
        .Follow_none {
          color: #ccc;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .Follow_customer_info {
          padding: 0px 40px;
          .Follow_customer_info_box {
            background: #f8f8f8;
            padding: 0 40px;
            display: flex;
            flex-direction: column;
            height: 60px;
            justify-content: space-around;
            .Follow_customer_info_box_line {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 19px;
              color: rgba(0, 0, 0, 0.85);
              justify-content: space-between;
              .Follow_customer_info_box_line_one {
                display: flex;
                .Follow_customer_info_box_line_one_tit {
                  color: rgba(0, 0, 0, 0.5);
                }
                .Follow_customer_info_box_line_one_info {
                  color: rgba(0, 0, 0, 0.8);
                }
              }
            }
          }
          .Follow_customer_info_plan {
            background: #fff;
            padding: 0 40px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            position: relative;

            .Follow_customer_info_plan_bottom {
              position: absolute;
              bottom: -30px;
              right: 20px;
              width: 80px;
              height: 35px;
              background: #3a85fc;
              opacity: 1;
              border-radius: 4px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 19px;
              color: #ffffff;
              opacity: 1;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .Follow_customer_info_plan_line {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 19px;
              color: rgba(0, 0, 0, 0.85);
              // justify-content: space-between;
              width: 100%;
              margin: 10px 0;
              .Follow_customer_info_plan_line_one {
                display: flex;
                .Follow_customer_info_plan_line_one_tit {
                  color: rgba(0, 0, 0, 0.5);
                  white-space: nowrap;
                }
                .Follow_customer_info_plan_line_one_info {
                  color: rgba(0, 0, 0, 0.8);
                  white-space: nowrap;
                  flex: 1;
                }
                .Follow_customer_info_plan_line_one_img {
                  display: flex;
                  img {
                    width: 60px;
                    height: 60px;
                    margin-right: 20px;
                  }
                }
                .Follow_customer_info_plan_line_one_time {
                  width: 66px;
                  height: 20px;
                  background: rgba(58, 133, 252, 0.1);
                  opacity: 1;
                  border-radius: 20px;
                  font-size: 12px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  line-height: 16px;
                  color: #3a85fc;
                  opacity: 1;
                  margin-left: 12px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .personal_result {
                  display: flex;
                  width: 80px;
                  height: 40px;
                  align-items: center;
                  justify-content: center;
                  background: #3a85fc;
                  color: #fff;
                  font-size: 14px;
                  border-radius: 4px;
                  cursor: pointer;
                }
              }
              .Follow_customer_info_plan_line_one:nth-child(2) {
                margin: 0 20%;
              }
            }
          }
        }
      }
    }
  }
  .acc_shadow {
    position: fixed;
    z-index: 120;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    .el-range-separator {
      width: 30px;
    }
    .acc_commen {
      width: 445px;
      height: 426px;
      background: #ffffff;
      border: 1px solid #707070;
      opacity: 1;
      border-radius: 10px;
      margin: 100px auto;
      position: relative;
      .acc_commen_title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;
        position: relative;
        height: 40px;
        border-bottom: 1px solid #ccc;
        i {
          position: absolute;
          // top: ;
          right: 10px;
        }
      }
      .acc_commen_body {
        width: 100%;
        resize: none;
        padding: 10% 10%;
        .el-textarea__inner {
          resize: none;
          height: 270px;
        }
      }
      .acc_commen_sent {
        position: absolute;
        right: 10px;
        padding: 0 10px;
        height: 25px;
        line-height: 25px;
        bottom: 10px;
        background: #3a85fc;
        color: #fff;
        border-radius: 5px;
      }
    }
  }
}
</style>