<template>
  <!-- 业绩趋势 -->
  <div class="all_trend">
    <div class="all_trend_head">本月业绩趋势</div>
    <div class="all_trend_chart">
      <div class="all_trend_chart_head">
        <div>合计(元)：{{ sum_money }}</div>
        <div>单位（元）</div>
      </div>
      <div id="mytrend" class="mytrend"></div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import { historyData } from "../../api/User/home";
export default {
  data() {
    return {
      line_one: [],
      sum_money: 0,
    };
  },
  created() {
    setTimeout(() => {
      this.$forceUpdate();
    }, 100);
  },
  mounted() {
    var myDate = new Date();
    let time = this.gettime(myDate);
    let data = {
      year_month: time,
    };
    this.gethistoryData(data);
  },
  methods: {
    getNow() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var d = new Date(year, month, 0);
      return d.getDate();
    },
    gettime(time) {
      var date = new Date(time); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); // let strDate = Y + M + D + h + m + '00';
      let strDate = Y + M;
      return strDate;
    },
    gethistoryData(data) {
      historyData(data).then((res) => {
        if (res.data.code == 200) {
          let one_data = [];
          let line_data = [];
          let day_arr = []; //底部
          let arr = res.data.data.data_array;

          this.sum_money = res.data.data.sum_money;
          for (let i = 0; i < arr.length; i++) {
            one_data.push(arr[i].sum_money);
            var myDate = new Date(Date.parse(arr[i].value));
            var days = myDate.getDay();
            switch (days) {
              case 1:
                days = "周一";
                break;
              case 2:
                days = "周二";

                break;
              case 3:
                days = "周三";
                break;
              case 4:
                days = "周四";
                break;
              case 5:
                days = "周五";
                break;
              case 6:
                days = "周六";
                break;
              case 0:
                days = "周日";
                break;
            }
            var str = days;
            day_arr.push(i * 1 + 1 + "日"+'【'+str+'】');
          }
          let obj_one = {
            name: "业绩(元)",
            type: "line",
            data: one_data,
            smooth: true,
            symbol: "none",
            color: "#FF6A6A",
          };
          line_data.push(obj_one);
          this.line_one = line_data;
          let mytrend = this.$echarts.init(document.getElementById("mytrend"));
          mytrend.resize();
          mytrend.setOption({
            tooltip: {
              trigger: "axis",
              backgroundColor: "#fff",
              textStyle: {
                color: "#000",
              },
            },

            xAxis: {
              type: "category",
              boundaryGap: false,
              data: day_arr,
            },
            yAxis: {
              type: "value",
              splitLine: {
                lineStyle: {
                  type: "solid",
                  color: "rgba(0,0,0,.1)",
                },
              },
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
            },
            grid: {
              left: "0%",
              right: "2%",
              bottom: "10%",
              containLabel: true,
            },
            series: line_data,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.all_trend {
  height: 541px;
  width: 100%;
  border-radius: 2px;
  padding: 30px 40px;
  background: #ffffff;
  .all_trend_head {
    font-size: 18px;
    font-weight: bold;
    line-height: 31px;
    color: rgba(0, 0, 0, 0.8);
    opacity: 1;
  }
  .all_trend_chart {
    width: 100%;
    margin-top: 20px;
    .all_trend_chart_head {
      display: flex;
      justify-content: space-between;
      div {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.6);
        opacity: 1;
      }
    }
    .mytrend {
      width: 100%;
      height: 430px;
    }
  }
}
</style>