import request from '@/utils/axios.tool';
// 添加事业线
export const addBusinessLine = (data)=>{              
    return request.post(`/roleapi/businessLine/addBusinessLine`,data)
}
// 事业线列表
export const list = (data)=>{              
    return request.post(`/roleapi/businessLine/list`,data)
}

// 编辑事业线
export const editBusinessLine = (data)=>{              
    return request.post(`/roleapi/businessLine/editBusinessLine`,data)
}

// 公共获取所有事业线
export const businessLineList = (data)=>{              
    return request.post(`/roleapi/businessLine/businessLineList`,data)
}


//获取部门详情
export const detail = (data)=>{              
    return request.post(`/roleapi/company/detail`,data)
}

//获取部门事业线
export const getProductAll = (data)=>{
    return request.post(`roleapi/businessLine/getProductAll`,data)
}