<template>
    <div class="CloudDetails">
        <div class="CloudDetails_top">
            <div class="back" @click="handleBack">
                返回上一级
            </div>
        </div>
        <div class="CloudDetails_center">
            <div class="CloudDetails_center_img">
                <img
                        style="width: 600px; height: 450px"
                        :src="productDetail.img_url"
                        />
            </div>
            <div class="CloudDetails_center_num">
                <h1>{{productDetail.name}}</h1>
                <div class="num">
                    <div class="menoy" v-for="(item,index) in productDetail.price_json" :key="index">
                        <p>{{item.name}}</p>
                        <div>
                            <span>￥</span>
                            <h1>{{item.price}}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="CloudDetails_center_concat">
                <div>
                    <h5>钉钉扫码入群</h5>
                    <img :src="productDetail.group_mode_url"/>
                </div>
                <div style="margin-top: 60px;">
                    <h5>电话咨询</h5>
                    <ul>
                        <li v-for="item in productDetail.tel_json">
                            <span style="margin-right: 10px" v-if="item.name">{{item.name}}</span>
                            {{item.phone}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="cloud_tab">
            <el-tabs v-model="activeName" >
                <el-tab-pane label="产品详情" name="产品详情"></el-tab-pane>
                <el-tab-pane label="案例展示" name="案例展示"></el-tab-pane>
                <el-tab-pane label="入住流程" name="入住流程"></el-tab-pane>
            </el-tabs>
        </div>

        <div class="details_html">
            <div v-html="productDetail.detail" v-if="activeName=='产品详情'"></div>
            <div v-html="productDetail.case_display" v-if="activeName=='案例展示'"></div>
            <div v-html="productDetail.settlement_process" v-if="activeName=='入住流程'"></div>
        </div>
    </div>
</template>

<script>
    import {
        productDetail
    } from "../../api/User/cloud";
    export default {
        name: "cloud_platform-details",
        data(){
            return{
                productDetail:{},
                activeName:'产品详情'
            }
        },
        created() {
            productDetail({
                cloud_platform_product_id:this.$route.query.cloud_platform_product_id
            }).then(res=>{
                this.productDetail=res.data.data
            })
        },
        methods: {
            handleBack() {
                history.go(-1);
            }
        }
    }
</script>

<style scoped lang="scss">
    .CloudDetails{
        background: #fff;
        padding: 30px;
        flex-direction: column;
         margin: 30px;
        .CloudDetails_top{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            border-bottom: 1px solid #E0E0E0;
            padding-bottom:10px;
        }
        .back {
            width: 90px;
            height: 35px;
            background: #3a85fc;
            border-radius: 2px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 19px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .CloudDetails_center{

            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            .CloudDetails_center_num{
                display: flex;
                width: 450px;
                flex-direction: column;
                margin-left: 5%;
                h1{
                    font-size: 18px;
                    color: #000;

                    font-weight: bold;
                }
                .num{
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 20px;
                    .menoy{
                        /*width: 120px;*/
                        height: 72px;
                        background: #FFFFFF;
                        box-shadow: 2px 3px 10px 0px rgba(154, 156, 160, 0.2);
                        border-radius: 4px;
                        display: flex;
                        flex-direction: column;
                        margin-top: 30px;
                        margin-right: 20px;
                        padding: 10px;
                        p{
                            font-size: 14px;
                            color: #666666;
                        }
                        div{
                            span{
                                font-size: 14px;
                            }
                            h1{
                                font-size: 32px;
                                color: #FF4848;
                            }

                            display: flex;
                            justify-content: flex-end;
                            align-items: baseline;
                            color: #FF4848;
                        }
                    }
                }

            }
        }
        .CloudDetails_center_concat{
            padding: 30px;
            color: #fff;
            flex-direction: column;
            width: 332px;
            height: 450px;
            background: url("../../assets/img/cont.png");
            img{
                width: 160px;
                height: 160px;
                margin-left: 15px;
            }
            font-size: 14px;
            div{
                display: flex;
                align-items: flex-start;
            }
            ul{
                margin-left:40px;
                li{
                    margin-bottom: 10px;
                }
            }
        }
        .cloud_tab{
            margin-top: 30px;
            .el-tabs__item{
                height: 50px !important;
            }
        }
        .details_html{
            margin-top: 20px;
            /*max-height: 350px;*/
            /*overflow-y: scroll;*/
        }
    }
    
</style>