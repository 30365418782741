
import request from '@/utils/axios.tool';

export const userLogin = (data)=>{              //用户登录
  return request.post(`/roleapi/login/index`,data)
}


export function choiceCompany(data){      //多部门选择单部门
  return request.post('/roleapi/choiceCompany',data)
}

//获取用户部门
export function obtainUserCompany(data){      
  return request.post('/roleapi/obtainUserCompany')
}
