<template>
  <div class="Account_box" style="flex: 1">
    <el-main>
      <div class="account_title">
        <div class="account_info"  v-if="human_check == 2">
          选择部门：
          <el-cascader
            :props="props"
            class="screen_input"
            @change="handleClick"
            ref="myCascader"
            placeholder="请选择部门名称"
            :key="isResouceShow"
            v-model="companyList"
          ></el-cascader>
        </div>
        <div class="account_info" v-if="human_check == 2">
          跟进人：
          <el-input size="large" placeholder="请输入跟进人姓名" suffix-icon="el-icon-user-solid" v-model="flow_human_name"> </el-input>
        </div>

        <div class="account_info" v-if="human_check == 1">
          公司名称：
          <el-input size="large" placeholder="请输入公司名称" v-model="compony_name"> </el-input>
        </div>
        <div class="account_info">
          产品名称：
          <div class="screen_input" style="border: 0">
            <el-cascader
              clearable
              v-model="search_product_name_id"
              :options="product_options"
              placeholder="请选择产品"
              ref="cascader"
              separator=" - "
            >
            </el-cascader>
          </div>
        </div>
        <div class="account_info">
          意向客户：
          <el-select v-model="search_intention" clearable placeholder="请选择">
            <el-option v-for="item in intention" :key="item.name" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </div>
        <div class="account_info" :style="human_check==2?'margin-left: 0':''" >
          有无预计到账：
          <el-select v-model="is_expected_arrival" clearable placeholder="请选择">
            <el-option v-for="item in expected_arrival" :key="item.name" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </div>
      
        <div class="account_info" :style="human_check==1?'margin-left: 0':''" >
          跟进方式：
          <el-select v-model="value" clearable placeholder="请选择">
            <el-option v-for="item in prodect_log" :key="item.name" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </div>
        <div class="account_info">
          计划时间：
          <el-date-picker
            v-model="date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            prefix-icon="ssa"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="account_info">
          <div class="account_serch pointer" @click="getlist(1)">查询</div>
          <div class="account_reload pointer" @click="getlist(1, 1)">重置</div>
        </div>
      </div>
      <div class="acc_info">
        <div class="left_add pointer" v-if="position != 1">
          <div
            :class="[human_check == 1 ? 'act_title' : '']"
            @click="
              human_check = 1;
              flow_human_name = '';
              date = '';
              value = '';
              compony_name = '';
            "
          >
            个人
          </div>
          <div
            :class="[human_check == 2 ? 'act_title' : '']"
            @click="
              human_check = 2;
              flow_human_name = '';
              date = '';
              value = '';
              compony_name = '';
            "
          >
            部门
          </div>
        </div>
        <div class="right_check pointer">
          <div :class="[status_check == 1 ? 'act_title' : '']" @click="status_check = 1">跟进提醒</div>
          <div :class="[status_check == 2 ? 'act_title' : '']" @click="status_check = 2">跟进记录</div>
          <div :class="[status_check == 3 ? 'act_title' : '']" @click="status_check = 3">待审核</div>
        </div>
      </div>
      <div class="acc_table">
        <el-table
          :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
          :data="tableData"
          style="width: 100%"
          border
          :key="Math.random()"
          :default-sort="{ prop: 'date', order: 'descending' }"
        >
          <el-table-column prop="name" label="公司名称" width="230">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ scope.row.my_customer.customer_res ? scope.row.my_customer.customer_res.name : "--" }}
                {{ scope.row.my_customer.intention == 1 ? "A" : "B" }}类{{
                  scope.row.my_customer.status == 3 ? "" : "-" + scope.row.my_customer.time + "天"
                }}
                <img
                  :src="
                    scope.row.my_customer.wechat == '' || scope.row.my_customer.wechat == null || scope.row.my_customer.wechat == '[]'
                      ? require('../../assets/img/wx.png')
                      : require('../../assets/img/wx_ing.png')
                  "
                  class="wx"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="日期" width="180">
            <template slot-scope="scope">
              <div class="acc_table_company_name">{{ status_check == 1 ? scope.row.to_time : scope.row.trail_time }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="客户姓名">
            <template slot-scope="scope">
              <div class="acc_table_company_name">{{ scope.row.customer_contacts ? scope.row.customer_contacts.contacts : "--" }}</div>
            </template>
          </el-table-column>
          <!--<el-table-column-->
          <!--prop="address"-->
          <!--label="手机号"-->
          <!--&gt;-->
          <!--<template slot-scope="scope">-->
          <!--<div class="acc_table_company_name">{{scope.row.my_customer.customer_res.name}}</div>-->
          <!--</template>-->
          <!--</el-table-column>-->
          <el-table-column prop="address" label="跟进方式">
            <template slot-scope="scope">
              <div class="acc_table_company_name" v-if="status_check != 1">
                {{
                  scope.row.status == 1
                    ? "电话"
                    : scope.row.status == 2
                    ? "微信"
                    : scope.row.status == 3
                    ? "出访"
                    : scope.row.status == 4
                    ? "来访"
                    : "其他"
                }}
              </div>
              <div class="acc_table_company_name" v-if="status_check == 1">
                {{
                  scope.row.status == 1
                    ? "面访"
                    : scope.row.status == 2
                    ? "来访"
                    : scope.row.status == 3
                    ? "出访"
                    : scope.row.status == 4
                    ? "网签"
                    : "其他"
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="产品名称">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                <div v-for="(item, index) in scope.row.plan_product" :key="index">
                  {{ item.product_plan_name.product_name }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="status_check == 2" prop="address" label="跟进目的">
            <template slot-scope="scope">
              <div v-for="item in follow_up_type">
                <div v-if="item.id == scope.row.follow_up_type">
                  {{ item.name }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="expected_arrival" label="预计到账">
            <template slot-scope="scope">
              <div class="acc_table_company_name">{{ scope.row.expected_arrival ? scope.row.expected_arrival : "0" }}元</div>
            </template>
          </el-table-column>
          <!--<el-table-column-->
          <!--prop="address"-->
          <!--label="跟进计划"-->
          <!--v-if="status_check!=3"-->
          <!--&gt;-->
          <!--<template slot-scope="scope">-->
          <!--<div class="acc_table_plan">-->
          <!--<el-popover-->
          <!--placement="top-start"-->
          <!--width="400"-->
          <!--trigger="hover"-->
          <!--:content="scope.row.plan">-->
          <!--&lt;!&ndash; <el-button slot="reference">hover 激活</el-button> &ndash;&gt;-->
          <!--<div slot="reference">{{scope.row.plan}}</div>-->
          <!--</el-popover>-->
          <!--&lt;!&ndash; {{scope.row.plan_comment[0].comment}} &ndash;&gt;-->
          <!--&lt;!&ndash; {{scope.row.plan}} &ndash;&gt;-->
          <!--</div>-->
          <!--</template>-->
          <!--</el-table-column>-->
          <el-table-column prop="address" label="跟进小结" v-if="status_check != 1">
            <template slot-scope="scope">
              <el-popover placement="top-start" width="400" trigger="hover" :content="scope.row.result">
                <div class="acc_table_plan" slot="reference">
                  {{ scope.row.result }}
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <!--<el-table-column-->
          <!--prop="address"-->
          <!--label="计划跟进时间"-->
          <!--&gt;-->
          <!--<template slot-scope="scope">-->
          <!--<div class="acc_table_plan">-->

          <!--{{scope.row.to_time}}-->
          <!--</div>-->
          <!--</template>-->
          <!--</el-table-column>-->
          <el-table-column prop="address" label="时间">
            <template slot-scope="scope">
              <div class="acc_table_company_name">{{ scope.row.time }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="预计计划">
            <template slot-scope="scope">
              <div v-if="scope.row.estimate_plan && status_check == 1">
                <div v-for="o in estimate_plan" class="acc_table_company_name">
                  <div v-for="t in scope.row.estimate_plan">
                    <div v-if="o.id == t">
                      {{ o.name }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="scope.row.customer_plan && status_check != 1">
                <div v-for="o in estimate_plan" class="acc_table_company_name">
                  <div v-for="t in scope.row.customer_plan.estimate_plan">
                    <div v-if="o.id == t">
                      {{ o.name }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="跟进人" v-if="human_check == 2">
            <template slot-scope="scope">
              <div class="acc_table_plan">
                {{ scope.row.user ? scope.row.user.name : "--" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="领导促进" v-if="status_check != 1">
            <template slot-scope="scope">
              <div class="acc_table_plan" v-if="scope.row.comment && scope.row.comment.length != 0">
                <el-popover placement="top-start" width="400" trigger="hover" :content="scope.row.comment[0].comment">
                  <!-- <el-button slot="reference">hover 激活</el-button> -->
                  <div class="acc_table_plan" v-if="scope.row.comment && scope.row.comment.length != 0" slot="reference">
                    {{ scope.row.comment ? scope.row.comment[0].comment : "--" }}
                  </div>
                </el-popover>
                <!-- {{scope.row.plan_comment[0].comment}} -->
              </div>
              <!--<div v-else class="acc_table_plan">-->
              <!--&#45;&#45;-->
              <!--</div>-->
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作" v-if="status_check != 1">
            <template slot-scope="scope">
              <div class="acc_moreinfo">
                <span style="color: #3a85fc; margin-right: 3px" class="pointer" @click="take_detail(scope.row)">查看</span>
                <!--<span v-if="status_check!=3&&human_check==1"> | </span>-->
                <!--<el-dropdown @command="handleCommand" v-if="status_check!=3&&human_check==1">-->
                <!--<span class="el-dropdown-link pointer" style="margin-left:3px;">-->
                <!--<span style="color:#3A85FC">更多</span> <i-->
                <!--class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
                <!--</span>-->
                <!--<el-dropdown-menu slot="dropdown">-->
                <!--<el-dropdown-item v-for="(item,index) in check_list" :key="index"-->
                <!--:command="newcommand(scope.row,item.id)">{{item.name}}-->
                <!--</el-dropdown-item>-->
                <!--</el-dropdown-menu>-->
                <!--</el-dropdown>-->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="10"
          layout="total,prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-main>
    <div class="acc_shadow" v-if="order_show">
      <div class="info" v-if="show_flag == 1">
        <div class="take_new_title">
          计划修改
          <i
            class="el-icon-close pointer"
            style="color: #999999"
            @click="
              order_show = false;
              show_detail_flag = false;
            "
          ></i>
        </div>
        <div class="take_new_detail">
          <div class="acc_top_compony">
            <div class="kf_detail">
              <div class="quan"></div>
              客户信息
            </div>
            <div class="kh_detail" v-if="info_detail.customer_res">
              <div>公司名称：{{ info_detail.customer_res.name }}</div>
              <div>客户名称：{{ info_detail.customer_res.contacts }}</div>
              <div>手机号：{{ info_detail.customer_res.phone }}</div>
              <div>
                产品意向：<span v-for="(item, index) in info_detail.my_customer_product" :key="index" style="margin-right: 10px"
                  >{{ item.product_name.product_name }}
                  <!-- - {{item.status==2?'已成交':item.intention==1?'A - ':item.intention==2?'B - ':item.intention==3?'C - ':'D - '}}<span-->
                  <!--v-if="item.status!=2">{{item.time+"天"}}</span>-->
                </span>
              </div>
            </div>
            <div class="get_detail">
              <div class="flow_kind">
                跟进方式：
                <el-select v-model="flow_kind" clearable placeholder="请选择">
                  <el-option v-for="item in prodect_log" :key="item.name" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </div>
              <div class="flow_kind">
                跟进日期：
                <el-date-picker v-model="check_time" type="datetime" placeholder="选择日期时间"> </el-date-picker>
              </div>
              <div class="flow_kind_text">
                <span style="magrin-top: 6px; white-space: nowrap">跟进计划：</span>
                <el-input type="textarea" :rows="3" placeholder="请输入跟进计划" v-model="flow_plan"> </el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="take_bottom">
          <div class="bottom_sent pointer" @click="sent_new_plan">保存</div>
        </div>
      </div>
    </div>
    <div class="acc_shadow" v-if="notes_show">
      <div class="info">
        <div class="take_new_title">
          填写跟进结果
          <i
            class="el-icon-close pointer"
            style="color: #999999"
            @click="
              notes_show = false;
              show_detail_flag = false;
            "
          ></i>
        </div>
        <div class="take_new_detail">
          <div class="acc_top_compony">
            <div class="kf_detail">
              <div class="quan"></div>
              客户信息
            </div>
            <div class="kh_detail" v-if="info_detail.customer_res">
              <div>公司名称：{{ info_detail.customer_res.name }}</div>
              <div>客户名称：{{ info_detail.customer_res.contacts }}</div>
              <div>手机号：{{ info_detail.customer_res.phone }}</div>
              <div>
                产品意向：<span v-for="(item, index) in info_detail.my_customer_product" :key="index" style="margin-right: 10px"
                  >{{ item.product_name.product_name }}
                  <!-- - {{item.status==2?'已成交':item.intention==1?'A - ':item.intention==2?'B - ':item.intention==3?'C - ':'D - '}}<span-->
                  <!--v-if="item.status!=2">{{item.time+"天"}}</span>-->
                </span>
              </div>
            </div>
            <div class="get_detail">
              <div class="flow_kind">
                跟进方式：{{
                  info_detail.status == 1
                    ? "电话"
                    : info_detail.status == 2
                    ? "微信"
                    : info_detail.status == 3
                    ? "出访"
                    : info_detail.status == 4
                    ? "来访"
                    : "其他"
                }}
              </div>
              <div class="flow_kind_text" style="white-space: wrap">
                <div style="white-space: nowrap">跟进计划：</div>

                {{ info_detail.plan }}
              </div>
              <div class="flow_kind">
                跟进时间：
                <el-date-picker
                  v-model="flow_time"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
              <div class="flow_kind" v-if="info_detail.status == 3">
                是否陪访：
                <el-radio v-model="is_tegether" label="1">是</el-radio>
                <el-radio v-model="is_tegether" label="2">否</el-radio>
              </div>
              <div class="flow_kind" v-if="is_tegether == 1">
                <span style="margin-right: 14px; white-space: nowrap">陪访人：</span>
                <el-select v-model="flow_human" clearable placeholder="请选择">
                  <el-option v-for="item in flow_human_list" :key="item.name" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </div>
              <div class="flow_kind" v-if="is_tegether == 1">
                <span style="margin-left: -14px; white-space: nowrap">陪访人姓名：</span>
                <el-input placeholder="请输入陪访人姓名" v-model="tegether_name"> </el-input>
              </div>
              <div class="flow_kind" v-if="info_detail.status == 3 || info_detail.status == 4">
                <span style="white-space: nowrap">见面目的：</span>
                <el-select v-model="objective" clearable placeholder="请选择">
                  <el-option v-for="item in objective_list" :key="item.name" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </div>
              <div class="flow_kind" v-if="info_detail.status == 3">
                <span style="margin-left: 28px; white-space: nowrap">地址：</span>
                <el-input placeholder="请输入地址" v-model="address"> </el-input>
              </div>
              <div class="flow_kind_text">
                <span style="margin-top: 6px; white-space: nowrap">跟进结果：</span>
                <el-input type="textarea" placeholder="请输入跟进结果" v-model="plan_end" rows="3" maxlength="1000" show-word-limit>
                </el-input>
              </div>
              <div class="flow_kind_text" v-if="info_detail.status == 2 || info_detail.status == 3 || info_detail.status == 4">
                <span style="margin-top: 6px; white-space: nowrap">图片展示：</span>
                <div class="flow_img_listinfo">
                  <div class="img_item_info" v-for="(item, index) in img_list" :key="index">
                    <img :src="item.file_url" alt="" class="big_img pointer" @click="take_big(item.file_url)" />
                    <i class="el-icon-error del_img pointer" style="color: #ccc" @click="del_img(index)"></i>
                  </div>
                  <div class="image_info" @click="chooseFile" v-if="img_list.length < 2">
                    <!-- <i class="el-icon-plus"></i> -->
                    <img src="@/assets/img/hentai.png" alt="" style="width: 28px; height: 25px" />
                    <span>请上传图片</span>
                    <input
                      type="file"
                      style="display: none"
                      v-if="upload_img"
                      ref="choosePhoto"
                      @change="photoChange($event)"
                      id="upload_file"
                    />
                  </div>
                </div>
                <!-- <el-input type="file" :value="dape.video" v-model="dape.video" name="video" accept="audio/mpeg" style="width: 20%"></el-input> -->
              </div>
            </div>
          </div>
        </div>
        <div class="take_bottom">
          <div class="bottom_sent pointer" @click="sent_flow">保存</div>
        </div>
      </div>
    </div>
    <div class="new_acc_shadow" v-if="isFollow">
      <div class="Follow" v-if="isFollow">
        <div class="Follow_head">跟进记录 <i class="el-icon-close pointer" style="padding: 10px" @click="handleclosePlan"></i></div>
        <div class="Follow_info">
          <div class="Follow_tit"><i></i>客户信息</div>
          <div class="Follow_customer_info">
            <div class="Follow_customer_info_box">
              <div class="Follow_customer_info_box_line" v-if="customerHead_info.customer_res">
                <div class="Follow_customer_info_box_line_one">
                  <div class="Follow_customer_info_box_line_one_tit">公司名称：</div>
                  <div class="Follow_customer_info_box_line_one_info">
                    {{ customerHead_info.customer_res.name }}
                  </div>
                </div>
                <div class="Follow_customer_info_box_line_one">
                  <div class="Follow_customer_info_box_line_one_tit">客户名称：</div>
                  <div class="Follow_customer_info_box_line_one_info">
                    {{ customerHead_info.customer_res.contacts }}
                  </div>
                </div>
                <div class="Follow_customer_info_box_line_one">
                  <div class="Follow_customer_info_box_line_one_tit">手机号：</div>
                  <div class="Follow_customer_info_box_line_one_info">
                    {{ customerHead_info.customer_res.phone }}
                  </div>
                </div>
              </div>
              <div class="Follow_customer_info_box_line">
                <div class="Follow_customer_info_box_line_one">
                  <div class="Follow_customer_info_box_line_one_tit">产品意向：</div>
                  <div class="Follow_customer_info_box_line_one_info" style="display: flex">
                    <div v-for="(item, index) in customerHead_info.my_customer_product" :key="index" style="margin-right: 10px">
                      {{ item.product_name.product_name }}
                      <!-- - -->
                      <!--{{item.status==2?'已成交':item.intention==1?'A - ':item.intention==2?'B - -->
                      <!--':item.intention==3?'C - ':'D - '}}<span v-if="item.status!=2">{{item.time+"天"}}</span>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-for="(item, index) in follow_List" :key="index" v-if="follow_List.length">
            <div class="Follow_tit"><i></i>{{ index == 0 ? "近期跟进记录" : "往期跟进记录" }}</div>
            <div
              class="Follow_customer_info"
              style="
                padding-bottom: 20px;
                border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
                width: 94%;
                margin: 0 auto;
                box-shadow: 0px 0px 5px 3px rgb(196 196 196 / 10%);
                padding: 5px;
                box-sizing: border-box;
              "
              v-if="follow_List.length > 0"
            >
              <div class="Follow_customer_info_plan" style="">
                <div class="Follow_customer_info_plan_line">
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">跟进时间：</div>
                    <div class="Follow_customer_info_plan_line_one_info">{{ item.to_time }}</div>
                    <div class="Follow_customer_info_plan_line_one_time">{{ item.time }}</div>
                  </div>
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">跟进方式：</div>
                    <div class="Follow_customer_info_plan_line_one_info">
                      {{
                        item.status == 1
                          ? "电话"
                          : item.status == 2
                          ? "微信"
                          : item.status == 3
                          ? "出访"
                          : item.status == 4
                          ? "来访"
                          : item.status == 5
                          ? "其他"
                          : ""
                      }}
                    </div>
                  </div>
                  <!--<div class="Follow_customer_info_plan_line_one">-->
                  <!--<div class="Follow_customer_info_plan_line_one_tit">状态：</div>-->
                  <!--<div class="Follow_customer_info_plan_line_one_info">{{item.plan_type==1-->
                  <!--?'待办':item.plan_type==2 ? '待处理' : '已完成'}}-->
                  <!--</div>-->
                  <!--</div>-->
                </div>
                <div class="Follow_customer_info_plan_line" v-if="item.visit_name">
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">是否有人陪访：</div>
                    <div class="Follow_customer_info_plan_line_one_info">是</div>
                  </div>
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">陪访人姓名：</div>
                    <div class="Follow_customer_info_plan_line_one_info">
                      {{ item.visit_name }}
                    </div>
                  </div>
                </div>
                <!--<div class="Follow_customer_info_plan_line">-->
                <!--<div class="Follow_customer_info_plan_line_one">-->
                <!--<div class="Follow_customer_info_plan_line_one_tit">预计计划：</div>-->
                <!--<div class="Follow_customer_info_plan_line_one_info"-->
                <!--style="white-space:initial" v-if="item.customer_plan">-->
                <!--<div v-for="o in estimate_plan">-->
                <!--<div v-for="t in item.customer_plan.estimate_plan">-->
                <!--<div v-if="o.id==t">-->
                <!--{{o.name}}-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <div class="Follow_customer_info_plan_line">
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">跟进结果：</div>
                    <div class="Follow_customer_info_plan_line_one_info" style="white-space: initial">{{ item.result }}</div>
                  </div>
                </div>
                <div class="Follow_customer_info_plan_line" v-if="item.address != ''">
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">地址：</div>
                    <div class="Follow_customer_info_plan_line_one_info" style="white-space: initial">{{ item.address }}</div>
                  </div>
                </div>
                <div class="Follow_customer_info_plan_line" v-if="item.file_url && item.file_url.length > 0">
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">图片展示：</div>
                    <div class="Follow_customer_info_plan_line_one_img">
                      <img
                        alt=""
                        class="pointer"
                        v-for="(itm, idx) in item.file_url"
                        :key="idx"
                        :src="itm.file_url"
                        @click="take_big(itm.file_url)"
                      />
                    </div>
                  </div>
                </div>
                <div class="Follow_customer_info_plan_line" v-show="item.comment" v-for="(itm, idx) in item.comment" :key="idx">
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">{{ itm.user_name.name }}促进：</div>
                    <div class="Follow_customer_info_plan_line_one_info" style="white-space: initial">{{ itm.comment }}</div>
                  </div>
                </div>
              </div>
              <!--<div class="tx_follow " v-if="item.plan_type==1&&human_check==1">-->
              <!--<div class="pointer " @click="watch_detail(item,1)">修改计划</div>-->
              <!--</div>-->
              <!--<div class="tx_follow " v-if="item.plan_type==2&&human_check==1" style="">-->
              <!--<div class="pointer " @click="watch_detail(item,1)"-->
              <!--style="color: #3A85FC;background:#fff;border:1px solid #3A85FC">修改计划-->
              <!--</div>-->
              <!--<div class="pointer " style="margin-left:10px;" @click="notes_detail(item,1)">填写结果</div>-->
              <!--</div>-->
              <div class="tx_follow" v-if="human_check == 2">
                <div class="pointer" @click="show_commen(item)" style="width: 60px; box-sizing: border-box; justify-content: center">
                  促进
                </div>
              </div>
            </div>
            <div class="Follow_none" v-else>暂无跟进记录</div>
          </div>
        </div>
      </div>
    </div>
    <div class="acc_shadow" v-if="commen_show">
      <div class="acc_commen">
        <div class="acc_commen_title">
          促进
          <i class="el-icon-close pointer" style="color: #999999" @click="commen_show = false"></i>
        </div>
        <div class="acc_commen_body">
          <el-input type="textarea" :rows="3" maxlength="150" placeholder="请输入促进内容" v-model="commen"> </el-input>
        </div>
        <div class="acc_commen_sent pointer" @click="sent_commen">确定</div>
      </div>
    </div>
    <div class="acc_img_shadow" v-if="big_imgshow" @click="big_imgshow = false">
      <img :src="imgsrc" alt="" />
    </div>
  </div>
</template>

<script>
import vue from "vue";
import { planList, getPlan, editPlan, customerHead, followList, addPlanLog, addComment } from "../../api/User/account";
// import {
//         selectOrderCompanyWhere,
//         } from '../../api/User/finance';
import { select, getAncestorsAndSelf } from "../../api/User/human";
import { uploadFile } from "../../api/User/index";
import { getProductList } from "../../api/User/customer";
export default {
  data() {
    return {
      // 跟进目的
      follow_up_type: [
        { id: 1, name: "服务" },
        { id: 2, name: "签约" },
      ],
      estimate_plan: [
        { id: 1, name: "计划面访" },
        { id: 2, name: "预计到账" },
        { id: 3, name: "客户要方案" },
      ], //预计计划
      compony_name: "",
      prodect_log: [
        // { id: 1, name: "面访" },
        { id: 2, name: "来访" },
        { id: 3, name: "出访" },
        { id: 4, name: "网签" },
      ],
      intention: [
        { id: 1, name: "A" },
        { id: 2, name: "B" },
      ],
      expected_arrival: [
        { id: 2, name: "全部" },
        { id: 1, name: "有" },
      ],
      date: "",
      human_check: 1, //个人 部门
      status_check: 1, //待办  已完成
      tableData: [],
      check_list: [{ name: "编辑", id: 1 }],
      order_show: false,
      value: "", //筛选跟进方式
      page: 1,
      total: 0,
      show_flag: "", //弹出框节流阀
      info_detail: {}, //获取的跟进详情数据
      flow_kind: "", //跟进详情里的跟进方式
      check_time: "",
      flow_plan: "", //跟进详情的跟进计划
      plan_id: "",
      row: {},
      follow_List: [],
      customerHead_info: {},
      isFollow: false,
      my_customer_id: "",
      prodect_array: [],
      show_detail_flag: "",
      notes_show: false,
      plan_end: "", //跟进结果
      img_list: [],
      imgsrc: "", // 放大图片路径
      big_imgshow: false, //放大图片显示隐藏
      flow_time: "", //跟进时间
      is_tegether: "2", //是否配方人
      flow_human: "", //跟进
      flow_human_list: [
        { id: 1, name: "经理陪访" },
        { id: 2, name: "其他陪访" },
      ], //跟进人职位陪访
      tegether_name: "", //配方人姓名
      objective: "",
      objective_list: [
        { id: 1, name: "洽谈签约" },
        { id: 2, name: "客户服务" },
        { id: 3, name: "要转介绍" },
        {
          id: 4,
          name: "邀请参会",
        },
        { id: 5, name: "送达礼物" },
        { id: 6, name: "其它" },
      ],
      address: "",
      commen_show: false,
      commen: "",
      add_flag: false,
      options: [],
      search_depth: "",
      company_id: "",
      isResouceShow: 0,
      flow_human_name: "",
      position: "",
      upload_img: true,

      permissions_id_string: "",
      search_product_name_id: "",
      search_intention: "",
      is_expected_arrival: "",
      product_options: [], //搜索产品列表
      props: {
        lazy: true,
        lazyLoad: this.getDepartment,
        checkStrictly: true,
      },
      companyList: [],
    };
  },
  created() {
    if (localStorage.getItem("jumpInfo")) {
      let jumpInfo = JSON.parse(localStorage.getItem("jumpInfo"));
      this.human_check = jumpInfo.human_check;
      this.status_check = 2;
      if (jumpInfo.company_id) {
        getAncestorsAndSelf({
          company_id: jumpInfo.company_id,
        }).then((res) => {
          this.companyList = res.data.data.map((item) => {
            return ~~item.id;
          });
        });
      } else {
        this.flow_human_name = jumpInfo.user_name;
      }
    }
    this.getlist();
    let token = JSON.parse(localStorage.getItem("token"));
    this.position = token.position_type;
    for (let i in token.role_permissions) {
      if (token.role_permissions[i].permissions == "Marketing") {
        for (let v in token.role_permissions[i].data) {
          if (token.role_permissions[i].data[v].permissions == "Marketing_Account") {
            this.permissions_id_string = token.role_permissions[i].data[v].permissions_id_string;
          }
        }
      }
    }
    getProductList().then((res) => {
      if (res.data.code == 200) {
        let list = res.data.data;
        for (let i = 0; i < list.length; i++) {
          list[i].label = list[i].class_name;
          list[i].value = list[i].id;
          list[i].children = list[i].product_name;
          for (let j = 0; j < list[i].product_name.length; j++) {
            list[i].product_name[j].label = list[i].product_name[j].product_name;
            list[i].product_name[j].value = list[i].product_name[j].id;
          }
        }
        this.product_options = list;
      }
    });
  },
  computed: {},
  methods: {
    getDepartment(node, resolve) {
      let parent_id = node.value ? node.value : "";
      select({ parent_id }).then((res) => {
        if (res.data.code == 200) {
          let resData;
          if (parent_id) {
            resData = res.data.data;
          } else {
            resData = res.data.data.data;
          }
          let nodes = resData.map((item) => ({
            label: item.name,
            value: item.id,
          }));

          resolve(nodes);
        }
      });
    },
    chooseFile() {
      this.$refs.choosePhoto.addEventListener("change", this.photoChange);
      this.$refs.choosePhoto.dispatchEvent(new MouseEvent("click"));
      this.upload_img = false;
      setTimeout(() => {
        this.upload_img = true;
      }, 10);
    },
    photoChange(el) {
      // console.log(el);
      var file = el.target.files[0]; //name: "dangqi1.png" || type: "image/png"
      var type = file.type.split("/")[0];
      // console.log(type);
      if (type === "image") {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        var that = this;
        reader.onloadend = function () {
          var dataURL = reader.result;
          var blob = that.dataURItoBlob(dataURL);
          that.upload(blob); //执行上传接口
        };
      } else {
        this.$message.error("请上传图片哦~~");
      }
    },
    del_img(i) {
      this.img_list.splice(i, 1);
    },
    show_commen(item) {
      this.commen_show = true;
      this.row = item;
      this.commen = "";
    },
    handleClick(value) {
      this.search_depth = this.$refs["myCascader"].getCheckedNodes()[0].data.depth;
      this.company_id = value[value.length - 1];
    },
    take_big(url) {
      this.imgsrc = url;
      this.big_imgshow = true;
    },
    sent_commen() {
      if (this.add_flag == true) {
        return;
      }
      this.add_flag = true;
      addComment({
        permissions_id_string: this.permissions_id_string,
        my_customer_log_id: this.row.id,
        comment: this.commen,
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "促进成功",
              type: "success",
            });
            setTimeout(() => {
              this.add_flag = false;
            }, 100);
            this.commen_show = false;
            this.commen = "";
            this.take_detail(this.row);
          }
        })
        .catch(() => {
          setTimeout(() => {
            this.add_flag = false;
          }, 100);
        });
    },
    sent_flow() {
      let trail_time = "";
      let trail_end_time = "";
      if (this.flow_time) {
        trail_time = this.getwaytime(this.flow_time[0]);
        trail_end_time = this.getwaytime(this.flow_time[1]);
      }
      let file_url = [];
      for (let i in this.img_list) {
        file_url.push({ file_url: this.img_list[i].file_url });
      }
      file_url = JSON.stringify(file_url);
      addPlanLog({
        permissions_id_string: this.permissions_id_string,
        my_customer_id: this.row.my_customer_id,
        customer_plan_id: this.row.id,
        status: this.row.status,
        result: this.plan_end,
        trail_time,
        trail_end_time,
        file_url,
        address: this.address,
        visit_status: this.is_tegether,
        visit_people: this.flow_human,
        purpose: this.objective,
        visit_name: this.tegether_name,
        // purpose
      }).then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          this.notes_show = false;
          if (this.show_detail_flag) {
            this.take_detail(this.row);
          }
          this.plan_end = "";
          this.flow_time = "";
          this.img_list = [];
          this.is_tegether = "2";
          this.flow_human = "";
          this.objective = "";
          this.getlist();
        }
      });
    },
    dataURItoBlob(dataURI) {
      // base64 解码

      let byteString = window.atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let T = mimeString.split("/")[1];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    upload(imgUrl) {
      let formData = new FormData();
      this.$message({
        message: "上传中，请稍后",
      });
      formData.append("file", imgUrl);
      uploadFile(formData).then((res) => {
        if (res.data.code == 200) {
          this.img_list.push(res.data.data);
          this.$message({
            message: "上传成功",
            type: "success",
          });
        }
        // console.log(res);
      });
    },

    beforeUploadForm(file) {
      // 验证文件类型
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "jpg" || testmsg === "png" || testmsg === "gif";
      if (!extension) {
        this.$message({
          message: "上传文件只能是jpg/png/gif格式!",
          duration: 1000,
          showClose: true,
          type: "warning",
        });
      }
      return extension;
    },
    getlist(i, is_reload) {
      this.tableData = [];
      console.log(this.companyList);
      //获取列表
      if (i) {
        this.page = 1;
      }
      if (is_reload) {
        this.compony_name = "";
        this.page = 1;
        this.date = "";
        this.value = "";
        this.search_product_name_id = "";
        this.search_intention = "";
        this.is_expected_arrival = "";
        this.company_id = [];
        // 清空选中的节点
        this.$refs.myCascader.$refs.panel.clearCheckedNodes();
        this.$refs.cascader.$refs.panel.clearCheckedNodes();
        // 设置为空可以让节点不高亮显示
        this.$refs.myCascader.$refs.panel.activePath = [];
        this.$refs.cascader.$refs.panel.activePath = [];
        // setTimeout(()=>{
        //     selectOrderCompanyWhere().then(res=>{
        //     if (res.data.code==200) {
        //         function dg(list){
        //             list.forEach(item=>{
        //                 item.label = item.name;
        //                 item.value = item.id;
        //                 if(item.data){
        //                     item.children = item.data;
        //                     dg(item.data);
        //                 }
        //             })
        //             return list;
        //         }
        //         let list = res.data.data
        //         dg(list)
        //             this.options = list
        //         }
        //     })
        // },500)
        this.flow_human_name = "";
      }
      let search_start_time = "";
      let search_end_time = "";
      if (this.date) {
        search_start_time = this.getwaytime(this.date[0]);
        search_end_time = this.getwaytime(this.date[1]);
      }
      console.log(this.search_product_name_id);
      planList({
        permissions_id_string: this.permissions_id_string,
        search_product_name_id: this.search_product_name_id[1],
        search_intention: this.search_intention,
        is_expected_arrival: this.is_expected_arrival == 1 ? "is_expected_arrival" : "",
        type: this.status_check,
        class_type: this.human_check,
        status: this.value,
        page: this.page,
        search_start_time,
        search_end_time,
        search_name: this.compony_name,
        follow_name: this.flow_human_name,
        company_id: this.company_id,
      }).then((res) => {
        // this.itemKey = Math.random()
        // this.$set(this.data, this.tableData, res.data.data.data)
        this.tableData = res.data.data.data;
        this.total = res.data.data.total;
      });
    },
    take_detail(row) {
      this.isFollow = true;
      customerHead({
        permissions_id_string: this.permissions_id_string,
        my_customer_id: row.my_customer_id,
      }).then((res) => {
        // console.log(res);
        this.customerHead_info = res.data.data;
      });
      followList({
        permissions_id_string: this.permissions_id_string,
        my_customer_id: row.my_customer_id,
      }).then((res) => {
        this.follow_List = res.data.data.data;
      });
    },
    handleclosePlan() {
      this.isFollow = false;
    },
    newcommand(row, command) {
      return {
        row: row,
        command: command,
      };
    },
    handleCommand(command) {
      if (command.command == 1) {
        this.watch_detail(command.row, 0);
      } else {
        this.notes_detail(command.row, 0);
      }
    },
    notes_detail(row, flag) {
      if (flag) {
        this.show_detail_flag = true;
      } else {
        this.show_detail_flag = false;
      }
      this.row = row;
      this.my_customer_id = row.my_customer_id;
      this.notes_show = true;
      this.plan_id = row.id;
      getPlan({
        permissions_id_string: this.permissions_id_string,
        plan_id: row.id,
      }).then((res) => {
        let want = "";
        let prodect_array = [];
        for (let i in res.data.data.my_customer_product) {
          want += res.data.data.my_customer_product[i].product_name.product_name + ",";
          prodect_array.push({
            product_name_id: res.data.data.my_customer_product[i].product_name.id,
            intention: res.data.data.my_customer_product[i].intention,
          });
        }
        this.prodect_array = prodect_array;
        if (want.length > 0) {
          want = want.substring(0, want.length - 1);
        }
        res.data.data.want = want;
        this.info_detail = res.data.data;
        this.flow_kind = res.data.data.status;
        this.flow_plan = res.data.data.plan;
        this.check_time = res.data.data.to_time;
      });
    },
    watch_detail(row, flag) {
      //row单条数据  flag 控制是否显示详情列表
      if (flag) {
        this.show_detail_flag = true;
      } else {
        this.show_detail_flag = false;
      }
      this.row = row;
      this.my_customer_id = row.my_customer_id;
      this.show_flag = 1;
      this.order_show = true;
      this.plan_id = row.id;
      getPlan({
        permissions_id_string: this.permissions_id_string,
        plan_id: row.id,
      }).then((res) => {
        let want = "";
        let prodect_array = [];
        for (let i in res.data.data.my_customer_product) {
          want += res.data.data.my_customer_product[i].product_name.product_name + ",";
          prodect_array.push({
            product_name_id: res.data.data.my_customer_product[i].product_name.id,
            intention: res.data.data.my_customer_product[i].intention,
          });
        }
        this.prodect_array = prodect_array;
        if (want.length > 0) {
          want = want.substring(0, want.length - 1);
        }
        res.data.data.want = want;
        this.info_detail = res.data.data;
        this.flow_kind = res.data.data.status;
        this.flow_plan = res.data.data.plan;
        this.check_time = res.data.data.to_time;
      });
      // }
    },
    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let strDate = Y + M + D + h + m + s;
      return strDate;
    },
    take_new_order() {
      this.order_show = true;
    },
    handleSizeChange(e) {
      // console.log(e);
    },
    handleCurrentChange(e) {
      this.page = e;
      this.getlist();
    },
    sent_new_plan() {
      let to_time = this.getwaytime(this.check_time);
      let product_ids = JSON.stringify(this.prodect_array);
      editPlan({
        permissions_id_string: this.permissions_id_string,
        plan: this.flow_plan,
        plan_id: this.plan_id,
        status: this.flow_kind,
        my_customer_id: this.my_customer_id,
        to_time,
        product_ids,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.order_show = false;
          if (this.show_detail_flag) {
            this.take_detail(this.row);
          }
          this.getlist();
        }
      });
    },
  },
  watch: {
    status_check() {
      // console.log(this.status_check);
      if (this.status_check == 2) {
        this.check_list = [
          {
            name: "编辑计划",
            id: "1",
          },
          {
            name: "填写结果",
            id: "2",
          },
        ];
      } else {
        this.check_list = [
          {
            name: "编辑",
            id: "1",
          },
        ];
      }
      if (this.status_check == 1) {
        this.prodect_log = [
          // { id: 1, name: "面访" },
          { id: 2, name: "来访" },
          { id: 3, name: "出访" },
          { id: 4, name: "网签" },
        ];
      } else {
        this.prodect_log = [
          { id: 1, name: "电话" },
          { id: 2, name: "微信" },
          { id: 3, name: "出访" },
          { id: 4, name: "来访" },
          {
            id: 5,
            name: "其他",
          },
        ];
      }
      this.getlist(1);
    },
    human_check() {
      // 清空选中的节点
      this.$refs.cascader.$refs.panel.clearCheckedNodes();
      // 设置为空可以让节点不高亮显示
      this.$refs.cascader.$refs.panel.activePath = [];
      (this.is_expected_arrival = ""), (this.search_intention = "");
      this.getlist(1);
    },
  },
};
</script>

<style lang="scss">
.v-modal {
  z-index: 21 !important;
}

.Account_box {
  .el-date-editor .el-range-separator {
    width: 20px;
  }
  height: 100%;
  width: 100%;
  padding: 20px;
  background: #eef5f9;
  .acc_table_company_name {
    width: 100%;
    height: 100%;
    // overflow: hidden;/*超出部分隐藏*/
    // white-space: nowrap;/*不换行*/
    // text-overflow:ellipsis;/*超出部分文字以...显示*/
  }
  .acc_table_plan {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .acc_img_shadow {
    widows: 100%;
    height: 100%;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2000;
    height: 100%;
    img {
      display: block;
      width: 300px;
      max-height: 600px;
      margin: 200px auto;
    }
  }
  .el-main {
    background: #ffffff;
    // height: 100%;
  }
  .new_acc_shadow {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .image_info {
    border-radius: 5px;
    width: 100px;
    height: 100px;
    position: relative;
    display: flex;
    background: #ececec;
    // color: #3A85FC;
    color: rgba(0, 0, 0, 0.28);
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .flow_img_listinfo {
    display: flex;
    .img_item_info {
      width: 100px;
      height: 100px;
      position: relative;
      margin-right: 10px;
      .big_img {
        width: 100%;
        height: 100%;
      }
      .del_img {
        position: absolute;
        top: -5px;
        right: -5px;
        color: #ccc;
      }
    }
  }

  .acc_shadow {
    position: fixed;
    z-index: 120;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    .info {
      width: 772px;
      height: 600px;
      margin: 100px auto;
      background: #ffffff;
      border: 1px solid #707070;
      opacity: 1;
      position: relative;
      border-radius: 5px;
      z-index: 999;
      // padding-top: 66px;
      box-sizing: border-box;
      .take_new_title {
        // top: -66px;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 66px;
        position: relative;
        border-bottom: 1px solid #ccc;
        text-align: center;
        line-height: 66px;
        font-size: 18px;
        i {
          position: absolute;
          top: 23px;
          right: 23px;
        }
      }
      .take_new_detail {
        height: 468px;
        width: 100%;
        box-sizing: border-box;
        overflow: auto;
        padding: 20px;
        .acc_top_compony {
          .kf_detail {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .quan {
              height: 5px;
              width: 5px;
              border-radius: 2.5px;
              background: #3a85fc;
              margin-right: 6px;
            }
          }

          .kh_detail {
            padding: 20px;
            width: 100%;
            box-sizing: border-box;
            height: 80px;
            margin-top: 10px;
            display: inline-block;
            background: #f8f8f8;
            div {
              overflow: hidden; /*超出部分隐藏*/
              white-space: nowrap; /*不换行*/
              text-overflow: ellipsis; /*超出部分文字以...显示*/
              width: 50%;
              display: inline-block;
              height: 50%;
            }
          }
        }
      }
      .flow_kind {
        width: 100%;
        display: flex;
        margin-top: 20px;
        white-space: nowrap; /*不换行*/
        align-items: center;
        .el-select {
          width: 250px;
        }
        .el-input__inner {
          width: 250px;
        }
        .el-date-editor .el-range-input {
          overflow: hidden; /*超出部分隐藏*/
          white-space: nowrap; /*不换行*/
          text-overflow: ellipsis; /*超出部分文字以...显示*/
        }
        .el-textarea__inner {
          width: 320px;
        }
        .el-date-editor.el-input {
          width: 250px;
        }
      }
      .take_bottom {
        height: 66px;
        position: relative;
        bottom: 0;
        width: 100%;
        display: flex;
        // align-items: center;
        // justify-content: center;
        box-sizing: border-box;
        justify-content: flex-end;
        padding-right: 40px;
        align-items: center;
        .bottom_sent {
          width: 80px;
          height: 40px;
          background: #3a85fc;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 1;
          color: #fff;
          border-radius: 4px;
        }
      }
      .flow_kind_text {
        width: 65%;
        display: flex;
        margin-top: 20px;
        // align-items: center;
        padding-top: 1px;
        // white-space: nowrap;
        .el-textarea {
          width: 400px;
          textarea {
            resize: none;
          }
        }

        .el-dialog__wrapper {
          z-index: 2010 !important;
        }
      }
    }
    .acc_commen {
      width: 445px;
      height: 426px;
      background: #ffffff;
      border: 1px solid #707070;
      opacity: 1;
      border-radius: 10px;
      margin: 100px auto;
      position: relative;
      .acc_commen_title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;
        position: relative;
        height: 40px;
        border-bottom: 1px solid #ccc;
        i {
          position: absolute;
          // top: ;
          right: 10px;
        }
      }
      .acc_commen_body {
        width: 100%;
        resize: none;
        padding: 10% 10%;
        .el-textarea__inner {
          resize: none;
          height: 270px;
        }
      }
      .acc_commen_sent {
        position: absolute;
        right: 10px;
        padding: 0 10px;
        height: 25px;
        line-height: 25px;
        bottom: 10px;
        background: #3a85fc;
        color: #fff;
        border-radius: 5px;
      }
    }
  }
  .account_title {
    display: flex;
    flex-wrap: wrap;
    .account_info {
      display: flex;
      width: 20%;
      white-space: nowrap;
      align-items: center;
      margin-left: 2%;
      margin-bottom: 10px;
      .el-input .el-input--large .el-input--suffix {
        flex: 1;
      }
      .el-input__inner {
        -webkit-appearance: none;
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 32px;
        line-height: 32px;
        outline: 0;
        padding: 0 15px;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
      }
      .el-input__icon {
        height: 100%;
        width: 25px;
        text-align: center;
        transition: all 0.3s;
        line-height: 32px;
      }
      .el-date-editor .el-range-input {
        overflow: hidden; /*超出部分隐藏*/
        white-space: nowrap; /*不换行*/
        text-overflow: ellipsis; /*超出部分文字以...显示*/
      }
      .el-date-editor .el-range-separator {
        width: 30px;
      }
      .el-cascader {
        flex: 1;
      }
    }
    .ssa {
      width: 0px !important; //时间选择框前面的图标
    }
    .account_info:nth-of-type(1) {
      margin-left: 0%;
    }
    .account_serch {
      width: 70px;
      height: 32px;
      background: #3a85fc;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      border-radius: 2px;
    }
    .account_reload {
      width: 70px;
      height: 32px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
      opacity: 1;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
      color: #666;
    }
    .account_info:nth-of-type(4) {
      width: 19%;
    }
  }
  .acc_info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    .left_add {
      display: flex;
      width: 150px;
      height: 40px;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      border: 1px solid #ccc;
      box-sizing: border-box;
      div {
        height: 100%;
        color: #666;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .act_title {
        background: #3a85fc;
        color: #fff;
      }
      border-radius: 4px;
      i {
        margin-right: 5px;
      }
    }
    .right_check {
      width: 300px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #cccccc;
      opacity: 1;
      border-radius: 4px;
      box-sizing: border-box;
      display: flex;
      div {
        color: #666;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .act_title {
        background: #3a85fc;
        color: #fff;
      }
      div:nth-of-type(2) {
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
      }
    }
  }
  .acc_table {
    margin-top: 20px;
    .el-table__header {
      background: #999 !important;
    }
    .el-pagination {
      margin-top: 10px;

      margin-top: 10px;
      display: flex;
      justify-content: flex-end;
    }
    .acc_moreinfo {
      display: flex;
      white-space: nowrap;
    }
  }
}

.Follow_customer_info:nth-last-of-type(1) {
  border-bottom: 1px solid transparent !important;
}

.Follow {
  z-index: 99;
  width: 40%;
  height: 90%;
  background: #ffff;
  margin: auto;
  // position: fixed;
  margin-top: 50px;
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  overflow: hidden;
  min-width: 650px;
  .Follow_head {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 60px;
    font-size: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    i {
      position: absolute;
      right: 50px;
    }
  }
  .Follow_info {
    overflow: auto;
    // padding-top: 60px;
    height: 90%;
  }
  .Follow_tit {
    padding: 0 30px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.8);
    opacity: 1;
    display: flex;
    align-items: center;
    margin: 20px 0;
    i {
      display: block;
      width: 6px;
      height: 6px;
      background: #3a85fc;
      border-radius: 50%;
      opacity: 1;
      margin-right: 10px;
    }
  }
  .Follow_none {
    color: #ccc;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tx_follow {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    div {
      height: 30px;
      padding: 0 5px;
      color: #fff;
      display: flex;
      align-items: center;
      background: #3a85fc;
      border-radius: 5px;
    }
  }

  .Follow_customer_info {
    padding: 0px 40px;
    .Follow_customer_info_box {
      background: #f8f8f8;
      padding: 0 40px;
      display: flex;
      flex-direction: column;
      height: 60px;
      justify-content: space-around;
      .Follow_customer_info_box_line {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: rgba(0, 0, 0, 0.85);
        justify-content: space-between;
        .Follow_customer_info_box_line_one {
          display: flex;
          .Follow_customer_info_box_line_one_tit {
            color: rgba(0, 0, 0, 0.5);
          }
          .Follow_customer_info_box_line_one_info {
            color: rgba(0, 0, 0, 0.8);
          }
        }
      }
    }
    .Follow_customer_info_plan {
      background: #fff;
      // padding:  0 40px ;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      position: relative;

      .Follow_customer_info_plan_bottom {
        position: absolute;
        bottom: -30px;
        right: 20px;
        width: 80px;
        height: 35px;
        background: #3a85fc;
        opacity: 1;
        border-radius: 4px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #ffffff;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .Follow_customer_info_plan_line {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: rgba(0, 0, 0, 0.85);
        // justify-content: space-between;
        width: 100%;
        margin: 10px 0;
        .Follow_customer_info_plan_line_one {
          display: flex;
          .Follow_customer_info_plan_line_one_tit {
            color: rgba(0, 0, 0, 0.5);
            white-space: nowrap;
          }
          .Follow_customer_info_plan_line_one_info {
            color: rgba(0, 0, 0, 0.8);
            white-space: nowrap;
            flex: 1;
          }
          .Follow_customer_info_plan_line_one_img {
            display: flex;
            img {
              width: 60px;
              height: 60px;
              margin-right: 20px;
            }
          }
          .Follow_customer_info_plan_line_one_time {
            width: 66px;
            height: 20px;
            background: rgba(58, 133, 252, 0.1);
            opacity: 1;
            border-radius: 20px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 16px;
            color: #3a85fc;
            opacity: 1;
            margin-left: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .Follow_customer_info_plan_line_one:nth-child(2) {
          margin: 0 10%;
        }
      }
    }
  }
}
.wx {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 32px;
  height: 32px;
}
.screen_input {
  width: 100%;
}
</style>
