<template>
  <div class="finace">
    <el-container>
      <el-aside width="250px">
        <el-menu @select="handleSelect" :default-active="isSelect">
          <el-submenu index="1" class="el_menu_left" v-if="role_what.Arrival ||role_what.refund||role_what.promotion||role_what.Approval_refund ">
            <template slot="title">
              <img src="../../assets/img/dzgl_yes.png" alt="" />
              业绩审核管理</template
            >
            <el-menu-item index="到账审核" v-if="role_what.Arrival">到账审核</el-menu-item>
            <el-menu-item index="促销审核" v-if="role_what.promotion">促销审核</el-menu-item>
            <el-menu-item index="退款审核" v-if="role_what.Approval_refund">退款审核</el-menu-item>
            <el-menu-item index="退款管理" v-if="role_what.refund">退款管理</el-menu-item>
          </el-submenu>
          <el-submenu index="3" class="el_menu_left" v-if="role_what.week_mission || role_what.mission|| role_what.Approval_mission">
            <template slot="title">
              <i
                class="el-icon-s-claim"
                style="color: #3a85fc; width: auto"
              ></i>
              业绩任务管理
            </template>
            <el-menu-item index="周节点设置" v-if="role_what.week_mission">周节点设置</el-menu-item>
            <el-menu-item index="任务审核" v-if="role_what.mission">任务审核</el-menu-item>
            <el-menu-item index="任务设置" v-if="role_what.Approval_mission">任务设置</el-menu-item>
          </el-submenu>
          <!--<el-submenu index="4" class="el_menu_left" v-if="role_what.is_fp">-->
            <!--<template slot="title">-->
            <!--<i class="iconfont icon-tree" style="margin-right:5px;color:#3a85fc;" ></i>-->
              <!--老客户分配-->
            <!--</template>-->
            <!--<el-menu-item index="分配客户">分配客户</el-menu-item>-->
          <!--</el-submenu>-->
        </el-menu>
      </el-aside>
      <router-view></router-view>
    </el-container>
  </div>
</template>

<script>
// import Refund from "./Refund";
// import FinaceDzgl from "./Finace_Dzgl";
// import mission_control from "./mission_control";
export default {
  components: {
    // FinaceDzgl: FinaceDzgl,
    // Refund: Refund,
    // "Mission-control": mission_control,
  },
  data() {
    return {
      isSelect: "",
      role_what: {
        Arrival: false,       //到账列表
        mission: false,
        // refund: false,
        promotion:false,      //促销
        is_fp:false,
        refund: false,      //退款管理
        promotion:false,      //促销审核
        Approval_refund:false,      //退款审核
        week_mission:false,       //周节点
        mission: false,         //任务设置
        Approval_mission:false,     //任务审核
      },
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      let roleList = JSON.parse(localStorage.getItem("token"))
        .role_permissions;
      roleList.forEach((item) => {
        if (item.permissions == "Finance") {
          item.data.forEach((itm) => {
            if (itm.permissions == "order_list") {        //到账审核列表
              this.role_what.Arrival = true;
            }
            if (itm.permissions=='Approval_promotion_list') {       //促销审核列表
              this.role_what.promotion = true;
            }
            if (itm.permissions == "Finance_refund_list") {         //退款管理
              this.role_what.refund = true;
            }
            if (itm.permissions == "Approval_refund_list") {         //退款审核
              this.role_what.Approval_refund = true;
            }
             if (itm.permissions == "mission_list") {        //周节点设置
              this.role_what.week_mission = true;
            }
            if (itm.permissions == "Finance_mission_list") {        //任务设置
              this.role_what.mission = true;
            }
            if (itm.permissions == "Approval_mission_list") {        //任务审核
              this.role_what.Approval_mission = true;
            }
          });
        }
      });
      let token=JSON.parse(localStorage.getItem("token"));
      for(let i in token.role){
        if(token.role[i].permissions=='Finance_user_list'){
          this.role_what.is_fp=true;
        }
      }
    }
  },
  mounted(){
      // console.log(document);
    if (localStorage.getItem("Finance_select")) {
      this.isSelect = localStorage.getItem("Finance_select");
      this.handleSelect(this.isSelect);
    } else {
      let menu=document.querySelector('.el-menu li>ul>li');
      // console.log(menu.innerHTML);
      this.isSelect = menu.innerHTML;
      this.handleSelect(menu.innerHTML,'');
      // this.$router.push("/Finance/FinaceDzgl");
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      this.isSelect = key;
      localStorage.setItem("Finance_select", key);
      if (key == "到账审核") {
        this.$router.push("/Finance/FinaceDzgl");
      }
      if (key == "促销审核") {
        this.$router.push("/Finance/Promotion_audit");
      }
      if (key == "退款审核") {
        this.$router.push("/Finance/Refund_audit");
      }
       if (key == "退款管理") {
        this.$router.push("/Finance/Refund");
      }
      if (key=="周节点设置") {
        this.$router.push("/Finance/Node_mission");
      }
       if (key=="任务审核") {
        this.$router.push("/Finance/Mission_audit");
      }
      if (key=="任务设置") {
        this.$router.push("/Finance/mission_control");
      }
      if(key=='分配客户'){
        this.$router.push("/Finance/distribute");
      }
    },
  },
  computed:{
      fullPath(){
        // console.log(this.$store.state.fullPath);
        return this.$store.state.fullPath
      }
  },
  watch:{
      fullPath(val){
        // console.log(this.$store.state.fullPath);
        // console.log(val);
        if(val=='/Finance'){
            let menu=document.querySelector('.el-menu li>ul>li');
            // console.log(menu.innerHTML);
            this.isSelect = menu.innerHTML;
            this.handleSelect(menu.innerHTML,'');
        }
      }
  }
};
</script>

<style lang="scss">
.finace {
  height: 100%;
  width: 100%;
  .el-container {
    width: 100%;
    height: 100%;
    display: flex;
  }

  aside {
    background: #fff;
    height: 100%;
    .el_menu_left {
      img {
        margin-right: 10px;
      }
    }
    .el-menu {
      border-right: none;
    }
  }
}
</style>