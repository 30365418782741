<template>
  <div class="approva">
    <el-container style="height: 100%; width: 100%">
      <el-aside width="250px">
        <el-menu @select="handleApproval" :default-active="is_Approva">
          <el-submenu index="1" class="el_menu_left">
            <template slot="title">
              <img
                style="margin-right: 5px"
                src="../../assets/img/Approval_center.png"
                alt=""
              />
              审批中心</template
            >
            <el-menu-item index="1-1" v-if="role_what.mission">
              任务审核
            </el-menu-item>
            <el-menu-item index="1-2" v-if="role_what.refund">
              退款审核
            </el-menu-item>
            <el-menu-item index="1-3" v-if="role_what.promotion">
              促销审核
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <router-view></router-view>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_Approva: "1-1",
      roleList: {},
      role_what: {
        mission: false,
        promotion: false,
        refund: false,
      },
    };
  },
  watch: {
    $route(to, from) {
      if (to.path == "/Approval/Mission_audit") {
        this.handleApproval("1-1");
      }
    },
  },
  created() {
    if (localStorage.getItem("is_Approva")) {
      this.is_Approva = localStorage.getItem("is_Approva");
    } else {
      this.is_Approva = "1-1";
    }
    if (this.is_Approva == "1-1") {
      this.$router.push("/Approval/Mission_audit");
    }
    // if (this.is_Approva == "1-2") {
    //   this.$router.push("/Approval/Refund_audit");
    // }
    if (this.is_Approva == "1-3") {
      this.$router.push("/Approval/Promotion_audit");
    }

    if (localStorage.getItem("token")) {
      let roleList = JSON.parse(localStorage.getItem("token")).role_permissions;
      roleList.forEach((item) => {
        if (item.permissions == "Approval") {
          this.roleList = item;
          item.data.forEach((itm) => {
            if (itm.permissions == "Approval_promotion_list") {
              this.role_what.promotion = true;
            }
            if (itm.permissions == "Approval_mission_list") {
              this.role_what.mission = true;
            }
            if (itm.permissions == "Approval_refund_list") {
              this.role_what.refund = true;
            }
          });
        }
      });
    }
  },
  methods: {
    handleApproval(key, keyPath) {
      this.is_Approva = key;
      localStorage.setItem("is_Approva", key);
      if (key == "1-1") {
        this.$router.push("/Approval/Mission_audit");
      }
      if (key == "1-2") {
        this.$router.push("/Approval/Refund_audit");
      }
      if (key == "1-3") {
        this.$router.push("/Approval/Promotion_audit");
      }
    },
  },
};
</script>

<style lang="scss">
.approva {
  height: 100%;
  width: 100%;
  display: flex;
  .el-container {
    height: 100%;
    width: 100%;
    .el-aside {
      height: 100%;
      background: #fff;
    }
  }
}
</style>