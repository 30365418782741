import request from "@/utils/axios.tool";

//获取用户信息
export const coreUserDetail = (data) => {
  return request.post(`/roleapi/user/coreUserDetail`, data);
};

//修改用户信息
export const coreEditUser = (data) => {
  return request.post(`/roleapi/user/coreEditUser`, data);
};

//修改用户信息获取验证码
export const modifyPhoneSendCode = (data) => {
  return request.post(`/roleapi/user/modifyPhoneSendCode`, data);
};

//找回密码获取验证码
export const getVerificationCode = (data) => {
  return request.post(`/roleapi/retrievePwd/sendMsg`, data);
};

//找回密码
export const getNewPwd = (data) => {
  return request.post(`/roleapi/retrievePwd`, data);
};

//获取图形验证码
export const getCaptcha = (data) => {
  return request.post(`/roleapi/getCaptcha`, data);
};
