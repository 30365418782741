<template>
    <div style="flex: 1" class="cloud-set">
        <el-main>
            <div class="cloud-set_all">
                <div class="cloud_set_right">
                    <div class="right_title">
                        <div class="title_right" @click="show_draw(2, 1)" v-if="all_power.is_add_pro">
                            <div class="pointer"><i class="el-icon-plus"></i> 添加产品</div>
                        </div>
                    </div>
                    <div class="right_body">
                        <el-table :data="tableData" height="250" border style="width: 100%">
                            <el-table-column prop="index" label="序号" width="50">
                                <template slot-scope="scope">
                                    <div>{{ scope.row.order_by * 1 }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="product_name" label="分类名称">
                            </el-table-column>
                            <el-table-column prop="value" label="状态" v-if="all_power.is_change_pro">
                                <template slot-scope="scope">
                                    <el-switch
                                            v-model="scope.row.value"
                                            active-color="#13ce66"
                                            inactive-color="#ccc"
                                            @change="change_swith(scope.row)"
                                    >
                                    </el-switch>
                                </template>
                            </el-table-column>
                            <el-table-column prop="address" width="240" label="排序">
                                <template slot-scope="scope">
                                    <div class="prodect_change">
                                        <div
                                                class="el-icon-top"
                                                @click="right_up(scope.row)"
                                                v-if="scope.row.index != 0&&all_power.is_move_pro"
                                        >
                                        </div>
                                        <div
                                                class="el-icon-bottom"
                                                @click="right_down(scope.row)"
                                                v-if="scope.row.index != tableData.length - 1&&all_power.is_move_pro"
                                        >
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="created_time" label="创建时间">
                            </el-table-column>
                            <el-table-column prop="address" width="280" label="操作">
                                <template slot-scope="scope">
                                    <div class="prodect_change">
                                        <div class="pointer" @click="show_draw(3, 0, scope.row)" v-if="all_power.is_change_pro" style="color: #FF3737">
                                            删除
                                        </div>
                                        <div style="color: #eee">|</div>
                                        <div class="pointer" @click="show_draw(2, 0, scope.row)" v-if="all_power.is_change_pro" style="color: #3A85FC">
                                            编辑
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <el-drawer
                        :visible.sync="drawer"
                        :with-header="false"
                        size="40%"
                        class="human_drawer"
                >
                    <!-- 新增分类 -->
                    <div class="drawer_all" v-if="drawer_type == 1">
                        <div class="drawer_title">{{ is_add ? "添加" : "编辑" }}分类</div>
                        <div class="compony_detail">分类信息</div>
                        <div class="drawer_compony_info">
                            <span style="color: #ff0000">*</span>
                            <div class="drawer_nice">分类名称</div>
                            <el-input
                                    type="text"
                                    placeholder="请输入分类名称"
                                    v-model="truename"
                                    maxlength="15"
                                    show-word-limit
                            />
                        </div>
                        <div class="drawer_bottom">
                            <div
                                    class="pointer"
                                    @click="check_detail"
                                    :class="[add_flag ? 'human_nosent' : '']"
                            >
                                确定
                            </div>
                            <div
                                    class="pointer"
                                    style="
                  color: #ff4141;
                  background: #ffffff;
                  border: 1px solid #ff4141;
                "
                                    v-if="0"
                                    @click="del_compony"
                            >
                                删除
                            </div>
                            <div
                                    class="pointer"
                                    style="
                  border: 1px solid #c3c6ce;
                  background: #ffffff;
                  color: #414141;
                "
                                    @click="drawer = false"
                            >
                                取消
                            </div>
                        </div>
                    </div>
                    <!-- 新增产品 -->
                    <div class="drawer_all" v-if="drawer_type == 2">
                        <div class="drawer_title">{{ is_add ? "添加" : "编辑" }}产品</div>
                        <div class="compony_detail">产品信息</div>
                        <div class="drawer_compony_info">
                            <span style="color: #ff0000">*</span>
                            <div class="drawer_nice">产品分类</div>
                            <!-- <el-input
                              type="text"
                              placeholder="请输入产品名称"
                              v-model="truename"
                              maxlength="15"
                              show-word-limit
                            /> -->
                            <el-select v-model="kind_id" placeholder="请选择">
                                <el-option
                                        v-for="item in kind_list"
                                        :key="item.id"
                                        :label="item.class_name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="drawer_compony_info">
                            <span style="color: #ff0000">*</span>
                            <div class="drawer_nice">产品名称</div>
                            <el-input
                                    type="text"
                                    placeholder="请输入产品名称"
                                    v-model="truename"
                                    maxlength="15"
                                    show-word-limit
                            />
                        </div>
                        <div class="drawer_bottom">
                            <div
                                    class="pointer"
                                    @click="check_detail"
                                    :class="[add_flag ? 'human_nosent' : '']"
                            >
                                确定
                            </div>
                            <div
                                    class="pointer"
                                    style="
                  color: #ff4141;
                  background: #ffffff;
                  border: 1px solid #ff4141;
                "
                                    v-if="0"
                                    @click="del_compony"
                            >
                                删除
                            </div>
                            <div
                                    class="pointer"
                                    style="
                  border: 1px solid #c3c6ce;
                  background: #ffffff;
                  color: #414141;
                "
                                    @click="drawer = false"
                            >
                                取消
                            </div>
                        </div>
                    </div>
                </el-drawer>
            </div>
        </el-main>
    </div>
</template>
<script>
    import {

        editProductBy,
        addProductClass,
        addProductName,
        editProductName,
        editProductClass,
    } from "../../api/User/cloud";
    export default {
        data() {
            return {
                check_act: null,
                serch_value: "",
                drawer: false,
                drawer_type: "",
                left_list: [],
                tableData: [],
                left_total: "",
                right_list: [],
                is_change: false,
                is_add: false,
                add_flag: false,
                truename: "",
                product_id: "",
                status: null,
                all_power: {
                    is_add_pro: false,
                    is_change_pro: false,
                    is_move_pro: false,
                },
                role_alldata:{},
                kind_list:[],
                kind_id:''
            };
        },
        created() {
            let newdata = JSON.parse(localStorage.getItem("token"));
            let role_permissions = newdata.role_permissions;
            let newpower = {};
            for (let i in role_permissions) {
                if (role_permissions[i].permissions == "system") {
                    newpower = role_permissions[i];
                    this.role_alldata = role_permissions[i];
                }
            }
            if (JSON.stringify(newpower) != "{}") {
                if (newpower.data.length > 0) {
                    for (let i in newpower.data) {
                        if (newpower.data[i].permissions == "system_pro_list_add") {
                            this.all_power.is_add_pro = true;
                        }
                        if (newpower.data[i].permissions == "system_pro_list_edit") {
                            this.all_power.is_change_pro = true;
                        }
                        if (newpower.data[i].permissions == "system_pro_list_move") {
                            this.all_power.is_move_pro = true;
                        }
                    }
                }
            }
            let str = "";
            for (let i in this.role_alldata.data) {
                if (this.role_alldata.data[i].permissions == "system_pro_list") {
                    str = this.role_alldata.data[i].permissions_id_string;
                }
            }

            let id = ''
            if (localStorage.getItem('kind_id')) {
                id = localStorage.getItem('kind_id')
            }
            list({
                permissions_id_string: str,
            }).then((res) => {
                this.kind_list=res.data.data.data
                this.left_list = res.data.data.data;
                this.left_total = res.data.data.total;
                this.check_kind(id?id:res.data.data.data[0].id);
            });

        },
        methods: {
            // i 是否添加分类 is_add是否添加 item内容
            show_draw(i, is_add, item) {
                this.product_id = "";
                this.truename = "";
                if (is_add) {
                    this.is_add = true;
                } else {
                    this.is_add = false;
                }
                if (i == 1) {
                    if (item) {
                        this.truename = item.class_name;
                    }
                } else {
                    if (item) {
                        this.truename = item.product_name;
                        this.product_id = item.id;
                        this.status = item.value == true ? "1" : "2";
                    }
                }
                let str = "";
                for (let i in this.role_alldata.data) {
                    if (this.role_alldata.data[i].permissions == "system_pro_list") {
                        str = this.role_alldata.data[i].permissions_id_string;
                    }
                }
                getProductList({
                    permissions_id_string: str,
                }).then((res) => {
                    this.kind_list=res.data.data.data
                });
                this.drawer = true;
                this.drawer_type = i;
            },
            check_detail() {
                if (this.add_flag) {
                    return;
                }
                this.add_flag = true;
                if (this.is_add) {
                    if (this.drawer_type == 1) {
                        let str = "";
                        for (let i in this.role_alldata.data) {
                            if (this.role_alldata.data[i].permissions == "system_pro_list_add") {
                                str = this.role_alldata.data[i].permissions_id_string;
                            }
                        }
                        addProductClass({
                            permissions_id_string: str,
                            name: this.truename }).then((res) => {
                            this.add_flag = false;
                            if (res.data.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: res.data.message,
                                });
                                this.drawer = false;
                                let str = "";
                                for (let i in this.role_alldata.data) {
                                    if (this.role_alldata.data[i].permissions == "system_pro_list") {
                                        str = this.role_alldata.data[i].permissions_id_string;
                                    }
                                }
                                getProductList({
                                    permissions_id_string: str,
                                }).then((res) => {
                                    this.left_list = res.data.data.data;
                                    this.left_total = res.data.data.total;
                                });
                            }
                        });
                    } else {
                        let str = "";
                        for (let i in this.role_alldata.data) {
                            if (this.role_alldata.data[i].permissions == "system_pro_list_add") {
                                str = this.role_alldata.data[i].permissions_id_string;
                            }
                        }
                        addProductName({
                            permissions_id_string: str,
                            name: this.truename,
                            product_id: this.kind_id,
                        }).then((res) => {
                            this.add_flag = false;
                            if (res.data.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: res.data.message,
                                });
                                this.drawer = false;
                                let str=''
                                for (let i in this.role_alldata.data) {
                                    if (this.role_alldata.data[i].permissions == "system_pro_list") {
                                        str = this.role_alldata.data[i].permissions_id_string;
                                    }
                                }
                                // getProductList({
                                //   permissions_id_string: str,
                                //   product_id: this.check_act }).then((res) => {
                                //   this.tableData = res.data.data.data;
                                // });
                                this.check_kind(this.check_act);
                            }
                        });
                    }
                    //修改
                } else {
                    if (this.drawer_type == 1) {
                        let str=''
                        for (let i in this.role_alldata.data) {
                            if (this.role_alldata.data[i].permissions == "system_pro_list_edit") {
                                str = this.role_alldata.data[i].permissions_id_string;
                            }
                        }
                        editProductClass({
                            permissions_id_string: str,
                            name: this.truename,
                            id: this.check_act,
                            status: 1,
                        }).then((res) => {
                            this.add_flag = false;
                            if (res.data.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: res.data.message,
                                });
                                this.drawer = false;
                                let str=''
                                for (let i in this.role_alldata.data) {
                                    if (this.role_alldata.data[i].permissions == "system_pro_list") {
                                        str = this.role_alldata.data[i].permissions_id_string;
                                    }
                                }
                                getProductList({
                                    permissions_id_string: str,}).then((res) => {
                                    this.left_list = res.data.data.data;
                                    this.left_total = res.data.data.total;
                                });
                            }
                        });
                    } else {
                        let str=''
                        for (let i in this.role_alldata.data) {
                            if (this.role_alldata.data[i].permissions == "system_pro_list_edit") {
                                str = this.role_alldata.data[i].permissions_id_string;
                            }
                        }

                        editProductName({
                            permissions_id_string: str,
                            product_id: this.kind_id,
                            name: this.truename,
                            id: this.product_id,
                            status: this.status,
                        }).then((res) => {
                            if (res.data.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: res.data.message,
                                });
                                this.drawer = false;
                                this.add_flag = false;
                                this.check_kind(this.check_act);
                            }
                        });
                    }
                }
            },
            change_swith(item) {
                let str=''
                for (let i in this.role_alldata.data) {
                    if (this.role_alldata.data[i].permissions == "system_pro_list_edit") {
                        str = this.role_alldata.data[i].permissions_id_string;
                    }
                }

                editProductName({
                    permissions_id_string: str,
                    name: item.product_name,
                    id: item.id,
                    status: item.value ? "1" : "2",
                }).then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            type: "success",
                            message: res.data.message,
                        });
                        this.drawer = false;
                        this.add_flag = false;
                    }
                });
            },
            check_kind(id) {  //点击确定种类
                if (this.is_change) {
                    return;
                }
                localStorage.setItem('kind_id',id)
                this.is_change = true;
                this.check_act = id;
                this.kind_id=id
                if(this.serch_value !=''){
                    this.serch_btn();
                    return
                }
                let str=''
                for (let i in this.role_alldata.data) {
                    if (this.role_alldata.data[i].permissions == "system_pro_list") {
                        str = this.role_alldata.data[i].permissions_id_string;
                    }
                }
                getProductList({
                    permissions_id_string: str, product_id: id }).then((res) => {
                    this.is_change = false;
                    let data = res.data.data.data;
                    for (let i in data) {
                        if (!data[i].created_at) {
                            data[i].created_time = "--";
                        } else {
                            data[i].created_time = data[i].created_at;
                        }
                        if (!data[i].deleted_at) {
                            data[i].value = true;
                        } else {
                            data[i].value = false;
                        }
                        data[i].index = i * 1;
                    }
                    this.tableData = data;
                });

            },
            left_up(id, index) {
                if (this.is_change) {
                    return;
                }
                this.is_change = true;
                var str='';
                for (var i in this.role_alldata.data) {
                    if (this.role_alldata.data[i].permissions == "system_pro_list_move") {
                        str = this.role_alldata.data[i].permissions_id_string;
                    }
                }

                editProductBy({
                    permissions_id_string: str,
                    old_by: this.left_list[index].order_by,
                    new_by: this.left_list[index - 1].order_by,
                    product_id: this.left_list[index].id,
                }).then((res) => {
                    this.is_change = false;
                    if (res.data.code == 200) {
                        var h = this.left_list.splice(index, 1);
                        this.left_list.splice(index - 1, 0, h[0]);
                        this.$forceUpdate();
                    }
                });
            },
            left_down(id, index) {
                if (this.is_change) {
                    return;
                }
                this.is_change = true;
                let str=''
                for (let i in this.role_alldata.data) {
                    if (this.role_alldata.data[i].permissions == "system_pro_list_move") {
                        str = this.role_alldata.data[i].permissions_id_string;
                    }
                }

                editProductBy({
                    permissions_id_string: str,
                    old_by: this.left_list[index].order_by,
                    new_by: this.left_list[index + 1].order_by,
                    product_id: this.left_list[index].id,
                }).then((res) => {
                    this.is_change = false;
                    if (res.data.code == 200) {
                        let h = this.left_list.splice(index, 1);
                        this.left_list.splice(index + 1, 0, h[0]);
                        this.$forceUpdate();
                    }
                });
            },
            right_up(row) {
                if (this.is_change) {
                    return;
                }
                this.is_change = true;
                let { index } = row;
                if (index == 0) {
                    return;
                }
                let str=''
                for (let i in this.role_alldata.data) {
                    if (this.role_alldata.data[i].permissions == "system_pro_list_move") {
                        str = this.role_alldata.data[i].permissions_id_string;
                    }
                }

                editProductBy({
                    permissions_id_string: str,
                    old_by: this.tableData[index].order_by,
                    new_by: this.tableData[index - 1].order_by,
                    product_id: this.check_act,
                    id: this.tableData[index].id,
                }).then((res) => {
                    this.is_change = false;
                    if (res.data.code == 200) {
                        this.check_kind(this.check_act);
                    }
                });
            },
            right_down(row) {
                if (this.is_change) {
                    return;
                }

                let { index } = row;
                this.is_change = true;
                let str=''
                for (let i in this.role_alldata.data) {
                    if (this.role_alldata.data[i].permissions == "system_pro_list_move") {
                        str = this.role_alldata.data[i].permissions_id_string;
                    }
                }

                editProductBy({
                    permissions_id_string: str,
                    old_by: this.tableData[index].order_by,
                    new_by: this.tableData[index + 1].order_by,
                    product_id: this.check_act,
                    id: this.tableData[index].id,
                }).then((res) => {
                    this.is_change = false;
                    if (res.data.code == 200) {
                        this.check_kind(this.check_act);
                    }
                });
            },
            right_top(row) {
                let { index } = row;
                if (this.is_change) {
                    return;
                }
                this.is_change = true;
                let str=''
                for (let i in this.role_alldata.data) {
                    if (this.role_alldata.data[i].permissions == "system_pro_list_move") {
                        str = this.role_alldata.data[i].permissions_id_string;
                    }
                }

                editProductBy({
                    permissions_id_string: str,
                    old_by: this.tableData[index].order_by,
                    new_by: this.tableData[0].order_by,
                    product_id: this.check_act,
                    id: this.tableData[index].id,
                }).then((res) => {
                    this.is_change = false;
                    if (res.data.code == 200) {
                        this.check_kind(this.check_act);
                    }
                });
            },
            right_bottom(row) {
                let { index } = row;

                if (this.is_change) {
                    return;
                }
                this.is_change = true;
                let str=''
                for (let i in this.role_alldata.data) {
                    if (this.role_alldata.data[i].permissions == "system_pro_list_move") {
                        str = this.role_alldata.data[i].permissions_id_string;
                    }
                }

                editProductBy({
                    permissions_id_string: str,
                    old_by: this.tableData[index].order_by,
                    new_by: this.tableData[this.tableData.length - 1].order_by,
                    product_id: this.check_act,
                    id: this.tableData[index].id,
                }).then((res) => {
                    this.is_change = false;
                    if (res.data.code == 200) {
                        this.check_kind(this.check_act);
                    }
                });
            },
            serch_btn() {
                let str=''
                for (let i in this.role_alldata.data) {
                    if (this.role_alldata.data[i].permissions == "system_pro_list") {
                        str = this.role_alldata.data[i].permissions_id_string;
                    }
                }
                getProductList({
                    permissions_id_string: str,
                    product_id: this.check_act,
                    product_name: this.serch_value,
                }).then((res) => {
                    this.is_change = false;
                    let data = res.data.data.data;
                    for (let i in data) {
                        if (!data[i].created_at) {
                            data[i].created_time = "--";
                        } else {
                            data[i].created_time = data[i].created_at;
                        }
                        if (!data[i].deleted_at) {
                            data[i].value = true;
                        } else {
                            data[i].value = false;
                        }
                        data[i].index = i * 1;
                    }
                    this.tableData = data;
                });
            },
            recheck() {
                this.serch_value = "";
                this.check_kind(this.check_act);
            },
        },
        beforeDestroy(){
            localStorage.removeItem('kind_id')
        }
    };
</script>

<style lang="scss">
    .cloud-set_all {
        // min-width: 1200px;
        width:100%;
        // margin: 0 auto;
        // margin-top: -11px;
        border: 1px solid #dedede;
        overflow: hidden;
        height: 800px;
        box-sizing: border-box;
        .cloud_set_right {
            // width: 938px;
            width:100%;
            background: #fff;
            padding: 26px;
            // overflow: auto;
            height: 800px;
            box-sizing: border-box;
            float: left;
            .right_title {
                display: flex;
                margin-bottom: 20px;
                align-items: center;
                .title_right {
                    // flex: 1.5;
                    // height: 100%;
                    align-items: center;
                    display: flex;
                    flex: 1;
                    justify-content: flex-start;
                    color: #fff;
                    div {
                        width: 87px;
                        height: 32px;
                        background: #3A85FC;
                        opacity: 1;
                        border-radius: 2px;
                        font-size: 14px;
                        color: #FFFFFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            .right_body {
                .prodect_change {
                    display: flex;
                    align-items: center;
                    div {
                        margin-left: 10px;
                    }
                    div:hover {
                        color: #409eff;
                    }
                    i:hover {
                        color: #409eff;
                    }
                }
            }
            .right_body .el-table {
                height: 707px !important;
            }
        }
    }
</style>