<template>
  <div class="mission_control_box" style="flex: 1">
   <div class="history_box_dep">
        <div
          v-for="(item, index) in dep_arr"
          :key="index"
          :class="[depth_check == item.id ? 'active_dep' : '']"
          @click="checkdep(item.id)"
        >
          {{ item.label }}
        </div>
      </div>
    <el-main>
      <div class="time_check pointer">
        <div
          v-for="(item, index) in time_arr"
          @click="change_timecheck(item.id)"
          :class="[check_time_id == item.id ? 'acc_time' : '']"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="serch_big_box">
        <div class="info">
          <span class="demonstration">年：</span>
          <el-date-picker
            v-model="year"
            :clearable="false"
            type="year"
            @change="changeyear"
            placeholder="选择年"
          >
          </el-date-picker>
        </div>
        <div class="info" v-if="check_time_id == 3">
          <span class="demonstration">季度：</span>
          <el-select
            @change="changequarter"
            v-model="quarter"
            placeholder="请选择季度"
          >
            <el-option
              v-for="(item, index) in quarter_list"
              :key="index"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="info" v-if="check_time_id == 1 || check_time_id == 2">
          <span class="demonstration">月：</span>
          <el-select
            @change="changemonth"
            v-model="month"
            placeholder="请选择月份"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="info" v-if="check_time_id == 1">
          <span class="demonstration">周：</span>
          <el-select
            @change="change_week"
            v-model="week"
            placeholder="请选择周"
            clearable
          >
            <el-option
              v-for="(item, index) in week_list"
              :key="index"
              :label="item.time"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="info">
          <div class="serch pointer" @click="serch">查询</div>
        </div>
      </div>
      <div class="acc_table">
        <el-table
          :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
          :data="tableData"
          style="width: 100%"
          border
          :default-sort="{ prop: 'date', order: 'descending' }"
        >
          <el-table-column label="部门">
            <template slot-scope="scope">
              <div class="acc_table_company_name">{{ scope.row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column label="任务额(元)">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ scope.row.mission == "" ? "--" : scope.row.mission }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <div class="acc_table_company_status">
                <i
                  :class="
                    scope.row.status == 1
                      ? 'wait_status'
                      : scope.row.status == 2
                      ? 'well_status'
                      : scope.row.status == 3
                      ? 'warning'
                      : ''
                  "
                ></i>
                {{
                  scope.row.status == 1
                    ? "审核中"
                    : scope.row.status == 2
                    ? "通过"
                    : scope.row.status == 3
                    ? "驳回"
                    : "--"
                }}
              </div>
            </template>
          </el-table-column>
            <el-table-column label="审核人">
            <template slot-scope="scope">
                <div class="acc_table_company_cuse" >
                  {{ scope.row.auditor_name?scope.row.auditor_name:'--' }}
                </div>
            </template>
          </el-table-column>
          <el-table-column label="原因">
            <template slot-scope="scope">
              <el-popover
                placement="bottom"
                width="400"
                v-if="scope.row.reason"
                trigger="hover"
                :content="scope.row.reason"
              >
                <div class="acc_table_company_cuse" slot="reference">
                  {{ scope.row.reason }}
                </div>
              </el-popover>
              <div class="acc_table_company_name" v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div
                class="acc_table_company_name"
                :class="[
                  scope.row.status == 3 ||
                  scope.row.status == 2 ||
                  !scope.row.status
                    ? 'pointer color3a'
                    : '',
                ]"
                @click="take_new_mission(scope.row)"
                v-if="role_what.edit"
              >
                {{
                  scope.row.status == 1
                    ? "--"
                    : scope.row.status == 2
                    ? "编辑"
                    : scope.row.status == 3
                    ? "修改"
                    : "添加"
                }}
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>
    <div class="acc_shadow" v-if="shadow_show">
      <div class="acc_commen">
        <div class="acc_commen_title">
          {{ is_add ? "添加" : "编辑" }}任务额
          <i
            class="el-icon-close pointer"
            style="color: #999999"
            @click="shadow_show = false"
          ></i>
        </div>
        <div class="acc_commen_body">
          <div class="tt">部门：{{ item.name }}</div>
          <div class="tt" v-if="check_time_id == 1">
            周节点：{{ week_item.time }}
          </div>
          <div class="tt" v-if="check_time_id == 2">
            月：{{ year }}-{{ month }}
          </div>
          <div class="tt" v-if="check_time_id == 3">
            季度：{{ year }}-{{
              quarter == 1
                ? "第一季度"
                : quarter == 2
                ? "第二季度"
                : quarter == 3
                ? "第三季度"
                : "第四季度"
            }}
          </div>
          <div class="tt" v-if="check_time_id == 4">年：{{ year }}</div>
          <div v-if="item.status == 3" style="margin-bottom: 15px">
            状态：驳回
          </div>
          <div
            v-if="item.status == 3"
            style="margin-bottom: 15px; display: flex"
          >
            <div style="word-break: keep-all; white-space: nowrap">
              驳回原因：
            </div>
            <div>{{ item.reason }}</div>
          </div>
          <div class="ano">任务额</div>
          <div class="ano">
            <el-input
              v-model="mission_num"
              type="number"
              placeholder="请输入任务额"
            ></el-input>
          </div>
          <div class="bottom">
            <div class="noshadow pointer" @click="shadow_show = false">
              取消
            </div>
            <div class="check_shadow pointer" @click="sent_mission">保存</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listsTask, saveTask, selectMakeNote } from "../../api/User/mission";
import {
  seeDepth,
} from "../../api/User/data";
export default {
  data() {
    return {
      time_arr: [
        { id: 1, name: "周" },
        { id: 2, name: "月" },
        { id: 3, name: "季" },
        { id: 4, name: "年" },
      ],
      check_time_id: "1",
      year: "",
      options: [],
      month: "",
      week_list: "",
      week: "",
      page: 1,
      week_item: {},
      total: 100,
      tableData: [],
      quarter: "",
      quarter_list: [
        { label: "第一季度", id: "1" },
        { label: "第二季度", id: "2" },
        { label: "第三季度", id: "3" },
        { label: "第四季度", id: "4" },
      ],
       dep_arr: [
        { label: "大区", id: 1 },
        { label: "分公司", id: 2 },
        { label: "事业部", id: 3 },
        { label: "战区", id: 4 },
        { label: "军团", id: 5 },
        // { label: "个人", id: 6 },
      ],
      depth_check: "1",
      shadow_show: false,
      is_add: false,
      item: {}, //单条任务弹出的内容
      mission_num: "",
      role_what: {
        list_str: "",
        edit: false,
        edit_str: "",
      },
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      let roleList = JSON.parse(localStorage.getItem("token"))
        .role_permissions;
      roleList.forEach((item) => {
        if (item.permissions == "Finance") {
          item.data.forEach((itm) => {
            if (itm.permissions == "Finance_mission_list") {
              this.role_what.list_str = itm.permissions_id_string;
            }
            if (itm.permissions == "Finance_mission_edit") {
              this.role_what.edit_str = itm.permissions_id_string;
              this.role_what.edit = true;
            }
          });
        }
      });
    }
    for (let i = 1; i <= 12; i++) {
      this.options.push({ label: i + "月", value: i });
    }
    this.year = new Date().getFullYear();
    var currQuarter = new Date().getMonth() + 1;
    var month = new Date().getMonth();
    if (month < 3) {
      this.quarter = "1";
    } else if (month < 6) {
      this.quarter = "2";
    } else if (month < 9) {
      this.quarter = "3";
    } else if (month < 12) {
      this.quarter = "4";
    }
    this.month = month + 1;
    this.month = this.month;
    this.year = this.year.toString();
    selectMakeNote({
      year: this.year,
      month: this.month,
      permissions_id_string: this.role_what.list_str,
    }).then((res) => {
      for (let i in res.data.data) {
        res.data.data[i].time =
          res.data.data[i].start_time + "-" + res.data.data[i].end_time;
      }
      this.week_list = res.data.data;
      if (this.week_list.length > 0) {
        this.week = this.week_list[0].id;
      }
      // this.serch();
      seeDepth({
          permissions_id_string: this.role_what.list_str,
        }).then(res=>{
            if (res.data.data != 1) {
              this.dep_arr.splice(0, res.data.data - 1);
          }
          this.checkdep(this.dep_arr[0].id);
        })
    });
  },
  methods: {
    change_timecheck(id) {
      this.check_time_id = id;
    },
    checkdep(id) {
      this.depth_check = id;
      this.serch();
    },
    serch() {
      let year = "";
      if (this.year != "") {
        year = this.getwaytime(this.year);
      } else {
        year = "";
      }
      if (this.check_time_id == 1) {
        //周
        listsTask({
          year: year,
          month: this.month < 10 ? "0" + this.month : this.month,
          type: 4,
          make_node_id: this.week,
          permissions_id_string: this.role_what.list_str,
          depth:this.depth_check
        }).then((res) => {
          this.tableData = res.data.data;
          // this.total=res.data.data.total;
        });
      } else if (this.check_time_id == 2) {
        // 月
        listsTask({
          year: year,
          month: this.month < 10 ? "0" + this.month : this.month,
          type: 3,
          permissions_id_string: this.role_what.list_str,
          depth:this.depth_check
        }).then((res) => {
          this.tableData = res.data.data;
          // this.tableData=res.data.data.data;
          // this.total=res.data.data.total;
        });
      } else if (this.check_time_id == 3) {
        // 季
        listsTask({
          year: year,
          quarter: this.quarter,
          type: 2,
          permissions_id_string: this.role_what.list_str,
          depth:this.depth_check
        }).then((res) => {
          this.tableData = res.data.data;
          // this.tableData=res.data.data.data;
          // this.total=res.data.data.total;
        });
      } else if (this.check_time_id == 4) {
        // 年
        listsTask({
          year: year,
          type: 1,
          permissions_id_string: this.role_what.list_str,
          depth:this.depth_check
        }).then((res) => {
          this.tableData = res.data.data;
        });
      }
    },
    change_week(e) {
      this.serch()
    },
    take_new_mission(item) {
      this.item = item;
      this.mission_num = item.mission;
      if (item.status == 1) {
        //如果是审核中
        return;
      } else if (this.check_time_id == 1) {
        //如果是周节点
        if (this.week == "") {
          this.$message.error("请选择周节点");
        } else {
          this.shadow_show = true;
          if (item.mission) {
            this.is_add = false;
          } else {
            this.is_add = true;
          }
          for (let i in this.week_list) {
            if (this.week_list[i].id == this.week) {
              this.week_item = this.week_list[i];
              break;
            }
          }
        }
      } else {
        this.shadow_show = true;
      }
    },
    changeyear(e) {
      // console.log(e);
      this.week_list = [];
      this.week = "";
      // if (this.check_time_id == 1) {
        let heihei = this.getwaytime(e);
        this.year = heihei.toString();
        let year = "";
      if (this.year != "") {
        year = this.getwaytime(this.year);
      } else {
        year = "";
      }
        selectMakeNote({
          year: year,
          permissions_id_string: this.role_what.list_str,
          month: this.month,
        }).then((res) => {
          for (let i in res.data.data) {
            res.data.data[i].time =
              res.data.data[i].start_time + "-" + res.data.data[i].end_time;
          }
          this.week_list = res.data.data;
          if (this.week_list.length > 0) {
            this.week = this.week_list[0].id;
          }
        });
      // }
      this.serch()
    },
    sent_mission() {
      if (this.check_time_id == 1) {
        //周任务
        if (this.item.status == "") {
          //添加
          saveTask({
            year_id: this.year,
            make_node_id: this.week_item.id,
            company_id: this.item.company_id,
            mission: this.mission_num,
            month: this.month < 10 ? "0" + this.month : this.month,
            type: 4,
            permissions_id_string: this.role_what.edit_str,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.message,
                type: "success",
              });
              this.shadow_show = false;
              this.serch();
            }
          });
        } else {
          //修改
          saveTask({
            year_id: this.year,
            make_node_id: this.week_item.id,
            company_id: this.item.company_id,
            mission: this.mission_num,
            task_id: this.item.task_id,
            month: this.month < 10 ? "0" + this.month : this.month,
            type: 4,
            permissions_id_string: this.role_what.edit_str,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.message,
                type: "success",
              });
              this.shadow_show = false;
              this.serch();
            }
          });
        }
      } else if (this.check_time_id == 2) {
        //月任务 新增
        // saveTask()
        if (this.item.status == "") {
          //添加
          saveTask({
            year_id: this.year,
            company_id: this.item.company_id,
            mission: this.mission_num,
            month: this.month < 10 ? "0" + this.month : this.month,
            type: 3,
            permissions_id_string: this.role_what.edit_str,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.message,
                type: "success",
              });
              this.shadow_show = false;
              this.serch();
            }
          });
        } else {
          //修改
          saveTask({
            year_id: this.year,
            company_id: this.item.company_id,
            mission: this.mission_num,
            task_id: this.item.task_id,

            month: this.month < 10 ? "0" + this.month : this.month,
            type: 3,
            permissions_id_string: this.role_what.edit_str,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.message,
                type: "success",
              });
              this.shadow_show = false;
              this.serch();
            }
          });
        }
      } else if (this.check_time_id == 3) {
        //季度任务 新增
        // saveTask()
        if (this.item.status == "") {
          //添加
          saveTask({
            year_id: this.year,
            company_id: this.item.company_id,
            mission: this.mission_num,
            quarter: this.quarter,
            type: 2,
            permissions_id_string: this.role_what.edit_str,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.message,
                type: "success",
              });
              this.shadow_show = false;
              this.serch();
            }
          });
        } else {
          //修改
          saveTask({
            year_id: this.year,
            company_id: this.item.company_id,
            mission: this.mission_num,
            task_id: this.item.task_id,
            quarter: this.quarter,
            type: 2,
            permissions_id_string: this.role_what.edit_str,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.message,
                type: "success",
              });
              this.shadow_show = false;
              this.serch();
            }
          });
        }
      } else if (this.check_time_id == 4) {
        //季度任务 新增
        // saveTask()
        if (this.item.status == "") {
          //添加
          saveTask({
            year: this.year,
            company_id: this.item.company_id,
            mission: this.mission_num,
            type: 1,
            permissions_id_string: this.role_what.edit_str,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.message,
                type: "success",
              });
              this.shadow_show = false;
              this.serch();
            }
          });
        } else {
          //修改
          saveTask({
            year: this.year,
            company_id: this.item.company_id,
            mission: this.mission_num,
            task_id: this.item.task_id,
            type: 1,
            permissions_id_string: this.role_what.edit_str,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.message,
                type: "success",
              });
              this.shadow_show = false;
              this.serch();
            }
          });
        }
      }
    },
    changequarter(e) {
      this.serch()
    },
    changemonth(e) {
      this.week_list = [];
      this.week = "";
      if (this.check_time_id == 1) {
          let year = "";
      if (this.year != "") {
        year = this.getwaytime(this.year);
      } else {
        year = "";
      }
        selectMakeNote({
          year: year,
          month: this.month,
          permissions_id_string: this.role_what.list_str,
        }).then((res) => {
          for (let i in res.data.data) {
            res.data.data[i].time =
            res.data.data[i].start_time + "-" + res.data.data[i].end_time;
          }
          this.week_list = res.data.data;
          if (this.week_list.length > 0) {
            this.week = this.week_list[0].id;
          }
        });
      }
      this.serch()
    },
    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear();
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let strDate = Y;
      // let strDate = Y + M + D + h + m + '00';
      return strDate;
    },
    handleCurrentChange(e) {
      this.page = e;
      this.serch();
    },
  },
  watch: {
    check_time_id() {
      this.page = 1;
      this.serch();
    },
  },
};
</script>

<style lang='scss'>
.color3a {
  color: #3a85fc !important;
}
.mission_control_box {
  display: flex;
  padding: 20px;
  padding-bottom: 0;
  .history_box_dep {
      background: #ffffff;
      width: 150px;
      margin-right: 10px;
      padding-top: 20px;
      height: 100%;
      max-height: 800px;
      position: absolute;
      top: 80px;
      left: 270px;
      div {
        // height: 45px;
        line-height: 45px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #595959;
        opacity: 1;
        cursor: pointer;
      }
      div:hover {
        background: #3a85fc;
        color: #ffffff;
      }
      .active_dep {
        background: #3a85fc;
        color: #ffffff;
      }
    }
  .el-main {
    margin-left: 170px;
    min-height: 847px;
    background: #fff;
    .acc_table_company_cuse {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .time_check {
      width: 280px;
      height: 40px;
      display: flex;
      border-radius: 5px;
      border: 1px solid #ccc;
      div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #ccc;
      }
      div:nth-last-of-type(1) {
        border-right: 0px;
      }
      .acc_time {
        background: #3a85fc;
        color: #fff;
      }
    }
    .serch_big_box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .info {
        width: 23%;
        margin-top: 20px;
        margin-left: 2%;
        display: flex;
        align-items: center;
        min-width: 240px;
        .demonstration {
          // margin-right: 10px;
          width: 50px;
          min-width: 42px;
        }
        .el-input__inner {
          height: 32px;
          line-height: 32px;
        }
        .el-input__icon {
          line-height: 34px;
        }
        .el-input {
          // width: 240px;
          width: 210px;
        }
        .serch {
          width: 70px;
          height: 32px;
          background: #3a85fc;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
        }
      }
      .info:nth-of-type(4n + 1) {
        margin-left: 0;
      }
    }
    .acc_table {
      margin-top: 20px;
      .acc_table_company_status {
        display: flex;
        align-items: center;
        i {
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          opacity: 1;
          margin-right: 10px;
        }
        .well_status {
          background: rgba(29, 208, 189, 1);
        }
        .wait_status {
          background: #faad14;
        }
        .to_time_status {
          background: #3a85fc;
        }
        .warning {
          background: #ff4d4f;
        }
      }
      .el-pagination {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .acc_shadow {
    position: fixed;
    z-index: 120;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    .info {
      width: 772px;
      height: 600px;
      margin: 100px auto;
      background: #ffffff;
      border: 1px solid #707070;
      opacity: 1;
      position: relative;
      border-radius: 5px;
      z-index: 999;
      // padding-top: 66px;
      box-sizing: border-box;
      .take_new_title {
        // top: -66px;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 66px;
        position: relative;
        border-bottom: 1px solid #ccc;
        text-align: center;
        line-height: 66px;
        font-size: 18px;
        i {
          position: absolute;
          top: 23px;
          right: 23px;
        }
      }
      .take_new_detail {
        height: 468px;
        width: 100%;
        box-sizing: border-box;
        overflow: auto;
        padding: 20px;
        .acc_top_compony {
          .kf_detail {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .quan {
              height: 5px;
              width: 5px;
              border-radius: 2.5px;
              background: #3a85fc;
              margin-right: 6px;
            }
          }

          .kh_detail {
            padding: 20px;
            width: 100%;
            box-sizing: border-box;
            height: 80px;
            margin-top: 10px;
            display: inline-block;
            background: #f8f8f8;
            div {
              overflow: hidden; /*超出部分隐藏*/
              white-space: nowrap; /*不换行*/
              text-overflow: ellipsis; /*超出部分文字以...显示*/
              width: 50%;
              display: inline-block;
              height: 50%;
            }
          }
        }
      }
      .flow_kind {
        width: 100%;
        display: flex;
        margin-top: 20px;
        white-space: nowrap; /*不换行*/
        align-items: center;
        .el-select {
          width: 250px;
        }
        .el-input__inner {
          width: 250px;
        }
        .el-date-editor .el-range-input {
          overflow: hidden; /*超出部分隐藏*/
          white-space: nowrap; /*不换行*/
          text-overflow: ellipsis; /*超出部分文字以...显示*/
        }
        .el-textarea__inner {
          width: 320px;
        }
        .el-date-editor.el-input {
          width: 250px;
        }
      }
      .take_bottom {
        height: 66px;
        position: relative;
        bottom: 0;
        width: 100%;
        display: flex;
        // align-items: center;
        // justify-content: center;
        box-sizing: border-box;
        justify-content: flex-end;
        padding-right: 40px;
        align-items: center;
        .bottom_sent {
          width: 80px;
          height: 40px;
          background: #3a85fc;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 1;
          color: #fff;
          border-radius: 4px;
        }
      }
      .flow_kind_text {
        width: 65%;
        display: flex;
        margin-top: 20px;
        // align-items: center;
        padding-top: 1px;
        // white-space: nowrap;
        .el-textarea {
          width: 400px;
          textarea {
            resize: none;
          }
        }

        .el-dialog__wrapper {
          z-index: 2010 !important;
        }
      }
    }
    .acc_commen {
      width: 445px;
      min-height: 311px;
      background: #ffffff;
      border: 1px solid #707070;
      opacity: 1;
      border-radius: 10px;
      margin: 100px auto;
      position: relative;
      .acc_commen_title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;
        position: relative;
        height: 40px;
        border-bottom: 1px solid #ccc;
        i {
          position: absolute;
          // top: ;
          right: 10px;
        }
      }
      .acc_commen_body {
        width: 100%;
        resize: none;
        padding: 5% 5%;
        .tt {
          margin-bottom: 15px;
        }
        .ano {
          margin-bottom: 10px;
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          input[type="number"] {
            -moz-appearance: textfield;
          }
        }
        .bottom {
          margin-top: 40px;
          display: flex;
          justify-content: flex-end;
          .noshadow {
            width: 80px;
            height: 40px;
            background: #ffffff;
            color: #3a85fc;
            border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
            opacity: 1;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
          }
          .check_shadow {
            width: 80px;
            height: 40px;
            background: #3a85fc;
            opacity: 1;
            border-radius: 4px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .el-textarea__inner {
          resize: none;
          height: 270px;
        }
      }
      .acc_commen_sent {
        position: absolute;
        right: 10px;
        padding: 0 10px;
        height: 25px;
        line-height: 25px;
        bottom: 10px;
        background: #3a85fc;
        color: #fff;
        border-radius: 5px;
      }
    }
  }
}
</style>