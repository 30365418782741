<template>
    <div class="editor">
        <quill-editor ref="myTextEditor" v-model="contents" :options="quillOption">
        </quill-editor>
        <el-button type="primary" @click="submit" class="submit">提交</el-button>
    </div>

</template>

<script>

    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import quillConfig from '../../utils/quill-config.js'
    import { quillEditor } from 'vue-quill-editor'
    export default {
        name: "cloud-editor",
        components: {
            quillEditor
        },
        data () {
            return {
                contents:this.content,
                quillOption: quillConfig,
            }
        },
        props:{
            content:{
                type:String
            }
        },
        created(){
            console.log(this.content)
        },
        methods: {
            submit () {
                this.$emit('onEditor',this.contents)
            }
        }

    }
</script>

<style scoped lang="scss">
    .editor{
        background: #fff;
        padding: 30px;
        min-height: 600px;
    }
    .submit{
        margin-top: 100px;
    }
    .quill-editor{
        height:350px;
    }
</style>