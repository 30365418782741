<template>
  <div class="map">
    <div
      @on-cancel="cancel"
      width="800"
      :closable="false"
      :mask-closable="false"
    >
    
      <baidu-map
        v-bind:style="mapStyle"
        class="bm-view"
        ak="Snp1AlQE5S2xEhRE3BIgrcNwsnPCnDFj"
        :center="center"
        :zoom="zoom"
        :scroll-wheel-zoom="true"
        @click="getClickInfo" 
      >
      <!-- 
         @click="getClickInfo"
        @moving="syncCenterAndZoom"
        @moveend="syncCenterAndZoom"
        @zoomend="syncCenterAndZoom"
       -->
        <bm-view style="width: 100%; height: 300px"></bm-view>
        <bm-marker
          :position="{ lng: center.lng, lat: center.lat }"
          :dragging="true"
          animation="BMAP_ANIMATION_BOUNCE"
        >
        </bm-marker>
        <bm-control :offset="{ width: '10px', height: '10px' }">
          <bm-auto-complete v-model="keyword" :sugStyle="{ zIndex: 999999 }">
            <input
              type="text"
              placeholder="请输入搜索关键字并点击选点"
              class="serachinput"
            />
          </bm-auto-complete>
        </bm-control>
        <bm-local-search
          :keyword="keyword"
          :auto-viewport="true"
          style="width: 0px; height: 0px; overflow: hidden"
        ></bm-local-search>
      </baidu-map>
      <div slot="footer" style="margin-top: 10px" class="footer">
        <Button
          class="pointer"
         @click="cancel" type="ghost" style="width: 60px; height: 36px;border-radius: 2px;color:#3a85fc;border:1px solid #ccc;margin-right:10px;"
          >取消
        </Button>
        <Button
          class="pointer"
          type="primary"
          style="width: 60px; height: 36px;background:#3a85fc;color:#fff;border-radius: 2px;"
          @click="confirm"
          >确定</Button
        >
      </div>
    </div>
  </div>
</template>
<script>
import {
  BaiduMap,
  BmControl,
  BmView,
  BmAutoComplete,
  BmLocalSearch,
  BmMarker,
} from "vue-baidu-map";
export default {
  components: {
    BaiduMap,
    BmControl,
    BmView,
    BmAutoComplete,
    BmLocalSearch,
    BmMarker,
  },
  props:['lng','lat'],
  data: function () {
    return {
      showMapComponent: this.value,
      keyword: "",
      mapStyle: {
        width: "100%",
        height: this.mapHeight + "px",
      },
      center: { lng: 116.404, lat: 39.915 },
      zoom: 15,
    };
  },
  created () {
    if (this.$attrs.lng) {
      this.center.lng = this.$attrs.lng*1
      this.center.lat = this.$attrs.lat*1
    }else{
      this.center.lng = 116.404
      this.center.lat = 39.915
    }
  },
  watch: {
    value: function (currentValue) {
      this.showMapComponent = currentValue;
      if (currentValue) {
        this.keyword = "";
      }
    },
  },
  props: {
    value: Boolean,
    mapHeight: {
      type: Number,
      default: 500,
    },
  },
  methods: {
    /***
     * 地图点击事件。
     */
    getClickInfo(e) {
      this.center.lng = e.point.lng;
      this.center.lat = e.point.lat;
    },
    syncCenterAndZoom(e) {
      const { lng, lat } = e.target.getCenter();
      this.center.lng = lng;
      this.center.lat = lat;
      this.zoom = e.target.getZoom();
    },
    /***
     * 确认
     */
    confirm: function () {
      this.showMapComponent = false;
      this.$emit('closeMap', 1,this.center,this.keyword)
    },
    /***
     * 取消
     */
    cancel: function () {
      this.showMapComponent = false;
      this.$emit('closeMap', 0,this.center,this.keyword)

    },
  },
};
</script>
 
<style lang="scss">
.serachinput {
  width: 300px;
  box-sizing: border-box;
  padding: 9px;
  border: 1px solid #dddee1;
  line-height: 20px;
  font-size: 16px;
  height: 38px;
  color: #333;
  position: relative;
  border-radius: 4px;
  -webkit-box-shadow: #666 0px 0px 10px;
  -moz-box-shadow: #666 0px 0px 10px;
  box-shadow: #666 0px 0px 10px;
  background: #fff;
}
.map {
  .bm-view{
    height: 300px!important;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
  }
}
</style>>