<template>
  <div class="order_list_box" style="flex: 1">
    <el-main>
      <div class="customer_header_check pointer" v-if="position != 1">
        <div
          :class="isPersonal == 1 ? 'isPersonal' : ''"
          @click="
            isPersonal = 1;
            getlist(0, 1);
          "
        >
          个人
        </div>
        <div
          :class="isPersonal == 2 ? 'isPersonal' : ''"
          @click="
            isPersonal = 2;
            getlist(0, 1);
          "
        >
          部门
        </div>
      </div>
      <div class="order_list_top">
        <div class="order_list_top_child" v-if="isPersonal == 2">
          部门：<el-cascader
            placeholder="请选择部门名称"
            :options="depOptions"
            @change="changeDep"
            :key="isResouceShow"
            clearable
            :props="{ checkStrictly: true }"
          ></el-cascader>
        </div>
        <div class="order_list_top_child" v-if="isPersonal == 2">
          跟进人：<el-input
            v-model="flow_name"
            placeholder="请输入下单人姓名"
          ></el-input>
        </div>
        <div class="order_list_top_child"  v-if="isPersonal == 2">
          下单状态：
          <el-select v-model="type" clearable placeholder="请选择">
            <el-option
                    v-for="item in type_list"
                    :key="item.name"
                    :label="item.name"
                    :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="order_list_top_child">
          客户姓名：<el-input
            v-model="name"
            placeholder="请输入客户姓名"
          ></el-input>
        </div>
        <div class="order_list_top_child">
          公司名称：<el-input
                v-model="customer_name_string"
                placeholder="请输入公司名称"
        ></el-input>
        </div>
        <div class="order_list_top_child" v-if="isPersonal == 2">
          订单号：<el-input
            v-model="order_num"
            placeholder="请输入订单号"
          ></el-input>
        </div>
        <div class="order_list_top_child" v-if="isPersonal == 2">
          合同号：<el-input
            v-model="contract_num"
            placeholder="请输入合同号"
          ></el-input>
        </div>
        <div class="order_list_top_child je">
          金额：
          <el-input
            v-model="small_price"
            placeholder="请输入金额"
            style="width: 100px"
            class="je"
          ></el-input>
          -
          <el-input
            v-model="big_price"
            placeholder="请输入金额"
            style="width: 100px"
            class="je"
          ></el-input>
        </div>
        <div class="order_list_top_child re">
          <div class="serch pointer" @click="getlist(1)">查询</div>
          <div class="reset pointer" @click="getlist(0, 1)">重置</div>
        </div>
       
      </div>

  
      <div class="allnumber-data">
        <ul>
          <li class="green">合同金额：{{topTotal.contract_money}}</li>
          <li class="orange">应收全款金额：{{topTotal.total_amount_receivable}}</li>
          <li class="red">实收金额：{{topTotal.achievement}}</li>
          <!-- <li class="blue ">应收尾款：{{topTotal.accounts_receivable}}</li> -->
          <li class="purple">应收合同余款：{{topTotal.accounts_receivable_contract}}</li>
        </ul>
      </div>
      <div class="acc_table">
        <el-table
          :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
          :data="tableData"
          style="width: 100%"
          border
          :default-sort="{ prop: 'date', order: 'descending' }"
        >
          <el-table-column label="订单号"  min-width="180" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ scope.row.order_number }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="公司名称">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ scope.row.customer?scope.row.customer.name:'--' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="客户名称">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ scope.row.contacts.contacts }}
              </div>
            </template>
          </el-table-column>
           <el-table-column label="合同金额（元）" min-width="130" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="acc_table_company_name" v-if="scope.row.product_name">
                {{ scope.row.product_name.product_name +'-' + scope.row.contract_money }}
              </div>
              <div class="acc_table_company_name" v-else>--</div>
            </template>
          </el-table-column> 
          <el-table-column label="本单应收全款金额" width="140" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ scope.row.total_amount_receivable }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="订单业绩">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ scope.row.achievement }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="订单类型" width="300">
            <template scope="scope">

          <el-popover
          v-if="scope.row.order_data.length > 4"
            placement="right"
            width="300"
            popper-class="popover-custom"
            trigger="hover">
          <!-- <div class="acc_table_company_name" v-html="collectionStatus(scope.row.order_data,5)"> </div> -->
           <div class="money-popinfo" >
              <p class="popinfo-tit">下单状态</p>
              <div v-html="collectionStatus(scope.row.order_data,5)">
                
              </div>
            
            </div>
            <div slot="reference" class="acc_table_company_name" v-html="collectionStatus(scope.row.order_data,4)"> </div>
          </el-popover>
          <div v-else>
             <div class="acc_table_company_name" v-html="collectionStatus(scope.row.order_data,4)"> </div>
          </div>
              
            </template>
          </el-table-column>
          <el-table-column label="下单状态">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ orderType(scope.row)}}
              </div>
            </template>
          </el-table-column>
           <el-table-column label="应收尾款">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ scope.row.accounts_receivable}}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="下单时间">
            <template slot-scope="scope">
              <div class="acc_table_company_name" v-if="scope.row.product_name">
                {{ scope.row.created_at }}
              </div>
              <div class="acc_table_company_name" v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <div class="acc_table_company_name" v-if="scope.row.product_name">
                {{ scope.row.product_name.product_name }}
              </div>
              <div class="acc_table_company_name" v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="审核人">
            <template slot-scope="scope">
              <div class="acc_table_company_name" v-if="scope.row.product_name">
                {{ scope.row.product_name.product_name }}
              </div>
              <div class="acc_table_company_name" v-else>--</div>
            </template>
          </el-table-column> -->
          <el-table-column v-if="isPersonal == 2" label="跟进人">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ scope.row.user? scope.row.user.name :'--' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button class="table-btn" type="text" @click="editOrderStatus(scope.row)" v-show="is_sales == 1">下单状态</el-button>
              <span class="table-btn-line" v-show="is_sales == 1"></span>
              <el-button class="table-btn" type="text" @click="todetail(scope.row)">业绩详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" -->
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="10"
          layout="total,prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-main>
    <!--下单状态 弹窗-->
    <el-dialog
      title="下单状态"
      :visible.sync="orderStatusEdit"
      width="450px"
      custom-class="order-status-dialog"
    >
      <el-row>
        <el-col :span="24">下单状态:</el-col>
      </el-row>
      <el-row  class="status-row">
        <el-col :span="12">
           <el-radio v-model="orderStatus" label="0">未下单</el-radio>
        </el-col>
        <el-col :span="12">
           <el-radio v-model="orderStatus" label="1">已下单</el-radio>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button  size="small" @click="orderStatusEdit = false">取 消</el-button>
        <el-button type="primary"  size="small" @click="submitEditStatus">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { searchCompanysList } from "../../api/User/customer";
import { list,editPlaceOrder } from "../../api/User/order_list";
export default {
  data() {
    return {
      orderId:'',//订单ID
      orderStatus: '',
      orderStatusEdit: false,
      position: "",
      isPersonal: 1,
      name: "",
        customer_name_string:'',
      small_price: "",
      big_price: "",
      tableData: [],
      total: 0,
      depOptions: [], //筛选部门
      page: 1,
      search_company_id: "",
      order_num: "",
      contract_num: "",
      flow_name: "",
      isResouceShow: 0,
      permissions_id_string:'',
      is_sales:'',//用户角色 1销售 2职能  3客服,
      topTotal:{
        'contract_money':"", //合同金额
        'total_amount_receivable':"",//应收全款金额
        'achievement':"",//实收金额
        'accounts_receivable':"",//应收尾款
        'accounts_receivable_contract':""//应收合同尾款

      },
        type_list:[{
          name:'未下单',
            id:'0'
        },{
          name:'已下单',
            id:'1'
        }],
        type:''
    };
  },
  created() {
    let token = JSON.parse(localStorage.getItem("token"));
  
    this.is_sales = token.is_sales;
    this.position = token.position_type;
 
    if (this.position == 1) {
      this.getlist();
      this.isPersonal = 1;
      return;
    }
    if (localStorage.getItem("order_list_check")) {
      this.isPersonal = localStorage.getItem("order_list_check");
    }
        this.position=token.position_type;
         for(let i in token.role_permissions){
                if(token.role_permissions[i].permissions=='Marketing'){
                    for(let v in token.role_permissions[i].data){
                        if(token.role_permissions[i].data[v].permissions=='system_order_list'){
                            this.permissions_id_string=token.role_permissions[i].data[v].permissions_id_string
                        } 
                    }
                }
          }
    if (this.isPersonal == 2) {
      searchCompanysList().then((res) => {
        if (res.data.code == 200) {
          function dg(list) {
            list.forEach((item) => {
              item.label = item.name;
              item.value = item.id;
              if (item.data) {
                item.children = item.data;
                dg(item.data);
              }
            });
            return list;
          }
          let list = res.data.data;
          dg(list);
          this.depOptions = list;
        }
      });
      if(localStorage.getItem('order_page')){
        this.page=localStorage.getItem('order_page')*1
      }
      
      this.getlist();
    } else {
       if(localStorage.getItem('order_page')){
        this.page=localStorage.getItem('order_page')*1
      }
     
      this.getlist();
     
    }
  },
  methods: {
    //点击表格  下单状态
    editOrderStatus(row){
    
      this.orderId = row.id;
      this.orderStatus = row.type.toString();
      this.orderStatusEdit = true;
    },
    submitEditStatus(){
    
      editPlaceOrder({type:this.orderStatus,order_id:this.orderId}).then(res =>{
     
        if(res.data.code == 200){
           this.$message({
              type: "success",
              message: '修改成功',
            });
            this.getlist();
            this.getTotal();
            this.orderStatusEdit = false
        }
        else{
           this.$message({
              type: "error",
              message: res.data.message,
            });
        }
      })
    },
    //获取头部统计
    getTotal(){
      list({
        head_total:'head_total',
        permissions_id_string:this.permissions_id_string,
        class_type: this.isPersonal,
        customer_name: this.name,
        company_id: this.search_company_id,
        start_contract_money: this.small_price,
        end_contract_money: this.big_price,
        page: this.page,
        order_number: this.order_num,
        contract_number: this.contract_num,
        follow_name: this.flow_name,
          customer_name_string:this.customer_name_string
      }).then(res =>{
   
        if(res.data.code == 200){
          this.topTotal = res.data.data
        }
        
      })
    },
    getlist(is_serch, is_reload) {
      if(is_serch != 10){//10 分页  不请求统计
        this.getTotal();
      }
       
      if (is_reload) {
        this.page = 1;
        ++this.isResouceShow;
        this.search_company_id=''
        this.big_price = "";
        this.small_price = "";
        this.order_num = "";
        this.contract_num = "";
        this.flow_name = "";
        this.name = "";
        this.customer_name_string=""
        this.type="";
        // this.getTotal();
      }
        if(is_serch != 10){//10 分页  不请求统计
            this.getTotal();
        }
      list({
         permissions_id_string:this.permissions_id_string,
        class_type: this.isPersonal,
        customer_name: this.name,
        company_id: this.search_company_id,
        start_contract_money: this.small_price,
        end_contract_money: this.big_price,
        page: this.page,
          type:this.type,
        order_number: this.order_num,
        contract_number: this.contract_num,
        follow_name: this.flow_name,
          customer_name_string:this.customer_name_string
      }).then((res) => {
   
        this.total = res.data.data.total;
        this.tableData = res.data.data.data;
      });
    },
    handleCurrentChange(e) {
      localStorage.setItem('order_page',e)
      this.page = e;
      this.getlist(10);
    },
    changeDep(value) {
      //部门筛选
      if (value.length > 0) {
        this.search_company_id = value[value.length - 1];
      } else {
        this.search_company_id = "";
      }
    },
    todetail(item) {
      setTimeout(() => {
        this.$forceUpdate();
      }, 10);
      this.$router.push({
        name: "orderdetail",
        params: {
          class_type: this.isPersonal,
          order_id: item.id,
        },
      });
    },
  },
  computed:{
    orderType(){
      return function(row){
        let option1 = row.cooperation == 1 ? '首签 - ' : row.cooperation == 2 ? '续签 - ' : '';
        let option2 = row.type == 0 ? '未下单' : '已下单';
        return option1  + option2
      }

    },
    collectionStatus(){
      return function(row,i){
        let str = '';
        if(i == 4 && row.length > 4){
          row = row.slice(0,4)
        }
        row.forEach(item =>{
          let type = item.type == 1 ? '定金' : item.type == 2 ? '尾款' : '全款'
          str += `<p>${item.to_time} - ${type} - ${item.to_money}</p>`
        })
        if(i == 5 && row.length > 4){
          str = '';
           row.forEach(item =>{
          let type = item.type == 1 ? '定金' : item.type == 2 ? '尾款' : '全款'
           str += `<div class="popinfo-item">
              <div class="popinfo-time">${item.to_time}</div>
              <div class="popinfo-money">${type}- ${item.to_money}</div>
            </div>`
        })
         
        }
       
        return str
      }
    }
  },
  watch: {
    isPersonal() {
      localStorage.setItem("order_list_check", this.isPersonal);
      if (this.isPersonal == 2) {
        searchCompanysList().then((res) => {
          if (res.data.code == 200) {
            function dg(list) {
              list.forEach((item) => {
                item.label = item.name;
                item.value = item.id;
                if (item.data) {
                  item.children = item.data;
                  dg(item.data);
                }
              });
              return list;
            }
            let list = res.data.data;
            dg(list);
            this.depOptions = list;
          }
        });
      }
    },
  },
};
</script>

<style lang='scss' >
.order_list_box {
  padding: 20px;
  box-sizing: border-box;
  .el-main {
    min-height: 847px;
    background: #fff;
    .customer_header_check {
      width: 280px;
      height: 40px;
      display: flex;
      align-items: center;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-bottom: 30px;
      div {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
      }

      .isPersonal {
        background: #3a85fc;
        color: #fff;
      }
    }
    .order_list_top {
      display: flex;
      flex-wrap: wrap;

      .order_list_top_child {
        width: 22%;
        // min-width: 300px;
        display: inline-block;
        white-space: nowrap;
        margin-left: 3%;
        .el-input {
          width: auto;
        }
        .el-cascader {
          line-height: 32px;
          .el-input__icon {
            line-height: 32px;
          }
        }
        .el-input__inner {
          height: 32px;

          line-height: 32px;
          width: 180px;
        }
        .serch {
          float: left;
          width: 70px;
          height: 32px;
          background: #3a85fc;
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          border-radius: 2px;
        }
        .reset {
          float: left;
          width: 70px;
          height: 32px;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
          opacity: 1;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 20px;
          color: #666;
        }
      }
      .order_list_top_child:nth-of-type(1) {
        margin-left: 0;
      }
      .order_list_top_child:nth-of-type(5) {
        margin-left: 0;
      }
      .order_list_top_child:nth-of-type(n + 5) {
        margin-top: 15px;
      }
      .je {
        .el-input__inner:nth-of-type(1) {
          height: 32px;
          margin-left: -4px;
          line-height: 32px;
          width: 102px;
        }
        .el-input__inner {
          height: 32px;
          line-height: 32px;
          width: 102px;
        }
        .el-input:nth-of-type(2) {
          height: 32px;
          margin-left: 4px;
          line-height: 32px;
          width: 102px;
        }
      }
    }
    .allnumber-data{
      background: rgb(245, 245, 245);
      border: 1px solid #EFEFEF;
      border-bottom: none;
      height: 48px;
       margin-top: 20px;
       position: relative;
      ul{
        padding-left: 15px;
        box-sizing: border-box;
        position: absolute;
        li{
          float: left;
          margin-right: 50px;
          line-height: 48px;
          font-size: 14px;
          position: relative;
          text-indent: 6px;
          &:after{
            content: '';
             position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            left: -5px;
            top: 22px;
          }
        }
      
        .green{
          &:after{
            background: #48CFB1;
          }
        }
        .orange{
          &:after{
            background: #FFAB19;
          }
        }
        .red{
          &:after{
            background: #FF3737;
          }
        }
        .blue{
          &:after{
            background: #3995FF;
          }
        }
        .purple{
         
           &:after{
            background: #5F39FF;
          }
        }
      }
    }
    .acc_table {
      .table-btn{
        color: #3A85FC !important;
      }
      .table-btn-line{
        width: 1px;
        height: 14px;
        background: rgba(0,0,0,0.1);
        display: inline-block;
        margin: 0 10px;
        vertical-align: middle;
      }
      .el-pagination {
        margin-top: 10px;
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  
}
.order-status-dialog{
    border-radius: 10px !important;
    .el-dialog__header{
      border-bottom: solid #EFEFEF 1px;
      color: #262626;
      font-size: 16px;
      text-align: center;
      padding-top: 0;
      padding-bottom: 0;
      height: 45px;
      line-height: 45px;
      .el-dialog__title{
        font-size: 16px;
      }
      .el-dialog__headerbtn{
        top: 13px;
        font-size: 20px;
      }
      
    }
    .el-dialog__body{
      padding: 20px 30px !important;
      padding-left: 50px !important;
      border-bottom: solid #EFEFEF 1px;
    }
    .status-row{
      padding-top: 25px;
      padding-bottom: 35px;
    }
  
}
.popover-custom{
  width: 230px !important;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16) !important;
    .money-popinfo{
      // width: 211px;
      max-height: 600px;
      overflow-y: auto;
      background: #FFFFFF;
        
      opacity: 1;
      padding-top: 15px;
      padding-left: 20px;
      box-sizing: border-box;
      .popinfo-tit{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #595959;
        margin-bottom: 15px;
      }
      .popinfo-item{
        position: relative;
        font-family: Microsoft YaHei;
        height: 75px;
        &::after{
          content: "";
          width: 0px;
          height: 65px;
          border: 1px solid #F1F1F1;
          position: absolute;
          left: 2px;
          top: 10px;
          z-index: 1;
        }
        &:last-child{
          &::after{
            content: "";
            width: 0px;
            height: 0;
          }
        }
        .popinfo-time{
          font-size: 14px;
          text-indent: 13px;
          font-weight: 400;
          line-height: 19px;
          color: #999999;
          margin-bottom: 10px;
          position: relative;
          &::after{
            content: "";
            width: 6px;
            height: 6px;
            background: #3A85FC;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 6px;
            z-index: 11;
          }
        }
        .popinfo-money{
          font-size: 14px;
          text-indent: 13px;
          font-weight: 400;
          line-height: 19px;
          color: #595959;
        }
      }
    
    }
  }
</style>