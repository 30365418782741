import request from '../../utils/axios.tool';

//获取用户所以事业线、职位等级
export const userBusinessLine = (data)=>{              
    return request.post(`/roleapi/contrastWeb/userBusinessLine`,data)
}


//个人对比页
export const personalContrast = (data)=>{              
    return request.post(`/roleapi/contrastWeb/personalContrast`,data)
}

//个人对比搜索列表
export const searchPersonalList = (data)=>{              
    return request.post(`/roleapi/contrastWeb/searchPersonalList`,data)
}

//个人对比结果
export const comparisonResults = (data)=>{              
    return request.post(`/roleapi/contrastWeb/comparisonResults`,data)
}


//部门对比页
export const companyContrast = (data)=>{              
    return request.post(`/roleapi/contrastWeb/companyContrast`,data)
}

// 其他部门
export const otherCompany = (data)=>{              
    return request.post(`/roleapi/contrastWeb/otherCompany`,data)
}
//      部门对比结果
export const companyComparisonResults = (data)=>{              
    return request.post(`/roleapi/contrastWeb/companyComparisonResults`,data)
}

//      查询所以事业线
export const allBusiness = (data)=>{              
    return request.post(`/roleapi/contrastWeb/allBusiness`,data)
}
