import request from '@/utils/axios.tool';

//获取订单列表
export const orderDatalist = (data)=>{              
    return request.post(`/roleapi/orderData/list`,data)
}

//订单详情
export const orderDetail = (data)=>{              
    return request.post(`/roleapi/orderData/detail`,data)
}

//订单编辑
export const edit = (data)=>{              
    return request.post(`/roleapi/orderData/edit`,data)
}


//订单删除
export const del = (data)=>{              
    return request.post(`/roleapi/orderData/del`,data)
}

//订单审核
export const editStatus = (data)=>{              
    return request.post(`/roleapi/orderData/editStatus`,data)
}

//订单导出 
export const orderExport = (data)=>{              
    return request.post(`/roleapi/orderData/export`,data)
}

//收款方式
export const collectionList = ()=>{              
    return request.post(`/roleapi/orderData/collectionList`)
}


//产品列表
export const productList = ()=>{              
    return request.post(`/roleapi/orderData/productList`)
}

//获取部门列表 一二级
export const select = (data)=>{              
    return request.post(`/roleapi/company/select`,data)
}

//用户最小部门id及深度 查询时候条件删选部门
export const userMinCompany = (data)=>{              
    return request.post(`/roleapi/orderData/userMinCompany`,data)
}



//获取部门
export const selectOrderCompanyWhere = (data)=>{              
    return request.post(`/roleapi/company/selectOrderCompanyWhere`,data)
}



//财务中心退款管理
export const financeRefundList = (data)=>{              
    return request.post(`roleapi/order/financeRefundList`,data)
}
//财务中心退款上传获取上传信息
export const voucherDetail = (data)=>{              
    return request.post(`roleapi/order/voucherDetail`,data)
}
//财务中心退款上传凭证
export const uploadVoucher = (data)=>{              
    return request.post(`roleapi/order/uploadVoucher`,data)
}
// 修改凭证部门id
export const editVoucher = (data)=>{              
    return request.post(`roleapi/order/editVoucher`,data)
}


//修改业绩归属部门
export const editAchievementAscription = (data)=>{              
    return request.post(`roleapi/orderData/editAchievementAscription`,data)
}