import request from '@/utils/axios.tool';
// 待办事项列表
export const planList = (data)=>{              
    return request.post(`/roleapi/planWeb/planList`,data)
}
// 获取计划信息
export const getPlan = (data)=>{              
    return request.post(`/roleapi/planWeb/getPlan`,data)
}
// 修改计划
export const editPlan = (data)=>{              
    return request.post(`/roleapi/planWeb/editPlan`,data)
}
// 客户信息头部
export const customerHead = (data)=>{              
    return request.post(`/roleapi/customerWeb/customerHead`,data)
}
// 跟进记录
export const followList = (data)=>{              
    return request.post(`/roleapi/planWeb/followList`,data)
}
//添加跟进记录
export const addPlanLog = (data)=>{              
    return request.post(`/roleapi/planWeb/addPlanLog`,data)
}
// 增加促进
export const addComment = (data)=>{              
    return request.post(`/roleapi/planWeb/addComment`,data)
}
