import request from '@/utils/axios.tool';
// 离职列表
export const list = (data)=>{              
    return request.post(`/roleapi/quit/list`,data)
}

export const seekCustomerList = (data)=>{              
    return request.post(`/roleapi/quit/seekCustomerList`,data)
}
// 查询可分配用户
export const distributionUserList = (data)=>{              
    return request.post(`/roleapi/quit/distributionUserList`,data)
}
// 分配
export const distribution = (data)=>{              
    return request.post(`/roleapi/quit/distribution`,data)
}