import request from "@/utils/axios.tool";
export const pkList = (data) => {
  return request.get(`/roleapi/pk/pkList`, data);
};

//pk战役管理
export const pkManage = (data) => {
  return request.get(`/roleapi/statistics/pkManage`, data);
};

//pk战役管理 导出
export const pkManageExport = (data) => {
  return request.get(`/roleapi/statistics/pkManageExport`, data);
};

//军团配置--获取一级大区  军团配置--获取军团
export const legionConfiguration = (data) => {
  return request.post(`/roleapi/statistics/legionConfiguration`, data);
};

//设置参与状态
export const setCampaignStatus = (data) => {
  return request.post(`/roleapi/statistics/setCampaignStatus`, data);
};

//获取赛区
export const getZone = (data) => {
  return request.post(`/roleapi/statistics/getZone`, data);
};
//新增赛区
export const addZone = (data) => {
  return request.post(`/roleapi/statistics/addZone`, data);
};

//修改赛区
export const editZone = (data) => {
  return request.post(`/roleapi/statistics/editZone`, data);
};

//历史积分
export const historyIntegral = (data) => {
  return request.post(
    `/roleapi/statistics/historyIntegral
    `,
    data
  );
};
//战役历史获取节点
export const getCampaignHistoryNode = (data) => {
    return request.post(
        `/roleapi/statistics/getCampaignHistoryNode`, data);
};
//设置赛区
export const setZone = (data) => {
    return request.post(
        `/roleapi/statistics/setZone`, data);
};
//战役历史
export const campaignHistory = (data) => {
    return request.post(
        `/roleapi/statistics/campaignHistory`, data);
};
//设置积分
export const setIntegral = (data) => {
    return request.post(
        `/roleapi/statistics/setIntegral`, data);
};
