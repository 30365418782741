<template>
  <div class="add_Arrival_box">
    <div class="add_Arrival">
      <!-- 新增到账 -->
      <div class="add_Arrival_head">
        新增到账
        <i class="pointer el-icon-close" @click="handleClose"></i>
      </div>
      <div class="add_Arrival_box_info">
        <div class="add_Arrival_head_tit"><i></i>公司信息</div>
        <div class="add_Arrival_line">
          <div class="add_Arrival_line_tit">公司名称：</div>
          <div class="add_Arrival_line_inp">
            {{ customerdetail.customer_res?customerdetail.customer_res.name:'--' }}
          </div>
        </div>
        <div class="add_Arrival_line">
          <div class="add_Arrival_line_tit">客户姓名：</div>
          <div class="add_Arrival_line_inp">
            {{ customerdetail.customer_res?customerdetail.customer_res.contacts:'--' }}
          </div>
        </div>
        <div class="add_Arrival_line">
          <div class="add_Arrival_line_tit">产品名称：</div>
          <el-select
            v-model="pro_id"
            @change="handleChangePro"
            placeholder="请选择产品"
          >
            <el-option
              v-for="item in prooption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div v-if="isFirstAdd == 1">
          <div class="add_Arrival_head_tit"><i></i>合同信息</div>
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">合同金额：</div>
            <el-input
              type="text"
              v-model="contract_money"
              placeholder="请输入合同金额"
            />
          </div>
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">合同单号：</div>
            <el-input
              type="text"
              v-model="contract_number"
              placeholder="请输入合同单号"
            />
          </div>
          <div
            class="add_Arrival_line"
            style="align-items: flex-start; height: 110px">
            <div class="add_Arrival_line_tit">合同：</div>
            <div class="contract_img">
              <div class="flow_kind_text">
                <div
                  class="flow_kind_text_img"
                  v-for="(item, index) in contract_file_list_arr"
                  :key="index"
                >
                  <el-image
                    style="width: 100px; height: 100px; border-radius: 4px"
                    :src="item"
                    :preview-src-list="contract_file_list_arr"
                  >
                  </el-image>
                  <i
                    class="el-icon-circle-close"
                    @click="handleDel_img(index, 1)"
                    style="color: #ccc"
                  ></i>
                </div>
                <div
                  class="image_info"
                  @click="chooseFile(1)"
                  v-if="contract_file_list.length < 3"
                >
                  <img src="@/assets/img/upload_img.png" alt="" />
                  <span>上传相关图片</span>
                  <input
                    type="file"
                    style="display: none"
                    ref="choosePhotos"
                    @change="photoChange($event)"
                    v-if="upload_img"
                    id="upload_file"
                  />
                </div>
              </div>
            </div>
          </div>
        <div class="row-item-tit"> <span class="star">*</span>本单应收全款金额：</div>
        <div class="row-item-ipt">
           <el-input
            type="text"
            v-model="total_amount_receivable"
            placeholder="请输入金额(元)"
          />
        </div>
          <div class="row-item-tit"> <span class="star">*</span> 服务起止时间：</div>
          <div class="row-item-ipt">
            <el-date-picker
              v-model="service_time"
              type="date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              placeholder="选择日期">
            </el-date-picker>
            <span class="mg-lr10">至</span>
              <el-date-picker
              v-model="service_time_end"
              type="date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              placeholder="选择日期">
            </el-date-picker>
              
          </div>
          <div
                  class="add_Arrival_line"
                  style="align-items: flex-start; height: 110px"
          >
            <div class="add_Arrival_line_tit">备注：</div>
            <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="请输入内容"
                    v-model="order_remarks"
            >
            </el-input>
          </div>
        </div>

        <div class="add_Arrival_head_tit"><i></i>到账信息</div>
        <div class="add_Arrival_line">
          <div class="add_Arrival_line_tit">本次实收金额：</div>
          <el-input
            type="text"
            v-model="Arrival_money"
            placeholder="请输入到账金额"
            @blur="blurNumber"
          />
        </div>
      
       
        <div class="add_Arrival_line">
          <div class="add_Arrival_line_tit">收款方式：</div>
          <el-select v-model="collection" placeholder="请选择收款方式">
            <el-option
              v-for="item in collectionList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="add_Arrival_line" v-if="0">
          <div class="add_Arrival_line_tit">到账时间：</div>
          <el-date-picker
            v-model="time"
            type="datetime"
            prefix-icon="el-icon-date"
            clear-icon="sss"
            :picker-options="pickerOptions"
            placeholder="请选择到账时间"
          >
          </el-date-picker>
        </div>
        <div
          class="add_Arrival_line"
          style="align-items: flex-start; height: 110px"
        >
          <div class="add_Arrival_line_tit">收款凭证：</div>
          <div class="contract_img">
            <div class="flow_kind_text">
              <div
                class="flow_kind_text_img"
                v-for="(item, index) in collection_file_list_arr"
                :key="index"
              >
                <el-image
                  style="width: 100px; height: 100px; border-radius: 4px"
                  :src="item"
                  :preview-src-list="collection_file_list_arr"
                >
                </el-image>
                <i
                  class="el-icon-circle-close"
                  @click="handleDel_img(index, 2)"
                  style="color: #ccc"
                ></i>
              </div>
              <div
                class="image_info"
                @click="chooseFile(2)"
                v-if="collection_file_list.length < 3"
              >
                <img src="@/assets/img/upload_img.png" alt="" />
                <span>上传相关图片</span>
                <input
                  type="file"
                  style="display: none"
                  ref="choosePhoto"
                  @change="photoChange($event)"
                  v-if="upload_img"
                  id="upload_file"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="add_Arrival_line"
          style="align-items: flex-start; height: 110px"
        >
          <div class="add_Arrival_line_tit">备注：</div>
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            v-model="remarks"
          >
          </el-input>
        </div>
        <div class="add_Arrival_line">
          <div class="add_Arrival_line_tit">是否促销：</div>
          <div class="add_Arrival_line_check">
            <el-radio v-model="radio" label="1">否</el-radio>
            <el-radio v-model="radio" label="2">是</el-radio>
          </div>
        </div>
        <div class="add_Arrival_line" v-if="radio == 2">
          <div class="add_Arrival_line_tit">促销类型：</div>
          <el-input
            type="text"
            disabled
            placeholder="暂无促销"
            v-if="PromotionList.length == 0"
          />
          <el-select v-model="Promotion_id" placeholder="请选择促销类型" v-else>
            <el-option
              v-for="(item, index) in PromotionList"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="add_Arrival_head_tit"><i></i>分单信息</div>
        <div v-for="(item, index) in contract_fd_list" :key="index">
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">分单人：</div>
            <el-autocomplete
              class="inline-input"
              v-model="item.user_name"
              :fetch-suggestions="querySearch"
              placeholder="请输入姓名选择分单人"
              :trigger-on-focus="false"
              @select="handleSelect"
            ></el-autocomplete>
            <i
              class="el-icon-remove-outline pointer"
              style="font-size: 30px; color: #ccc; margin-left: 20px"
              @click="handleDel_fendan(index)"
            ></i>
          </div>
          <div class="add_Arrival_line" style="padding-right: 160px">
            <div class="add_Arrival_line_tit">分单金额：</div>
            <el-input
              type="text"
              v-model="item.to_money"
              placeholder="请输入金额"
            />
          </div>
        </div>
        <div class="add_Arrival_line_add pointer" @click="handleAdd_fendan">
          <i class="el-icon-plus"></i>
          新增分单人
        </div>
        <div class="add_Arrival_bottom">
          <div @click="handleClose">取消</div>
          <div @click="handleOK" v-if="isAdd">确认</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { collectionList } from "../../api/User/finance";
import { PromotionList, getUserData, addOrder,isAddOrder } from "../../api/User/customer";
import { addOrderMore } from "../../api/User/order_list";
import { uploadFile } from "../../api/User/index";
export default {
  props: ["detail"],
  data() {
    return {
        is_fist:false,
      tail_sincere:'',//尾款金额
      order_id: '',//订单ID
      isFirstAdd:1,//是否首次到账 1 首次  2 多次
      product_name_id:'',//产品ID
      total_amount_receivable:'',//本单应收全款金额
      service_time: '',//服务开始时间
      service_time_end:'',//服务结束时间
      customerdetail: {}, //客户详情
      prooption: [],
      pro_id: "", //到账产品
      Arrival_money: "", //到账金额
      Arrivaloption: [
        { value: "1", label: "定金" },
        { value: "2", label: "尾款" },
        { value: "3", label: "全款" },
      ],
      Arrival_type: "", //到账类型
      collectionList: [], //收款方式列表
      collection: "", //收款方式
      time: "", //到账时间
      contract_money: "", //合同金额
      contract_number: "", //合同单号
      radio: "1", //是否促销
      PromotionList: [], //促销列表
      Promotion_id: [], //选择促销id
      //   contract_fd_people: "", //分单人
      //   contract_fd_money: "", //分单金额
      contract_fd_list: [
        {
          user_id: "",
          user_name: "",
          to_money: "",
        },
      ], //多个分单人列表
      //   check_contract: {}, //选中的分单人信息
      fluterArr: [], //分单搜索人
      remarks: "", //备注
        order_remarks:'',
      chooseFile_what: "", //1 合同  2 收款凭证
      contract_file_list: [], //合同文件
      contract_file_list_arr: [], //合同文件放大
      collection_file_list: [], //收款文件
      collection_file_list_arr: [], //收款文件放大
      upload_img: true,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
      add_flag: false,
      isAdd: true,
    };
  },
  created() {
    console.log('detail',this.detail)
    this.customerdetail = this._props.detail;
    let list = this._props.detail.my_customer_product;
    list.forEach((item) => {
      item.label = item.product_name.product_name;
      item.value = item.product_name_id;
    });
    this.prooption = list;
    console.log(this.prooption )
    collectionList().then((res) => {
      //收款方式
      if (res.data.code == 200) {
        this.collectionList = res.data.data;
      }
    });
  },
  methods: {
  
    getwaytime_s(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // let strDate = Y + M + D;
      let strDate = Y + M + D + h + m + "00";
      return strDate;
    },
    handleChangePro(id) {
      this.product_name_id = id;
      this.PromotionList = [];
      this.Promotion_id = "";
      let { customerdetail } = this;
      let data = {
        my_customer_id: customerdetail.ci_id?customerdetail.my_customer_id:customerdetail.id,
        customer_id: customerdetail.customer_id,
        product_name_id: id,
        status: 3,
      };
      let cx_arr = [
        { value: 1, label: "实物" },
        { value: 2, label: "返现" },
        { value: 3, label: "打折" },
        { value: 4, label: "赠送服务时长" },
        { value: 5, label: "赠送其它服务" },
      ];
      PromotionList(data).then((res) => {
        if (res.data.code == 200) {
          let list = res.data.data.data;
          list.forEach((item) => {
            cx_arr.forEach((itm) => {
              if (item.type == itm.value) {
                item.name = itm.label;
              }
            });
          });
          this.PromotionList = list;
        }
      });

      //根据产品 判断是新增还是二次到账
      this.isAddProduct();
    },
    isAddProduct(){
      let data = {
        customer_id:  this.customerdetail.customer_id,//客户ID
        product_name_id: this.product_name_id,//产品ID
        my_customer_id: this.customerdetail.id,
      }
       isAddOrder(data).then((res) => {
         console.log(res)
      
          if(!res){
            this.isAdd = false;
            return;
          }
          this.isAdd = true
          this.isFirstAdd = res.data.data.cooperation_type;//1  首次  2  二次到账
          if(this.isFirstAdd == 2){
            let order = res.data.data.order
            this.order_id = order.id;
            this.tail_sincere = res.data.data.tail_sincere;//尾款
          }
          
        
      });
       
    },
     blurNumber(){
       console.log(this.Arrival_money)
         console.log(this.tail_sincere)
       if(this.isFirstAdd == 1) return;
            if(Number(this.Arrival_money) >Number( this.tail_sincere)){
                this.$message({
                  type: "error",
                  message: '本次实收金额不能大于本单应收全款金额',
                });
                this.Arrival_money = ''
            }
      //   if(this.Arrival_money > this.order_info.accounts_receivable){
    
     
      // }
      
    },
    handleOK() {
      

        let contract_file = []; //合同上传文件
        let collection_file = []; //收款凭证
        let order_data_branch_json = []; //分单人
        let to_time = "";
        let {
          customerdetail,
          contract_money,
          contract_number,
          Arrival_money,
        // Arrival_type,//到账类型
          collection,
          time,
          pro_id,
          Promotion_id,
          contract_fd_list,
          // check_contract,
          contract_file_list,
          collection_file_list,
          remarks,
            order_remarks
        } = this;

        contract_fd_list.forEach((item) => {
          if (item.user_id != "") {
            // console.log(item);
            let obj = {
              user_id: item.user_id,
              to_money: item.to_money,
            };
            order_data_branch_json.push(obj);
          }
        });
        if (order_data_branch_json.length == 0) {
          order_data_branch_json = JSON.stringify([]);
        } else {
          order_data_branch_json = JSON.stringify(order_data_branch_json);
        }

        contract_file_list.forEach((item) => {
          let obj = {
            file_url: item.url,
          };
          contract_file.push(obj);
        });
        collection_file_list.forEach((item) => {
          let obj = {
            file_url: item.url,
          };
          collection_file.push(obj);
        });
        
        this.add_flag = true;
      if(this.isFirstAdd == 2){//二次添加
          let secondData = {
            order_id: this.order_id,
            product_name_id: pro_id, //产品id

            to_money: Arrival_money, //到账金额
            collection_id: collection, //收款方式
            promotion_id: Promotion_id, //促销id
            order_data_branch_json, //分单人
            collection_file: JSON.stringify(collection_file), //收款文件
            remarks,
              order_remarks
          }
          if ( collection_file.length == 0) {
            this.$message({
              message: "请上传收款凭证",
              type: "error",
            });
            return;
          }
          if(!this.is_fist){
              this.is_fist=true
              addOrderMore(secondData).then(res =>{
                  if (res.data.code == 200) {
                      this.$message({
                          message: res.data.message,
                          type: "success",
                      });
                      this.$emit("close");
                      this.is_fist=false
                  }
              }).catch(()=>{
                  this.is_fist=false
              })
          }else{
              this.$message({
                  message: '正在提交中',
                  type: "error",
              });
          }


      }
      else{





        if (
          contract_money == "" ||
          contract_number == "" ||
          Arrival_money == "" ||
        
          collection == "" ||
          pro_id == ""
        ) {
          this.$message({
            message: "请完善数据",
            type: "error",
          });
          return;
        }
        
       

        if (contract_file.length == 0 || collection_file.length == 0) {
          this.$message({
            message: "请完善数据",
            type: "error",
          });
          return;
        }
        let data = {
          my_customer_id: customerdetail.ci_id?customerdetail.my_customer_id:customerdetail.id,//我的客户id
          customer_id: customerdetail.customer_id, //客户id
          product_name_id: pro_id, //产品id
          contract_money, //合同金额
          contract_number, //合同单号
          to_money: Arrival_money, //到账金额
          //type: Arrival_type, //到账类型 1定金 2尾款 3全款
          collection_id: collection, //收款方式
          // to_time, //到账时间
          promotion_id: Promotion_id, //促销id
          order_data_branch_json, //分单人
          contract_file: JSON.stringify(contract_file), //合同文件
          collection_file: JSON.stringify(collection_file), //收款文件
          total_amount_receivable: this.total_amount_receivable,//本单应收全款金额
          service_time: this.service_time,//服务开始时间
          service_time_end: this.service_time_end,//服务结束时间
          remarks,
            order_remarks
        };
        if(!this.total_amount_receivable){
          this.$message({
            message: "请填写本单应收全款金额",
            type: "error",
          });
          return;
        }
        if(this.service_time == '' || this.service_time_end == ''){
          this.$message({
            message: "服务起止时间不能为空",
            type: "error",
          });
          return;
        }
          if(!this.is_fist) {
              this.is_fist = true
              addOrder(data).then((res) => {

                  if (res.data.code == 200) {
                      this.$message({
                          message: res.data.message,
                          type: "success",
                      });
                      this.$emit("close");
                      this.is_fist = false
                  }
              }).catch(()=>{
                  this.is_fist=false
              })
          }else{
              this.$message({
                  message: '正在提交中',
                  type: "error",
              });
          }
      }
    },
    chooseFile: function (i) {
      this.chooseFile_what = i;
      if(i==1){
        this.$refs.choosePhotos.addEventListener('change',this.photoChange);
          this.$refs.choosePhotos.dispatchEvent(new MouseEvent("click"));
      }else{
          this.$refs.choosePhoto.addEventListener('change',this.photoChange);
this.$refs.choosePhoto.dispatchEvent(new MouseEvent("click"));
      }
      this.upload_img = false;
      setTimeout(() => {
        this.upload_img = true;
      }, 10);
    },
    photoChange(el) {
      var file = el.target.files[0]; //name: "dangqi1.png" || type: "image/png"
      var type = file.type.split("/")[0];
      if (type === "image") {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        var that = this;
        reader.onloadend = function () {
          var dataURL = reader.result;
          var blob = that.dataURItoBlob(dataURL);
          that.upload(blob); //执行上传接口
        };
      } else {
        this.$message.error("请上传图片哦~~");
      }
    },
    dataURItoBlob(dataURI) {
      // base64 解码
      let byteString = window.atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let T = mimeString.split("/")[1];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    upload(imgUrl) {
      let { chooseFile_what } = this;
      let formData = new FormData();
      this.$message({
        message: "上传中，请稍后",
      });
      formData.append("file", imgUrl);
      uploadFile(formData).then((res) => {
        if (res.data.code == 200) {
          if (chooseFile_what == 1) {
            this.contract_file_list.push(res.data.data);
            this.contract_file_list_arr.push(res.data.data.file_url);
          }
          if (chooseFile_what == 2) {
            this.collection_file_list.push(res.data.data);
            this.collection_file_list_arr.push(res.data.data.file_url);
          }
          this.$message({
            message: "上传成功",
            type: "success",
          });
        }
      });
    },
    handleDel_img(num, i) {
      // i  ==1  删除合同   2   删除 收款
      let {
        contract_file_list,
        collection_file_list,
        contract_file_list_arr,
        collection_file_list_arr,
      } = this;
      if (i == 1) {
        contract_file_list.forEach((item, index) => {
          if (index == num) {
            contract_file_list.splice(index, 1);
            contract_file_list_arr.splice(index, 1);
          }
        });
      }
      if (i == 2) {
        collection_file_list.forEach((item, index) => {
          if (index == num) {
            collection_file_list.splice(index, 1);
            collection_file_list_arr.splice(index, 1);
          }
        });
      }
    },
    handleClose() {
      this.radio = "1";
      this.$emit("close");
    },
    querySearch(queryString, cb) {
       getUserData({ user_name: queryString }).then((res) => {
                let list = res.data.data.data;
                list.forEach((item) => {
                    item.value = item.user.name + " 【" + item.company.name + "】";
                });
                this.fluterArr = list;
                var restaurants = this.loadAll();
                var results = queryString
                    ? restaurants.filter(this.createFilter(queryString))
                    : restaurants;
                // // 调用 callback 返回建议列表的数据
                cb(results);
            });
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    loadAll() {
      return this.fluterArr;
    },
    handleSelect(val) {
      let { contract_fd_list } = this;
      contract_fd_list.forEach((item) => {
        if (item.user_name == val.value) {
          item.user_id = val.user_id;
        }
      });
    },
    handleAdd_fendan() {
      //新增分单人
      let bool = false;
      let { contract_fd_list } = this;
      contract_fd_list.forEach((item) => {
        if (item.user_name == "" || item.to_money == "") {
          this.$message({
            message: "请完善分单信息",
            type: "error",
          });
          bool = true;
        }
      });
      if (!bool) {
        let obj = {
          user_id: "",
          user_name: "",
          to_money: "",
        };
        contract_fd_list.push(obj);
      }
    },
    handleDel_fendan(i) {
      //删除分单人
      let { contract_fd_list } = this;
      if (contract_fd_list.length < 2) {
        return;
      }
      contract_fd_list.splice(i, 1);
    },
  },
};
</script>

<style lang="scss">
.add_Arrival_box {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .add_Arrival {
    z-index: 99;
    width: 650px;
    height: 90%;
    min-width: 650px;
    background: #ffff;
    position: relative;
    margin: auto;
    margin-top: 50px;
    border-radius: 10px;
    padding-bottom: 30px;
    overflow: hidden;
    .el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
      display: none;
    }
    .el-date-editor--datetime {
      .el-input__inner {
        padding-left: 15px;
      }
    }
    .add_Arrival_head {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.8);
      opacity: 1;
      border: 1px solid rgba(0, 0, 0, 0.2);
      position: relative;
      i {
        position: absolute;
        right: 20px;
        padding: 10px;
      }
    }
    .add_Arrival_box_info {
      height: 95%;
      overflow: auto;
      .add_Arrival_head_tit {
        padding: 0 30px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: rgba(0, 0, 0, 0.6);
        opacity: 1;
        display: flex;
        align-items: center;
        margin: 10px 0;
        i {
          display: block;
          width: 6px;
          height: 6px;
          background: #3a85fc;
          border-radius: 50%;
          opacity: 1;
          margin-right: 5px;
        }
      }
      .row-item-tit{
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.8);
          opacity: 1;
          padding: 0 110px;
          margin-bottom: 15px;
          .star{
            color: #FF3737;
          }
      }
      .row-item-ipt{
        padding-left: 180px;
        padding-right: 110px;
        display: flex;
        flex: 1;
        input{
          height: 32px;
          line-height: 32px;
          margin-bottom: 15px;
          
        }
        .el-input__icon{
          height: 32px;
          line-height: 32px;
        }
        .mg-lr10{
          margin: 0 10px;
          padding-top: 7px;
        }
      }
      .add_Arrival_line {
        display: flex;
        padding: 0 110px;
        height: 32px;
        margin: 20px 0;
        align-items: center;
        .add_Arrival_line_tit {
          min-width: 70px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.8);
          opacity: 1;
        }
       
        .contract_img {
          .flow_kind_text {
            .flow_kind_text_img {
              position: relative;
              border-radius: 4px;
              margin-right: 10px;
              .el-icon-circle-close {
                display: block;
                position: absolute;
                top: -5px;
                right: -5px;
                cursor: pointer;
              }
              .el-image-viewer__btn {
                color: #fff;
              }
            }
          }
          .image_info {
            width: 100px;
            height: 100px;
            position: relative;
            display: flex;
            background: rgba(0, 0, 0, 0.1);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            opacity: 0.6;
            overflow: hidden;
            cursor: pointer;
            img {
              height: 30px;
              width: 30px;
            }
            span {
              margin-top: 10px;
              color: rgba(0, 0, 0, 0.3);
            }
          }

          div:first-child {
            display: flex;
            .el-upload-list {
              .el-upload-list__item {
                width: 110px;
                height: 110px;
              }
            }
          }
        }
        .el-autocomplete {
          flex: 1;
        }
        .el-textarea {
          flex: 1;
          .el-textarea__inner {
            //el_input中的隐藏属性
            resize: none; //主要是这个样式
          }
        }
        .el-input {
          flex: 1;
          height: 32px;
          input {
            height: 32px;
          }
          .el-input__prefix {
            .el-icon-date {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .el-select {
          flex: 1;
          .el-input__suffix {
            display: flex;
            align-items: center;
          }
        }
        .el-date-editor {
          flex: 1;
          height: 100%;
          input {
            height: 100%;
            padding-left: 15px;
          }
          .el-input__prefix {
            left: initial;
            right: 5px;
            display: flex;
            align-items: center;
            .el-input__icon {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          &.date-close {
            .el-icon-date {
              display: none;
            }
          }
        }
        .add_Arrival_line_inp {
          flex: 1;
          height: 100%;
          padding-left: 15px;
          display: flex;
          align-items: center;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
        .add_Arrival_line_check {
          height: 32px;
          display: flex;
          align-items: center;
          label {
            display: flex;
            align-items: center;
            margin-right: 110px;
            span:first-child {
              display: flex;
              align-items: center;
            }
          }
        }
      }
      .add_Arrival_line_add {
        margin: 0 160px 0 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        background: #ffffff;
        border: 1px dashed rgba(0, 0, 0, 0.2);
        opacity: 1;
        border-radius: 2px;
        i {
          margin-right: 5px;
        }
      }
      .add_Arrival_bottom {
        width: 100%;
        height: 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
        padding-right: 40px;
        margin-bottom: 25px;
        div {
          width: 80px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          opacity: 1;
          cursor: pointer;
        }
        div:first-child {
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
          opacity: 1;
          color: rgba(0, 0, 0, 0.5);
        }
        div:last-child {
          background: #3a85fc;
          opacity: 1;
          color: #fff;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>