<template>
  <div style="flex: 1" class="position_control">
    <el-main>
      <div class="position_control_all">
        <div class="position_left">
          <div class="position_title">
            <div
              class="add_kind pointer"
              @click="show_draw(1, 1)"
              v-if="jurisdiction.system_pos_list_add"
            >
              <i class="el-icon-plus"></i>
              添加分类
            </div>
            <div class="position_title_sort">操作</div>
          </div>
          <div class="position_item">
            <div
              class="position_item_info pointer"
              :class="[check_act == item.id ? 'check_act' : '']"
              v-for="(item, index) in left_list"
              :key="index"
              @click="check_kind(item.id)"
            >
              <div class="type">{{ item.classify }}</div>
              <div class="setting">
                <div>
                  <i
                    v-if="index != 0 && jurisdiction.system_pos_list_move"
                    class="el-icon-top"
                    @click.stop="left_up(item.id, index)"
                  ></i>
                  <i
                    v-if="
                      index !== left_list.length - 1 &&
                      jurisdiction.system_pos_list_move
                    "
                    class="el-icon-bottom"
                    @click.stop="left_down(item.id, index)"
                  ></i
                  ><i
                    class="el-icon-setting"
                    @click="show_draw(1, 0, item)"
                    v-if="jurisdiction.system_pos_list_edit"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="position_right">
          <div class="right_title">
            <div class="title_left">
              职位名：<input
                type="text"
                v-model="position"
                placeholder="请输入职位名"
              />
              <div class="title_serch pointer" @click="serch_btn">查询</div>
              <div class="title_reload pointer" @click="recheck">重置</div>
            </div>
            <div
              class="title_right"
              @click="show_draw(2, 1)"
              v-if="jurisdiction.system_pos_list_add"
            >
              <div class="pointer"><i class="el-icon-plus"></i> 添加职位</div>
            </div>
          </div>
          <div class="right_body">
            <el-table :data="tableData" height="250" border style="width: 100%">
              <el-table-column prop="index" label="序号" width="50">
                <template slot-scope="scope">
                  <div>{{ scope.row.order_by * 1 }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="position" label="职位名字">
              </el-table-column>
              <el-table-column label="是否为管理岗" width="120">
                <template slot-scope="scope">
                  {{ scope.row.status == 1 ? "是" : "否" }}
                </template>
              </el-table-column>
              <el-table-column label="岗位类型" width="100">
                <template slot-scope="scope">
                  {{ scope.row.is_sales == 1 ? "销售岗" : scope.row.is_sales == 2 ?"职能岗":'客服岗'}}
                </template>
              </el-table-column>
              <el-table-column label="创建人" width="100">
                <template slot-scope="scope">
                  {{ scope.row.user ? scope.row.user.name : "--" }}
                </template>
              </el-table-column>
              <el-table-column label="角色">
                <template slot-scope="scope">
                  <div class="role_box">
                    <div v-if="scope.row.position_role.length == 0">--</div>
                    <div
                      v-if="scope.row.position_role.length > 0"
                      v-for="(item, index) in scope.row.position_role"
                      :key="index"
                    >
                      {{ item.role_name ? item.role_name : "--" }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="value" label="状态" v-if="all_power.is_change_pro">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.value"
                    active-color="#13ce66"
                    inactive-color="#ccc"
                    @change="change_swith(scope.row)"
                  >
                  </el-switch>
                </template>
              </el-table-column> -->
              <el-table-column prop="created_at" label="创建时间">
              </el-table-column>
              <el-table-column prop="address" width="160" label="操作">
                <template slot-scope="scope">
                  <div class="position_change">
                    <div
                      class="pointer"
                      @click="show_draw(2, 0, scope.row)"
                      v-if="jurisdiction.system_pos_list_edit"
                    >
                      编辑
                    </div>
                    <div
                      class="pointer"
                      @click="right_up(scope.row)"
                      v-if="
                        scope.row.index != 0 &&
                        jurisdiction.system_pos_list_move
                      "
                    >
                      上移
                    </div>
                    <div
                      class="pointer"
                      @click="right_down(scope.row)"
                      v-if="
                        scope.row.index != tableData.length - 1 &&
                        jurisdiction.system_pos_list_move
                      "
                    >
                      下移
                    </div>
                    <!-- <div
                      class="pointer"
                      @click="right_top(scope.row)"
                      v-if="
                        scope.row.index != 0 &&
                        jurisdiction.system_pos_list_move
                      "
                    >
                      置顶
                    </div> -->
                    <!-- <div
                      class="pointer"
                      @click="right_bottom(scope.row)"
                      v-if="
                        scope.row.index != tableData.length - 1 &&
                        jurisdiction.system_pos_list_move
                      "
                    >
                      置底
                    </div> -->
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <el-drawer
          :visible.sync="drawer"
          :with-header="false"
          size="40%"
          class="human_drawer"
        >
          <!-- 新增分类 -->
          <div class="drawer_all" v-if="drawer_type == 1">
            <div class="drawer_title">{{ is_add ? "添加" : "编辑" }}分类</div>
            <div class="compony_detail">分类信息</div>
            <div class="drawer_compony_info">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">分类名称</div>
              <el-input
                type="text"
                placeholder="请输入分类名称"
                v-model="truename"
                maxlength="15"
                show-word-limit
              />
            </div>
            <div class="drawer_bottom">
              <div
                class="pointer"
                @click="check_detail"
                :class="[add_flag ? 'human_nosent' : '']"
              >
                确定
              </div>
              <div
                class="pointer"
                style="
                  color: #ff4141;
                  background: #ffffff;
                  border: 1px solid #ff4141;
                "
                v-if="0"
                @click="del_compony"
              >
                删除
              </div>
              <div
                class="pointer"
                style="
                  border: 1px solid #c3c6ce;
                  background: #ffffff;
                  color: #414141;
                "
                @click="drawer = false"
              >
                取消
              </div>
            </div>
          </div>
          <!-- 新增职位 -->
          <div class="drawer_all" v-if="drawer_type == 2">
            <div class="drawer_title">{{ is_add ? "添加" : "编辑" }}职位</div>
            <div class="compony_detail">职位信息</div>
            <div class="drawer_compony_info">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">职位分类</div>
              <el-select v-model="kind_id" placeholder="请选择">
                <el-option
                  v-for="item in kind_list"
                  :key="item.id"
                  :label="item.classify"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="drawer_compony_info">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">职位名称</div>
              <el-input
                type="text"
                placeholder="请输入职位名称"
                v-model="truename"
                maxlength="15"
                show-word-limit
              />
            </div>
            <div class="drawer_compony_info">
              <span style="color: #ff0000; width: 6.38px"></span>
              <div class="drawer_nice">职位类型</div>
              <el-select v-model="position_type" placeholder="请选择(非必选)">
                <el-option
                  v-for="item in position_type_arr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="drawer_compony_info">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">{{ is_add ? "添加" : "编辑" }}角色</div>
              <el-select v-model="role" multiple placeholder="请选择">
                <el-option
                  v-for="item in Role_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="drawer_compony_info">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">是否为管理岗</div>
              <el-select v-model="Administration" placeholder="请选择">
                <el-option
                  v-for="item in Administration_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="drawer_compony_info">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">岗位类型</div>
              <el-select v-model="issales" placeholder="请选择">
                <el-option
                  v-for="item in sales"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="drawer_bottom">
              <div
                class="pointer"
                @click="check_detail"
                :class="[add_flag ? 'human_nosent' : '']"
              >
                确定
              </div>
              <div
                class="pointer"
                style="
                  color: #ff4141;
                  background: #ffffff;
                  border: 1px solid #ff4141;
                "
                v-if="0"
                @click="delposition"
              >
                删除
              </div>
              <div
                class="pointer"
                style="
                  border: 1px solid #c3c6ce;
                  background: #ffffff;
                  color: #414141;
                "
                @click="drawer = false"
              >
                取消
              </div>
            </div>
          </div>
        </el-drawer>
      </div>
    </el-main>
  </div>
</template>
<script>
import {
  positionList,
  addPositionClassify,
  editPositionClassify,
  orderByPositionClassify,
  addPosition,
  editPosition,
  setOrderBy,
} from "../../api/User/position";
import { selectRole } from "../../api/User/human";

export default {
  data() {
    return {
      check_act: null,
      drawer: false,
      drawer_type: "",
      left_list: [],
      tableData: [],
      left_total: "",
      right_list: [],
      is_change: false,
      is_add: false,
      add_flag: false,
      truename: "",
      product_id: "",
      kind_list: [],
      kind_id: "",
      //职位
      position: "",
      jurisdiction: {
        system_pos_list: false,
        system_pos_list_str: "",
        system_pos_list_add: false,
        system_pos_list_add_str: "",
        system_pos_list_edit: true,
        system_pos_list_edit_str: "",
        system_pos_list_move: false,
        system_pos_list_move_str: "",
      },
      role_alldata: {},
      Role_list: [], //所有角色列表
      role: [], //角色
      sales: [
        { id: 1, name: "销售岗" },
        { id: 2, name: "职能岗" },
          { id: 3, name: "客服岗" }
      ],
      issales: "",
      position_type_arr: [
        { id: 1, name: "财务" },
        { id: 2, name: "人事" },
      ],
      position_type: "",
      Administration_list: [
        //是否管理岗
        { id: 1, name: "是" },
        { id: 0, name: "否" },
      ],
      Administration: "",
      position_id: "", //编辑职位id
    };
  },
  created() {
    let roleList = JSON.parse(localStorage.getItem("user")).role_permissions; //权限列表
    for (let i in roleList) {
      if (roleList[i].permissions == "resources") {
        this.role_alldata = roleList[i];
      }
    }
    console.log(roleList)
    roleList.forEach((item) => {
        if(item.data){
            if (item.data.length > 0) {
                item.data.forEach((itm) => {
                    if (itm.permissions == "system_pos_list") {
                        this.jurisdiction.system_pos_list = true;
                        this.jurisdiction.system_pos_list_str = itm.permissions_id_string;
                    }
                    if (itm.permissions == "system_pos_list_add") {
                        this.jurisdiction.system_pos_list_add = true;
                        this.jurisdiction.system_pos_list_add_str =
                            itm.permissions_id_string;
                    }
                    if (itm.permissions == "system_pos_list_edit") {
                        this.jurisdiction.system_pos_list_edit = true;
                        this.jurisdiction.system_pos_list_edit_str =
                            itm.permissions_id_string;
                    }
                    if (itm.permissions == "system_pos_list_move") {
                        this.jurisdiction.system_pos_list_move = true;
                        this.jurisdiction.system_pos_list_move_str =
                            itm.permissions_id_string;
                    }
                });
            }
        }
    });
    selectRole().then((res) => {
      if (res.data.code == 200) {
        this.Role_list = res.data.data;
        positionList({
          permissions_id_string: this.jurisdiction.system_pos_list_str,
        }).then((res) => {
          this.kind_list = res.data.data;
          this.left_list = res.data.data;
          this.kind_id = res.data.data[0].id;
          this.check_kind(res.data.data[0].id);
        });
      }
    });
  },
  methods: {
    // i 是否添加分类 is_add是否添加 item内容
    show_draw(i, is_add, item) {
      this.position_id = "";
      this.truename = "";
      this.issales = "";
      this.role = [];
      this.Administration = "";
      this.position_type = "";
      if (is_add) {
        this.is_add = true;
      } else {
        this.is_add = false;
      }
      if (i == 1) {
        if (item) {
          this.truename = item.classify;
        }
      } else {
        if (item) {
          this.truename = item.position;
          this.position_id = item.id;
          item.position_role.forEach((itm) => {
            this.role.push(itm.role_id);
          });
          this.Administration = item.status;
          this.issales = item.is_sales==0?'':item.is_sales * 1;
          this.kind_id = item.position_classify_id==0?'':item.position_classify_id * 1;
          this.position_type =
            item.position_type == 0 ? "" : item.position_type * 1;
        }
      }
      this.drawer_type = i;
      this.drawer = true;
    },
    check_detail() {
      if (this.add_flag) {
        return;
      }
      this.add_flag = true;
      if (this.is_add) {
        if (this.drawer_type == 1) {
          //新增分类
          if (this.truename == "") {
            this.$message({
              type: "error",
              message: "分类名字不能为空",
            });
            this.add_flag = false;
            return;
          }
          addPositionClassify({
            classify: this.truename,
            permissions_id_string: this.jurisdiction.system_pos_list_add_str,
          }).then((res) => {
            this.add_flag = false;
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: res.data.message,
              });
              this.drawer = false;
              positionList({
                permissions_id_string: this.jurisdiction.system_pos_list_str,
              }).then((ress) => {
                this.kind_list = ress.data.data;
                this.left_list = ress.data.data;
              });
            }
          });
        } else {
          //新增职位
          let { issales, Administration, role, position_type } = this;
          let data = {
            permissions_id_string: this.jurisdiction.system_pos_list_add_str,
            position: this.truename,
            position_classify_id: this.kind_id,
            status: Administration,
            is_sales: issales,
            role_id: role.join(","),
            position_type: position_type ? position_type : 0,
          };
          for (const i in data) {
            if (i !== "position_type") {
              if (data[i] === "") {
                this.$message({
                  message: "请完善信息",
                  type: "error",
                });
                this.add_flag = false;
                return;
              }
            }
          }
          addPosition(data).then((res) => {
            this.add_flag = false;
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: res.data.message,
              });
              this.drawer = false;
              this.check_kind(this.check_act);
            }
          });
        }
        //修改
      } else {
        if (this.drawer_type == 1) {
          if (this.truename == "") {
            this.$message({
              type: "error",
              message: "分类名字不能为空",
            });
            this.add_flag = false;
            return;
          }
          editPositionClassify({
            permissions_id_string: this.jurisdiction.system_pos_list_add_str,
            classify: this.truename,
            position_classify_id: this.check_act,
          }).then((res) => {
            this.add_flag = false;
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: res.data.message,
              });
              this.drawer = false;
              positionList({
                permissions_id_string: this.jurisdiction.system_pos_list_str,
              }).then((ress) => {
                this.kind_list = ress.data.data;
                this.left_list = ress.data.data;
              });
            }
          });
        } else {
          //编辑职位
          let {} = this;
          let data = {
            permissions_id_string: this.jurisdiction.system_pos_list_add_str,
            position_classify_id: this.kind_id,
            position_id: this.position_id,
            role_id: this.role.join(","),
            status: this.Administration,
            is_sales: this.issales,
            position: this.truename,
            position_type: this.position_type ? this.position_type : 0,
          };
          for (const i in data) {
            if (i !== "position_type") {
              if (data[i] === "") {
                this.$message({
                  message: "请完善信息",
                  type: "error",
                });
                this.add_flag = false;
                return;
              }
            }
          }
          editPosition(data).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: res.data.message,
              });
              this.drawer = false;
              this.add_flag = false;
              this.check_kind(this.check_act);
            }
          });
        }
      }
    },
    // change_swith(item) {
    //    let str=''
    //        for (let i in this.role_alldata.data) {
    //             if (this.role_alldata.data[i].permissions == "system_pro_list_edit") {
    //               str = this.role_alldata.data[i].permissions_id_string;
    //             }
    //           }

    //       editProductName({
    //         permissions_id_string: str,
    //     name: item.product_name,
    //     id: item.id,
    //     status: item.value ? "1" : "2",
    //   }).then((res) => {
    //     if (res.data.code == 200) {
    //       this.$message({
    //         type: "success",
    //         message: res.data.message,
    //       });
    //       this.drawer = false;
    //       this.add_flag = false;
    //     }
    //   });
    // },
    check_kind(id) {
      //点击确定种类
      let { Role_list } = this;
      if (this.is_change) {
        return;
      }
      this.kind_id = id;
      this.is_change = true;
      this.check_act = id;
      if (this.position != "") {
        this.serch_btn();
        return;
      }
      positionList({
        permissions_id_string: this.jurisdiction.system_pos_list_str,
        position_classify_id: id,
      }).then((res) => {
        this.is_change = false;
        let data = res.data.data;
        data.forEach((item) => {
          item.position_role.forEach((itm) => {
            Role_list.forEach((attr) => {
              if (itm.role_id == attr.id) {
                itm.role_name = attr.name;
              }
            });
          });
        });
        for (let i in data) {
          if (!data[i].created_at) {
            data[i].created_at = "--";
          }
          data[i].index = i * 1;
        }
        this.tableData = data;
      });
    },
    delposition(){
      // console.log(this.kind_id);
      // console.log(this.position_id);
    },
    left_up(id, index) {
      if (this.is_change) {
        return;
      }
      this.is_change = true;
      orderByPositionClassify({
        permissions_id_string: this.jurisdiction.system_pos_list_move_str,
        old_by: this.left_list[index].order_by,
        new_by: this.left_list[index - 1].order_by,
        position_classify_id: this.left_list[index].id,
      }).then((res) => {
        this.is_change = false;
        if (res.data.code == 200) {
          let h = this.left_list.splice(index, 1);
          this.left_list.splice(index - 1, 0, h[0]);
          this.$forceUpdate();
        }
      });
    },
    left_down(id, index) {
      if (this.is_change) {
        return;
      }
      this.is_change = true;
      orderByPositionClassify({
        permissions_id_string: this.jurisdiction.system_pos_list_move_str,
        old_by: this.left_list[index].order_by,
        new_by: this.left_list[index + 1].order_by,
        position_classify_id:this.kind_id,
        position_classify_id: this.left_list[index].id,
      }).then((res) => {
        this.is_change = false;
        if (res.data.code == 200) {
          let h = this.left_list.splice(index, 1);
          this.left_list.splice(index + 1, 0, h[0]);
          this.$forceUpdate();
        }
      });
    },
    right_up(row) {
      if (this.is_change) {
        return;
      }
      this.is_change = true;
      let { index } = row;
      if (index == 0) {
        return;
      }
      setOrderBy({
        permissions_id_string: this.jurisdiction.system_pos_list_move_str,
        old_by: this.tableData[index].order_by,
        new_by: this.tableData[index - 1].order_by,
        position_classify_id:this.kind_id,
        position_id: this.tableData[index].id,
      }).then((res) => {
        this.is_change = false;
        if (res.data.code == 200) {
          this.check_kind(this.check_act);
        }
      });
    },
    right_down(row) {
      if (this.is_change) {
        return;
      }
      let { index } = row;
      this.is_change = true;
      setOrderBy({
        permissions_id_string: this.jurisdiction.system_pos_list_move_str,
        old_by: this.tableData[index].order_by,
        new_by: this.tableData[index + 1].order_by,
        position_id: this.tableData[index].id,
        position_classify_id:this.kind_id,
      }).then((res) => {
        this.is_change = false;
        if (res.data.code == 200) {
          this.check_kind(this.check_act);
        }
      });
    },
    right_top(row) {
      let { index } = row;
      if (this.is_change) {
        return;
      }
      setOrderBy({
        permissions_id_string: this.jurisdiction.system_pos_list_move_str,
        old_by: this.tableData[index].order_by,
        new_by: this.tableData[0].order_by,
        position_id: this.tableData[index].id,
        position_classify_id:this.kind_id,
      }).then((res) => {
        this.is_change = false;
        if (res.data.code == 200) {
          this.check_kind(this.check_act);
        }
      });
    },
    right_bottom(row) {
      let { index } = row;
      if (this.is_change) {
        return;
      }
      this.is_change = true;
      setOrderBy({
        permissions_id_string: this.jurisdiction.system_pos_list_move_str,
        old_by: this.tableData[index].order_by,
        new_by: this.tableData[this.tableData.length - 1].order_by,
        position_id: this.tableData[index].id,
      }).then((res) => {
        this.is_change = false;
        if (res.data.code == 200) {
          this.check_kind(this.check_act);
        }
      });
    },
    serch_btn() {
      let { position } = this;
      positionList({
        position,
        position_classify_id: this.kind_id,
        permissions_id_string: this.jurisdiction.system_pos_list_str,
      }).then((res) => {
        if (res.data.code == 200) {
          let list = res.data.data;
          list.forEach((item) => {
            item.position_role.forEach((itm) => {
              this.Role_list.forEach((attr) => {
                if (itm.role_id == attr.id) {
                  itm.role_name = attr.name;
                }
              });
            });
          });
          for (let i in list) {
            if (!list[i].created_at) {
              list[i].created_at = "--";
            }
            list[i].index = i * 1;
          }
          this.tableData = list;
        }
      });
    },
    recheck() {
      this.position = "";
      this.check_kind(this.check_act);
    },
  },
};
</script>

<style lang="scss">
.position_control {
  .position_control_all {
    // min-width: 1200px;
    width: 100%;
    // margin: 0 auto;
    // margin-top: -11px;
    border: 1px solid #dedede;
    overflow: hidden;
    height: 800px;
    box-sizing: border-box;
    .position_left {
      width: 20%;
      background: #fff;
      float: left;
      border-right: 1px solid #dedede;
      // min-height: 800px;
      height: 800px;
      overflow: auto;
      box-sizing: border-box;
      .position_title {
        padding: 20px 10px 0px 10px;
        font-size: 14px;
        min-height: 54px;
        color: #333;
        box-sizing: border-box;
        padding-right: 0;

        // border-bottom: 1px solid #ccc;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        .add_kind {
          flex: 1;
          align-items: center;
          justify-content: center;
          display: flex;
          background: #409eff;
          height: 35px;
          color: #fff;
          border-radius: 5px;
        }
        .position_title_sort {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          div {
            display: flex;
            flex-direction: column;
            color: #ccc;
            justify-content: space-around;
            margin-left: 5px;
          }
        }
      }
      .position_item {
        height: 35px;
        padding: 0 10px;
        padding-top: 10px;
        box-sizing: border-box;
        height: 745px;
        overflow: auto;
        padding-right: 0;
        .position_item_info {
          width: 100%;
          border-right: 3px solid #fff;
          display: flex;
          height: 40px;
          align-items: center;
          .type {
            flex: 1;
            padding-left: 10px;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }
          .inputinfo {
            display: block;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            input {
              height: 20px;
              text-align: center;
              width: 50%;
              // padding-left: 10px;
              box-sizing: border-box;
              border: 1px solid #409eff;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none !important;
            }
            input[type="number"] {
              -moz-appearance: textfield;
            }
          }
          .setting {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-around;
            box-sizing: border-box;
            // padding: 0 10px;
            // padding-left: 30px;
            div {
              display: flex;
              width: 60%;
              justify-content: space-around;
              margin: 0 auto;
              i {
                color: #333;
              }
              i:hover {
                color: #409eff;
              }
            }
          }
        }
        .check_act {
          border-right: 3px solid #409eff;
          color: #409eff;
        }
      }
    }
    .position_right {
      // width: 938px;
      width: 80%;
      background: #fff;
      padding: 26px;
      // overflow: auto;
      height: 800px;
      box-sizing: border-box;
      float: left;
      .right_title {
        display: flex;
        height: 50px;
        align-items: center;
        .title_left {
          display: flex;
          color: #666;
          align-items: center;
          height: 100%;
          input {
            width: 379px;
            height: 32px;
            background: #ffffff;
            border: 1px solid #dddddd;
            opacity: 1;
            padding-left: 10px;
            border-radius: 4px;
          }
          .title_serch {
            width: 70px;
            height: 32px;
            background: #409eff;
            opacity: 1;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            margin-left: 10px;
          }
          .title_reload {
            width: 70px;
            height: 32px;
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
            margin-left: 10px;
          }
        }
        .title_right {
          // flex: 1.5;
          // height: 100%;
          align-items: center;
          display: flex;
          flex: 1;
          justify-content: flex-end;
          color: #fff;
          div {
            border-radius: 5px;
            color: #fff;
            align-items: center;
            display: flex;
            padding: 0 10px;
            background: #409eff;
            height: 32px;
          }
        }
      }
      .right_body {
        .position_change {
          display: flex;
          align-items: center;
          div {
            white-space: nowrap;
            margin-left: 10px;
          }
          div:hover {
            color: #409eff;
          }
          i:hover {
            color: #409eff;
          }
        }
      }
      .right_body .el-table {
        height: 707px !important;
      }
    }
  }
  .drawer_compony_info .drawer_nice {
    width: 120px;
  }
}
</style>