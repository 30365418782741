import request from '@/utils/axios.tool';

//角色列表
export const selectRoleList = (data)=>{              
    return request.post(`/roleapi/role/selectRoleList`,data)
}


//权限查询--添加角色的时候用
export const userPermissionsList = (data)=>{              
    return request.post(`/roleapi/role/userPermissionsList`,data)
}

//添加角色
export const saveRole = (data)=>{              
    return request.post(`/roleapi/role/saveRole`,data)
}

//修改的时候查询角色对应的权限
export const selectFirstRole = (data)=>{              
    return request.post(`/roleapi/role/selectFirstRole`,data)
}

//删除角色
export const deleteRole = (data)=>{              
    return request.post(`/roleapi/role/deleteRole`,data)
}

