<template>
  <div class="pk_management" style="flex: 1; width: 100%">
    <el-main>
      <div class="serch_big_box">
        <div class="info">
          <span class="demonstration">赛区：</span>
          <el-select
            @change="changranking"
            v-model="Competition_area_id"
            placeholder="请选择赛区"
          >
            <el-option
              v-for="item in Competition_area"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="info">
          <span class="demonstration">大区：</span>
          <el-cascader
            placeholder="请选择大区"
            :options="options"
            ref="myCascader"
            @change="handleClick"
            :key="isResouceShow"
            clearable
            :show-all-levels="false"
            collapse-tags
            :props="{ multiple: true, checkStrictly: true }"
          ></el-cascader>
        </div>
        <div class="info">
          <span class="demonstration" style="width: 90px">事业线：</span>
          <el-select
            @change="changbusiness"
            v-model="business_line_id"
            placeholder="请选择事业线"
          >
            <el-option
              v-for="item in business_Line_arr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>

        <div class="info" style="width: 20%">
          <div class="serch pointer" @click="serch">查询</div>
          <div class="reset pointer" @click="reset">重置</div>
          <!-- <div class="reset pointer" @click="export_">导出</div> -->
        </div>
      </div>
      <div class="pk_table">
        <el-table
          :data="tableData"
          :show-overflow-tooltip="true"
          style="width: 100%"
          max-height="690"
          border
          :header-cell-style="{ color: '#E7E7E7' }"
          ref="table"
        >
          <el-table-column
            type="index"
            label="排序"
            fixed="left"
            width="50"
            key="0"
          >
          </el-table-column>
          <el-table-column label="赛区" width="150" key="21" fixed="left">
            <template slot-scope="scope">
              <div>
                {{ scope.row.zone_name ? scope.row.zone_name : "--" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="军团 / 人数 / 领导人 / 事业线"
            key="1"
            fixed="left"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.name }}【{{ scope.row.people_num }}】-
                {{ scope.row.leader ? scope.row.leader : "--" }}
              </div>
              <div>
                {{ scope.row.business_line }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="大区 / 分公司 / 事业部"
            key="2"
            fixed="left"
          >
            <template slot-scope="scope">
              <div>
                <div>
                  {{
                    scope.row.company_info["1"].name
                      ? scope.row.company_info["1"].name
                      : "--"
                  }}
                  <div>
                    {{
                      scope.row.company_info["2"].name
                        ? scope.row.company_info["2"].name
                        : "--"
                    }}
                  </div>
                </div>
                <div>
                  {{
                    scope.row.company_info["3"].name
                      ? scope.row.company_info["3"].name
                      : "--"
                  }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="总积分"
            sortable
            width="90"
            key="3"
            :sort-method="(a, b) => sortByDate(a, b, 1)"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.total_integral }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            sortable
            :sort-method="(a, b) => sortByDate(a, b, 2, index)"
            width="180"
            v-for="(item, index) in node_arr"
            :key="(index + 10) * 4"
            :label="item.name + '业绩'"
          >
            <template slot-scope="scope">
              <div style="color: #ff4848">
                {{ scope.row.note_arr[index].achievement }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            sortable
            width="190"
            :sort-method="(a, b) => sortByDate(a, b, 3, index)"
            v-for="(item, index) in node_arr"
            :key="(index + 10) * 5"
            :label="item.name + '完成率'"
          >
            <template slot-scope="scope">
              <div style="color: #faad14">
                {{ scope.row.note_arr[index].completion_rate + "%" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label=" 当前节点业绩积分" width="150" key="30">
            <template slot-scope="scope">
              <div>
                {{ scope.row.this_achievement_integral }}
              </div>
            </template>
          </el-table-column>
              <el-table-column label=" 当前节点完成率积分" width="150" key="31">
            <template slot-scope="scope">
              <div>
                {{ scope.row.this_note_rate_integral  }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="月任务额"
            :sort-method="(a, b) => sortByDate(a, b, 4)"
            sortable
            width="120"
            key="6"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.month_mission }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="本月总业绩"
            width="120"
            :sort-method="(a, b) => sortByDate(a, b, 5)"
            sortable
            key="7"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.month_achievement }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="month_rate"
            label="本月完成率"
            width="120"
            sortable
            :sort-method="(a, b) => sortByDate(a, b, 6)"
            key="8"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.month_rate }} %</div>
            </template>
          </el-table-column>
          <el-table-column label="历史积分" width="120" key="20">
            <template slot-scope="scope">
              <div
                style="color: #3a85fc"
                class="pointer"
                @click="integral(scope.row)"
              >
                查看
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>
  </div>
</template>

<script>
import { selectCompanyTree, businessLine } from "../../api/User/data";
import { pkManage, pkManageExport, getZone } from "../../api/User/PK";
export default {
  data() {
    return {
      tableData: [],
      node_arr: [],
      business_Line_arr: [], //事业线
      business_line_id: "", //所选事业线
      options: [], //部门
      search_company_id: [], //部门id
      isResouceShow: 0,
      Competition_area: [], //赛区
      Competition_area_id: "", //赛区 id
    };
  },
  created() {
    //事业线
    businessLine().then((res) => {
      if (res.data.code == 200) {
        this.business_Line_arr = res.data.data;
      }
    });
    this.getselectCompanyTree({
      depth: 1,
    });
    this.getpkManage({
      depth: 5,
    });
    getZone({
      status: 1,
    }).then((res) => {
      if (res.data.code == 200) {
        this.Competition_area = res.data.data;
      }
    });
  },
  methods: {
    sortByDate(obj1, obj2, i, index) {
      //排序
      var val1 = "";
      var val2 = "";
      if (i == 1) {
        //总积分
        val1 = obj1.total_integral * 1;
        val2 = obj2.total_integral * 1;
      }
      if (i == 2) {
        val1 = obj1.note_arr[index].achievement * 1;
        val2 = obj2.note_arr[index].achievement * 1;
      }
      if (i == 3) {
        //完成率
        val1 = obj1.note_arr[index].completion_rate * 1;
        val2 = obj2.note_arr[index].completion_rate * 1;
      }
      if (i == 4) {
        //月任务额
        val1 = obj1.month_mission * 1;
        val2 = obj2.month_mission * 1;
      }
      if (i == 5) {
        //本月总业绩
        val1 = obj1.month_achievement * 1;
        val2 = obj2.month_achievement * 1;
      }
      if (i == 6) {
        //本月完成率
        val1 = obj1.month_rate * 1;
        val2 = obj2.month_rate * 1;
      }
      return val1 - val2;
    },
    handleClick(value) {
      //选择 删除下级children
      //展开可选择不同层级
      if (value.length == 0) {
        ++this.isResouceShow;
        this.search_company_id = [];
        return;
      }
      let options = JSON.parse(JSON.stringify(this.options));
      function dg(list) {
        list.forEach((item) => {
          if (item.data) {
            item.children = item.data;
            dg(item.data);
          }
        });
        return list;
      }
      dg(options);
      this.options = options;
      if (value.length == 0) {
        ++this.isResouceShow;
        return;
      }
      let check_arr = this.$refs["myCascader"].getCheckedNodes();
      this.search_company_id = [];
      let arr = this.search_company_id;
      function depdg(options, check_arr) {
        for (let i = 0; i < options.length; i++) {
          for (let j = 0; j < check_arr.length; j++) {
            if (options[i].id == check_arr[j].value) {
              if (options[i].data) {
                delete options[i].children;
              }
            } else {
              if (options[i].data) {
                depdg(options[i].data, check_arr);
              }
            }
          }
        }
      }
      depdg(options, check_arr);
      this.options = JSON.parse(JSON.stringify(options));
      check_arr.forEach((item) => {
        arr.push(item.value);
      });
      this.search_company_id = arr;
    },
    getselectCompanyTree(data) {
      selectCompanyTree(data).then((res) => {
        if (res.data.code == 200) {
          function dg(list) {
            list.forEach((item) => {
              item.label = item.name;
              item.value = item.id;
              if (item.data) {
                item.children = item.data;
                dg(item.data);
              }
            });
            return list;
          }
          let list = res.data.data;
          dg(list);
          this.options = list;
        }
      });
    },
    //重置
    reset() {
      ++this.isResouceShow;
      this.Competition_area_id = "";
      this.business_line_id = "";
      this.search_company_id = [];
      this.serch();
    },
    //查询
    serch() {
      this.getpkManage({
        permissions_id_string:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.NzA.szVADV17L1BmEtMYsj7llhCkosRgJb8btqdfC5Xps6E",
        company_id: this.search_company_id.join(","),
        business_line_id: this.business_line_id,
        zone_id: this.Competition_area_id,
        depth: 5,
      });
    },
    export_() {
      //导出
      pkManageExport({
        permissions_id_string:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.NzA.szVADV17L1BmEtMYsj7llhCkosRgJb8btqdfC5Xps6E",
        company_id: this.search_company_id.join(","),
        business_line_id: this.business_line_id,
        zone_id: this.Competition_area_id,
      }).then((res) => {
        if (res.data.code == 200) {
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = res.data.data;
          link.setAttribute("download", "courman" + ".xls");
          document.body.appendChild(link);
          link.click();
        }
      });
    },

    changbusiness(e) {
      this.business_line_id = e;
    },
    changranking(e) {
      this.Competition_area_id = e;
    },
    getpkManage(data) {
      this.tableData = [];
      pkManage(data).then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.data.data;
          this.node_arr = res.data.data.node;
          var list = res.data.data.node;
          for (let i = 0; i < list.length; i++) {
            var end_time_arr = list[i].end_time.split("-");
            var start_time_arr = list[i].start_time.split("-");
            var start_time_str =
              start_time_arr[1] + "月" + start_time_arr[2] + "日";
            var end_time_str = end_time_arr[1] + "月" + end_time_arr[2] + "日";
            list[i].start_time_str = start_time_str;
            list[i].end_time_str = end_time_str;
          }

          this.$nextTick(() => {
            this.$refs.table.doLayout();
          });
        }
      });
    },
    integral(row) {
      // this.$router.push(`/Marketing/management/integral/${row.id}`)
      this.$router.push({
        name: "integral",
        params: {
          row,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.pk_management {
  flex: 1 1 0%;
  padding: 20px;
  background: #eef5f9;
  .el-main {
    background: #fff;
    width: 100%;
    height: 100%;
    .pk_table {
      margin: 0 24px;
      margin-top: 45px;
      position: relative;
      //   height: 610px;
      .el-table th,
      .el-table thead tr {
        height: 44px;
        background: #fafafa;
        font-size: 14px;
        font-weight: bold;
        color: #595959;
      }
      .el-table td,
      .el-table th.is-leaf {
        text-align: center;
      }
      .task {
        font-size: 16px;
        font-weight: 400;
        color: #ffa70e;
      }
      .achi {
        font-size: 16px;
        font-weight: 400;
        color: #ff4e4e;
      }
      .rate {
        font-size: 16px;
        font-weight: 400;
        color: #2dfd32;
      }
      .cell {
        color: #595959;
        font-size: 14px;
      }
      .el-table {
        width: 100%;
        z-index: 1;
      }
    }
    .serch_big_box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-left: 60px;
      .info {
        width: 23%;
        margin-top: 20px;
        margin-left: 2%;
        display: flex;
        align-items: center;
        .demonstration {
          margin-right: 10px;
          min-width: 42px;
          font-size: 14px;
          white-space: nowrap;

          color: #595959;
        }
        .el-cascader {
          height: 32px;
          line-height: 32px;
          flex: 1;
          .el-input {
            height: 32px;
            line-height: 32px;
            input {
              height: 32px !important;
            }
          }
          .el-cascader__tags {
            flex-wrap: nowrap;
          }
        }
        // .el-input {
        //   background: url("../../assets/img/fight_9.png") no-repeat;
        //   background-size: 100% 100%;
        // }
        .el-input__inner {
          height: 36px;
          line-height: 36px;
          border-radius: inherit;
          border-radius: 2px;
        }

        .el-input__icon {
          line-height: 36px;
        }
        .el-tag {
          background: transparent !important;
        }
        .serch {
          width: 70px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
          color: #ffffff;
          background: #3a85fc;
        }
        .reset {
          width: 70px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
          margin-left: 20px;
          border: 1px solid #efefef;
          color: #595959;
        }
      }
      .info:nth-of-type(4n + 1) {
        margin-left: 0;
      }
    }
    .integral_table {
      margin-top: 20px;
    }
  }
}
</style>