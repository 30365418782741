<template>
  <div class="my_team_box">
    <div class="title">我的团队</div>
    <div class="info">
      <div class="info_title">
        <div class="info_one">
          <img src="@/assets/img/human_blue.png" alt="" />
          <div>{{ team_info.leader ? team_info.leader : "--" }}</div>
        </div>
        <div class="info_two">{{ team_info.name ? team_info.name : "--" }}</div>
        <div class="info_three">
          <div v-if="team_info.type == 1">
            <span style="margin-right: 2px">应</span>
            {{ team_info.expect }}
          </div>
          <div>
            <span style="margin-right: 2px">现</span>
            {{ team_info.have_num }}
          </div>
          <div
            :class="[team_info.lack > 0 ? 'red' : '']"
            v-if="team_info.type == 1"
          >
            <span style="margin-right: 2px">缺</span>
            {{ team_info.lack }}
          </div>
        </div>
      </div>
      <img src="@/assets/img/wtf_xia.png" class="big_arrow" alt="" />
      <div class="info_detail">
        <div
          class="info_box"
          v-for="(item, index) in team_info.child"
          :key="index"
        >
          <div class="info_box_compony" v-if="item.is_select == 1">
            <div class="info_box_title">
              <div class="fir">
                <img src="@/assets/img/human_blue.png" alt="" />
                <div>{{ item.name }}</div>
              </div>
              <div class="sec"></div>
            </div>
            <div class="info_box_val">
              <div v-if="item.type == 1">
                <span style="margin-right: 2px">应</span>
                <i>
                  {{ item.expect }}
                </i>
              </div>
              <div>
                <span style="margin-right: 2px">现</span>
                <i>
                  {{ item.have_num }}
                </i>
              </div>
              <div :class="[item.lack > 0 ? 'red' : '']" v-if="item.type == 1">
                <span style="margin-right: 2px">缺</span>
                <i>
                  {{ item.lack }}
                </i>
              </div>
            </div>
          </div>
          <div class="info_box have_human" v-if="item.is_select == 0">
            <img src="@/assets/img/human_blue.png" alt="" />
            {{ item.name }}
          </div>
        </div>
        <div class="info_box nohuman" v-if="team_info.child.length == 0">
          <img src="@/assets/img/human_brown.png" alt="" />
          缺人
        </div>
        <div class="info_box nohuman" v-if="team_info.child.length == 0">
          <img src="@/assets/img/human_brown.png" alt="" />
          缺人
        </div>
        <div class="info_box nohuman" v-if="team_info.child.length == 0">
          <img src="@/assets/img/human_brown.png" alt="" />
          缺人
        </div>
        <div class="info_box nohuman" v-if="team_info.child.length == 1">
          <img src="@/assets/img/human_brown.png" alt="" />
          缺人
        </div>
        <div class="info_box nohuman" v-if="team_info.child.length == 1">
          <img src="@/assets/img/human_brown.png" alt="" />
          缺人
        </div>
        <div class="info_box nohuman" v-if="team_info.child.length == 2">
          <img src="@/assets/img/human_brown.png" alt="" />
          缺人
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { myTeamlist } from "../../api/User/home";
export default {
  data() {
    return {
      team_info: {},
    };
  },
  created() {
    myTeamlist().then((res) => {
      this.team_info = res.data.data;
    });
  },
  methods: {},
};
</script>

<style lang="scss">
.my_team_box {
  .title {
    height: 51px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 26px;
    display: flex;
    font-size: 18px;
    align-items: center;
    font-weight: bold;
    line-height: 31px;
    color: rgba(0, 0, 0, 0.8) !important;
    // justify-content: space-between;
  }
  .info {
    .info_title {
      width: 227px;
      height: 86px;
      background: #ffffff;
      border: 1px solid #2c88ff;
      opacity: 1;
      margin: 11px auto;
      margin-bottom: 8px;
      border-radius: 2px;
      .info_one {
        width: 50%;
        margin: 0 auto;
        display: flex;
        margin-top: 5px;
        align-items: center;
        justify-content: center;
        img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
        div {
          // width: 43px;
          max-width: 90px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color: #2c88ff;
        }
      }
      .info_two {
        color: #2c88ff;
        text-align: center;
        // margin-top: 5px;
      }
      .info_three {
        display: flex;
        justify-content: space-around;
        margin-top: 5px;
        div {
          // white-space: nowrap;
          display: flex;
          align-items: center;
          color: #2c88ff;
        }
        span {
          width: 22px;
          height: 22px;
          text-align: center;
          line-height: 22px;
          // background: #;
          background: #2c88ff;
          display: block;
          color: #fff;
          border-radius: 50%;
        }
        .red {
          color: #ff3f38;
          span {
            background: #ff3f38;
          }
        }
      }
    }
    .big_arrow {
      display: block;
      margin: 0 auto;
      width: 16px;
      height: 13px;
    }

    .info_detail {
      width: 100%;
      overflow: auto;
      border: 1px;
      height: 224px;
      // margin: 0 auto;
      padding: 0 10%;
      margin-top: 5px;
      .info_box {
        // display: inline-block;
        float: left;
        height: 214px;
        width: 30%;
        // border: 1px solid #A7C5FF;
        opacity: 1;
        border-radius: 4px;
        box-sizing: border-box;
        .info_box_compony {
          border: 1px dashed #a7c5ff;
          height: 100%;
          .info_box_title {
            border-bottom: 1px dashed #a7c5ff;
            padding-bottom: 5px;
            .fir {
              width: 70%;
              margin: 0 auto;
              display: flex;
              margin-top: 5px;
              align-items: center;
              justify-content: center;
              img {
                width: 14px;
                height: 14px;
                margin-right: 5px;
              }
              div {
                max-width: 74px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: #2c88ff;
              }
            }
            .sec {
              text-align: center;
              color: #2c88ff;
              padding: 0 10px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              box-sizing: border-box;
              width: 100%;
            }
          }
          .info_box_val {
            padding: 20px 0;
            height: 165px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            div {
              width: 80%;
              margin: 0 auto;
              justify-content: center;
              height: 30px;
              display: flex;
              align-items: center;
              background: #f3f8ff;
              span {
                width: 22px;
                height: 22px;
                text-align: center;
                line-height: 22px;
                // background: #;
                background: #2c88ff;
                display: block;
                color: #fff;
                border-radius: 50%;
                margin-right: 5px;
              }
              i {
                width: 37%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            .red {
              background: #fff9f9;
              color: #ff3f38;
              span {
                background: #ff3f38;
              }
              // color: #fff;
            }
          }
        }
        .have_human {
          background: #f3f8ff;
          float: left;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          border: 0px;
          justify-content: center;
          color: #3a85fc;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .info_box:nth-of-type(1) {
        margin-left: 0;
      }
      .info_box:nth-of-type(n + 1) {
        margin-left: 5%;
      }
      .info_box:nth-of-type(3n + 1) {
        margin-left: 0;
      }
      .info_box:nth-of-type(n + 4) {
        margin-top: 10px;
      }

      .nohuman {
        background: #f9f9f9;
        float: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 0px;
        justify-content: center;
        color: #ccc;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    ::-webkit-scrollbar {
      width: 0px;
    }
  }
}
</style>