<template>
  <div class="Arrival">
    <el-main>
      <div
        class="Arrival_top_check"
        v-if="position != 1"
      >
        <div
          :class="isCheck == 1 ? 'isCheck' : ''"
          @click="handleTab(1)"
        >
          个人
        </div>
        <div
          :class="isCheck == 2 ? 'isCheck' : ''"
          @click="handleTab(2)"
        >
          部门
        </div>
      </div>
      <div class="Arrival_query">
        <div class="Arrival_query_line">
          <div
            class="Arrival_query_line_one"
            style="margin-left: 0px"
          >
            <div class="Arrival_query_line_one_name">
              {{ isCheck == 1 ? "名称：" : "部门：" }}
            </div>
            <el-cascader
              placeholder="请选择部门"
              :options="depOptions"
              @change="changeDep"
              :key="isResouceShow"
              clearable
              :props="{ checkStrictly: true }"
              v-if="isCheck == 2"
            ></el-cascader>
            <el-input
              v-model="company_name"
              placeholder="请输入公司名称"
              v-if="isCheck == 1"
            ></el-input>
          </div>
          <div
            class="Arrival_query_line_one"
            v-if="isCheck == 2"
          >
            <div class="Arrival_query_line_one_name">名称：</div>
            <el-input
              v-model="company_name"
              placeholder="请输入公司名称"
            ></el-input>
          </div>

          <div class="Arrival_query_line_one">
            <div class="Arrival_query_line_one_name">产品类型：</div>
            <el-cascader
              v-model="search_product_id"
              :options="productList"
              placeholder="请选择产品"
              separator=" - "
            >
            </el-cascader>
          </div>
          <div class="Arrival_query_line_one">
            <div class="Arrival_query_line_one_name">到账时间：</div>
            <div class="Arrival_query_line_one_time">
              <el-date-picker
                :editable="iseditable"
                class="date_time"
                prefix-icon="saa"
                clear-icon="ssa"
                style="width: 50%; height: 32px"
                v-model="search_start_time"
                type="date"
                :picker-options="pickerOptions"
                @focus="handleFocus(1)"
                @change="handleTime($event, 1)"
                placeholder="开始时间"
              >
              </el-date-picker>
              <i>-</i>
              <el-date-picker
                :editable="iseditable"
                prefix-icon="saa"
                clear-icon="ssa"
                class="date_time"
                v-model="search_end_time"
                style="width: 50%; height: 32px"
                type="date"
                :picker-options="pickerOptions"
                @focus="handleFocus(2)"
                @change="handleTime($event, 2)"
                placeholder="结束时间"
              >
              </el-date-picker>
            </div>
          </div>
          <div
            class="Arrival_query_line_one"
            v-if="isCheck == 1"
          >
            <div class="Arrival_query_line_one_name">到账金额：</div>
            <div class="Arrival_query_line_one_time Arrival_query_line_one_money">
              <el-input
                v-model="search_start_money"
                placeholder="到账金额"
              ></el-input>
              <i>-</i>
              <el-input
                v-model="search_end_money"
                placeholder="到账金额"
              ></el-input>
            </div>
          </div>
        </div>

        <div class="Arrival_query_line">
          <div
            class="Arrival_query_line_one"
            style="margin-left: 0"
            v-if="isCheck == 2"
          >
            <div class="Arrival_query_line_one_name">到账金额：</div>
            <div class="Arrival_query_line_one_time Arrival_query_line_one_money">
              <el-input
                v-model="search_start_money"
                placeholder="到账金额"
              ></el-input>
              <i>-</i>
              <el-input
                v-model="search_end_money"
                placeholder="到账金额"
              ></el-input>
            </div>
          </div>
          <div
            class="Arrival_query_line_one"
            v-if="isCheck == 2"
          >
            <div
              class="Arrival_query_line_one_name"
              v-if="isCheck == 2"
            >
              提交人：
            </div>
            <el-input
              v-if="isCheck == 2"
              v-model="search_name"
              placeholder="提交人"
              style="flex: 1"
            ></el-input>
          </div>

          <div class="Arrival_query_line_one" style="margin-left: 0px;">
            <div class="Arrival_query_line_one_name">合同单号：</div>
            <el-input
                v-model="contract_number"
                placeholder="请输入合同单号"
            ></el-input>
          </div>

          <div
            class="header_buttom"
            style="margin-left:1%"
          >
            <div
              class="finance_find"
              @click="handleFind(tab_active)"
            >查询</div>
            <div
              class="finance_reset"
              @click="handleReset"
            >重置</div>
          </div>
        </div>
      </div>
      <div class="Arrival_table_tab">
        <div v-if="tab_active == -1 || tab_active == 1">
          到账总金额：{{ to_money_sum }}元
        </div>
        <div v-else></div>
        <div class="Arrival_table_tab_box">
          <div
            @click="handletab_active(-1)"
            :class="tab_active == -1 ? 'tab_active' : ''"
          >
            全部
          </div>
          <div
            @click="handletab_active(0)"
            :class="tab_active == 0 ? 'tab_active' : ''"
          >
            待审核
          </div>
          <div
            @click="handletab_active(1)"
            :class="tab_active == 1 ? 'tab_active' : ''"
          >
            通过
          </div>
          <div
            @click="handletab_active(2)"
            :class="tab_active == 2 ? 'tab_active' : ''"
          >
            驳回
          </div>
          <div
            @click="handletab_active(3)"
            :class="tab_active == 3 ? 'tab_active' : ''"
          >
            撤销
          </div>
        </div>
      </div>
      <div class="export">
        <div @click="exportdata">
          <i class="el-icon-printer"></i>
          导出
        </div>
      </div>
      <div class="Arrival_table">
        <el-table
          :data="tableData"
          border
          :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
          style="width: 100%"
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column label="公司名称">
            <template scope="scope">
              {{ scope.row.customer ? scope.row.customer : "--" }}
            </template>
          </el-table-column>
          <el-table-column label="产品名称">
            <template scope="scope">
              {{ scope.row.product_name }}
            </template>
          </el-table-column>
          <el-table-column label="订单编号">
            <template scope="scope">
              {{ scope.row.order_number }}
            </template>
          </el-table-column>
          <el-table-column label="提交信息">
            <template scope="scope">
              <div>
                <div>
                  <!-- 姓名： -->
                  {{ scope.row.name }}
                </div>
                <div>
                  <!-- 部门： -->
                  {{ scope.row.order_data_branch }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="合同金额（元）">
            <template scope="scope">
              {{ scope.row.contract_money }}
            </template>
          </el-table-column>
          <el-table-column label="到账金额（元）">
            <template scope="scope">
              {{ scope.row.to_money }}
            </template>
          </el-table-column>
          <el-table-column
            label="到账类型"
            width="100"
          >
            <template scope="scope">
              {{ scope.row.type_text }}
            </template>
          </el-table-column>
          <el-table-column label="收款方式">
            <template scope="scope">
              {{ scope.row.collection_type }}
            </template>
          </el-table-column>
          <el-table-column
            label="到账时间"
            prop="to_time"
          > </el-table-column>
          <el-table-column label="状态">
            <template scope="scope">
              <i :class="
                  scope.row.status == 0
                    ? 'wait_status'
                    : scope.row.status == 1
                    ? 'pass_status'
                    : scope.row.status == 2
                    ? 'reject_status'
                    : 'revoke_status'
                "></i>
              {{ scope.row.status_text }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template scope="scope">
              <div
                class="pointer"
                @click="handleDetail(scope.row)"
                style="color: #3a85fc"
              >
                详情
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="10"
            el-pagination
            layout="total,prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
    <div
      class="Arrival_detail"
      v-if="isDetail"
    >
      <div
        class="detail"
        v-if="isDetail"
      >
        <div class="detail_tit">
          <div>
            到账详情
            <i
              class="el-icon-close"
              @click="isDetail = false"
            ></i>
          </div>
        </div>
        <div class="detail_content">
          <div class="order_number">
            订单号：{{ order_detail.order.order_number }}
          </div>
          <div
            class="detail_content_status"
            style="display: flex; justify-content: space-between"
          >
            <div>
              状态：{{
                order_detail.status == 0
                  ? "待审核"
                  : order_detail.status == 1
                  ? "通过"
                  : order_detail.status == 2
                  ? "驳回"
                  : "已撤销"
              }}
            </div>
            <div
              style="
                padding-right: 50px;
                max-width: 500px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              v-if="order_detail.status == 3 || order_detail.status == 2"
            >
              原因：{{ order_detail.reason }}
            </div>
          </div>
          <div class="detail_content_box">
            <div class="detail_content_box_flex">
              <div class="detail_content_box_left">
                <div class="box_left_head">
                  <div class="box_left_head_L"></div>
                  <div class="box_left_head_R">客户信息</div>
                </div>
                <div class="detail_content_box_line">
                  <div class="detail_content_box_line_tit">公司名称：</div>
                  <div class="detail_content_box_line_info">
                    {{
                      order_detail.order.customer
                        ? order_detail.order.customer.name
                        : "--"
                    }}
                  </div>
                </div>
                <div class="detail_content_box_line">
                  <div class="detail_content_box_line_tit">联系人：</div>
                  <div class="detail_content_box_line_info">
                    {{ order_detail.order.my_customer_contacts.contacts }}
                  </div>
                </div>
                <div class="detail_content_box_line">
                  <div class="detail_content_box_line_tit">电话：</div>
                  <div class="detail_content_box_line_info">
                    {{ order_detail.order.my_customer_contacts.phone }}
                  </div>
                </div>
              </div>
              <div class="detail_content_box_left">
                <div class="box_left_head">
                  <div class="box_left_head_L"></div>
                  <div class="box_left_head_R">合同信息</div>
                </div>
                <div class="detail_content_box_line">
                  <div class="detail_content_box_line_tit">合同号：</div>
                  <div class="detail_content_box_line_info">
                    {{ order_detail.order.contract_number }}
                  </div>
                </div>
                <div class="detail_content_box_line">
                  <div class="detail_content_box_line_tit">合同金额：</div>
                  <div class="detail_content_box_line_info">
                    {{ order_detail.order.contract_money }}
                  </div>
                </div>
                <div class="detail_content_box_line">
                  <div class="detail_content_box_line_tit">产品：</div>
                  <div class="detail_content_box_line_info">
                    {{ order_detail.order.product_name.product_name }}
                  </div>
                </div>
                <div class="detail_content_box_line">
                  <div class="detail_content_box_line_tit">本单应收全款金额:</div>
                  <div class="detail_content_box_line_info">
                    {{ order_detail.order.total_amount_receivable }}
                  </div>
                </div>
                 <div class="detail_content_box_line">
                  <div class="detail_content_box_line_tit">服务起止时间：</div>
                  <div class="detail_content_box_line_info">
                    {{ order_detail.order.service_time  }}&nbsp;至&nbsp;{{ order_detail.order.service_time_end}}
                  </div>
                </div>
                <div class="detail_content_box_line">
                  <div class="detail_content_box_line_tit">备注:</div>
                  <div class="detail_content_box_line_info">
                    {{ order_detail.order.remarks=='null'?'无':order_detail.order.remarks }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="detail_content_box">
            <div class="detail_content_box_flex">
              <div class="detail_content_box_left">
                <div class="box_left_head">
                  <div class="box_left_head_L"></div>
                  <div class="box_left_head_R">到账信息</div>
                </div>
                <div class="detail_content_box_line">
                  <div class="detail_content_box_line_tit">本次实收金额：</div>
                  <div class="detail_content_box_line_info">
                    {{ order_detail.to_money }}
                  </div>
                </div>
                <div class="detail_content_box_line">
                  <div class="detail_content_box_line_tit">到账时间：</div>
                  <div class="detail_content_box_line_info">
                    {{ order_detail.to_time }}
                  </div>
                </div>
                <div class="detail_content_box_line">
                  <div class="detail_content_box_line_tit">提交人：</div>
                  <div class="detail_content_box_line_info">
                    {{ order_detail.user.name }}【{{
                      order_detail.order_data_branch_one.companys.name
                    }}】
                  </div>
                </div>
                <div class="detail_content_box_line detail_content_box_line_img">
                  <div
                    class="detail_content_box_line_tit"
                    style="white-space: nowrap"
                  >
                    协议信息：
                  </div>
                  <div class="detail_content_box_line_info">
                    <!-- <img
                      @click="handleImg(item.file_url)"
                      :src="item.file_url"
                      alt=""
                      v-for="(item, index) in order_detail.order.contract_file"
                      :key="index"
                    /> -->
                    <el-image
                      v-for="(item, index) in order_detail.order
                        .contract_file_url"
                      :key="index"
                      style="width: 60px; height: 60px; margin-right: 10px"
                      :src="item"
                      :preview-src-list="order_detail.order.contract_file_url"
                    >
                    </el-image>
                  </div>
                </div>
              </div>
              <div class="detail_content_box_left">
                <div class="box_left_head"></div>
                <div class="detail_content_box_line">
                  <div class="detail_content_box_line_tit">收款方式：</div>
                  <div class="detail_content_box_line_info">
                    {{
                      order_detail.collection_id == 1
                        ? "微信"
                        : order_detail.collection_id == 2
                        ? "支付宝"
                        : order_detail.collection_id == 3
                        ? "银行卡"
                        : order_detail.collection_id == 4
                        ? "对公账户"
                        : order_detail.collection_id == 5
                        ? "收钱吧"
                        : order_detail.collection_id == 6
                        ? "银行私账"
                        : order_detail.collection_id == 7
                        ? "POS机"
                        : "现金"
                    }}
                  </div>
                </div>
                <div class="detail_content_box_line">
                  <div class="detail_content_box_line_tit">到账类型：</div>
                  <div class="detail_content_box_line_info">
                    {{
                      order_detail.type == 1
                        ? "定金"
                        : order_detail.type == 2
                        ? "尾款"
                        : "全款"
                    }}
                  </div>
                </div>
                <div class="detail_content_box_line">
                  <div class="detail_content_box_line_tit">提交人电话：</div>
                  <div class="detail_content_box_line_info">
                    {{ order_detail.user.phone }}
                  </div>
                </div>
                <div class="detail_content_box_line detail_content_box_line_img">
                  <div
                    class="detail_content_box_line_tit"
                    style="white-space: nowrap"
                  >
                    收款凭证：
                  </div>
                  <div class="detail_content_box_line_info">
                    <el-image
                      v-for="(item, index) in order_detail.collection_file_url"
                      :key="index"
                      style="width: 60px; height: 60px; margin-right: 10px"
                      :src="item"
                      :preview-src-list="order_detail.collection_file_url"
                    >
                    </el-image>
                  </div>
                </div>
              </div>
            </div>
            <div class="detail_content_box_noflex">
              <div
                class="detail_content_box_remarks_tit"
                style="white-space: nowrap"
              >
                备注：
              </div>
              <div class="detail_content_box_remarks_info">
                {{ order_detail.remarks == "null" ? "无" : order_detail.remarks }}
              </div>
            </div>
          </div>
          <div class="detail_content_box">
            <div class="detail_content_box_flex">
              <div class="detail_content_box_left">
                <div class="box_left_head">
                  <div class="box_left_head_L"></div>
                  <div class="box_left_head_R">分单信息</div>
                </div>
                <div
                  class="no_body"
                  v-if="order_detail.order_data_branch.length == 0"
                >
                  暂无分单信息
                </div>
                <div
                  class="detail_content_box_line"
                  v-for="(item, index) in order_detail.order_data_branch"
                  :key="index"
                >
                  <div class="detail_content_box_line_tit">分单人：</div>
                  <div class="detail_content_box_line_info">
                    {{ item.user.name }}
                  </div>
                </div>
              </div>
              <div class="detail_content_box_left">
                <div class="box_left_head"></div>
                <div
                  class="detail_content_box_line"
                  v-for="(item, index) in order_detail.order_data_branch"
                  :key="index"
                >
                  <div class="detail_content_box_line_tit">分单金额：</div>
                  <div class="detail_content_box_line_info">
                    {{ item.money }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="detail_content_box">
            <div class="detail_content_box_flex">
              <div class="detail_content_box_left">
                <div class="box_left_head">
                  <div class="box_left_head_L"></div>
                  <div class="box_left_head_R">促销信息</div>
                </div>
                <div class="detail_content_box_line">
                  <div class="detail_content_box_line_tit">促销类型：</div>
                  <div class="detail_content_box_line_info">
                    {{ order_detail.promotion.text }}
                  </div>
                </div>
              </div>
              <div class="detail_content_box_left">
                <div class="box_left_head"></div>
                <div class="detail_content_box_line">
                  <div class="detail_content_box_line_tit">促销成本：</div>
                  <div class="detail_content_box_line_info">
                    {{ order_detail.promotion.money }}
                  </div>
                </div>
              </div>
            </div>
            <div class="detail_content_box_noflex">
              <div class="detail_content_box_remarks_tit">申请说明：</div>
              <div class="detail_content_box_remarks_info">
                {{
                  order_detail.promotion.apply_explain
                    ? order_detail.promotion.apply_explain
                    : "----"
                }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="detail_old"
          :style="
            order_detail.status == 1
              ? 'margin-bottom:80px;'
              : order_detail.status == 0
              ? 'margin-bottom:80px;'
              : 'margin-bottom:0px;'
          "
        >
          <div class="detail_old_tit">
            <div style="display: flex; align-items: center">
              <div class="circular"></div>
              <div>历史到账信息</div>
            </div>
            <div>
              总金额：<span style="color: #3a85fc; font-weight: bold">￥{{ order_detail.history_money_all }}</span>
            </div>
          </div>
          <div class="old_line_box">
            <div
              class="orde_history"
              v-if="order_detail.history.length == 0"
            >
              暂无历史到账信息
            </div>
            <div
              class="old_line_header"
              v-if="order_detail.history.length > 0"
            >
              <div
                v-for="(item, index) in oldline"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
            <div
              class="old_line_info"
              v-for="(item, index) in order_detail.history"
              :key="index"
            >
              <!-- <div>{{item.order.order_number}}</div> -->
              <div>
                {{ item.type == 1 ? "定金" : item.type == 2 ? "尾款" : "全款" }}
              </div>
              <div>{{ item.to_money }}</div>
              <div>
                {{
                  item.collection_id == 1
                    ? "微信"
                    : item.collection_id == 2
                    ? "支付宝"
                    : item.collection_id == 3
                    ? "银行卡"
                    : item.collection_id == 4
                    ? "对公账户"
                    : item.collection_id == 5
                    ? "收钱吧"
                    : item.collection_id == 6
                    ? "银行私账"
                    : item.collection_id == 7
                    ? "POS机"
                    : "现金"
                }}
              </div>
              <div>{{ item.to_time }}</div>
              <div>
                <div
                  class="fendan"
                  v-for="itm in item.order_data_branch"
                  :key="itm.name"
                >
                  {{ itm.user.name }}
                </div>
              </div>
              <div>
                <div
                  class="fendan"
                  v-for="(attr, ix) in item.order_data_branch"
                  :key="ix"
                >
                  {{ attr.money == "----" ? "----" : "￥" + attr.money }}
                </div>
              </div>
              <div>{{ order_detail.promotion.text }}</div>
              <div>{{ order_detail.promotion.money }}</div>
              <div>
                {{
                  order_detail.status == 0
                    ? "待审核"
                    : order_detail.status == 1
                    ? "通过"
                    : order_detail.status == 2
                    ? "驳回"
                    : "撤销中"
                }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="detail_content_Button"
          v-if="order_detail.status == 2"
        >
          <div
            @click="handlereject(1)"
            v-if="isCheck == 1"
          >同意驳回</div>
          <div
            @click="handleChange"
            v-if="isCheck == 1"
          >修改</div>
        </div>
        <div
          class="detail_content_Button"
          v-if="order_detail.status == 3 && isCheck == 1"
        >
          <div
            @click="handlereject(1)"
            v-if="isCheck == 1"
          >同意撤销</div>
          <div
            @click="handlereject(2)"
            v-if="isCheck == 1"
          >不同意撤销</div>
        </div>
      </div>
    </div>
    <div
      class="img_show_box"
      v-if="isImg"
      @click="
        isImg = false;
        isDetail = true;
      "
    >
      <img
        :src="img_src"
        alt=""
      />
    </div>
    <div
      class="add_Arrival_box"
      style="background: rgba(0, 0, 0, 0.5)"
      v-if="is_Addarrival"
    >
      <div class="add_Arrival">
        <!-- 新增到账 -->
        <div class="add_Arrival_head">
          修改到账
          <i
            class="pointer el-icon-close"
            @click="onBlack"
          ></i>
        </div>
        <div class="add_Arrival_box_info">
          <div class="add_Arrival_head_tit"><i></i>公司信息</div>
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">公司名称：</div>
            <div class="add_Arrival_line_inp">
              {{ order_detail.order.customer.name }}
            </div>
          </div>
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">客户姓名：</div>
            <div class="add_Arrival_line_inp">
              {{ order_detail.order.customer.contacts }}
            </div>
          </div>
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">产品名称：</div>
            <el-select
              v-model="pro_id"
              placeholder="请选择产品"
            >
              <el-option
                v-for="item in prooption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="add_Arrival_head_tit"><i></i>到账信息</div>
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">本次实收金额：</div>
            <el-input
              type="text"
              v-model="Arrival_money"
              placeholder="请输入到账金额"
            />
          </div>
          <!--<div class="add_Arrival_line">-->
            <!--<div class="add_Arrival_line_tit">到账类型：</div>-->
            <!--<el-select-->
              <!--v-model="Arrival_type"-->
              <!--placeholder="请选择到账类型"-->
            <!--&gt;-->
              <!--<el-option-->
                <!--v-for="item in Arrivaloption"-->
                <!--:key="item.value"-->
                <!--:label="item.label"-->
                <!--:value="item.value"-->
              <!--&gt;-->
              <!--</el-option>-->
            <!--</el-select>-->
          <!--</div>-->
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">收款方式：</div>
            <el-select
              v-model="collection"
              placeholder="请选择收款方式"
            >
              <el-option
                v-for="item in collectionList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">到账时间：</div>
            <el-date-picker
              v-model="time"
              type="datetime"
              prefix-icon="el-icon-date"
              clear-icon="sss"
              placeholder="请选择到账时间"
            >
            </el-date-picker>
          </div>
          <div
                  class="add_Arrival_line"
                  style="align-items: flex-start; height: 110px"
          >
            <div class="add_Arrival_line_tit">收款凭证：</div>
            <div class="contract_img">
              <div class="flow_kind_text">
                <div
                        class="flow_kind_text_img"
                        v-for="(item, index) in collection_file_list_arr"
                        :key="index"
                >
                  <el-image
                          style="width: 100px; height: 100px; border-radius: 4px"
                          :src="item"
                          :preview-src-list="collection_file_list_arr"
                  >
                  </el-image>
                  <i
                          class="el-icon-circle-close"
                          @click="handleDel_img(index,2)"
                          style="color: #ccc"
                  ></i>
                </div>
                <div
                        class="image_info"
                        @click="chooseFile(2)"
                        v-if="collection_file_list.length < 3"
                >
                  <img
                          src="@/assets/img/upload_img.png"
                          alt=""
                  />
                  <span>上传相关图片</span>
                  <input
                          type="file"
                          style="display: none"
                          ref="choosePhoto"
                          @change="photoChange($event)"
                          id="upload_file"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
                  class="add_Arrival_line"
                  style="align-items: flex-start; height: 110px"
          >
            <div class="add_Arrival_line_tit">备注：</div>
            <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="请输入内容"
                    v-model="remarks"
            >
            </el-input>
          </div>
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">是否促销：</div>
            <div class="add_Arrival_line_check">
              <el-radio
                      v-model="radio_id"
                      label="1"
                      @change="handleChangePro"
              >否</el-radio>
              <el-radio
                      v-model="radio_id"
                      label="2"
                      @change="handleChangePro"
              >是</el-radio>
            </div>
          </div>
          <div
                  class="add_Arrival_line"
                  v-if="radio_id == 2"
          >
            <div class="add_Arrival_line_tit">促销类型：</div>
            <el-input
                    type="text"
                    disabled
                    placeholder="暂无促销"
                    v-if="PromotionList.length == 0"
            />
            <el-select
                    v-model="Promotion_id"
                    placeholder="请选择促销类型"
                    v-else
            >
              <el-option
                      v-for="(item, index) in PromotionList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div v-if="is_only_order_data==1">
            <div class="add_Arrival_head_tit"><i></i>合同信息</div>
            <div class="add_Arrival_line">
              <div class="add_Arrival_line_tit">合同金额：</div>
              <el-input
                      type="text"
                      v-model="contract_money"
                      placeholder="请输入合同金额"
              />
            </div>
            <div class="add_Arrival_line">
              <div class="add_Arrival_line_tit">合同单号：</div>
              <el-input
                      type="text"
                      v-model="contract_number"
                      placeholder="请输入合同单号"
              />
            </div>
            <div class="add_Arrival_line" style="align-items: flex-start; height: 110px">
              <div class="add_Arrival_line_tit">合同：</div>
              <div class="contract_img">
                <div class="flow_kind_text">
                  <div
                          class="flow_kind_text_img"
                          v-for="(item, index) in contract_file_list_arr"
                          :key="index"
                  >
                    <el-image
                            style="width: 100px; height: 100px; border-radius: 4px"
                            :src="item"
                            :preview-src-list="contract_file_list_arr"
                    >
                    </el-image>
                    <i
                            class="el-icon-circle-close"
                            @click="handleDel_img(index, 1)"
                            style="color: #ccc"
                    ></i>
                  </div>
                  <div
                          class="image_info"
                          @click="chooseFile(1)"
                          v-if="contract_file_list.length < 3"
                  >
                    <img src="@/assets/img/upload_img.png" alt="" />
                    <span>上传相关图片</span>
                    <input
                            type="file"
                            style="display: none"
                            ref="choosePhoto"
                            @change="photoChange($event)"
                            v-if="upload_img"
                            id="upload_file"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row-item-tit"> <span class="star">*</span>本单应收全款金额：</div>
            <div class="row-item-ipt">
              <el-input type="text" v-model="total_amount_receivable" placeholder="请输入金额(元)"/>
            </div>
            <div class="row-item-tit"> <span class="star">*</span> 服务起止时间：</div>
            <div class="row-item-ipt">
              <el-date-picker
                      v-model="service_time"
                      type="date"
                      value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd"
                      placeholder="选择日期">
              </el-date-picker>
              <span class="mg-lr10">至</span>
              <el-date-picker
                      v-model="service_time_end"
                      type="date"
                      value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd"
                      placeholder="选择日期">
              </el-date-picker>

            </div>
            <div
                    class="add_Arrival_line"
                    style="align-items: flex-start; height: 110px"
            >
              <div class="add_Arrival_line_tit">备注：</div>
              <el-input
                      type="textarea"
                      :rows="3"
                      placeholder="请输入内容"
                      v-model="order_remarks"
              >
              </el-input>
            </div>
          </div>


          <div class="add_Arrival_head_tit"><i></i>分单信息</div>
          <div
            v-for="(item, index) in contract_fd_list"
            :key="index"
          >
            <div class="add_Arrival_line">
              <div class="add_Arrival_line_tit">分单人：</div>
              <el-autocomplete
                class="inline-input"
                v-model="item.user_name"
                :fetch-suggestions="querySearch"
                placeholder="请输入姓名"
                :trigger-on-focus="false"
                @select="handleSelect"
              ></el-autocomplete>
              <i
                class="el-icon-remove-outline pointer"
                style="font-size: 30px; color: #ccc; margin-left: 20px"
                @click="handleDel_fendan(index)"
              ></i>
            </div>
            <div
              class="add_Arrival_line"
              style="padding-right: 160px"
            >
              <div class="add_Arrival_line_tit">分单金额：</div>
              <el-input
                type="text"
                v-model="item.to_money"
                placeholder="请输入金额"
              />
            </div>
          </div>
          <div
            class="add_Arrival_line_add pointer"
            @click="handleAdd_fendan"
          >
            <i class="el-icon-plus"></i>
            新增分单人
          </div>
          <div class="add_Arrival_bottom">
            <div @click="
                isMc = false;
                is_Addarrival = false;
              ">
              取消
            </div>
            <div @click="handleOK">确认</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  orderUserList,
  PromotionList,
  getUserData,
  updateOrder,
  addOrderMore,
  performanceCenterExport,
  searchCompanysList,
} from "../../api/User/customer";
import { uploadFile } from "../../api/User/index";
import { collectionList } from "../../api/User/finance";
import { productList } from "../../api/User/finance";
import {
  accountList,
    detail,
  accountListMoney,
  updateRevoke,
} from "../../api/User/achi";
export default {
  data() {
    return {
        is_first:false,
      isCheck: "1",
      company_name: "", //公司名称
      search_start_time: "", //开始时间
      search_end_time: "", //结束时间
      iseditable: false,
      search_start_money: "", //到账金额
      search_end_money: "", //到账金额
      contract_number: "", //合同单号
      search_name: "", //提交人
      pickerOptions: {},
      tableData: [],
      total: 0,
      currentPage: 1, //页码
      isDetail: false, //详情
      oldline: [
        "到账类型",
        "到账金额",
        "收款方式",
        "到账时间",
        "分单人",
        "分单金额",
        "促销类型",
        "促销成本",
        "审核状态",
      ],
      isImg: false,
      order_detail: {},
      productList: [], //产品列表
      search_product_id: [],
      depOptions: [], //筛选部门
      search_company_id: "",
      isResouceShow: 0,
      to_money_sum: 0, //总金额
      promotion: [
        { value: "1", label: "实物" },
        { value: "2", label: "返现" },
        { value: "3", label: "打折" },
        { value: "4", label: "赠送服务时长" },
        { value: "5", label: "赠送其它服务" },
      ], //促销类型
      img_src: "",
      tab_active: -1, //状态切换
      position: "",
      //修改 (再次到账)
      is_Addarrival: false,
      prooption: [],
      pro_id: "", //到账产品
      Arrival_money: "", //到账金额
      Arrivaloption: [
        { value: "1", label: "定金" },
        { value: "2", label: "尾款" },
        { value: "3", label: "全款" },
      ],
      Arrival_type: "", //到账类型
      collectionList: [], //收款方式列表
      collection: "", //收款方式
      time: "", //到账时间
      contract_money:'',              //合同金额
      radio_id: "1", //是否促销
      PromotionList: [], //促销列表
      Promotion_id: "", //选择促销id
      contract_fd_list: [
        {
          user_id: "",
          user_name: "",
          to_money: "",
        },
      ],
        contract_file_list: [], //合同文件
        contract_file_list_arr: [], //合同文件放大
        collection_file_list: [], //收款文件
        collection_file_list_arr: [], //收款文件放大
      fluterArr: [], //分单搜索人
      // chooseFile_what:'',                 //1 合同  2 收款凭证
      remarks: "", //备注
        order_remarks:'',
      jurisdiction: {
        achievement_list: false,
        achievement_list_str: "",
        achievement_detail: false,
        achievement_detail_str: "",
      },
        is_only_order_data:'',//驳回第一次无数据
        total_amount_receivable:'',
        service_time:'',
        service_time_end:'',
        upload_img: true,
        chooseFile_what: "", //1 合同  2 收款凭证
    };
  },
  created() {
    let token = JSON.parse(localStorage.getItem("token"));
    this.position = token.position_type;
    let rolelist = token.role;
    rolelist.forEach((item) => {
      if (item.permissions == "achievement_list") {
        this.jurisdiction.achievement_list = true;
        this.jurisdiction.achievement_list_str = item.permissions_id_string;
      }
      if (item.permissions == "achievement_detail") {
        this.jurisdiction.achievement_detail = true;
        this.jurisdiction.achievement_detail_str = item.permissions_id_string;
      }
    });
    // console.log(this.jurisdiction);
    let is_team = "";
    if (localStorage.getItem("is_Check_achi")) {
      this.isCheck = localStorage.getItem("is_Check_achi");
      is_team = localStorage.getItem("is_Check_achi");
    } else {
      is_team = 1;
      this.isCheck = 1;
    }
    // let tab_active = ''
    // if (localStorage.getItem("tab_active")) {
    //   this.tab_active = localStorage.getItem("tab_active");
    //   tab_active = localStorage.getItem("tab_active");
    // } else {
    //   tab_active = -1;
    //   this.tab_active = -1;
    // }
    productList().then((res) => {
      //搜索产品列表
      if (res.data.code == 200) {
        let list = res.data.data;
        for (let i = 0; i < list.length; i++) {
          list[i].label = list[i].class_name;
          list[i].value = list[i].id;
          list[i].children = list[i].product_name;
          for (let j = 0; j < list[i].product_name.length; j++) {
            list[i].product_name[j].label =
              list[i].product_name[j].product_name;
            list[i].product_name[j].value = list[i].product_name[j].id;
          }
        }
        this.productList = list;
      }
    });
    this.getaccountListMoney({ is_team });
    this.getaccountList({
      is_team,
      permissions_id_string: this.jurisdiction.achievement_list_str,
    });
    if (this.isCheck == 2) {
      searchCompanysList().then((res) => {
        if (res.data.code == 200) {
          function dg(list) {
            list.forEach((item) => {
              item.label = item.name;
              item.value = item.id;
              if (item.data) {
                item.children = item.data;
                dg(item.data);
              }
            });
            return list;
          }
          let list = res.data.data;
          dg(list);
          this.depOptions = list;
        }
      });
    }
  },
  methods: {
    exportdata() {
      //导出
      let {
        search_end_money,
        search_start_money,
        search_end_time,
        search_start_time,
        company_name,
        search_product_id,
        isCheck,
        search_company_id,
        search_name,
        tab_active,
      } = this;
      //       if (search_start_money*1<=0 ||search_end_money * 1<=0 ) {
      //     this.$message({
      //     message: "金额输入有误",
      //     type: "error",
      //     center: true,
      //   });
      //   return;
      // }
      // if (isNaN(search_end_money * 1) || isNaN(search_start_money * 1)) {
      //   this.$message({
      //     message: "金额输入有误",
      //     type: "error",
      //     center: true,
      //   });
      //   return;
      // }
      // if (search_end_money != "" && search_start_money !== "") {
      //   if (Number(search_end_money) < Number(search_start_money)) {
      //     this.$message({
      //       message: "结束金额不能小于开始金额",
      //       type: "error",
      //       center: true,
      //     });
      //     return;
      //   }
      // }
      let status = "";
      if (tab_active == -1) {
        //不需要状态
        status = "";
      } else {
        status = tab_active;
      }
      let data = {
        is_team: isCheck,
        customer_name: company_name,
        product_name_id: search_product_id[1],
        to_time: search_start_time.split(" ")[0],
        end_to_time: search_end_time.split(" ")[0],
        to_money: search_start_money,
        max_to_money: search_end_money,
        user_name: search_name,
        company_id: search_company_id,
        status,
        permissions_id_string: this.jurisdiction.achievement_list_str,
      };
      performanceCenterExport(data).then((res) => {
        if (res.data.code == 200) {
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = res.data.data.url;
          link.setAttribute("download", "courman" + ".xls");
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    getaccountList(data) {
      accountList(data).then((res) => {
        if (res.data.code == 200) {
          let list = res.data.data.data;
          list.forEach((item) => {
            if (item.status == 0) {
              item.status_text = "待审核";
            }
            if (item.status == 1) {
              item.status_text = "通过";
            }
            if (item.status == 2) {
              item.status_text = "驳回";
            }
            if (item.status == 3) {
              item.status_text = "撤销中";
            }
            if (item.type == 1) {
              item.type_text = "定金";
            }
            if (item.type == 2) {
              item.type_text = "尾款";
            }
            if (item.type == 3) {
              item.type_text = "全款";
            }
          });
          this.total = res.data.data.total;
          this.tableData = list;
        }
      });
    },
    getaccountListMoney(data) {
      accountListMoney(data).then((res) => {
        if (res.data.code == 200) {
          this.to_money_sum = res.data.data.to_money_sum;
        }
      });
    },
    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // let strDate = Y + M + D;
      let strDate = Y + M + D + h + m + "00";
      return strDate;
    },

    handleTab(i) {
      this.search_product_id = [];
      this.search_end_money = "";
      this.search_start_money = "";
      this.search_end_time = "";
      this.search_start_time = "";
      this.search_company_id = "";
      this.search_name = "";
      this.tab_active = -1;
      this.company_name = "";
      this.isCheck = i;
      localStorage.setItem("is_Check_achi", i);
      this.getaccountList({
        is_team: i,
        permissions_id_string: this.jurisdiction.achievement_list_str,
      });
      this.getaccountListMoney({ is_team: i });
      if (i == 2) {
        searchCompanysList().then((res) => {
          if (res.data.code == 200) {
            function dg(list) {
              list.forEach((item) => {
                item.label = item.name;
                item.value = item.id;
                if (item.data) {
                  item.children = item.data;
                  dg(item.data);
                }
              });
              return list;
            }
            let list = res.data.data;
            dg(list);
            this.depOptions = list;
          }
        });
      }
    },
    handletab_active(i) {
      this.tab_active = i;
      // localStorage.setItem("tab_active", i);
      this.handleFind(i);
    },
    handleFocus(i) {
      //时间选择器事件
      this.pickerOptions = {};
      if (i == 1) {
        if (this.search_end_time == "") {
          this.pickerOptions.disabledDate = (time) =>
            time.getTime() > Date.now() - 8.64e6;
        } else {
          let endtime_s = new Date(this.search_end_time);
          this.pickerOptions.disabledDate = (time) =>
            time.getTime() > Date.parse(endtime_s) - 8.64e6;
        }
      }
      if (i == 2) {
        if (this.search_start_time == "") {
          this.pickerOptions.disabledDate = (time) =>
            time.getTime() > Date.now() - 8.64e6;
        } else {
          let starttime_s = new Date(this.search_start_time);
          this.pickerOptions.disabledDate = (time) =>
            time.getTime() < Date.parse(starttime_s) ||
            time.getTime() > Date.now() - 8.64e6;
        }
      }
      if (i == 3) {
        this.pickerOptions.disabledDate = (time) =>
          time.getTime() > Date.now() - 8.64e6;
      }
    },
    handleTime(value, i) {
      //时间
      // i   1  开始时间  2 结束时间
      if (i == 1) {
        this.search_start_time = this.getwaytime(value);
      }
      if (i == 2) {
        this.search_end_time = this.getwaytime(value);
      }
      if (i == 3) {
        //编辑修改时间
        this.order_to_time = this.getwaytime(value);
      }
    },
    handleFind(i, page) {
      let {
        search_end_money,
        search_start_money,
        search_end_time,
        search_start_time,
        company_name,
        search_product_id,
        isCheck,
        search_company_id,
        search_name,
        contract_number,
      } = this;
      // if (search_start_money*1<=0 ||search_end_money * 1<=0 ) {
      //     this.$message({
      //     message: "金额输入有误",
      //     type: "error",
      //     center: true,
      //   });
      //   return;
      // }
      // if (isNaN(search_end_money * 1) || isNaN(search_start_money * 1)) {
      //   this.$message({
      //     message: "金额输入有误",
      //     type: "error",
      //     center: true,
      //   });
      //   return;
      // }
      // if (search_end_money != "" && search_start_money !== "") {
      //   if (Number(search_end_money) < Number(search_start_money)) {
      //     this.$message({
      //       message: "结束金额不能小于开始金额",
      //       type: "error",
      //       center: true,
      //     });
      //     return;
      //   }
      // }
      let status = "";
      if (i == -1) {
        //不需要状态
        status = "";
      } else {
        status = i;
      }
      let p = "";
      if (page) {
        p = page;
      } else {
        p = 1;
      }
      let data = {
        is_team: isCheck,
        contract_number: contract_number,
        customer_name: company_name,
        product_name_id: search_product_id[1],
        to_time: search_start_time.split(" ")[0],
        end_to_time: search_end_time.split(" ")[0],
        to_money: search_start_money,
        max_to_money: search_end_money,
        user_name: search_name,
        company_id: search_company_id,
        status,
        page: p,
        permissions_id_string: this.jurisdiction.achievement_list_str,
      };
      this.getaccountListMoney(data);
      this.getaccountList(data);
    },
    handleReset() {
      ++this.isResouceShow;
      this.search_product_id = [];
      this.search_end_money = "";
      this.search_start_money = "";
      this.search_end_time = "";
      this.search_start_time = "";
      this.contract_number = "";
      this.search_company_id = "";
      this.search_name = "";
      // this.tab_active =  -1
      this.company_name = "";
      this.handleFind(this.tab_active);
    },
    changeDep(value) {
      //部门筛选
      if (value.length == 0) {
        ++this.isResouceShow;
        return;
      }
      if (value.length > 0) {
        this.search_company_id = value[value.length - 1];
      } else {
        this.search_company_id = "";
      }
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.handleFind(this.tab_active, val);
    },
    handleDetail(row) {
      detail({
        order_data_id: row.order_data_id,
        permissions_id_string: this.jurisdiction.achievement_detail_str,
      }).then((res) => {
        if (res.data.code == 200) {
          if (res.data.data.data.promotion) {
            this.promotion.forEach((item) => {
              if (item.value == res.data.data.data.promotion.type) {
                res.data.data.data.promotion.text = item.label;
              }
            });
          } else {
            res.data.data.data.promotion = {
              text: "----",
              money: "----",
            };
          }
          let historyList = res.data.data.data.history;
          let num = 0;
          if (historyList.length > 0) {
            historyList.forEach((itm, idx) => {
              if (itm.order_data_branch.length == 0) {
                //历史到账记录无分单记录
                // console.log('历史到账记录无分单记录',itm.order_data_branch);
                // let obj = {
                //     money:'暂无分单信息',
                //     user:{
                //         name:'暂无分单人'
                //     }
                // }
                // itm.order_data_branch.push(obj)
              } else {
                //历史到账有分单记录
                itm.order_data_branch.forEach((attr, i) => {
                  if (itm.user_id == attr.user_id) {
                    itm.order_data_branch.splice(i, 1);
                  }
                });
                if (itm.order_data_branch == 0) {
                  let obj = {
                    money: "----",
                    user: {
                      name: "----",
                    },
                  };
                  itm.order_data_branch.push(obj);
                }
              }
              num += Number(itm.to_money);
            });
          }
          res.data.data.data.history_money_all = num;
          let detail = res.data.data.data;
          this.is_only_order_data=detail.is_only_order_data
          console.log(detail);
          if (detail.order.contract_file == "[]") {
            detail.order.contract_file = JSON.parse(detail.order.contract_file);
          }
          if (detail.collection_file == "[]") {
            detail.collection_file = JSON.parse(detail.collection_file);
          }
          let collection_file_url = [];
          let contract_file_url = [];
          if (detail.collection_file.length > 0) {
            detail.collection_file.forEach((item) => {
              collection_file_url.push(item.file_url);
            });
            detail.collection_file_url = collection_file_url;
          } else {
            detail.collection_file_url = collection_file_url;
          }
          detail.order.contract_file.forEach((item) => {
            contract_file_url.push(item.file_url);
          });
          detail.order.contract_file_url = contract_file_url
          this.service_time=detail.order.service_time;
          this.service_time_end=detail.order.service_time;
          this.total_amount_receivable=detail.order.total_amount_receivable;
          this.contract_money=detail.order.contract_money;
          this.contract_number=detail.order.contract_number;
          this.order_detail = detail;
          this.isDetail = true;
        }
        if (res.data.code == 400) {
          this.$message({
            message: res.data.message,
            type: "error",
            center: true,
          });
        }
      });
    },
    handlereject(i) {
      //同意驳回撤销 及不同意撤销  1  yes 2no
      let { order_detail } = this;
      updateRevoke({
        order_data_id: order_detail.id,
        type: i,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.isDetail = false;
          this.handleFind(this.tab_active);
        }
      });
    },
    handleImg(src) {
      this.img_src = src;
      this.isDetail = false;
      this.isImg = true;
    },

    handleChange() {
        console.log('ddddddddddddd')
      this.isDetail = false;
      this.is_Addarrival = true;
      let { order_detail } = this;
      console.log(order_detail);
      this.prooption = [];
      // this.order_id = order_detail.order.product_name.id;
      let obj = {
        label: order_detail.order.product_name.product_name,
        value: order_detail.order.product_name.id,
      };
      this.pro_id = order_detail.order.product_name.id;
      this.prooption.push(obj);
      collectionList().then((res) => {
        //收款方式
        if (res.data.code == 200) {
          this.collectionList = res.data.data;
        }
      });

      this.Arrival_money = order_detail.to_money;
      this.Arrival_type = String(order_detail.type);
      this.collection = order_detail.collection_id;
      this.time = order_detail.to_time;
      console.log(order_detail.collection_file);

      order_detail.collection_file.forEach((item) => {
        item.url = item.file_url.split("com")[1];
      });
      this.collection_file_list = order_detail.collection_file;
        order_detail.collection_file.forEach(item=>{
        this.collection_file_list_arr.push(item.file_url);
      })

        order_detail.order.contract_file.forEach((item) => {
            item.url = item.file_url.split("com")[1];
        });
        this.contract_file_list = order_detail.order.contract_file;
        order_detail.order.contract_file.forEach(item=>{
            this.contract_file_list_arr.push(item.file_url);
        })

      this.remarks = order_detail.remarks;
        this.order_remarks=order_detail.order.remarks;
    },
    // chooseFile: function () {
    //   this.$refs.choosePhoto./**/dispatchEvent(new MouseEvent("click"));
    //   this.upload_img = false;
    //   setTimeout(() => {
    //     this.upload_img = true;
    //   }, 10);
    // },
      chooseFile: function (i) {
          this.chooseFile_what = i;
          console.log(i)
          this.$refs.choosePhoto.addEventListener('change',this.photoChange);
this.$refs.choosePhoto.dispatchEvent(new MouseEvent("click"));
          this.upload_img = false;
          setTimeout(() => {
              this.upload_img = true;
          }, 10);
      },
    handleChangePro(i) {
      //获取促销
      if (i == 2) {
        this.PromotionList = [];
        this.Promotion_id = "";
        let { order_detail } = this;
        let data = {
          my_customer_id: order_detail.order.my_customer_id,
          customer_id: order_detail.order.customer_id,
          product_name_id: order_detail.order.product_name_id,
          status: 5,
        };
        let cx_arr = [
          { value: 1, label: "实物" },
          { value: 2, label: "返现" },
          { value: 3, label: "打折" },
          { value: 4, label: "赠送服务时长" },
          { value: 5, label: "赠送其它服务" },
          { value: 6, label: "其他" },
        ];
        PromotionList(data).then((res) => {
          if (res.data.code == 200) {
            let list = res.data.data.data;
            list.forEach((item) => {
              cx_arr.forEach((itm) => {
                if (item.type == itm.value) {
                  item.name = itm.label;
                }
              });
            });
            this.PromotionList = list;
          }
        });
      }
    },
    querySearch(queryString, cb) {
        getUserData({ user_name: queryString }).then((res) => {
            let list = res.data.data.data;
            list.forEach((item) => {
                item.value = item.user.name + " 【" + item.company.name + "】";
            });
            this.fluterArr = list;
            var restaurants = this.loadAll();
            var results = queryString
                ? restaurants.filter(this.createFilter(queryString))
                : restaurants;
            // // 调用 callback 返回建议列表的数据
            cb(results);
        });
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    loadAll() {
      return this.fluterArr;
    },
    handleSelect(val) {
      let { contract_fd_list } = this;
      contract_fd_list.forEach((item) => {
        if (item.user_name == val.value) {
          item.user_id = val.user_id;
        }
      });
    },
    handleAdd_fendan() {
      //新增分单人
      let bool = false;
      let { contract_fd_list } = this;
      contract_fd_list.forEach((item) => {
        if (item.user_name == "" || item.to_money == "") {
          this.$message({
            message: "请完善分单信息",
            type: "error",
          });
          bool = true;
        }
      });
      if (!bool) {
        let obj = {
          user_id: "",
          user_name: "",
          to_money: "",
        };
        contract_fd_list.push(obj);
      }
    },
    handleDel_fendan(val) {
      //删除分单人
      let { contract_fd_list } = this;
      if (contract_fd_list.length < 2) {
        return;
      }
      contract_fd_list.splice(val, 1);
    },
    photoChange(el) {
      var file = el.target.files[0]; //name: "dangqi1.png" || type: "image/png"
      var type = file.type.split("/")[0];
      if (type === "image") {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        var that = this;
        reader.onloadend = function () {
          var dataURL = reader.result;
          var blob = that.dataURItoBlob(dataURL);
          that.upload(blob); //执行上传接口
        };
      } else {
        this.$message.error("请上传图片哦~~");
      }
    },
    dataURItoBlob(dataURI) {
      // base64 解码
      let byteString = window.atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let T = mimeString.split("/")[1];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    // upload(imgUrl) {
    //   let formData = new FormData();
    //   this.$message({
    //     message: "上传中，请稍后",
    //   });
    //   formData.append("file", imgUrl);
    //   uploadFile(formData).then((res) => {
    //     if (res.data.code == 200) {
    //       this.collection_file_list.push(res.data.data);
    //       this.collection_file_list_arr.push(res.data.data.file_url);
    //
    //       this.$message({
    //         message: "上传成功",
    //         type: "success",
    //       });
    //     }
    //   });
    // },
      upload(imgUrl) {
          let { chooseFile_what } = this;
          let formData = new FormData();
          this.$message({
              message: "上传中，请稍后",
          });
          formData.append("file", imgUrl);
          uploadFile(formData).then((res) => {
              console.log(chooseFile_what)
              if (res.data.code == 200) {
                  if (chooseFile_what == 1) {
                      this.contract_file_list.push(res.data.data);
                      this.contract_file_list_arr.push(res.data.data.file_url);
                  }
                  if (chooseFile_what == 2) {
                      this.collection_file_list.push(res.data.data);
                      this.collection_file_list_arr.push(res.data.data.file_url);
                  }
                  this.$message({
                      message: "上传成功",
                      type: "success",
                  });
              }
          });
      },
    // handleDel_img(num) {
    //   this.collection_file_list.forEach((item, index) => {
    //     if (index == num) {
    //       this.collection_file_list.splice(index, 1);
    //        this.collection_file_list_arr.splice(index, 1);
    //     }
    //   });
    // },
      handleDel_img(num, i) {
        console.log(num)
          // i  ==1  删除合同   2   删除 收款
          let {
              contract_file_list,
              collection_file_list,
              contract_file_list_arr,
              collection_file_list_arr,
          } = this;
          if (i == 1) {
              contract_file_list.forEach((item, index) => {
                  if (index == num) {
                      contract_file_list.splice(index, 1);
                      contract_file_list_arr.splice(index, 1);
                  }
              });
          }
          if (i == 2) {
              collection_file_list.forEach((item, index) => {
                  if (index == num) {
                      collection_file_list.splice(index, 1);
                      collection_file_list_arr.splice(index, 1);
                  }
              });
          }
      },
    handleOK() {
      let collection_file = []; //收款凭证
        let contract_file =[];
      let order_data_branch_json = []; //分单人
      let to_time = "";
      let {
        Arrival_money,
        Arrival_type,
        collection,
        time,
        pro_id,
        Promotion_id,
        contract_fd_list,
        collection_file_list,
        remarks,
          order_remarks,
        order_detail,
        contract_file_list
      } = this;
      if (time == "") {
        this.$message({
          message: "请选择到账时间",
          type: "error",
        });
        return;
      } else {
        to_time = this.getwaytime(time);
      }
      if (
        Arrival_money == "" ||
        Arrival_type == "" ||
        collection == "" ||
        pro_id == ""
      ) {
        this.$message({
          message: "请完善数据",
          type: "error",
        });
        return;
      }
      contract_fd_list.forEach((item) => {
        if (item.user_id != "") {
          // console.log(item);
          let obj = {
            user_id: item.user_id,
            to_money: item.to_money,
          };
          order_data_branch_json.push(obj);
        }
      });
      if (order_data_branch_json.length == 0) {
        order_data_branch_json = JSON.stringify([]);
      } else {
        order_data_branch_json = JSON.stringify(order_data_branch_json);
      }
      console.log(collection_file_list)
        console.log(contract_file_list)

        collection_file_list.forEach((item) => {
        let obj = {
          file_url: item.url,
        };
        collection_file.push(obj);
      });
        contract_file_list.forEach((item) => {
            let obj = {
                file_url: item.url,
            };
            contract_file.push(obj);
        });
      let data = {
        order_id: order_detail.order_id,
        order_data_id: order_detail.id,
        to_money: Arrival_money, //到账金额
        type: Arrival_type, //到账类型 1定金 2尾款 3全款
        collection_id: collection, //收款方式
        to_time, //到账时间
        collection_file: JSON.stringify(collection_file), //收款文件
        remarks,
          order_remarks,
          contract_file:JSON.stringify(contract_file),
        service_time:this.service_time,
          service_time_end:this.service_time_end,
          contract_money:this.contract_money,
          contract_number:this.contract_number,
          total_amount_receivable:this.total_amount_receivable,
        promotion_id: Promotion_id, //促销id
        order_data_branch_json, //分单人
      };
      if(!this.is_first){
          this.is_fist=true;
          addOrderMore(data).then((res) => {
              if (res.data.code == 200) {
                  this.$message({
                      message: res.data.message,
                      type: "success",
                  });
                  this.is_Addarrival = false;
                  this.isDetail = true;
                  this.handleDetail({
                      order_data_id: order_detail.id,
                  });
                  this.is_fist=false
              }

          }).catch(()=>{
              this.is_fist=false
          })
      }else {
          this.$message({
              message: '正在提交中',
              type: "error",
          });
      }

    },
    onBlack(){
        this.is_Addarrival = false;
        this.isDetail = true;
        this.radio_id = '1';
        this.collection_file_list = [];
        this.collection_file_list_arr=[];
        this.contract_file_list = [];
        this.contract_file_list_arr=[];
        this.isDetail = false;
    }
  },
};
</script>

<style lang="scss">
.Arrival {
  background: #eef5f9;
  width: 100%;
  // .detail_content_box_line_info {
  //   img {
  //     width: 60px;
  //     height: 60px;
  //     margin-right: 10px;
  //     border-radius: 5px;
  //   }
  // }
  .el-main {
    background: #fff;
    height: 100%;
    width: 100%;
    .Arrival_top_check {
      display: flex;
      align-items: center;
      width: 280px;
      height: 40px;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-bottom: 30px;
      div {
        width: 50%;
        height: 100%;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
      }
      .isCheck {
        background: #3a85fc;
        color: #fff;
      }
    }
    .Arrival_query {
      width: 100%;
      .Arrival_query_line {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        // justify-content: space-between;
        margin-bottom: 20px;
        .Arrival_query_line_one {
          display: flex;
          width: 22%;
          margin-left: 3%;
          .Arrival_query_line_one_name {
            white-space: nowrap;
            display: flex;
            align-items: center;
            height: 100%;
            width: 70px;
            justify-content: flex-end;
          }

          .el-cascader {
            line-height: 32px;
            height: 32px;
            width: 200px;
            input {
              width: 200px;
              height: 32px;
            }
            .el-input__suffix {
              display: flex;
              align-items: center;
            }
          }
          .el-input {
            // flex: 1;
            width: 200px;
            input {
              height: 32px;
              width: 200px;
            }
          }
          .Arrival_query_line_one_time {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1;
            width: 200px;
            input {
              height: 32px;
              max-width: 120px !important;
              min-width: 90px;
              padding-left: 5px;
              padding-right: 10px;
              width: 100%;
            }
            .el-input__suffix {
              display: flex;
              align-items: center;
              right: -15px;
            }
            .el-input {
              min-width: 90px;
              max-width: 120px !important;
            }
          }
          .Arrival_query_line_one_money {
            input {
              padding-left: 15px;
            }
          }
          .ssa {
            width: 0px;
          }
        }
        .header_buttom {
          display: flex;
          height: 32px;
          align-items: center;
          //   width: 75%;
          justify-content: flex-end;
          div {
            width: 70px;
            height: 32px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            border-radius: 2px;
            cursor: pointer;
          }
          .finance_find {
            color: #ffffff;
            opacity: 1;
            background: #3a85fc;
          }
          .finance_reset {
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
            color: rgba(0, 0, 0, 0.6);
            margin-left: 20px;
          }
        }
      }
    }
    .export {
      width: 100%;
      height: 60px;
      background: rgba(0, 0, 0, 0.04);
      opacity: 1;
      display: flex;
      align-items: center;
      margin-top: 20px;
      div {
        width: 80px;
        height: 32px;
        background: #3a85fc;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        margin-left: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #ffffff;
        opacity: 1;
        border-radius: 4px;
        cursor: pointer;
        i {
          margin-right: 5px;
        }
      }
    }
    .Arrival_table_tab {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .Arrival_table_tab_box {
        display: flex;
        border: 1px solid #cccccc;
        border-radius: 2px;
        div {
          width: 72px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border-right: 1px solid #ccc;
          color: rgba(0, 0, 0, 0.8);
        }
        div:last-child {
          border: none;
        }
        .tab_active {
          background: #3a85fc;
          color: #fff;
        }
      }
    }
    .Arrival_table {
      width: 100%;
      .wait_status {
        //0  待审核
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #faad14;
        opacity: 1;
        border-radius: 50%;
        margin-right: 5px;
      }
      .pass_status {
        //1 通过
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #1dd0bd;
        border: 1px solid #1dd0bd;
        border-radius: 50%;
        opacity: 1;
        margin-right: 5px;
      }
      .reject_status {
        //2驳回
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #ff5c5c;
        border-radius: 50%;
        opacity: 1;
        margin-right: 5px;
      }
      .revoke_status {
        //  3 撤销中
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #3299eb;
        border-radius: 50%;
        opacity: 1;
        margin-right: 5px;
      }
      .block {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
      }
    }
  }
  .Arrival_detail {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    .detail_content_Button {
      display: flex;
      width: 100%;
      height: 40px;
      justify-content: flex-end;
      align-items: center;
      margin-top: 40px;
      margin-bottom: 40px;
      padding-right: 100px;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        cursor: pointer;
        height: 100%;
        font-size: 14px;
        border-radius: 4px;
      }
      div:first-child {
        background: #ffffff;
        border: 1px solid #3a85fc;
        color: #3a85fc;
      }
      div:last-child {
        background: #3a85fc;
        color: #fff;
        margin-left: 40px;
      }
    }
  }
  .img_show_box {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    img {
      width: 300px;
      max-height: 600px;
      opacity: 1;
      margin: auto;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 99;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>