import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./utils/axios.tool"
import './assets/fonts/iconfont.css';
import './utils/btnPermissions'


import BaiduMap from 'vue-baidu-map'      //百度地图
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'mGHBwd5wfCq3Xk0sXtthuSDUe50tXyzB'
})
import * as echarts from 'echarts'

Vue.prototype.$echarts = echarts



Vue.use(ElementUI);

Vue.config.productionTip = false
import './assets/icon/iconfont.css';
// import '@/styles/index.scss'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
