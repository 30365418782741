import request from '@/utils/axios.tool';

//我的荣誉
export const myHonor = (data)=>{              
    return request.get(`/roleapi/index/myHonor`,data)
}

//我的得分
export const myScore = (data)=>{              
    return request.get(`/roleapi/index/myScore`,data)
}


//本月业绩趋势
export const historyData = (data)=>{              
    return request.post(`/Applets/myCenter/historyData`,data)
}


//今日到账
export const todayAchievement = (data)=>{              
    return request.get(`/roleapi/index/todayAchievement`,data)
}

//待办事项
export const planList = (data)=>{              
    return request.get(`/roleapi/index/planList`,data)
}

//消息列表
export const newsList = (data)=>{              
    return request.get(`/roleapi/index/newsList`,data)
}


//排名名次
export const ranking = (data)=>{              
    return request.get(`/roleapi/index/ranking`,data)
}


//业绩金额查询和任务获取
export const performance = (data)=>{              
    return request.post(`/Applets/myCenter/performance`,data)
}


//客户意向数
export const myCustomerNumber = (data)=>{              
    return request.post(`Applets/myCenter/myCustomerNumber`,data)
}


//成交客户数
export const myCustomerClinch = (data)=>{              
    return request.post(`Applets/myCenter/myCustomerClinch`,data)
}


//面访量
export const myCustomerCallOn = (data)=>{              
    return request.post(`/Applets/myCenter/myCustomerCallOn`,data)
}

//个人中心--漏斗(意向客户 A类客户数 面访量 到账笔数)
export const funnel = (data)=>{              
    return request.post(`/Applets/myCenter/funnel`,data)
}



//pk
export const pkList = (data)=>{              
    return request.get(`/roleapi/index/pkList`,data)
}

//我的团队
export const myTeamlist = (data)=>{              
    return request.get(`/roleapi/index/myTeamList`,data)
}

//战绩报告
export const achievementReport = (data)=>{              
    return request.get(`/roleapi/index/achievementReport`,data)
}

//战绩报告
export const expectedReceiptList = (data)=>{              
    return request.get(`/roleapi/index/expectedReceiptList`,data)
}
//战绩报告
export const centerMyHonor = (data)=>{              
    return request.get(`/roleapi/index/centerMyHonor`,data)
}