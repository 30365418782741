<template>
  <div
    class="quit_box"
    style="flex: 1"
  >
    <el-main>
      <div class="quit_info">
        <div class="quit_top_box">
          <div class="time_check pointer">
            <div
              v-for="(item, index) in time_arr"
              @click="change_timecheck(item.id)"
              :class="[check_time_id == item.id ? 'acc_time' : '']"
              :key="index"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="quit_top">
            <el-input
              v-model="serchval"
              placeholder="请输入姓名"
            ></el-input>
            <div
              class="account_serch pointer"
              @click="getlist(1)"
            >查询</div>
          </div>
        </div>
        <div
          class="acc_table"
          v-if="tableData.length>0"
        >
          <el-table
            :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
            :data="tableData"
            style="width: 100%"
            border
            :default-sort="{ prop: 'date', order: 'descending' }"
          >
            <el-table-column
              prop="name"
              label="姓名"
            >
              <template slot-scope="scope">
                <div class="acc_table_company_name">
                  {{ scope.row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="职位"
            >
              <template slot-scope="scope">
                <div class="acc_table_company_name">
                  {{ scope.row.position?  scope.row.position.position:'--'}}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="部门"
            >
              <template slot-scope="scope">
                <div class="acc_table_company_name">
                  {{ scope.row.company_name?  scope.row.company_name:'--'}}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="入职时间"
            >
              <template slot-scope="scope">
                <div class="acc_table_company_name">
                  {{ scope.row.entry_time }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="离职时间"
            >
              <template slot-scope="scope">
                <div class="acc_table_company_name">
                  {{
                    scope.row.deleted_at
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="客户数"
              v-if="check_time_id==1"
            >
              <template slot-scope="scope">
                <div class="acc_table_company_name">
                  {{
                    scope.row.my_customer_count
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="分配人"
              v-if="check_time_id==2"
            >
              <template slot-scope="scope">
                <div class="acc_table_company_name">
                  {{
                    scope.row.new_user_name ?  scope.row.new_user_name:'--'
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="操作"
              v-if="role.is_fp&&check_time_id==1"
            >
              <template slot-scope="scope">
                <div
                  class="acc_moreinfo pointer"
                  @click="showshadow(scope.row)"
                >分配</div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :page-size="10"
            layout="total,prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div
          class="no_quit"
          v-if="tableData.length==0"
        >
          <img
            src="@/assets/img/no_quit.png"
            alt=""
          >
          <span>暂无离职人员数据</span>
        </div>
      </div>
    </el-main>
    <!-- 弹出层 -->
    <div
      class="Node_misson_shadow"
      v-if="shadow_show"
      @click.stop="shadow_noshow"
    >
      <div
        class="Node_shadow"
        @click.stop="shadow_show = true"
      >
        <div class="Node_shadow_title">
          {{user_name}}的客户
          <i
            class="el-icon-close pointer"
            @click.stop="shadow_show = false"
            style="position: absolute; right: 19px; color: rgb(153, 153, 153)"
          ></i>
        </div>
        <div class="left_add pointer">
          <div
            :class="[human_check==1 ? 'act_title':'']"
            @click="human_check=1;"
          >客户</div>
          <div
            :class="[human_check==2 ? 'act_title':'']"
            @click="human_check=2;"
          >客服</div>
        </div>
        <div class="Node_shadow_info">
          <el-table
            stripe
            ref="multipleTable"
            :data="infotable"
            tooltip-effect="dark"
            style="width: 100%"
            height="300"
            @selection-change="handleSelectionChange"
            :header-cell-style="{background:'#3A85FC',color:'#fff'}"
          >
            <el-table-column type="selection">
            </el-table-column>
            <el-table-column label="客户名称">
              <template slot-scope="scope">{{scope.row.customer_res? scope.row.customer_res.name:'--' }}</template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="产品"
            >
              <template slot-scope="scope">
                <div
                  class="prodect_info"
                  v-for="(item,index) in scope.row.my_customer_product"
                  :key="index"
                >
                  <!-- {{ scope.row.customer_res? scope.row.customer_res.name:'--' }} -->
                  <div
                    class="quan"
                    :class="item.status==2? '':'black'"
                  ></div>
                  {{item.status==2? (item.product_name.product_name+"-已成交"):(item.product_name.product_name+'-'+item.time+'天')}}
                </div>
                <div v-if="scope.row.my_customer_product.length==0">--</div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="handleinfoChange"
            :current-page.sync="infopage"
            :page-size="10"
            layout="total,prev, pager, next"
            :total="infototal"
          >
          </el-pagination>
          <div class="fpcheck">
            分配人选择
          </div>
          <div class="serchbox">
            <el-autocomplete
              v-model="state"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入分配人名字"
              @select="handleSelect"
            ></el-autocomplete>
          </div>
        </div>
        <div class="bottom_box_1">
          <div>已选择{{check_table.length}}人</div>
          <div
            class="pointer"
            @click="handleOK"
          >确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { list, seekCustomerList, distributionUserList, distribution } from '../../api/User/quit';
export default {
  data() {
    return {
      serchval: "",
      tableData: [],
      page: 1,
      total: 0,
      shadow_show: false,
      user_id: '',
      user_name: '',
      infotable: [],
      check_table: [],
      infopage: 1,
      infototal: 1,
      human_check: 1,
      state: '',//搜索分配人
      check_item: {},
      role: {
        is_fp: false,
        fp_str: '',
        list_str: ''
      },
      check_time_id: 1,
      time_arr: [
        { id: 1, name: "待分配" },
        { id: 2, name: "已分配" },
      ],
    };
  },
  created() {
    let token = JSON.parse(localStorage.getItem("token"));
    for (let i in token.role) {
      if (token.role[i].permissions == 'quit_list') {  //分配列表
        this.role.list_str = token.role[i].permissions_id_string;
      }
      if (token.role[i].permissions == 'quit_edit') {
        this.role.is_fp = true;
        this.role.fp_str = token.role[i].permissions_id_string;
      }
    }
    this.getlist();
  },
  methods: {
    getlist(newpage) {
      if (newpage) {
        this.page = 1
      }
      list({
        permissions_id_string: this.role.list_str,
        type: this.check_time_id,
        name: this.serchval,
        page: this.page
      }).then(res => {
        // console.log(res);
        this.total = res.data.data.total;
        this.tableData = res.data.data.data
      })
    },
    change_timecheck(id) {
      //切换年月日
      this.check_time_id = id;
      this.getlist(1);
    },
    handleSelectionChange(val) {
      // console.log(val);
      this.check_table = val;
    },
    handleSizeChange(e) {
    },
    showshadow(e) {
      this.state = '';
      this.check_item = {};
      this.check_table = [];
      this.shadow_show = true;
      this.user_id = e.id;
      this.user_name = e.name;
      seekCustomerList({
        permissions_id_string: this.role.list_str,
        type: this.human_check,
        page: this.infopage,
        seek_user_id: e.id
      }).then(res => {
        this.infotable = res.data.data.data;
        this.infototal = res.data.data.total
      })
    },
    handleinfoChange(e) {
      this.infopage = e
      let h = {
        id: this.user_id,
        name: this.user_name
      }
      this.showshadow(h)
    },
    handleSelect(item) { //分配人选择
      // console.log(item);
      this.check_item = item;
    },
    querySearchAsync(str, cb) {
      let data = []
      distributionUserList({
        permissions_id_string: this.role.list_str,
        name: str
      }).then(res => {
        for (let i in res.data.data) {
          res.data.data[i].value = res.data.data[i].name + '【' + res.data.data[i].position.position + '】'
        }
        // console.log(res);
        data = res.data.data
        cb(data)
      })
    },
    handleCurrentChange(e) {
      this.page = e;
      this.getlist();
    },
    handleOK() {
      if (JSON.stringify(this.check_item) == "{}") {
        this.$message.error('请选择分配人后分配噢~')
        return
      }
      if (this.check_table.length == 0) {
        this.$message.error('请选择一个或以上客户后分配噢~')
        return
      }
      let str = ''
      for (let i in this.check_table) {
        str += this.check_table[i].id + ','
      }
      str = str.slice(0, str.length - 1)
      // console.log(str);
      this.$confirm("此操作将勾选的" + this.check_table.length + "个客户分配给" + this.check_item.name + "是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        distribution({
          permissions_id_string: this.role.fp_str,
          type: this.human_check,
          old_user_id: this.user_id,
          new_user_id: this.check_item.id,
          distribution_id: str
        }).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "分配成功",
            });
            let h = {
              id: this.user_id,
              name: this.user_name
            }
            this.showshadow(h);
            this.getlist();
          }
        })
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消分配",
        });
      });
    }
  },
  watch: {
    human_check() {
      this.infopage = 1

      let h = {
        id: this.user_id,
        name: this.user_name
      }
      this.showshadow(h)
    },
    state() { }
  }
};
</script>

<style lang="scss">
.quit_box {
  .time_check {
    width: 250px;
    height: 40px;
    // margin-bottom: 20px;
    display: flex;
    border-radius: 5px;
    border: 1px solid #ccc;
    div {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #ccc;
    }
    div:nth-last-of-type(1) {
      border-right: 0px;
    }
    .acc_time {
      background: #3a85fc;
      color: #fff;
    }
  }
  .quit_top_box {
    display: flex;
  }
  .quit_info {
    padding: 20px;
    box-sizing: border-box;
    background: #fff;
    .quit_top {
      display: flex;
      margin-left: 20px;
      width: 450px;
      align-items: center;
      .el-input {
        width: 70%;
        margin-right: 10px;
        .el-input__inner {
          height: 32px;
          line-height: 32px;
        }
      }
      .account_serch {
        width: 70px;
        height: 32px;
        background: #3a85fc;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        border-radius: 2px;
      }
    }
    .acc_table {
      margin-top: 20px;
      .el-pagination {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
      }
      .acc_moreinfo {
        color: #3a85fc;
      }
    }
    .no_quit {
      height: 760px;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      img {
        height: 300px;
        width: 300px;
      }
      span {
        margin-top: 10px;
        color: #595959;
      }
    }
  }
  .Node_misson_shadow {
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    z-index: 2000;
    .el-pagination {
      margin-top: 10px;
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;
    }
    .Node_shadow {
      height: 700px;
      overflow: hidden;
      width: 600px;
      background: #ffffff;
      border: 1px solid #707070;
      opacity: 1;
      border-radius: 10px;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      // .el-table thead{
      //     background: #3A85FC;
      //     color: #fff;
      // }
      .Node_shadow_title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;
        position: relative;
        height: 55px;
        border-bottom: 1px solid #ccc;
      }
      .left_add {
        margin-top: 20px;
        margin-left: 5%;
        display: flex;
        width: 208px;
        height: 35px;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        border: 1px solid #ccc;
        box-sizing: border-box;
        div {
          height: 100%;
          color: #666;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .act_title {
          background: #3a85fc;
          color: #fff;
        }
        border-radius: 4px;
        i {
          margin-right: 5px;
        }
      }
      .Node_shadow_info {
        width: 90%;
        margin: 0 auto;
        margin-top: 10px;
        //   height: 300px;
        .el-table__empty-block {
          //   height: 202px !important;
        }
        .prodect_info {
          display: flex;
          align-items: center;
          .quan {
            margin-right: 5px;
            width: 6px;
            height: 6px;
            background: #42cb94;
            border-radius: 50%;
            opacity: 1;
          }
          .black {
            background: #d6d6d6;
          }
        }
        .fpcheck {
          margin-top: 10px;
          //   width: 90%;
          //   margin: 0 auto;
          display: flex;
          align-items: center;
          height: 40px;
          padding-left: 15px;
          color: #333;
          // background: #ccc;
        }
        .serchbox {
          width: 100%;
          margin-top: 11px;
          .el-autocomplete {
            width: 100%;
          }
        }
      }
      .bottom_box_1 {
        height: 70px;
        background: #ffffff;
        box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.05);
        opacity: 1;
        margin-top: 65px;
        border-radius: 0px 0px 10px 10px;
        display: flex;
        align-items: center;
        padding: 0 29px;
        justify-content: space-between;
        div:nth-of-type(2) {
          width: 120px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          background: #3a85fc;
          border-radius: 5px;
        }
      }
      .info {
        padding: 20px;
        .info_i {
          margin-top: 10px;
          .el-input__inner {
            width: 380px;
          }
        }
        .info_i:nth-of-type(1) {
          margin-top: 0;
        }
        .info_last {
          width: 80px;
          height: 40px;
          background: #3a85fc;
          display: flex;

          justify-content: center;
          align-items: center;
          opacity: 1;
          border-radius: 4px;
          color: #fff;
        }
      }
    }
  }
}
</style>