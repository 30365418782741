import request from '@/utils/axios.tool';
// 任务列表
export const listsTask = (data)=>{              
    return request.post(`/roleapi/task/listsTask`,data)
} 
// 添加和修改任务
export const saveTask = (data)=>{              
    return request.post(`/roleapi/task/saveTask`,data)
} 
// 查询周节点
export const selectMakeNote = (data)=>{              
    return request.post(`/roleapi/task/selectMakeNote`,data)
} 
// 任务审核列表
export const taskAuditList = (data)=>{              
    return request.post(`/roleapi/task/taskAuditList`,data)
} 
// 任务审核
export const taskAudit = (data)=>{              
    return request.post(`/roleapi/task/taskAudit`,data)
} 