<template>
  <div class="drawback_box" style="flex: 1">
    <el-main>
      <div class="customer_header_check pointer" v-if="position != 1">
        <div
          :class="isPersonal == 1 ? 'isPersonal' : ''"
          @click="isPersonal = 1"
        >
          个人
        </div>
        <div
          :class="isPersonal == 2 ? 'isPersonal' : ''"
          @click="isPersonal = 2"
        >
          部门
        </div>
      </div>
      <div class="order_list_top">
        <div class="order_list_top_child" v-if="isPersonal == 2">
          部门：<el-cascader
            placeholder="请选择部门名称"
            :options="depOptions"
            @change="changeDep"
            :key="isResouceShow"
            clearable
            :props="{ checkStrictly: true }"
          ></el-cascader>
        </div>
        <div class="order_list_top_child" v-if="isPersonal == 2">
          申请人：<el-input
            v-model="take_name"
            placeholder="请输入申请人姓名"
          ></el-input>
        </div>
        <div class="order_list_top_child" v-if="isPersonal == 1">
          客户姓名：<el-input
            v-model="name"
            placeholder="请输入客户姓名"
          ></el-input>
        </div>
        <div class="order_list_top_child je">
          金额：
          <el-input
            v-model="small_price"
            placeholder="请输入金额"
            style="width: 100px"
            class="je"
          ></el-input>
          -
          <el-input
            v-model="big_price"
            placeholder="请输入金额"
            style="width: 100px"
            class="je"
          ></el-input>
        </div>
        <div class="order_list_top_child">
          产品名：
          <el-cascader
            v-model="search_product_id"
            :options="productList"
            placeholder="请选择产品"
            separator=" - "
          >
                     </el-cascader
          >
        </div>
        <div class="order_list_top_child">
          状态：
          <el-select v-model="status" placeholder="请选择">
            <el-option
              v-for="item in status_list"
              :key="item.name"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="order_list_top_child re">
          <div class="serch pointer" @click="getlist(1)">查询</div>
          <div class="reset pointer" @click="getlist(0, 1)">重置</div>
        </div>
      </div>
      <div class="acc_table">
        <el-table
          :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
          :data="tableData"
          style="width: 100%"
          border
          :default-sort="{ prop: 'date', order: 'descending' }"
        >
          <el-table-column prop="name" label="公司名称">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ scope.row.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="客户姓名">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ scope.row.contacts }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="产品名">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ scope.row.product_name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="退款金额(元)">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                ￥{{ scope.row.to_money }}
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="isPersonal == 2" prop="name" label="退款人">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ scope.row.user }}
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="isPersonal == 2" prop="name" label="退款部门">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ scope.row.user_company ? scope.row.user_company : "--" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="申请时间">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ scope.row.created_at }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="申请说明" width="120">
            <template slot-scope="scope">
              <el-popover
                placement="bottom"
                width="400"
                trigger="click"
                v-if="scope.row.remarks"
              >
                <div slot="reference" class="pointer acc_table_company_click">
                  点击查看
                </div>
                <div v-html="scope.row.remarks"></div>
              </el-popover>
              <div class="acc_table_company_cuse" v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="状态" key="5">
            <template slot-scope="scope">
              <div
                class="acc_table_company_status"
                v-if="scope.row.is_refund != 1"
              >
                <i
                  :class="
                    scope.row.status == 0
                      ? 'wait_status'
                      : scope.row.status == 1
                      ? 'well_status'
                      : scope.row.status == 2
                      ? 'warning'
                      : ''
                  "
                ></i>
                {{
                  scope.row.status == 0
                    ? "待审核"
                    : scope.row.status == 1
                    ? "通过"
                    : scope.row.status == 2
                    ? "驳回"
                    : "--"
                }}
              </div>
              <div v-else class="acc_table_company_status">
                <i class="well_status"> </i>
                已退款
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="10"
          layout="total,prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-main>
  </div>
</template>

<script>
import { searchCompanysList } from "../../api/User/customer";
import { orderManageRefundList } from "../../api/User/order_list";
import { productList } from "../../api/User/finance";
export default {
  data() {
    return {
      isPersonal: 1,
      position: "",
      name: "",
      small_price: "",
      big_price: "",
      search_product_id: [],
      take_name: "",
      depOptions: [], //筛选部门
      productList: [],
      // 0待审核1通过2驳回 -10已退款
      status_list: [
        { id: 0, name: "待审核" },
        { id: 1, name: "通过" },
        { id: 2, name: "驳回" },
        { id: -10, name: "已退款" },
      ],
      status: "",
      search_company_id: "",
      isResouceShow: 0,
      page: 1,
      total: 0,
      tableData: [],
      permissions_id_string: "",
    };
  },
  created() {
    let token = JSON.parse(localStorage.getItem("token"));
    this.position = token.position_type;
    for (let i in token.role_permissions) {
      if (token.role_permissions[i].permissions == "Marketing") {
        for (let v in token.role_permissions[i].data) {
          if (
            token.role_permissions[i].data[v].permissions == "system_drawback"
          ) {
            this.permissions_id_string =
              token.role_permissions[i].data[v].permissions_id_string;
          }
        }
      }
    }
    productList().then((res) => {
      //搜索产品列表
      if (res.data.code == 200) {
        let list = res.data.data;
        for (let i = 0; i < list.length; i++) {
          list[i].label = list[i].class_name;
          list[i].value = list[i].id;
          list[i].children = list[i].product_name;
          for (let j = 0; j < list[i].product_name.length; j++) {
            list[i].product_name[j].label =
              list[i].product_name[j].product_name;
            list[i].product_name[j].value = list[i].product_name[j].id;
          }
        }
        this.productList = list;
      }
    });
     if (this.isPersonal == 2) {
        searchCompanysList().then((res) => {
          if (res.data.code == 200) {
            function dg(list) {
              list.forEach((item) => {
                item.label = item.name;
                item.value = item.id;
                if (item.data) {
                  item.children = item.data;
                  dg(item.data);
                }
              });
              return list;
            }
            let list = res.data.data;
            dg(list);
            this.depOptions = list;
          }
        });
      }
    this.getlist();
  },
  methods: {
    getlist(is_serch, is_reload) {
      if (is_serch) {
        this.page = 1;
      }
      if (is_reload) {
        this.page = 1;
        this.big_price = "";
        ++this.isResouceShow;
        this.small_price = ""; //价格
        this.name = ""; //客户姓名
        this.search_product_id = []; //产品搜索 
        this.status = "";
        this.take_name = "";
        this.search_company_id = "";
      }
      orderManageRefundList({
        permissions_id_string: this.permissions_id_string,
        class_type: this.isPersonal,
        status: this.status,
        page: this.page,
        contacts: this.name,
        company_id: this.search_company_id,
        user_name: this.take_name,
        search_start_money: this.small_price,
        search_end_money: this.big_price,
        product_id: this.search_product_id[1],
      }).then((res) => {
        this.tableData = res.data.data.data;
        this.total = res.data.data.total;
      });
    },
    changeDep(value) {
      //部门筛选
      if (value.length > 0) {
        this.search_company_id = value[value.length - 1];
      } else {
        this.search_company_id = "";
      }
    },
    handleCurrentChange(e) {
      this.page = e;
      this.getlist();
    },
  },
  watch: {
    isPersonal() {
      this.getlist(0, 1);
      if (this.isPersonal == 2) {
        searchCompanysList().then((res) => {
          if (res.data.code == 200) {
            function dg(list) {
              list.forEach((item) => {
                item.label = item.name;
                item.value = item.id;
                if (item.data) {
                  item.children = item.data;
                  dg(item.data);
                }
              });
              return list;
            }
            let list = res.data.data;
            dg(list);
            this.depOptions = list;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.drawback_box {
  padding: 20px;
  .el-main {
    min-height: 847px;
    background: #fff;
    .acc_table {
      .acc_table_company_click:hover {
        color: #3a85fc;
      }
      .acc_table_company_cuse {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      margin-top: 20px;
      .acc_table_company_status {
        display: flex;
        align-items: center;
        i {
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          opacity: 1;
          margin-right: 10px;
        }
        .well_status {
          background: rgba(29, 208, 189, 1);
        }
        .wait_status {
          background: #faad14;
        }
        .to_time_status {
          background: #3a85fc;
        }
        .warning {
          background: #ff4d4f;
        }
      }
      .el-table__header {
        background: #999 !important;
      }
      .el-pagination {
        margin-top: 10px;
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
      }
      .acc_moreinfo {
        display: flex;
        white-space: nowrap;
      }
    }
    .customer_header_check {
      width: 280px;
      height: 40px;
      display: flex;
      align-items: center;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-bottom: 30px;
      div {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
      }
      .isPersonal {
        background: #3a85fc;
        color: #fff;
      }
    }
    .order_list_top {
      display: flex;
      flex-wrap: wrap;

      .order_list_top_child {
        width: 22%;
        // min-width: 300px;
        display: inline-block;
        white-space: nowrap;
        margin-left: 3%;
        .el-input__icon {
          height: 100%;
          width: 25px;
          text-align: center;
          transition: all 0.3s;
          line-height: 32px;
        }
        .el-select {
          width: 100%;
          max-width: 180px;
        }
        .el-input {
          width: auto;
        }
        .el-cascader {
          line-height: 32px;
          .el-input__icon {
            line-height: 32px;
          }
        }
        .el-input__inner {
          height: 32px;

          line-height: 32px;
          width: 180px;
        }
        .serch {
          float: left;
          width: 70px;
          height: 32px;
          background: #3a85fc;
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          border-radius: 2px;
        }
        .reset {
          float: left;
          width: 70px;
          height: 32px;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
          opacity: 1;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 20px;
          color: #666;
        }
      }
      .order_list_top_child:nth-of-type(1) {
        margin-left: 0;
      }
      .order_list_top_child:nth-of-type(5) {
        margin-left: 0;
      }
      .order_list_top_child:nth-of-type(n + 5) {
        margin-top: 15px;
      }
      .je {
        .el-input__inner:nth-of-type(1) {
          height: 32px;
          margin-left: -4px;
          line-height: 32px;
          width: 102px;
        }
        .el-input__inner {
          height: 32px;
          line-height: 32px;
          width: 102px;
        }
        .el-input:nth-of-type(2) {
          height: 32px;
          margin-left: 4px;
          line-height: 32px;
          width: 102px;
        }
      }
    }
  }
}
</style>