<template>
  <div class="configuration">
    <el-main>
      <div class="war_zone" v-if="tab_head == 1">
        <div
          v-for="(item, index) in war_zone_arr"
          :key="index"
          :class="war_zone_id == item.id ? 'active' : ''"
          @click="war_zone_check(item)"
        >
          {{ item.name }}
          <span>【{{ item.count }}】</span>
        </div>
      </div>
      <div class="configuration_">
        <div class="configuration_head">
          <div
            :class="tab_head == 1 ? 'active' : ''"
            @click="configuration_head(1)"
          >
            军团配置
            <span></span>
          </div>
          <div
            :class="tab_head == 2 ? 'active' : ''"
            @click="configuration_head(2)"
          >
            赛区配置
            <span></span>
          </div>
        </div>
        <div class="configuration_table">
          <el-table
            :data="legion_tableData"
            border
            height="700"
            style="width: 100%"
            v-if="tab_head == 1"
            ref="legion_table"
          >
            <el-table-column prop="name" label="军团名称" width="180">
            </el-table-column>
            <el-table-column prop="leader" label="军团长" width="180">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.leader ? scope.row.leader : "--" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="分公司名称">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.company_info["2"].name
                      ? scope.row.company_info["2"].name
                      : "--"
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="事业部名称">
              <template scope="scope">
                <div>
                  {{
                    scope.row.company_info["3"].name
                      ? scope.row.company_info["3"].name
                      : "--"
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="business_line" label="事业线">
            </el-table-column>
            <el-table-column label="参与状态">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.campaign_status_bool"
                  active-color="#4CD1A3"
                  inactive-color="#E5E5E5"
                  @change="change_status(1, scope.row)"
                >
                </el-switch>
              </template>
            </el-table-column>
          </el-table>
          <div class="addarea" v-if="tab_head == 2">
            <div @click="looklook(1)"><i class="el-icon-plus"></i>新增赛区</div>
          </div>
          <el-table
            :data="area_tableData"
            border
            height="600"
            style="width: 100%"
            v-if="tab_head == 2"
            ref="area_table"
          >
            <el-table-column prop="name" label="赛区名称"> </el-table-column>
            <el-table-column prop="address" label="状态">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.is_status"
                  active-color="#4CD1A3"
                  inactive-color="#E5E5E5"
                  @change="change_status(2, scope.row)"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="查看">
              <template slot-scope="scope">
                <div
                  style="color: #3a85fc"
                  class="pointer"
                  @click="looklook(2, scope.row)"
                >
                  查看
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="addarea_shadow" v-if="add_area">
        <div class="addarea_commen">
          <div class="addarea_commen_title">
            {{ is_add == 1 ? "新增" : "修改" }}赛区
            <i
              class="el-icon-close pointer"
              style="color: #999999"
              @click="add_area = false"
            ></i>
          </div>
          <div class="addarea_commen_body">
            <div class="ano">
              赛区：<el-input
                v-model="area_name"
                type="text"
                placeholder="请输入赛区名称"
                maxlength="10"
              ></el-input>
            </div>
          </div>
          <div class="bottom">
            <div class="noshadow pointer" @click="add_area = false">取消</div>
            <div class="check_shadow pointer" @click="sent_add_area">保存</div>
          </div>
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
import {
  getZone,
  addZone,
  editZone,
  legionConfiguration,
  setCampaignStatus,
} from "../../api/User/PK";
export default {
  data() {
    return {
      war_zone_arr: [],
      war_zone_id: "1",
      tab_head: 1,
      legion_tableData: [], //军团
      area_tableData: [], //赛区
      value: false,
      area_name: "",
      area_row: {},
      add_area: false,
      is_add: "", //1新增 2 修改
      add_flag: false,
      permissions_id_string: "",
    };
  },
  created() {
    // this.getlegionConfiguration();
    legionConfiguration({
      permissions_id_string: this.permissions_id_string,
    }).then((res) => {
      if (res.data.code == 200) {
        this.war_zone_arr = res.data.data;
        this.war_zone_check(res.data.data[0]);
      }
    });
    let token = JSON.parse(localStorage.getItem("token"));
    this.position = token.position_type;
    for (let i in token.role_permissions) {
      if (token.role_permissions[i].permissions == "Marketing") {
        for (let v in token.role_permissions[i].data) {
          if (
            token.role_permissions[i].data[v].permissions == "configuration"
          ) {
            this.permissions_id_string =
              token.role_permissions[i].data[v].permissions_id_string;
          }
        }
      }
    }
  },
  methods: {
    war_zone_check(item) {
      this.war_zone_id = item.id;
      this.legion_tableData = [];
      this.getlegionConfiguration({
        company_one_id: item.id,
        permissions_id_string: this.permissions_id_string,
      });
    },
    configuration_head(i) {
      this.tab_head = i;
      if (i == 1) {
        history.go(0);
      }
      if (i == 2) {
        this.getZone_data({
          permissions_id_string: this.permissions_id_string,
        });
      }
    },
    getlegionConfiguration(data) {
      legionConfiguration(data).then((res) => {
        if (res.data.code == 200) {
          let list = res.data.data;
          for (let i = 0; i < list.length; i++) {
            list[i].campaign_status_bool =
              list[i].campaign_status == 1 ? true : false;
          }
          this.legion_tableData = list;
          this.$nextTick(() => {
            this.$refs.legion_table.doLayout();
          });
        }
      });
    },
    change_status(i, row) {
      //i  1 军团状态修改 2 赛区状态修改
      if (this.add_flag) {
        return;
      }
      this.add_flag = true;
      if (i == 1) {
        setCampaignStatus({
          company_id: row.id,
          campaign_status: row.campaign_status == 1 ? 2 : 1,
        }).then((res) => {
          this.add_flag = false;

          this.$message({
            message: res.data.message,
            type: "success",
            center: true,
          });
          this.getlegionConfiguration({
            company_one_id: this.war_zone_id,
            permissions_id_string: this.permissions_id_string,
          });
        });
      }
      if (i == 2) {
        editZone({
          zone_id: row.id,
          status: row.status == 1 ? 2 : 1,
          permissions_id_string: this.permissions_id_string,
        }).then((res) => {
          this.add_flag = false;
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
              center: true,
            });
            this.getZone_data({
              permissions_id_string: this.permissions_id_string,
            });
          }
        });
      }
    },
    looklook(i, row) {
      this.is_add = i;
      this.add_area = true;
      this.area_name = "";
      if (row) {
        this.area_name = row.name;
        this.area_row = row;
      }
    },
    sent_add_area() {
      if (this.add_flag) {
        return;
      }
      this.add_flag = true;
      if (this.is_add == 1) {
        addZone({
          name: this.area_name,
        }).then((res) => {
          this.add_flag = false;
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
              center: true,
            });
            this.getZone_data({
              permissions_id_string: this.permissions_id_string,
            });
            this.add_area = false;
          }
        });
      } else {
        editZone({
          zone_id: this.area_row.id,
          name: this.area_name,
          permissions_id_string: this.permissions_id_string,
        }).then((res) => {
          this.add_flag = false;
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
              center: true,
            });
            this.getZone_data({
              permissions_id_string: this.permissions_id_string,
            });
            this.add_area = false;
          }
        });
      }
    },
    getZone_data(data) {
      getZone(data).then((res) => {
        if (res.data.code == 200) {
          var list = res.data.data;
          for (let i = 0; i < list.length; i++) {
            list[i].is_status = list[i].status == 1 ? true : false;
          }
          this.area_tableData = list;
          this.$nextTick(() => {
            this.$refs.area_table.doLayout();
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.configuration {
  flex: 1 1 0%;
  padding: 20px;
  background: #eef5f9;
  .el-main {
    width: 100%;
    height: 100%;
    display: flex;
    .war_zone {
      width: 253px;
      height: 100%;
      background: #ffffff;
      margin-right: 20px;
      div {
        height: 40px;
        width: 100%;
        padding-left: 20px;
        line-height: 40px;
        color: #333;
        cursor: pointer;
      }
      border-radius: 4px;
      div.active {
        background: #3a85fc;
        color: #fff;
      }
      div:hover {
        background: #3a85fc;
        color: #fff;
      }
    }
    .configuration_ {
      flex: 1;
      height: 100%;
      background: #fff;
      padding: 20px;
      padding-top: 0;
      .configuration_head {
        height: 58px;
        border-bottom: 1px solid #efefef;
        display: flex;
        align-items: center;
        cursor: pointer;
        div {
          font-size: 14px;
          font-weight: 400;
          color: #646464;
          margin-right: 50px;
          padding: 0 14px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          span {
            display: block;
            width: 42px;
            height: 3px;
            opacity: 1;
            position: absolute;
            bottom: 0;
          }
        }
        div.active {
          color: #3a85fc;
          span {
            display: block;
            width: 42px;
            height: 3px;
            background: #3a85fc;
            opacity: 1;
            position: absolute;
            bottom: 0;
          }
        }
      }
      .configuration_table {
        margin-top: 20px;
        .addarea {
          width: 100%;
          height: 60px;
          background: #fafafa;
          border: 1px solid #efefef;
          border-bottom: none;
          display: flex;
          align-items: center;
          padding-left: 16px;
          div {
            width: 87px;
            height: 32px;
            background: #3a85fc;
            opacity: 1;
            border-radius: 2px;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: #ffffff;
            opacity: 1;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            i {
              margin-right: 4px;
            }
          }
        }
        thead {
          tr {
            th {
              background: #fafafa;
              height: 44px;
              padding: 0;
            }
          }
        }
        thead .cell {
          font-size: 14px;
          font-weight: bold;
          color: #595959;
          opacity: 1;
        }
        tbody .cell {
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          color: #666666;
          opacity: 1;
        }
      }
    }
    .addarea_shadow {
      position: fixed;
      z-index: 120;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;

      .addarea_commen {
        width: 446px;
        height: 243px;
        background: #ffffff;
        border: 1px solid #707070;
        opacity: 1;
        border-radius: 10px;
        margin: auto;
        position: relative;
        .addarea_commen_title {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #333;
          position: relative;
          height: 52px;
          border-bottom: 1px solid #ccc;
          font-size: 16px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
          opacity: 1;
          i {
            position: absolute;
            right: 10px;
          }
        }
        .addarea_commen_body {
          width: 100%;
          resize: none;
          padding: 5% 10%;
          overflow: auto;
          .ano {
            margin-bottom: 10px;
            display: flex;
            white-space: nowrap;
            align-items: center;
          }
          .el-input {
            position: relative;
            font-size: 14px;
            display: inline-block;
            width: 100%;
          }
        }
        .bottom {
          margin-top: 20px;
          display: flex;
          justify-content: flex-end;
          padding: 0 10%;
          .noshadow {
            width: 80px;
            height: 40px;
            background: #ffffff;

            color: #707070;
            border: 1px solid rgba(0, 0, 0, 0.3019607843);
            opacity: 1;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
          }
          .check_shadow {
            width: 80px;
            height: 40px;
            background: #3a85fc;
            opacity: 1;
            border-radius: 4px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
</style>