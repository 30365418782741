<template>
  <div class="tkgl">
    <el-main>
      <div class="tkgl_header">
        <div class="tkgl_header_search">
          <div class="tkgl_header_search_one" style="margin: 0">
            <div class="tkgl_header_search_one_tit">申请人：</div>
            <el-input
              v-model="user_name"
              placeholder="请输入申请人姓名"
            ></el-input>
          </div>
          <div class="tkgl_header_search_one">
            <div class="tkgl_header_search_one_tit">部门：</div>
            <el-cascader
              placeholder="请选择部门名称"
              :options="options"
              ref="myCascader"
              @change="handleClick"
              :key="isResouceShow"
              clearable
              :props="{ checkStrictly: true }"
            ></el-cascader>
          </div>
          <div class="tkgl_header_search_one">
            <div class="tkgl_header_search_one_tit">产品名称：</div>
            <el-cascader
              v-model="search_product_id"
              :options="productList"
              placeholder="请选择产品"
              separator=" - "
            >
            </el-cascader>
          </div>
          <div class="tkgl_header_search_one">
            <div class="tkgl_header_search_one_tit">提交时间：</div>
            <div class="line_input">
              <el-date-picker
                :editable="iseditable"
                class="date_time"
                prefix-icon="saa"
                style="width: 144px; height: 32px"
                v-model="search_start_time"
                type="date"
                :picker-options="pickerOptions"
                @focus="handleFocus(1)"
                @change="handleTime($event, 1)"
                placeholder="开始时间"
              >
              </el-date-picker>
              <i>-</i>
              <el-date-picker
                :editable="iseditable"
                prefix-icon="saa"
                class="date_time"
                v-model="search_end_time"
                style="width: 144px; height: 32px"
                type="date"
                :picker-options="pickerOptions"
                @focus="handleFocus(2)"
                @change="handleTime($event, 2)"
                placeholder="结束时间"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="tkgl_header_search_bottom">
          <div @click="handleFind(currentPage)">查询</div>
          <div @click="handleReset">重置</div>
        </div>
        <div class="tkgl_header_search_tab">
          <div class="tkgl_header_search_tab_one" @click="checkstatus(0)">
            <div :class="check_status == '0' ? 'tab_tit_active' : ''">
              待处理
            </div>
            <div :class="check_status == '0' ? 'tab_active' : ''"></div>
          </div>
          <div
            class="tkgl_header_search_tab_one"
            style="margin-left: 100px"
            @click="checkstatus(1)"
          >
            <div :class="check_status == '1' ? 'tab_tit_active' : ''">
              已处理
            </div>
            <div :class="check_status == '1' ? 'tab_active' : ''"></div>
          </div>
        </div>
        <div class="tkgl_table">
          <el-table
            :data="tableData"
            border
            :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
            style="width: 100%"
          >
            <el-table-column label="公司名称">
              <template scope="scope">
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column label="客户姓名">
              <template scope="scope">
                {{ scope.row.contacts }}
              </template>
            </el-table-column>
            <el-table-column label="产品名">
              <template scope="scope"> {{ scope.row.product_name }} </template>
            </el-table-column>
            <el-table-column label="退款金额（元）">
              <template scope="scope">
                {{ scope.row.to_money }}
              </template>
            </el-table-column>
            <el-table-column label="申请人">
              <template scope="scope"> {{ scope.row.user }} </template>
            </el-table-column>
            <el-table-column label="申请部门">
              <template scope="scope">
                {{ scope.row.user_company ? scope.row.user_company : "--" }}
              </template>
            </el-table-column>
            <el-table-column label="退款说明" width="120">
              <template scope="scope">
                <div class="apply_explain">
                  <el-popover
                    placement="bottom"
                    width="400"
                    trigger="click"
                    v-if="scope.row.remarks"
                  >
                    <div
                      slot="reference"
                      class="pointer acc_table_company_click"
                    >
                      点击查看
                    </div>
                    <div v-html="scope.row.remarks"></div>
                  </el-popover>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="申请时间">
              <template scope="scope"> {{ scope.row.created_at }} </template>
            </el-table-column>
            <el-table-column label="状态">
              <template scope="scope">
                <i
                  :class="
                    scope.row.is_refund == 1
                      ? 'pass_status'
                      : scope.row.status == 1
                      ? 'wait_status'
                      : ''
                  "
                ></i>
                {{
                  scope.row.is_refund == 1
                    ? "已处理"
                    : scope.row.status == 1
                    ? "待处理"
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template scope="scope">
                <div
                  v-if="check_status == '0' && role_what.edit"
                  class="pointer"
                  style="color: #3a85fc"
                  @click="wuhu(scope.row, 1)"
                >
                  上传凭证
                </div>
                <div
                  v-if="check_status == '1' && role_what.edit"
                  class="pointer"
                  style="color: #3a85fc"
                  @click="wuhu(scope.row, 2)"
                >
                  修改
                </div>
                <div v-if="!role_what.edit">--</div>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="10"
              el-pagination
              layout="total,prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </el-main>
    <div class="voucher_box" v-if="is_upload">
      <div class="voucher">
        <div class="voucher_head">
          {{ wuhu_i == 1 ? "凭证上传" : "修改" }}
          <i
            class="pointer el-icon-close"
            @click="
              is_upload = false;
              voucher_file_list = [];
              time = '';
              wuhu_company_id = '';
            "
          ></i>
        </div>
        <div style="padding: 0 60px">
          <div class="voucher_tit">退款日期</div>
          <el-date-picker
            v-model="time"
            type="datetime"
            prefix-icon="sada"
            placeholder="选择日期时间"
            :disabled="disabled"
          >
          </el-date-picker>
          <div class="voucher_tit">部门</div>
          <el-select v-model="wuhu_company_id" placeholder="请选择部门">
            <el-option
              v-for="item in upload_detail.companys"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <div class="voucher_tit">上传退款凭证</div>
          <div class="voucher_img_choose">
            <div
              class="flow_kind_text_img"
              v-for="(item, index) in voucher_file_list"
              :key="index"
            >
              <img :src="item.file_url" alt="" />
              <i
                class="el-icon-circle-close"
                @click="handleDel_img(index)"
                v-if="wuhu_i == 1"
              ></i>
            </div>
            <div
              class="image_info"
              @click="chooseFile"
              v-if="voucher_file_list.length < 3 && wuhu_i == 1"
            >
              <img src="@/assets/img/upload_img.png" alt="" />
              <span>上传相关图片</span>
              <input
                type="file"
                style="display: none"
                ref="choosePhoto"
                @change="photoChange($event)"
                id="upload_file"
              />
            </div>
          </div>
          <div class="voucher_bottom">
            <div
              @click="
                is_upload = false;
                voucher_file_list = [];
                time = '';
                wuhu_company_id = '';
              "
            >
              取消
            </div>
            <div @click="upupupload">确定</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  productList,
  selectOrderCompanyWhere,
  financeRefundList,
  uploadVoucher,
  voucherDetail,
  editVoucher,
} from "../../api/User/finance";
import { uploadFile } from "../../api/User/index";

export default {
  data() {
    return {
      iseditable: false,
      user_name: "", //申请人
      options: [], //部门
      search_depth: "", //深度
      search_company_id: "", //部门id
      isResouceShow: 0,
      productList: [], //产品列表
      search_product_id: [], //产品id
      search_start_time: "", //
      search_end_time: "", //结束时间
      pickerOptions: {},
      check_status: "0", //审核状态
      tableData: [],
      currentPage: 1, //页码
      total: 0,
      time: "",
      voucher_file_list: [],
      is_upload: false, //上传

      row: {},
      wuhu_i: "",
      disabled: false,
      upload_detail: {}, //上传凭证信息
      wuhu_company_id: "", //上传部门选择
      role_what: {
        list_str: "",
        edit: false,
        edit_str: "",
      },
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      let roleList = JSON.parse(
        localStorage.getItem("token")
      ).role_permissions;
      roleList.forEach((item) => {
        if (item.permissions == "Finance") {
          item.data.forEach((itm) => {
            if (itm.permissions == "Finance_refund_list") {
              this.role_what.list_str = itm.permissions_id_string;
            }
            if (itm.permissions == "Finance_refund_edit") {
              this.role_what.edit = true;
              this.role_what.edit_str = itm.permissions_id_string;
            }
          });
        }
      });
    }
    if (localStorage.getItem("check_status")) {
      this.check_status = localStorage.getItem("check_status");
    } else {
      this.check_status = 0;
    }
    selectOrderCompanyWhere().then((res) => {
      if (res.data.code == 200) {
        function dg(list) {
          list.forEach((item) => {
            item.label = item.name;
            item.value = item.id;
            if (item.data) {
              item.children = item.data;
              dg(item.data);
            }
          });
          return list;
        }
        let list = res.data.data;
        dg(list);
        this.options = list;
      }
    });
    this.getfinanceRefundList({
      status: this.check_status,
      permissions_id_string: this.role_what.list_str,
    });
    productList().then((res) => {
      //搜索产品列表
      if (res.data.code == 200) {
        let list = res.data.data;
        for (let i = 0; i < list.length; i++) {
          list[i].label = list[i].class_name;
          list[i].value = list[i].id;
          list[i].children = list[i].product_name;
          for (let j = 0; j < list[i].product_name.length; j++) {
            list[i].product_name[j].label =
              list[i].product_name[j].product_name;
            list[i].product_name[j].value = list[i].product_name[j].id;
          }
        }
        this.productList = list;
      }
    });
  },
  methods: {
    getfinanceRefundList(data) {
      financeRefundList(data).then((res) => {
        if (res.data.code == 200) {
          this.total = res.data.data.total;
          this.tableData = res.data.data.data;
          this.search_company_id = "";
        }
      });
    },
    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // let strDate = Y + M + D;
      let strDate = Y + M + D + h + m + "00";
      return strDate;
    },
    handleClick(value) {
      this.search_depth =
        this.$refs["myCascader"].getCheckedNodes()[0].data.depth;
      this.search_company_id = value[value.length - 1];
      // this.search_company_name = this.$refs['myCascader'].getCheckedNodes()[0].data.name
    },
    handleFocus(i) {
      //时间选择器事件
      this.pickerOptions = {};
      if (i == 1) {
        if (this.search_end_time == "") {
          this.pickerOptions.disabledDate = (time) =>
            time.getTime() > Date.now() - 8.64e6;
        } else {
          let endtime_s = new Date(this.search_end_time);
          this.pickerOptions.disabledDate = (time) =>
            time.getTime() > Date.parse(endtime_s) - 8.64e6;
        }
      }
      if (i == 2) {
        if (this.search_start_time == "") {
          this.pickerOptions.disabledDate = (time) =>
            time.getTime() > Date.now() - 8.64e6;
        } else {
          let starttime_s = new Date(this.search_start_time);
          this.pickerOptions.disabledDate = (time) =>
            time.getTime() < Date.parse(starttime_s) ||
            time.getTime() > Date.now() - 8.64e6;
        }
      }
    },
    handleTime(value, i) {
      //时间
      // i   1  开始时间  2 结束时间
      if (i == 1) {
        this.search_start_time = this.getwaytime(value);
      }
      if (i == 2) {
        this.search_end_time = this.getwaytime(value);
      }
    },
    checkstatus(status) {
      this.check_status = status;
      localStorage.setItem("check_status", status);
      this.getfinanceRefundList({
        status,
        permissions_id_string: this.role_what.list_str,
      });
    },
    handleCurrentChange(val) {
      this.handleFind(val);
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    chooseFile: function () {
      this.$refs.choosePhoto.addEventListener('change',this.photoChange);
this.$refs.choosePhoto.dispatchEvent(new MouseEvent("click"));
      this.upload_img = false;
      setTimeout(() => {
        this.upload_img = true;
      }, 10);
    },
    photoChange(el) {
      var file = el.target.files[0]; //name: "dangqi1.png" || type: "image/png"
      var type = file.type.split("/")[0];
      if (type === "image") {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        var that = this;
        reader.onloadend = function () {
          var dataURL = reader.result;
          var blob = that.dataURItoBlob(dataURL);
          that.upload(blob); //执行上传接口
        };
      } else {
        this.$message.error("请上传图片哦~~");
      }
    },
    dataURItoBlob(dataURI) {
      // base64 解码
      let byteString = window.atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let T = mimeString.split("/")[1];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    upload(imgUrl) {
      let formData = new FormData();
      this.$message({
        message: "上传中，请稍后",
      });
      formData.append("file", imgUrl);
      uploadFile(formData).then((res) => {
        if (res.data.code == 200) {
          this.voucher_file_list.push(res.data.data);
          this.$message({
            message: "上传成功",
            type: "success",
          });
        }
      });
    },
    handleDel_img(num) {
      this.voucher_file_list.forEach((item, index) => {
        if (index == num) {
          this.voucher_file_list.splice(index, 1);
        }
      });
    },
    handleFind(page) {
      if (!page) {
        page = 1;
      }
      let {
        user_name,
        search_company_id,
        search_product_id,
        search_start_time,
        search_end_time,
      } = this;
      let data = {
        status: this.check_status,
        company_id: search_company_id,
        product_id: search_product_id[1],
        start_submit_time: search_start_time.split(" ")[0],
        end_submit_time: search_end_time.split(" ")[0],
        user_name,
        page,
        permissions_id_string: this.role_what.list_str,
      };
      this.getfinanceRefundList(data);
    },
    handleReset() {
      ++this.isResouceShow;
      this.user_name = "";
      this.search_company_id = "";
      this.search_product_id = [];
      this.search_start_time = "";
      this.search_end_time = "";
      this.getfinanceRefundList({
        status: this.check_status,
        permissions_id_string: this.role_what.list_str,
      });
    },
    wuhu(row, i) {
      //1上传 2修改
      if (i == 1) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
      this.row = row;
      this.wuhu_i = i;
      this.is_upload = true;
      this.time = "";
      voucherDetail({
        order_data_id: row.order_data_id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.upload_detail = res.data.data;
          if (i == 2) {
            this.wuhu_company_id = res.data.data.current_companys.id;
            this.time = res.data.data.voucher.refund_time;
            this.voucher_file_list = res.data.data.voucher.collection_file;
          }
        }
      });
    },
    upupupload() {
      //凭证上传
      //  upload_detail: {}, //上传凭证信息
      let { voucher_file_list, time, wuhu_company_id, row, wuhu_i } = this;
      let data = {};
      if (wuhu_i == 1) {
        //上传
        if (time == "" || wuhu_company_id == "") {
          this.$message({
            message: "请完善数据",
            type: "error",
          });
          return;
        }
        let collection_file = [];
        if (voucher_file_list.length == 0) {
          this.$message({
            message: "请上传凭证图片",
            type: "error",
          });
          return;
        } else {
          voucher_file_list.forEach((item) => {
            let obj = {
              file_url: item.url,
            };
            collection_file.push(obj);
          });
        }
        data = {
          order_data_id: row.order_data_id,
          refund_time: this.getwaytime(time),
          company_id: wuhu_company_id,
          collection_file: JSON.stringify(collection_file),
        };
        uploadVoucher(data).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.is_upload = false;
          }
        });
      }
      if (wuhu_i == 2) {
        //修改
        data = {
          order_data_id: row.order_data_id,
          company_id: wuhu_company_id,
        };
        editVoucher(data).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.is_upload = false;
          }
        });
      }
      this.handleFind(this.currentPage);
    },
  },
};
</script>

<style lang="scss">
.tkgl {
  flex: 1;
  background: #eef5f9;
  width: 100%;
  margin: 20px;
  .el-main {
    background: #fff;
    height: 100%;
    width: 100%;
    .tkgl_header {
      .tkgl_header_search {
        display: flex;
        .tkgl_header_search_one {
          display: flex;
          width: 20%;
          height: 32px;
          align-items: center;
          margin-left: 3%;
          .tkgl_header_search_one_tit {
            width: 80px;
            white-space: nowrap;
            display: flex;
            align-items: center;
            height: 100%;
            justify-content: flex-end;
          }
          .el-input {
            width: 92%;
            input {
              height: 32px;
              width: 180px;
            }
          }
          .line_input {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 280px;
            .el-input {
              width: 114px !important;
              input {
                padding-left: 5px;
                width: 114px;
              }
            }
            i {
              color: #ccc;
            }
            .el-input__icon {
              display: flex;
              height: 100%;
              align-items: center;
            }
          }
        }
      }
      .tkgl_header_search_bottom {
        display: flex;
        height: 32px;
        align-items: center;
        margin: 20px;
        div {
          width: 70px;
          height: 32px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          border-radius: 2px;
          cursor: pointer;
        }
        div:first-child {
          color: #ffffff;
          opacity: 1;
          background: #3a85fc;
        }
        div:last-child {
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
          color: rgba(0, 0, 0, 0.6);
          margin-left: 20px;
        }
      }
      .tkgl_header_search_tab {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #e1e1e1;
        padding-left: 26px;
        div {
          cursor: pointer;
        }
        .tkgl_header_search_tab_one {
          width: 46px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          div:first-child {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
          }
          div:last-child {
            width: 46px;
            height: 2px;
            opacity: 1;
          }
          .tab_tit_active {
            color: #3a85fc;
          }
          .tab_active {
            background: #3a85fc;
          }
        }
      }
      .tkgl_table {
        margin-top: 20px;
        width: 100%;
        .apply_explain {
          .acc_table_company_click:hover {
            color: #3a85fc;
          }
        }
        .el-table th.gutter {
          display: table-cell !important;
        }
        .el-table__header-wrapper {
          .cell {
            white-space: nowrap;
          }
        }
        .block {
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;
        }
        .wait_status {
          //0  待审核
          display: inline-block;
          width: 6px;
          height: 6px;
          background: #faad14;
          opacity: 1;
          border-radius: 50%;
          margin-right: 5px;
        }
        .pass_status {
          //1 通过
          display: inline-block;
          width: 6px;
          height: 6px;
          background: #1dd0bd;
          border: 1px solid #1dd0bd;
          border-radius: 50%;
          opacity: 1;
          margin-right: 5px;
        }
      }
    }
  }
  .voucher_box {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    .voucher {
      width: 600px;
      min-width: 460px;
      min-height: 520px;
      height: 520px;
      background: #ffffff;
      opacity: 1;
      border-radius: 10px;
      margin: auto;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 99;
      display: flex;
      flex-direction: column;
      .voucher_head {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        font-size: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        width: 100%;
        position: relative;
        i {
          position: absolute;
          right: 20px;
          padding: 10px;
        }
      }
      .el-date-editor {
        width: 100%;
        height: 32px;
        input {
          height: 32px;
        }
      }
      .el-cascader {
        width: 100%;
        .el-input {
          height: 32px;
          input {
            height: 32px;
          }
        }
      }
      .voucher_tit {
        margin: 30px 0 10px;
      }
      .el-input__inner {
        padding-left: 15px;
      }
      .voucher_img_choose {
        display: flex;
        .flow_kind_text_img {
          position: relative;
          border-radius: 4px;
          margin-right: 10px;
          img {
            width: 100px;
            height: 100px;
            border-radius: 4px;
          }
          i {
            display: block;
            position: absolute;
            top: -5px;
            right: -5px;
            cursor: pointer;
            color: #000 !important;
          }
        }
        .image_info {
          width: 100px;
          height: 100px;
          position: relative;
          display: flex;
          background: rgba(0, 0, 0, 0.1);
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          opacity: 0.6;
          overflow: hidden;
          cursor: pointer;
          img {
            height: 30px;
            width: 30px;
          }
          span {
            margin-top: 10px;
            color: rgba(0, 0, 0, 0.3);
          }
        }
        div:first-child {
          display: flex;
          .el-upload-list {
            .el-upload-list__item {
              width: 110px;
              height: 110px;
            }
          }
        }
      }
      .voucher_bottom {
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
        div {
          width: 80px;
          height: 40px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border-radius: 4px;
        }
        div:first-child {
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
          opacity: 1;
        }
        div:last-child {
          background: #3a85fc;
          color: #fff;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>