<template>
  <div id="app">
    <!--<router-view />-->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
  import './assets/common.css'

export default {
    data() {
        return {

        }
    },
    watch: {
        $route(to, from) {
            // let token = JSON.parse(localStorage.getItem("token")).token
            // if (token&&token!=null&&token!=''&&token!=undefined) {// firebase returns null if user logged out
            //     this.isLoggedIn = true;
            // } else {
            //     this.isLoggedIn = false;
            // }

            if(from.fullPath=='/Marketing/customer/detail'){
               to.meta.keepAlive=true
            }
            console.log(this.$route.meta)
        }
    }
}
</script>
<style>
    
#app {
  height: 100%;
}
.el-header{
    height: 60px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 20;
    min-width: 1400px;
}
.index_header .el-container.is-vertical{
      padding-top: 60px;
    padding-left: 250px;
    box-sizing: border-box;
}
.el-aside{
    width: 250px;
    height: 100%;
    position: fixed;
    left: 0;
}
</style>
