<template>
  <div class="bill_box" style="flex: 1">
    <el-main>
      <div class="kind_info">
        <div class="left_tit">
          <div
            class="pointer"
            @click="check_status(item.id)"
            :class="[status == item.id ? 'active' : '' ]"
            v-for="(item, index) in status_arr"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <Arrival v-if="status==1" />
      <!-- <Excellent v-if="status==2" />
      <Launch v-if="status==3" />
      <Public v-if="status==4" /> -->
    </el-main>
  </div>
</template>

<script>
import excellent from './excellent';
import Launch_page from './Launch_page';
import Public from './public_img';
import Arrival_broadcast from './Arrival_broadcast';
export default {
    components: {
        Excellent:excellent,
        Launch:Launch_page,
        Public,
        Arrival:Arrival_broadcast
    },
    data(){
        return{
        status_arr: [
            { id: 1, name: "到账播报" },
        ],
        status:1,
        role_wt:{
          is_Arrival:false,
          is_Excellence:false,
        }
        }
    },
    created () {
        let token = JSON.parse(localStorage.getItem("token"));
        let rolelist = token.role
        rolelist.forEach(item => {
          if (item.permissions=="Poster_Arrival_edit") {
            this.role_wt.is_Arrival = true
          }
          if (item.permissions=="Poster_excellence_add") {
            this.role_wt.is_Excellence = true
          }
        });
        this.status = 1
    },
    methods: {
      check_status(id){
        this.status = id
      }
    },
    
};
</script>

<style lang='scss'>
.bill_box {
  padding: 20px;
  box-sizing: border-box;
  .el-main {
    min-height: 847px;
    background: #fff;
    .take_money{
        display: flex;
        margin-top: 20px;
          .big_money {
            width: 30%;
            margin-left: 3%;
            height: 219px;
            background: #ffffff;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            opacity: 1;
            border-radius: 4px;

          }
          .big_money:nth-of-type(1){
            margin-left: 0%;
          }
    }
    .kind_info {
      width: 100%;
      display: flex;
      border-bottom: 1px solid #e1e1e1;
      margin-top: 20px;
      padding-left: 10px;
      justify-content: space-between;
      line-height: 35px;
      .left_tit {
        width: 40%;
        display: flex;
        // margin-bottom: 5px;
        justify-content: space-between;
        div {
          width: 84px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 2px solid transparent;
        }
        .active {
          color: #3a85fc;
          border-bottom: 2px solid #3a85fc;
        }
      }
      .right_tit {
        padding-bottom: 1px;
        .right_info {
          width: 119px;
          border-radius: 5px;
          border: 1px solid #3a85fc;
          display: flex;
          color: #3a85fc;
          align-items: center;
          justify-content: center;
          margin-bottom: 4px;
        }
      }
    }
  }
}
</style>