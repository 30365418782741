import request from '@/utils/axios.tool';


//获取所有一级
export const oneLevel = (data)=>{              
    return request.post(`/roleapi/explain/oneLevel`,data)
}

//新增
export const add = (data)=>{              
    return request.post(`/roleapi/explain/add`,data)
}
//edit
export const edit = (data)=>{              
    return request.post(`/roleapi/explain/edit`,data)
}

//del
export const del = (data)=>{              
    return request.post(`/roleapi/explain/del`,data)
}
//排序
export const setOrderBy = (data)=>{              
    return request.post(`/roleapi/explain/setOrderBy`,data)
}


//列表
export const list = (data)=>{              
    return request.post(`/roleapi/explain/list`,data)
}

//详情
export const detail = (data)=>{              
    return request.post(`/roleapi/explain/detail`,data)
}