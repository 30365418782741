//营销中心 促销单

import request from '@/utils/axios.tool';

//促销列表
export const PromotionList = (data)=>{              
    return request.post(`/roleapi/Promotion/PromotionList`,data)
}
//获取促销单详情
export const getPromotion = (data)=>{              
    return request.post(`/roleapi/Promotion/getPromotion`,data)
}
//修改促销单
export const savePromotion = (data)=>{              
    return request.post(`/roleapi/Promotion/savePromotion`,data)
}






