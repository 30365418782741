import request from "@/utils/axios.tool";

//统计 产品维度
export const statistics = (data) => {
  return request.post(`/roleapi/productDimension/statistics`, data);
};

//时间维度分析统计图
export const timeStatisticsChart = (data) => {
  return request.get(`/roleapi/statistics/timeStatisticsChart`, data);
};

//业绩分析列表
export const timeStatisticsList = (data) => {
  return request.get(`/roleapi/statistics/timeStatisticsList`, data);
};

export const seeDepth = (data) => {
  return request.get(`/roleapi/statistics/seeDepth`, data);
};

export const register = (data) => {
  return request.post(`/roleapi/statistics/register`, data);
};

//业绩导出
export const export_sta = (data) => {
  return request.get(`roleapi/statistics/export`, data);
};

// 导出

export const export_pro = (data) => {
  return request.post(`/roleapi/productDimension/export`, data);
};

// 业绩分析查看部门
export const selectCompanyTree = (data) => {
  return request.post(`/roleapi/statistics/selectCompanyTree`, data);
};

// 业绩 任务额 总计

export const statisticsall = (data) => {
  return request.get(`/roleapi/statistics/statistics`, data);
};

//事业线列表
export const businessLine = (data) => {
  return request.get(`/roleapi/statistics/businessLine`, data);
};

//面访客户部门列表
export const companyDataList = (data) => {
  return request.get(`/roleapi/statistics/companyDataList`, data);
};

//面访销售人员统计
export const companySellerCount = (data) => {
  return request.get(`/roleapi/statistics/companySellerCount`, data);
};

//面访意向客户数量
export const companyCustomerCount = (data) => {
  return request.get(`/roleapi/statistics/companyCustomerCount`, data);
};

//面访数量
export const companyLogCount = (data) => {
  return request.get(`/roleapi/statistics/companyLogCount`, data);
};

//面访促进数量
export const companyPlanCommentCount = (data) => {
  return request.get(`/roleapi/statistics/companyPlanCommentCount`, data);
};
//面访部门分析
export const companyDataStatistics = (data) => {
  return request.get(`/roleapi/statistics/companyDataStatistics`, data);
};
//业绩统计
export const companyAchievementCount = (data) => {
  return request.get(`/roleapi/statistics/companyAchievementCount`, data);
};

//面访部门导出
export const companyExport = (data) => {
  return request.post(`/roleapi/statistics/companyExport`, data);
};

//可交接客户列表
export const handoverList = (data) => {
  return request.get(`/roleapi/handover/handoverList`, data);
};
//用户库容
export const userCustomerCapacity = (data) => {
  return request.get(`/roleapi/handover/userCustomerCapacity`, data);
};
//可交接客户列表
export const handover = (data) => {
  return request.post(`/roleapi/handover/handover`, data);
};
//公海列表
export const publicCustomer = (data) => {
  return request.get(`/roleapi/publicWater/publicCustomer`, data);
};
//领取
export const receivePublicCustomer = (data) => {
  return request.post(`/roleapi/publicWater/receivePublicCustomer`, data);
};
//添加客户
export const addPublicCustomer = (data) => {
  return request.post(`/roleapi/publicWater/addPublicCustomer`, data);
};

//成交客户交接
export const dealHandover = (data) => {
  return request.post(`/roleapi/handover/dealHandover`, data);
};

//意向客户交接
export const intentionHandover = (data) => {
  return request.post(`/roleapi/handover/intentionHandover`, data);
};

//获取事业部
export const selectCompanyTrees = (data) => {
  return request.post(`/roleapi/company/selectCompanyTree`, data);
};

//删除公海
export const delWaterTableRow = (data) => {
  return request.post(`/roleapi/publicWater/publicCustomerDel`, data);
};

//导出excel
export const publicCustomerExport = (data) => {
  return request.get(`/roleapi/publicWater/publicCustomerExport`, data);
};
