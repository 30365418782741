<template>
  <div style="height:100%">
    <el-container style="height:100%">
      <el-aside
        width="250px"
        class="human_leftcheck"
      >
        <el-menu
          :default-active="check_active"
          class="el-menu-vertical-demo"
          @select="handleSelect"
        >
          <el-submenu
            index="1"
            v-if="all_power.is_resources ||all_power.is_pos_list ||all_power.is_Promotion"
          >
            <template slot="title">
              <!-- <i class="el-icon-location"></i> -->
              <i
                class="iconfont icon-tree"
                style="margin-right:5px;color:#3a85fc;"
              ></i>
              <span>组织管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                index="1-1"
                v-if="all_power.is_resources"
              >
                <span>组织架构</span>
              </el-menu-item>
              <el-menu-item
                index="1-2"
                v-if="all_power.is_pos_list"
              >
                职位管理
              </el-menu-item>
              <el-menu-item
                index="1-3"
                v-if="all_power.is_Promotion"
              >
                晋升说明
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>
      <humanChild v-if="check_active=='1-1'" />
      <Position v-if="check_active=='1-2'" />
      <Promotion v-if="check_active=='1-3'" />
    </el-container>
  </div>
</template>
<script>

import {
  selectCompanyAll,
  select,
  userList,
  getAncestorsAndSelf,
  update,
  add,
  deleteCompany,
  positionList,
  addUserSelectCompany,
  addUserGetAncestorsAndSelf,
  selectRole,
  user_add,
  userDetail,
  edit,
  searchCompany,
  del,
  checkCompanyOperate,
} from "../../api/User/human";
import humanChild from "./human_child";
import Promotion from './Promotion';
import Position from './Position';
export default {

  data() {
    return {
      check_active: '1-1',
      all_power: {
        is_resources: false,       //组织架构
        is_pos_list: false,        //职位管理
        is_Promotion: false,     //晋升说明
      }
    };
  },
  components: {
    'humanChild': humanChild,
    // 'Position':Position_set,
    Promotion,
    Position
  },
  created() {
    let newdata = JSON.parse(localStorage.getItem("token"));
    let role_permissions = newdata.role_permissions;
    let newpower = {};
    for (let i in role_permissions) {
      if (role_permissions[i].permissions == "resources") {
        newpower = role_permissions[i]
      }
    }
    newpower.data.forEach(item => {
      if (item.permissions == 'serch_userList') {
        this.all_power.is_resources = true
      }
      if (item.permissions == 'system_pos_list') {
        this.all_power.is_pos_list = true
      }
      if (item.permissions == 'Promotion_list') {
        this.all_power.is_Promotion = true
      }
    });
    if (localStorage.getItem('check_active')) {
      this.check_active = localStorage.getItem('check_active')
    }
  },
  methods: {
    //左侧变化 或者切换右边   部门 id 父级id 深度 名字 , 是否销售部门
    handleSelect(key) {
      this.check_active = key;
      localStorage.setItem('check_active', key)
    },
  },
  watch: {
    serch_val(val) {
      if (val == "") {
        this.serch_compony_list = [];
        this.serch_user_list = [];
        return;
      }
      searchCompany({ name: val }).then((res) => {
        this.serch_compony_list = res.data.data.company;
        this.serch_user_list = res.data.data.user;
      });
    },
  },
};
</script>