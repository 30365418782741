<template>
  <div class="ett_center">
    <el-container style="height: 100%; width: 100%">
      <el-aside width="250px">
        <el-menu @select="handleSelect" :default-active="is_Select">
          <el-submenu index="1" class="el_menu_left" v-if="all_power.is_prodect">
            <template slot="title">
              <img style="margin-right: 5px" src="../../assets/img/product_yes.png" alt="" />
              产品管理</template
            >
            <el-menu-item index="产品管理" v-if="all_power.is_prodect">产品管理</el-menu-item>
          </el-submenu>
          <el-submenu index="2" class="el_menu_left" v-if="all_power.is_role">
            <template slot="title">
              <i class="el-icon-s-custom" style="width: 18px; color: #3a85fc"></i>
              <span>角色管理</span></template
            >
            <el-menu-item index="角色管理" v-if="all_power.is_role">
              <span>角色管理</span>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="5" class="el_menu_left" v-if="all_power.is_business">
            <template slot="title">
              <!-- <i class="el-icon-setting" style="margin-right:5px；color:rgba(58, 133, 252, 0.5)"></i> -->
              <i class="el-icon-s-marketing" style="width: 18px; color: #3a85fc"></i>
              <span>事业线管理</span></template
            >
            <el-menu-item index="事业线管理">
              <!-- <i class="el-icon-s-custom" style="width:18px" ></i> -->
              <span>事业线管理</span>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="1" class="el_menu_left" v-if="all_power.is_cloud_set">
            <template slot="title">
              <img style="margin-right: 5px; width: 19px; height: 15px" src="../../assets/img/cloud.png" alt="" />
              云平台配置</template
            >
            <el-menu-item index="云平台配置" v-if="all_power.is_prodect">云平台配置</el-menu-item>
          </el-submenu>
          <el-submenu index="6" class="el_menu_left">
            <template slot="title">
              <!-- <i class="el-icon-setting" style="margin-right:5px；color:rgba(58, 133, 252, 0.5)"></i> -->
              <i class="el-icon-question" style="width: 18px; color: #3a85fc"></i>
              <span>系统操作说明</span></template
            >
            <el-menu-item index="系统操作说明">
              <!-- <i class="el-icon-s-custom" style="width:18px" ></i> -->
              <span>系统操作说明</span>
            </el-menu-item>
            <el-menu-item index="系统操作说明设置" v-if="all_power.is_explain_edit">
              <!-- <i class="el-icon-s-custom" style="width:18px" ></i> -->
              <span>系统操作说明设置</span>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="7" class="el_menu_left">
            <template slot="title">
              <!-- <i class="el-icon-setting" style="margin-right:5px；color:rgba(58, 133, 252, 0.5)"></i> -->
              <i class="el-icon-s-flag" style="width: 18px; color: #3a85fc"></i>
              <span>客户标签管理</span></template
            >
            <el-menu-item index="客户标签管理">
              <!-- <i class="el-icon-s-custom" style="width:18px" ></i> -->
              <span>客户标签管理</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <prodect v-if="is_Select == '产品管理' && all_power.is_prodect" />
      <Role v-if="is_Select == '角色管理' && all_power.is_role" />
      <Business-line v-if="is_Select == '事业线管理' && all_power.is_business" />
      <Explain v-if="is_Select == '系统操作说明'" />
      <Editexplain v-if="is_Select == '系统操作说明设置' && all_power.is_explain_edit" />
      <cloud-administration v-if="is_Select == '云平台配置' && all_power.is_cloud_set"></cloud-administration>
      <customerTagsList v-if="is_Select == '客户标签管理' && all_power.is_tags_set" />
    </el-container>
  </div>
</template>
<script>
import Position_set from "../system/Position_set";
import prodect_control from "../system/prodect_control";
import role from "../role/role";
import Node_mission from "../system/Node_mission";
import Play_bill from "./play_bill.vue";
import Business_lineVue from "./Business_line.vue";
import explain from "./explain";
import edit_explain from "./edit_explain";
import CloudAdministration from "./CloudAdministration";
import customerTagsList from "./customerTags/index.vue";
export default {
  components: {
    prodect: prodect_control,
    Position: Position_set,
    Role: role,
    "Node-mission": Node_mission,
    "Play-bill": Play_bill,
    "Business-line": Business_lineVue,
    Explain: explain,
    Editexplain: edit_explain,
    CloudAdministration,
    customerTagsList,
  },
  data() {
    return {
      is_Select: "",
      all_power: {
        is_role: false,
        is_prodect: false,
        is_business: false,
        is_explain: false,
        is_explain_edit: false,
        is_cloud_set: false,
        is_tags_set: false,
      },
    };
  },
  created() {
    let newdata = JSON.parse(localStorage.getItem("token"));
    let role_permissions = newdata.role_permissions;
    for (let i in role_permissions) {
      if (role_permissions[i].permissions == "system") {
        role_permissions[i].data.forEach((item) => {
          if (item.permissions == "system_pro_list") {
            //产品管理
            this.all_power.is_prodect = true;
          }
          if (item.permissions == "role_list") {
            //角色管理
            this.all_power.is_role = true;
          }
          if (item.permissions == "Business_line_list") {
            //事业线
            this.all_power.is_business = true;
          }
          // if (item.permissions == "system_explain") {
          //   //系统说明
          //   this.all_power.is_explain = true;
          // }
          if (item.permissions == "system_explain_edit") {
            //系统说明编辑
            this.all_power.is_explain_edit = true;
          }
          if (item.permissions == "cloud_set") {
            //云平台配置
            this.all_power.is_cloud_set = true;
          }
          if(item.permissions == 'customer_tag'){
            this.all_power.is_tags_set = true;

          }
        });
      }
    }
  },
  mounted() {
    if (localStorage.getItem("is_Select")) {
      // this.is_Select = localStorage.getItem("is_Select");
      this.handleSelect(localStorage.getItem("is_Select"));
    } else {
      let menu = document.querySelector(".el-menu li>ul>li");
      // console.log(menu.innerHTML);
      this.isSelect = menu.innerHTML;
      this.handleSelect(menu.innerHTML, "");
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      this.is_Select = key;
      localStorage.setItem("is_Select", key);
    },
  },
};
</script>

<style lang="scss">
.ett_center {
  height: 100%;
  width: 100%;
  display: flex;
  .el-aside {
    height: 100%;
    background: #fff;
  }
}
</style>
