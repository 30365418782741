<template>
    <div class="visit_analysis" style="flex: 1">
        <el-main>
            <div class="history_box_dep">
                <div
                        v-for="(item, index) in dep_arr"
                        :key="index"
                        :class="[depth_check == item.id ? 'active_dep' : '']"
                        @click="checkdep(item.id)"
                >
                    {{ item.label }}
                </div>
            </div>
            <div class="visit_analysis_data">
                <div class="visit_analysis_data_line">
                    <div class="serch_big_box">
                        <div class="info">
                            <span class="demonstration">部门：</span>
                            <el-cascader
                                    placeholder="请选择部门名称"
                                    :options="options"
                                    ref="myCascader"
                                    @change="handleClick"
                                    :key="isResouceShow"
                                    clearable
                                    :show-all-levels="false"
                                    collapse-tags
                                    :props="{ multiple: true, checkStrictly: true }"
                            ></el-cascader>
                        </div>
                        <div class="info">
                            <span class="demonstration">开始时间：</span>
                            <el-date-picker
                                    v-model="start_time"
                                    :clearable="false"
                                    type="date"
                                    @change="changeyear($event,'a1')"
                                    placeholder="选择开始时间"
                            >
                            </el-date-picker>
                        </div>
                        <div class="info">
                            <span class="demonstration">结束时间：</span>
                            <el-date-picker
                                    v-model="end_time"
                                    :clearable="false"
                                    type="date"
                                    @change="changeyear($event,'a2')"
                                    placeholder="选择结束时间"
                            >
                            </el-date-picker>
                        </div>
                        <div class="info" v-if="depth_check == 3">
                            <span class="demonstration" style="width: 68px;">事业线：</span>
                            <el-select
                                    @change="changbusiness"
                                    v-model="business_line_id"
                                    placeholder="请选择事业线"
                            >
                                <el-option
                                        v-for="item in business_Line_arr"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="info">
                            <div class="serch pointer" @click="serch">查询</div>
                            <div class="reset pointer" @click="reset">重置</div>
                        </div>
                    </div>
                </div>
                <div class="visit_analysis_data_table">
                    <div class="export">
                        <div class="all_num">
                        </div>
                        <div @click="export_list" class="export_bottom">
                            <i class="el-icon-printer" style="margin-right: 5px"></i>
                            导出
                        </div>
                    </div>
                    <div class="table">
                        <el-table
                                :data="tableData"
                                border
                                height="650"
                                fixed
                                :show-overflow-tooltip="true"
                                :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
                                ref="table"
                                style="width: 100%"
                        >
                            <el-table-column
                                    type="index"
                                    label="序号"
                                    fixed="left"
                                    width="80"
                                    key="0"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                    label="部门名称"
                                    prop="name"
                                    :key="1"
                                    fixed="left"
                                    width="150"
                                    :show-overflow-tooltip="true"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                    label="负责人"
                                    :key="2"
                                    prop="company_leader"
                                    width="100"
                                    fixed="left"
                                    :show-overflow-tooltip="true"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                    label="商务人数"
                                    prop="total_user"
                                    width="100"
                                    :show-overflow-tooltip="true"
                                    :key="3"
                                    align="center"
                            >
                            </el-table-column>
                            <!--<el-table-column-->
                            <!--label="客户录入情况"-->
                            <!--width="400"-->
                            <!--:show-overflow-tooltip="true"-->
                            <!--:key="4"-->
                            <!--align="center"-->
                            <!--&gt;-->

                            <!--</el-table-column>-->
                            <el-table-column
                                    label="意向客户总数"
                                    width="130"
                                    prop="total_customer"
                                    :show-overflow-tooltip="true"
                                    :key="4"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                    label="B级客户数"
                                    width="130"
                                    prop="total_customer_b"
                                    :show-overflow-tooltip="true"
                                    :key="5"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                    label="A级客户数"
                                    width="130"
                                    prop="total_customer_a"
                                    :show-overflow-tooltip="true"
                                    :key="6"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                    label="A类转化率"
                                    width="130"
                                    prop="rate_customer_a"
                                    :show-overflow-tooltip="true"
                                    :key="7"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                    label="面访量"
                                    width="130"
                                    prop="total_log"
                                    :show-overflow-tooltip="true"
                                    :key="8"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                    label="面访量占A类"
                                    width="130"
                                    prop="rate_log_a"
                                    :show-overflow-tooltip="true"
                                    :key="9"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                    label="到账笔数"
                                    width="130"
                                    prop="total_order"
                                    :show-overflow-tooltip="true"
                                    :key="10"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                    label="到账笔数占A类"
                                    width="130"
                                    prop="rate_order_a"
                                    :show-overflow-tooltip="true"
                                    :key="11"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                    label="到账金额"
                                    width="130"
                                    prop="total_money"
                                    :show-overflow-tooltip="true"
                                    :key="12"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                    label="意向客户人均"
                                    width="130"
                                    prop="customer_per"
                                    :show-overflow-tooltip="true"
                                    :key="13"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                    label="B类人均"
                                    width="130"
                                    prop="total_b_per"
                                    :show-overflow-tooltip="true"
                                    :key="14"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                    label="A类人均"
                                    width="130"
                                    prop="total_a_per"
                                    :show-overflow-tooltip="true"
                                    :key="15"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                    label="面访量人均"
                                    width="130"
                                    prop="total_tog_per"
                                    :show-overflow-tooltip="true"
                                    :key="16"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                    label="到账笔数人均"
                                    width="130"
                                    prop="total_moeny_per"
                                    :show-overflow-tooltip="true"
                                    :key="17"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                    label="人均到账"
                                    width="130"
                                    prop="total_per"
                                    :show-overflow-tooltip="true"
                                    :key="18"
                                    align="center"
                            >
                            </el-table-column>
                            <!--<el-table-column-->
                            <!--label="客户面访情况"-->
                            <!--:key="9"-->
                            <!--:show-overflow-tooltip="true"-->
                            <!--align="center"-->
                            <!--width="600">-->

                            <!--&lt;!&ndash;<el-table-column&ndash;&gt;-->
                            <!--&lt;!&ndash;label="上级促进数量"&ndash;&gt;-->
                            <!--&lt;!&ndash;width="200"&ndash;&gt;-->
                            <!--&lt;!&ndash;fixed&ndash;&gt;-->
                            <!--&lt;!&ndash;:show-overflow-tooltip="true"&ndash;&gt;-->
                            <!--&lt;!&ndash;:key="13"&ndash;&gt;-->
                            <!--&lt;!&ndash;prop="total_comment"&ndash;&gt;-->
                            <!--&lt;!&ndash;align="center"&ndash;&gt;-->
                            <!--&lt;!&ndash;&gt;&ndash;&gt;-->
                            <!--&lt;!&ndash;</el-table-column>&ndash;&gt;-->
                            <!--</el-table-column>-->
                        </el-table>
                    </div>
                    <div class="block">
                        <!--<el-pagination-->
                        <!--@current-change="handleCurrentChange"-->
                        <!--:current-page.sync="currentPage"-->
                        <!--:page-size="15"-->
                        <!--el-pagination-->
                        <!--layout="total,prev, pager, next, jumper"-->
                        <!--:total="total"-->
                        <!--&gt;-->
                        <!--</el-pagination>-->
                    </div>
                </div>
            </div>
        </el-main>
    </div>
</template>

<script>
    import {
        companyExport,
        companySellerCount,
        selectCompanyTree,
        companyCustomerCount,
        companyLogCount,
        companyDataList,
        businessLine,
        companyPlanCommentCount,
        companyAchievementCount
    } from "../../api/User/data";
    // import { productList } from "../../api/User/finance";
    import axios from 'axios'
    export default {
        data() {
            return {
                dep_arr: [
                    {label: "大区", id: 1},
                    {label: "分公司", id: 2},
                    {label: "事业部", id: 3}
                ],
                depth_check: "1",
                tableData: [],
                currentPage: 1,
                total: 0,
                check_time_id: "1",
                start_time: "", //开始时间
                end_time: "", //结束时间
                shadow_show: false,
                is_add: false,
                isResouceShow: 0,
                search_company_id: [],
                // is_dep:false,
                options: [],
                all_task: 0,
                all_do: 0,
                all_rate: 0,
                product_arr: [],
                isproductShow: 1,
                total_customerA:'',
                total_comment:'',
                totall:'',
                total_user:'',
                arr:[],
                business_Line_arr: [], //事业线
                business_line_id: "", //所选事业线
            };
        },
        created() {
            let token = JSON.parse(localStorage.getItem("token"));
            for (let i in token.role) {
                if (token.role[i].permissions == "achievement_analysis") {
                    //分配列表
                    this.list_str = token.role[i].permissions_id_string;
                }
            }
            // seeDepth({
            //     permissions_id_string: this.list_str,
            // }).then((res) => {
            //     if (res.data.data != 1) {
            //         this.dep_arr.splice(0, res.data.data - 1);
            //     }
            //
            // });
            this.checkdep(1);
            businessLine().then((res) => {
                if (res.data.code==200) {
                    this.business_Line_arr = res.data.data
                }
            });
            // this.getList();
            console.log(this.tableData);
        },
        mounted(){

        },
        methods: {
            getselectCompanyTree(data) {
                selectCompanyTree(data).then((res) => {
                    if (res.data.code == 200) {
                        function dg(list) {
                            list.forEach((item) => {
                                item.label = item.name;
                                item.value = item.id;
                                if (item.data) {
                                    item.children = item.data;
                                    dg(item.data);
                                }
                            });
                            return list;
                        }

                        let list = res.data.data;
                        dg(list);
                        this.options = list;
                    }
                });
            },
            checkdep(id) {
                // if (this.is_dep) {
                //     return;
                // }
                this.check_prodect = "";
                this.business_line_id = "";
                this.is_dep = true;
                this.currentPage = 1;
                this.depth_check = id;
                ++this.isResouceShow;
                this.search_company_id = [];
                this.tableData = [];
                let list = this.options;
                function dg(list) {
                    list.forEach((item) => {
                        item.label = item.name;
                        item.value = item.id;
                        if (item.data) {
                            item.children = item.data;
                            dg(item.data);
                        }
                    });
                    return list;
                }
                dg(list);
                this.options = list;
                this.serch();
                this.getselectCompanyTree({
                    permissions_id_string: this.list_str,
                    depth: this.depth_check,
                });
            },
            changeyear(e, id) {
                var heihei = this.getwaytime(e);
                if (id == 'a1') {
                    console.log(heihei.toString())
                    this.start_time = heihei.toString();
                } else {
                    this.end_time = heihei.toString();
                }

            },
            handleClick(value) {
                //选择 删除下级children
                //展开可选择不同层级
                if (value.length == 0) {
                    ++this.isResouceShow;
                    this.search_company_id = [];
                    return;
                }
                let options = JSON.parse(JSON.stringify(this.options));
                function dg(list) {
                    list.forEach((item) => {
                        if (item.data) {
                            item.children = item.data;
                            dg(item.data);
                        }
                    });
                    return list;
                }
                dg(options);
                this.options = options;
                if (value.length == 0) {
                    ++this.isResouceShow;
                    return;
                }
                let check_arr = this.$refs["myCascader"].getCheckedNodes();
                this.search_company_id = [];
                let arr = this.search_company_id;
                function depdg(options, check_arr) {
                    for (let i = 0; i < options.length; i++) {
                        for (let j = 0; j < check_arr.length; j++) {
                            if (options[i].id == check_arr[j].value) {
                                if (options[i].data) {
                                    delete options[i].children;
                                }
                            } else {
                                if (options[i].data) {
                                    depdg(options[i].data, check_arr);
                                }
                            }
                        }
                    }
                }
                depdg(options, check_arr);
                this.options = JSON.parse(JSON.stringify(options));
                check_arr.forEach((item) => {
                    arr.push(item.value);
                });
                this.search_company_id = arr;

            },
            serch() {
                this.getList()
            },
            reset() {
                //
                this.isproductShow++;
                this.is_dep = false
                this.tableData = [];
                this.start_time = '';
                this.end_time = '';
                this.search_company_id = [];
                ++this.isResouceShow;
                this.business_line_id=''
                this.getList()
            },
            //时间撮
            getwaytime(timestamp) {
                var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
                var Y = date.getFullYear();
                var M =
                    (date.getMonth() + 1 < 10
                        ? "0" + (date.getMonth() + 1)
                        : date.getMonth() + 1) + "-";
                var D =
                    (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
                var h =
                    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
                var m =
                    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
                    ":";
                var s =
                    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
                let strDate = Y + '-' + M + D;
                return strDate;
            },
            //导出
            export_list() {
                let datad = {
                    permissions_id_string: this.list_str,
                    data:JSON.stringify(this.tableData)
                };
                companyExport(datad).then((res) => {
                    let link = document.createElement("a");
                    link.style.display = "none";
                    link.href = res.data.data;
                    link.setAttribute("download", "courman" + ".xls");
                    document.body.appendChild(link);
                    link.click();
                });
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.serch();
            },
            changbusiness(e){
                this.business_line_id = e;
            },
            getList(){
                let data={
                    depth: this.depth_check,
                    company_id: this.search_company_id.join(","),
                    start_time: this.start_time,
                    end_time: this.end_time,
                    permissions_id_string: this.list_str,
                    business_line_id:this.business_line_id
                };
                //列表
                // var arr=[]
                // companyDataList(data).then(res=>{
                //      arr=res.data.data.map(item=>{
                //         //面访数量
                //         return {
                //             'name':item.name,
                //             'company_leader':item.company_leader.user.name,
                //             'id':item.id
                //         }
                //     });
                // //销售人员统计
                // companySellerCount(data).then(res=>{
                //     this.total_user = res.data.data;
                //     arr.map(item=>{
                //         item['total_user'] = 0;
                //         for(var i in this.total_user) {
                //             if (item.id == i) {
                //                 total_user = res.data.data[item.id]['total_user'];
                //                 item['total_user'] = total_user;
                //                 return item;
                //             }
                //         }
                //
                //     })
                // });
                // companyLogCount(data).then(res=>{
                //     this.totall = res.data.data
                //     arr.map(item=>{
                //         item['total_log'] = '0';
                //         item['rate_log_a'] = '0';
                //         for(var i in this.totall) {
                //             if (item.id == i) {
                //                 item['total_log'] = res.data.data[item.id]['total_log']
                //                 item['rate_log_a'] = res.data.data[item.id]['rate_log_a']
                //                 return item;
                //             }
                //         }
                //     })
                // });
                // //促进数量
                // // companyPlanCommentCount(data).then(res=>{
                // //    this.total_comment = res.data.data
                // //     arr.map(item=>{
                // //         item['total_comment'] = '0'
                // //         for(var i in this.total_comment) {
                // //             if (item.id == i) {
                // //                 item['total_comment'] = res.data.data[item.id]['total_comment']
                // //                 return item;
                // //             }
                // //         }
                // //     })
                // // });
                // //意向客户数量
                // companyCustomerCount(data).then(res=>{
                //     this.total_customerA=res.data.data;
                //     arr.map(item=>{
                //         item['total_customer_a'] = '0'
                //         item['total_customer_b'] = '0'
                //         item['total_customer'] = '0'
                //         item['rate_customer_a'] = '0'
                //             for(var i in this.total_customerA) {
                //                 if (item.id == i) {
                //                     item['total_customer_a'] = res.data.data[item.id]['total_customer_a']
                //                     item['total_customer_b'] = res.data.data[item.id]['total_customer_b']
                //                     item['total_customer'] = res.data.data[item.id]['total_customer']
                //                     item['rate_customer_a'] = res.data.data[item.id]['rate_customer_a']
                //                     return item;
                //                 }
                //             }
                //     })
                // });
                //  //业绩统计
                // companyAchievementCount(data).then(res=>{
                //     arr.map(item=>{
                //         item['total_order'] = '0';
                //         item['total_moeny'] = '0';
                //         item['rate_order_a'] = '0';
                //         for(var i in res.data.data) {
                //             if (item.id == i) {
                //                 item['total_moeny'] = res.data.data[item.id]['total_moeny'];
                //                 item['total_order'] = res.data.data[item.id]['total_order'];
                //                 item['rate_order_a'] = res.data.data[item.id]['rate_order_a'];
                //                 return item;
                //             }
                //         }
                //     })
                // });
                //
                //     // setTimeout(this.$nextTick(() => {
                //     //     this.tableData=arr}),3000)
                //
                // });
                axios.all([companyDataList(data),companySellerCount(data),companyLogCount(data),companyCustomerCount(data),companyAchievementCount(data)])
                    .then(axios.spread((list, Count,LogCount,CustomerCount,AchievementCount) => {
                        var arr= list.data.data.map(item=>{
                            item['total_user'] = '0';
                            item['total_log'] = '0';
                            item['rate_log_a'] = '0';
                            item['total_customer_a'] = '0'
                            item['total_customer_b'] = '0'
                            item['total_customer'] = '0'
                            item['rate_customer_a'] = '0'
                            item['total_order'] = '0';
                            item['total_money'] = '0';
                            item['rate_order_a'] = '0';

                            for(var i in Count.data.data) {
                                if (item.id == i) {
                                    item['total_user'] = Count.data.data[item.id]['total_user'];
                                }
                            }
                            for(var i in LogCount.data.data) {
                                if (item.id == i) {
                                    item['total_log'] = LogCount.data.data[item.id]['total_log']
                                }
                            }
                            for(var i in CustomerCount.data.data) {
                                if (item.id == i) {
                                    item['total_customer_a'] = CustomerCount.data.data[item.id]['total_customer_a']
                                    item['total_customer_b'] = CustomerCount.data.data[item.id]['total_customer_b']
                                    item['total_customer'] = CustomerCount.data.data[item.id]['total_customer']
                                    item['rate_customer_a'] = CustomerCount.data.data[item.id]['rate_customer_a']
                                }
                            }
                            for(var i in AchievementCount.data.data) {
                                if (item.id == i) {
                                    item['total_money'] = AchievementCount.data.data[item.id]['total_money'];
                                    item['total_order'] = AchievementCount.data.data[item.id]['total_order'];
                                }
                            }
                            item['rate_order_a'] = item['total_customer_a']>0?((item['total_order']/item['total_customer_a']).toFixed(4)*100).toFixed(2)+'%':'0'
                            item['rate_log_a'] = item['total_customer_a']>0?((item['total_log']/item['total_customer_a']).toFixed(4)*100).toFixed(2)+'%':'0'

                            item['customer_per'] = item['total_user']>0?parseInt(item['total_customer']/item['total_user']):'0'
                            item['total_b_per'] = item['total_user']>0?parseInt(item['total_customer_b']/item['total_user']):'0'
                            item['total_a_per'] = item['total_user']>0?parseInt(item['total_customer_a']/item['total_user']):'0'
                            item['total_tog_per']=item['total_user']>0?parseInt(item['total_log']/item['total_user']):'0'
                            item['total_moeny_per']=item['total_user']>0?parseInt(item['total_order']/item['total_user']):'0'
                            item['total_per']=item['total_user']>0?(item['total_money']/item['total_user']).toFixed(2):'0'
                            item['name'] =item.name
                            item['company_leader'] =item.company_leader
                            item['id'] =item.id
                            //面访数量
                            return item
                        });
                        this.tableData=arr
                    }))
            },
        }
    };
</script>

<style lang="scss">
    .visit_analysis {
        // padding: 20px;
        box-sizing: border-box;
        background: #eef5f9;
        .el-main {
            width: 100%;
            height: 100%;
            // background: #fff;
            display: flex;
            .visit_analysis_dep {
                background: #ffffff;
                width: 150px;
                margin-right: 10px;
                padding-top: 20px;
                height: 100%;
                max-height: 800px;
                position: absolute;
                top: 80px;
                left: 270px;
                div {
                    // height: 45px;
                    line-height: 45px;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 400;
                    color: #595959;
                    opacity: 1;
                    cursor: pointer;
                }
                div:hover {
                    background: #3a85fc;
                    color: #ffffff;
                }
                .active_dep {
                    background: #3a85fc;
                    color: #ffffff;
                }
            }
            .visit_analysis_data {
                flex: 1;
                height: 100%;
                margin-left: 170px;
                .visit_analysis_data_line {
                    // height: 50%;
                    padding: 20px;
                    background: #ffffff;
                    .time_check {
                        width: 280px;
                        height: 40px;
                        display: flex;
                        border-radius: 5px;
                        border: 1px solid #ccc;
                        div {
                            flex: 1;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-right: 1px solid #ccc;
                        }
                        div:nth-last-of-type(1) {
                            border-right: 0px;
                        }
                        .acc_time {
                            background: #3a85fc;
                            color: #fff;
                        }
                    }
                    .serch_big_box {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        .info {
                            width: 23%;
                            margin-top: 20px;
                            margin-left: 2%;
                            display: flex;
                            align-items: center;
                            .demonstration {
                                // margin-right: 10px;
                                width: 80px;
                                min-width: 42px;
                            }
                            .el-cascader {
                                height: 32px;
                                line-height: 32px;
                                .el-input {
                                    height: 32px;
                                    line-height: 32px;
                                    input {
                                        height: 32px !important;
                                    }
                                }
                                .el-cascader__tags {
                                    flex-wrap: nowrap;
                                }
                            }
                            .el-input__inner {
                                height: 32px;
                                line-height: 32px;
                            }
                            .el-input__icon {
                                line-height: 34px;
                            }
                            .el-input {
                                // width: 240px;
                                width: 210px;
                            }
                            .serch {
                                width: 70px;
                                height: 32px;
                                background: #3a85fc;
                                color: #fff;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 2px;
                            }
                            .reset {
                                width: 70px;
                                height: 32px;
                                background: #fff;
                                border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
                                color: rgba(0, 0, 0, 0.6);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 2px;
                                margin-left: 20px;
                            }
                        }
                        .info:nth-of-type(4n + 1) {
                            margin-left: 0;
                        }
                    }
                    .data_echarts {
                        width: 100%;
                        height: 300px;
                        .mycharts {
                            width: 100%;
                            height: 300px;
                        }
                    }
                }
                .visit_analysis_data_table {
                    margin-top: 10px;
                    width: 100%;
                    background: #ffffff;
                    min-width: 1000px;
                    max-width: 1440px;
                    .export {
                        width: 100%;
                        height: 60px;
                        opacity: 1;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 20px;
                        padding-right: 40px;
                        .export_bottom {
                            width: 80px;
                            height: 32px;
                            background: #3a85fc;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            line-height: 19px;
                            color: #ffffff;
                            opacity: 1;
                            border-radius: 4px;
                            cursor: pointer;
                        }
                        .all_num {
                            margin-left: 20px;
                            display: inline-block;
                            div {
                                display: inline-block;
                                margin-right: 20px;
                            }
                        }
                    }
                    .table {
                        margin: 0 20px;
                        .el-table .el-table__fixed {
                            height: auto !important;
                            bottom: 16px;
                        }
                    }
                    .block {
                        margin: 0 20px;
                        margin-top: 20px;
                        display: flex;
                        justify-content: flex-end;
                    }
                }
            }
            .history_box_dep {
                background: #ffffff;
                width: 150px;
                margin-right: 10px;
                padding-top: 20px;
                height: 100%;
                max-height: 800px;
                position: absolute;
                top: 80px;
                left: 270px;
                div {
                    // height: 45px;
                    line-height: 45px;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 400;
                    color: #595959;
                    opacity: 1;
                    cursor: pointer;
                }
                div:hover {
                    background: #3a85fc;
                    color: #ffffff;
                }
                .active_dep {
                    background: #3a85fc;
                    color: #ffffff;
                }
            }
        }
    }
</style>