<template>
  <div class="explain_sm">
    <el-main>
      <div class="explain_meun" v-if="meun.length > 0">
        <div class="meun_box" v-for="(item, index) in meun" :key="index">
          <div class="meun_one">
            <div
              :class="
                meun_one_id == item.id ? 'meun_tit meun_tit_check' : 'meun_tit'
              "
            >
              <span style="width: 80%" @click.stop="click_one(item)">
                {{ item.name }}
              </span>
              <i
                class="el-icon-caret-right"
                @click.stop="open(item)"
                v-if="!item.is_check"
                style="font-size: 20px"
              ></i>
              <i
                class="el-icon-caret-bottom"
                @click.stop="colse(item)"
                v-if="item.is_check"
                style="font-size: 20px"
              ></i>
            </div>
            <div
              :class="
                meun_two_id == itm.id ? 'meun_two meun_tit_check' : 'meun_two '
              "
              v-for="(itm, idx) in item.data"
              :key="idx"
              @click="click_two(item, itm)"
              v-if="item.is_check"
            >
              {{ itm.name }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="wang_edit"
        :style="meun.length > 0 ? 'width: 80%;' : 'width: 100%;'"
      >
        <div class="edit_box">
          <div class="wang" v-show="meun.length > 0">
            <div class="wang_editor">
              <div ref="toolbar" class="wang_toolbar"></div>
              <div ref="editor" class="wang_text"></div>
            </div>
          </div>
          <div
            v-if="meun.length == 0"
            style="text-align: center; margin: 100px auto; color: #999"
          >
            暂无说明
          </div>
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
import E from "wangeditor";
import { oneLevel, list, detail } from "../../api/User/explain";
export default {
  data() {
    return {
      meun: [],
      meun_one_id: "", //一级id
      meun_two_id: "", //二级id
      active: "", //唯一选中 右侧编辑框展示内容
      editor: null,
      info_: null,
    };
  },
  created() {

    oneLevel().then((res) => {
      if (res.data.code == 200) {
        let list = res.data.data;
        list.forEach((item) => {
          item.is_check = false;
        });
        this.meun = list;
        if (list.length>0) {
        this.getdetail({
          explain_grade_id: list[0].id,
        });
        this.meun_one_id = list[0].id;
        }
      }
    });
  },
  watch: {
    watch: {
      isClear(val) {
        // 触发清除文本域内容
        if (val) {
          this.editor.txt.clear();
          this.info_ = null;
        }
      },
    },
  },
  mounted() {
    this.seteditor();
    this.editor.txt.html("");
  },
  methods: {
    seteditor() {
      this.editor = new E(this.$refs.toolbar, this.$refs.editor);
      this.editor.config.uploadImgShowBase64 = false; // base 64 存储图片
      this.editor.config.uploadImgServer = "/api/api/uploadFile"; // 配置服务器端地址
      this.editor.config.uploadImgHeaders = {
        token: JSON.parse(localStorage.getItem("token")).token,
      }; // 自定义 header
      this.editor.config.uploadFileName = "file"; // 后端接受上传文件的参数名
      this.editor.config.uploadImgMaxSize = 2 * 1024 * 1024; // 将图片大小限制为 2M
      this.editor.config.uploadImgMaxLength = 1; // 限制一次最多上传 3 张图片
      this.editor.config.uploadImgTimeout = 3 * 60 * 1000; // 设置超时时间
      this.editor.config.height = 100;
      // 配置菜单
      this.editor.config.menus = [
        // "head", // 标题
        // "bold", // 粗体
        // "fontSize", // 字号
        // "fontName", // 字体
        // "italic", // 斜体
        // "underline", // 下划线
        // "strikeThrough", // 删除线
        // "foreColor", // 文字颜色
        // "backColor", // 背景颜色
        // "link", // 插入链接
        // "list", // 列表
        // "justify", // 对齐方式
        // "quote", // 引用
        // "emoticon", // 表情
        // "image", // 插入图片
        // "table", // 表格
        // //   'video', // 插入视频
        // "code", // 插入代码
        // "undo", // 撤销
        // "redo", // 重复
        // "fullscreen", // 全屏
      ];
      this.editor.config.uploadImgHooks = {
        fail: (xhr, editor, result) => {
          // 插入图片失败回调
        },
        success: (xhr, editor, result) => {
          // 图片上传成功回调
        },
        timeout: (xhr, editor) => {
          // 网络超时的回调
        },
        error: (xhr, editor) => {
          // 图片上传错误的回调
        },
        customInsert: (insertImg, result, editor) => {
          // 图片上传成功，插入图片的回调
          //result为上传图片成功的时候返回的数据，这里我打印了一下发现后台返回的是data：[{url:"路径的形式"},...]
          // console.log(result.data[0].url)
          //insertImg()为插入图片的函数
          //循环插入图片
          // for (let i = 0; i < 1; i++) {
          let url = result.data.file_url;
          insertImg(url);
          // }
        },
      };
      this.editor.config.onchange = (html) => {
        this.info_ = html; // 绑定当前逐渐地值
        this.$emit("change", this.info_); // 将内容同步到父组件中
      };
      // 创建富文本编辑器
      this.editor.create();
      this.editor.disable(); //禁止编辑
    },
    getdetail(data) {
      detail(data).then((res) => {
        if (res.data.code == 200) {
          this.editor.txt.html(
            `${
              res.data.data.explain
                ? res.data.data.explain.explain
                : "暂无详情说明"
            }`
          );
        }
      });
    },
    getlist(data) {
      list(data).then((res) => {
        if (res.data.code == 200) {
          let list = this.meun;
          list.forEach((item) => {
            if (item.id == data.parent_id) {
              item.data = res.data.data.list;
              item.is_check = true
            }
          });
          this.meun = list;
        }
      });
    },
    click_one(item) {
      this.getdetail({
        explain_grade_id: item.id,
      });
      this.meun_one_id = item.id;
      this.meun_two_id = "";
    },
    click_two(item, itm) {
      this.getdetail({
        explain_grade_id: itm.id,
      });
      this.meun_one_id = "";
      this.meun_two_id = itm.id;
    },
    open(item) {
      this.getlist({
        parent_id: item.id,
      });
    },
    colse(item) {
      let list = this.meun;
      list.forEach((itm) => {
        if (item.id == itm.id) {
          item.is_check = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
div {
  box-sizing: border-box;
}
.explain_sm {
  flex: 1;
  .el-main {
    background: #eef5f9;
    padding: 20px;
    display: flex;
    width: 100%;
    height: 100%;
    .explain_meun {
      width: 20%;
      background: #fff;
      border: 1px solid #ccc;
      padding-top: 20px;
      overflow: auto;
      .meun_box {
        height: auto;
        .meun_one {
          height: auto;
          padding-left: 20px;
          .meun_tit {
            cursor: pointer;
            height: 40px;
            width: 100%;
            padding-right: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .meun_tit_check {
            color: #3a85fc;
          }
          .meun_two:hover,
          .meun_tit:hover {
            color: #3a85fc;
          }
          .meun_two {
            cursor: pointer;
            height: 40px;
            padding-left: 20px;
            line-height: 40px;
          }
        }
      }
    }
    .wang_edit {
      width: 80%;
      background: #f2f2f2;
      //   padding: 20px 30px;
      .edit_box {
        background: #fff;
        width: 100%;
        height: 100%;
        border: 1px solid #f5f5f5;
        .wang {
          // padding-top: 20px;
          width: 100%;
          height: 100%;

          .wang_editor {
            height: 100%;
            width: 100%;
            margin: 0 auto;
            position: relative;
            z-index: 0;
            padding: 0;
            .wang_toolbar {
              border: 1px solid #ccc;
            }
            .wang_text {
              border: 1px solid #ccc;
              min-height: 500px;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>