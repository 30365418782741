<template>
  <!-- 跟进代办 -->
  <div class="all_agency">
    <div class="all_agency_head">
      <div class="all_agency_head_tit">
        <div
          :class="
            active_wt == 1
              ? 'all_agency_head_tit_active'
              : 'all_agency_head_tit_one'
          "
          @click="active_wt_plan(1)"
        >
          跟进待办<i>({{ plan_total }})</i>
        </div>
        <div
          :class="
            active_wt == 2
              ? 'all_agency_head_tit_active'
              : 'all_agency_head_tit_one'
          "
          @click="active_wt_plan(2)"
          style="margin-left: 20px"
        >
          消息中心<i>({{ new_total }})</i>
        </div>
      </div>
      <div class="all_agency_head_more" @click="look_all">
        查看全部<i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="all_agency_box" v-if="active_wt == 1">
      <div
        class="all_agency_box_line"
        v-for="(item, index) in plan_List"
        :key="index"
      >
        <div class="all_agency_box_line_l">
          <img src="../../assets/img/all_agency_line.png" alt="" />
          <div>{{ item.plan }}</div>
        </div>
        <div class="all_agency_box_line_r">
          {{ item.time }}
        </div>
      </div>
      <div
        v-if="plan_List.length == 0"
        style="margin: 50px auto; color: #ccc; text-align: center"
      >
        暂无跟进代办
      </div>
    </div>
    <div class="all_agency_box" v-if="active_wt == 2">
      <div
        class="all_agency_box_line"
        v-for="(item, index) in news_List"
        :key="index"
      >
        <div class="all_agency_box_line_l">
          <img src="../../assets/img/all_agency_news.png" alt="" />
          <div>{{ item.content }}</div>
        </div>
        <div class="all_agency_box_line_r">
          {{ item.time }}
        </div>
      </div>
      <div
        v-if="news_List.length == 0"
        style="margin: 50px auto; color: #ccc; text-align: center"
      >
        暂无最新消息
      </div>
    </div>
  </div>
</template>

<script>
import { planList, newsList } from "../../api/User/home";
export default {
  data() {
    return {
      active_wt: "1",
      plan_total: 0,
      new_total: 0,
      plan_List: [],
      news_List: [],
    };
  },
  created() {
    this.getplan();
    this.getNews();
  },
  methods: {
    active_wt_plan(i) {
      this.active_wt = i;
      if (i == 1) {
        this.getplan();
      } else {
        this.getNews();
      }
    },
    getplan() {
      planList().then((res) => {
        if (res.data.code == 200) {
          this.plan_total = res.data.data.total;
          this.plan_List = res.data.data.list;
        }
      });
    },
    getNews() {
      newsList().then((res) => {
        if (res.data.code == 200) {
          this.new_total = res.data.data.total;
          this.news_List = res.data.data.list;
        }
      });
    },
    look_all() {
      if (this.active_wt == 1) {
        this.$router.push("/Marketing/Account");
        localStorage.setItem("isSelect", "1-1");
      } else {
        this.$router.push("/Marketing/achievement_center");
        localStorage.setItem("isSelect", "4-1");
      }
    },
  },
};
</script>

<style lang="scss">
.all_agency {
  // width: 33%;
  height: 407px;
  background: #ffffff;
  opacity: 1;
  border-radius: 6px;
  padding: 0 26px;
  display: flex;
  flex-direction: column;
  .all_agency_head {
    padding-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .all_agency_head_tit {
      display: flex;
      align-items: center;
      div {
        cursor: pointer;
      }
      .all_agency_head_tit_one {
        font-size: 18px;
        font-weight: 400;
        line-height: 31px;
        opacity: 1;
        color: #000000;
        i {
          color: #ff3f38;
          margin-left: 10px;
        }
      }
      .all_agency_head_tit_active {
        font-size: 18px;
        font-weight: bold;
        line-height: 31px;
        color: rgba(0, 0, 0, 0.8);
        opacity: 1;
        i {
          color: #ff3f38;
          margin-left: 10px;
        }
      }
    }
    .all_agency_head_more {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.4);
      opacity: 1;
      cursor: pointer;
      i {
        margin-left: 5px;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 0px;
  }
  .all_agency_box {
    overflow-y: auto;
    .all_agency_box_line {
      height: 60px;
      width: 100%;
      border-bottom: 1px solid #f2f2f2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .all_agency_box_line_l {
        display: flex;
        flex: 1;
        height: 100%;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        opacity: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        img {
          width: 17px;
          height: 15px;
          margin-right: 15px;
        }
        div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .all_agency_box_line_r {
        width: 71px;
        height: 25px;
        background: #e6f0ff;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: #3a85fc;
        text-align: center;
        line-height: 25px;
        border-radius: 13px;
      }
    }
  }
}
</style>