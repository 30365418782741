<template>
  <div class="Achievement_center">
    <Arrival />
  </div>
</template>

<script>
import Arrival from "./Arrival";
export default {
  components: {
    Arrival: Arrival,
  },
  data() {
    return {
      isSelect: "4-1",
    };
  },
  created() {

    let isSelect = localStorage.getItem("isSelect");
    this.isSelect = isSelect;
  },
};
</script>

<style lang="scss">
.Achievement_center {
  flex: 1 1 0%;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #eef5f9;
  padding: 20px;
}
</style>