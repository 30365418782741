<template>
  <div class="login_bg">
    <div class="login_show">
      <div class="login_logo">
        <img src="../assets/img/logo_login.png" alt="" />
      </div>
      <div class="login_input" v-show="!isForgetPwd">
        <div class="input_box">
          <div class="login_flex">
            <img src="../assets/img/login_username.png" alt="" />
            <el-input v-model="name" placeholder="请输入账户名/电话" oninput="value=value.replace(/[^\d]/g,'')" maxlength="11"> </el-input>
          </div>
          <div class="login_trips" v-if="isName">请输入账户名/电话</div>
          <div :class="name == '' ? 'input_border' : 'input_border_active'"></div>
        </div>
        <div class="input_box" style="margin-bottom: 0">
          <div class="login_flex">
            <img src="../assets/img/login_password.png" alt="" />
            <el-input v-model="password" placeholder="请输入密码" @keyup.enter.native="handleLogin" show-password></el-input>
          </div>
          <div class="login_trips" v-if="isPassword">请输入密码</div>
          <div :class="password == '' ? 'input_border' : 'input_border_active'"></div>
        </div>
        <div class="forget-pwd" @click="handlePwd">忘记密码?</div>
      </div>
      <div class="login" @click="handleLogin" v-show="!isForgetPwd">登录</div>

      <!--忘记密码-->
      <div class="forget-pwd-box" v-show="isForgetPwd">
        <el-form :model="ruleForm" label-position="top" :rules="rules" ref="ruleForms" label-width="70px" class="demo-ruleForm">
          <el-form-item prop="phone">
            <div slot="label" class="form-item-custom">
              <img class="phone" src="../assets/img/login/phone.png" alt="" />
              <span>手机号</span>
            </div>
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>

          <el-form-item prop="captcha">
            <div slot="label" class="form-item-custom">
              <img class="captcha" src="../assets/img/login/graphical.png" alt="" />
              <span>图形验证码</span>
            </div>
            <img :src="captchaImg" alt="" class="captcha-img" @click="getImgCode" />
            <el-input v-model="ruleForm.captcha"></el-input>
          </el-form-item>

          <el-form-item prop="code">
            <div slot="label" class="form-item-custom">
              <img class="message" src="../assets/img/login/message.png" alt="" />
              <span>短信验证码</span>
            </div>
            <button class="message-btn" :disabled="codeDisable" @click="getCode">{{ codeText }}</button>
            <el-input v-model="ruleForm.code"></el-input>
          </el-form-item>

          <el-form-item prop="pwd" style="margin-bottom: 20px">
            <div slot="label" class="form-item-custom">
              <img class="pwd" src="../assets/img/login/pwd.png" alt="" />
              <span>新密码</span>
            </div>

            <el-input v-model="ruleForm.pwd" password show-password></el-input>
          </el-form-item>

          <div class="back-login">
            <el-button type="text" @click="backLogin">前往登录</el-button>
          </div>

          <el-form-item class="forget-btn">
            <!-- <el-button @click="pwdVisible = false">取 消</el-button> -->
            <el-button class="forget-submit" type="primary" @click="submitPwd('ruleForms')">确 定</el-button>
          </el-form-item>
        </el-form>
      </div>

      <div class="login_mc" v-if="isMore"></div>
      <div class="login_Choose" v-if="isMore">
        <div class="login_choose_logo">
          <img src="../assets/img/login_choose_logo.png" alt="" />
        </div>
        <div class="login_choose_tit">你可以选择以下部门登录</div>
        <div style="height: 80%; overflow: auto" class="choose_box_big">
          <div
            :class="isCheck == index ? 'choose_box_check' : 'choose_box'"
            v-for="(item, index) in companyarr"
            :key="index"
            @click="handleCheck(index, item.company.id, item.company.name)"
          >
            {{ item.company.name }}
          </div>
        </div>
        <div class="choose_bottom">
          <div class="choose_no" @click="handleNo">取消</div>
          <div class="choose_ok" @click="handleOk">登录</div>
        </div>
      </div>
    </div>

    <el-dialog title="找回密码" :visible.sync="pwdVisible" custom-class="search-pwd" width="500px" @close="handleClosePwd">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForms" label-width="70px" class="demo-ruleForm">
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="ruleForm.phone" size="small"></el-input>
        </el-form-item>

        <el-row :gutter="30">
          <el-col :span="10">
            <el-form-item label="验证码" prop="code">
              <el-input v-model="ruleForm.code" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" style="margin-top: 5px">
            <el-button size="small" :disabled="codeDisable" plain @click="getCode">{{ codeText }}</el-button>
          </el-col>
        </el-row>

        <el-form-item label="新密码" prop="pwd">
          <el-input v-model="ruleForm.pwd" size="small" password show-password></el-input>
        </el-form-item>

        <!-- <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="pwdVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitPwd('ruleForms')" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { userLogin, choiceCompany } from "../api/User/login";
import { getVerificationCode, getNewPwd, getCaptcha } from "../api/User/user";
let token = localStorage.getItem("token");
export default {
  data() {
    return {
      captchaImg: "",
      isForgetPwd: false,
      codeTimer: "",
      codeDisable: false,
      codeText: "发送验证码",
      ruleForm: {
        phone: "",
        captcha: "",
        code: "",
        pwd: "",
        key: "",
      },
      rules: {
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        captcha: [{ required: true, message: "请输入图形验证码", trigger: "blur" }],
        pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      pwdVisible: false,
      name: "",
      password: "",
      isName: false,
      isPassword: false,
      isCheck: null,
      isMore: false, //多部门 蒙层
      companyarr: [{ name: "" }, { name: "" }], //多部门数组
      company_id: "", //多部门选择的部门id
      codeNumber: "",
      company_name: "",
    };
  },
  created() {
    if (token) {
      this.$router.push("/Finance");
    }
  },
  mounted() {
    this.getImgCode();
  },
  methods: {
    getImgCode() {
      getCaptcha().then((res) => {
        this.captchaImg = res.data.data.img;
        this.ruleForm.key = res.data.data.key;
      });
    },
    backLogin() {
      clearInterval(this.codeTimer);
      this.codeText = "发送验证码";
      this.codeDisable = false;
      this.$refs["ruleForms"].resetFields();
      this.isForgetPwd = false;
    },
    //获取验证码
    getCode() {
      //
      let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg_tel.test(this.ruleForm.phone)) {
        this.$message({
          message: "请输入正确的手机号",
          type: "error",
        });
        return;
      }
      if (!this.ruleForm.captcha) {
        this.$message({
          message: "请输入图形验证码",
          type: "error",
        });
        return;
      }

      this.codeNumber = 120;

      this.codeTimer = setInterval(() => {
        if (this.codeNumber == 0) {
          clearInterval(this.codeTimer);
          this.codeText = "发送验证码";
          this.codeDisable = false;
        } else {
          this.codeNumber--;
          this.codeText = `${this.codeNumber}s`;
          this.codeDisable = true;
        }
      }, 1000);
      let data = {
        phone: this.ruleForm.phone,
        key: this.ruleForm.key,
        captcha: this.ruleForm.captcha,
      };
      getVerificationCode(data).then((res) => {
        if (!res) {
          this.codeNumber = 120;
          this.getImgCode();
          clearInterval(this.codeTimer);
          this.codeText = "发送验证码";
          this.codeDisable = false;
        }
        if (res.code == 200) {
          this.$message({
            message: "验证码发送成功",
            type: "success",
          });
        }
      });
    },
    handleClosePwd() {
      clearInterval(this.codeTimer);
      this.codeText = "发送验证码";
      this.codeDisable = false;
      this.$refs["ruleForms"].resetFields();
    },
    //找回密码
    handlePwd() {
      this.isForgetPwd = true;
      // this.pwdVisible = true;
    },
    submitPwd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            phone: this.ruleForm.phone,
            code: this.ruleForm.code,
            password: this.ruleForm.pwd,
          };
          getNewPwd(data).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              clearInterval(this.codeTimer);
              this.codeText = "发送验证码";
              this.codeDisable = false;
              this.$refs["ruleForms"].resetFields();
              this.isForgetPwd = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    handleLogin() {
      //登录
      let { name, password } = this;
      if (name == "") {
        this.isName = true;
      } else {
        this.isName = false;
      }
      if (password == "") {
        this.isPassword = true;
      } else {
        this.isPassword = false;
      }
      if (this.isName && this.isPassword) {
        return;
      } else {
        userLogin({
          username: this.name,
          password: this.password,
        }).then((res) => {
          if (res.data.code == 200) {
            if (res.data.data.is_company == "") {
              localStorage.removeItem("company_info");
              //没有多部门  直接登录
              this.$message({
                message: res.data.message,
                type: "success",
              });
              let newpath = "";
              if (res.data.data.role_permissions) {
                newpath = res.data.data.role_permissions[0].permissions;
              }
              setTimeout(() => {
                this.$router.push(`/newDataIndex`);
                // this.$router.push(`/all_can_see`);
              }, 1000);
            } else {
              //多部门
              this.companyarr = res.data.data.is_company;
              this.isMore = true;
            }
            let obj = {
              is_show: true,
              url: res.data.data.start_img,
            };
            localStorage.setItem("start_img", JSON.stringify(obj)); //保存启动页图片信息
            localStorage.setItem("user", JSON.stringify(res.data.data)); //本地存储
            localStorage.setItem("token", JSON.stringify(res.data.data)); //本地存储
          }
          if (res.data.code == 400) {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      }
    },
    handleCheck(e, id, name) {
      this.isCheck = e;
      this.company_id = id;
      this.company_name = name;
    },
    handleNo() {
      //多部门 取消登录
      this.isMore = false;
      localStorage.clear();
      localStorage.removeItem("token");
    },
    handleOk() {
      //多部门 确定登录
      if (this.company_id == "") {
        this.$message({
          message: "请选择部门",
          type: "error",
        });
        return;
      }
      localStorage.setItem(
        "company_info",
        JSON.stringify({
          id: this.company_id,
          name: this.company_name,
        })
      );
      choiceCompany({
        choice_company_id: this.company_id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          let obj = {
            is_show: true,
            url: res.data.data.start_img,
          };
          localStorage.setItem("start_img", JSON.stringify(obj)); //保存启动页图片信息
          localStorage.setItem("token", JSON.stringify(res.data.data)); //本地存储
          let newpath = "";
          if (res.data.data.role_permissions) {
            newpath = res.data.data.role_permissions[0].permissions;
          }

          setTimeout(() => {
            this.$router.push(`/newDataIndex`);
          }, 1000);
        }
        if (res.data.code == 400) {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
body {
  height: 100%;
  width: 100%;
}

.login_bg {
  background: url(http://domssmaster.oss-cn-chengdu.aliyuncs.com/master-resources/background-img-doms.png) no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  width: 100%;
  height: 100%;
  position: relative;
  background-size: auto;
  min-height: 900px;
}

.login_logo {
  width: 26%;
  height: 33px;
  /* background: #999; */
  margin: 50px auto;
  margin-bottom: 17%;
}

.login_show {
  width: 500px;
  min-height: 700px;
  background: #ffffff;
  opacity: 1;
  border-radius: 20px;
  position: absolute;
  top: 17%;
  right: 12%;
  box-sizing: border-box;
}

.input_box {
  width: 70%;
  height: 73px;
  margin: 10% auto;
}

.forget-pwd {
  width: 70%;
  margin: 0 auto;
  color: #409eff;
  text-align: right;
  cursor: pointer;
}

.login_flex {
  display: flex;
  align-items: center;
  box-sizing: border-box;

  .el-input__inner {
    border: none !important;
    font-size: 16px;
  }
}

.input_border {
  width: 100%;
  height: 2px;
  background: #f5f5f5;
  margin-top: 15px;
}

.input_border_active {
  width: 100%;
  height: 2px;
  background: #a4ceff;
  margin-top: 15px;
}

::v-deep .login_input.el-input__inner {
  border: 0;
}

.login {
  /* width: 350px; */
  width: 67%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3a85fc;
  border-radius: 4px;
  font-size: 20px;
  color: #ffffff;
  margin: 190px auto;
  margin-bottom: 0;
  cursor: pointer;
}

.login_trips {
  color: red;
  font-size: 14px;
}

.login_mc {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
}

.choose_box_big {
  div {
    cursor: pointer;
  }
}

.choose_box_big > div:hover {
  border: 1px solid #3a85fc;
}

.login_Choose {
  // height: 400px;
  height: 100%;
  width: 100%;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  border-radius: 10px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  padding-bottom: 70px;

  .login_choose_logo {
    width: 80%;
    margin: 0 auto;
    margin-top: 40px;
  }

  .login_choose_tit {
    font-size: 16px;
    width: 80%;
    margin: 20px auto;
  }

  .choose_box {
    width: 80%;
    margin: 20px auto;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  .choose_box_check {
    width: 80%;
    margin: 20px auto;
    height: 40px;
    border: 1px solid #3a85fc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    background: #f3f8ff;
  }
}

.choose_bottom {
  // border-top: 1px solid #ccc;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.choose_bottom > div {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  cursor: pointer;
  border-radius: 4px;
}

.choose_bottom > div:first-child {
  background: #fff;
  color: #3a85fc;
  border: 1px solid #3a85fc;
  margin-right: -80px;
}

.choose_bottom > div:last-child {
  background: #3a85fc;
  color: #fff;
}

.forget-pwd-box {
  width: 80%;
  margin: auto;

  .el-form-item__label {
    &::before {
      content: "" !important;
      width: 0;
      height: 0;
    }
  }

  .el-form-item__content {
    position: relative;

    .captcha-img {
      width: 120px;
      height: 36px;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }

    .message-btn {
      width: 80px;
      height: 30px;
      color: #54a4ff;
      font-size: 14px;
      background: #dfecff;
      cursor: pointer;
      opacity: 1;
      border-radius: 2px;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }

  .el-form-item {
    margin-bottom: 0;

    .el-form-item__label {
      font-size: 15px;
    }
  }

  .el-form-item__label {
    line-height: 20px;
    padding: 0 !important;
  }

  .form-item-custom {
    display: flex;
    align-content: center;

    .phone {
      width: 17px;
      height: 23px;
      margin-right: 8px;
      margin-top: -1px;
    }

    .captcha {
      width: 22px;
      height: 24px;
      margin-top: -1px;
      margin-right: 8px;
    }

    .message {
      width: 21px;
      height: 16px;
      margin-top: 3px;
      margin-right: 8px;
      margin-left: 3px;
    }

    .pwd {
      margin-right: 10px;
      margin-left: 4px;
    }
  }

  .el-input__inner {
    border: none;
    border-bottom: 1px solid #dcdfe6;
    border-radius: 0;
  }
}

.forget-btn {
  margin-top: 50px;
}

.forget-submit {
  width: 100%;
}

.back-login {
  text-align: right;

  button {
    padding-top: 0;
  }
}

.search-pwd {
  border-radius: 10px !important;
}

.search-pwd .el-dialog__header {
  border-bottom: solid 1px #efefef;
  padding: 0;
  height: 48px;
  line-height: 48px;
  text-align: center;

  .el-dialog__title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #262626;
  }

  .el-dialog__headerbtn {
    top: 15px;
  }

  .el-dialog__headerbtn {
    .el-dialog__close {
      color: #8c8c8c;
    }
  }
}
</style>
