<template>
  <div
    style="flex: 1"
    class="human_child_bigbox"
  >
    <el-main>
      <div class="human_info">
        <!-- 左侧组织架构 -->
        <div class="human_left">
          <!-- 搜索 -->
          <div class="human_serch">
            <div class="human_serch_child">
              <img
                src="../../assets/img/serch_icon.png"
                alt=""
              />
              <input
                type="text"
                placeholder="搜索姓名/部门/电话"
                v-model="serch_val"
                @focus="focus"
              />
              <i
                class="el-icon-error"
                v-if="serch_list_show"
                @click="blur"
              ></i>
            </div>
            <div
              class="human_serch_list"
              v-if="serch_list_show"
            >
              <div class="fir_compony_list">
                团队列表：
                <div
                  v-for="(item, index) in serch_compony_list"
                  :key="index"
                  class="pointer"
                  @click="
                    change_active(
                      item.id,
                      item.parent_id,
                      item.depth,
                      item.name,
                      item.type,
                      item.is_ranking
                    )
                  "
                  style="border-bottom: 1px solid #ccc"
                >
                  <i class="el-icon-share"></i>
                  {{ item.name }}
                </div>
                <div
                  v-if="serch_compony_list.length == 0"
                  style="text-align: center"
                >
                  目前暂时没有噢~
                </div>
              </div>
              <div class="sec_compony_list">
                用户列表：
                <div
                  class="pointer"
                  v-for="(item, index) in serch_user_list"
                  :key="index"
                  style="border-bottom: 1px solid #ccc"
                  @click="show_user_drawer(item, 1)"
                >
                  <i class="el-icon-s-custom"></i>
                  {{ item.name }}
                </div>
                <div
                  v-if="serch_user_list.length == 0"
                  style="text-align: center"
                >
                  目前暂时没有噢~
                </div>
              </div>
            </div>
          </div>
          <!-- 第一层 噩梦才开始 -->
          <div class="human_framework">
            <div
              class="frameworkinfo"
              :class="[check_id == left_list.id ? 'active_title' : '']"
            >
              <i
                class="el-icon-caret-right"
                style="cursor: pointer"
                v-if="!left_list.arrow"
                @click="left_list.arrow = !left_list.arrow"
              ></i>
              <i
                class="el-icon-caret-bottom"
                style="cursor: pointer"
                v-if="left_list.arrow"
                @click="left_list.arrow = !left_list.arrow"
              ></i>
              <span @click="
                  change_active(
                    left_list.id,
                    left_list.parent_id,
                    left_list.depth,
                    left_list.name,
                    left_list.type,
                    left_list.is_ranking
                  )
                ">{{ left_list.name }} ({{ left_list.count_user }})</span>
            </div>
            <div
              v-show="left_list.arrow"
              class="framework_firchild"
              v-for="(item_1, index_1) in left_list.data"
              :key="index_1"
            >
              <div
                class="frameworkinfo"
                :class="[check_id == item_1.id ? 'active_title' : '']"
              >
                <i
                  class="el-icon-caret-right"
                  style="cursor: pointer"
                  v-if="!item_1.arrow"
                  @click="item_1.arrow = !item_1.arrow"
                ></i>
                <i
                  class="el-icon-caret-bottom"
                  style="cursor: pointer"
                  v-if="item_1.arrow"
                  @click="item_1.arrow = !item_1.arrow"
                ></i>

                <span @click="
                    change_active(
                      item_1.id,
                      item_1.parent_id,
                      item_1.depth,
                      item_1.name,
                      item_1.type,
                      item_1.is_ranking
                    )
                  ">{{ item_1.name }} ({{ item_1.count_user }})</span>
              </div>
              <div
                v-show="item_1.arrow"
                class="framework_secchild"
                v-for="(item_2, index_2) in item_1.data"
                :key="index_2"
              >
                <div
                  class="frameworkinfo"
                  :class="[check_id == item_2.id ? 'active_title' : '']"
                >
                  <i
                    class="el-icon-caret-right"
                    style="cursor: pointer"
                    v-if="!item_2.arrow"
                    @click="item_2.arrow = !item_2.arrow"
                  ></i>
                  <i
                    class="el-icon-caret-bottom"
                    style="cursor: pointer"
                    v-if="item_2.arrow"
                    @click="item_2.arrow = !item_2.arrow"
                  ></i>
                  <span @click="
                      change_active(
                        item_2.id,
                        item_2.parent_id,
                        item_2.depth,
                        item_2.name,
                        item_2.type,
                        item_2.is_ranking
                      )
                    ">{{ item_2.name }} ({{ item_2.count_user }})
                  </span>
                </div>
                <div
                  v-show="item_2.arrow"
                  class="framework_thrchild"
                  v-for="(item_3, index_3) in item_2.data"
                  :key="index_3"
                >
                  <div
                    class="frameworkinfo"
                    :class="[check_id == item_3.id ? 'active_title' : '']"
                  >
                    <i
                      class="el-icon-caret-right"
                      style="cursor: pointer"
                      v-if="!item_3.arrow"
                      @click="item_3.arrow = !item_3.arrow"
                    ></i>
                    <i
                      class="el-icon-caret-bottom"
                      style="cursor: pointer"
                      v-if="item_3.arrow"
                      @click="item_3.arrow = !item_3.arrow"
                    ></i>
                    <span @click="
                        change_active(
                          item_3.id,
                          item_3.parent_id,
                          item_3.depth,
                          item_3.name,
                          item_3.type,
                          item_3.is_ranking
                        )
                      ">{{ item_3.name }} ({{ item_3.count_user }})</span>
                  </div>
                  <div
                    v-show="item_3.arrow"
                    class="framework_forchild"
                    v-for="(item_4, index_4) in item_3.data"
                    :key="index_4"
                  >
                    <div
                      class="frameworkinfo"
                      :class="[check_id == item_4.id ? 'active_title' : '']"
                    >
                      <i
                        class="el-icon-caret-right"
                        style="cursor: pointer"
                        v-if="!item_4.arrow"
                        @click="item_4.arrow = !item_4.arrow"
                      ></i>
                      <i
                        class="el-icon-caret-bottom"
                        style="cursor: pointer"
                        v-if="item_4.arrow"
                        @click="item_4.arrow = !item_4.arrow"
                      ></i>
                      <span @click="
                          change_active(
                            item_4.id,
                            item_4.parent_id,
                            item_4.depth,
                            item_4.name,
                            item_4.type,
                            item_4.is_ranking
                          )
                        ">{{ item_4.name }} ({{ item_4.count_user }})</span>
                    </div>
                    <div
                      v-show="item_4.arrow"
                      class="framework_forchild"
                      v-for="(item_5, index_5) in item_4.data"
                      :key="index_5"
                    >
                      <div
                        class="frameworkinfo"
                        :class="[check_id == item_5.id ? 'active_title' : '']"
                      >
                        <span @click="
                            change_active(
                              item_5.id,
                              item_5.parent_id,
                              item_5.depth,
                              item_5.name,
                              item_5.type,
                              item_5.is_ranking
                            )
                          ">{{ item_5.name }} ({{ item_5.count_user }})</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="human_right">
          <div class="human_first">
            {{ name }}
            <div
              @click="change_compony('')"
              v-if="all_power.is_change_company && edit_company"
            >
              编辑
            </div>
            <div
              @click="show_honor(0, 1)"
              v-if="all_power.is_change_company && edit_company"
              style="
                width: 70px;
                font-size: 14px;
                height: 20px;
                margin-left: 5px;
                border-radius: 0px;
                border: 1px solid #999;
                cursor: pointer;
                text-align: center;
                line-height: 20px;
                border-radius: 10px;
              "
            >
              荣誉管理
            </div>
          </div>
          <!-- 面包屑最难那快 -->
          <div class="human_bread">
            <div
              v-for="(item, index) in title_list"
              :key="index"
              @click="
                change_active(
                  item.id,
                  item.parent_id,
                  item.depth,
                  item.name,
                  item.type,
                  item.is_ranking
                )
              "
            >
              <span
                style="cursor: pointer"
                :style="index != title_list.length - 1 ? '' : 'color:#333;'"
              >{{ item.name }}</span>
              <i
                class="el-icon-arrow-right"
                v-if="index != title_list.length - 1"
              ></i>
            </div>
          </div>
          <!-- 标题 -->
          <div class="human_lower">
            <div class="lower_title">
              <i class="iconfont icon-tree"></i>
              下级部门
            </div>
          </div>
          <!-- 操作区 -->
          <div
            class="human_action"
            v-if="deep_show && all_power.is_add_company && nozzle_add_company"
          >
            <div
              class="pointer"
              @click="change_compony(1)"
            >添加子部门</div>
          </div>
          <!-- 部门列表  -->
          <div
            class="compony_list pointer"
            v-for="(item, index) in compony_list"
            :key="index"
            @click="
              change_active(
                item.id,
                item.parent_id,
                item.depth,
                item.name,
                item.type,
                item.is_ranking
              )
            "
          >
            <div>{{ item.name }} ({{ item.count }})</div>
            <i class="el-icon-arrow-right"></i>
          </div>
          <div
            style="text-align: center"
            v-if="!deep_show"
          >
            当前部门已是最小部门
          </div>
          <div class="human_lower">
            <div class="lower_title">
              <i class="el-icon-s-custom"></i>
              部门人员
            </div>
          </div>
          <div
            class="human_action_child"
            v-if="add_usershow && all_power.is_add_user && nozzle_add_user"
          >
            <div
              class="pointer"
              @click="show_user_drawer(null)"
              v-if="all_power.is_add_user"
            >
              添加成员
            </div>
          </div>
          <div class="human_list">
            <div class="human_list_title">
              <div class="human_listFir">姓名</div>
              <div class="human_listSec">职位</div>
              <div class="human_listThr">部门</div>
              <div class="human_listThr">管理部门</div>
              <div class="human_listFor">手机</div>
              <div class="human_listFiv">邮箱</div>
              <div
                class="human_listFiv"
                v-if="all_power.is_change_company && edit_company"
              >
                荣誉
              </div>
            </div>
            <div
              class="human_list_info pointer"
              v-for="(item, index) in user_list"
              :key="index"
              @click="show_user_drawer(item)"
            >
              <div class="human_listFir">{{ item.name }}</div>
              <div class="human_listSec">{{ item.position }}</div>
              <div class="human_listThr">{{ item.cname }}</div>
              <div
                class="human_listThr"
                v-if="item.status_position"
              >
                {{ item.manage_company }}
              </div>
              <div
                class="human_listThr"
                v-if="!item.status_position"
              ></div>
              <div class="human_listFor">{{ item.phone }}</div>
              <div class="human_listFiv">{{ item.email }}</div>
              <div
                class="human_listFiv"
                @click.stop="show_honor(item, 0)"
                v-if="all_power.is_change_company && edit_company"
                style="color: #3a85fc"
              >
                个人荣誉
              </div>
            </div>
          </div>
          <div
            style="text-align: center; margin-top: 10px"
            v-if="user_list.length == 0"
          >
            当前部门无成员
          </div>
          <!-- prev-click="prev"
          next-click="next" -->
          <el-pagination
            layout="total,prev, pager, next"
            @current-change="current"
            :total="total"
            :page-size="page_size"
            v-if="user_list.length > 0"
            :current-page="current_page"
            ref="pagination"
          >
          </el-pagination>
          <div class="human_lower">
            <div
              class="lower_title"
              style="margin-bottom: 10px"
            >
              <i class="el-icon-trophy"></i>
              管理人员
            </div>
          </div>
          <div class="human_list">
            <div class="human_list_title">
              <div class="human_listFir">姓名</div>
              <div class="human_listSec">职位</div>
              <div class="human_listThr">部门</div>
              <div class="human_listThr">管理部门</div>
              <div class="human_listFor">手机</div>
              <div class="human_listFiv">邮箱</div>
              <div
                class="human_listFiv"
                v-if="all_power.is_change_company && edit_company"
              >
                荣誉
              </div>
            </div>
            <div
              class="human_list_info pointer"
              v-for="(item, index) in leader_list"
              :key="index"
              @click="show_user_drawer(item)"
            >
              <div class="human_listFir">{{ item.name }}</div>
              <div class="human_listSec">{{ item.position }}</div>
              <div class="human_listThr">{{ item.cname }}</div>
              <div class="human_listThr">
                {{ item.manage_company }}
              </div>
              <div class="human_listFor">{{ item.phone }}</div>
              <div class="human_listFiv">{{ item.email }}</div>
              <div
                class="human_listFiv"
                @click.stop="show_honor(item, 0)"
                v-if="all_power.is_change_company && edit_company"
                style="color: #3a85fc"
              >
                个人荣誉
              </div>
            </div>
          </div>
          <div
            style="text-align: center; margin-top: 10px"
            v-if="leader_list.length == 0"
          >
            当前部门无领导
          </div>
        </div>
      </div>
      <!-- <el-drawer
        :visible.sync="drawer"
        :with-header="false"
        size="40%"
        class="human_drawer"
      > -->
      <!-- 部门 -->
      <!-- <div class="drawer_all" v-if="drawer_type == 2"> 
          <div class="drawer_title">{{ is_add ? "添加" : "编辑" }}部门</div>
          <div class="compony_detail">部门信息</div>
          <div class="drawer_compony_info">
            <span style="color: #ff0000">*</span>
            <div class="drawer_nice">部门名称</div>
            <el-input
              type="text"
              placeholder="请输入部门名称"
              v-model="truename"
              maxlength="10"
              show-word-limit
            />
          </div>
          <div class="drawer_compony_info">
            <span style="color: #ff0000">*</span>
            <div class="drawer_nice">是否销售部门</div>
            <el-select v-model="sell_type" placeholder="请选择">
              <el-option value="是"></el-option>
              <el-option value="否"></el-option>
            </el-select>
          </div>
          <div class="drawer_compony_info">
            <span style="color: #ff0000">*</span>
            <div class="drawer_nice">参与排名</div>
            <el-select v-model="compony_isjoin" placeholder="请选择">
              <el-option value="是"></el-option>
              <el-option value="否"></el-option>
            </el-select>
          </div>
          <div class="drawer_bottom">
            <div class="pointer" @click="check_detail" :class="[add_flag ? 'human_nosent':'']">确定</div>
            <div
              class="pointer"
              style="
                color: #ff4141;
                background: #ffffff;
                border: 1px solid #ff4141;
              "
              v-if="!is_add && all_power.is_del_company"
              @click="del_compony"
            >
              删除
            </div>
            <div
              class="pointer"
              style="
                border: 1px solid #c3c6ce;
                background: #ffffff;
                color: #414141;
              "
              @click="drawer = false"
            >
              取消
            </div>
          </div>
        </div> -->
      <!-- 个人 -->
      <!-- <div class="drawer_all" v-if="drawer_type == 1">
          <div class="drawer_title">
            {{ is_adduser == "" ? "编辑" : "添加" }}成员
          </div>
          <div class="drawer_compony_info">
            <span style="color: #ff0000">*</span>
            <div class="drawer_nice">姓名</div>
            <el-input
              type="text"
              placeholder="请输入人员姓名"
              v-model="username"
              maxlength="10"
              show-word-limit
              :disabled="is_serch"
            />
          </div>
          <div class="drawer_compony_info">
            <span style="color: #ff0000">*</span>
            <div class="drawer_nice">电话</div>
            <el-input
              type="text"
              placeholder="请输入人员电话"
              v-model="mobile"
              maxlength="11"
              show-word-limit
              :disabled="is_serch"
            />
          </div>
           <i
            class="drawer_compony_info"
            style="color: rgb(255, 0, 0); padding-left: 110px;margin-top:5px;margin-bottom:0px;"
            v-if="is_adduser"
            >(默认密码:123456)</i
          >
          <div class="drawer_compony_info">
            <span style="color: #ff0000">*</span>
            <div class="drawer_nice">职位</div>
            <el-select
              v-model="position"
              placeholder="请选择"
              :disabled="is_serch"
              @change="change_position"
            >
              <el-option
                v-for="(item, index) in position_list"
                :key="index"
                :value="item.id"
                :label="item.position"
                :disabled="is_serch"
              ></el-option>
            </el-select>
          </div>
          <div class="drawer_compony_info">
            <span style="color: #ff0000">*</span>
            <div class="drawer_nice">入职日期</div>
            <el-date-picker
              v-model="date"
              align="right"
              type="date"
              placeholder="选择日期"
              :picker-options="pickerOptions"
              @change="heihei"
              :disabled="is_serch"
            >
            </el-date-picker>
          </div>
         
          <div class="drawer_compony_info">
            <span style="color: #ff0000">*</span>
            <div class="drawer_nice">性别</div>

            <el-select v-model="sex" placeholder="请选择" :disabled="is_serch">
              <el-option
                v-for="(item, index) in sex_list"
                :key="index"
                :value="item.id"
                :label="item.name"
                :disabled="is_serch"
              ></el-option>
            </el-select>
          </div>
       
          <div class="drawer_compony_info">
            <span style="color: #ff0000">*</span>
            <div class="drawer_nice">参与排名</div>

            <el-select
              v-model="rank"
              placeholder="请选择"
              :disabled="is_serch"
              :style="is_serch ? 'cursor:not-allowed;' : ''"
            >
              <el-option
                v-for="(item, index) in ranking_list"
                :key="index"
                :value="item.id"
                :label="item.name"
                :disabled="is_serch"
                :style="is_serch ? 'cursor:not-allowed;' : ''"
              ></el-option>
            </el-select>
          </div>
           <div class="drawer_compony_info">
            <span style="color: #ff0000">*</span>
            <div class="drawer_nice">所属部门</div>
            <div
              class="humancompony_info pointer"
              @click="shadowshow(1)"
              :style="is_serch ? 'cursor:not-allowed;' : ''"
            >
              <div
                v-for="(item, index) in user_componey"
                :key="index"
                :style="is_serch ? 'cursor:not-allowed; ' : ''"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="drawer_compony_info" v-if="status!=0">
            <span style="color: #ff0000"></span>
            <div class="drawer_nice" style="margin-left:6px;">管理部门</div>
            <div
              class="humancompony_info pointer"
              @click="shadowshow(null)"
              :style="is_serch ? 'cursor:not-allowed;' : ''"
            >
              <div
                v-for="(item, index) in admin_true_componey"
                :key="index"
                :style="is_serch ? 'cursor:not-allowed; ' : ''"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="drawer_compony_info">
            <span style="color: #ff0000">*</span>
            <div class="drawer_nice">邮箱</div>
            <el-input
              type="text"
              placeholder="请输入人员邮箱"
              v-model="email"
              show-word-limit
              :disabled="is_serch"
            />
          </div>

          <div class="drawer_bottom">
            <div class="pointer" @click="checkuser" v-if="!is_serch" :class="[add_flag ? 'human_nosent':'']">确定</div>
            <div
              class="pointer"
              style="
                color: #ff4141;
                background: #ffffff;
                border: 1px solid #ff4141;
              "
              v-if="!is_adduser && all_power.is_del_user && !is_serch"
              @click="del_user"
            >
              删除
            </div>
            <div
              class="pointer"
              style="
                border: 1px solid #c3c6ce;
                background: #ffffff;
                color: #414141;
              "
              @click="drawer = false"
            >
              取消
            </div>
          </div>
        </div> -->
      <!-- </el-drawer> -->
      <!-- drawer = false -->
      <div
        class="human_xiugai_box"
        v-if="drawer"
        @click.stop=""
      >
        <div
          class="human_info"
          @click.stop="drawer = true"
        >
          <div
            class="drawer_title"
            v-if="drawer_type == 2"
          >
            {{ is_add ? "添加" : "编辑" }}部门
          </div>
          <div
            class="drawer_all"
            v-if="drawer_type == 2"
          >
            <div class="compony_detail">部门信息</div>
            <div class="drawer_compony_info">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">部门名称</div>
              <el-input
                type="text"
                placeholder="请输入部门名称"
                v-model="truename"
                maxlength="10"
                show-word-limit
              />
            </div>
            <div class="drawer_compony_info">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">是否销售部门</div>
              <el-select
                v-model="sell_type"
                placeholder="请选择"
              >
                <el-option value="是"></el-option>
                <el-option value="否"></el-option>
              </el-select>
            </div>
            <div
              class="drawer_compony_info"
              v-if="is_business"
            >
              <!-- <span style="color: #ff0000"></span> -->
              <div
                class="drawer_nice"
                style="margin-left: 6px"
              >选择事业线</div>
              <el-select
                v-model="business"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in business_arr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="drawer_compony_info">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">参与排名</div>
              <el-select
                v-model="compony_isjoin"
                placeholder="请选择"
              >
                <el-option value="是"></el-option>
                <el-option value="否"></el-option>
              </el-select>
            </div>
            <div class="drawer_compony_info">
              <span style="color: #ff0000" v-if="!is_add">*</span>
              <div class="drawer_nice" :style="is_add? 'margin-left:6px;':''">部门领导</div>
              <!-- <el-select v-model="compony_isjoin" placeholder="请选择">
                <el-option value="是"></el-option>
                <el-option value="否"></el-option>
              </el-select> -->
              <el-autocomplete
                v-model="leader"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入领导人名字后选择"
                @select="handleSelect"
              ></el-autocomplete>
            </div>
            <div
              class="drawer_compony_info"
              v-if="depth==1"
            >
              <span style="color: #ff0000;margin-left:6px;"></span>
              <div class="drawer_nice">查看全部数据</div>
              <el-select
                v-model="is_cansee_all"
                placeholder="请选择是否可以查看全部数据"
              >
                <el-option
                  key="1"
                  value='1'
                  label="是"
                ></el-option>
                <el-option
                  key="0"
                  value='0'
                  label="否"
                ></el-option>
              </el-select>
            </div>

          </div>
          <div class="drawer_bottom">
            <div
              class="pointer"
              @click.stop="check_detail"
              :class="[add_flag ? 'human_nosent' : '']"
            >
              确定
            </div>
            <div
              class="pointer"
              style="
                  color: #ff4141;
                  background: #ffffff;
                  border: 1px solid #ff4141;
                "
              v-if="!is_add && all_power.is_del_company"
              @click.stop="del_compony"
            >
              删除
            </div>
            <div
              class="pointer"
              style="
                  border: 1px solid #c3c6ce;
                  background: #ffffff;
                  color: #414141;
                "
              @click.stop="drawer = false"
            >
              取消
            </div>
          </div>
          <div
            class="drawer_title"
            v-if="drawer_type == 1"
          >
            {{ is_adduser == "" ? "编辑" : "添加" }}成员
          </div>
          <div
            class="drawer_all"
            v-if="drawer_type == 1"
          >
            <div class="drawer_compony_info">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">姓名</div>
              <el-input
                type="text"
                placeholder="请输入人员姓名"
                v-model="username"
                maxlength="10"
                show-word-limit
                :disabled="is_serch"
              />
            </div>
            <div class="drawer_compony_info">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">电话</div>
              <el-input
                type="text"
                placeholder="请输入人员电话"
                v-model="mobile"
                maxlength="11"
                show-word-limit
                :disabled="is_serch || !is_adduser"
              />
            </div>
            <i
              class="drawer_compony_info"
              style="
                color: rgb(255, 0, 0);
                padding-left: 110px;
                margin-top: 5px;
                margin-bottom: 0px;
              "
              v-if="is_adduser"
            >(默认密码:123456)</i>
            <div class="drawer_compony_info">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">职位</div>
              <div
                class="drawer_position pointer"
                @click="check_position"
                :style="position_name.name == '' ? 'color:#C0C4CC' : ''"
              >
                {{
                  position_name.name == "" ? "请选择职位" : position_name.name
                }}
              </div>
            </div>
            <div class="drawer_compony_info">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">入职日期</div>
              <el-date-picker
                v-model="date"
                align="right"
                type="date"
                placeholder="选择日期"
                :picker-options="pickerOptions"
                @change="heihei"
                :disabled="is_serch"
              >
              </el-date-picker>
            </div>

            <div class="drawer_compony_info">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">性别</div>

              <el-select
                v-model="sex"
                placeholder="请选择"
                :disabled="is_serch"
              >
                <el-option
                  v-for="(item, index) in sex_list"
                  :key="index"
                  :value="item.id"
                  :label="item.name"
                  :disabled="is_serch"
                ></el-option>
              </el-select>
            </div>
            <div class="drawer_compony_info">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">参与排名</div>
              <el-select
                v-model="rank"
                placeholder="请选择"
                :disabled="is_serch"
                :style="is_serch ? 'cursor:not-allowed;' : ''"
              >
                <el-option
                  v-for="(item, index) in ranking_list"
                  :key="index"
                  :value="item.id"
                  :label="item.name"
                  :disabled="is_serch"
                  :style="is_serch ? 'cursor:not-allowed;' : ''"
                ></el-option>
              </el-select>
            </div>
            <div class="drawer_compony_info">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">所属部门</div>
              <div
                class="humancompony_info pointer dd"
                @click.stop="shadowshow(1)"
                :style="is_serch ? 'cursor:not-allowed;' : ''"
              >
                <div
                  v-for="(item, index) in user_componey"
                  :key="index"
                  :style="is_serch ? 'cursor:not-allowed; ' : ''"
                >
                  {{ item.company_name?item.company_name:item.name }}
                </div>
              </div>
            </div>
            <div
              class="drawer_compony_info"
              v-if="position_name.status != 0"
            >
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">管理部门</div>
              <div
                class="humancompony_info pointer ee"
                @click.stop="shadowshow(null)"
                :style="is_serch ? 'cursor:not-allowed;' : ''"
              >
                <div
                  v-for="(item, index) in admin_true_componey"
                  :key="index"
                  :style="is_serch ? 'cursor:not-allowed; ' : ''"
                >
                  {{ item.company_name?item.company_name:item.name }}
                </div>
              </div>
            </div>
            <div class="drawer_compony_info">
              <span style="color: #ff0000">*</span>
              <div class="drawer_nice">邮箱</div>
              <el-input
                type="text"
                placeholder="请输入人员邮箱"
                v-model="email"
                show-word-limit
                :disabled="is_serch"
              />
            </div>
          </div>
          <div
            class="drawer_bottom"
            v-if="drawer_type == 1"
          >
            <div
              class="pointer"
              @click.stop="checkuser"
              v-if="!is_serch"
              :class="[add_flag ? 'human_nosent' : '']"
            >
              确定
            </div>
            <div
              class="pointer"
              style="
                color: #ff4141;
                background: #ffffff;
                border: 1px solid #ff4141;
              "
              v-if="!is_adduser && all_power.is_del_user && !is_serch"
              @click.stop="del_user"
            >
              删除
            </div>
            <div
              class="pointer"
              style="
                color: #3a85fc;
                background: #ffffff;
                border: 1px solid #3a85fc;
                padding: 0 5px;
              "
              v-if="!is_adduser && all_power.is_del_user && !is_serch"
              @click.stop="show_new_honor"
            >
              荣誉管理
            </div>
            <div class="pointer" style="
                color: #3a85fc;
                background: #ffffff;
                border: 1px solid #3a85fc;
                padding: 0 5px;
                "v-if="!is_adduser && all_power.is_del_user && !is_serch"
                 @click.stop="reset_password">
              重置密码
            </div>
            <div
              class="pointer"
              style="
                border: 1px solid #c3c6ce;
                background: #ffffff;
                color: #414141;
              "
              @click.stop="drawer = false"
            >
              取消
            </div>
          </div>
        </div>
      </div>
      <!-- shadow -->
      <div
        class="human_shadow"
        v-if="shadow"
      >
        <div class="human_shadowInfo">
          <div class="human_check_compony_title">选择部门</div>
          <div class="human_check_compony_body">
            <div class="human_check_compony_fir">
              <div>选择：</div>
              <div>已选：</div>
            </div>
            <div class="human_check_compony_sec">
              <div class="fir">
                <div class="bread">
                  <div
                    v-for="(item, index) in add_user_list"
                    :key="index"
                  >
                    <span
                      style="cursor: pointer"
                      :style="
                        index != add_user_list.length - 1 ? '' : 'color:#333;'
                      "
                      @click.stop="getchild(item.id)"
                    >{{ item.name }}</span>
                    <i
                      class="el-icon-arrow-right"
                      v-if="index != add_user_list.length - 1"
                    ></i>
                  </div>
                </div>
                <div class="left_list">
                  <div
                    class="left_list_info"
                    v-for="(item, index) in add_user_compony_list"
                    :key="index"
                  >
                    <!-- :style="[item.ischeck ? 'color: #38adff;' : 'color: #999;']" -->
                    <i
                      class="el-icon-check pointer"
                      style="margin-right: 2px"
                      :class="[item.ischeck ? 'colorb' : 'color9']"
                      @click.stop="changecheck(item.id)"
                    ></i>
                    <div class="list_right">
                      <div>{{ item.name }}</div>
                      <div
                        style="color: #38adff"
                        class="pointer"
                        @click.stop="getchild(item.id)"
                        v-if="item.depth != 5 && !item.ischeck"
                      >
                        下级
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sec">
                <div
                  class="rightList"
                  v-for="(item, index) in truely_compony_list"
                  :key="index"
                >
                  <div>{{ item.company_name?item.company_name:item.name }}</div>
                  <i
                    class="el-icon-circle-close pointer"
                    @click.stop="del_right(item.id)"
                  ></i>
                </div>
              </div>
            </div>
            <div class="human_check_compony_thr">
              <div>
                <div
                  class="pointer"
                  @click.stop="check_compony"
                >确定</div>
                <div
                  class="pointer"
                  @click.stop="no_compony"
                >取消</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="human_shadow"
        v-if="admin_shadow"
      >
        <div class="human_shadowInfo">
          <div class="human_check_compony_title">选择部门</div>
          <div class="human_check_compony_body">
            <div class="human_check_compony_fir">
              <div>选择：</div>
              <div>已选：</div>
            </div>
            <div class="human_check_compony_sec">
              <div class="fir">
                <div class="bread">
                  <div
                    v-for="(item, index) in add_user_list"
                    :key="index"
                  >
                    <span
                      style="cursor: pointer"
                      :style="
                        index != add_user_list.length - 1 ? '' : 'color:#333;'
                      "
                      @click.stop="getadminchild(item.id)"
                    >{{ item.name }}</span>
                    <i
                      class="el-icon-arrow-right"
                      v-if="index != add_user_list.length - 1"
                    ></i>
                  </div>
                </div>
                <div class="left_list">
                  <div
                    class="left_list_info"
                    v-for="(item, index) in add_user_compony_list"
                    :key="index"
                  >
                    <!-- :style="[item.ischeck ? 'color: #38adff;' : 'color: #999;']" -->

                    <i
                      class="el-icon-check pointer"
                      style="margin-right: 2px"
                      :class="[item.ischeck ? 'colorb' : 'color9']"
                      @click.stop="changeadmin_check(item.id)"
                    ></i>
                    <div class="list_right">
                      <div>{{ item.name }}</div>
                      <div
                        style="color: #38adff"
                        class="pointer"
                        @click.stop="getadminchild(item.id)"
                        v-if="item.depth != 5 && !item.ischeck"
                      >
                        下级
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sec">
                <div
                  class="rightList"
                  v-for="(item, index) in admin_componey"
                  :key="index"
                >
                  <div>{{ item.company_name?item.company_name:item.name}}</div>
                  <i
                    class="el-icon-circle-close pointer"
                    @click.stop="del_admin_right(item.id)"
                  ></i>
                </div>
              </div>
            </div>
            <div class="human_check_compony_thr">
              <div>
                <div
                  class="pointer"
                  @click.stop="check_admin_compony"
                >
                  确定
                </div>
                <div
                  class="pointer"
                  @click.stop="no_admin_compony"
                >取消</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="human_shadow"
        @click="no_drawer_position"
        v-if="drawer_position_show"
      >
        <div
          class="human_shadowInfo"
          @click.stop="drawer_position_show = true"
        >
          <div class="human_check_compony_title">选择职位</div>
          <div class="classify">
            <div
              v-for="(itm, idx) in position_title"
              :key="idx"
              @click="title_check(itm.id)"
              :class="[position_check == itm.id ? 'check_title' : '']"
            >
              {{ itm.classify }}
            </div>
          </div>
          <div class="human_check_compony_info">
            <div
              v-for="(item, index) in position_list"
              :key="index"
            >
              <el-radio
                v-model="position_newname.id"
                :label="item.id"
              >
                {{ item.position }}</el-radio>
            </div>
          </div>
          <div class="human_check_compony_body">
            <div class="human_check_compony_thr">
              <div>
                <div
                  class="pointer"
                  @click.stop="check_drawer_position"
                >
                  确定
                </div>
                <div
                  class="pointer"
                  @click.stop="no_drawer_position"
                >取消</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="human_shadow"
        v-if="honor"
      >
        <div class="acc_commen">
          <div class="acc_commen_title">
            荣誉管理
            <i
              class="el-icon-close pointer"
              style="color: #999999"
              @click="honor = false"
            ></i>
          </div>
          <div class="acc_commen_body">
            <div
              class="btn pointer"
              @click="show_add_honor"
            >
              <i class="el-icon-plus"></i>
              新增荣誉
            </div>
            <div class="acc_info">
              <div
                class="acc_item"
                v-for="(item, index) in honor_list"
                :key="index"
              >
                <div class="imginfo pointer">
                  <img
                    :src="item.honor_img"
                    alt=""
                    style="height: 100%; width: 100%"
                    @click="take_big(item.honor_img)"
                  />
                  <i
                    class="el-icon-circle-close"
                    @click="del_honor(item)"
                  ></i>
                </div>
                <div style="white-space: nowrap">
                  标题:
                  <el-input
                    type="text"
                    style="width: 118px; margin-left: 3px"
                    v-model="item.title"
                    placeholder="请输入荣誉标题"
                    maxlength="15"
                    @blur="blur_title(item)"
                  ></el-input>
                </div>
                <!-- <div></div> -->
              </div>
              <div
                style="color: #ccc; text-align: center"
                v-if="honor_list.length == 0"
              >
                目前暂无荣誉
              </div>
            </div>
          </div>
          <!-- <div class="bottom">
          <el-pagination
            layout="total,prev, pager, next"
            @current-change="change_honor_page"
            :total="total"
            :page-size="10"
            v-if="honor_list.length > 0"
            :current-page="honor_page"
            ref="pagination"
          >
          </el-pagination>
        </div> -->
        </div>
      </div>
      <div
        class="honor_show"
        v-if="add_honor_show"
      >
        <div class="acc_commen">
          <div class="acc_commen_title">
            添加荣誉
            <i
              class="el-icon-close pointer"
              style="color: #999999"
              @click="add_honor_show = false"
            ></i>
          </div>
          <div class="commen_body">
            <div class="add_title">
              标题:<el-input
                v-model="honor_title"
                placeholder="请输入荣誉标题"
                maxlength="15"
                show-word-limit
              ></el-input>
            </div>
            <div class="acc_title">
              <div class="yuan"></div>
              图片信息
              <span style="color: #ccc; font-size: 14px">
                （建议上传JPG,PNG格式, 尺寸宽690*300）
              </span>
            </div>
            <div class="flow_img_listinfo">
              <div
                class="img_item_info"
                v-for="(item, index) in img_list"
                :key="index"
              >
                <img
                  :src="item.file_url"
                  alt=""
                  class="big_img pointer"
                  @click="take_big(item.file_url)"
                />
                <i
                  class="el-icon-error del_img pointer"
                  style="color: #ccc"
                  @click="del_img(index)"
                ></i>
              </div>
              <div
                class="image_info"
                @click="chooseFile"
                v-if="img_list.length < 1"
              >
                <!-- <i class="el-icon-plus"></i> -->
                <img
                  src="@/assets/img/hentai.png"
                  alt=""
                  style="width: 28px; height: 25px"
                />
                <span>请上传图片</span>
                <input
                  type="file"
                  style="display: none"
                  v-if="upload_img"
                  ref="choosePhoto"
                  @change="photoChange($event)"
                  id="upload_file"
                />
              </div>
            </div>
          </div>
          <div
            class="acc_commen_sent pointer"
            @click="sent_honor"
          >确定</div>
        </div>
      </div>
      <div
        class="acc_img_shadow"
        v-if="big_imgshow"
        @click="big_imgshow = false"
      >
        <img
          :src="imgsrc"
          alt=""
        />
      </div>
      <!--是否带走客户-->
      <el-dialog
              title="是否带走客户"
              :visible.sync="dialogVisible"
              width="30%"
              >
        <el-radio-group v-model="take_customer_type">
          <el-radio label="1">否</el-radio>
          <el-radio label="2">带走全部客户</el-radio>
          <el-radio label="3">带走意向客户</el-radio>
        </el-radio-group>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="onSucc">确 定</el-button>
        </span>
      </el-dialog>
      <!--选择事业部-->
      <el-dialog
              title="选择事业部"
              :visible.sync="dialogVisiblegh"
              width="30%"
      >
        <div class="info" >
          <span class="demonstration">部门：</span>
          <el-cascader
                  placeholder="请选择部门名称"
                  :options="options"
                  ref="myCascader"
                  @change="handleClick"
                  clearable
                  :show-all-levels="false"
                  collapse-tags
          ></el-cascader>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisiblegh = false">取 消</el-button>
          <el-button type="primary" @click="onyes">确 定</el-button>
        </span>
      </el-dialog>
    </el-main>
  </div>
</template>

<script>
import {
  selectCompanyAll,
  select,
  userList,
  getAncestorsAndSelf,
  update,
  add,
  deleteCompany,
  positionList,
  newPositionList,
  addUserSelectCompany,
  addUserGetAncestorsAndSelf,
  selectRole,
  user_add,
  userDetail,
  edit,
  searchCompany,
  del,
  checkCompanyOperate,
  checkUserOperate,
  addHonor,
  delHonor,
  honorList,
  human_honorList,
  human_addHonor,
  human_delHonor,
  editHonor,
  human_editHonor,
    password,
    checkDivision,
} from "../../api/User/human";
import {
    selectCompanyTrees,
} from "../../api/User/data";
import { uploadFile } from "../../api/User/index";

import { businessLineList, detail } from "../../api/User/business";
import {
  ciCustomerList,
  ciSearch,
  ciAssignCustomer,
  financeCustomerList,
  financeSearch,
  financeAssignCustomer,
} from "../../api/User/assignment";

export default {
  data() {
    return {
        dialogVisiblegh:false,//转移
      check_id: null, //选中id
      drawer: false, //抽屉
      left_list: {}, //左侧列表
      left_checkId: "", //左侧选中id
      compony_list: [], //部门列表
      name: "", //选中名字
      id: "", //选中id
      depth: "",
      deep_show: true, //部门下级
      user_list: [],
      total: 0, //总条数
      current_page: 1,
      drawer_type: "", //1为 用户  2为部门
      select_compony: {},
      truename: "",
      add_usershow: false,
      title_list: [], //面包屑
      is_add: false,
      act_type: "", //1销售部门 2非销售部门
      sell_type: "",
      is_adduser: false, //是否是添加用户
      username: "", //用户名
      mobile: "", //电话
      email: "", //邮箱
        user_id:'',//用户id
      position_list: [], //职位列表
      position: "", //选中职位
      date: "", //日期
      truely_compony_list: [], //右侧已添加的列表
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      user_componey: [], //用户公司列表
      add_user_compony_list: [], //添加用户左侧列表
      shadow: false, //
      add_user_list: [], //添加用户的面包屑
      role_list: [],
      role: [],
      sex: "",
      sex_list: [
        { id: "1", name: "男" },
        { id: "2", name: "女" },
      ],
      rank: "", //是否排名
      ranking_list: [
        //排名
        { id: "1", name: "是" },
        { id: "2", name: "否" },
      ],
      user_id: "",
      serch_val: "",
      serch_compony_list: [],
      serch_user_list: [],
      serch_list_show: false,
      all_power: {
        is_add_company: false,
        is_change_company: false,
        is_del_company: false,
        is_add_user: false,
        is_change_user: false,
        is_del_user: false,
      },
      nozzle_add_user: false,
      nozzle_add_company: false,
      edit_company: false,
      role_alldata: {},
      is_serch: false,
      page_size: 15,
      compony_isjoin: "",
      is_ranking: "", //是否是销售部门,
      admin_componey: [], //管理部门  右侧
      admin_true_componey: [], //真的管理部门  渲染到列表的
      admin_shadow: false,
      add_flag: false,
      status: "",
      position_name: {
        id: "",
        name: "",
        status: "",
      },
      position_newname: {
        id: "",
        name: "",
        status: "",
      },
      drawer_position_show: false,
      is_business: true,
      business_arr: [],
      business: "",
      honor: false,
      honor_page: 1, //荣誉列表页码
      honor_list: [],
      add_honor_show: false, //新增荣誉
      honor_title: "",
      img_list: [],
      upload_img: true,
      imgsrc: "",
      big_imgshow: false,
      company_honor: false,
      truely_list: [],
      human_item: {},
      leader: "", //部门领导人
      leader_check: {}, //部门领导人
      position_title: [], //职位大类
      position_check: "",
      leader_list: [],
      is_cansee_all: '0',
      dialogVisible:false,
        search_company_id:[],
      take_customer_type:'1',//是否带走客户
        options:[],
    };
  },
  created() {
    let newdata = JSON.parse(localStorage.getItem("token"));
    let role_permissions = newdata.role_permissions;
    let newpower = {};
    for (let i in role_permissions) {
      if (role_permissions[i].permissions == "resources") {
        newpower = role_permissions[i];
        this.role_alldata = role_permissions[i];
      }
    }
    if (JSON.stringify(newpower) != "{}") {
      if (newpower.data.length > 0) {
        for (let i in newpower.data) {
          if (newpower.data[i].permissions == "change_company") {
            this.all_power.is_change_company = true;
          }
          if (newpower.data[i].permissions == "add_company") {
            this.all_power.is_add_company = true;
          }
          if (newpower.data[i].permissions == "add_user") {
            this.all_power.is_add_user = true;
          }
          if (newpower.data[i].permissions == "change_user") {
            this.all_power.is_change_user = true;
          }
          if (newpower.data[i].permissions == "del_user") {
            this.all_power.is_del_user = true;
          }
          if (newpower.data[i].permissions == "del_company") {
            this.all_power.is_del_company = true;
          }
        }
      }
    }
    businessLineList().then((res) => {
      // console.log(res);
      this.business_arr = res.data.data;
    });
    selectRole().then((res) => {
      this.role_list = res.data.data;
    });
    newPositionList().then((res) => {
      if (res.data.code == 200) {
        // this.po
        // console.log(res.data.data);
        this.position_check = res.data.data[0].id;
        this.position_title = res.data.data;
        this.position_list = res.data.data[0].position;
      }
    });
    //获取全部部门
    selectCompanyAll().then((res) => {
      if (res.data.code == 400) {
        this.$message.error(res.data.message);
      } else if (res.data.code == 200) {
        res.data.data.arrow = true;
        for (let i in res.data.data.data) {
          res.data.data.data[i].arrow = false;
          for (let v in res.data.data.data[i].data) {
            res.data.data.data[i].data[v].arrow = false;
            for (let j in res.data.data.data[i].data[v].data) {
              if (res.data.data.data[i].data[v].data[j]) {
                res.data.data.data[i].data[v].data[j].arrow = false;
              }
              for (let h in res.data.data.data[i].data[v].data[j].data) {
                if (res.data.data.data[i].data[v].data[j].data[h]) {
                  res.data.data.data[i].data[v].data[j].data[h].arrow = false;
                }
              }
            }
          }
        }
        this.name = res.data.data.name;
        this.check_id = res.data.data.id;
        this.left_list = res.data.data;
        this.change_active(
          res.data.data.id,
          res.data.data.parent_id,
          res.data.data.depth,
          res.data.data.name,
          res.data.data.type,
          res.data.data.is_ranking
        );
      }
    });
    //获取事业部
      selectCompanyTrees({
        depth:3,
    }).then((res) => {
        if (res.data.code == 200) {
            function dg(list) {
                list.forEach((item) => {
                    item.label = item.name;
                    item.value = item.id;
                    if (item.data) {
                        item.children = item.data;
                        dg(item.data);
                    }
                });
                return list;
            }
            let list = res.data.data;
            dg(list);
            this.options = list;
        }
    });
  },
  methods: {
    querySearchAsync(str, cb) {
      let data = [];
      financeSearch({
        //  permissions_id_string:this.role.fpstr,
        name: str,
      }).then((res) => {
        for (let i in res.data.data) {
          res.data.data[i].value =
            res.data.data[i].name + "【" + res.data.data[i].company.name + "】";
        }
        data = res.data.data;
        cb(data);
      });
    },
    title_check(id) {
      this.position_check = id;
      for (let i in this.position_title) {
        if (this.position_title[i].id == id) {
          this.position_list = this.position_title[i].position;
        }
      }
    },
    handleSelect(e) {
      // console.log(e);
      this.add_flag = false;
      this.leader_check = e;
    },
    //是否部门
    show_honor(is_company) {
      //荣耀列表
      // console.log(is_company);
      this.honor = true;
      let str = "";
      for (let i in this.role_alldata.data) {
        if (this.role_alldata.data[i].permissions == "add_company") {
          str = this.role_alldata.data[i].permissions_id_string;
        }
      }
      if (is_company) {
        //个人
        this.company_honor = false;
        this.human_item = is_company;
        // console.log(is_company);
        human_honorList({
          user_id_str: is_company.id,
          permissions_id_string: str,
        }).then((res) => {
          this.honor_list = JSON.parse(JSON.stringify(res.data.data));
        });
      } else {
        //部门
        this.company_honor = true;
        honorList({
          parent_id: this.id,
          permissions_id_string: str,
        }).then((res) => {
          // console.log(res);
          this.truely_list = JSON.parse(JSON.stringify(res.data.data));
          this.honor_list = JSON.parse(JSON.stringify(res.data.data));
        });
      }
    },
    show_new_honor() {
      // console.log(this.human_item);
      this.show_honor(this.human_item);
    },
      //重置密码
    reset_password(){
        this.$confirm("是否确定重置密码?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(() => {
            password({
           reset_user_id:this.user_id
          }).then((res)=>{
          if(res.data.code=='200'){
              this.$alert('重置密码成功，密码是'+res.data.data, '提示', {
                  confirmButtonText: '确定'
              })
          }
        })
    })
      },
    change_honor_page(e) {
      //荣誉页数
      this.honor_page = e;
    },
    show_add_honor() {
      //新增页面
      this.add_honor_show = true;
      this.img_list = [];
      this.honor_title = "";
      if (this.company_honor) {
        //部门荣誉
      } else {
      }
    },
    sent_honor() {
      if (this.img_list.length == 0) {
        this.$message.error("请上传图片哦~~");
        return;
      } else if (this.img_list.length > 2) {
        this.$message.error("只能上传一张图片哦~~");
        return;
      }
      let str = "";
      for (let i in this.role_alldata.data) {
        if (this.role_alldata.data[i].permissions == "add_company") {
          str = this.role_alldata.data[i].permissions_id_string;
        }
      }
      if (this.add_flag) {
        return;
      }
      this.add_flag = true;
      if (this.company_honor) {
        //部门荣誉
        // console.log(this.img_list);
        addHonor({
          parent_id: this.id,
          title: this.honor_title,
          honor_img: this.img_list[0].url,
          permissions_id_string: str,
        }).then((res) => {
          this.add_flag = false;
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.add_honor_show = false;
            this.show_honor(0);
          }
        });
      } else {
        human_addHonor({
          user_id_str: this.human_item.id,
          title: this.honor_title,
          honor_img: this.img_list[0].url,
          permissions_id_string: str,
        }).then((res) => {
          this.add_flag = false;
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.add_honor_show = false;
            this.show_honor(this.human_item);
          }
        });
      }
    },
    blur_title(i) {
      let str = "";
      for (let i in this.role_alldata.data) {
        if (this.role_alldata.data[i].permissions == "add_company") {
          str = this.role_alldata.data[i].permissions_id_string;
        }
      }
      let newitem = JSON.parse(JSON.stringify(i));
      // console.log(i);
      if (this.company_honor) {
        //部门荣誉
        editHonor({
          title: i.title,
          honor_id: i.id,
          permissions_id_string: str,
        }).then((res) => {
          this.show_honor(0);
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
          }
        });
      } else {
        human_editHonor({
          title: i.title,
          honor_id: i.id,
          permissions_id_string: str,
        }).then((res) => {
          this.show_honor(this.human_item);
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
          }
        });
      }
    },
    del_honor(item) {
      let str = "";
      for (let i in this.role_alldata.data) {
        if (this.role_alldata.data[i].permissions == "add_company") {
          str = this.role_alldata.data[i].permissions_id_string;
        }
      }
      this.$confirm("此操作将永久删除该荣誉, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.company_honor) {
            //部门荣誉
            delHonor({
              permissions_id_string: str,
              honor_id: item.id,
            }).then((res) => {
              this.$message({
                message: res.data.message,
                type: "success",
              });
              this.show_honor(0);
            });
          } else {
            human_delHonor({
              permissions_id_string: str,
              honor_id: item.id,
            }).then((res) => {
              this.$message({
                message: res.data.message,
                type: "success",
              });
              this.show_honor(this.human_item);
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    take_big(url) {
      this.imgsrc = url;
      this.big_imgshow = true;
    },
    del_img(i) {
      this.img_list.splice(i, 1);
    },
    chooseFile() {
      this.$refs.choosePhoto.addEventListener('change',this.photoChange);
this.$refs.choosePhoto.dispatchEvent(new MouseEvent("click"));
      this.upload_img = false;
      setTimeout(() => {
        this.upload_img = true;
      }, 10);
    },
    photoChange(el) {
      // console.log(el);
      var file = el.target.files[0]; //name: "dangqi1.png" || type: "image/png"
      var type = file.type.split("/")[0];
      // console.log(type);
      if (type === "image") {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        var that = this;
        reader.onloadend = function () {
          var dataURL = reader.result;
          var blob = that.dataURItoBlob(dataURL);
          that.upload(blob); //执行上传接口
        };
      } else {
        this.$message.error("请上传图片哦~~");
      }
    },
    dataURItoBlob(dataURI) {
      // base64 解码

      let byteString = window.atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let T = mimeString.split("/")[1];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    upload(imgUrl) {
      let formData = new FormData();
      this.$message({
        message: "上传中，请稍后",
      });
      formData.append("file", imgUrl);
      uploadFile(formData).then((res) => {
        if (res.data.code == 200) {
          this.img_list.push(res.data.data);
          this.$message({
            message: "上传成功",
            type: "success",
          });
        }
        // console.log(res);
      });
    },

    beforeUploadForm(file) {
      // 验证文件类型
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" || testmsg === "png" || testmsg === "gif";
      if (!extension) {
        this.$message({
          message: "上传文件只能是jpg/png/gif格式!",
          duration: 1000,
          showClose: true,
          type: "warning",
        });
      }
      return extension;
    },
    //左侧变化 或者切换右边   部门 id 父级id 深度 名字 , 是否销售部门  我写尼玛呢？
    change_active(id, parent_id, depth, name, type, is_ranking) {
      // console.log(id, parent_id, depth, name, type, is_ranking);
      // console.log(depth);
      if (type) {
        this.act_type = type;
      }
      if (name) {
        this.name = name;
      }
      if (is_ranking) {
        this.is_ranking = is_ranking;
      }
      // this.$refs.pagination.internalCurrentPage = 1;
      this.current_page = 1;
      this.id = id;
      this.depth = depth;
      checkCompanyOperate({ search_company_id: id, search_depth: depth }).then(
        (res) => {
          if (res.data.code == 200) {
            this.nozzle_add_user = res.data.data.add_user;
            this.nozzle_add_company = res.data.data.add_next_company;
            this.edit_company = res.data.data.edit_company;
          }
        }
      );
      if (depth == 5) {
        this.deep_show = false;
      } else {
        this.deep_show = true;
      }
      if (depth) {
        this.add_usershow = true;
      } else {
        this.add_usershow = false;
      }
      getAncestorsAndSelf({ company_id: id }).then((res) => {
        this.title_list = res.data.data;
      });
      this.check_id = id;
      let str = "";
      for (let i in this.role_alldata.data) {
        if (this.role_alldata.data[i].permissions == "serch_userList") {
          str = this.role_alldata.data[i].permissions_id_string;
        }
      }
      userList({
        company_id: id,
        depth: depth || 0,
        page: 1,
        permissions_id_string: str,
      }).then((res) => {
        if (res.data.code == 200) {
          this.user_list = res.data.data.data;
          this.total = res.data.data.total;
          let array = [];
          let list = res.data.data.leader;
          for (let i = 0; i < list.length; i++) {
            if (list[i].name !== "" || list[i].position !== "") {
              array.push(list[i]);
            }
          }
          this.leader_list = array;
        }
      });
      if (!parent_id) {
        select({
          is_count: true,
        }).then((res) => {
          if (res.data.code == 200) {
            this.compony_list = res.data.data.data;
          }
        });
      } else {
        select({
          parent_id: id,
          is_count: true,
        }).then((res) => {
          if (res.data.code == 200) {
            this.compony_list = res.data.data;
          }
        });
      }
    },
    check_drawer_position() {
      for (let i in this.position_list) {
        if (this.position_newname.id == this.position_list[i].id) {
          this.position_name.name = this.position_list[i].position;
          this.position_newname.name = this.position_list[i].position;
          this.position_newname.id = this.position_list[i].id;
          this.position_name.id = this.position_list[i].id;
          this.position_name.status = this.position_list[i].status;
          this.position_newname.status = this.position_list[i].status;
        }
      }
      this.drawer_position_show = false;
    },
    no_drawer_position() {
      this.drawer_position_show = false;
    },
    focus() {
      this.serch_list_show = true;
    },
    blur() {
      this.serch_list_show = false;
      this.serch_val = "";
    },
    // 设定第几页
    current(e) {
      this.current_page = e;
      let { depth, id } = this;
      let str = "";
      for (let i in this.role_alldata.data) {
        if (this.role_alldata.data[i].permissions == "serch_userList") {
          str = this.role_alldata.data[i].permissions_id_string;
        }
      }
      userList({
        company_id: id,
        depth,
        page: e,
        permissions_id_string: str,
      }).then((res) => {
        if (res.data.code == 200) {
          this.user_list = res.data.data.data;
          this.total = res.data.data.total;
          let array = [];
          let list = res.data.data.leader;
          for (let i = 0; i < list.length; i++) {
            if (list[i].name !== "" || list[i].position !== "") {
              array.push(list[i]);
            }
          }
          this.leader_list = array;
        }
      });
    },
    // 删除员工
    del_user() {
      let str = "";
      for (let i in this.role_alldata.data) {
        if (this.role_alldata.data[i].permissions == "del_user") {
          str = this.role_alldata.data[i].permissions_id_string;
        }
      }
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          del({
            user_id_str: this.user_id,
            permissions_id_string: str,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.drawer = false;
              let str = "";
              for (let i in this.role_alldata.data) {
                if (this.role_alldata.data[i].permissions == "serch_userList") {
                  str = this.role_alldata.data[i].permissions_id_string;
                }
              }
              userList({
                company_id: this.id,
                depth: this.depth || 0,
                page: this.current_page,
                permissions_id_string: str,
              }).then((res) => {
                if (res.data.code == 200) {
                  this.user_list = res.data.data.data;
                  this.total = res.data.data.total;
                  let array = [];
                  let list = res.data.data.leader;
                  for (let i = 0; i < list.length; i++) {
                    if (list[i].name !== "" || list[i].position !== "") {
                      array.push(list[i]);
                    }
                  }
                  this.leader_list = array;
                }
              });
              selectCompanyAll().then((res) => {
                if (res.data.code == 400) {
                  this.$message({
                    type: "error",
                    message: res.data.message,
                  });
                } else if (res.data.code == 200) {
                  res.data.data.arrow = true;
                  for (let i in res.data.data.data) {
                    if (this.left_list.data[i].arrow == true) {
                      res.data.data.data[i].arrow = true;
                    } else {
                      res.data.data.data[i].arrow = false;
                    }
                    if (this.check_id == res.data.data.data[i].id) {
                      this.change_active(
                        res.data.data.data[i].id,
                        res.data.data.data[i].parent_id,
                        res.data.data.data[i].depth,
                        res.data.data.data[i].name,
                        res.data.data.data[i].type,
                        res.data.data.data[i].is_ranking
                      );
                    }
                    for (let v in res.data.data.data[i].data) {
                      if (this.left_list.data[i].data[v].arrow == true) {
                        res.data.data.data[i].data[v].arrow = true;
                      } else {
                        res.data.data.data[i].data[v].arrow = false;
                      }
                      if (this.check_id == res.data.data.data[i].data[v].id) {
                        this.change_active(
                          res.data.data.data[i].data[v].id,
                          res.data.data.data[i].data[v].parent_id,
                          res.data.data.data[i].data[v].depth,
                          res.data.data.data[i].data[v].name,
                          res.data.data.data[i].data[v].type,
                          res.data.data.data[i].data[v].is_ranking
                        );
                      }
                      for (let j in res.data.data.data[i].data[v].data) {
                        if (this.left_list.data[i].data[v].data[j]) {
                          if (
                            this.left_list.data[i].data[v].data[j].arrow == true
                          ) {
                            res.data.data.data[i].data[v].data[j].arrow = true;
                          } else {
                            res.data.data.data[i].data[v].data[j].arrow = false;
                          }
                        }
                        // console.log(this.check_id,res.data.data.data[i].data[v].data[j].id);
                        if (
                          this.check_id ==
                          res.data.data.data[i].data[v].data[j].id
                        ) {
                          this.change_active(
                            res.data.data.data[i].data[v].data[j].id,
                            res.data.data.data[i].data[v].data[j].parent_id,
                            res.data.data.data[i].data[v].data[j].depth,
                            res.data.data.data[i].data[v].data[j].name,
                            res.data.data.data[i].data[v].data[j].type,
                            res.data.data.data[i].data[v].data[j].is_ranking
                          );
                        }
                        for (let a in res.data.data.data[i].data[v].data[j]
                          .data) {
                          if (this.left_list.data[i].data[v].data[j].data[a]) {
                            if (
                              this.left_list.data[i].data[v].data[j].data[a]
                                .arrow == true
                            ) {
                              res.data.data.data[i].data[v].data[j].data[
                                a
                              ].arrow = true;
                            } else {
                              res.data.data.data[i].data[v].data[j].data[
                                a
                              ].arrow = false;
                            }
                          }
                          // console.log(this.check_id,res.data.data.data[i].data[v].data[j].data[a].id);
                          // res.data.data.data[i].data[v].id
                          if (
                            this.check_id ==
                            res.data.data.data[i].data[v].data[j].data[a].id
                          ) {
                            this.change_active(
                              res.data.data.data[i].data[v].data[j].data[a].id,
                              res.data.data.data[i].data[v].data[j].data[a]
                                .parent_id,
                              res.data.data.data[i].data[v].data[j].data[a]
                                .depth,
                              res.data.data.data[i].data[v].data[j].data[a]
                                .name,
                              res.data.data.data[i].data[v].data[j].data[a]
                                .type,
                              res.data.data.data[i].data[v].data[j].data[a]
                                .is_ranking
                            );
                          }
                          for (let b in res.data.data.data[i].data[v].data[j]
                            .data[a].data) {
                            if (
                              this.check_id ==
                              res.data.data.data[i].data[v].data[j].data[a]
                                .data[b].id
                            ) {
                              this.change_active(
                                res.data.data.data[i].data[v].data[j].data[a]
                                  .data[b].id,
                                res.data.data.data[i].data[v].data[j].data[a]
                                  .data[b].parent_id,
                                res.data.data.data[i].data[v].data[j].data[a]
                                  .data[b].depth,
                                res.data.data.data[i].data[v].data[j].data[a]
                                  .data[b].name,
                                res.data.data.data[i].data[v].data[j].data[a]
                                  .data[b].type,
                                res.data.data.data[i].data[v].data[j].data[a]
                                  .data[b].is_ranking
                              );
                            }
                          }
                        }
                      }
                    }
                  }
                  if (this.check_id == res.data.data.id) {
                    this.change_active(
                      res.data.data.id,
                      res.data.data.parent_id,
                      res.data.data.depth,
                      res.data.data.name,
                      res.data.data.type,
                      res.data.data.is_ranking
                    );
                  }
                  this.left_list = res.data.data;
                  this.drawer = false;
                  this.add_flag = false;
                }
              });

              if (this.is_serch) {
                searchCompany({ name: this.serch_val }).then((res) => {
                  this.serch_compony_list = res.data.data.company;
                  this.serch_user_list = res.data.data.user;
                });
              }
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 切换职位
    change_position(key) {
      // console.log(key);
      for (let i in this.position_list) {
        if (this.position_list[i].id == key) {
          this.status = this.position_list[i].status;
          // status  是否是管理岗 1是0不是
          break;
        }
      }
    },
    heihei(e) {
      // console.log(e);
      let date = this.getwaytime(e);
      this.date = date;
    },
    //展示添加用户抽屉
    shadowshow(flag) {
      if (this.is_serch) {
        return;
      }
      //   组织部门
      if (flag) {
        // user_componey 用户渲染   truely_compony_list//进去的右侧
        this.truely_compony_list = JSON.parse(
          JSON.stringify(this.user_componey)
        );

        addUserSelectCompany().then((res) => {
          for (let i in res.data.data) {
            res.data.data[i].ischeck = false;
            // if()
          }
          this.add_user_compony_list = res.data.data;

          for (let i = 0; i < this.add_user_compony_list.length; i++) {
            for (let v = 0; v < this.truely_compony_list.length; v++) {
              if (
                this.add_user_compony_list[i].id ==
                this.truely_compony_list[v].id
              ) {
                this.add_user_compony_list[i].ischeck = true;
                break;
              } else {
                this.add_user_compony_list[i].ischeck = false;
              }
            }
          }

          this.add_user_compony_list = this.add_user_compony_list;
          this.shadow = true;
          this.add_user_list = [];
        });
        //   add_user_compony_list左侧列表

        // 管理部门
      } else {
        this.admin_componey = JSON.parse(
          JSON.stringify(this.admin_true_componey)
        );
        // 不就是加个字段么 随便
        addUserSelectCompany().then((res) => {
          for (let i in res.data.data) {
            res.data.data[i].ischeck = false;
          }
          this.add_user_compony_list = res.data.data;

          for (let i = 0; i < this.add_user_compony_list.length; i++) {
            for (let v = 0; v < this.admin_componey.length; v++) {
              if (
                this.admin_componey[v].id == this.add_user_compony_list[i].id
              ) {
                this.add_user_compony_list[i].ischeck = true;
                break;
              } else {
                this.add_user_compony_list[i].ischeck = false;
              }
            }
          }
          this.add_user_compony_list = this.add_user_compony_list;
          this.admin_shadow = true;
          this.add_user_list = [];
        });
      }
    },
    // 用户抽屉显示 是否 增加
    show_user_drawer(item, is_serch) {
      // console.log(item);
      if (item) {
        this.human_item = item;
      }
      // console.log(this.human_item);
      this.status = 0;
      if (is_serch) {
        checkUserOperate({
          search_user_id: item.id,
        }).then((res) => {
          if (res.data.code == 200) {
            this.is_serch = !res.data.data.edit_user;
          } else {
            this.is_serch = true;
          }
        });
        // this.is_serch = true;
        if (item) {
          this.drawer = true;
          this.drawer_type = 1; //1用户
          this.is_adduser = false;
          this.user_id = item.id;
          let str = "";
          for (let i in this.role_alldata.data) {
            if (this.role_alldata.data[i].permissions == "user_detail") {
              str = this.role_alldata.data[i].permissions_id_string;
            }
          }
          userDetail({ user_id_str: item.id, permissions_id_string: str }).then(
            (res) => {
              this.username = res.data.data.name;
              this.mobile = res.data.data.phone;
              this.email = res.data.data.email;
              this.user_id=res.data.data.id;
              this.date = res.data.data.entry_time;
              this.user_componey = res.data.data.company;
              this.admin_true_componey = res.data.data.other_company;
              // this.change_position(res.data.data.position_id);
              for (let i in this.position_title) {
                for (let v in this.position_title[i].position) {
                  if (
                    res.data.data.position_id ==
                    this.position_title[i].position[v].id
                  ) {
                    this.position_name.name = this.position_title[i].position[
                      v
                    ].position;
                    this.position_newname.name = this.position_title[
                      i
                    ].position[v].position;
                    this.position_newname.id = this.position_title[i].position[
                      v
                    ].id;
                    this.position_name.id = this.position_title[i].position[
                      v
                    ].id;
                    this.position_name.status = this.position_title[i].position[
                      v
                    ].status;
                    this.position_newname.status = this.position_title[
                      i
                    ].position[v].status;
                  }
                  if (
                    this.position_title[i].position[v].id ==
                    res.data.data.position_id
                  ) {
                    // console.log(this.position_title[i].position[v]);
                    this.title_check(this.position_title[i].id);
                    break;
                  }
                }
              }
              for (let i in this.position_list) {
                if (res.data.data.position_id == this.position_list[i].id) {
                  this.position_name.name = this.position_list[i].position;
                  this.position_newname.name = this.position_list[i].position;
                  this.position_newname.id = this.position_list[i].id;
                  this.position_name.id = this.position_list[i].id;
                  this.position_name.status = this.position_list[i].status;
                  this.position_newname.status = this.position_list[i].status;
                }
              }

              this.position = res.data.data.position_id;
              if (res.data.data.sex == 1) {
                this.sex = "男";
              } else {
                this.sex = "女";
              }
              if (res.data.data.is_ranking == 1) {
                this.rank = "是";
              } else {
                this.rank = "否";
              }
              this.role = res.data.data.user_role;
              addUserSelectCompany().then((res) => {
                for (let i in res.data.data) {
                  res.data.data[i].ischeck = false;
                  // if()
                }
                this.add_user_compony_list = res.data.data;
              });
            }
          );
        }
      } else {
        this.is_serch = false;
      }
      // 修改
      if (item) {
        if (!this.all_power.is_change_user || !this.nozzle_add_user) {
          return;
        }
        // this.rank=''
        this.drawer = true;
        this.drawer_type = 1; //1用户
        this.is_adduser = false;
        this.user_id = item.id;
        let str = "";
        for (let i in this.role_alldata.data) {
          if (this.role_alldata.data[i].permissions == "user_detail") {
            str = this.role_alldata.data[i].permissions_id_string;
          }
        }
        userDetail({ user_id_str: item.id, permissions_id_string: str }).then(
          (res) => {
            this.username = res.data.data.name;
            this.mobile = res.data.data.phone;
            this.email = res.data.data.email;
            this.date = res.data.data.entry_time;
            this.user_componey = res.data.data.company;
            this.admin_true_componey = res.data.data.other_company;
            // this.change_position(res.data.data.position_id);
            for (let i in this.position_title) {
              for (let v in this.position_title[i].position) {
                if (
                  res.data.data.position_id ==
                  this.position_title[i].position[v].id
                ) {
                  this.position_name.name = this.position_title[i].position[
                    v
                  ].position;
                  this.position_newname.name = this.position_title[i].position[
                    v
                  ].position;
                  this.position_newname.id = this.position_title[i].position[
                    v
                  ].id;
                  this.position_name.id = this.position_title[i].position[v].id;
                  this.position_name.status = this.position_title[i].position[
                    v
                  ].status;
                  this.position_newname.status = this.position_title[
                    i
                  ].position[v].status;
                }
                if (
                  this.position_title[i].position[v].id ==
                  res.data.data.position_id
                ) {
                  // console.log(this.position_title[i].position[v]);
                  this.title_check(this.position_title[i].id);
                  break;
                }
              }
            }
            for (let i in this.position_list) {
              if (res.data.data.position_id == this.position_list[i].id) {
                this.position_name.name = this.position_list[i].position;
                this.position_newname.name = this.position_list[i].position;
                this.position_newname.id = this.position_list[i].id;
                this.position_name.id = this.position_list[i].id;
                this.position_name.status = this.position_list[i].status;
                this.position_newname.status = this.position_list[i].status;
              }
            }
            this.position = res.data.data.position_id;
            if (res.data.data.sex == 1) {
              this.sex = "男";
            } else {
              this.sex = "女";
            }
            if (res.data.data.is_ranking == 1) {
              this.rank = "是";
            } else {
              this.rank = "否";
            }
            this.role = res.data.data.user_role;
            addUserSelectCompany().then((res) => {
              for (let i in res.data.data) {
                res.data.data[i].ischeck = false;
                // if()
              }
              this.add_user_compony_list = res.data.data;
            });
          }
        );
      } else {
        //添加
        if (!this.all_power.is_add_user && this.nozzle_add_user) {
          return;
        }
        this.title_check(this.position_title[0].id);
        this.drawer = true;
        this.drawer_type = 1; //1用户
        this.is_adduser = true;
        this.admin_true_componey = [];
        // this.
        // this.admin_true_componey.push(this.title_list[this.title_list.length - 1]);
        this.position_name = {
          id: "",
          name: "",
          status: "",
        };
        this.position_newname = {
          id: "",
          name: "",
          status: "",
        };
        this.user_componey = [];
        this.user_componey.push(this.title_list[this.title_list.length - 1]);
        // 显示右侧列表
        this.add_user_compony_list = [];
        this.truely_compony_list = [];
        this.add_user_list = [];

        this.truely_compony_list.push(
          this.title_list[this.title_list.length - 1]
        );
        this.username = "";
        this.mobile = "";
        this.email = "";
        this.date = "";
        this.position = "";
        this.role = "";
        this.sex = "";
        this.rank = "";
        addUserSelectCompany().then((res) => {
          for (let i in res.data.data) {
            res.data.data[i].ischeck = false;
            // if()
          }
          this.add_user_compony_list = res.data.data;
        });
      }
    },
    check_position() {
      if (this.position_name.name == "") {
        this.title_check(this.position_title[0].id);
      }
      // console.log(this.position_name);
      this.position_newname = JSON.parse(JSON.stringify(this.position_name));
      this.drawer_position_show = true;
    },
    //添加员工切换选中所属部门
    changecheck(id) {
      for (let i in this.add_user_compony_list) {
        if (this.add_user_compony_list[i].id == id) {
          this.add_user_compony_list[i].ischeck = !this.add_user_compony_list[i]
            .ischeck;
          if (this.add_user_compony_list[i].ischeck == false) {
            for (let v in this.truely_compony_list) {
              if (this.truely_compony_list[v].id == id) {
                this.truely_compony_list.splice(v, 1);
              }
            }
          } else {
            this.truely_compony_list.push(this.add_user_compony_list[i]);
          }
        }
      }
      // for(let i in )
    },
    changeadmin_check(id) {
      for (let i in this.add_user_compony_list) {
        if (this.add_user_compony_list[i].id == id) {
          this.add_user_compony_list[i].ischeck = !this.add_user_compony_list[i]
            .ischeck;
          if (this.add_user_compony_list[i].ischeck == false) {
            for (let v in this.admin_componey) {
              if (this.admin_componey[v].id == id) {
                this.admin_componey.splice(v, 1);
              }
            }
          } else {
            this.admin_componey.push(this.add_user_compony_list[i]);
          }
        }
      }
    },
    //添加员工删除右侧选中部门
    del_right(id) {
      for (let i in this.truely_compony_list) {
        if (this.truely_compony_list[i].id == id) {
          this.truely_compony_list.splice(i, 1);
        }
      }
      for (let i in this.add_user_compony_list) {
        if (this.add_user_compony_list[i].id == id) {
          this.add_user_compony_list[i].ischeck = false;
        }
      }
    },
    del_admin_right(id) {
      for (let i in this.admin_componey) {
        if (this.admin_componey[i].id == id) {
          this.admin_componey.splice(i, 1);
        }
      }
      for (let i in this.add_user_compony_list) {
        if (this.add_user_compony_list[i].id == id) {
          this.add_user_compony_list[i].ischeck = false;
        }
      }
    },
    // 修改 或是 添加  显示  抽屉
    change_compony(type) {
      // 是否添加
      if (type) {
        this.drawer = true;
        this.drawer_type = 2; //2部门
        this.truename = "";
        this.sell_type = "";
        this.is_add = true;
        this.compony_isjoin = "";
        this.business = "";
        this.leader_check = {};
        this.leader = "";
        this.is_cansee_all = '0'
        if (this.depth == 2) {
          this.is_business = true;
        } else {
          this.is_business = false;
        }
      } else {
        this.drawer = true;
        this.drawer_type = 2; //2部门
        this.truename = this.name;
        if (this.act_type == 1) {
          this.sell_type = "是";
        } else {
          this.sell_type = "否";
        }
        if (this.is_ranking == 1) {
          this.compony_isjoin = "是";
        } else {
          this.compony_isjoin = "否";
        }
        this.is_add = false;
        if (this.depth == 3) {
          this.is_business = true;
        } else {
          this.is_business = false;
        }
        detail({
          parent_id: this.id,
        }).then((res) => {
          console.log(res);
          if (res.data.data.business_line) {
            this.business = res.data.data.business_line[0];
          }
          this.is_cansee_all = res.data.data.is_all.toString();
          if (res.data.data.leader) {
            if (res.data.data.leader.company) {
              this.leader =
                res.data.data.leader.name +
                "【" +
                res.data.data.leader.company.name +
                "】";
            } else {
              this.leader = res.data.data.leader.name;
            }
            this.leader_check = res.data.data.leader;
            this.leader_check.value=res.data.data.leader.name +
                "【" +
                res.data.data.leader.company.name +
                "】"
          } else {
            this.leader = "";
            this.leader_check = {};
          }
          // this.

          this.is_ranking=res.data.data.is_ranking;
          this.act_type=res.data.data.type;
          if (this.act_type == 1) {
              this.sell_type = "是";
          } else {
              this.sell_type = "否";
          }
          if (this.is_ranking == 1) {
              this.compony_isjoin = "是";
          } else {
              this.compony_isjoin = "否";
          }
        });
      }
    },
    //部门添加或修改
    check_detail() {
      let { id, depth, truename, is_add, compony_isjoin } = this;
      //添加
      if (this.add_flag == true) {
        return;
      }
      this.add_flag = true;
      let type = "";
      if (is_add) {
        if (this.sell_type == "") {
          this.$message.error("请先补全信息");
          this.add_flag = false;

          return;
        }
        let str = "";
        for (let i in this.role_alldata.data) {
          if (this.role_alldata.data[i].permissions == "add_company") {
            str = this.role_alldata.data[i].permissions_id_string;
          }
        }
        if (this.sell_type == "是") {
          type = 1;
        } else if (this.sell_type == "否") {
          type = 2;
        }
        let is_ranking = "";
        if (this.compony_isjoin == "是") {
          is_ranking = 1;
        } else if (this.compony_isjoin == "否") {
          is_ranking = 2;
        }

        // if (JSON.stringify(this.leader_check) == "{}") {
        //   this.$message.error("请输入领导人名字后选择噢~");
        //   this.add_flag = false;
        //   return;
        // }
        // if (this.leader != this.leader_check.value) {
        //   this.$message.error("请重新输入领导人名字后选择噢~");
        //   this.add_flag = false;
        //   return;
        // }
        add({
          name: truename,
          parent_id: id,
          type,
          is_ranking,
          business_line_id: this.business,
          permissions_id_string: str,
          leader: this.leader_check.id,
          is_all: this.is_cansee_all
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "添加成功",
            });
            selectCompanyAll().then((res) => {
              if (res.data.code == 400) {
                this.add_flag = false;

                // this.$message({
                //   type: "error",
                //   message: res.data.message,
                // });
              } else if (res.data.code == 200) {
                // 重新赋值并且全部不打开箭头
                for (let i in res.data.data.data) {
                  res.data.data.data[i].arrow = false;
                  for (let v in res.data.data.data[i].data) {
                    res.data.data.data[i].data[v].arrow = false;
                    for (let j in res.data.data.data[i].data[v].data) {
                      if (res.data.data.data[i].data[v].data[j]) {
                        res.data.data.data[i].data[v].data[j].arrow = false;
                      }
                      for (let h in res.data.data.data[i].data[v].data[j]
                        .data) {
                        if (res.data.data.data[i].data[v].data[j].data[h]) {
                          res.data.data.data[i].data[v].data[j].data[
                            h
                          ].arrow = false;
                        }
                      }
                    }
                  }
                }
                res.data.data.arrow = true;
                for (let i in res.data.data.data) {
                  for (let v in this.left_list.data) {
                    if (this.left_list.data[v].arrow) {
                      res.data.data.data[v].arrow = true;
                      // break;
                    }
                  }
                  if (this.check_id == res.data.data.data[i].id) {
                    this.change_active(
                      res.data.data.data[i].id,
                      res.data.data.data[i].parent_id,
                      res.data.data.data[i].depth,
                      res.data.data.data[i].name,
                      res.data.data.data[i].type,
                      res.data.data.data[i].is_ranking
                    );
                  }
                  for (let v in res.data.data.data[i].data) {
                    for (let f in this.left_list.data[i].data) {
                      if (this.left_list.data[i].data[f].arrow) {
                        res.data.data.data[i].data[f].arrow = true;
                        // break;
                      }
                    }
                    if (this.check_id == res.data.data.data[i].data[v].id) {
                      this.change_active(
                        res.data.data.data[i].data[v].id,
                        res.data.data.data[i].data[v].parent_id,
                        res.data.data.data[i].data[v].depth,
                        res.data.data.data[i].data[v].name,
                        res.data.data.data[i].data[v].type,
                        res.data.data.data[i].data[v].is_ranking
                      );
                    }
                    for (let j in res.data.data.data[i].data[v].data) {
                      for (let f in this.left_list.data[i].data[v].data) {
                        if (this.left_list.data[i].data[v].data[f].arrow) {
                          res.data.data.data[i].data[v].data[f].arrow = true;
                        }
                      }

                      if (
                        this.check_id ==
                        res.data.data.data[i].data[v].data[j].id
                      ) {
                        this.change_active(
                          res.data.data.data[i].data[v].data[j].id,
                          res.data.data.data[i].data[v].data[j].parent_id,
                          res.data.data.data[i].data[v].data[j].depth,
                          res.data.data.data[i].data[v].data[j].name,
                          res.data.data.data[i].data[v].data[j].type,
                          res.data.data.data[i].data[v].data[j].is_ranking
                        );
                      }
                      for (let h in res.data.data.data[i].data[v].data[j]
                        .data) {
                        for (let a in this.left_list.data[i].data[v].data[j]
                          .data) {
                          if (
                            this.left_list.data[i].data[v].data[j].data[a].arrow
                          ) {
                            res.data.data.data[i].data[v].data[j].data[
                              a
                            ].arrow = true;
                          }
                        }

                        if (
                          this.check_id ==
                          res.data.data.data[i].data[v].data[j].data[h].id
                        ) {
                          this.change_active(
                            res.data.data.data[i].data[v].data[j].data[h].id,
                            res.data.data.data[i].data[v].data[j].data[h]
                              .parent_id,
                            res.data.data.data[i].data[v].data[j].data[h].depth,
                            res.data.data.data[i].data[v].data[j].data[h].name,
                            res.data.data.data[i].data[v].data[j].data[h].type,
                            res.data.data.data[i].data[v].data[j].data[h]
                              .is_ranking
                          );
                        }
                      }
                    }
                  }
                }
                if (this.check_id == res.data.data.id) {
                  this.change_active(
                    res.data.data.id,
                    res.data.data.parent_id,
                    res.data.data.depth,
                    res.data.data.name,
                    res.data.data.type,
                    res.data.data.is_ranking
                  );
                }
                this.left_list = res.data.data;
                this.drawer = false;
                this.add_flag = false;
              }
            });
          } else if (res.data.code == 400) {
          }
        });
      } else {
        if (this.sell_type == "是") {
          type = 1;
        } else {
          type = 2;
        }
        let is_ranking = "";
        if (this.compony_isjoin == "是") {
          is_ranking = 1;
        } else if (this.compony_isjoin == "否") {
          is_ranking = 2;
        }
        let str = "";
        for (let i in this.role_alldata.data) {
          if (this.role_alldata.data[i].permissions == "change_company") {
            str = this.role_alldata.data[i].permissions_id_string;
          }
        }
        if (JSON.stringify(this.leader_check) == "{}") {
          this.$message.error("请输入领导人名字后选择噢~");
          this.add_flag = false;
          return;
        }
        if (this.leader != this.leader_check.value) {
          this.$message.error("请重新输入领导人名字后选择噢~");
          this.add_flag = false;
          return;
        }
        update({
          name: truename,
          parent_id: id,
          type,
          is_ranking,
          permissions_id_string: str,
          business_line_id: this.business,
          leader: this.leader_check.id,
          is_all: this.is_cansee_all
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            selectCompanyAll().then((res) => {
              if (res.data.code == 400) {
                this.$message({
                  type: "error",
                  message: res.data.message,
                });
              } else if (res.data.code == 200) {
                res.data.data.arrow = true;
                for (let i in res.data.data.data) {
                  if (this.left_list.data[i].arrow == true) {
                    res.data.data.data[i].arrow = true;
                  } else {
                    res.data.data.data[i].arrow = false;
                  }
                  if (this.check_id == res.data.data.data[i].id) {
                    this.change_active(
                      res.data.data.data[i].id,
                      res.data.data.data[i].parent_id,
                      res.data.data.data[i].depth,
                      res.data.data.data[i].name,
                      res.data.data.data[i].type,
                      res.data.data.data[i].is_ranking
                    );
                  }
                  for (let v in res.data.data.data[i].data) {
                    if (this.left_list.data[i].data[v].arrow == true) {
                      res.data.data.data[i].data[v].arrow = true;
                    } else {
                      res.data.data.data[i].data[v].arrow = false;
                    }
                    if (this.check_id == res.data.data.data[i].data[v].id) {
                      this.change_active(
                        res.data.data.data[i].data[v].id,
                        res.data.data.data[i].data[v].parent_id,
                        res.data.data.data[i].data[v].depth,
                        res.data.data.data[i].data[v].name,
                        res.data.data.data[i].data[v].type,
                        res.data.data.data[i].data[v].is_ranking
                      );
                    }
                    for (let j in res.data.data.data[i].data[v].data) {
                      if (this.left_list.data[i].data[v].data[j]) {
                        if (
                          this.left_list.data[i].data[v].data[j].arrow == true
                        ) {
                          res.data.data.data[i].data[v].data[j].arrow = true;
                        } else {
                          res.data.data.data[i].data[v].data[j].arrow = false;
                        }
                      }
                      // console.log(this.check_id,res.data.data.data[i].data[v].data[j].id);
                      if (
                        this.check_id ==
                        res.data.data.data[i].data[v].data[j].id
                      ) {
                        this.change_active(
                          res.data.data.data[i].data[v].data[j].id,
                          res.data.data.data[i].data[v].data[j].parent_id,
                          res.data.data.data[i].data[v].data[j].depth,
                          res.data.data.data[i].data[v].data[j].name,
                          res.data.data.data[i].data[v].data[j].type,
                          res.data.data.data[i].data[v].data[j].is_ranking
                        );
                      }
                      for (let a in res.data.data.data[i].data[v].data[j]
                        .data) {
                        if (this.left_list.data[i].data[v].data[j].data[a]) {
                          if (
                            this.left_list.data[i].data[v].data[j].data[a]
                              .arrow == true
                          ) {
                            res.data.data.data[i].data[v].data[j].data[
                              a
                            ].arrow = true;
                          } else {
                            res.data.data.data[i].data[v].data[j].data[
                              a
                            ].arrow = false;
                          }
                        }
                        // console.log(this.check_id,res.data.data.data[i].data[v].data[j].data[a].id);
                        // res.data.data.data[i].data[v].id
                        if (
                          this.check_id ==
                          res.data.data.data[i].data[v].data[j].data[a].id
                        ) {
                          this.change_active(
                            res.data.data.data[i].data[v].data[j].data[a].id,
                            res.data.data.data[i].data[v].data[j].data[a]
                              .parent_id,
                            res.data.data.data[i].data[v].data[j].data[a].depth,
                            res.data.data.data[i].data[v].data[j].data[a].name,
                            res.data.data.data[i].data[v].data[j].data[a].type,
                            res.data.data.data[i].data[v].data[j].data[a]
                              .is_ranking
                          );
                        }
                        for (let b in res.data.data.data[i].data[v].data[j]
                          .data[a].data) {
                          if (
                            this.check_id ==
                            res.data.data.data[i].data[v].data[j].data[a].data[
                              b
                            ].id
                          ) {
                            this.change_active(
                              res.data.data.data[i].data[v].data[j].data[a]
                                .data[b].id,
                              res.data.data.data[i].data[v].data[j].data[a]
                                .data[b].parent_id,
                              res.data.data.data[i].data[v].data[j].data[a]
                                .data[b].depth,
                              res.data.data.data[i].data[v].data[j].data[a]
                                .data[b].name,
                              res.data.data.data[i].data[v].data[j].data[a]
                                .data[b].type,
                              res.data.data.data[i].data[v].data[j].data[a]
                                .data[b].is_ranking
                            );
                          }
                        }
                      }
                    }
                  }
                }
                if (this.check_id == res.data.data.id) {
                  this.change_active(
                    res.data.data.id,
                    res.data.data.parent_id,
                    res.data.data.depth,
                    res.data.data.name,
                    res.data.data.type,
                    res.data.data.is_ranking
                  );
                }
                this.left_list = res.data.data;
                this.drawer = false;
                this.add_flag = false;
              }
            });
          } else if (res.data.code == 400) {
          }
        });
      }
    },
    //添加成员时获取下一级部门
    getchild(id) {
      select({ parent_id: id }).then((res) => {
        //获取下一级
        for (let i = 0; i < res.data.data.length; i++) {
          for (let v = 0; v < this.truely_compony_list.length; v++) {
            // console.log(this.truely_compony_list[v].id, res.data.data[i].id);
            if (this.truely_compony_list[v].id == res.data.data[i].id) {
              res.data.data[i].ischeck = true;
              break;
            } else {
              res.data.data[i].ischeck = false;
            }
          }
        }
        this.add_user_compony_list = res.data.data;
        addUserGetAncestorsAndSelf({
          company_id: id,
        }).then((res) => {
          this.add_user_list = res.data.data;
        });
      });
    },
    getadminchild(id) {
      // admin_componey
      select({ parent_id: id }).then((res) => {
        //获取下一级
        for (let i = 0; i < res.data.data.length; i++) {
          for (let v = 0; v < this.admin_componey.length; v++) {
            // console.log(this.truely_compony_list[v].id, res.data.data[i].id);
            if (this.admin_componey[v].id == res.data.data[i].id) {
              res.data.data[i].ischeck = true;
              break;
            } else {
              res.data.data[i].ischeck = false;
            }
          }
        }
        this.add_user_compony_list = res.data.data;
        addUserGetAncestorsAndSelf({
          company_id: id,
        }).then((res) => {
          this.add_user_list = res.data.data;
        });
      });
    },
    //删除部门
    del_compony() {
          console.log(this.depth)
            if(this.depth<=3){
                this.$confirm("是否转移公海客户?", "提示", {
                    confirmButtonText: "是",
                    cancelButtonText: "否",
                    type: "warning",
                })
                    .then(() => {
                      this.dialogVisiblegh=true
                    })
                    .catch(() => {
                        this.$confirm("此操作将永久删除该部门, 是否继续?", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                        })
                            .then(() => {
                                this.delete_bm('')
                            })
                            .catch(() => {
                                this.$message({
                                    type: "info",
                                    message: "已取消删除",
                                });
                            });
                    });
            }else{
                this.$confirm("此操作将永久删除该部门, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                       this.delete_bm('')
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            }

    },

    no_compony() {
      this.shadow = false;
      // this.truely_compony_list = [];
      this.admin_shadow = false;
    },
    no_admin_compony() {
      this.shadow = false;
      // this.truely_compony_list = [];
      this.admin_shadow = false;
    },
    check_compony() {
      this.user_componey = JSON.parse(JSON.stringify(this.truely_compony_list));
      if(this.user_componey.length>1){
          this.$message.error("您不可以选择两个所属部门");
      }else{
            checkDivision({
                user_id_str: this.user_id,
                company_id:this.user_componey[0].id
            }).then(res=>{
                if(res.data.data.is_span==1){
                   this.dialogVisible=true
                }else{
                    this.shadow = false;
                }
            });

      }

    },
      //删除是部门
      delete_bm(transfer_company_id){
          let str = "";
          for (let i in this.role_alldata.data) {
              if (this.role_alldata.data[i].permissions == "del_company") {
                  str = this.role_alldata.data[i].permissions_id_string;
              }
          }
          deleteCompany({
              parent_id: this.id,
              permissions_id_string: str,
              transfer_company_id:transfer_company_id
          }).then((res) => {
              if (res.data.code == 200) {
                  this.$message({
                      type: "success",
                      message: "删除成功",
                  });
                  if (this.is_serch) {
                      searchCompany({ name: this.serch_val }).then((res) => {
                          this.serch_compony_list = res.data.data.company;
                          this.serch_user_list = res.data.data.user;
                      });
                  }

                  selectCompanyAll().then((res) => {
                      if (res.data.code == 400) {
                          this.$message.error(res.data.message);
                      } else if (res.data.code == 200) {
                          for (let i in res.data.data.data) {
                              res.data.data.data[i].arrow = false;
                              for (let v in res.data.data.data[i].data) {
                                  res.data.data.data[i].data[v].arrow = false;
                                  for (let j in res.data.data.data[i].data[v].data) {
                                      if (res.data.data.data[i].data[v].data[j]) {
                                          res.data.data.data[i].data[v].data[j].arrow = false;
                                      }
                                      for (let h in res.data.data.data[i].data[v].data[j]
                                          .data) {
                                          if (res.data.data.data[i].data[v].data[j].data[h]) {
                                              res.data.data.data[i].data[v].data[j].data[
                                                  h
                                                  ].arrow = false;
                                          }
                                      }
                                  }
                              }
                          }
                          res.data.data.arrow = true;
                          for (let i in this.left_list.data) {
                              for (let v in res.data.data.data) {
                                  if (this.left_list.data[v].arrow) {
                                      res.data.data.data[v].arrow = true;
                                  }
                                  for (let f in res.data.data.data[v].data) {
                                      if (this.left_list.data[v].data[f].arrow) {
                                          res.data.data.data[v].data[f].arrow = true;
                                      }
                                      for (let g in res.data.data.data[v].data[f].data) {
                                          if (this.left_list.data[v].data[f].data[g].arrow) {
                                              res.data.data.data[v].data[f].data[g].arrow = true;
                                          }
                                          for (let a in res.data.data.data[v].data[f].data[g]
                                              .data) {
                                              if (
                                                  this.left_list.data[v].data[f].data[g].data[a]
                                                      .arrow
                                              ) {
                                                  res.data.data.data[v].data[f].data[g].data[
                                                      a
                                                      ].arrow = true;
                                              }
                                          }
                                      }
                                  }
                              }
                          }

                          if (this.check_id == res.data.data.id) {
                              this.change_active(
                                  res.data.data.id,
                                  res.data.data.parent_id,
                                  res.data.data.depth,
                                  res.data.data.name,
                                  res.data.data.type,
                                  res.data.data.is_ranking
                              );
                          }
                          this.change_active(
                              res.data.data.id,
                              res.data.data.parent_id,
                              res.data.data.depth,
                              res.data.data.name,
                              res.data.data.type,
                              res.data.data.is_ranking
                          );
                          this.left_list = res.data.data;
                          this.drawer = false;
                      }
                  });
              } else if (res.data.code == 400) {
                  this.$message.error(res.data.message);
              }
          });
      },
    check_admin_compony() {
      this.admin_true_componey = JSON.parse(
        JSON.stringify(this.admin_componey)
      );
      this.admin_shadow = false;
    },
    //用户新增
    checkuser() {
      let position_id = this.position;
      let company_id = "";
      if (this.add_flag) {
        return;
      }
      for (let i in this.user_componey) {
        company_id += this.user_componey[i].id + ",";
      }
      if (company_id.length > 0) {
        company_id = company_id.substr(0, company_id.length - 1);
      }
      let role_id = "";
      // console.log(this.role);
      for (let i in this.role) {
        role_id += this.role[i] + ",";
      }
      if (role_id.length > 0) {
        role_id = role_id.substr(0, role_id.length - 1);
      }
      // console.log(this.role);
      let other_company_id = "";
      for (let i in this.admin_true_componey) {
        other_company_id += this.admin_true_componey[i].id + ",";
      }
      if (other_company_id.length > 0) {
        other_company_id = other_company_id.substr(
          0,
          other_company_id.length - 1
        );
      }
      let sex = "";
      if (this.sex == "男" || this.sex == 1) {
        sex = 1;
      }
      if (this.sex == "女" || this.sex == 2) {
        sex = 2;
      }
      let is_ranking = "";

      if (this.is_adduser) {
        let str = "";
        for (let i in this.role_alldata.data) {
          if (this.role_alldata.data[i].permissions == "add_user") {
            str = this.role_alldata.data[i].permissions_id_string;
          }
        }
        if (this.status == 0) {
          if (this.user_componey.length > 1) {
            this.$message.error("您不可以选择两个所属部门");
            return;
          }
        } else {
          if (this.admin_true_componey.length == 0) {
            this.$message.error("您是管理人员必须选择一个以上管理部门");
            return;
          }
        }
        this.add_flag = true;
        var partten = /^1[3,4,6,9,5,8,7]\d{9}$/;
        if (!partten.test(this.mobile)) {
          this.add_flag = false;
          this.$message({
            type: "error",
            message: "电话填写错误，请检查后输入",
          });
          return;
        }
        user_add({
          name: this.username,
          email: this.email,
          phone: this.mobile,
          position_id: this.position_name.id,
          company_id,
          role_id,
          entry_time: this.date,
          sex: this.sex,
          is_ranking: this.rank,
          permissions_id_string: str,
          other_company_id,
        }).then((res) => {
          this.add_flag = false;
          if (res.data.code == 200) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.drawer = false;
            // parent_id, depth, name, type
            this.change_active(
              this.title_list[this.title_list.length - 1].id,
              this.title_list[this.title_list.length - 1].parent_id,
              this.title_list[this.title_list.length - 1].depth,
              this.title_list[this.title_list.length - 1].name,
              this.title_list[this.title_list.length - 1].type,
              this.title_list[this.title_list.length - 1].is_ranking
            );
            selectCompanyAll().then((res) => {
              if (res.data.code == 400) {
                this.add_flag = false;

                this.$message({
                  type: "error",
                  message: res.data.message,
                });
              } else if (res.data.code == 200) {
                res.data.data.arrow = true;
                for (let i in res.data.data.data) {
                  if (this.left_list.data[i].arrow == true) {
                    res.data.data.data[i].arrow = true;
                  } else {
                    res.data.data.data[i].arrow = false;
                  }
                  if (this.check_id == res.data.data.data[i].id) {
                    this.change_active(
                      res.data.data.data[i].id,
                      res.data.data.data[i].parent_id,
                      res.data.data.data[i].depth,
                      res.data.data.data[i].name,
                      res.data.data.data[i].type,
                      res.data.data.data[i].is_ranking
                    );
                  }
                  for (let v in res.data.data.data[i].data) {
                    if (this.left_list.data[i].data[v].arrow == true) {
                      res.data.data.data[i].data[v].arrow = true;
                    } else {
                      res.data.data.data[i].data[v].arrow = false;
                    }
                    if (this.check_id == res.data.data.data[i].data[v].id) {
                      this.change_active(
                        res.data.data.data[i].data[v].id,
                        res.data.data.data[i].data[v].parent_id,
                        res.data.data.data[i].data[v].depth,
                        res.data.data.data[i].data[v].name,
                        res.data.data.data[i].data[v].type,
                        res.data.data.data[i].data[v].is_ranking
                      );
                    }
                    for (let j in res.data.data.data[i].data[v].data) {
                      if (this.left_list.data[i].data[v].data[j]) {
                        if (
                          this.left_list.data[i].data[v].data[j].arrow == true
                        ) {
                          res.data.data.data[i].data[v].data[j].arrow = true;
                        } else {
                          res.data.data.data[i].data[v].data[j].arrow = false;
                        }
                      }
                      // console.log(this.check_id,res.data.data.data[i].data[v].data[j].id);
                      if (
                        this.check_id ==
                        res.data.data.data[i].data[v].data[j].id
                      ) {
                        this.change_active(
                          res.data.data.data[i].data[v].data[j].id,
                          res.data.data.data[i].data[v].data[j].parent_id,
                          res.data.data.data[i].data[v].data[j].depth,
                          res.data.data.data[i].data[v].data[j].name,
                          res.data.data.data[i].data[v].data[j].type,
                          res.data.data.data[i].data[v].data[j].is_ranking
                        );
                      }
                      for (let a in res.data.data.data[i].data[v].data[j]
                        .data) {
                        if (this.left_list.data[i].data[v].data[j].data[a]) {
                          if (
                            this.left_list.data[i].data[v].data[j].data[a]
                              .arrow == true
                          ) {
                            res.data.data.data[i].data[v].data[j].data[
                              a
                            ].arrow = true;
                          } else {
                            res.data.data.data[i].data[v].data[j].data[
                              a
                            ].arrow = false;
                          }
                        }
                        // console.log(this.check_id,res.data.data.data[i].data[v].data[j].data[a].id);
                        // res.data.data.data[i].data[v].id
                        if (
                          this.check_id ==
                          res.data.data.data[i].data[v].data[j].data[a].id
                        ) {
                          this.change_active(
                            res.data.data.data[i].data[v].data[j].data[a].id,
                            res.data.data.data[i].data[v].data[j].data[a]
                              .parent_id,
                            res.data.data.data[i].data[v].data[j].data[a].depth,
                            res.data.data.data[i].data[v].data[j].data[a].name,
                            res.data.data.data[i].data[v].data[j].data[a].type,
                            res.data.data.data[i].data[v].data[j].data[a]
                              .is_ranking
                          );
                        }
                        for (let b in res.data.data.data[i].data[v].data[j]
                          .data[a].data) {
                          if (
                            this.check_id ==
                            res.data.data.data[i].data[v].data[j].data[a].data[
                              b
                            ].id
                          ) {
                            this.change_active(
                              res.data.data.data[i].data[v].data[j].data[a]
                                .data[b].id,
                              res.data.data.data[i].data[v].data[j].data[a]
                                .data[b].parent_id,
                              res.data.data.data[i].data[v].data[j].data[a]
                                .data[b].depth,
                              res.data.data.data[i].data[v].data[j].data[a]
                                .data[b].name,
                              res.data.data.data[i].data[v].data[j].data[a]
                                .data[b].type,
                              res.data.data.data[i].data[v].data[j].data[a]
                                .data[b].is_ranking
                            );
                          }
                        }
                      }
                    }
                  }
                }
                if (this.check_id == res.data.data.id) {
                  this.change_active(
                    res.data.data.id,
                    res.data.data.parent_id,
                    res.data.data.depth,
                    res.data.data.name,
                    res.data.data.type,
                    res.data.data.is_ranking
                  );
                }
                this.left_list = res.data.data;
                this.drawer = false;
                this.add_flag = false;
              }
            });
          } else if (res.data.code == 400) {
            this.$message.error(res.data.message);
          }
        });
      } else {
          if (this.status == 0) {
              if (this.user_componey.length > 1) {
                  this.$message.error("您不可以选择两个所属部门");
                  return;
              }
          } else {
              if (this.admin_true_componey.length == 0) {
                  this.$message.error("您是管理人员必须选择一个以上管理部门");
                  return;
              }
          }
        let str = "";
        for (let i in this.role_alldata.data) {
          if (this.role_alldata.data[i].permissions == "change_user") {
            str = this.role_alldata.data[i].permissions_id_string;
          }
        }
        let is_ranking = "";
        if (this.rank == "是") {
          is_ranking = 1;
        } else if (this.rank == "否") {
          is_ranking = 2;
        } else {
          is_ranking = this.rank;
        }
        if (this.position_name.status == 0) {
          if (this.user_componey.length > 1) {
            this.$message.error("您不是管理人员不可以选择两个部门");
            return;
          }
        } else {
          if (this.admin_true_componey.length == 0) {
            this.$message.error("您是管理人员必须选择一个以上的管理部门");
            return;
          }
        }
        this.add_flag = true;
        var partten = /^1[3,4,6,9,5,8,7]\d{9}$/;
        if (!partten.test(this.mobile)) {
          this.add_flag = false;
          this.$message({
            type: "error",
            message: "电话填写错误，请检查后输入",
          });
          return;
        }
        edit({
          name: this.username,
          email: this.email,
          phone: this.mobile,
          position_id: this.position_name.id,
          company_id,
          role_id,
          entry_time: this.date,
          sex,
          user_id_str: this.user_id,
          permissions_id_string: str,
          is_ranking,
          other_company_id,
            take_customer_type:this.take_customer_type
        }).then((res) => {
          this.add_flag = false;

          if (res.data.code == 200) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.drawer = false;
            // parent_id, depth, name, type
            this.change_active(
              this.title_list[this.title_list.length - 1].id,
              this.title_list[this.title_list.length - 1].parent_id,
              this.title_list[this.title_list.length - 1].depth,
              this.title_list[this.title_list.length - 1].name,
              this.title_list[this.title_list.length - 1].type,
              this.title_list[this.title_list.length - 1].is_ranking
            );
          } else if (res.data.code == 400) {
            this.$message.error(res.data.message);
          }
        });
      }
    },
    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); // let strDate = Y + M + D;
      let strDate = Y + M + D;
      return strDate;
    },
      //是否带走客户
      onSucc(){
          this.take_customer_type=this.take_customer_type;
          this.dialogVisible=false;
          this.shadow = false;
      },
      //切换事业部
      handleClick(value) {
          //选择 删除下级children
          //展开可选择不同层级
          if (value.length == 0) {
              ++this.isResouceShow;
              this.search_company_id = [];
              return;
          }
          let options = JSON.parse(JSON.stringify(this.options));
          function dg(list) {
              list.forEach((item) => {
                  if (item.data) {
                      item.children = item.data;
                      dg(item.data);
                  }
              });
              return list;
          }
          dg(options);
          this.options = options;
          if (value.length == 0) {
              ++this.isResouceShow;
              return;
          }
          let check_arr = this.$refs["myCascader"].getCheckedNodes();
          this.search_company_id = [];
          let arr = this.search_company_id;
          function depdg(options, check_arr) {
              for (let i = 0; i < options.length; i++) {
                  for (let j = 0; j < check_arr.length; j++) {
                      if (options[i].id == check_arr[j].value) {
                          if (options[i].data) {
                              delete options[i].children;
                          }
                      } else {
                          if (options[i].data) {
                              depdg(options[i].data, check_arr);
                          }
                      }
                  }
              }
          }
          depdg(options, check_arr);
          this.options = JSON.parse(JSON.stringify(options));
          check_arr.forEach((item) => {
              arr.push(item.value);
          });
          this.search_company_id = arr;

      },
      //转移公海客户
      onyes(){
          this.delete_bm(this.search_company_id[0]);
          this.dialogVisiblegh=false
      }
  },
  watch: {
    serch_val(val) {
      if (val == "") {
        this.serch_compony_list = [];
        this.serch_user_list = [];
        return;
      }
      searchCompany({ name: val }).then((res) => {
        this.serch_compony_list = res.data.data.company;
        this.serch_user_list = res.data.data.user;
      });
    },
    leader(v) {
      if (v == "") {
        this.leader_check = {};
      }
      // console.log(this.leader_check);

      // console.log(v);
    },
  },
};
</script>

<style lang="scss">
  .el-popup-parent--hidden{
    padding-right:0 !important;
  }
.human_child_bigbox {
  .honor_show {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    padding-top: 2px;
    .acc_commen {
      width: 545px;
      height: 308px;
      background: #ffffff;
      border: 1px solid #707070;
      opacity: 1;
      border-radius: 10px;
      // margin: 100px auto;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      .commen_body {
        padding: 20px;
        box-sizing: border-box;
        .add_title {
          display: flex;
          align-items: center;
          white-space: nowrap;
          .el-input {
            margin-left: 10px;
          }
        }
        .image_info {
          border-radius: 5px;
          width: 100px;
          height: 100px;
          position: relative;
          display: flex;
          background: #ececec;
          // color: #3A85FC;
          color: rgba(0, 0, 0, 0.28);
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .flow_img_listinfo {
          margin-top: 10px;
          display: flex;
          .img_item_info {
            width: 100px;
            height: 100px;
            position: relative;
            margin-right: 10px;
            .big_img {
              width: 100%;
              height: 100%;
            }
            .del_img {
              position: absolute;
              top: -5px;
              right: -5px;
              color: #ccc;
            }
          }
        }

        .acc_title {
          margin-top: 20px;
          height: 34px;
          align-items: center;
          display: flex;
          // margin-left: -11px;
          // font-weight: 600;
          .yuan {
            margin-right: 5px;
            width: 3px;
            height: 15px;
            background: #3a85fc;
            border-radius: 1.5px;
            opacity: 1;
          }
        }
      }

      .acc_commen_title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;
        position: relative;
        height: 40px;
        border-bottom: 1px solid #ccc;
        i {
          position: absolute;
          // top: ;
          right: 10px;
        }
      }
      .acc_commen_body {
        height: 100%;
        overflow: auto;
        width: 100%;
        resize: none;
        padding: 10% 10%;
        .el-textarea__inner {
          resize: none;
          height: 270px;
        }
      }
      .acc_commen_sent {
        position: absolute;
        right: 10px;
        padding: 0 10px;
        height: 25px;
        line-height: 25px;
        bottom: 10px;
        background: #3a85fc;
        color: #fff;
        border-radius: 5px;
      }
    }
  }
  .acc_img_shadow {
    widows: 100%;
    height: 100%;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2000;
    height: 100%;
    img {
      display: block;
      width: 300px;
      max-height: 600px;
      margin: 200px auto;
    }
  }
}
.human_xiugai_box {
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  position: fixed;
  z-index: 21;
  background: rgba(0, 0, 0, 0.5);
  .human_info {
    width: 700px;
    height: 500px;
    background: #fff;
    margin: 0 auto;
    margin-top: 50px;
    position: relative;
  }
}
.human_nosent {
  color: #fff !important;
  background: #ccc !important;
}
.el-main {
  padding: 8px;
  .el-drawer {
    // width: ;
    min-width: 650px;
  }
}
.el-menu {
  border-right: none;
}
.human_leftcheck {
  background: #ffff;
}
.el-drawer:focus {
  outline: none;
}
.colorb {
  color: #0089ff;
  background: #fff;
  border: 1px solid #ffffff;
}
.color9 {
  color: #fefefe;
  background: #fff;
  border: 1px solid #ffffff;
}
.pointer {
  cursor: pointer;
}
.human_shadow {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  padding-top: 2px;
  .human_shadowInfo {
    margin: 30px auto;
    box-sizing: border-box;
    width: 700px;
    border: 1px solid #dedede;
    border-radius: 8px;
    background: #fff;
    height: 554px;
    overflow: hidden;
    .human_check_compony_title {
      width: 100%;
      padding: 15px 35px;
      font-size: 20px;
      width: 100%;
      background: #eceff4;
      text-align: center;
    }
    .classify {
      // line-height: 40px;
      // display: flex;
      // align-items: center;
      // flex-wrap: nowrap;
      width: 100%;
      overflow-y: auto;
      white-space: nowrap; /*规定段落中的文本不进行换行 */
      div {
        line-height: 40px;
        display: inline-block;
        cursor: pointer;
        width: 25%;
        text-align: center;
        overflow: hidden; /*超出部分隐藏*/
        text-overflow: ellipsis; /* 超出部分显示省略号 */
        white-space: nowrap; /*规定段落中的文本不进行换行 */
        border: 1px solid #e5e5e5;
      }
      .check_title {
        color: #fff;
        background: #3a85fc;
      }
    }
    .classify::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    .classify::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 2px;
    }
    .classify::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 10px;
    }
    .human_check_compony_info {
      height: 374px;
      overflow: auto;
      padding: 20px 20px;
      div {
        height: 30px;
        display: inline-block;
        width: 25%;
        overflow: hidden; /*超出部分隐藏*/
        text-overflow: ellipsis; /* 超出部分显示省略号 */
        white-space: nowrap; /*规定段落中的文本不进行换行 */
        .el-radio {
          width: 100%;
          overflow: hidden; /*超出部分隐藏*/
          text-overflow: ellipsis; /* 超出部分显示省略号 */
          white-space: nowrap; /*规定段落中的文本不进行换行 */
        }
      }
    }
    .human_check_compony_body {
      padding: 0px 35px;
      width: 100%;
      box-sizing: border-box;
      margin-top: 10px;
      // height: 80px;
      .human_check_compony_fir {
        display: flex;
        div {
          flex: 1;
          font-size: 16px;
          color: #363636;
          padding: 14px 0px;
        }
      }
      .human_check_compony_sec {
        height: 360px;
        // display: flex;
        width: 100%;
        .sec {
          height: 100%;
          width: 45%;
          float: left;
          // width: 100%;
          border: 1px solid #dedede;
          border-radius: 4px;
          background: #f4f6f8;
          margin-left: 5%;
          overflow: auto;
          .rightList {
            width: 100%;
            padding: 0 10px;
            display: flex;
            align-items: center;
            /*height: 30px;*/
            border-bottom: 1px solid #cccccc;
            justify-content: space-between;
            div{
              padding: 5px 0;
            }
          }
        }
        .fir {
          // flex: 1;
          height: 100%;
          width: 45%;
          float: left;
          // width: 100%;
          border: 1px solid #dedede;
          border-radius: 4px;
          background: #f4f6f8;
          position: relative;
          .bread {
            // width: 100%;
            height: 50px;
            overflow: auto;
            // text-align: center;
            padding: 0 20px;
            white-space: nowrap;
            color: #38adff;
            div {
              font-size: 14px;
              line-height: 50px;
              // float: left;
              display: inline-block;
            }
          }
          .left_list {
            // margin-top: 20px;
            height: 300px;
            overflow: auto;
            .left_list_info {
              display: flex;
              padding: 0 10px;
              box-sizing: border-box;
              height: 28px;
              align-items: center;
              border-bottom: 1px solid #cccccc;
              .list_right {
                flex: 1;
                display: flex;
                justify-content: space-between;
              }
            }
          }
        }
      }
      .human_check_compony_thr {
        width: 100%;
        margin-top: 20px;
        div {
          width: 200px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          div:nth-of-type(1) {
            display: inline-block;
            padding: 5px 12px;
            margin-bottom: 0;
            font-size: 16px;
            line-height: 1.42857143;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            cursor: pointer;
            border: 1px solid #c3c6ce;
            background: #ffffff;
            border-radius: 4px;
            min-width: 80px;
            color: #414141;
            position: relative;
            color: #fff;
            background-color: #38adff;
            border-color: rgba(0, 140, 238, 0.72);
          }
          div:nth-of-type(2) {
            display: inline-block;
            text-align: center;
            padding: 5px 12px;
            cursor: pointer;
            border: 1px solid #c3c6ce;
            background: #ffffff;
            border-radius: 4px;
            min-width: 80px;
            color: #414141;
            position: relative;
            margin-left: 10px;
            color: #38adff;
            background-color: #fff;
            border-color: rgba(0, 140, 238, 0.72);
          }
        }
      }
    }
  }
}
.human_drawer .el-drawer__body {
  position: relative;
  min-width: 660px;
  overflow: auto;
}
.drawer_bottom {
  // min-width: 650px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 0 10%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #eee;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  bottom: 0;
  background: #fff;
  div {
    width: 70px;
    color: #fff;
    background-color: #38adff;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
}
.human_child_bigbox {
  .acc_commen {
    width: 670px;
    // max-height:74%;
    // height: 74%;
    height: 620px;
    // min-height: 25%;
    background: #ffffff;
    border: 1px solid #707070;
    opacity: 1;
    border-radius: 10px;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // left: ;
    // position: relative;
    .acc_commen_title {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
      position: relative;
      height: 40px;
      border-bottom: 1px solid #ccc;
      i {
        position: absolute;
        // top: ;
        right: 10px;
      }
    }
    .acc_commen_body::-webkit-scrollbar {
      width: 0;
    }
    .acc_commen_body {
      overflow: auto;
      height: 577px;
      overflow: auto;
      width: 100%;
      resize: none;
      padding: 5% 5%;
      .btn {
        width: 114px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 104px;
        height: 35px;
        background: #3a85fc;
        color: #fff;
        opacity: 1;
        border-radius: 4px;
        i {
          margin-right: 2px;
        }
      }
      .acc_info {
        margin-top: 20px;
        .acc_item {
          display: inline-block;
          height: 197px;
          width: 145px;
          // background: #000;
          .imginfo {
            height: 145px;
            width: 145px;
            margin-bottom: 10px;
            position: relative;
            i {
              position: absolute;
              top: -7px;
              right: -7px;
              font-size: 16px;
            }
            img {
              border-radius: 5px;
            }
          }
        }
        .acc_item:nth-of-type(1) {
          margin-left: 0;
        }
        .acc_item:nth-of-type(n + 2) {
          margin-top: 20px;
          margin-left: 83px;
        }
        .acc_item:nth-of-type(3n + 1) {
          margin-left: 0px;
        }
      }
      .el-textarea__inner {
        resize: none;
        height: 270px;
      }
    }
    .bottom {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 100%;
    }
    .acc_commen_sent {
      position: absolute;
      right: 10px;
      padding: 0 10px;
      height: 25px;
      line-height: 25px;
      bottom: 10px;
      background: #3a85fc;
      color: #fff;
      border-radius: 5px;
    }
  }
}

.drawer_compony_info {
  display: flex;
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  align-items: center;
  .drawer_position {
    width: 90%;
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    text-align: left;
    color: #606266;
    // overflow: auto;
    // overflow-y: hidden;
    // overflow-x: scroll;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    overflow: hidden; /*超出部分隐藏*/
    text-overflow: ellipsis; /* 超出部分显示省略号 */
    white-space: nowrap; /*规定段落中的文本不进行换行 */
  }
  .humancompony_info {
    width: 90%;
    padding: 1px 10px;
    /*height: 40px;*/
    // overflow: auto;
    // overflow-y: hidden;
    // overflow-x: scroll;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    /*overflow: hidden; !*超出部分隐藏*!*/
    /*text-overflow: ellipsis; !* 超出部分显示省略号 *!*/
    /*white-space: nowrap; !*规定段落中的文本不进行换行 *!*/
    div {
      display: inline-block;
      margin-left: 5px;
      height: 30px;
      // vertical-align: middle;
      text-align: center;
      line-height: 30px;
      background: #fafafa;
      color: #999;
      min-width: 20px;
      margin-top: 5px;
      /*max-width: 100px;*/
      /*overflow: hidden; !*超出部分隐藏*!*/
      /*text-overflow: ellipsis; !* 超出部分显示省略号 *!*/
      /*white-space: nowrap; !*规定段落中的文本不进行换行 *!*/
    }
    div:nth-of-type(1) {
      margin-left: 0;
    }
  }
  .drawer_nice {
    width: 80px;
  }
  .el-input {
    width: 100% !important;
  }
  .el-select {
    width: 90%;
  }
}
.compony_detail {
  margin: 0 auto;
  margin-top: 20px;
  width: 80%;
  padding: 0px 15px;
  min-height: 38px;
  background: #eceff4;
  line-height: 38px;
}
.drawer_title {
  position: absolute;
  top: 0;
  left: 0;
  padding: 19px 35px;
  font-size: 20px;
  width: 100%;
  background: #fff;
  z-index: 1;
  border-bottom: 1px solid #eee;
}
.drawer_all {
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  padding-bottom: 60px;
  position: relative;
  padding-top: 70px;
}
.human_list {
  width: 100%;
  // display: flex;
  .human_list_title {
    display: flex;
    border-bottom: 1px solid #ebebeb;

    div {
      // flex: 2;
      width: 20%;
      text-align: center;
      height: 30px;
      overflow: hidden; /*超出部分隐藏*/
      text-overflow: ellipsis; /* 超出部分显示省略号 */
      white-space: nowrap; /*规定段落中的文本不进行换行 */
      line-height: 30px;
    }
    // .human_listFir {
    //   // flex:;
    // }
    // .human_listSec {
    // }
    // .human_listThr {
    // }
    // .human_listFor {
    // }
    // .human_listFiv {
    // }
  }

  .human_list_info {
    display: flex;
    border-bottom: 1px solid #ebebeb;

    div {
      // flex: 2;
      width: 20%;
      color: #999;
      text-align: center;
      height: 30px;
      overflow: hidden; /*超出部分隐藏*/
      text-overflow: ellipsis; /* 超出部分显示省略号 */
      white-space: nowrap; /*规定段落中的文本不进行换行 */
      line-height: 30px;
      div {
        vertical-align: center;
        width: 100%;
        overflow: hidden; /*超出部分隐藏*/
        text-overflow: ellipsis; /* 超出部分显示省略号 */
        white-space: nowrap; /*规定段落中的文本不进行换行 */
      }
    }
  }
}

.compony_list {
  width: 100%;
  padding: 10px 15px;
  font-size: 12px;
  border: 1px solid transparent;
  border-bottom: 1px solid #ebebeb;
  position: relative;
  background: #fff;
  box-sizing: border-box;
  min-height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    color: #333;
  }
}
.human_action_child {
  width: 100%;
  padding: 0px 15px;
  box-sizing: border-box;
  min-height: 38px;
  background: #eceff4;
  margin-top: 10px;
  display: flex;
  align-items: center;
  div {
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    padding: 3px 15px;
    font-size: 12px;
    // line-height: 28px;
    justify-content: center;
    display: flex;
    align-items: center;
    // line-height: 1.5;
    height: 28px;
    border-radius: 15px;
    box-sizing: border-box;
    width: 97px;
    border: 1px solid #c3c6ce;
    color: #fff;
    background-color: #38adff;
    border-color: rgba(0, 140, 238, 0.72);
  }
}
.human_action {
  width: 100%;
  padding: 0px 15px;
  box-sizing: border-box;
  min-height: 38px;
  background: #eceff4;
  //   line-height: 38px;
  margin-top: 10px;
  //   display: inlin;
  display: flex;
  align-items: center;
  div {
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    padding: 3px 15px;
    font-size: 12px;
    // line-height: 28px;
    justify-content: center;
    display: flex;
    align-items: center;
    // line-height: 1.5;
    height: 28px;
    border-radius: 15px;
    box-sizing: border-box;
    width: 97px;
    border: 1px solid #c3c6ce;
    background: #ffffff;
    color: #555;
  }
}
.human_lower {
  font-size: 20px;
  margin-top: 20px;
}
.human_left {
  width: 19%;
  background: #fff;
  margin-right: 1%;
  float: left;
  border-right: 1px solid #dedede;
  min-height: 800px;
  box-sizing: border-box;
}
.human_right {
  width: 80%;
  background: #fff;
  padding: 26px;
  overflow: auto;
  height: 866px;
  box-sizing: border-box;
  float: left;
  .human_first {
    display: flex;
    align-items: center;
    font-size: 20px;
    div {
      width: 50px;
      font-size: 14px;
      height: 20px;
      margin-left: 5px;
      border-radius: 10px;
      border: 1px solid #999;
      cursor: pointer;
      text-align: center;
      line-height: 20px;
    }
  }
  .human_bread {
    display: flex;
    color: #0089ff;
    margin-top: 10px;
  }
}
.human_serch {
  padding: 22px 10px 10px 10px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  .human_serch_child {
    position: relative;
    img {
      position: absolute;
      top: 8px;
      left: 11px;
      font-size: 20px;
      color: #949494;
      height: 18px;
      width: 17px;
    }
    i {
      position: absolute;
      top: 8px;
      right: 11px;
      font-size: 18px;
      color: #949494;
      height: 18px;
      cursor: pointer;
      width: 17px;
    }
    input {
      width: 100%;
      height: 34px;
      padding: 6px 25px 6px 12px;
      padding-left: 33px;
      font-size: 12px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      overflow: auto;
      line-height: 20px;
      outline: none;
    }
  }
  .human_serch_list {
    position: absolute;
    left: 0;
    // bottom: -200px;
    top: 66px;
    // min-height: 600px;
    height: 800px;
    overflow: auto;
    background: #fff;
    padding: 0 10px;
    width: 100%;
    padding-bottom: 30px;
    .fir_compony_list {
      padding-top: 10px;
      box-sizing: border-box;
      // height: 30px;
      line-height: 30px;
      div {
        white-space: nowrap; /*规定文本不换行**/
        text-overflow: ellipsis; /**显示省略符号来代表被修剪的文本。*/
        overflow: hidden;
        width: 100%;
        color: #0089ff;
      }
    }
    .sec_compony_list {
      padding-top: 10px;
      box-sizing: border-box;
      line-height: 30px;

      div {
        white-space: nowrap; /*规定文本不换行**/
        text-overflow: ellipsis; /**显示省略符号来代表被修剪的文本。*/
        overflow: hidden;
        width: 100%;
        color: #0089ff;
      }
    }
  }
}
.active_title {
  background-color: #0089ff !important;
  color: #fff !important;
}
.human_framework {
  width: 100%;
  line-height: 34px;
  height: 800px;
  overflow: auto;
  font-size: 17px;
  .frameworkinfo {
    color: #555;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    height: 34px;
  }

  span {
    flex: 1;
    width: 130px;
    height: 100%;
    cursor: pointer;
    line-height: 34px;
    font-size: 14px;
    display: inline-block;
    overflow: hidden; /*超出部分隐藏*/
    white-space: nowrap; /*不换行*/
    text-overflow: ellipsis; /*超出部分文字以...显示*/
    // margin-top: 3px;
    // padding-top: 3px;
  }
  .framework_firchild {
    margin-left: 10px;
    .frameworkinfo {
      color: #555;

      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      height: 34px;
    }
    span {
      flex: 1;
      cursor: pointer;

      width: 130px;
      height: 100%;
      line-height: 34px;
      display: inline-block;
      overflow: hidden; /*超出部分隐藏*/
      white-space: nowrap; /*不换行*/
      text-overflow: ellipsis; /*超出部分文字以...显示*/
      // margin-top: 3px;
      // padding-top: 3px;
    }
    .framework_secchild {
      margin-left: 10px;
      .frameworkinfo {
        color: #555;

        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        height: 34px;
      }
      span {
        flex: 1;
        cursor: pointer;

        width: 130px;
        height: 100%;
        line-height: 34px;
        display: inline-block;
        overflow: hidden; /*超出部分隐藏*/
        white-space: nowrap; /*不换行*/
        text-overflow: ellipsis; /*超出部分文字以...显示*/
        // margin-top: 3px;
        // padding-top: 3px;
      }
      .framework_thrchild {
        margin-left: 10px;
        .frameworkinfo {
          color: #555;

          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          height: 34px;
        }
        span {
          flex: 1;
          cursor: pointer;

          width: 130px;
          height: 100%;
          line-height: 34px;
          display: inline-block;
          overflow: hidden; /*超出部分隐藏*/
          white-space: nowrap; /*不换行*/
          text-overflow: ellipsis; /*超出部分文字以...显示*/
          // margin-top: 3px;
          // padding-top: 3px;
        }
        .framework_forchild {
          margin-left: 20px;
          .frameworkinfo {
            color: #555;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            height: 34px;
          }
          span {
            cursor: pointer;
            width: 100px;
            flex: 1;
            height: 100%;
            line-height: 34px;
            display: inline-block;
            overflow: hidden; /*超出部分隐藏*/
            white-space: nowrap; /*不换行*/
            text-overflow: ellipsis; /*超出部分文字以...显示*/
            // margin-top: 3px;
            // padding-top: 3px;
          }
        }
      }
    }
  }
}
  .ee{
    display: flex;
    flex-direction: column;
    min-height: 40px;
    div{
      text-align: left !important;
    }
  }
  .dd{
    min-height: 40px;
  }
</style>

