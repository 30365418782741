<template>
  <!-- 推优播报 -->
  <div class="excellent">
    <div class="excellent_find">
      <div class="excellent_find_one">
        日期：
        <el-date-picker
          v-model="time"
          prefix-icon="sa"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
      <div
        class="excellent_find_box"
        @click="handleFind"
        :style="role_what.is_add ? '' : 'margin-bottom:40px;'"
      >
        查询
      </div>
    </div>
    <div
      class="pointer add_excellent"
      @click="want_add()"
      v-if="role_what.is_add"
    >
      <i class="el-icon-plus" style="margin-right: 3px"></i>新增推优
    </div>
    <div class="excellent_table" style="width: 99%">
      <el-table
        :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
        :data="tableData"
        style="width: 100%"
        border
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column label="时间" width="180">
          <template slot-scope="scope">
            {{ scope.row.created_at }}
          </template>
        </el-table-column>
        <el-table-column label="标题" width="240">
          <template slot-scope="scope">
            <div
              style="
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              "
            >
              <el-popover
                placement="bottom"
                trigger="hover"
                width="400"
                :content="scope.row.title ? scope.row.title : '--'"
                v-if="scope.row.title"
              >
                <div slot="reference">
                  <span
                    class="my_customer_plan_totime"
                    v-if="scope.row.title"
                    >{{ scope.row.title ? scope.row.title : "--" }}</span
                  >
                  {{
                    scope.row.my_customer_plan
                      ? scope.row.my_customer_plan.plan
                      : ""
                  }}
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="播报范围">
          <template slot-scope="scope">
            {{ scope.row.range_company }}
          </template>
        </el-table-column>
        <el-table-column label="操作人">
          <template slot-scope="scope">
            {{ scope.row.user?scope.row.user.name:'--' }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div
              style="color: #3a85fc"
              class="pointer"
              @click="check_detail(scope.row)"
            >
              详情
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="10"
        el-pagination
        layout="total,prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div class="add" v-if="add_excellent">
      <div class="add_box">
        <div class="add_box_title">
          添加海报配置
          <i
            class="el-icon-close pointer"
            style="color: #999999"
            @click="
            closeadd_excellent()
           
            "
          ></i>
        </div>
        <div class="add_box_body">
          <div class="add_box_body_title"><i></i>海报展示信息</div>
          <div class="add_box_body_line" style="margin-top: 18px">
            <div class="add_box_body_line_tit">标题：</div>
            <el-input
              v-model="excellent_name"
              placeholder="请输入标题"
              maxlength="15"
              show-word-limit
            ></el-input>
          </div>
          <div class="add_box_body_line" style="margin-top: 18px">
            <div class="add_box_body_line_tit">播报范围：</div>
            <el-cascader
              placeholder="请选择播报范围"
              :options="depOptions"
              v-model="show_depArr"
              ref="myCascader"
              @change="changeDep"
              :key="isResouceShow"
              clearable
              collapse-tags
              :props="{ multiple: true, checkStrictly: true }"
            ></el-cascader>
          </div>
          <div class="add_box_body_title">
            <i></i>海报弹出前奏配图
            <span style="color: rgba(0, 0, 0, 0.3); font-weight: normal"
              >（建议上传GIF格式，尺寸120*120）</span
            >
          </div>
          <div class="add_box_body_img">
            <div
              class="add_box_body_img_box"
              v-for="(item, index) in public_img_arr"
              :key="index"
              v-if="index < 5"
            >
              <el-image
                style="width: 100%; height: 100%"
                :src="item"
                :preview-src-list="public_img_arr"
              >
              </el-image>
              <img
                class="check_img"
                src="../../assets/img/excellent_img_no.png"
                alt=""
                @click="
                  public_check = index;
                  img_check = -1;
                "
              />
              <img
                class="check_img"
                src="../../assets/img/excellent_img_yes.png"
                alt=""
                @click="public_check = -1"
                v-if="public_check == index"
              />
            </div>
            <div
              class="add_box_body_img_box"
              v-for="(item, index) in prelude_img_arr"
              :key="Math.random()"
              v-if="index < 5"
            >
              <!-- <img
                :src="item.file_url"
                alt=""
                @click="
                  is_show = true;
                  show_img = item.file_url;
                  add_excellent = false;
                  show_what = '1';
                "
              /> -->
              <el-image
                style="width: 100px; height: 100px"
                :src="item"
                :preview-src-list="prelude_img_arr"
              >
              </el-image>
              <img
                class="check_img"
                src="../../assets/img/excellent_img_no.png"
                alt=""
                @click="
                  img_check = index;
                  public_check = -1;
                "
              />
              <img
                class="check_img"
                src="../../assets/img/excellent_img_yes.png"
                alt=""
                @click="img_check = -1"
                v-if="img_check == index"
              />
              <i
                class="el-icon-delete"
                @click="
                  prelude_img = [];
                  prelude_img_arr = [];
                  img_check = -1;
                "
              ></i>
            </div>
            <div
              class="image_info"
              @click="chooseFile(1)"
              v-show="prelude_img.length < 1"
            >
              <img src="@/assets/img/upload_img.png" alt="" />
              <span>上传相关图片</span>
              <input
                type="file"
                style="display:none"
                ref="choosePhoto"
                id="upload_file"
                v-if="upload_img"
              />
            </div>
            <div
              class="add_box_body_img_more"
              v-if="public_img.length > 5"
              @click="
                is_more = true;
                add_excellent = false;
              "
            >
              查看更多
            </div>
          </div>
          <div class="add_box_body_title">
            <i></i>海报图片信息
            <span style="color: rgba(0, 0, 0, 0.3); font-weight: normal"
              >（建议上传JPG、PNG格式， 尺寸640*900）</span
            >
          </div>
          <div class="add_box_body_img">
            <div
              class="add_box_body_img_box"
              v-for="(item, index) in poster_img_arr"
              :key="index"
              style="overflow: visible"
            >
              <el-image
                style="width: 100px; height: 100px; border-radius: 5px"
                :src="item"
                :preview-src-list="poster_img_arr"
              >
              </el-image>
              <i
                class="el-icon-circle-close"
                @click="
                  poster_img = [];
                  poster_img_arr = [];
                "
                style="
                  position: absolute;
                  top: -7px;
                  right: -7px;
                  cursor: pointer;
                  color: #cccccc;
                "
              ></i>
            </div>
            <div
              class="image_info"
              @click="chooseFile(2)"
              v-show="poster_img.length < 1"
            >
              <img src="@/assets/img/upload_img.png" alt="" />
              <span>上传相关图片</span>
              <input
                type="file"
                style="display:none"
                ref="choosePhoto"
                id="upload_file"

                v-if="upload_img"
              />
            </div>
          </div>
        </div>
        <div class="add_box_bottom">
          <div @click="closeadd_excellent()">取消</div>
          <div @click="handle_add">确定</div>
        </div>
      </div>
    </div>
    <div class="excellent_detail" v-if="is_detail">
      <div class="excellent_detail_box">
        <div class="excellent_detail_box_title">
          推优详情
          <i
            class="el-icon-close pointer"
            style="color: #999999"
            @click="close_detail()"
          ></i>
        </div>
        <div class="excellent_detail_box_body">
          <div class="excellent_detail_box_body_title"><i></i>海报展示信息</div>
          <div
            class="excellent_detail_box_line"
            style="margin-top: 25px; margin-bottom: 10px"
          >
            <div class="excellent_detail_box_line_tit">标题：</div>
            <div class="excellent_detail_box_count">
              {{ Excell_detail.title ? Excell_detail.title : "--" }}
            </div>
          </div>
          <div class="excellent_detail_box_line" style="margin-bottom: 30px">
            <div class="excellent_detail_box_line_tit">播报范围：</div>
            <div class="excellent_detail_box_count">
              {{ Excell_detail.range_company }}
            </div>
          </div>
          <div class="excellent_detail_box_body_title">
            <i></i>海报弹出前奏配图
          </div>
          <div class="excellent_detail_box_body_img">
            <div class="img_box">
              <el-image
                style="width: 100px; height: 100px"
                v-for="(item, index) in Excell_detail.prelude_img_arr"
                :src="item"
                :key="index"
                :preview-src-list="Excell_detail.prelude_img_arr"
              >
              </el-image>
            </div>
          </div>
          <div class="excellent_detail_box_body_title"><i></i>海报图片信息</div>
          <div class="excellent_detail_box_body_img">
            <div class="img_box">
              <el-image
                style="width: 100px; height: 100px"
                v-for="(item, index) in Excell_detail.poster_img_arr"
                :src="item"
                :key="index"
                :preview-src-list="Excell_detail.poster_img_arr"
              >
              </el-image>
            </div>
          </div>
        </div>
        <!-- <div class="excellent_detail_box_bottom">
          <div>取消</div>
          <div>确定</div>
        </div> -->
      </div>
    </div>
    <div class="show_img" v-if="is_show" @click="close_show">
      <img :src="show_img" alt="" />
    </div>
    <div class="more_img" v-if="is_more">
      <div class="more_img_box">
        <div class="more_img_box_title">
          查看更多
          <i
            class="el-icon-close pointer"
            style="color: #999999"
            @click="
              is_more = false;
              add_excellent = true;
              public_check = -1;
            "
          ></i>
        </div>
        <div class="more_img_box_body">
          <div
            class="more_img_box_img_box"
            v-for="(item, index) in public_img_arr"
            :key="index"
          >
            <el-image
              style="width: 100%; height: 100%"
              :src="item"
              :preview-src-list="public_img_arr"
            >
            </el-image>
            <img
              class="check_img"
              src="../../assets/img/excellent_img_no.png"
              alt=""
              @click="
                public_check = index;
                img_check = -1;
              "
            />
            <img
              class="check_img"
              src="../../assets/img/excellent_img_yes.png"
              alt=""
              @click="public_check = -1"
              v-if="public_check == index"
            />
          </div>
        </div>
        <div class="more_img_box_bottom">
          <div
            @click="
              is_more = false;
              add_excellent = true;
              public_check > 4
                ? (public_check = -1)
                : (public_check = public_check);
            "
          >
            取消
          </div>
          <div
            @click="
              is_more = false;
              add_excellent = true;
            "
          >
            确定
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uploadFile } from "../../api/User/index";
import {
  promotingExcellencelist,
  detail,
  add,
  preludeImgList,
} from "../../api/User/poster";
import { selectOrderCompanyWhere } from "../../api/User/finance";
export default {
  data() {
    return {
      test: 2,
      time: "", //搜索时间
      //   tableData: [],
      total: 0,
      currentPage: 1,
      tableData: [],
      add_excellent: false,
      excellent_name: "", //标题

      depOptions: [], //筛选部门
      depArr: [], //选中的部门
      show_depArr: [], //选中的部门  回显示
      isResouceShow: 0,
      public_img: [], //公众图片
      public_img_arr: [], //公共图片放大
      public_check: -1, //选中公众图片
      prelude_img: [],
      prelude_img_arr: [], //前奏图片 放大
      img_check: -1, //选中的前奏配图
      chooseFile_what: "1", //1 上传前奏图 2上传海报图
      poster_img: [], //海报图片
      poster_img_arr: [], //海报图片放大
      // poster_check: -1,
      upload_img: true,
      value: "",
      is_detail: false,
      Excell_detail: {}, //详情
      show_img: "", //放大图片路径
      show_what: false, //  1 新增图片  2  查看更多图片放大   3 详情
      is_show: false, //放大图片
      is_more: false, //查看更多
      add_flag: false,
      role_what: {
        is_add: false,
        add_str: "",
      },
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      let roleList = JSON.parse(localStorage.getItem("token")).role;
      roleList.forEach((item) => {
        if (item.permissions == "Poster_excellence_add") {
          this.role_what.is_add = true;
          this.role_what.add_str = item.permissions_id_string;
        }
      });
    }
    this.getList({
      permissions_id_string: this.role_what.add_str,
      page:this.currentPage
    });
    selectOrderCompanyWhere().then((res) => {
      if (res.data.code == 200) {
        function dg(list) {
          list.forEach((item) => {
            item.label = item.name;
            item.value = item.id;
            if (item.data) {
              item.children = item.data;
              dg(item.data);
            }
          });
          return list;
        }
        let list = res.data.data;
        dg(list);
        this.depOptions = list;
      }
    });
  },
  methods: {
    hello(){
      console.log('hello')
    },
    getList(data) {
      promotingExcellencelist(data).then((res) => {
        if (res.data.code == 200) {
          this.total = res.data.data.total;
          this.tableData = res.data.data.data;
        }
      });
    },

    close_show() {
      let { show_what } = this;
      if (show_what == "1") {
        this.add_excellent = true;
      }
      if (show_what == "2") {
        this.is_more = true;
      }
      this.is_show = false;
    },
    changeDep(value) {
      this.show_depArr = value;
      //部门筛选
      let arr = [];
      if (this.$refs["myCascader"].getCheckedNodes().length > 0) {
        let nodearr = this.$refs["myCascader"].getCheckedNodes();
        nodearr.forEach((item) => {
          arr.push(item.data.id);
        });
        this.depArr = arr;
      } else {
        ++this.isResouceShow;
        this.depArr = [];
      }
      // console.log(this.depArr);
      // if (value.length > 0) {
      //   this.search_company_id = value[value.length - 1];
      // } else {
      //   this.search_company_id = "";
      // }
    },
    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let strDate = Y + M + D;
      // let strDate = Y + M + D + h + m + '00';
      return strDate;
    },
    handleCurrentChange(val) {
      let { time } = this;
      let data = {};
      if (time) {
        data = {
          date: this.getwaytime(time),
          page: val,
          permissions_id_string: this.role_what.add_str,
        };
      } else {
        data = {
          date: "",
          page: val,
          permissions_id_string: this.role_what.add_str,
        };
      }
      this.getList(data);
    },
    handleFind() {
      this.currentPage = 1
      let { time } = this;
      let date = {};
      if (time) {
        date = this.getwaytime(time);
      } else {
        date = "";
      }
      this.getList({
        date,
        permissions_id_string: this.role_what.add_str,
        page:this.currentPage
      });
    },
    chooseFile: function (i) {
      this.chooseFile_what = i;
      console.log('choosePhoto',this.$refs.choosePhoto)
      this.$refs.choosePhoto.addEventListener('change',this.photoChange);
this.$refs.choosePhoto.dispatchEvent(new MouseEvent("click"));
      this.upload_img = false;
      setTimeout(() => {
        this.upload_img = true;
      }, 10);
    },
    photoChange(el) {
      console.log('photoChange',el)
      var file = el.target.files[0]; //name: "dangqi1.png" || type: "image/png"
      let { chooseFile_what } = this;
      var type = "";
      if (chooseFile_what == 1) {
        //前奏图片
        type = file.type.split("/")[1];
        if (type === "gif") {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          var that = this;
          reader.onloadend = function () {
            var dataURL = reader.result;
            var blob = that.dataURItoBlob(dataURL);
            that.upload(blob); //执行上传接口
          };
        } else {
          this.$message.error("图片格式错误~~");
        }
      } else {
        type = file.type.split("/")[0];
        if (type === "image") {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          var that = this;
          reader.onloadend = function () {
            var dataURL = reader.result;
            var blob = that.dataURItoBlob(dataURL);
            that.upload(blob); //执行上传接口
          };
        } else {
          this.$message.error("请上传图片~~");
        }
      }
    },
    dataURItoBlob(dataURI) {
      // base64 解码
      let byteString = window.atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let T = mimeString.split("/")[1];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    upload(imgUrl) {
      let { chooseFile_what } = this;
      let formData = new FormData();
      this.$message({
        message: "上传中，请稍后",
      });
      formData.append("file", imgUrl);
      uploadFile(formData).then((res) => {
        if (chooseFile_what == "1") {
          this.prelude_img.push(res.data.data);
          this.prelude_img_arr.push(res.data.data.file_url);
        }
        if (chooseFile_what == "2") {
          this.poster_img.push(res.data.data);
          this.poster_img_arr.push(res.data.data.file_url);
        }
        if (res.data.code == 200) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
        }
      });
    },
    check_detail(row) {
      //详情
      detail({
        promoting_excellence_id: row.id,
      }).then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          this.is_detail = true;
          let detail = res.data.data;
          detail.poster_img_arr = [];
          detail.prelude_img_arr = [];
          detail.poster_img_arr.push(detail.poster_img);
          detail.prelude_img_arr.push(detail.prelude_img);
          this.Excell_detail = res.data.data;
        }
      });
      localStorage.setItem("add_excellent", 1);

    },
    close_detail(){
      this.is_detail = false;
      localStorage.removeItem("add_excellent");
    },
    want_add() {
      this.add_excellent = true;
      localStorage.setItem("add_excellent", 1);
      ++this.isResouceShow;
      this.excellent_name = "";
      this.img_check = -1;
      this.prelude_img = [];
      this.poster_img = [];
      preludeImgList().then((res) => {
        if (res.data.code == 200) {
          if (res.data.data.data.length > 0) {
            res.data.data.data.forEach((item) => {
              item.file_url = item.complete_img_url;
              item.url = item.img_url;
              this.public_img_arr.push(item.file_url);
            });
          }
          this.public_img = res.data.data.data;
        }
      });
    },
    handle_add() {
      //新增
      let {
        excellent_name,
        poster_img,
        prelude_img,
        public_img,
        public_check,
        img_check,
        add_flag,
        depArr,
      } = this;
      if (add_flag) {
        return;
      }
      let poster_str = "";
      if (poster_img.length == 0) {
        this.$message({
          message: "海报图片不能为空",
          type: "error",
        });
        return;
      } else {
        poster_str = poster_img[0].url;
      }
      if (img_check == -1 && public_check == -1) {
        this.$message({
          message: "请选择前奏配图",
          type: "error",
        });
        return;
      }
      let prelude_img_str = ""; //前奏图片

      if (img_check !== -1 && public_check == -1) {
        //选中自己上传的
        prelude_img_str = prelude_img[img_check].url;
      }
      if (public_check !== -1 && img_check == -1) {
        //选中公众图片库
        prelude_img_str = public_img[public_check].url;
      }
      if (depArr.length == 0) {
        this.$message({
          message: "请选择播报范围",
          type: "error",
        });
        return;
      }
      this.add_flag = true;
      let data = {
        title: excellent_name,
        range: depArr.join(","),
        prelude_img: prelude_img_str,
        poster_img: poster_str,
        permissions_id_string: this.role_what.add_str,
      };
      add(data).then((res) => {
        this.add_flag = false;
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.getList({
            permissions_id_string: this.role_what.add_str,
          });
          this.add_excellent = false;
          this.img_check = -1;
          this.public_check = -1;
          this.depArr = [];
          this.show_depArr = [];
          localStorage.removeItem("add_excellent");
        }
      });
    },
    closeadd_excellent() {
      this.add_excellent = false;
      this.img_check = -1;
      this.add_flag = false;
      this.depArr = [];
      this.show_depArr = [];
      localStorage.removeItem("add_excellent");
    },
  },
};
</script>

<style lang="scss">
.excellent {
  margin: 40px 16px;
  .excellent_find {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .excellent_find_one {
      width: 23%;
      white-space: nowrap;
      .el-date-editor {
        height: 32px;
        width: 180px;
        input {
          height: 32px;
          width: 180px;
        }
        .el-input__suffix {
          display: flex;
          align-items: center;
          height: 100%;
        }
      }
    }
    .excellent_find_box {
      width: 70px;
      height: 32px;
      background: #3a85fc;
      opacity: 1;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
      margin-left: 70px;
    }
  }
  .add_excellent {
    width: 104px;
    height: 35px;
    background: #3a85fc;
    opacity: 1;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  .block {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .add {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    .add_box {
      z-index: 99;
      width: 40%;
      height: 75%;
      background: #ffff;
      margin: auto;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
      overflow: hidden;
      min-width: 672px;
      min-height: 650px;
      .add_box_title {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 60px;
        font-size: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        i {
          position: absolute;
          right: 50px;
          padding: 10px;
        }
      }
      .add_box_body {
        height: 75%;
        overflow: auto;
        margin: 16px 20px;
        .add_box_body_title {
          height: 40px;
          background: #fafafa;
          display: flex;
          align-items: center;
          padding: 0 32px;
          font-size: 14px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.8);
          i {
            width: 2px;
            height: 12px;
            background: #3a85fc;
            opacity: 1;
            display: block;
            margin-right: 5px;
          }
        }
        .add_box_body_line {
          height: 32px;
          display: flex;
          align-items: center;
          margin: 0 46px;
          margin-bottom: 28px;
          .add_box_body_line_tit {
            white-space: nowrap;
            width: 70px;
          }
          .el-input {
            height: 32px;
            width: 300px;
            input {
              width: 300px;
              height: 32px;
            }
          }
          .el-select {
            .el-input__suffix {
              .el-input__suffix-inner {
                i {
                  line-height: 32px;
                }
              }
            }
          }
          .el-cascader {
            .el-input__icon {
              line-height: 32px;
            }
          }
        }
        .add_box_body_img {
          margin: 0 46px;
          display: flex;
          flex-wrap: wrap;
          position: relative;
          .add_box_body_img_box:hover {
            i {
              display: block;
            }
          }
          .add_box_body_img_box {
            width: 100px;
            height: 100px;
            border: 1px solid #ececec;
            opacity: 1;
            overflow: hidden;
            border-radius: 8px;
            margin: 10px 0;
            margin-right: 5px;
            position: relative;
            .el-icon-delete {
              display: none;
              position: absolute;
              top: 0;
              right: 0;
              left: 5px;
              bottom: 0;
              width: 20px;
              margin: auto;
              height: 20px;
              background: #ccc;
              line-height: 20px;
              text-align: center;
              opacity: 0.5;
              border-radius: 5px;
              cursor: pointer;
            }

            img {
              width: 100%;
              height: 100%;
            }
            .check_img {
              position: absolute;
              top: 5px;
              right: 5px;
              height: 10px;
              width: 10px;
            }
          }
          // .add_box_body_img_box:nth-child(5) {
          //   margin-right: 0;
          // }
          .add_box_body_img_more {
            color: #3a85fc;
            width: 14px;
            font-size: 14px;
            cursor: pointer;
            height: 100px;
            display: flex;
            align-items: center;
            position: absolute;
            top: 10px;
            right: -36px;
          }
          .image_info {
            position: relative;
            width: 100px;
            height: 100px;
            margin: 10px 0;
            // position: relative;
            display: flex;
            background: rgba(0, 0, 0, 0.1);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            opacity: 0.6;
            overflow: hidden;
            cursor: pointer;
            img {
              height: 30px;
              width: 30px;
            }
            span {
              margin-top: 10px;
              color: rgba(0, 0, 0, 0.3);
            }
          }
        }
      }
      .add_box_bottom {
        display: flex;
        justify-content: flex-end;
        margin: 0 20px;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 40px;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
        }
        div:first-child {
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
          color: rgba(0, 0, 0, 0.5);
        }
        div:last-child {
          margin-left: 20px;
          background: #3a85fc;
          color: #fff;
        }
      }
    }
  }
  .excellent_detail {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    .excellent_detail_box {
      z-index: 99;
      width: 40%;
      height: 70%;
      background: #ffff;
      margin: auto;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
      overflow: hidden;
      min-width: 672px;
      min-height: 600px;
      .excellent_detail_box_title {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 60px;
        font-size: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        i {
          position: absolute;
          right: 50px;
          padding: 10px;
          cursor: pointer;
        }
      }
      .excellent_detail_box_body {
        // height: 70%;
        overflow: auto;
        margin: 16px 20px;
        .excellent_detail_box_body_title {
          height: 40px;
          background: #fafafa;
          display: flex;
          align-items: center;
          padding: 0 32px;
          font-size: 14px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.8);
          i {
            width: 2px;
            height: 12px;
            background: #3a85fc;
            opacity: 1;
            display: block;
            margin-right: 5px;
          }
        }
        .excellent_detail_box_line {
          display: flex;
          margin: 0 46px;
          .excellent_detail_box_line_tit {
            white-space: nowrap;
            width: 70px;
            display: flex;
            height: auto;
            align-items: flex-start;
            color: rgba(0, 0, 0, 0.4);
          }
          .excellent_detail_box_line_count {
            color: rgba(0, 0, 0, 0.8);
            margin-left: 10px;
          }
        }
        .excellent_detail_box_body_img {
          margin: 0 46px;
          .img_box {
            width: 85px;
            height: 85px;
            background: rgba(0, 0, 0, 0.5);
            opacity: 1;
            border-radius: 2px;
            overflow: hidden;
            cursor: pointer;
            margin: 20px 0;
            img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .more_img {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    .more_img_box {
      z-index: 99;
      width: 40%;
      height: 70%;
      background: #ffff;
      margin: auto;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
      overflow: hidden;
      min-width: 672px;
      min-height: 650px;
      .more_img_box_title {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 60px;
        font-size: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        i {
          position: absolute;
          right: 50px;
          padding: 10px;
          cursor: pointer;
        }
      }
      .more_img_box_body {
        height: 75%;
        overflow: auto;
        margin: 16px 20px;
        .more_img_box_img_box {
          width: 19%;
          height: 100px;
          border: 1px solid #ececec;
          opacity: 1;
          overflow: hidden;
          border-radius: 8px;
          margin: 10px 0;
          margin-right: 5px;
          position: relative;
          float: left;
          img {
            width: 100%;
            height: 100%;
          }
          .check_img {
            position: absolute;
            top: 5px;
            right: 5px;
            height: 10px;
            width: 10px;
          }
        }
      }
      .more_img_box_bottom {
        display: flex;
        justify-content: flex-end;
        margin: 0 20px;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 40px;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
        }
        div:first-child {
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
          color: rgba(0, 0, 0, 0.5);
        }
        div:last-child {
          margin-left: 20px;
          background: #3a85fc;
          color: #fff;
        }
      }
    }
  }
  .show_img {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    img {
      z-index: 99;
      width: 350px;
      background: #ffff;
      margin: auto;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
      overflow: hidden;
    }
  }
}
</style>