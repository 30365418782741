<template>
  <div class="role">
    <div class="table" v-if="!isAdd">
      <div class="role_add">
        <div @click="handleAdd"><i class="el-icon-plus" style="margin-right: 5px"></i>新增标签</div>
      </div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        default-expand-all
        row-key="id"
        v-loading="isLoading"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="title" label="标签名"> </el-table-column>
        <el-table-column prop="created_at" label="添加时间"> </el-table-column>
        <el-table-column width="190" label="操作">
          <template slot-scope="scope">
            <div class="cz">
              <div @click="handleAdd(scope.row)" v-if="scope.row.children" class="cz_detail">添加子标签</div>
              <div @click="handleDetail(scope.row)" class="cz_detail">编辑</div>
              <div @click="handleDel(scope.row)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination layout="total,prev, pager, next, jumper" @current-change="handleCurrentChange" :total="total"> </el-pagination>
      </div>
    </div>
    <el-dialog title="标签设置" :visible.sync="dialogVisible" width="30%">
      <el-form :model="form" class="demo-form-inline" label-width="100px" >
        <el-form-item label="父级" v-if="form.pid">
          <el-select v-model="form.pid" placeholder="请选择" disabled>
            <el-option v-for="item in tableData" :key="item.value" :label="item.title" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标签名称">
          <el-input v-model="form.title" placeholder="标签名称"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { customerListTags, delCustomerListTags, addCustomerListTags, editCustomerListTags } from "@/api/User/customer";
export default {
  data() {
    return {
      isAdd: false,
      page: 1,
      tableData: [],
      total: 0,
      form: {},
      dialogVisible: false,
      isLoading: false,
    };
  },
  created() {
    this.handleCurrentChange(1);
  },
  methods: {
    submit() {
      this.isLoading = true;
      this.dialogVisible = false;
      console.log(this.form)
      if (this.form.id) {
        editCustomerListTags({
          ...this.form,
        })
          .then((res) => {
            this.$message({
              type: "success",
              message: res.data.message,
            });
            this.isLoading = false;
            this.handleCurrentChange();
          })
          .catch((e) => {
            this.isLoading = false;
          });
      } else {
        addCustomerListTags({
          ...this.form,
        })
          .then((res) => {
            this.$message({
              type: "success",
              message: res.data.message,
            });
            this.isLoading = false;
            this.handleCurrentChange();
          })
          .catch((e) => {
            this.isLoading = false;
          });
      }
    },
    handleAdd(form) {
      console.log(form);
      this.form = {};
      if (form) {
        this.form = { pid: form.id };
      }
      this.dialogVisible = true;
    },
    handleDetail(row) {
      this.form = { ...row };
      this.dialogVisible = true;
    },
    handleCurrentChange(val) {
      this.page = val || this.page;
      this.isLoading = true;
      customerListTags({
        page: this.page,
      }).then((res) => {
        this.tableData = res.data.data.list;
        this.total = res.data.data.total;
        this.isLoading = false;
      });
    },
    handleDel(row) {
      //删除
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delCustomerListTags({ id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: res.data.message,
            });
            this.handleCurrentChange();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style lang="scss">
.role {
  width: 100%;
  height: 100%;
  .role_add {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #fff;
    div {
      cursor: pointer;
      width: 100px;
      height: 70%;
      display: flex;
      align-items: center;
      background: #3a85fc;
      border-radius: 4px;
      color: #fff;
      justify-content: center;
      margin-right: 20px;
    }
  }
  .table {
    margin: 20px;
    background: #fff;
    padding-bottom: 20px;
    padding: 20px;
    .block {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      // justify-content: center;
    }
    .cz {
      cursor: default;
      display: flex;
      align-items: center;
      justify-content: space-around;
      div {
        line-height: 14px;
        cursor: pointer;
      }
      div:hover {
        color: #3a85fc;
      }
      .cz_detail {
        border-right: 1px solid #ccc;
        padding-right: 10px;
      }
    }
  }
  .addRole {
    width: 60%;
    height: 80%;
    background: #fff;
    border-radius: 10px;
    z-index: 99;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-width: 834px;
    .addrole_header {
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-bottom: 1px dotted #f5f5f5;
      div {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
        i {
          margin-right: 40px;
          padding: 10px;
          cursor: pointer;
        }
      }
    }
    .addRole_input {
      height: 32px;
      border-radius: 5px;
      margin-top: 20px;
      margin-left: 50px;
      display: flex;
      align-items: center;
      .addRole_input_input {
        border: 1px solid #cccccc;
        border-radius: 5px;
        height: 100%;
        margin-left: 10px;
        input {
          width: 100%;
          height: 100%;
          padding-left: 10px;
        }
      }
    }
    .addRole_box {
      flex: 1;
      margin-left: 50px;
      height: 90%;
      overflow: auto;
      .addRole_box_line {
        display: flex;
        // align-items: center;
        margin-top: 10px;
        min-height: 50px;
        // border-bottom: 1px solid #ccc;
        .checkbox_check {
          border: 1px solid #3a85fc;
        }
        .addRole_box_line_left {
          height: 100%;
          width: 15%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 20px;
          // border-right: 1px solid #ccc;
          .left_box {
            padding: 10px 0;
            display: flex;
            align-items: center;
            margin-top: 10px;
            cursor: pointer;
            .checkbox {
              width: 15px;
              height: 15px;
              border-radius: 50%;
              border: 1px solid #ccc;
              margin-right: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              div {
                width: 50%;
                height: 50%;
                border-radius: 50%;
                background: #3a85fc;
              }
            }
          }
        }
        .addRole_box_line_right {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: 20px;
          flex-wrap: wrap;
          .right_box {
            height: 40%;
            min-width: 15%;
            display: flex;
            align-items: center;
            cursor: pointer;
            // border: 1px solid #f5f5f5;
            margin-right: 20px;
            padding: 10px 0;
            border-radius: 5px;
            margin-bottom: 10px;
            margin-top: 10px;
            .checkbox {
              width: 15px;
              height: 15px;
              border-radius: 50%;
              border: 1px solid #ccc;
              margin-right: 10px;
              margin-left: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              div {
                width: 50%;
                height: 50%;
                border-radius: 50%;
                background: #3a85fc;
              }
            }
          }
          // .el-checkbox-group{
          //     width: 100%;
          //     display: flex;
          //     align-items: center;
          //     flex-wrap: wrap;
          //     label{
          //         width: 13%;
          //         display: inline-block;
          //         // margin: 10px auto;
          //         margin-right: 0;
          //         margin-left: 10px;
          //         margin-top: 10px;
          //         margin-bottom: 10px;
          //     }

          // }
        }
      }
    }
    .addrole_botton {
      height: 40px;
      display: flex;
      justify-content: flex-end;
      margin-right: 100px;
      margin-top: 22px;
      margin-bottom: 26px;
      // position: absolute;
      // right: 20px;
      // bottom: 20px;
      div {
        cursor: pointer;
        width: 80px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        font-size: 14px;
        margin-left: 20px;
      }
      .add_no {
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
        opacity: 1;
        color: rgba(0, 0, 0, 0.5);
      }
      .add_ok {
        background: #3a85fc;
        color: #ffffff;
      }
    }
  }
  .login_mc {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 98;
  }
  .el-table__header-wrapper {
    table {
      width: 100% !important;
      table-layout: auto;
    }
  }
  .el-table__body-wrapper {
    table {
      width: 100% !important;
      table-layout: auto;
    }
  }
}
</style>
