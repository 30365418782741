<template>
  <div class="integral" style="flex: 1; width: 100%">
    <el-main>
      <div class="serch_big_box" style="position: relative">
        <div class="info">
          <span class="demonstration">时间：</span>
          <el-date-picker
            v-model="time"
            type="month"
            :picker-options="pickerOptions"
            placeholder="请选择时间"
          >
          </el-date-picker>
        </div>
        <div class="info" style="width: 20%">
          <div class="serch pointer" @click="serch_integral">查询</div>
          <div class="reset pointer" @click="reset_integral">重置</div>
        </div>
        <div
          style="
            width: 55px;
            height: 20px;
            border: 1px solid;
            color: #666;
            text-align: center;
            cursor: pointer;
            position: absolute;
            right: 20px;
            border-radius: 2px;
          "
          @click="back_history"
        >
          关闭
          <i class="el-icon-close"></i>
        </div>
      </div>
      <div class="integral_table">
        <el-table
          :data="integralData"
          border
          :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
          style="width: 100%"
          ref="table"
        >
          <el-table-column prop="date" label="节点时间" width="200" key="30">
            <template slot-scope="scope">
              <div>{{ scope.row.start_time }} ~ {{ scope.row.end_time }}</div>
              <div>【{{ scope.row.name }}】</div>
            </template>
          </el-table-column>
          <el-table-column label="赛区" width="180" key="31">
            <template slot-scope="scope">
              <div>{{ scope.row.zone_name ? scope.row.zone_name : "--" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="业绩积分"
            sortable
            :sort-method="(a, b) => sortByDate(a, b, 1)"
            key="32"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.performance_integral }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="完成率积分"
            sortable
            :sort-method="(a, b) => sortByDate(a, b, 2)"
            key="33"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.completion_rate_integral }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="当月任务额"
            sortable
            :sort-method="(a, b) => sortByDate(a, b, 3)"
            key="34"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.month_mission }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="节点业绩"
            sortable
            :sort-method="(a, b) => sortByDate(a, b, 4)"
            key="35"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.node_achievement }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="节点业绩占比"
            sortable
            :sort-method="(a, b) => sortByDate(a, b, 5)"
            key="36"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.node_achievement_rate + "%" }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>
  </div>
</template>

<script>
import { historyIntegral } from "../../api/User/PK";
export default {
  data() {
    return {
      time: "",
      year: "",
      month: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      integralData: [],
      row: {},
    };
  },
  created() {
    // console.log(this.$route.params);
    this.row = this.$route.params.row;

    // if (this.$route.params.row) {
    //   localStorage.setItem('integralRow',JSON.stringify(this.$route.params.row))
    //   this.row = this.$route.params.row;
    // }else{
    //   this.row = JSON.parse(localStorage.getItem('integralRow'))
    // }
    this.doHandleDate();
    this.gethistoryIntegral({
      company_id: this.row.id,
      year: this.year,
      month: this.month,
    });
  },

  methods: {
    sortByDate(obj1, obj2, i) {
      //排序
      var val1 = "";
      var val2 = "";
      if (i == 1) {
        //业绩积分

        val1 = obj1.performance_integral * 1;
        val2 = obj2.performance_integral * 1;
      }
      if (i == 2) {
        //完成率积分

        val1 = obj1.completion_rate_integral * 1;
        val2 = obj2.completion_rate_integral * 1;
      }
      if (i == 3) {
        //当月任务额
        val1 = obj1.month_mission * 1;
        val2 = obj2.month_mission * 1;
      }
      if (i == 4) {
        //节点业绩
        val1 = obj1.node_achievement * 1;
        val2 = obj2.node_achievement * 1;
      }
      if (i == 5) {
        //节点业绩占比
        val1 = obj1.node_achievement_rate * 1;
        val2 = obj2.node_achievement_rate * 1;
      }
      return val1 - val2;
    },
    back_history() {
      history.go(-1);
    },
    gethistoryIntegral(data) {
      historyIntegral(data).then((res) => {
        if (res.data.code == 200) {
          this.integralData = res.data.data;
          this.$nextTick(() => {
            this.$refs.table.doLayout();
          });
        }
      });
    },
    serch_integral() {
      var time_arr = this.getwaytime(this.time).split("-");
      this.year = time_arr[0];
      this.month = time_arr[1];
      this.gethistoryIntegral({
        company_id: this.row.id,
        year: this.year,
        month: this.month,
      });
    },
    reset_integral() {
      this.doHandleDate();
      this.gethistoryIntegral({
        company_id: this.row.id,
        year: this.year,
        month: this.month,
      });
    },
    doHandleDate() {
      var myDate = new Date();
      var tYear = myDate.getFullYear();
      var tMonth = myDate.getMonth();
      var m = tMonth + 1;
      if (m.toString().length == 1) {
        m = "0" + m;
      }
      this.year = tYear;
      this.month = m;
      this.time = tYear + "-" + m;
    },
    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let strDate = Y + M;
      return strDate;
    },
  },
};
</script>

<style lang="scss">
.integral {
  flex: 1 1 0%;
  padding: 20px;
  background: #eef5f9;
  .el-main {
    background: #fff;
    width: 100%;
    height: 100%;
    .serch_big_box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-left: 20px;
      .info {
        width: 23%;
        margin-top: 20px;
        margin-left: 2%;
        display: flex;
        align-items: center;
        .demonstration {
          margin-right: 10px;
          min-width: 42px;
          font-size: 14px;
          white-space: nowrap;

          color: #595959;
        }
        .el-cascader {
          height: 32px;
          line-height: 32px;
          flex: 1;
          .el-input {
            height: 32px;
            line-height: 32px;
            input {
              height: 32px !important;
            }
          }
          .el-cascader__tags {
            flex-wrap: nowrap;
          }
        }
        // .el-input {
        //   background: url("../../assets/img/fight_9.png") no-repeat;
        //   background-size: 100% 100%;
        // }
        .el-input__inner {
          height: 36px;
          line-height: 36px;
          border-radius: inherit;
          border-radius: 2px;
        }

        .el-input__icon {
          line-height: 36px;
        }
        .el-tag {
          background: transparent !important;
        }
        .serch {
          width: 70px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
          color: #ffffff;
          background: #3a85fc;
        }
        .reset {
          width: 70px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
          margin-left: 20px;
          border: 1px solid #efefef;
          color: #595959;
        }
      }
      .info:nth-of-type(4n + 1) {
        margin-left: 0;
      }
    }
    .integral_table {
      margin-top: 20px;
    }
  }
}
</style>