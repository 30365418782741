<template>
  <div class="all_Record">
    <div class="all_Record_tit">本月战绩报告</div>
    <div class="all_Record_box">
      <div class="all_Record_box_left">
        <div class="all_Record_box_tit">{{ position_type == 1 ? "我的" : "团队" }}战绩报告</div>
        <div class="all_Record_box_left_chart">
          <div class="left_left">
            <div class="left_left_box">
              <div>任务额</div>
              <div style="color: #46c990">{{ task_amount }}</div>
            </div>
            <div class="left_left_box">
              <div>完成额</div>
              <div style="color: #fc4f4c">{{ completed_amount }}</div>
            </div>
            <div class="left_left_box" style="cursor: pointer" @click="openAnticipateAmount">
              <div>预计到账</div>
              <div style="color: #fc4f4c">{{ anticipate_amount }}</div>
            </div>
          </div>
          <div class="left_right">
            <div id="mygauge"></div>
          </div>
        </div>
      </div>
      <div class="all_Record_box_right">
        <div class="all_Record_box_tit">{{ position_type == 1 ? "我的" : "团队" }}战绩</div>
        <div class="all_Record_box_right_chart">
          <div id="mycategory" class="mycategory"></div>
        </div>
      </div>
    </div>
    <el-dialog title="到账详情" append-to-body :visible.sync="isShowAnticipateAmount" width="40%">
      <el-table :data="anticipateAmountList" style="width: 100%" v-loading="isLoading">
        <el-table-column prop="customer" label="公司名称" > </el-table-column>
        <el-table-column prop="user" label="跟进人" > </el-table-column>
        <el-table-column prop="expected_arrival" label="预计到账金额"> </el-table-column>
        <el-table-column prop="to_time" label="预计到账时间"> </el-table-column>
      </el-table>
      <el-pagination :current-page.sync="antiPage" @current-change="antiPageChange" layout="prev, pager, next" :total="antiTotal"> </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import { achievementReport, expectedReceiptList } from "../../api/User/home";
export default {
  data() {
    return {
      list: [],
      task_amount: "", //任务额
      completed_amount: "", //完成额度
      rate_num: 0, //完成率
      xAxisData: [],
      task_amount_arr: [],
      completed_amount_arr: [],
      position_type: 1,
      anticipate_amount: 0,
      isShowAnticipateAmount: false,
      anticipateAmountList: [],
      isLoading: false,
      antiTotal: 0,
      antiPage: 1,
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      let token = JSON.parse(localStorage.getItem("token"));
      this.position_type = token.position_type;
    }
    achievementReport().then((res) => {
      if (res.data.code == 200) {
        this.task_amount = res.data.data.task_amount;
        this.completed_amount = res.data.data.completed_amount;
        this.rate_num = res.data.data.rate * 1;
        this.anticipate_amount = res.data.data.anticipate_amount;
        let list = res.data.data.list;
        let xAxisData = [];
        let task_amount_arr = [];
        let completed_amount_arr = [];
        for (let i = 0; i < list.length; i++) {
          xAxisData.push(list[i].name);
          task_amount_arr.push(list[i].task_amount * 1);
          completed_amount_arr.push(list[i].completed_amount * 1);
        }
        this.xAxisData = xAxisData;
        this.task_amount_arr = task_amount_arr;
        this.completed_amount_arr = completed_amount_arr;
        this.getmygauge();
        this.getmycategory();
      }
    });
  },
  mounted() {
    this.getmygauge();
    this.getmycategory();
    window.onresize = () => {
      this.getmygauge();
      this.getmycategory();
    };
  },
  methods: {
    openAnticipateAmount() {
      this.isShowAnticipateAmount = true;
      this.isLoading = true;
      this.getExpectedReceiptList();
    },
    getExpectedReceiptList() {
      expectedReceiptList().then((res) => {
        console.log(res.data.data);
        this.anticipateAmountList=res.data.data.data
        this.isLoading = false;
        this.antiTotal = res.data.data.total;
      });
    },
    antiPageChange() {
      this.getExpectedReceiptList();
    },
    getmygauge() {
      let mygauge = this.$echarts.init(document.getElementById("mygauge"));
      mygauge.resize();
      mygauge.setOption({
        series: [
          {
            type: "gauge",
            radius: "100%",
            axisLine: {
              lineStyle: {
                width: 10,
                color: [
                  [0.3, "rgb(252, 79, 76)"],
                  [0.7, "#FAC141"],
                  [1, "rgb(70, 201, 144)"],
                ],
              },
            },
            pointer: {
              itemStyle: {
                color: "auto",
              },
              length: "100",
            },
            axisTick: {
              distance: -30,
              length: 8,
              lineStyle: {
                color: "#fff",
                width: 2,
              },
            },
            splitLine: {
              distance: -30,
              length: 10,
              lineStyle: {
                color: "#fff",
                width: 4,
              },
            },
            axisLabel: {
              color: "auto",
              distance: 5,
              fontSize: 12,
            },
            detail: {
              valueAnimation: true,
              formatter: "{value}%",
              color: "auto",
              fontSize: 20,
            },
            title: {
              offsetCenter: [0, "20%"],
              fontSize: 13,
            },
            data: [
              {
                value: this.rate_num,
                name: "完成率",
              },
            ],
          },
        ],
      });
    },
    getmycategory() {
      let mycategory = this.$echarts.init(document.getElementById("mycategory"));
      mycategory.resize();
      var emphasisStyle = {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: "rgba(0,0,0,0.2)",
        },
      };
      mycategory.setOption({
        color: ["#c8e1ff", "#3C8FF4"],
        tooltip: {
          trigger: "axis",
          backgroundColor: "#3C8FF4",
        },
        xAxis: {
          data: this.xAxisData,
          axisLine: {
            onZero: true,
            lineStyle: {
              color: "#ccc",
            },
          },
          splitArea: { show: false },
        },
        yAxis: [
          {
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "#ccc",
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#ccc",
              },
            },
            axisLabel: {
              color: "#262626 ",
            },
          },
        ],
        grid: {
          top: 10,
          bottom: 50,
        },
        series: [
          {
            name: "任务额",
            type: "bar",
            stack: "one",
            barWidth: 30,
            data: this.task_amount_arr,
          },
          {
            name: "完成额",
            type: "bar",
            barWidth: 30,
            barGap: "-100%" /*这里设置包含关系，只需要这一句话*/,
            data: this.completed_amount_arr,
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss">
.all_Record {
  width: 67%;
  min-width: 800px;
  height: 430px;
  background: #ffffff;
  opacity: 1;
  border-radius: 6px;
  padding: 24px 0;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  .all_Record_tit {
    font-size: 18px;
    font-weight: bold;
    line-height: 31px;
    color: rgba(0, 0, 0, 0.8);
    opacity: 1;
    margin-bottom: 10px;
  }
  .all_Record_box {
    width: 100%;
    margin-top: 0px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    .all_Record_box_tit {
      margin-bottom: 14px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.6);
      opacity: 1;
    }
    .all_Record_box_left {
      width: 50%;
      .all_Record_box_left_chart {
        width: 100%;
        display: flex;
        height: 100%;
        .left_left {
          display: flex;
          flex-direction: column;
          margin-left: 9px;
          width: 120px;
          .left_left_box {
            width: 120px;
            height: 74px;
            background: #ffffff;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            border-radius: 2px;
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: space-around;
            margin-top: 24px;
            div:first-child {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 19px;
              color: rgba(0, 0, 0, 0.8);
              opacity: 1;
              margin: 18px 0 10px 0;
            }
            div:last-child {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              line-height: 21px;
              opacity: 1;
            }
          }
        }
        .left_right {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 250px;
          width: 100%;
          height: 90%;
          div {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
          }
        }
      }
    }
    .all_Record_box_right {
      width: 50%;
      height: 100%;
      .all_Record_box_right_chart {
        width: 100%;
        height: 90%;
        .mycategory {
          width: 100%;
          height: 100%;
          div:first-child {
            width: 100% !important;
          }
          div:last-child {
            background: #fff !important;
            color: rgba(0, 0, 0, 0.6) !important;
          }
        }
      }
    }
  }
}
</style>
