<template>
  <div class="arrived_box">
    <div class="title">
      <div class="left">
        <div
          :class="[check == 1 ? 'active' : '']"
          class="pointer"
          @click=" click_check(1)"

        >
          今日到账
        </div>
        <div
          :class="[check == 2 ? 'active' : '']"
          class="pointer"
          @click=" click_check(2)"
        >
          PK榜
        </div>
      </div>
      <div class="right pointer" v-if="check==2" @click="lookmore">
        查看更多<i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="bot_box" v-if="check == 1">
      <div class="item" v-for="(item, index) in achi_list" :key="index">
        <div class="img_info">
          <img
            :src="item.head_portrait"
            alt=""
            v-if="item.head_portrait"
          />
          <img src="../../assets/img/more_head.png" alt="" v-else>
        </div>
        <div class="center">
          <div style="color:#262626;font-size:15px;">{{item.company_name}} - {{item.name}}</div>
          <div style="color:#8c8c8c;font-size:13px;">产品名：{{item.product_name}}</div>
          <div style="color:#8c8c8c;font-size:13px;">到账金额：{{item.money}}元</div>
        </div>
        <div class="last">{{item.time}}</div>
      </div>
      <div style="    text-align: center;
    color: #ccc;" v-if="achi_list.length==0">暂无数据</div>
    </div>
    <div class="fight_box" v-if="check == 2">
      <div class="title_check">
        <div class="fight_1" >
          <div :class="fight == 1 ? 'fight' : ''" @click="click_fight(1)" class="pointer">
            个人PK
          </div>
          <div @click="click_fight(2)" :class="fight == 2 ? 'fight' : ''" class="pointer">
            部门PK
          </div>
        </div>
      </div>
      <div class="hahahaha" >
        <p v-if="list.length==0" style="margin-top:10px;text-align:center;color:#ccc;">
          目前暂无PK噢~
        </p>
        <div v-for="(item, index) in list" :key="index" class="PK_big">
            <div class="PK_date" v-if="item.distance_start_time">
               <div>距开始</div>
               <div>{{item.distance_start_time}}</div>
            </div>
          <div class="PK_list">
            <div class="PK_info">
              <div class="title">
                <div class="title_p">
                  <span>{{ item.pk_type == 1 ? "业绩PK" : "完成率PK" }}</span>
                  |{{ item.start_time }}~{{ item.end_time }}
                </div>
              </div>
              <div class="PK_yj_money">
                <div>
                  <span>{{
                    item.pk_type == 2
                      ? item.my_rate + "%"
                      : "￥" + item.my_achievement
                  }}</span>
                </div>
                <div>
                  <span>{{
                    item.pk_type == 2
                      ? item.opponent_rate + "%"
                      : "￥" + item.opponent_achievement
                  }}</span>
                </div>
              </div>
              <div class="PK_jd">
                <div class="left_head">
                  <img src="@/assets/img/fight_type.png" class="quan" alt="" />
                  <img
                    :src="item.head_portrait"
                    alt=""
                    class="moren"
                    v-if="item.head_portrait"
                  />
                  <img alt="" v-else src="@/assets/img/morenhead.png" class="moren" />
                  <div class="fight_name">
                    {{ item.operate ? "应战" : "挑战" }}方
                  </div>
                  <div class="name"></div>
                </div>
                <div class="jdt">
                  <div
                    :style="
                      'width:' +
                      [
                        item.proportion || item.right_proportion
                          ? item.proportion > 70
                            ? '70'
                            : item.proportion < 30
                            ? '30'
                            : item.proportion
                          : '50',
                      ] +
                      '%'
                    "
                  >
                    <!-- <span style="margin-left:1px">{{item.pk_type==2? (item.my_rate + '%'):('￥'+item.my_achievement)}}</span> -->
                  </div>
                  <img
                    class="qt"
                    :style="
                      'left:' +
                      [
                        item.proportion
                          ? item.proportion > 70
                            ? '70'
                            : item.proportion < 30 && item.proportion
                            ? '30'
                            : item.proportion
                          : '50',
                      ] +
                      '%'
                    "
                    src="@/assets/img/qt.png"
                  />
                  <div
                    :style="
                      'width: ' +
                      [
                        item.right_proportion || item.proportion
                          ? item.right_proportion > 70
                            ? '70'
                            : item.right_proportion < 30
                            ? '30'
                            : item.right_proportion
                          : '50',
                      ] +
                      '%;'
                    "
                  >
                    <!-- <span >{{item.pk_type==2? (item.opponent_rate + '%'):('￥'+item.opponent_achievement)}}</span> -->
                  </div>
                </div>
                <div class="right_head">
                  <img src="@/assets/img/fight_type.png" alt="" class="quan" />
                  <img
                    alt=""
                    class="moren"
                    v-if="item.opponent_head_portrait"
                    :src="item.opponent_head_portrait"
                  />
                  <img
                    src="@/assets/img/morenhead.png"
                    alt=""
                    v-else
                    class="moren"
                  />
                  <!-- <image   v-if="item.opponent_head_portrait" src="{{item.opponent_head_portrait}}"></image> -->
                  <!-- <image class="moren" v-else src="/assets/img/morenhead.png"></image> -->
                  <div class="fight_name">
                    {{ !item.operate ? "应战" : "挑战" }}方
                  </div>
                  <div class="name"></div>
                </div>
              </div>
              <div class="PK_name">
                <div style="min-width: 60px; text-align: center; color: #666">
                  {{ item.name }}
                </div>
                <div style="min-width: 60px; text-align: center; color: #666">
                  {{ item.opponent_name }}
                </div>
              </div>
              <!-- <div class="PK_begin_time" v-if="item.distance_start_time">
                距离开始<span style="font-size: 32px">{{
                  item.distance_start_time
                }}</span
                >天
              </div> -->
              <div class="PK_money">PK金:{{ item.pk_money }}元</div>
              <div class="PK_agree" v-if="0">
                <div class="agree" @click="agree(item.id)">同意</div>
                <div class="noagree" @click="noagree(item.id)">拒绝</div>
              </div>
              <div class="PK_rwe" v-if="item.pk_type == 2">
                <div class="left" style="height: 100%">
                  <div
                    style="
                      flex: 1;
                      font-size: 12px;
                      display: flex;
                      align-items: center;
                    "
                  >
                    己方任务额
                  </div>
                  <div style="flex: 1">{{ item.my_target }}</div>
                </div>
                <div class="xian"></div>
                <div class="right" style="height: 100%">
                  <div
                    style="
                      flex: 1;
                      font-size: 12px;
                      display: flex;
                      align-items: center;
                    "
                  >
                    对方任务额
                  </div>
                  <div style="flex: 1">{{ item.opponent_target }}</div>
                </div>
              </div>
              <!-- <div class="PK_money" v-if="item.status == 2">
                PK金:{{ item.pk_money }}元
              </div> -->
              <div></div>
              <!-- <div class="PK_money" v-if="item.status == 1 && !item.operate">
                等待对方同意....
              </div> -->
              <div class="PK_sb" v-if="0">
                <div>PK金：{{ item.pk_money }}</div>
                <img
                  src="http://domssmaster.oss-cn-chengdu.aliyuncs.com/master-resources/%E8%83%9C%E5%88%A9%402x.png"
                  v-if="item.winner == 2"
                />
                <img
                  src="http://domssmaster.oss-cn-chengdu.aliyuncs.com/master-resources/%E8%B4%A5%402x.png"
                  v-if="item.winner == 3"
                />
                <img
                  src="http://domssmaster.oss-cn-chengdu.aliyuncs.com/master-resources/%E5%B9%B3%402x.png"
                  v-if="item.winner == 1"
                />
                <div @click="up_pk(item)">再次挑战</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {todayAchievement,pkList} from '../../api/User/home';
export default {
  data() {
    return {
      check: 1,
      status: 1,
      fight: 1,
      list: [],
      achi_list:[],         //到账列表
      position:''
    };
  },
  created () {
    this.position=JSON.parse(localStorage.getItem('token')).position_type
    this.gettodayAchievement()
  },
  methods:{
    gettodayAchievement(){
      todayAchievement().then(res=>{
        if (res.data.code==200) {
          this.achi_list = res.data.data
        }
      })
    },
    click_check(i){
      this.check = i
      if (i==1) {
        this.gettodayAchievement()
      }
      if (i==2) {
        this.getpkList(1)
      }
    },
    click_fight(i){
      this.fight = i
      this.getpkList(i)
    },
    getpkList(i){
      let data = {}
      if (i==1) {
        data = {
          type:1
        }
      }else{
        data = {
          type:2
        }
      }
      pkList(data).then(res=>{
        this.list=res.data.data.data
      })
    },
    lookmore(){
      // return
        this.$router.push('/Marketing/Pk_list');
        localStorage.setItem("isSelect", "10-1");
    }
  }
};
</script>

<style lang="scss">
.arrived_box {
  width: 33%;
  background: #fff;
  border-radius: 6px;
  margin-left: 16px;
  .title {
    height: 51px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      width: 41%;
      display: flex;
      justify-content: space-between;
      color: #606060;
      font-size: 18px;
      .active {
        color: rgba(0, 0, 0, 0.8) !important;
        font-weight: bold;
      }
    }
    .right {
      display: flex;
      align-items: center;
      color: #ccc;
      font-size: 14px;
    }
  }
  .bot_box {
    padding: 0 27px;
    width: 100%;
    // display: flex;
    // flex-direction: column;
    overflow: auto;
    height: 356px;
    // align-items: center;
    .last {
      width: 71px;
      height: 25px;
      background: #e6f0ff;
      border-radius: 12.5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 25px;
      box-sizing: border-box;
      padding: 0 5px;
      text-align: center;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #3a85fc;
    }
    .item {
      width: 100%;
      height: 75px;
      border-bottom: 1px solid #f2f2f2;
      float: left;
      display: flex;
      align-items: center;
      .img_info {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .center {
        width: 77%;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        color: #666;
        div {
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 0;
  }
  .fight_box {
    .PK_big:nth-of-type(1) {
      margin-top: 10px;
    }
    .hahahaha {
        overflow: auto;
        height: 326px;
      }
    .title_check {
      //   height: ;
      width: 100%;
      background: #fff;
      // position: fixed;

      // top: 0;
      .fight_1 {
        width: 120px;
        height: 30px;
        display: flex;
        margin: 0 auto;
        line-height: 30px;
        border-radius: 2px;
        border: 1px solid #e2e2e2;
        box-sizing: border-box;
        div {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #666;
        }
        .fight {
          width: 70px;
          // height: 30px;
          background: #4b85ff;
          color: #fff;
        }
      }
      
    }
    height: 356px;
    // overflow: auto;
    width: 90%;
    margin: 0 auto;
    .PK_big {
      position: relative;
      width: 100%;
      // padding: 10px 0;
      padding-top: 0;
      margin-bottom: 10px;
      border: 1px solid #ebebeb;
        .PK_date{
            width: 51px;
            height: 40px;
            top: 5px;
            left: 9px;
            position: absolute;
            box-shadow: 0px 2px 2px rgba(75, 153, 255, 0.16);
            text-align: center;
            div:nth-of-type(1){
                font-size: 14px;
                color: #333;
            }
             div:nth-of-type(2){
                font-size: 14px;    
                color: #4B85FF;
                width: 100%;
                overflow:hidden;
                white-space:nowrap;
                text-overflow: ellipsis;

                font-weight: 600;
            }
        }
    }
  }
}
.PK_list {
  margin-top: 10px;
  /* min-height: 322px; */
  width: 75%;
  margin: 0 auto;
  /* background: #fff; */
  
}
.PK_info {
  min-height: 166px;
  width: 100%;
  padding-bottom: 10px;
  background: #fff;
  margin-bottom: 10px;
  overflow: hidden;
}
.PK_info .title {
  width: 100%;
  height: 29px;
  line-height: 29px;
  display: flex;
  justify-content: center;
  .title_p {
    background: #f3f8ff;
    color: #4b85ff;
    padding: 0px 10px;
    //   padding-bottom: 5px;
    border-radius: 0 0 5px 5px;
    white-space: nowrap;
  }
}

.title_text {
  padding: 0 10px;
  border-radius: 0 0 5px 5px;
  box-sizing: border-box;
  min-width: 225px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;
  color: #999999;
  font-size: 14px;
}
.title_text text {
  color: #333333;
}
.PK_jd {
  /* display: flex; */
  width: 80%;
  height: 25px;
  background: #e3e3e3;
  margin: 0 auto;
  margin-top: 20px;
  position: relative;
}
.PK_jd .left_head {
  position: relative;
  width: 55px;
  left: -24px;
  height: 54px;
  top: -13px;
  z-index: 10;
  border-radius: 50px;
  /* background: #ccc; */
}
.PK_jd .left_head .quan {
  position: absolute;
  top: -5px;
  left: -9px;
  height: 110%;
  z-index: 1;
  width: 110%;
}
.PK_jd .left_head .moren {
  border-radius: 50%;
  position: absolute;
  top: -2px;
  left: -5px;
  width: 98%;
  height: 98%;
}
.PK_jd .jdt {
  position: absolute;
  height: 100%;
  width: 85%;
  top: 0;
  left: 7.5%;
  display: flex;
}
.PK_jd .jdt > div:nth-of-type(1) {
  position: relative;
  font-size: 28px;
  color: #fff;
  height: 100%;
  display: flex;
  width: 0;
  align-items: center;
  /* padding-left: 10px; */
  box-sizing: border-box;
  background: linear-gradient(to right, #87bfff, #278fff);
}
.PK_jd .jdt > div:nth-of-type(2) {
  position: relative;
  align-items: center;
  font-size: 28px;
  color: #fff;
  height: 100%;
  width: 0%;
  background: linear-gradient(to right, #fb892a, #fed911);
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  /* padding-right: 10px; */
  box-sizing: border-box;
}

.PK_jd .qt {
  /* translate:translateX(-141px) ; */
  margin-left: -65px;
  position: absolute;
  /* left: 0; */
  top: -4px;
  left: 50%;
  width: 140px;
  height: 32px;
  z-index: 9;
}
.PK_jd .right_head {
  position: absolute;
  width: 55px;
  right: -35px;
  height: 55px;
  top: -11px;
  border-radius: 50px;
  z-index: 10;
  /* background: #ccc; */
}
.PK_jd .right_head .quan {
  position: absolute;
  top: -5px;
  left: -9px;
  height: 110%;
  z-index: 1;
  width: 110%;
}
.PK_jd .right_head .moren {
  position: absolute;
  border-radius: 50%;
  top: -2px;
  left: -5px;
  width: 98%;
  height: 98%;
}

.PK_jd .right_head .fight_name {
  position: absolute;
  color: #cd5700;
  z-index: 6;
  bottom: 0px;
  left: 6px;
  font-size: 10px;
}
.PK_jd .left_head .fight_name {
  width: 100px;
  position: absolute;
  z-index: 6;
  color: #cd5700;
  font-size: 10px;
  bottom: -0px;
  left: 6px;
}
.PK_info .PK_name {
  width: 95%;
  margin: 0 auto;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.PK_info .PK_begin_time {
  width: 235px;
  margin-top: 20px;
  border-radius: 25px;
  height: 50px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  background: #3d94f7;
  color: #fff;
}
.PK_info .PK_money {
  color: #999999;
  text-align: center;
  margin-top: -55px;
}
.PK_info .PK_agree {
  width: 50%;
  height: 65px;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.PK_info .PK_agree .agree {
  width: 150px;
  height: 65px;
  background: linear-gradient(0deg, #007cff 0%, #027cff 100%);
  border-radius: 32.5px;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  box-shadow: 0 0 10px #007cff;
}
.PK_info .PK_agree .noagree {
  width: 150px;
  height: 65px;
  background: linear-gradient(0deg, #fe4d47 0%, #fe4d49 100%);
  border-radius: 32.5px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 10px #fe4d47;
  color: #fff;
  justify-content: center;
}
.PK_info .PK_rwe {
  width: 163px;
  height: 37px;
  margin: 0 auto;
  margin-top: 10px;
  background: #3d94f7;
  border-radius: 40px;
}
.PK_info .PK_rwe {
  /* display: flex; */

  position: relative;
  justify-content: space-around;
  color: #fff;
}
.PK_info .PK_rwe .left {
  float: left;
  width: 50%;
  align-items: center;
  display: flex;
  flex-direction: column;
  /* border-right:1px solid #fff ; */
}
.PK_info .PK_rwe .left > div:nth-of-type(2) {
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}

.PK_info .PK_rwe .right {
  width: 50%;
  float: left;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.PK_info .PK_rwe .right > div:nth-of-type(2) {
  text-align: center;
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.PK_info .PK_rwe .xian {
  position: absolute;
  left: 50%;
  height: 50%;
  width: 2px;
  background: #fff;
  top: 50%;
  margin-left: -1px;
  margin-top: -5%;
}
.PK_info .PK_sb {
  width: 550px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.PK_info .PK_sb > div:nth-of-type(1) {
  padding: 0 10px;
  box-sizing: border-box;
  width: 240px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #407ff3;
  color: #417bf3;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 50px;
}
.PK_info .PK_sb > div:nth-of-type(2) {
  width: 240px;
  height: 50px;
  border-radius: 10px;
  background: #3d91f7;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 50px;
}
.PK_info .PK_sb > image {
  width: 80px;
  height: 80px;
}
.PK_info .PK_yj_money {
  display: flex;
  width: 65%;
  margin: 0 auto;
  color: #333;
  justify-content: space-between;
  /* margin-bottom: -40px; */
  position: relative;
  top: 20px;
  z-index: 20;
}
</style>