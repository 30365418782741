<template>
    <div class="cloud-list">
        <!--选项卡-->
        <div class="cloud_search">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="全部" name="0"></el-tab-pane>
                <el-tab-pane :label="item.name" :name="item.id.toString()" v-for="item in typeList"></el-tab-pane>
            </el-tabs>
            <div class="search_input">
                <input v-model="cloud_platform_product_name" placeholder="输入产品名称"/>
                <div @click="onSearch">搜索</div>
            </div>
        </div>

        <div class="cloud_img" v-if="cloudList!=''">
            <ul>
                <li v-for="item in cloudList" @click="onDetail(item.id)">
                    <img :src="item.img_url"/>
                    <h2>{{item.name}}</h2>
                    <p>{{item.introduce}}</p>
                </li>
            </ul>
        </div>
        <el-empty description="暂时没有更多~" v-if="cloudList==''"></el-empty>

        <div class="block">
            <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="pageSize"
                    el-pagination
                    layout="total,prev, pager, next, jumper"
                    :total="total"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import {
        getProductList,
        productListd
    } from "../../api/User/cloud";
    export default {
        name: "cloud_platform-list",
        data() {
            return {
                cloud_platform_product_name:'',//产品名称
                cloud_platform_classify_id:'',//分类id
                typeList:[],//分类列表
                activeName: '0',
                currentPage: 1, //页码
                total: 0, //总条数
                cloudList:[],//列表
                pageSize:10,
                current_id:0
            };
        },
        created() {
            this.getType();
            this.getList()
        },
        methods: {
            //选项卡
            handleClick(tab) {
                this.current_id = tab.index;
                this.currentPage=1
                this.getChange(tab.name)
            },
            //翻页
            handleCurrentChange(val) {
                this.currentPage = val;
                console.log(val)
                this.getChange(this.cloud_platform_classify_id)
            },
            //数据切换
            getChange(name){
                console.log(name)
                if(this.current_id==0){
                    if(this.cloud_platform_product_name!=''){
                        this.getList('',this.cloud_platform_product_name)
                    }else{
                        this.getList()
                    }
                }else{
                    this.cloud_platform_classify_id=name;
                    if(this.cloud_platform_product_name!=''){
                        this.getList(this.cloud_platform_classify_id,this.cloud_platform_product_name)
                    }else{
                        this.getList(this.cloud_platform_classify_id,'')
                    }
                }
            },
            //获取分类类别
            getType(){
                getProductList({
                }).then(res=>{
                    this.typeList=res.data.data
                })
            },
            //获取产品列表
            getList(id,name){
                productListd({
                    cloud_platform_classify_id:id,
                    cloud_platform_product_name:name,
                    page:this.currentPage
                }).then(res=>{
                    this.cloudList=res.data.data.data,
                    this.currentPage = res.data.data.current_page,
                    this.total=res.data.data.total
                })
            },
            //搜索名称
            onSearch(){
                this.page=1;
                this.getList(this.cloud_platform_classify_id,this.cloud_platform_product_name)
            },
            //查看详情
            onDetail(e){
                this.$router.push({
                    name: "CloudDetails",
                    query: {
                        cloud_platform_product_id:e
                    },
                });
            }
        }
    }
</script>

<style scoped lang="scss">

    .cloud-list{
        background: #fff;
        padding: 20px;
        margin: 30px;
        .cloud_search{
            display: flex;
            justify-content: space-between;
            position: relative;
            .el-tabs{
                width: 100%;
            }
            .el-tabs__item{
                height: 50px;
            }
            .el-tabs__nav-wrap::after{
                height: 1px;
            }
            .search_input{
                display: flex;
                align-items: center;
                position: absolute;
                top: 0;
                right: 20px;
                input{
                    width: 240px;
                    height: 32px;
                    border: 1px solid #E0E0E0;
                    border-radius: 2px;
                    padding-left: 10px;
                }
                div{
                    margin-left: 10px;
                    width: 70px;
                    height: 32px;
                    background: #3A85FC;
                    border-radius: 2px;
                    font-size: 14px;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .cloud_img ul{
           display: flex;
            flex-wrap: wrap;
            align-items: center;
            li{
                width: 16%;
                height: 308px;
                background: #FFFFFF;
                margin-right:2%;
                margin-top: 50px;
                margin-left: 2%;
                img{
                   width: 100%;
                   height: 212px;
                }
                h2{
                    margin-top: 20px;
                    margin-left: 10px;
                    color: #000000;
                    font-size: 14px;
                }
                p{
                    margin-top: 10px;
                    margin-left: 10px;
                    font-size: 12px;
                    color: #666;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
            }
            li:nth-child(5n){
                margin-right: 0;
            }
            li:hover{
                box-shadow: 3px 5px 15px 0px rgba(154, 156, 160, 0.35);
            }
        }
        .block {
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;
        }
    }

</style>