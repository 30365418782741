<template>
  <div class="index_header">
    <el-container>
      <!--顶部-->
      <el-header>
        <div class="index_header_header_info">
          <div class="index_header_fir_box pointer" @click="to_all_can_see">
            <img src="@/assets/img/logo.png" alt="" />
            <div class="info_home">
              <img src="@/assets/img/home.png" alt="" style="height: 18px; width: 20px; display: block" />
            </div>
          </div>
          <div class="index_header_sec_box">
            <div
              :class="[check_id == item.id ? 'header_active' : '']"
              v-for="(item, index) in header_array"
              :key="index"
              @click="handleHeader(item)"
            >
              {{ item.permissions_name }}
            </div>
          </div>
          <div class="index_header_thr_box">
            <div class="wu_yan_zu" @mouseover="mouseOver(1)" @mouseleave="mouseLeave">
              <img src="../assets/img/doms.png" alt="" />
            </div>
            <div class="peng_yu_yan" @mouseover="mouseOver(2)" @mouseleave="mouseLeave">
              <img src="../assets/img/sssd.jpg" alt="" />
            </div>
            <div class="index_header_thr_box_info"></div>
            <div class="index_header_thr_box_info_t" v-if="user">
              <el-dropdown @command="handleHead">
                <span class="el-dropdown-link">
                  <img v-if="user.head_portrait" :src="user.head_portrait" alt="" />
                  <img v-else src="../assets/img/more_head.png" alt="" />

                  <i v-if="user">{{ user.name }}</i
                  ><i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="3">个人信息</el-dropdown-item>
                  <el-dropdown-item v-if="user.is_company.length > 0" :command="2">选择部门</el-dropdown-item>
                  <el-dropdown-item :command="1">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="show_img_box" :class="[show_img == 1 ? 'index_H_show' : '']">
              <img src="../assets/img/doms.png" alt="" />
              小程序二维码
            </div>
            <div class="show_img_box" :class="[show_img == 2 ? 'index_H_show' : '']">
              <img src="../assets/img/sssd.jpg" alt="" />
              公众号二维码
            </div>
          </div>
        </div>
      </el-header>
      <!--部门-->
      <div class="more_company" v-if="isMore_choose">
        <div class="login_Choose" v-if="isMore">
          <div class="login_choose_logo">
            <img src="../assets/img/login_choose_logo.png" alt="" />
          </div>
          <div class="login_choose_tit">请选择部门</div>
          <div style="height: 80%; overflow: auto" class="choose_box_big">
            <div
              :class="item.isCheck ? 'choose_box_check' : 'choose_box'"
              v-for="(item, index) in companyList"
              :key="index"
              @click="handleCompany(item.company.id)"
            >
              {{ item.company.name }}
            </div>
          </div>
          <div class="choose_bottom">
            <div class="choose_no" @click="handleNo">取消</div>
            <div class="choose_ok" @click="handleOk">确定</div>
          </div>
        </div>
      </div>
      <!--个人信息-->
      <el-drawer :visible.sync="drawer" :with-header="false" size="35%" class="human_drawer">
        <div class="drawer_all">
          <div class="drawer_title" style="display: flex; justify-content: center">个人中心</div>
          <div class="index_header_user">
            <img v-if="user.head_portrait" class="header_dead_img" :src="user.head_portrait" alt="" />
            <img v-else class="header_dead_img" src="../assets/img/more_head.png" alt="" />
            <!-- <div class="header_dead_username">{{username}}</div> -->
          </div>
          <div class="drawer_compony_info">
            <div class="drawer_nice">姓名</div>
            <el-input type="text" placeholder="请输入人员姓名" v-model="username" maxlength="10" show-word-limit />
          </div>
          <div class="drawer_compony_info">
            <div class="drawer_nice">电话</div>
            <el-input type="text" v-model="mobile" maxlength="11" show-word-limit disabled />
          </div>
          <div class="drawer_compony_info">
            <div class="drawer_nice">职位</div>
            <el-input type="text" v-model="position" show-word-limit disabled />
          </div>
          <!-- <div class="drawer_compony_info">
                <div class="drawer_nice">角色</div>
                <el-input
                type="text"
                v-model="role"
                show-word-limit
                disabled
                />
            </div> -->
          <div class="drawer_compony_info">
            <div class="drawer_nice">入职日期</div>
            <el-date-picker
              v-model="date"
              align="right"
              type="date"
              disabled
              placeholder="选择日期"
              @change="handleTime"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </div>

          <div class="drawer_compony_info">
            <div class="drawer_nice">性别</div>

            <el-select v-model="sex" placeholder="请选择">
              <el-option v-for="(item, index) in sex_list" :key="index" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </div>

          <div class="drawer_compony_info">
            <div class="drawer_nice">所属部门</div>
            <div class="humancompony_info no_pointer">
              <div v-for="(item, index) in user_componey" :key="index">
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="drawer_compony_info" v-if="other_componey.length > 0">
            <div class="drawer_nice">管理部门</div>
            <div class="humancompony_info no_pointer">
              <div v-for="(item, index) in other_componey" :key="index">
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="drawer_compony_info">
            <div class="drawer_nice">邮箱</div>
            <el-input type="text" placeholder="请输入人员邮箱" v-model="email" show-word-limit />
          </div>

          <div class="drawer_bottom" style="padding: 10px">
            <div class="pointer" style="width: 100px; margin-left: 30px" @click="handleChangepass">修改密码</div>
            <!-- <div
              class="pointer"
              style="width: 100px; margin-left: 10px"
              @click="handlePwd"
            >
              找回密码
            </div> -->
            <div
              class="pointer"
              style="width: 100px; margin-left: 10px"
              @click="
                change_phone = true;
                isMore = true;
                drawer = false;
              "
            >
              电话修改
            </div>
            <div class="pointer" style="width: 100px" @click="handleEdit">确认修改</div>
          </div>
        </div>
      </el-drawer>
      <!--密码-->
      <div class="more_company" v-if="isChangepass" style="height: 410px; width: 640px">
        <div class="more_head" style="font-size: 16px">修改密码</div>
        <div class="choose_box_password">
          <div class="choose_box_username">用户名：{{ username }}</div>
          <div class="choose_box_pass">
            <div>密码</div>
            <div class="pass_input">
              <el-input type="text" placeholder="请输入密码(6位及以上)" v-model="password" show-password />
            </div>
          </div>
          <div class="choose_box_pass">
            <div>请再次确认密码</div>
            <div class="pass_input">
              <el-input type="text" placeholder="请再次确认密码(6位及以上)" v-model="password_two" show-password />
            </div>
          </div>
        </div>
        <div class="pass_bottom">
          <div class="pass_no pointer" @click="handleNo_change">取消</div>
          <div class="pass_ok pointer" @click="handleOk_change">保存</div>
        </div>
      </div>

      <!--找回密码-->

      <!--手机-->
      <div class="more_company" v-if="change_phone" style="height: 370px; width: 640px">
        <div class="more_head" style="font-size: 16px">电话修改</div>
        <div class="head_phone">更换手机号，下次登录使用新手机号登录，当前绑定手机号：{{ mobile }}</div>
        <div class="choose_box_password">
          <div class="choose_box_username">用户名：{{ username }}</div>
          <div class="choose_box_pass" style="display: flex; align-items: center">
            <div class="phone_left">
              <div style="width: 56px">电话：</div>
              <div class="pass_input" style="height: 32px">
                <el-input type="text" placeholder="请输入电话号码" v-model="edit_phone" maxlength="11" show-word-limit @input="phoneinput">
                  <template slot="prepend">+86</template>
                </el-input>
              </div>
            </div>
          </div>
          <div class="choose_box_pass" style="display: flex; align-items: center">
            <div class="phone_left" style="width: 65%">
              <div style="width: 56px">验证码：</div>
              <div class="pass_input" style="height: 32px">
                <el-input type="text" placeholder="请输入验证码" v-model="code_phone" :disabled="!ok_send" maxlength="10" />
              </div>
            </div>
            <div :class="ok_send ? 'pointer ok_send' : 'pointer send'" @click="sendcode">
              获取验证码
              <span v-if="show_count">({{ count_down }}s)</span>
            </div>
          </div>
        </div>
        <div class="pass_bottom">
          <div class="pass_no pointer" @click="handleNo_change">取消</div>
          <div class="pass_ok pointer" @click="Ok_editphone">保存</div>
        </div>
      </div>
      <div class="login_mc" v-if="isMore"></div>
      <router-view></router-view>
      <div class="Launch" v-if="Launch">
        <div class="Launch_img_box" style="width: 100%; height: 100%">
          <img :src="Launch_img" alt="" style="width: 100%; height: 100%" />
          <span
            style="
              text-align: center;
              z-index: 1055;
              position: absolute;
              top: 20px;
              right: 80px;
              font-size: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: 50px;
              color: #666;
            "
            >{{ num }}秒后关闭</span
          >
          <span
            style="
              display: block;
              height: 50px;
              width: 50px;
              border: 2px solid #666;
              border-radius: 50%;
              position: absolute;
              top: 20px;
              right: 20px;
              text-align: center;
            "
          >
            <i class="el-icon-close" style="font-size: 40px; color: #666; line-height: 50px" @click="colse_Launch"></i>
          </span>
        </div>
      </div>
    </el-container>

    <div class="index_header_shadow" v-if="new_shadow">
      <div class="info_first_img">
        <img :src="newimg[0].img_url" alt="" />
      </div>
    </div>
    <div class="index_header_show" v-if="newimg_show">
      <div class="info_sec_img">
        <img :src="newimg[1].img_url" alt="" />
        <i class="el-icon-circle-close pointer" @click="newimg_show = false"></i>
      </div>
    </div>
    <div class="excellence_allbox" v-if="right_array.length > 0">
      <div class="excellence" v-for="(item, index) in right_array" :key="index">
        <div class="excellence_box elementToFadeInAndOut">
          <img :src="item.type == 1 ? img1_src : item.type == 2 ? img2_src : img3_src" alt="" />
          <div class="excellence_box_deadimg">
            <img :src="item.head_portrait ? item.head_portrait : img_moren" alt="" />
          </div>
          <div class="excellence_box_position">
            <div class="dep_name">{{ item.company_str }}</div>
            <div class="who_money">
              <span>{{ item.user_name }}</span>
              <span style="margin-left: 3px">{{ item.to_money }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
window.addEventListener("scroll", function () {
  var box = document.getElementsByClassName("el-aside")[0];
  if (box) {
    box.style.left = "-" + document.documentElement.scrollLeft + "px";
  }
});
import human_detail from "./human/human";
import { selectRole, positionList } from "../api/User/human";
import { coreUserDetail, coreEditUser, modifyPhoneSendCode, getVerificationCode, getNewPwd } from "../api/User/user";
import { obtainUserCompany, choiceCompany } from "../api/User/login";
import { Finance_center, role, setting_center, Marketing } from "../components/index";
export default {
  components: {
    Financecenter: Finance_center,
    "human-detail": human_detail,
    Role: role,
    System: setting_center,
    Marketing: Marketing,
  },
  data() {
    return {
      codeTimer: "",
      codeDisable: false,
      codeText: "发送验证码",
      ruleForm: {
        phone: "",
        code: "",
        pwd: "",
      },
      rules: {
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      pwdVisible: false,
      drawer: false,
      header_array: [],
      show_img: "", //
      check_id: "", //顶部选择项
      user: {},
      isMore: false,
      companyList: [], //多部门选择
      isMore_choose: false,
      company_id: "", //第一次进入选的id
      choice_company_id: "", //多部门选择的id
      resources_id: "", //人资中心
      Finance_id: "", //财务中心
      system_id: "", //系统设置
      username: "", //用户姓名
      mobile: "", //用户电话
      position: "", //用户职位
      role: "", //用户角色
      date: "", //入职时间
      user_componey: [], //所属部门
      other_componey: [], //管理部门
      sex: "", //用户性别
      email: "", //用户邮箱
      password: "", //用户密码
      password_two: "", //第二次输入
      isChangepass: false,
      sex_list: [
        { id: "1", name: "男" },
        { id: "2", name: "女" },
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      Launch: false, //是否有启动页
      Launch_img: "", //启动页图片
      num: 3,
      websock: null, //全局socket;
      newimg: [],
      newimg_show: false, //真图片
      new_shadow: false, //前奏
      right_array: [],
      img1_src: require("../assets/img/excellence_large.png"),
      img2_src: require("../assets/img/excellence_in.png"),
      img3_src: require("../assets/img/excellence_small.png"),
      img_moren: require("../assets/img/more_head.png"),
      limit: 0,
      timeer: null,

      change_phone: false, //修改电话
      edit_phone: "",
      code_phone: "", //验证码
      ok_send: false,
      count_down: 60,
      show_count: false,
      edit_timer: null,
    };
  },
  mounted() {
    if (
      this.$route.fullPath == "/all_can_see" ||
      this.$route.fullPath == "/cloud_platform/CloudList" ||
      this.$route.fullPath.indexOf("/cloud_platform/CloudDetails") != -1
    ) {
      var heihei = document.getElementsByClassName("el-container")[0];
      heihei.style.paddingLeft = 0;
      this.check_id = "";
    } else {
      var heihei = document.getElementsByClassName("el-container")[0];
      heihei.style.paddingLeft = 250 + "px";
    }
  },
  created() {
    this.initWebSocket();
    let user = JSON.parse(localStorage.getItem("user")); //用户信息  头像 姓名  如果有多部门     则 is_company
    this.user = user;
    let role = user.role_permissions; //权限列表
    this.header_array = role;
    this.check_id = role[0].id;
    let start_img = JSON.parse(localStorage.getItem("start_img")); //启动图
    if (start_img.is_show && start_img.url) {
      this.Launch = true;
      this.Launch_img = start_img.url;
      timer = setInterval(() => {
        this.num--;
        if (this.num == 0) {
          this.colse_Launch();
          this.stop(timer);
        }
      }, 1000);
    }

    for (let i = 0; i < role.length; i++) {
      if (role[i].permissions == "role") {
        role.splice(i, 1);
      }
      if (role[i].permissions == "resources") {
        this.resources_id = role[i].id;
      }
      if (role[i].permissions == "Finance") {
        this.Finance_id = role[i].id;
      }
      if (role[i].permissions == "system") {
        this.system_id = role[i].id;
      }
      if (role[i].permissions == "Approval") {
        this.system_id = role[i].id;
      }
      if (role[i].permissions == "cloud_platform") {
        this.system_id = role[i].id;
      }
    }
    if (localStorage.getItem("check_id")) {
      if (JSON.parse(localStorage.getItem("check_id"))) {
        let check_id = JSON.parse(localStorage.getItem("check_id"));
        this.check_id = check_id;
      }
    }
    obtainUserCompany().then((res) => {
      if (res.data.code == 200) {
        let list = res.data.data.is_company;
        let company_id = res.data.data.company_id;
        this.company_id = company_id;
        list.forEach((item) => {
          item.isCheck = false;
          if (item.company_id == company_id) {
            item.isCheck = true;
          }
        });
        this.companyList = [...list];
      }
    });
  },
  updated() {
    var box = document.getElementsByClassName("el-aside")[0];
    if (box) {
      box.style.left = "-" + document.documentElement.scrollLeft + "px";
    }
  },
  methods: {
    handleClosePwd() {
      clearInterval(this.codeTimer);
      this.codeText = "发送验证码";
      this.codeDisable = false;
      this.$refs["ruleForms"].resetFields();
    },
    //找回密码
    handlePwd() {
      this.pwdVisible = true;
    },
    //
    submitPwd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            phone: this.ruleForm.phone,
            code: this.ruleForm.code,
            password: this.ruleForm.pwd,
          };
          getNewPwd(data).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              this.$message({
                message: res.data.message,
                type: "success",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    //获取验证码
    getCode() {
      //
      let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg_tel.test(this.ruleForm.phone)) {
        this.$message({
          message: "请输入正确的手机号",
          type: "error",
        });
        return;
      }

      let i = 59;
      this.codeTimer = setInterval(() => {
        if (i == 0) {
          clearInterval(this.codeTimer);
          this.codeText = "发送验证码";
          this.codeDisable = false;
        } else {
          i--;
          this.codeText = `${i}s`;
          this.codeDisable = true;
        }
      }, 1000);
      getVerificationCode({ phone: this.ruleForm.phone }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "验证码发送成功",
            type: "success",
          });
        }
      });
    },
    to_all_can_see() {
      this.$router.push("/newDataIndex");
      // this.$router.push("/all_can_see");
      localStorage.removeItem("check_id");
      this.check_id = "";
    },
    stop(timer) {
      clearInterval(timer);
    },
    colse_Launch() {
      let start_img = JSON.parse(localStorage.getItem("start_img")); //启动图
      this.Launch = false;
      let obj = {
        is_show: false,
        url: start_img.url,
      };
      localStorage.setItem("start_img", JSON.stringify(obj));
    },
    initWebSocket() {
      //初始化weosocket
      //  wss://domsdevhyperf.sssdbdsyt.cn/ws
      // const wsuri = "ws://domsdevhyperf.sssdbdsyt.cn:9502?"+'token='+JSON.parse(localStorage.getItem('token')).token;
      if (this.limit > 3) {
        return;
      }
      let limit = this.limit;
      limit++;
      this.limit = limit;
      const wsuri =
        // 正式  domshyperf.sssdbdsyt.cn
        //预发布 domshyperfbranch.sssdbdsyt.cn
        //   测试 domsdevhyperf.sssdbdsyt.cn
        "wss://domshyperf.sssdbdsyt.cn/ws?" + "token=" + JSON.parse(localStorage.getItem("token")).token;
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
      // console.log(this.limit);
    },
    websocketonopen() {
      //连接建立之后执行send方法发送数据
      // this.websocketsend(JSON.stringify(actions));
      if (this.limit > 10) {
        return;
      }
      // clearInterval(timer)
      if (this.timer != null) {
        clearInterval(this.timer);
      }
      let that = this;
      this.timer = setInterval(() => {
        that.websocketsend();
        // this.limit++
      }, 12000);
    },
    websocketonerror() {
      //连接建立失败重连
      this.initWebSocket();
    },
    websocketonmessage(e) {
      // console.log(e.data);
      let that = this;
      let hei = JSON.parse(e.data);
      // console.log(e);
      // console.log(hei);
      if (hei.code == 200) {
        if (hei.data) {
          if (hei.data.api_type == 1) {
            ////到账全部推送
            this.right_array.push(hei.data);
          } else if (hei.data.api_type == 2) {
            ////播报返回数据
            this.newimg = hei.data.imgs;
            this.new_shadow = true;
            this.newimg_show = false;
            setTimeout(() => {
              that.new_shadow = false;
              that.newimg_show = true;
            }, 1500);
          }
          if (hei.data.api_type == 1) {
            setTimeout(() => {
              this.right_array.splice(0, 1);
            }, 4000);
          }
        }
      } else {
        this.initWebSocket();
      }
    },
    websocketsend(Data) {
      //数据发送
      if (this.limit > 3) {
        // console.log("websocket 链接已断开");
        return;
      }
      this.websock.send(Data);
    },
    websocketclose(e) {
      //关闭
      // console.log('断开连接',e);
      this.initWebSocket();
    },

    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
      var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let strDate = Y + M + D;
      // let strDate = Y + M + D + h + m + '00';
      return strDate;
    },
    handleTime(val) {
      this.date = this.getwaytime(val);
    },
    handleHeader(item) {
      //顶部点击事件 选择header
      this.$router.push(`/${item.permissions}`);
      localStorage.setItem("check_id", item.id);
      this.check_id = item.id;
    },
    mouseOver(i) {
      this.show_img = i;
    },
    mouseLeave() {
      this.show_img = "";
    },
    handleHead(command) {
      if (command == 1) {
        //退出登录
        setTimeout(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("check_id");
          localStorage.removeItem("isSelect");
          localStorage.removeItem("isPersonal");
          localStorage.clear();
          localStorage.clear();
          this.$router.push("/");
        }, 1000);
        this.$message({
          message: "退出成功",
          type: "success",
        });
      }
      if (command == 2) {
        //多部门选择
        this.isMore = true;
        this.isMore_choose = true;
      }
      if (command == 3) {
        //个人信息
        let plist = []; //职位
        positionList().then((res) => {
          if (res.data.code == 200) {
            plist = res.data.data;
          }
        });
        let roleList = []; //角色
        selectRole().then((res) => {
          if (res.data.code == 200) {
            roleList = res.data.data;
          }
        });
        coreUserDetail().then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data;
            this.username = data.name;
            this.mobile = data.phone;
            this.date = data.entry_time;
            this.email = data.email;
            this.user_componey = data.company;
            this.other_componey = data.other_company;
            if (data.sex == 1) {
              this.sex = "男";
            } else {
              this.sex = "女";
            }
            plist.forEach((item) => {
              if (item.id == data.position_id) {
                this.position = item.position;
              }
            });
            roleList.forEach((item) => {
              data.user_role.forEach((itm) => {
                if (item.id == itm) {
                  this.role = item.name;
                }
              });
            });
          }
        });
        this.drawer = true;
      }
    },
    handleEdit() {
      let { username, date, email } = this;
      let sex = "";
      if (this.sex == "男" || this.sex == 1) {
        sex = 1;
      }
      if (this.sex == "女" || this.sex == 2) {
        sex = 2;
      }
      let data = {
        name: username,
        sex,
        entry_time: date,
        email,
        new_phone: "",
        code: "",
      };
      coreEditUser(data).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.user.name = username;
          localStorage.setItem("user", JSON.stringify(this.user)); //本地存储
          this.drawer = false;
        }
      });
    },

    handleChangepass() {
      //修改密码
      this.isChangepass = true;
      this.isMore = true;
      this.drawer = false;
    },
    handleNo_change() {
      //取消修改密码
      this.isChangepass = false;
      this.isMore = false;
      this.drawer = true;
      this.change_phone = false;
      this.code_phone = "";
      this.ok_send = false;
      this.count_down = 60;
      this.show_count = false;
      this.edit_phone = "";
      this.password = "";
      this.password_two = "";
    },
    phoneinput(value) {
      if (value.length == 11) {
        this.ok_send = true;
      } else {
        this.ok_send = false;
        this.count_down = 60;
        this.show_count = false;
        this.stop(this.edit_timer);
      }
    },
    sendcode() {
      let { edit_phone, ok_send } = this;
      if (!ok_send) {
        this.$message.error("1");
        return;
      }
      var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; //11位手机号码正则
      if (!reg_tel.test(edit_phone)) {
        this.$message.error("请输入正确的电话号码");
        return;
      }
      if (this.show_count) {
        return;
      }
      this.show_count = true;
      modifyPhoneSendCode({
        phone: edit_phone,
      }).then((res) => {
        this.show_count = false;
        if (res.data.code == 200) {
          this.show_count = true;
          this.$message.success(res.data.message);
          this.count_down = 60;
          this.edit_timer = setInterval(() => {
            this.count_down--;
            if (this.count_down == 0) {
              this.stop(this.edit_timer);
              this.show_count = false;
            }
          }, 1000);
        }
      });
    },
    Ok_editphone() {
      let { username, password, date, email, password_two } = this;
      let sex = "";
      if (this.sex == "男" || this.sex == 1) {
        sex = 1;
      }
      if (this.sex == "女" || this.sex == 2) {
        sex = 2;
      }
      let data = {
        name: username,
        password,
        sex,
        entry_time: date,
        email,
        new_phone: this.edit_phone,
        code: this.code_phone,
      };
      coreEditUser(data).then((res) => {
        if (res.data.code == 200) {
          localStorage.clear();
          setTimeout(() => {
            this.$router.push("/");
          }, 1000);
          this.$message({
            message: res.data.message,
            type: "success",
          });
        }
      });
    },
    handleOk_change() {
      //确定
      let { username, password, date, email, password_two } = this;
      let sex = "";
      if (this.sex == "男" || this.sex == 1) {
        sex = 1;
      }
      if (this.sex == "女" || this.sex == 2) {
        sex = 2;
      }

      if (password != password_two) {
        this.$message.error("密码不匹配");
        return;
      }
      if (password.length < 6) {
        this.$message.error("请输入6位及以上的密码");
        return;
      }
      let data = {
        name: username,
        password,
        sex,
        entry_time: date,
        email,
        new_phone: "",
        code: "",
      };
      coreEditUser(data).then((res) => {
        if (res.data.code == 200) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("check_id");
          localStorage.clear();
          setTimeout(() => {
            this.$router.push("/");
          }, 1000);
          this.$message({
            message: res.data.message,
            type: "success",
          });
        }
      });
    },
    handleCompany(id) {
      let list = this.companyList;
      list.forEach((item) => {
        item.isCheck = false;
        console.log(item);
        if (item.company_id == id) {
          item.isCheck = true;
          localStorage.setItem(
            "company_info",
            JSON.stringify({
              id: id,
              name: item.company.name,
            })
          );
          this.choice_company_id = id;
        }
      });
    },
    handleNo() {
      // 取消选择部门
      this.isMore = false;
      this.isMore_choose = false;
      let { company_id, companyList } = this;
      companyList.forEach((item) => {
        item.isCheck = false;
        if (item.company_id == company_id) {
          item.isCheck = true;
        }
      });
    },
    handleOk() {
      //确定选择部门
      let choice_company_id = "";
      if (this.choice_company_id == "") {
        choice_company_id = this.company_id;
      } else {
        choice_company_id = this.choice_company_id;
      }
      choiceCompany({ choice_company_id }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.isMore = false;
          this.isMore_choose = false;
          localStorage.setItem("token", JSON.stringify(res.data.data)); //本地存储
          location.reload();
        }
      });
    },
  },
  watch: {
    $route: {
      handler(val, oldval) {
        // console.log(val); //新路由信息
        this.$store.commit("updateFullpath", val.fullPath);
        let array = [];
        array = val.fullPath.split("/");

        if (array[0] == "Marketing") {
          this.header_array.forEach((item) => {
            if (item.permissions == "Marketing") {
              this.check_id = item.id;
            }
          });
        } else if (array[0] == "resources") {
          this.header_array.forEach((item) => {
            if (item.permissions == "resources") {
              this.check_id = item.id;
            }
          });
        } else if (array[0] == "Finance") {
          this.header_array.forEach((item) => {
            if (item.permissions == "Finance") {
              this.check_id = item.id;
            }
          });
        } else if (array[0] == "system") {
          this.header_array.forEach((item) => {
            if (item.permissions == "system") {
              this.check_id = item.id;
            }
          });
        } else if (array[0] == "cloud_platform") {
          this.header_array.forEach((item) => {
            if (item.permissions == "cloud_platform") {
              this.check_id = item.id;
            }
          });
        }

        // console.log(this.$store.state.fullPath);
        if (
          val.fullPath == "/all_can_see" ||
          val.fullPath == "/cloud_platform/CloudList" ||
          val.fullPath.indexOf("/cloud_platform/CloudDetails") != -1
        ) {
          var heihei = document.getElementsByClassName("el-container")[0];
          heihei.style.paddingLeft = 0;
          this.check_id == "";
        } else {
          var heihei = document.getElementsByClassName("el-container")[0];
          heihei.style.paddingLeft = 250 + "px";
        }
        if (val.fullPath == "/Marketing/Account") {
          this.header_array.forEach((item) => {
            if (item.permissions == "Marketing") {
              this.check_id = item.id;
            }
          });
        } else if (val.fullPath == "/Marketing/Pk_list") {
          // this.$router.push('/Marketing/Pk_list')
          this.header_array.forEach((item) => {
            if (item.permissions == "Marketing") {
              this.check_id = item.id;
            }
          });
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="scss">
// @-webkit-keyframes fadeinout {
//   0%,100% { opacity: 0; }
//   50% { opacity: 1; }
// }
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.index_header {
  height: 100%;
  min-width: 1400px;
  .Launch {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.5);
    .Launch_img_box {
      // height: 60%;
      // width: 60%;
      width: 800px;
      height: 500px;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      img {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 800px;
      }
      div {
        position: absolute;
        top: 0;
        right: 0;
        width: 100px;
        height: 30px;
        background: #3a85fc;
        border-radius: 20px;
        text-align: center;
        line-height: 30px;
        color: #fff;
      }
      i {
        cursor: pointer;
      }
    }
  }
  .el-container.is-vertical {
    height: 100%;
    .el-header {
      background: #3a85fc;
      height: 70px;
      padding: 0px;
    }
  }
  .el-drawer {
    min-width: 650px;
  }
  .index_header_shadow {
    animation: fadeinout 1.5s linear forwards;
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 20000;
    display: flex;
    align-items: center;
    justify-content: center;
    .info_first_img {
      position: relative;
      z-index: 200001;
      img {
        width: 100%;
        height: 100%;
        opacity: 0.5;
      }
      // top: 0;
      // left:0 ;
      // bottom:0;
      // right: 0;
      height: 40vw;
      width: 40vw;
      // background: #fff;
    }
  }
  .index_header_show {
    animation: fadein 1.5s linear forwards;
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20000;
    display: flex;
    align-items: center;
    justify-content: center;
    .info_sec_img {
      position: relative;
      width: 32vw;
      height: 45vw;
      img {
        width: 100%;
        height: 100%;
        opacity: 1;
      }
      i {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 40px;
      }
      position: relative;
    }
  }
  .excellence_allbox {
    width: 440px;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 60px;
    height: auto;
    right: 0;
  }
  .excellence {
    width: 440px;
    // position: absolute;
    // top: 60px;
    // right: 0;
    .excellence_box {
      width: 440px;
      height: 170px;
      position: relative;
      .excellence_box_deadimg {
        width: 74px;
        height: 74px;
        border-radius: 50%;
        overflow: hidden;
        top: 42px;
        position: absolute;
        left: 39px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .excellence_box_position {
        position: absolute;
        top: 58px;
        right: 80px;
        width: 200px;
        .dep_name {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.8);
          opacity: 1;
          line-height: 20px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .who_money {
          height: 34px;
          display: flex;
          align-items: center;
          span {
            line-height: 34px;
          }
          span:first-child {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            opacity: 0.8;
          }
          span:last-child {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            opacity: 1;
          }
        }
      }
    }
    .elementToFadeInAndOut {
      -webkit-animation: fadeinout 5s linear forwards;
      animation: fadeinout 5s linear forwards;
    }
  }

  .flow_in {
    opacity: 1;
  }
}
.index_header_header_info {
  height: 100%;
  width: 100%;
  // display: flex;
  align-items: center;

  .index_header_fir_box {
    position: relative;
    float: left;
    // background: url(../assets/img/logo.png) no-repeat ;
    // background-size: 100px 20px;
    width: 250px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-left: 30px;

    img {
      height: 20px;
      width: 100px;
    }
    .info_home {
      width: 40px;
      height: 40px;
      position: absolute;
      // margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 10px;
      top: 11px;
    }
    .info_home:hover {
      border-radius: 50%;
      background: rgba(28, 95, 203, 0.3);
    }
  }
  .index_header_sec_box {
    float: left;
    cursor: pointer;
    width: 600px;
    display: flex;
    align-items: center;
    // margin-left: 10%;
    height: 100%;
    div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      flex: 1;
      max-width: 200px;
    }
    div:hover {
      background: rgba(28, 95, 203, 0.3);
    }
    .header_active {
      background: #1c5fcb;
    }
  }
  .index_header_thr_box {
    margin-left: 40%;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    .wu_yan_zu {
      position: relative;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .peng_yu_yan {
      position: relative;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
      margin: 0 20px;
    }
    .show_img_box {
      width: 200px;
      overflow: hidden;
      transition: all 1s;
      height: 0;
      position: absolute;
      z-index: 99;
      top: 60px;
      right: 100px;
      box-sizing: border-box;
      background: #fff;
      text-align: center;
      img {
        width: 200px;
        height: 200px;
        border: 1px solid #ccc;
      }
    }
    .index_H_show {
      height: 230px !important;
      color: #333;
    }
  }
  .index_header_thr_box_info {
    width: 20px;
    height: 20px;
    background: url(../assets/img/news.png) no-repeat;
    background-size: 20px 20px;
  }
  .index_header_thr_box_info_t {
    cursor: pointer;
    margin-left: 12px;
    display: flex;
    align-items: center;
    .el-dropdown-link {
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
    img:nth-of-type(1) {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
    i {
      display: block;
      max-width: 80px;
      overflow: hidden; /*超出部分隐藏*/
      white-space: nowrap; /*不换行*/
      text-overflow: ellipsis; /*超出部分文字以...显示*/
      color: #fff;
    }
    img:nth-of-type(2) {
      width: 12px;
      height: 6px;
      border-radius: 20px;
      margin-left: 5px;
    }
  }
}
.more_company {
  .choose_box_big > div:hover {
    border: 1px solid #3a85fc;
  }
  .choose_box_big {
    div {
      // justify-content: center;
      cursor: pointer;
    }
  }
  .login_Choose {
    // height: 400px;
    height: 100%;
    width: 100%;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    border-radius: 10px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    padding-bottom: 70px;
    .login_choose_logo {
      width: 80%;
      margin: 0 auto;
      margin-top: 40px;
    }
    .login_choose_tit {
      font-size: 16px;
      width: 80%;
      margin: 20px auto;
    }
    .choose_box {
      width: 80%;
      margin: 20px auto;
      height: 40px;
      border: 1px solid #ccc;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding-left: 10px;
    }
    .choose_box_check {
      width: 80%;
      margin: 20px auto;
      height: 40px;
      border: 1px solid #3a85fc;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      background: #f3f8ff;
    }
  }

  .choose_bottom {
    // border-top: 1px solid #ccc;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    bottom: 10px;
    width: 100%;
  }
  .choose_bottom > div {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    cursor: pointer;
    border-radius: 4px;
  }
  .choose_bottom > div:first-child {
    background: #fff;
    color: #3a85fc;
    border: 1px solid #3a85fc;
    margin-right: -80px;
  }
  .choose_bottom > div:last-child {
    background: #3a85fc;
    color: #fff;
  }
  .choose_box_password {
    padding: 0 60px;
  }
  .choose_box_username {
    margin: 30px 0;
  }
  .choose_box_pass {
    margin-bottom: 30px;
    width: 100%;
    justify-content: space-between;
    .phone_left {
      display: flex;
      align-items: center;
      width: 100%;
    }
    .pass_input {
      flex: 1;
      input {
        height: 32px;
      }
      .el-input__suffix {
        height: 32px;
        display: flex;
        align-items: center;
      }
    }
  }
  .send {
    padding: 5px;
    height: 32px;
    margin-left: 30px;
    background: #f4f4f4;
    color: #999999;
    border-radius: 2px;
  }
  .ok_send {
    color: #fff;
    background: #3a85fc;
    margin-left: 30px;
    padding: 5px;
    height: 32px;
    border-radius: 2px;
  }
  width: 500px;
  height: 600px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background: #fff;
  z-index: 99;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .more_head {
    width: 100%;
    height: 50px;
    font-size: 18px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ccc;
  }
  .head_phone {
    width: 600px;
    margin: 0 auto;
    height: 40px;
    background: #ecf5ff;
    border-radius: 2px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
  }
  .pass_bottom {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 60px;
    div {
      height: 40px;
      width: 80px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }
    .pass_no {
      background: #ffffff;
      color: rgba(0, 0, 0, 0.5);
      border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
      margin-right: 16px;
    }
    .pass_ok {
      background: #3a85fc;
      color: #fff;
    }
  }
}
.index_header_user {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  .header_dead_img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
}
.login_mc {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
}
body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
body::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 2px;
}

body::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

// body::-webkit-scrollbar-thumb:hover{
//   background: #333;
// }

body::-webkit-scrollbar-corner {
  background: #fff;
}
.search-pwd {
  border-radius: 10px !important;
}
.search-pwd .el-dialog__header {
  border-bottom: solid 1px #efefef;
  padding: 0;
  height: 48px;
  line-height: 48px;
  text-align: center;

  .el-dialog__title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #262626;
  }
  .el-dialog__headerbtn {
    top: 15px;
  }
  .el-dialog__headerbtn {
    .el-dialog__close {
      color: #8c8c8c;
    }
  }
}
</style>
