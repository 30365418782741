import request from '@/utils/axios.tool';


export const List = (data)=>{
    return request.post(`/roleapi/position/list`,data)
}

//职位列表
export const positionList = (data)=>{
    return request.post(`/roleapi/position/positionList`,data)
}

//新增职位
export const addPosition = (data)=>{              
    return request.post(`/roleapi/position/addPosition`,data)
}

//编辑职位
export const editPosition = (data)=>{              
    return request.post(`/roleapi/position/editPosition`,data)
}

//职位排序
export const setOrderBy = (data)=>{              
    return request.post(`/roleapi/position/setOrderBy`,data)
}


//新增职位分类
export const addPositionClassify = (data)=>{              
    return request.post(`/roleapi/position/addPositionClassify`,data)
}

//修改职位分类
export const editPositionClassify = (data)=>{              
    return request.post(`/roleapi/position/editPositionClassify`,data)
}

//职位分类排序
export const orderByPositionClassify = (data)=>{              
    return request.post(`/roleapi/position/orderByPositionClassify`,data)
}



