<template>
  <div class="PK_list_box" style="flex: 1">
    <el-main>
      <div class="title_box">
        <div class="customer_header_check pointer">
          <div
            :class="isPersonal == 1 ? 'isPersonal' : ''"
            @click="isPersonal = 1"
          >
            个人PK榜
          </div>
          <div
            :class="isPersonal == 2 ? 'isPersonal' : ''"
            @click="isPersonal = 2"
          >
            部门PK榜
          </div>
        </div>
      </div>
      <div class="kind_info">
        <div class="left_tit">
          <div
            class="pointer"
            @click="check_status(item.id)"
            :class="[status == item.id ? 'active' : '']"
            v-for="(item, index) in status_arr"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
       
      </div>
       <div class="list_info" v-if="list.length>0">
          <div v-for="(item, index) in list" :key="index" class="list_item">
            <div class="PK_date" v-if="(item.distance_start_time||item.distance_end_time)&&(status==1||status==2)">
               <div>距{{status==1? '开始':status==2? '结束':''}}</div>
               <div>{{status==1? item.distance_start_time:item.distance_end_time}}天</div>
            </div>
            <div class="PK_date" v-if="status==3" :style="status==3? 'display:flex;align-items:center;justify-content: center;':''">
                <img
                  src="@/assets/img/win.png"
                  v-if="item.winner == 2"
                />
                <img
                  src="@/assets/img/lose.png"
                  v-if="item.winner == 3"
                />
                <img
                  src="@/assets/img/white.png"
                  v-if="item.winner == 1"
                />
            </div>
          <div class="PK_list">
            <div class="PK_info">
              <div class="title">
                <div class="title_p">
                  <span>{{ item.pk_type == 1 ? "业绩PK" : "完成率PK" }}</span>
                  |{{ item.start_time }}~{{ item.end_time }}
                </div>
              </div>
              <div class="PK_yj_money">
                <div>
                  <span>{{
                    item.pk_type == 2
                      ? item.my_rate + "%"
                      : "￥" + item.my_achievement
                  }}</span>
                </div>
                <div>
                  <span>{{
                    item.pk_type == 2
                      ? item.opponent_rate + "%"
                      : "￥" + item.opponent_achievement
                  }}</span>
                </div>
              </div>
              <div class="PK_jd">
                <div class="left_head">
                  <img src="@/assets/img/fight_type.png" class="quan" alt="" />
                  <img
                    :src="item.head_portrait"
                    alt=""
                    class="moren"
                    v-if="item.head_portrait"
                  />
                  <img alt="" v-else src="@/assets/img/morenhead.png" class="moren" />
                  <div class="fight_name">
                    {{ item.operate ? "应战" : "挑战" }}方
                  </div>
                  <div class="name"></div>
                </div>
                <div class="jdt">
                  <div
                    :style="
                      'width:' +
                      [
                        item.proportion || item.right_proportion
                          ? item.proportion > 70
                            ? '70'
                            : item.proportion < 30
                            ? '30'
                            : item.proportion
                          : '50',
                      ] +
                      '%'
                    "
                  >
                    <!-- <span style="margin-left:1px">{{item.pk_type==2? (item.my_rate + '%'):('￥'+item.my_achievement)}}</span> -->
                  </div>
                  <img
                    class="qt"
                    :style="
                      'left:' +
                      [
                        item.proportion
                          ? item.proportion > 70
                            ? '70'
                            : item.proportion < 30 && item.proportion
                            ? '30'
                            : item.proportion
                          : '50',
                      ] +
                      '%'
                    "
                    src="@/assets/img/qt.png"
                  />
                  <div
                    :style="
                      'width: ' +
                      [
                        item.right_proportion || item.proportion
                          ? item.right_proportion > 70
                            ? '70'
                            : item.right_proportion < 30
                            ? '30'
                            : item.right_proportion
                          : '50',
                      ] +
                      '%;'
                    "
                  >
                    <!-- <span >{{item.pk_type==2? (item.opponent_rate + '%'):('￥'+item.opponent_achievement)}}</span> -->
                  </div>
                </div>
                <div class="right_head">
                  <img src="@/assets/img/fight_type.png" alt="" class="quan" />
                  <img
                    alt=""
                    class="moren"
                    v-if="item.opponent_head_portrait"
                    :src="item.opponent_head_portrait"
                  />
                  <img
                    src="@/assets/img/morenhead.png"
                    alt=""
                    v-else
                    class="moren"
                  />
                  <!-- <image   v-if="item.opponent_head_portrait" src="{{item.opponent_head_portrait}}"></image> -->
                  <!-- <image class="moren" v-else src="/assets/img/morenhead.png"></image> -->
                  <div class="fight_name">
                    {{ !item.operate ? "应战" : "挑战" }}方
                  </div>
                  <div class="name"></div>
                </div>
              </div>
              <div class="PK_name">
                <div style="width: 20%; text-align: center; color: #333;">
                  {{ item.name }}
                </div>
                <div style="width: 20%; 88px; text-align: center; color: #333">
                  {{ item.opponent_name }}
                </div>
              </div>
              <!-- <div class="PK_begin_time" v-if="item.distance_start_time">
                距离开始<span style="font-size: 32px">{{
                  item.distance_start_time
                }}</span
                >天
              </div> -->
              <div class="PK_money">PK金:{{ item.pk_money }}元</div>
              <div class="PK_agree" v-if="0">
                <div class="agree" @click="agree(item.id)">同意</div>
                <div class="noagree" @click="noagree(item.id)">拒绝</div>
              </div>
              <div class="PK_rwe" v-if="item.pk_type == 2">
                <div class="left" style="height: 100%">
                  <div
                    style="
                      flex: 1;
                      font-size: 12px;
                      display: flex;
                      align-items: center;
                    "
                  >
                    {{ item.operate ? "应战" : "挑战" }}方任务额
                  </div>
                  <div style="flex: 1">{{ item.my_target }}</div>
                </div>
                <div class="xian"></div>
                <div class="right" style="height: 100%">
                  <div
                    style="
                      flex: 1;
                      font-size: 12px;
                      display: flex;
                      align-items: center;
                    "
                  >
                   {{ !item.operate ? "应战" : "挑战" }}方任务额
                  </div>
                  <div style="flex: 1">{{ item.opponent_target }}</div>
                </div>
              </div>
              <div></div>
              <div class="PK_money" v-if="item.status == 1 && !item.operate" style="margin-top:0px;">
                等待应战方同意
              </div>
              <div class="PK_sb" v-if="0">
                <div>PK金：{{ item.pk_money }}</div>
                <img
                  src="http://domssmaster.oss-cn-chengdu.aliyuncs.com/master-resources/%E8%83%9C%E5%88%A9%402x.png"
                  v-if="item.winner == 2"
                />
                <img
                  src="@/assets/img/lose.png"
                  v-if="item.winner == 3"
                />
                <img
                  src="@/assets/img/white.png"
                  v-if="item.winner == 1"
                />
                <div @click="up_pk(item)">再次挑战</div>
              </div>
            </div>
          </div>
        </div>
       </div>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="6"
          layout="total,prev, pager, next, jumper"
          :total="total"
          v-if="list.length>0"
        >
        </el-pagination>
       <div class="nolist" v-if="list.length==0">
           <img src="@/assets/img/noPK.png" alt="">
           暂无数据
       </div>
    </el-main>
  </div>
</template>

<script>
import { pkList } from "../../api/User/PK";
export default {
  data() {
    return {
      isPersonal: 1,
      position: "",
      status_arr: [
        { id: 1, name: "待开始" },
        { id: 2, name: "进行中" },
        { id: 3, name: "已结束" },
      ],
      status: 1,
        page:1,
        total:0,
      list:[],
      role:{
        list_str:''
      }
    };
  },
  created() {
    let token = JSON.parse(localStorage.getItem("token"));
    this.position = token.position_type;
    for(let i in token.role){
      if(token.role[i].permissions=='PK_list'){  //分配列表
          this.role.list_str=token.role[i].permissions_id_string;
        }
    }
    this.getlist();
  },
  methods: {
    getlist(re_page){
        if(re_page){
            this.page=1
        }
        pkList({
            permissions_id_string:this.role.list_str,
            type:this.isPersonal,
            status:this.status,
            page:this.page,
            num:6,
        }).then(res=>{
            // console.log(res);
            this.list=res.data.data.data;
            this.total=res.data.data.total;
        })
    },
    handleCurrentChange(e){
        this.page = e;
        this.getlist();
    },
    check_status(id) {
      this.status = id;
    },
  },
  watch:{
      status(){
        this.getlist(1);
      },
      isPersonal(){
        this.getlist(1);
      }
  }
};
</script>

<style lang="scss" scoped>
.PK_list_box {
  padding: 20px;
  padding-right: 0;
  .el-main {
    padding: 20px 0;
    background: #fff;
    .el-pagination {
        margin-top: 10px;
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
      }
    .title_box {
      display: flex;
      justify-content: center;
      .customer_header_check {
        width: 280px;
        height: 40px;
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: center;

        div {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
        }
        .isPersonal {
          background: #3a85fc;
          color: #fff;
        }
      }
    }
    .kind_info {
      width: 100%;
      display: flex;
      border-bottom: 1px solid #e1e1e1;
      margin-top: 20px;
      padding-left: 10px;
      justify-content: center;
      line-height: 35px;
      .left_tit {
        width: 40%;
        display: flex;
        // margin-bottom: 5px;
        justify-content: space-between;
        div {
          width: 84px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 2px solid transparent;
        }
        .active {
          color: #3a85fc;
          border-bottom: 2px solid #3a85fc;
        }
      }
      .right_tit {
        padding-bottom: 1px;
        .right_info {
          width: 119px;
          border-radius: 5px;
          border: 1px solid #3a85fc;
          display: flex;
          color: #3a85fc;
          align-items: center;
          justify-content: center;
          margin-bottom: 4px;
        }
      }
    }
    .list_item {
      position: relative;
      width: 100%;
      // padding: 10px 0;
      padding-top: 0;
      margin-bottom: 10px;
      border: 1px solid #ebebeb;
       .PK_date{
            width: 51px;
            height: 40px;
            top: 0px;
            // left: 9px;
            left: 5%;
            position: absolute;
            box-shadow: 0px 2px 2px rgba(75, 153, 255, 0.16);
            text-align: center;
            img{
              width: 38px;
              height: 30px;
              // height: 100%;
            }
            div:nth-of-type(1){
                font-size: 14px;
                color: #333;
            }
             div:nth-of-type(2){
                font-size: 14px;    
                color: #4B85FF;
                width: 100%;
                overflow:hidden;
                white-space:nowrap;
                text-overflow: ellipsis;

                font-weight: 600;
            }
        } 
    }
    .list_info {
      width: 90%;
      margin: 0 auto;
      .list_item{
          height: 190px;
          width: 48%;
          display: inline-block;
          box-shadow: 0px 3px 6px rgba(226, 226, 226, 0.4);
          margin-top: 30px;
          position: relative;
      }
      .list_item:nth-of-type(2n){
          margin-left: 4%;
      }
    }
    .nolist{
        display: flex;
        flex-direction: column;
        height: 760px;
        justify-content: center;
        align-items: center;
        color: #ccc;
        img{
            width: 300px;
            height: 300px;
        }
    }
  }
 .fight_box {
    .PK_big:nth-of-type(1) {
      margin-top: 10px;
    }
    .hahahaha {
        overflow: auto;
        height: 326px;
      }
    .title_check {
      //   height: ;
      width: 100%;
      background: #fff;
      // position: fixed;

      // top: 0;
      .fight_1 {
        width: 120px;
        height: 30px;
        display: flex;
        margin: 0 auto;
        line-height: 30px;
        border-radius: 2px;
        border: 1px solid #e2e2e2;
        box-sizing: border-box;
        div {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #666;
        }
        .fight {
          width: 70px;
          // height: 30px;
          background: #4b85ff;
          color: #fff;
        }
      }
      
    }
    height: 356px;
    // overflow: auto;
    width: 90%;
    margin: 0 auto;
    
  }

  .PK_list {
  margin-top: 10px;
  /* min-height: 322px; */
  width: 95%;
  margin: 0 auto;
  /* background: #fff; */
  
}
.PK_info {
//   min-height: 166px;
  height: 100px;
  width: 100%;
  padding-bottom: 0px;
  background: #fff;
  margin-bottom: 0px;
  overflow: hidden;
}
.PK_info .title {
  top: 61px;
  width: 95%;
  height: 29px;
  
  line-height: 29px;
  display: flex;
  justify-content: center;
  position: absolute;
  .title_p {
    background: #f3f8ff;
    color: #4b85ff;
    padding: 0px 10px;
    //   padding-bottom: 5px;
    border-radius: 0 0 5px 5px;
    white-space: nowrap;
  }
}

.title_text {
  padding: 0 10px;
  border-radius: 0 0 5px 5px;
  box-sizing: border-box;
  min-width: 225px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;
  color: #999999;
  font-size: 14px;
}
.title_text text {
  color: #333333;
}
.PK_jd {
  /* display: flex; */
  width: 80%;
  height: 25px;
  background: #e3e3e3;
  margin: 0 auto;
  margin-top: 45px;
  position: relative;
}
.PK_jd .left_head {
  position: relative;
  width: 55px;
  left: -24px;
  height: 54px;
  top: -13px;
  z-index: 10;
  border-radius: 50px;
  /* background: #ccc; */
}
.PK_jd .left_head .quan {
  position: absolute;
  top: -5px;
  left: -9px;
  height: 110%;
  z-index: 1;
  width: 110%;
}
.PK_jd .left_head .moren {
  border-radius: 50%;
  position: absolute;
  top: -2px;
  left: -5px;
  width: 98%;
  height: 98%;
}
.PK_jd .jdt {
  position: absolute;
  height: 100%;
  width: 92%;
  top: 0;
  left:4%;
  display: flex;
}
.PK_jd .jdt > div:nth-of-type(1) {
  position: relative;
  font-size: 28px;
  color: #fff;
  height: 100%;
  display: flex;
  width: 0;
  align-items: center;
  /* padding-left: 10px; */
  box-sizing: border-box;
  background: linear-gradient(to right, #87bfff, #278fff);
}
.PK_jd .jdt > div:nth-of-type(2) {
  position: relative;
  align-items: center;
  font-size: 28px;
  color: #fff;
  height: 100%;
  width: 0%;
  background: linear-gradient(to right, #fb892a, #fed911);
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  /* padding-right: 10px; */
  box-sizing: border-box;
}

.PK_jd .qt {
  /* translate:translateX(-141px) ; */
  margin-left: -65px;
  position: absolute;
  /* left: 0; */
  top: -4px;
  left: 50%;
  width: 140px;
  height: 32px;
  z-index: 9;
}
.PK_jd .right_head {
  position: absolute;
  width: 55px;
  right: -35px;
  height: 55px;
  top: -13px;
  border-radius: 50px;
  z-index: 10;
  /* background: #ccc; */
}
.PK_jd .right_head .quan {
  position: absolute;
  top: -5px;
  left: -9px;
  height: 110%;
  z-index: 1;
  width: 110%;
}
.PK_jd .right_head .moren {
  position: absolute;
  border-radius: 50%;
  top: -2px;
  left: -5px;
  width: 98%;
  height: 98%;
}

.PK_jd .right_head .fight_name {
  position: absolute;
  color: #cd5700;
  z-index: 6;
  bottom: 0px;
  left: 5px;
  font-size: 10px;
  
}
.PK_jd .left_head .fight_name {
  width: 100px;
  position: absolute;
  z-index: 6;
  color: #cd5700;
  font-size: 10px;
  bottom: -0px;
  left: 5px;
}
.PK_info .PK_name {
  width: 100%;
  margin: 0 auto;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.PK_info .PK_begin_time {
  width: 235px;
  margin-top: 20px;
  border-radius: 25px;
  height: 50px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  background: #3d94f7;
  color: #fff;
}
.PK_info .PK_money {
  color: #999999;
  text-align: center;
  margin-top: -55px;
}
.PK_info .PK_agree {
  width: 50%;
  height: 65px;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.PK_info .PK_agree .agree {
  width: 150px;
  height: 65px;
  background: linear-gradient(0deg, #007cff 0%, #027cff 100%);
  border-radius: 32.5px;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  box-shadow: 0 0 10px #007cff;
}
.PK_info .PK_agree .noagree {
  width: 150px;
  height: 65px;
  background: linear-gradient(0deg, #fe4d47 0%, #fe4d49 100%);
  border-radius: 32.5px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 10px #fe4d47;
  color: #fff;
  justify-content: center;
}
.PK_info .PK_rwe {
    position: absolute;
    top: 0;
    right: 0;
    width: 176px;
    height: 46px;
    margin: 0 auto;
//   margin-top: 10px;
  background: #3d94f7;
  border-radius: 0 0 0 10px;
}
.PK_info .PK_rwe {
  /* display: flex; */
//   position: relative;
  justify-content: space-around;
  color: #fff;
}
.PK_info .PK_rwe .left {
  float: left;
  width: 50%;
  align-items: center;
  display: flex;
  flex-direction: column;
  /* border-right:1px solid #fff ; */
}
.PK_info .PK_rwe .left > div:nth-of-type(2) {
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}

.PK_info .PK_rwe .right {
  width: 50%;
  float: left;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.PK_info .PK_rwe .right > div:nth-of-type(2) {
  text-align: center;
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.PK_info .PK_rwe .xian {
  position: absolute;
  left: 50%;
  height: 50%;
  width: 2px;
  background: #fff;
  top: 50%;
  margin-left: -1px;
  margin-top: -5%;
}
.PK_info .PK_sb {
  width: 550px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.PK_info .PK_sb > div:nth-of-type(1) {
  padding: 0 10px;
  box-sizing: border-box;
  width: 240px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #407ff3;
  color: #417bf3;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 50px;
}
.PK_info .PK_sb > div:nth-of-type(2) {
  width: 240px;
  height: 50px;
  border-radius: 10px;
  background: #3d91f7;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 50px;
}
.PK_info .PK_sb > image {
  width: 80px;
  height: 80px;
}
.PK_info .PK_yj_money {
  display: flex;
  width: 70%;
  margin: 0 auto;
  margin-top: 71px;
  margin-bottom: -46px;
  color: #fff;
  justify-content: space-between;
  /* margin-bottom: -40px; */
  position: relative;
  top: 20px;
  z-index: 20;
}
}
</style>