<template>
  <div class="promotion">
    <el-main>
      <div class="promotion_top_check">
        <div :class="is_Check == 1 ? 'isCheck' : ''" @click="handleTab(1)">
          个人
        </div>
        <div :class="is_Check == 2 ? 'isCheck' : ''" @click="handleTab(2)">
          部门
        </div>
      </div>
      <div class="Arrival_query">
        <div class="Arrival_query_line">
          <div class="Arrival_query_line_one">
            <div class="Arrival_query_line_one_name">
              {{ is_Check == 1 ? "客户姓名：" : "申请部门：" }}
            </div>
            <el-cascader
              placeholder="请选择部门"
              :options="depOptions"
              @change="changeDep"
              :key="isResouceShow"
              clearable
              :props="{ checkStrictly: true }"
              v-if="is_Check == 2"
            ></el-cascader>
            <el-input
              v-model="company_name"
              placeholder="请输入客户名称"
              v-if="is_Check == 1"
            ></el-input>
          </div>
          <div class="Arrival_query_line_one" v-if="is_Check == 1">
            <div class="Arrival_query_line_one_name">产品类型：</div>
            <el-cascader
              v-model="search_product_id"
              :options="productList"
              placeholder="请选择产品"
              separator=" - "
            >
            </el-cascader>
          </div>
          <div class="Arrival_query_line_one" v-if="is_Check == 2">
            <div class="Arrival_query_line_one_name" v-if="is_Check == 2">
              提交人：
            </div>
            <el-input
              v-if="is_Check == 2"
              v-model="search_name"
              placeholder="请输入姓名"
              style="flex: 1"
            ></el-input>
          </div>
          <div class="Arrival_query_line_one">
            <div class="Arrival_query_line_one_name">促销成本：</div>
            <div class="Arrival_query_line_one_time">
              <el-input
                v-model="search_start_money"
                placeholder="最低"
              ></el-input>
              <i>-</i>
              <el-input
                v-model="search_end_money"
                placeholder="最高"
              ></el-input>
            </div>
          </div>
          <div class="Arrival_query_line_one">
            <div class="Arrival_query_line_one_name">到账时间：</div>
            <div class="Arrival_query_line_one_time">
              <el-date-picker
                :editable="iseditable"
                class="date_time"
                prefix-icon="saa"
                style="width: 50%; height: 32px"
                v-model="search_start_time"
                type="date"
                :picker-options="pickerOptions"
                @focus="handleFocus(1)"
                @change="handleTime($event, 1)"
                placeholder="开始时间"
              >
              </el-date-picker>
              <i>-</i>
              <el-date-picker
                :editable="iseditable"
                prefix-icon="saa"
                class="date_time"
                v-model="search_end_time"
                style="width: 50%; height: 32px"
                type="date"
                :picker-options="pickerOptions"
                @focus="handleFocus(2)"
                @change="handleTime($event, 2)"
                placeholder="结束时间"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="Arrival_query_line">
          <div class="Arrival_query_line_one">
            <div class="Arrival_query_line_one_name" v-if="is_Check == 2">
              客户姓名：
            </div>
            <el-input
              v-if="is_Check == 2"
              v-model="company_name"
              placeholder="请输入姓名"
              style="flex: 1"
            ></el-input>
          </div>
          <div class="header_buttom">
            <div class="finance_find" @click="handleFind(tab_active)">查询</div>
            <div class="finance_reset" @click="handleReset">重置</div>
          </div>
        </div>
      </div>
      <div class="Arrival_table_tab">
        <div class="Arrival_table_tab_box">
          <div
            @click="handletab_active(-1)"
            :class="tab_active == -1 ? 'tab_active' : ''"
          >
            全部
          </div>
          <div
            @click="handletab_active(1)"
            :class="tab_active == 1 ? 'tab_active' : ''"
          >
            待审核
          </div>
          <div
            @click="handletab_active(3)"
            :class="tab_active == 3 ? 'tab_active' : ''"
          >
            通过
          </div>
          <div
            @click="handletab_active(2)"
            :class="tab_active == 2 ? 'tab_active' : ''"
          >
            已使用
          </div>
          <div
            @click="handletab_active(4)"
            :class="tab_active == 4 ? 'tab_active' : ''"
          >
            驳回
          </div>
        </div>
      </div>
      <div class="promotion_table">
        <el-table
          :data="tableData"
          border
          :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
          style="width: 100%"
        >
          <el-table-column label="公司名称" :key="1">
            <template scope="scope">
              <div v-if="scope.row.customer">
                {{ scope.row.customer ? scope.row.customer.name : "" }}
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="客户姓名" :key="4">
            <template scope="scope">
              {{
                scope.row.my_customer_contacts
                  ? scope.row.my_customer_contacts.contacts
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column label="产品名">
            <template scope="scope">
              {{ scope.row.product_name?scope.row.product_name.product_name:''}}
            </template>
          </el-table-column>
          <!-- <div v-if="is_Check==2"  :key="1"> -->
          <el-table-column label="申请人" v-if="is_Check == 2" :key="3">
            <template scope="scope">
              {{ scope.row.user.user_auth&&scope.row.user ? scope.row.user.name : "" }}
            </template>
          </el-table-column>
          <el-table-column label="申请部门" v-if="is_Check == 2" :key="2">
            <template scope="scope">
              {{
              scope.row.user&&scope.row.user.user_auth
                  ? scope.row.user.user_auth.company.name
                  : ""
              }}
            </template>
          </el-table-column>
          <!-- </div> -->
          <el-table-column label="促销类型" :key="5">
            <template scope="scope">
              {{
                scope.row.type == 1
                  ? "实物"
                  : scope.row.type == 2
                  ? "返现"
                  : scope.row.type == 3
                  ? "打折"
                  : scope.row.type == 4
                  ? "赠送服务时长"
                  : "赠送其它服务"
              }}
            </template>
          </el-table-column>
          <el-table-column label="促销成本（元）" :key="6">
            <template scope="scope">
              {{ scope.row.money }}
            </template>
          </el-table-column>
          <el-table-column label="申请说明" :key="7">
            <template scope="scope">
              <div class="apply_explain">
                <el-popover
                  placement="bottom"
                  trigger="hover"
                  width="400"
                  :content="scope.row.apply_explain"
                >
                  <div slot="reference">
                    {{ scope.row.apply_explain }}
                  </div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="提交时间" :key="8">
            <template scope="scope">
              {{ scope.row.created_at }}
            </template>
          </el-table-column>
          <el-table-column label="状态" :key="9">
            <template scope="scope">
              <i
                :class="
                  scope.row.status == 1
                    ? 'wait_status'
                    : scope.row.status == 3 || scope.row.status == 2
                    ? 'pass_status'
                    : scope.row.status == 4
                    ? 'reject_status'
                    : ''
                "
              ></i>
              {{
                scope.row.status == 1
                  ? "待审核"
                  : scope.row.status == 2
                  ? "通过"
                  : scope.row.status == 3
                  ? "通过"
                  : scope.row.status == 4
                  ? "驳回"
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column label="使用状态" :key="10">
            <template scope="scope">
              <i
                :class="
                  scope.row.status == 2
                    ? 'pass_status'
                    : scope.row.status == 3
                    ? 'no_used'
                    : ''
                "
              ></i>
              {{
                scope.row.status == 1
                  ? "--"
                  : scope.row.status == 2
                  ? "已使用"
                  : scope.row.status == 3
                  ? "未使用"
                  : scope.row.status == 4
                  ? "--"
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column label="操作" v-if="is_Check == 1" :key="11">
            <template scope="scope">
              <div style="display: flex" class="scope_cz">
                <div
                  class="pointer"
                  @click="edit(scope.row)"
                  v-if="scope.row.status == 4"
                  style="color: #3a85fc"
                >
                  编辑
                </div>
                <div v-else>--</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="10"
            el-pagination
            layout="total,prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="edit_box" v-if="is_edit">
        <div class="Promotion_edit">
          <div class="Promotion_edit_head">
            编辑
            <i class="pointer el-icon-close" @click="is_edit = false"></i>
          </div>
          <div class="Promotion_edit_detail">
            <div class="Promotion_edit_detail_status" style="padding: 0 20px">
              <div style="display: flex; align-items: center">
                <i
                  style="
                    width: 6px;
                    height: 6px;
                    background: #3a85fc;
                    display: block;
                    border-radius: 50%;
                    margin-right: 5px;
                  "
                ></i>
                <span>状态：</span>
                {{ Promotion_detail.status == 4 ? "驳回" : "" }}
              </div>
            </div>
            <!-- <div class="Promotion_edit_detail_status">
              <div
                style="
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
              >
                <span>原因：</span
                >驳回原因暂无
              </div>
            </div> -->
            <div class="Promotion_edit_detail_info">
              <div class="Promotion_edit_detail_info_line">
                <span>公司名称：</span>
                {{
                  Promotion_detail.customer
                    ? Promotion_detail.customer.name
                    : "--"
                }}
              </div>
              <div class="Promotion_edit_detail_info_line">
                <span>客户姓名：</span
                >{{
                  Promotion_detail
                    ? Promotion_detail.my_customer_contacts.contacts
                    : "--"
                }}
              </div>
              <div class="Promotion_edit_detail_info_line">
                <span>电话：</span
                >{{
                  Promotion_detail
                    ? Promotion_detail.my_customer_contacts.phone
                    : "--"
                }}
              </div>
            </div>
            <div class="Promotion_edit_detail_change">
              <div class="Promotion_edit_detail_change_tit">促销类型：</div>
              <el-select v-model="type" placeholder="请选择促销类型">
                <el-option
                  v-for="item in typeoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="Promotion_edit_detail_change" v-if="type == 4">
              <div class="Promotion_edit_detail_change_tit">服务时长：</div>
              <el-date-picker
                v-model="time_section"
                type="daterange"
                prefix-icon="sdal"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
            <div
              class="Promotion_edit_detail_change"
              v-if="type == 5"
              style="height: 100px"
            >
              <div
                class="Promotion_edit_detail_change_tit"
                style="display: flex; align-items: flex-start; height: 100%"
              >
                赠送服务：
              </div>
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 4 }"
                placeholder="请输入内容"
                v-model="service_info"
              >
              </el-input>
            </div>

            <div class="Promotion_edit_detail_change">
              <div class="Promotion_edit_detail_change_tit">促销成本：</div>
              <el-input v-model="money" placeholder="请输入促销成本"></el-input>
            </div>
            <div class="Promotion_edit_detail_change">
              <div class="Promotion_edit_detail_change_tit">政策类型：</div>
              <el-select v-model="cx_zc_typeid" placeholder="请选择政策类型">
                <el-option
                  v-for="item in cx_zc_type"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="Promotion_edit_detail_change" style="height: 100px">
              <div
                class="Promotion_edit_detail_change_tit"
                style="display: flex; align-items: flex-start; height: 100%"
              >
                申请说明：
              </div>
              <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                v-model="apply_explain"
                style="flex: 1"
              >
              </el-input>
            </div>
            <div class="edit_bottom">
              <div @click="is_edit = false">取消</div>
              <div @click="savePromotion">确定</div>
            </div>
          </div>
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
import { searchCompanysList } from "../../api/User/customer";
import { productList } from "../../api/User/finance";
import {
  PromotionList,
  getPromotion,
  savePromotion,
} from "../../api/User/promotion";
export default {
  data() {
    return {
      is_edit: false,
      is_Check: 1,
      company_name: "",
      productList: [],
      search_product_id: [],
      iseditable: false,
      pickerOptions: {},
      search_company_id: "",
      search_start_time: "",
      search_end_time: "",
      search_start_money: "",
      search_end_money: "",
      isResouceShow: 0,
      depOptions: [],
      tab_active: -1,
      search_name: "",
      tableData: [],
      total: 0,
      currentPage: 1, //页码

      Promotion_detail: {}, //促销单详情
      type: "", //促销类型
      typeoptions: [
        { value: 1, label: "实物" },
        { value: 2, label: "返现" },
        { value: 3, label: "打折" },
        { value: 4, label: "赠送服务时长" },
        { value: 5, label: "赠送其它服务" },
      ],
      money: "", //促销成本
      apply_explain: "", //申请说明
      cx_zc_type: [
        { value: 1, label: "政策性促销" },
        { value: 2, label: "非政策性促销" },
      ],
      cx_zc_typeid: "", //政策类型
      time_section: [], //服务区间
      service_info: "", //服务详情
      permissions_id_string: "",
    };
  },
  created() {
    if (localStorage.getItem("is_Check_pro")) {
      this.is_Check = localStorage.getItem("is_Check_pro");
    } else {
      this.is_Check = 1;
    }
    let token = JSON.parse(localStorage.getItem("token"));
    for (let i in token.role_permissions) {
      if (token.role_permissions[i].permissions == "Marketing") {
        for (let v in token.role_permissions[i].data) {
          if (
            token.role_permissions[i].data[v].permissions == "system_Promotion"
          ) {
            this.permissions_id_string =
              token.role_permissions[i].data[v].permissions_id_string;
          }
        }
      }
    }
    this.getList({
      class_type: this.is_Check,
      permissions_id_string: this.permissions_id_string,
    });
    productList().then((res) => {
      //搜索产品列表
      if (res.data.code == 200) {
        let list = res.data.data;
        for (let i = 0; i < list.length; i++) {
          list[i].label = list[i].class_name;
          list[i].value = list[i].id;
          list[i].children = list[i].product_name;
          for (let j = 0; j < list[i].product_name.length; j++) {
            list[i].product_name[j].label =
              list[i].product_name[j].product_name;
            list[i].product_name[j].value = list[i].product_name[j].id;
          }
        }
        this.productList = list;
      }
    });
    if (this.is_Check == 2) {
      searchCompanysList().then((res) => {
        if (res.data.code == 200) {
          function dg(list) {
            list.forEach((item) => {
              item.label = item.name;
              item.value = item.id;
              if (item.data) {
                item.children = item.data;
                dg(item.data);
              }
            });
            return list;
          }
          let list = res.data.data;
          dg(list);
          this.depOptions = list;
        }
      });
    }
  },
  methods: {
    handleFind(i, page) {
      let {
        company_name,
        search_product_id,
        search_start_time,
        search_end_time,
        search_start_money,
        search_end_money,
        search_name,
        search_company_id,
      } = this;
      // if (isNaN(search_end_money * 1) || isNaN(search_start_money * 1)) {
      //   this.$message({
      //     message: "金额输入有误",
      //     type: "error",
      //     center: true,
      //   });
      //   return;
      // }
      // if (search_end_money != "" && search_start_money !== "") {
      //   if (Number(search_end_money) < Number(search_start_money)) {
      //     this.$message({
      //       message: "结束金额不能小于开始金额",
      //       type: "error",
      //       center: true,
      //     });
      //     return;
      //   }
      // }
      let status = "";
      if (i == -1) {
        //不需要状态
        status = "";
      } else {
        status = i;
      }
      let p = "";
      if (page) {
        p = page;
      } else {
        p = 1;
      }
      let product_name_id = "";
      if (search_product_id.length == 0) {
        product_name_id = "";
      } else {
        product_name_id = search_product_id[1];
      }
      let data = {
        contacts: company_name, //客户姓名
        min_price: search_start_money, //最小金额
        max_price: search_end_money, //最大金额
        start_time: search_start_time.split(" ")[0], //开始时间
        end_time: search_end_time.split(" ")[0], //结束时间
        company_id: search_company_id, //部门id
        page: p,
        status, //状态
        user_name: search_name,
        product_name_id, //产品
        permissions_id_string: this.permissions_id_string,
      };
      this.getList(data);
    },
    getList(data) {
      PromotionList(data).then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
        }
      });
    },
    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // let strDate = Y + M + D;
      let strDate = Y + M + D + h + m + "00";
      return strDate;
    },
    handleFocus(i) {
      //时间选择器事件
      this.pickerOptions = {};
      if (i == 1) {
        if (this.search_end_time == "") {
          this.pickerOptions.disabledDate = (time) =>
            time.getTime() > Date.now() - 8.64e6;
        } else {
          let endtime_s = new Date(this.search_end_time);
          this.pickerOptions.disabledDate = (time) =>
            time.getTime() > Date.parse(endtime_s) - 8.64e6;
        }
      }
      if (i == 2) {
        if (this.search_start_time == "") {
          this.pickerOptions.disabledDate = (time) =>
            time.getTime() > Date.now() - 8.64e6;
        } else {
          let starttime_s = new Date(this.search_start_time);
          this.pickerOptions.disabledDate = (time) =>
            time.getTime() < Date.parse(starttime_s) ||
            time.getTime() > Date.now() - 8.64e6;
        }
      }
      if (i == 3) {
        this.pickerOptions.disabledDate = (time) =>
          time.getTime() > Date.now() - 8.64e6;
      }
    },
    handleTime(value, i) {
      //时间
      // i   1  开始时间  2 结束时间
      if (i == 1) {
        this.search_start_time = this.getwaytime(value);
      }
      if (i == 2) {
        this.search_end_time = this.getwaytime(value);
      }
      if (i == 3) {
        //编辑修改时间
        this.order_to_time = this.getwaytime(value);
      }
    },
    handleTab(i) {
      this.is_Check = i;
      this.handleReset();
      localStorage.setItem("is_Check_pro", i);
      if (i == 2) {
        searchCompanysList().then((res) => {
          if (res.data.code == 200) {
            function dg(list) {
              list.forEach((item) => {
                item.label = item.name;
                item.value = item.id;
                if (item.data) {
                  item.children = item.data;
                  dg(item.data);
                }
              });
              return list;
            }
            let list = res.data.data;
            dg(list);
            this.depOptions = list;
          }
        });
      }
      this.handleFind(this.tab_active);
    },
    handletab_active(i) {
      this.tab_active = i;
      this.handleFind(i);
    },
    handleFind(i, page) {
      let {
        company_name,
        search_product_id,
        search_start_time,
        search_end_time,
        search_start_money,
        search_end_money,
        search_name,
        search_company_id,
        is_Check,
      } = this;
      // if (isNaN(search_end_money * 1) || isNaN(search_start_money * 1)) {
      //   this.$message({
      //     message: "金额输入有误",
      //     type: "error",
      //     center: true,
      //   });
      //   return;
      // }
      // if (search_end_money != "" && search_start_money !== "") {
      //   if (Number(search_end_money) < Number(search_start_money)) {
      //     this.$message({
      //       message: "结束金额不能小于开始金额",
      //       type: "error",
      //       center: true,
      //     });
      //     return;
      //   }
      // }
      let status = "";
      if (i == -1) {
        //不需要状态
        status = "";
      } else {
        status = i;
      }
      let p = "";
      if (page) {
        p = page;
      } else {
        p = 1;
      }
      let product_name_id = "";
      if (search_product_id.length == 0) {
        product_name_id = "";
      } else {
        product_name_id = search_product_id[1];
      }
      let data = {
        contacts: company_name, //客户姓名
        min_price: search_start_money, //最小金额
        max_price: search_end_money, //最大金额
        start_time: search_start_time.split(" ")[0], //开始时间
        end_time: search_end_time.split(" ")[0], //结束时间
        company_id: search_company_id, //部门id
        page: p,
        status, //状态
        user_name: search_name,
        product_name_id, //产品
        class_type: is_Check, //
        permissions_id_string: this.permissions_id_string,
      };
      this.getList(data);
    },
    handleReset() {
      ++this.isResouceShow;
      this.search_product_id = [];
      this.search_end_money = "";
      this.search_start_money = "";
      this.search_end_time = "";
      this.search_start_time = "";
      this.search_company_id = "";
      this.search_name = "";
      this.tab_active = -1;
      this.company_name = "";
      this.handleFind(this.tab_active);
    },
    changeDep(value) {
      //部门筛选
      if (value.length > 0) {
        this.search_company_id = value[value.length - 1];
      } else {
        this.search_company_id = "";
      }
    },
    handleCurrentChange(val) {
      this.handleFind(this.tab_active, val);
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    edit(row) {
      //详情
      getPromotion({
        permissions_id_string: this.permissions_id_string,
        id: row.id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.is_edit = true;
          this.Promotion_detail = res.data.data;
          this.cx_zc_typeid = res.data.data.policy_type;
          this.type = res.data.data.type;
          if (res.data.data.end_service_duration) {
            this.time_section[0] = res.data.data.end_service_duration.split(
              " "
            )[0];
            this.time_section[1] = res.data.data.start_service_duration.split(
              " "
            )[0];
          } else {
            this.time_section = [];
          }
          this.service_info = res.data.data.other_service_detail;
          this.money = res.data.data.money;
          this.apply_explain = res.data.data.apply_explain;
        }
      });
    },
    savePromotion() {
      let {
        Promotion_detail,
        type,
        money,
        apply_explain,
        cx_zc_typeid,
        time_section,
        service_info,
      } = this;
      if (isNaN(money)) {
        this.$message({
          message: "请输入正确的金额",
          type: "error",
        });
        return;
      }
      if (isNaN(Number(money))) {
        this.$message.error("请输入正确的促销成本金额");
        return;
      }
      if (type == 4) {
        if (time_section.length == 0) {
          this.$message.error("请选择服务时长区间");
          return;
        }
      }
      let start_service_duration = "";
      let end_service_duration = "";
      let other_service_detail = "";
      if (type == 4) {
        start_service_duration = this.getwaytime(time_section[0]).split(' ')[0];
        end_service_duration = this.getwaytime(time_section[1]).split(' ')[0];
      } else if (type == 5) {
        other_service_detail = service_info;
      } else {
        other_service_detail = "";
        start_service_duration = "";
        end_service_duration = "";
      }

      let data = {
        id: Promotion_detail.id,
        type,
        money,
        apply_explain,
        status: 1,
        policy_type: cx_zc_typeid,
        other_service_detail,
        start_service_duration,
        end_service_duration,
        permissions_id_string: this.permissions_id_string,
      };
      savePromotion(data).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.is_edit = false;
          this.handleFind(this.tab_active, this.currentPage);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.promotion {
  background: #eef5f9;
  width: 100%;
  margin: 20px;
  .el-main {
    background: #fff;
    height: 100%;
    width: 100%;
    .promotion_top_check {
      display: flex;
      align-items: center;
      width: 280px;
      height: 40px;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-bottom: 30px;
      div {
        width: 50%;
        height: 100%;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
      }
      .isCheck {
        background: #3a85fc;
        color: #fff;
      }
    }
    .Arrival_query {
      width: 100%;
      .Arrival_query_line {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 20px;
        .Arrival_query_line_one {
          display: flex;
          width: 20%;
          .Arrival_query_line_one_name {
            white-space: nowrap;
            display: flex;
            align-items: center;
            height: 100%;
            width: 70px;
            justify-content: flex-end;
          }
          .el-cascader {
            line-height: 32px;
            height: 32px;
            flex: 1;
            input {
              height: 32px;
            }
            .el-input__suffix {
              display: flex;
              align-items: center;
            }
          }
          .Arrival_query_line_one_time {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1;
            input {
              height: 32px;
              padding-left: 15px;
            }
            .el-input__suffix {
              display: flex;
              align-items: center;
            }
            .el-input {
              width: 40%;
            }
          }
          .el-input {
            // flex: 1;
            input {
              height: 32px;
            }
          }
        }
        .header_buttom {
          display: flex;
          height: 32px;
          align-items: center;
          //   width: 75%;
          justify-content: flex-end;
          div {
            width: 70px;
            height: 32px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            border-radius: 2px;
            cursor: pointer;
          }
          .finance_find {
            color: #ffffff;
            opacity: 1;
            background: #3a85fc;
          }
          .finance_reset {
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
            color: rgba(0, 0, 0, 0.6);
            margin-left: 20px;
          }
        }
      }
    }
    .Arrival_table_tab {
      display: flex;
      justify-content: flex-end;
      .Arrival_table_tab_box {
        display: flex;
        border: 1px solid #cccccc;
        border-radius: 2px;
        div {
          width: 72px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border-right: 1px solid #ccc;
          color: rgba(0, 0, 0, 0.8);
        }
        div:last-child {
          border: none;
        }
        .tab_active {
          background: #3a85fc;
          color: #fff;
        }
      }
    }
    .promotion_table {
      width: 100%;
      margin-top: 20px;
      .el-table__header-wrapper {
        .cell {
          white-space: nowrap;
        }
      }
      .block {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
      }
      .apply_explain {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        -webkit-box-orient: vertical;
      }
      .wait_status {
        //0  待审核
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #faad14;
        opacity: 1;
        border-radius: 50%;
        margin-right: 5px;
      }
      .pass_status {
        //1 通过
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #1dd0bd;
        border: 1px solid #1dd0bd;
        border-radius: 50%;
        opacity: 1;
        margin-right: 5px;
      }
      .reject_status {
        //2驳回
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #ff5c5c;
        border-radius: 50%;
        opacity: 1;
        margin-right: 5px;
      }
      .no_used {
        //已通过 为使用
        display: inline-block;
        width: 6px;
        height: 6px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        opacity: 1;
        margin-right: 5px;
      }
    }
    .edit_box {
      position: fixed;
      z-index: 100;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      .Promotion_edit {
        width: 600px;
        min-width: 460px;
        min-height: 560px;
        height: 560px;
        background: #ffffff;
        opacity: 1;
        border-radius: 10px;
        margin: auto;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
        display: flex;
        flex-direction: column;
        .Promotion_edit_head {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 60px;
          font-size: 16px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          width: 100%;
          position: relative;
          i {
            position: absolute;
            right: 20px;
            padding: 10px;
          }
        }
        .Promotion_edit_detail {
          padding: 20px;
          padding-top: 0px;
          flex: 1;
          overflow: auto;
          .Promotion_edit_detail_status {
            display: flex;
            padding: 0 30px;
            margin: 10px 0;
            div {
              span {
                color: rgba(0, 0, 0, 0.8);
              }
            }
          }
          .Promotion_edit_detail_info {
            height: 102px;
            background: rgba(0, 0, 0, 0.02);
            opacity: 1;
            margin: 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            div {
              padding: 0 10px;
              color: rgba(0, 0, 0, 0.6);
              span {
                color: rgba(0, 0, 0, 0.8);
              }
            }
          }
          .Promotion_edit_detail_change {
            display: flex;
            padding-left: 30px;
            padding-right: 20px;
            height: 32px;
            align-items: center;
            margin: 20px 0;
            .Promotion_edit_detail_change_tit {
              width: 70px;
            }
            .el-select {
              width: 200px;
              height: 100%;
              .el-input {
                height: 100%;
                input {
                  height: 32px;
                }
              }
              .el-input__suffix {
                height: 100%;
                display: flex;
                align-items: center;
              }
            }
            .el-input {
              width: 350px;
              height: 100%;
              input {
                height: 32px;
              }
            }
            .el-textarea {
              textarea {
                resize: none;
              }
            }
            .el-textarea {
              width: 350px;
            }
            .el-date-editor {
              height: 32px;
              .sdal {
                width: 0px;
              }
              span {
                display: flex;
                align-items: center;
              }
              .el-input__icon {
                display: flex;
                align-items: center;
                margin-left: 20px;
              }
            }
          }
          .edit_bottom {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            height: 40px;
            padding-right: 20px;
            margin-top: 40px;
            div {
              width: 80px;
              height: 100%;
              border-radius: 4px;
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
            div:first-child {
              background: #ffffff;
              border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
              opacity: 1;
              color: rgba(0, 0, 0, 0.5);
            }
            div:last-child {
              background: #3a85fc;
              color: #fff;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}
</style>