<template>
  <div class="history_box2" style="flex: 1; width: 100%">
    <el-main>
      <div class="history_con">
        <div class="serch_big_box">
          <div class="info">
            <span class="demonstration">时间：</span>
            <el-date-picker
              v-model="date"
              type="month"
              :picker-options="pickerOptions"
              @change="ontime"
              placeholder="请选择时间"
            >
            </el-date-picker>
          </div>
          <div class="info">
            <span class="demonstration">节点：</span>
            <el-select
              @change="changranking"
              v-model="ranking_rules"
              placeholder="请选择节点"
            >
              <el-option
                v-for="item in ranking_arr"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="info" style="width: 20%">
            <div class="serch pointer" @click="serch">查询</div>
            <div class="reset pointer" @click="reset">重置</div>
          </div>
        </div>
        <div class="history_table">
          <el-table
            :data="tableData"
            :show-overflow-tooltip="true"
            border
            style="width: 100%"
            max-height="700"
            :cell-style="{ color: '#666', fontSize: '14px' }"
            :header-cell-style="{
              background: '#F5F5F5',
              color: '#595959',
              fontSize: '13px',
            }"
            ref="table"
            @cell-click="tableCellClick"
            :cell-class-name="
              ({ row, column, rowIndex, columnIndex }) => (
                (row.index = rowIndex), (column.index = columnIndex)
              )
            "
          >
            <el-table-column
              type="index"
              label="排序"
              fixed="left"
              width="50"
              key="0"
            >
              <template scope="scope">
                <div class="pointer">
                  {{ scope.row.index + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="zone_name"
              label="赛区"
              fixed="left"
              width="140"
            >
              <template scope="scope">
                <div class="pointer">
                  {{ scope.row.zone_name ? scope.row.zone_name : "--" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              fixed="left"
              label="军团 / 人数 / 领导人 / 事业线"
              width="240"
            >
              <template scope="scope">
                <div class="pointer">
                  {{ scope.row.name }}【{{ scope.row.people_num }}】-{{
                    scope.row.leader ? scope.row.leader : "--"
                  }}
                  <br />
                  {{ scope.row.business_line }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="date"
              fixed="left"
              label="大区 / 分公司 / 事业部"
              width="240"
            >
              <template scope="scope">
                <div class="pointer">
                  {{ scope.row.company_info["1"].name }}-{{
                    scope.row.company_info["2"].name
                  }}
                  <br />
                  {{ scope.row.company_info["3"].name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="total_integral"
              label="本月总积分"
              sortable
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="this_performance_integral"
              label="本节点业绩积分"
              width="150"
              sortable
            >
              <template slot-scope="scope">
                <span
                  v-if="
                    scope.row.index === tableCellClickRowIndex &&
                    scope.column.index === tableCellClickColumnIndex
                  "
                >
                  <el-input
                    placeholder="请输入内容…"
                    v-model="scope.row.this_performance_integral"
                    @blur="tableCellInputBlur(scope.row, 1)"
                    size="mini"
                    autofocus
                  />
                </span>
                <span v-else>{{ scope.row.this_performance_integral }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="this_completion_rate_integral"
              label="本节点完成率积分"
              sortable
              width="180"
            >
              <template slot-scope="scope">
                <span
                  v-if="
                    scope.row.index === tableCellClickRowIndex &&
                    scope.column.index === tableCellClickColumnIndex
                  "
                >
                  <el-input
                    placeholder="请输入内容…"
                    v-model="scope.row.this_completion_rate_integral"
                    @blur="tableCellInputBlur(scope.row, 2)"
                    size="mini"
                    autofocus
                  />
                </span>
                <span v-else>{{
                  scope.row.this_completion_rate_integral
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="month_mission"
              label="本月任务总计"
              sortable
              width="150"
            >
            </el-table-column>
            <el-table-column
              prop="node_achievement"
              label="节点业绩"
              sortable
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="node_ompletion_rate"
              label="节点完成率"
              sortable
              width="150"
            >
              <template scope="scope">
                <div class="pointer" style="color: #ff4848">
                  {{ scope.row.node_ompletion_rate }}%
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="cumulative_achievement"
              label="累计业绩"
              sortable
              width="130"
            >
              <template scope="scope">
                <div class="pointer" style="color: #ff4848">
                  {{ scope.row.cumulative_achievement }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="cumulative_completion_rate"
              label="节点累计完成率"
              sortable
              width="150"
            >
              <template scope="scope">
                <div class="pointer" style="color: #42cb94">
                  {{ scope.row.cumulative_completion_rate }}%
                </div>
              </template>
            </el-table-column>
            <el-table-column label="赛区设置" width="80">
              <template scope="scope">
                <div
                  class="pointer"
                  @click="set(scope.row)"
                  style="color: #3a85fc"
                >
                  设置
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--设置弹框-->
        <div class="edit_box" v-if="is_edit">
          <div class="Promotion_edit">
            <div class="Promotion_edit_head">
              设置赛区
              <i class="pointer el-icon-close" @click="is_edit = false"></i>
            </div>
            <div class="Promotion_edit_detail">
              <div class="info">
                <span class="demonstration" style="width: 60px">赛区：</span>
                <el-select
                  @change="changarea"
                  v-model="area_id"
                  placeholder="请选择赛区"
                >
                  <el-option
                    v-for="item in area"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="edit_bottom">
                <div @click="is_edit = false">取消</div>
                <div @click="savePromotion">确定</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
import {
  getZone,
  getCampaignHistoryNode,
  setZone,
  campaignHistory,
  setIntegral,
} from "../../api/User/PK";
export default {
  data() {
    return {
      date: "", //日期
      year: "",
      month: "",
      ranking_rules: 0,
      currentPage: 1, //页码
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      total: 0,
      ranking_arr: [], //排名规则
      tableData: [],
      is_edit: false,
      set_row: {},
      area: [],
      area_id: "",
      ranking_id: "",
      tableCellClickRowIndex: null, // 点击的单元格行索引
      tableCellClickColumnIndex: null, // 点击的单元格列索引
      permissions_id_string: "",
    };
  },
  created() {
    //初始化时间
    this.doHandleDate();
    //获取赛区
    getZone({
      status: 1,
      permissions_id_string: this.permissions_id_string,
    }).then((res) => {
      if (res.data.code == 200) {
        this.area = res.data.data;
      }
    });
    this.getTable();

    let token = JSON.parse(localStorage.getItem("token"));
    this.position = token.position_type;
    for (let i in token.role_permissions) {
      if (token.role_permissions[i].permissions == "Marketing") {
        for (let v in token.role_permissions[i].data) {
          if (
            token.role_permissions[i].data[v].permissions == "Historical_data2"
          ) {
            this.permissions_id_string =
              token.role_permissions[i].data[v].permissions_id_string;
          }
        }
      }
    }
  },
  methods: {
    // sortByDate(obj1, obj2, i) {
    //   //排序
    //   var val1 = "";
    //   var val2 = "";
    //   if (i == 1) {
    //     //本月总积分
    //     if (obj1.performance_integral == "--") {
    //       obj1.performance_integral = 0;
    //     }
    //     if (obj2.performance_integral == "--") {
    //       obj2.performance_integral = 0;
    //     }
    //     val1 = obj1.performance_integral * 1;
    //     val2 = obj2.performance_integral * 1;
    //   }
    //   if (i == 2) {
    //     //本月节点业绩积分
    //     if (obj1.completion_rate_integral == "--") {
    //       obj1.completion_rate_integral = 0;
    //     }
    //     if (obj2.completion_rate_integral == "--") {
    //       obj2.completion_rate_integral = 0;
    //     }
    //     val1 = obj1.completion_rate_integral * 1;
    //     val2 = obj2.completion_rate_integral * 1;
    //   }
    //   if (i == 3) {
    //     //本节点完成率积分
    //     val1 = obj1.month_mission * 1;
    //     val2 = obj2.month_mission * 1;
    //   }
    //   if (i == 4) {
    //     //本月任务总计
    //     val1 = obj1.node_achievement * 1;
    //     val2 = obj2.node_achievement * 1;
    //   }
    //   if (i == 5) {
    //     //节点业绩
    //     val1 = obj1.node_achievement_rate * 1;
    //     val2 = obj2.node_achievement_rate * 1;
    //   }
    //   if (i == 6) {
    //     //节点完成率
    //     val1 = obj1.node_achievement_rate * 1;
    //     val2 = obj2.node_achievement_rate * 1;
    //   }
    //   if (i == 7) {
    //     //累计业绩
    //     val1 = obj1.node_achievement_rate * 1;
    //     val2 = obj2.node_achievement_rate * 1;
    //   }
    //   if (i == 8) {
    //     //节点累计完成率
    //     val1 = obj1.node_achievement_rate * 1;
    //     val2 = obj2.node_achievement_rate * 1;
    //   }
    //   return val1 - val2;
    // },
    //重置
    reset() {
      this.getTable();
    },
    //查询
    serch() {
      campaignHistory({
        year: this.date.split("-")[0],
        month: this.date.split("-")[1],
        task_make_note_id: this.ranking_rules,
        permissions_id_string: this.permissions_id_string,
      }).then((res) => {
        this.tableData = res.data.data;
      });
    },
    changranking(e) {
      this.ranking_rules = e;
    },
    changarea(e) {
      this.area_id = e;
    },
    //设置
    set(row) {
      this.is_edit = true;
      this.set_row = row;
      this.area_id = row.zone_id == 0 ? "" : row.zone_id;
    },
    //当前时间
    doHandleDate() {
      var myDate = new Date();
      var tYear = myDate.getFullYear();
      var tMonth = myDate.getMonth();
      var m = tMonth + 1;
      if (m.toString().length == 1) {
        m = "0" + m;
      }
      this.year = tYear;
      this.month = m;
      this.date = tYear + "-" + m;
    },
    //时间戳转时间
    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let strDate = Y + M;
      return strDate;
    },
    //切换时间
    ontime(e) {
      this.date = this.getwaytime(e);
      getCampaignHistoryNode({
        year: this.date.split("-")[0],
        month: this.date.split("-")[1],
        permissions_id_string: this.permissions_id_string,
      }).then((res) => {
        this.ranking_arr = res.data.data;
        if (this.ranking_arr.length > 0) {
          this.ranking_rules = this.ranking_arr[0].id;
        } else {
          this.ranking_rules = "";
        }
      });
    },
    //赛区设置
    savePromotion() {
      let data = {
        zone_id: this.area_id,
        company_id: this.set_row.id,
        permissions_id_string: this.permissions_id_string,
      };
      setZone(data).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
            center: true,
          });
        }
        this.$nextTick(() => {
          this.is_edit = false;
          this.serch();
        });
      });
    },
    // 点击表格可以编辑
    tableCellClick(row, column, cell, event) {
      this.tableCellClickRowIndex = row.index;
      this.tableCellClickColumnIndex = column.index;
    },
    // 单元格失去焦点
    tableCellInputBlur(row, index) {
      this.tableCellClickRowIndex = null;
      this.tableCellClickColumnIndex = null;
      if (isNaN(row.this_performance_integral)) {
        this.$message({
          message: "不能为空",
          type: "error",
          center: true,
        });
        return;
      }
      if (index == 1) {
        setIntegral({
          task_make_note_id: this.ranking_rules,
          company_id: row.id,
          performance_integral: row.this_performance_integral,
          permissions_id_string: this.permissions_id_string,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
              center: true,
            });
            this.serch();
          }
        });
      } else {
        setIntegral({
          task_make_note_id: this.ranking_rules,
          permissions_id_string: this.permissions_id_string,
          company_id: row.id,
          completion_rate_integral: row.this_completion_rate_integral,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
              center: true,
            });
            this.serch();
          }
        });
      }
    },
    //初始化
    getTable() {
      this.date = this.year + "-" + this.month;
      getCampaignHistoryNode({
        year: this.year,
        month: this.month,
        permissions_id_string: this.permissions_id_string,
      }).then((res) => {
        this.ranking_arr = res.data.data;
        if (this.ranking_arr.length > 0) {
          this.ranking_id = this.ranking_arr[this.ranking_arr.length - 1].id;
          this.ranking_rules = this.ranking_id;
        } else {
          this.ranking_rules = "";
        }
        this.$nextTick(() => {
          //获取战役历史
          campaignHistory({
            year: this.year,
            month: this.month,
            permissions_id_string: this.permissions_id_string,
            task_make_note_id: this.ranking_rules,
          }).then((res) => {
            this.tableData = res.data.data;
          });
        });
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.history_box2 {
  box-sizing: border-box;
  background: #eef5f9;
  .el-main {
    width: 100%;
    height: 100%;
    display: flex;
    .history_con {
      background: #fff;
      width: 100%;
      flex: 1;
      padding: 30px;
      .serch_big_box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .info {
          display: flex;
          align-items: center;
          margin-right: 70px;
          width: 313px;
          .demonstration {
            min-width: 42px;
            font-size: 14px;
            white-space: nowrap;
            color: #595959;
          }
          .el-cascader {
            height: 32px;
            line-height: 32px;
            flex: 1;
            .el-input {
              height: 32px;
              line-height: 32px;
              input {
                height: 32px !important;
              }
            }
            .el-cascader__tags {
              flex-wrap: nowrap;
            }
          }
          .el-input__inner {
            height: 36px;
            line-height: 36px;
            border-radius: 0;
            width: 313px !important;
          }
          .el-input__icon {
            line-height: 36px;
          }
          .el-tag {
            background: transparent !important;
          }
          .serch {
            width: 73px;
            height: 36px;
            background: #3a85fc;
            opacity: 1;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 14px;
          }
          .reset {
            width: 73px;
            height: 36px;
            background: #ffffff;
            border: 1px solid #efefef;
            opacity: 1;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            margin-left: 10px;
            color: #595959;
          }
        }
        .info:nth-child(2) {
          margin-right: 20px;
        }
      }
      .history_table {
        margin-top: 20px;
      }
      .edit_box {
        position: fixed;
        z-index: 100;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        .Promotion_edit {
          width: 460px;
          height: 243px;
          background: #ffffff;
          border-radius: 10px;
          margin: auto;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 99;
          display: flex;
          flex-direction: column;
          .Promotion_edit_head {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            font-size: 16px;
            border-bottom: 1px solid #efefef;
            width: 100%;
            position: relative;
            i {
              position: absolute;
              right: 20px;
              padding: 10px;
            }
          }
          .Promotion_edit_detail {
            padding: 20px;
            padding-top: 0px;
            flex: 1;
            overflow: auto;
            .info {
              display: flex;
              align-items: center;
              margin: 30px;
            }
            .edit_bottom {
              width: 100%;
              display: flex;
              justify-content: flex-end;
              height: 40px;
              padding-right: 20px;
              margin-top: 40px;
              div {
                width: 80px;
                height: 100%;
                border-radius: 4px;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              }
              div:first-child {
                background: #ffffff;
                border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
                opacity: 1;
                color: rgba(0, 0, 0, 0.5);
              }
              div:last-child {
                background: #3a85fc;
                color: #fff;
                margin-left: 20px;
              }
            }
          }
        }
      }
      .block {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
      }
    }
  }
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 313px;
}
</style>