<template>
    <div class="customer">
        <el-main>
            <div class="customer_header">
                <div class="customer_header_screen" style="margin-top: 0px">
                    <div class="customer_header_screen_box" style="margin: 0">
                        <div class="customer_header_screen_box_tit">公司名称：</div>
                        <div class="screen_input">
                            <input
                                    type="text"
                                    v-model="customer_name"
                                    placeholder="请输入公司名称"
                                    style="padding-left: 15px"
                            />
                        </div>
                    </div>
                    <div class="customer_header_screen_box">
                        <div class="customer_header_screen_box_tit">客户建立时间：</div>
                        <div class="screen_input" style="border: 0">
                            <el-date-picker
                                    v-model="search_time"
                                    prefix-icon="saa"
                                    type="daterange"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                            >
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="customer_header_screen_box customer_header_bottom">
                        <div @click="handleScreen">查询</div>
                        <div @click="handleReset">重置</div>
                    </div>
                </div>

                <div class="customer_all">
                    <div
                            class="customer_add pointer"
                            @click="onHandover"
                    >
                        <i class="el-icon-user"></i>交接
                    </div>
                    <div class="export" style="width:87%">
                        <!--<div class="export_button" @click="exporttable">-->
                            <!--<i class="el-icon-printer" style="margin-right: 5px"></i>-->
                            <!--导出-->
                        <!--</div>-->
                    </div>
                    <!--客户总人数：{{ total }}人-->
                    库容 : {{have}}/{{capacity}}
                </div>
            </div>
            <div style="width: 99.9%">
                <el-table
                        :data="tableData"
                        border
                        :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
                        style="width: 100%"
                        @selection-change="handleSelectionChange"
                        ref="multipleTable"
                >
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column label="公司名称" :key="1">
                        <template scope="scope">
                            <div>
                                <img :src="scope.row.wechat==''||scope.row.wechat==null||scope.row.wechat=='[]'?require('../../assets/img/wx.png'):require('../../assets/img/wx_ing.png')" class="wx"/>
                                <div>{{scope.row.customer_res?scope.row.customer_res.name:'--'}}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="customer_res.contacts"
                            label="客户信息"
                            :key="2"
                    >
                        <template scope="scope">
                            <div>
                                <div>{{scope.row.customer_res?scope.row.customer_res.contacts:'--'}}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="意向产品" :key="3">
                        <template scope="scope">
                            <div>
                                <div v-for="(item,index) in scope.row.my_customer_product" :key="index">{{item.product_name?item.product_name.product_name:'--' }}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="客户建立时间"
                            :key="4"
                    >
                        <template scope="scope">
                            <div>
                                <div>{{scope.row.created_at!=null?scope.row.created_at:'--'}}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template scope="scope">
                            <i></i>
                            <div class="customer_cz pointer" style="display: flex ;color: #3A85FC">
                                <div @click="handleClickcz(scope.row)">查看</div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="block">
                <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="15"
                        el-pagination
                        layout="total,prev, pager, next, jumper"
                        :total="total"
                >
                </el-pagination>
            </div>
        </el-main>
        <div class="login_mc" v-if="isMc"></div>
        <Addcustomer v-if="isaddcustomer" @Close="handleClose"/>

        <!--领取-->
        <el-dialog
                title="交接"
                :visible.sync="centerDialogVisible"
                width="620px"
                center>
            <div class="handover">
                <div>
                    <h3>已选择：</h3>
                    <div class="handover_l">
                        <div class="handover_list">
                            <h2>已选择客户<span>({{this.multipleSelection.length}}个)</span> </h2>
                            <ul>
                                <li v-for="(item,index) in multipleSelection" :key="index">
                                    {{item.customer_res?item.customer_res.name:'--'}}
                                    <img src="../../assets/img/close.png" @click="onDel(index,item)"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <h3>选择交接人：</h3>
                    <div class="handover_r">
                        <div class="search_input">
                            <!--<img src="../../assets/img/search.png">-->
                            <!--<input placeholder="搜索姓名"/>-->
                            <div class="serchbox">
                                <el-autocomplete
                                        v-model="state"
                                        :fetch-suggestions="querySearchAsync"
                                        placeholder="请输入(名字+【部门】)"
                                        @select="handleSelect"
                                ></el-autocomplete>
                            </div>
                        </div>
                        <div class="handover_b">
                            库容 (<span>{{haveer}}</span>/{{capacityer}})
                        </div>
                    </div>
                </div>

            </div>
            <span slot="footer" class="dialog-footer">
    <el-button @click="onCancel">取 消</el-button>
    <el-button type="primary" @click="onSub">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    import { distributionUserList} from '@/api/User/quit';
    import Add_customer from "@/components/Marketing/Add_customer";
    import {
        handoverList,
        userCustomerCapacity,
        handover
    } from "@/api/User/data";

    export default {
        components: {
            Addcustomer: Add_customer,
        },
        data() {
            return {
                target_id:'',//交接人id
                centerDialogVisible:false,//交接弹窗
                role: {
                    is_fp: false,
                    fp_str: '',
                    list_str: ''
                },
                state: '',//搜索分配人
                multipleSelection: [],//选中的交接客户
                customer_name: "", //客户名称
                intention_options: [
                    {value: "1", label: "新客户"},
                    {value: "2", label: "老客户"},
                    {value: "3", label: "意向客户"},
                    // { value: "4", label: "D" },
                ], //意向
                isMc: false, //蒙层
                intention: "", //意向 搜素     id
                isaddcustomer: false, //是否新增客户
                tableData: [],
                currentPage: 1, //页码
                total: 0, //总条数
                search_time: "",
                start_time:'',
                end_time:'',
                per_page:'',//每页条数
                page:1,
                have:'',//已有客户数量
                capacity:'',//最大客户数量
                haveer:0,//他的已有客户数量
                capacityer:0,//他的最大客户数量
                check_table: [],
            };
        },
        created() {
            this.getTable()
        //    扩容
            userCustomerCapacity({
                type:1
            }).then(res=>{
                this.have=res.data.data.have
                this.capacity=res.data.data.capacity
            })
        },
        //全选
        methods: {
            // handleSelectionChange(val) {
            //     // console.log(val);
            //     this.check_table = val;
            // },
            querySearchAsync(str, cb) {
                let data = []
                distributionUserList({
                    permissions_id_string: this.role.list_str,
                    name: str,
                    all:1
                }).then(res => {
                    for (let i in res.data.data) {
                        res.data.data[i].value = res.data.data[i].name + '【' + res.data.data[i].position.position + '】'
                    }
                    // console.log(res);
                    data = res.data.data
                    cb(data)
                })
            },
            handleSelectionChange(val) {
                this.multipleSelection = val
                console.log(val)
            },
            handleClickcz(row) {
                console.log(row);
                this.$router.push({
                    name: "customerdetail",
                    params: {
                        my_customer_id: row.id,
                        type: 1,
                        is_gh:1,
                        customer_id: row.customer_id,
                        // check_self: row.check_self,
                    },
                });
            },
            //添加客户
            handleAdd() {
                this.isaddcustomer = true;
                this.isMc = true;
            },
            //查询
            handleScreen() {
                if (this.search_time=='') {
                    this.start_time=''
                    this.end_time=''
                }
                if (this.search_time != "") {
                    this.start_time = this.getwaytime(this.search_time[0]);
                    this.end_time = this.getwaytime(this.search_time[1]);
                }
                this.getTable()
            },
            handleReset() {
                //重置
                this.customer_name = "";
                this.search_time = "";
                this.currentPage=1;
                this.start_time='';
                this.end_time='';
                this.page=1;
                this.getTable()
            },
            //    领取
            // exporttable() {
            //
            // },
            //切换页面
            handleCurrentChange(val) {
                this.page = val
                this.getTable()
            },
            //关闭弹窗
            handleClose() {
                this.isaddcustomer = false;
                this.isMc = false;
            },
            getTable(){
                handoverList({
                    name:this.customer_name,
                    start_time:this.start_time,
                    end_time:this.end_time,
                    page:this.page
                }).then(res=>{
                    this.tableData=res.data.data.data,
                    this.current_page = res.data.data.current_page,
                    this.per_page = res.data.data.per_page,
                    this.total=res.data.data.total
                })
            },
            //时间转换
            getwaytime(timestamp) {
                var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
                var Y = date.getFullYear() + "-";
                var M =
                    (date.getMonth() + 1 < 10
                        ? "0" + (date.getMonth() + 1)
                        : date.getMonth() + 1) + "-";
                var D =
                    (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
                var h =
                    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
                var m =
                    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
                    ":";
                var s =
                    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
                let strDate = Y + M + D;
                // let strDate = Y + M + D + h + m + '00';
                return strDate;
            },
            //删除
            onDel(index,rows){
                if(this.multipleSelection.length<=1){
                    this.$message.error('请选择一个或以上客户后交接噢~')
                    return
                }
                this.multipleSelection.splice(index,1);

                this.$refs.multipleTable.toggleRowSelection(rows);
            },
            //分配人选择
            handleSelect(item) {
                this.check_item = item;
                console.log(item)
                this.target_id=item.id
                //    扩容
                userCustomerCapacity({
                    type:1,
                    target_id:item.id
                }).then(res=>{
                    this.haveer=res.data.data.have
                    this.capacityer=res.data.data.capacity
                });
                console.log(this.haveer)
                if((Number(this.multipleSelection)+Number(this.haveer))>this.capacityer){
                    this.$message.error("已超过最大库容！");
                }
            },
            //取消
            onCancel(){
                this.centerDialogVisible=false
                this.state=''
            },
            //提交
            onSub() {
                   var customer_ids= this.multipleSelection.map(item=>{
                    return item.id
                });
                this.$confirm("此操作将勾选的" + this.multipleSelection.length + "个客户分配给" + this.check_item.name + "是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    handover({
                        customer_ids:customer_ids.join(','),
                        handover_user_id:this.target_id
                    }).then(res => {
                        if (res.data.code == 200) {
                            this.$message({
                                type: "success",
                                message: "交接成功",
                            });
                            this.centerDialogVisible=false
                            this.state=''
                            this.getTable()
                        }
                    }).catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消分配",
                        });
                        this.centerDialogVisible=false
                        this.state=''
                    });
                })
            },
            //交接弹窗
            onHandover(){
                if(this.multipleSelection.length<1){
                    this.$message.error('请选择一个或以上客户后交接噢~')
                    return
                }
                this.centerDialogVisible=true
            }
        }
    };
</script>

<style lang="scss">
    .el-cascader {
        width: 100%;
    }

    .el-radio-group, .el-checkbox-group {
        width: 70%;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
    }

    .flow_kind .form_input {
        -webkit-appearance: none;
        background-color: #FFF;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
        width: 80%;
    }

    .flow_kind .form_input::placeholder {
        color: rgba(0, 0, 0, 0.3);
        font-size: 14px;
    }
    .handover{
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3{
            font-size:14px;
            color: #595959;
            font-weight: bold;
            margin-top:10px;
        }
        .handover_l ,.handover_r{
            width: 276px;
            height: 442px;
            padding: 10px;
            background: #FAFAFA;
            border: 1px solid #EFEFEF;
            border-radius: 4px;
            margin-top: 10px;
            overflow-y: scroll;
        }
        .handover_list{
            h2{
                font-size: 14px;
                color: #595959;
                span{
                    color: #1182FC;
                    margin-left: 10px;
                }
            }
            ul{
                margin-top: 20px;
                li{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 14px;
                    color: #707070;
                    margin-top: 10px;
                    padding: 10px 0;
                    border-bottom: 1px solid #EFEFEF;
                    img{
                        width: 14px;
                        height: 14px;
                        cursor: pointer;
                    }
                }
            }

        }
        .search_input{
            /*width: 250px;*/
            /*height: 36px;*/
            /*background: #FFFFFF;*/
            /*border: 1px solid #DDDDDD;*/
            /*border-radius: 2px;*/
            /*display: flex;*/
            /*justify-content: center;*/
            /*align-items: center;*/
            .el-autocomplete{
                width: 100%;
            }
            img{
                width: 16px;
                height: 16px;
                margin-right: 10px;
            }
            input::placeholder{
                color: #CCCCCC;
                font-size: 14px;
            }
        }
        .handover_r{
            position: relative;
            ul{
                margin-top: 20px;
                li{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 14px;
                    color: #707070;
                    margin-top: 10px;
                    padding: 10px 0;
                    border-bottom: 1px solid #EFEFEF;
                    img{
                        width: 14px;
                        height: 14px;
                        cursor: pointer;
                    }
                }
            }
        }
        .handover_b{
            position: absolute;
            right: 10px;
            bottom: 20px;
            font-size: 14px;
            color: #595959;
            span{
                color: #3A85FC;
            }
        }
    }
    .el-table_1_column_2  {
        position: relative;
    }
    .wx{
        position: absolute;
        right: 0;
        bottom: 0;
    }
</style>