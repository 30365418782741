<template>
  <div class="customer_achi">
    <el-main>
      <div class="customer_achi_head" v-if="customerdetail">
        <div class="customer_achi_head_name">
          {{ customerdetail.customer_res?customerdetail.customer_res.name:'--' }}
        </div>
        <div class="customer_achi_head_info">
          <div class="">
            客户姓名：{{ customerdetail.customer_res?customerdetail.customer_res.contacts:'--' }}
          </div>
          <div class="">手机号: {{ customerdetail.customer_res?customerdetail.customer_res.phone:'--' }}</div>
        </div>
        <div class="customer_achi_head_back pointer" @click="handleBack">
          返回上一级
        </div>
      </div>
      <div class="customer_achi_table">
        <div class="customer_achi_table_all">业绩总数：{{ total }}条</div>
        <el-table
          :data="performance"
          border
          :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
          style="width: 100%"
        >
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column label="订单号">
            <template scope="scope">
              {{ scope.row.order_number }}
            </template>
          </el-table-column>
          <el-table-column label="产品类型">
            <template scope="scope">
              {{ scope.row.product_name }}
            </template>
          </el-table-column>
          <el-table-column label="到账金额（元）">
            <template scope="scope">
              {{ scope.row.to_money }}
            </template>
          </el-table-column>
          <el-table-column label="到账类型">
            <template scope="scope">
              {{
                scope.row.type == 1
                  ? "定金"
                  : scope.row.type == 2
                  ? "尾款"
                  : "全款"
              }}
            </template>
          </el-table-column>
          <el-table-column label="收款方式">
            <template scope="scope">
              {{ scope.row.collection_text }}
            </template>
          </el-table-column>
          <el-table-column label="到账时间">
            <template scope="scope">
              {{ scope.row.to_time }}
            </template>
          </el-table-column>
          <el-table-column label="合同号">
            <template scope="scope">
              {{ scope.row.contract_number }}
            </template>
          </el-table-column>
          <el-table-column label="合同金额(元)">
            <template scope="scope">
              {{ scope.row.contract_money }}
            </template>
          </el-table-column>
          <el-table-column label="审核状态">
            <template scope="scope">
              <div class="row_status">
                <i
                  :class="
                    scope.row.status == 0
                      ? 'shenhe'
                      : scope.row.status == 1
                      ? 'pass'
                      : scope.row.status == 2
                      ? 'reject'
                      : scope.row.status == 3
                      ? 'cxz'
                      : ''
                  "
                ></i>
                {{
                  scope.row.status == 0
                    ? "待审核"
                    : scope.row.status == 1
                    ? "通过"
                    : scope.row.status == 2
                    ? "驳回"
                    : scope.row.status == 3
                    ? "撤销中"
                    : ""
                }}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
                    label="审核状态"
                    >
                       <template scope="scope">
                            {{scope.row.status==0?'----':scope.row.status==1?'----':scope.row.status==2?scope.row.reason:scope.row.status==3?scope.row.reason:''}}
                        </template>
                    </el-table-column> -->
          <el-table-column label="操作">
            <template scope="scope">
              <div class="achi_cz pointer">
                <div @click="handleAchi_detail(scope.row)">详情</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="10"
            el-pagination
            layout="total,prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
    <div class="login_mc" v-if="isMore"></div>

    <div class="achi_detail_box" v-if="isDetail && performanceCenterDetail">
      <div class="achi_detail_box_head">
        业绩
        <i
          class="el-icon-close"
          @click="
            isMore = false;
            isDetail = false;
          "
        ></i>
      </div>
      <div class="achi_detail_box_titshow">
        订单号：{{
          performanceCenterDetail ? performanceCenterDetail.order_number : ""
        }}
      </div>
      <div class="achi_detail_box_titshow" style="display: flex">
        <div style="width: 50%">
          状态：{{
            !performanceCenterDetail
              ? ""
              : performanceCenterDetail.status == 0
              ? "待审核"
              : performanceCenterDetail.status == 1
              ? "通过"
              : performanceCenterDetail.status == 2
              ? "驳回"
              : performanceCenterDetail.status == 3
              ? "撤销中"
              : ""
          }}
        </div>
        <div
          style="
            width: 50%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            overflow: hidden;
            -webkit-box-orient: vertical;
          "
          v-if="
            performanceCenterDetail.status == 2 ||
            performanceCenterDetail.status == 3
          "
        >
          原因：
          <!-- <span> -->
          {{ performanceCenterDetail.reason }}
          <!-- </span> -->
        </div>
      </div>
      <div class="achi_detail_box_head_info">
        <div class="achi_detail_box_head_info_line">
          <div class="achi_detail_box_head_info_line_tit"><i></i>客户信息</div>
          <div>
            <span>公司名称：</span
            >{{
              performanceCenterDetail
                ? performanceCenterDetail.customer_name
                : "--"
            }}
          </div>
          <div>
            <span>联系人：</span
            >{{
              performanceCenterDetail ? performanceCenterDetail.contacts : "--"
            }}
          </div>
          <div>
            <span>电话：</span
            >{{ performanceCenterDetail ? performanceCenterDetail.phone : "--" }}
          </div>
        </div>
        <div class="achi_detail_box_head_info_line">
          <div class="achi_detail_box_head_info_line_tit"><i></i>合同信息</div>
          <div>
            <span>合同号：</span
            >{{
              performanceCenterDetail
                ? performanceCenterDetail.contract_number
                : ""
            }}
          </div>
          <div>
            <span>合同金额：</span>￥{{
              performanceCenterDetail
                ? performanceCenterDetail.contract_money
                : ""
            }}
          </div>
          <div>
            <span>产品：</span
            >{{
              performanceCenterDetail
                ? performanceCenterDetail.product_name
                : ""
            }}
          </div>
        </div>
      </div>
      <div class="achi_detail_box_head_info">
        <div class="achi_detail_box_head_info_line">
          <div class="achi_detail_box_head_info_line_tit"><i></i>到账信息</div>
          <div>
            <span>到账金额：</span>￥{{
              performanceCenterDetail ? performanceCenterDetail.to_money : ""
            }}
          </div>
          <div>
            <span>到账时间 ：</span
            >{{
              performanceCenterDetail ? performanceCenterDetail.to_time : ""
            }}
          </div>
          <div>
            <span>提交人：</span>{{ performanceCenterDetail.user_name }}
          </div>
          <div class="achi_detail_box_head_info_line_img_box">
            <span>协议信息：</span>
            <div class="achi_detail_box_head_info_line_img">
              <img
                :src="item.file_url"
                alt=""
                v-for="(item, index) in performanceCenterDetail.contract_file"
                :key="index"
                v-if="item.file_url"
                @click="take_big(item.file_url)"
              />
              <div v-else>--</div>
            </div>
          </div>
        </div>
        <div class="achi_detail_box_head_info_line">
          <div class="achi_detail_box_head_info_line_tit"></div>
          <div>
            <span>收款方式：</span
            >{{
              performanceCenterDetail
                ? performanceCenterDetail.collection_type
                : ""
            }}
          </div>
          <div>
            <span>到账类型：</span
            >{{
              !performanceCenterDetail
                ? ""
                : performanceCenterDetail.type == 1
                ? "定金"
                : performanceCenterDetail.type == 2
                ? "尾款"
                : performanceCenterDetail.type == 3
                ? "全款"
                : ""
            }}
          </div>
          <div>
            <span>提交人电话：</span>{{ performanceCenterDetail.user_phone }}
          </div>
          <div class="achi_detail_box_head_info_line_img_box">
            <span>收款凭证：</span>
            <div class="achi_detail_box_head_info_line_img">
              <img
                :src="item.file_url"
                alt=""
                v-for="(item, index) in performanceCenterDetail.collection_file"
                :key="index"
                v-if="item.file_url"
                @click="take_big(item.file_url)"
              />
              <div v-else>--</div>
            </div>
          </div>
        </div>
      </div>
      <div class="achi_detail_box_remoke">
        <div>备注：</div>
        <div>
          {{
            performanceCenterDetail ? performanceCenterDetail.remarks : "未备注"
          }}
        </div>
      </div>
      <div class="achi_detail_box_head_info">
        <div class="achi_detail_box_head_info_line">
          <div class="achi_detail_box_head_info_line_tit"><i></i>分单信息</div>
          <div
            v-show="performanceCenterDetail.order_data_branch.length > 0"
            v-for="(item, index) in performanceCenterDetail.order_data_branch"
            :key="index"
          >
            <span>分单人：</span>{{ item.user_name }}
          </div>
          <div v-show="performanceCenterDetail.order_data_branch.length == 0">
            暂无分单信息
          </div>
        </div>
        <div class="achi_detail_box_head_info_line">
          <div class="achi_detail_box_head_info_line_tit"></div>
          <div
            v-show="performanceCenterDetail.order_data_branch.length > 0"
            v-for="(item, index) in performanceCenterDetail.order_data_branch"
            :key="index"
          >
            <span>分单金额：</span>￥{{ item.money }}
          </div>
        </div>
      </div>
    </div>
    <!-- 放大图片 -->
    <div class="acc_img_shadow" v-if="big_imgshow" @click="big_imgshow = false">
      <img :src="imgsrc" alt="" />
    </div>
  </div>
</template>

<script>
import { collectionList } from "../../api/User/finance";
import {
  detail,
  performanceCenter,
  performanceCenterDetails,
} from "../../api/User/customer";
export default {
  data() {
    return {
      isMore: false,
      isDetail: false,
      performance: [],
      currentPage: 1,
      my_customer_id: "", ///
      customerdetail: null,
      customer_contacts: null,
      my_customer_product: null,
      total: 0,
      performanceCenterDetail: null, //业绩详情

      big_imgshow: false,
      imgsrc: "",
    };
  },
  created() {
    let obj = {};
    let my_customer_id = "";
    if (this.$route.params.type) {
      obj = this.$route.params;
      my_customer_id = this.$route.params.my_customer_id;
      this.my_customer_id = this.$route.params.my_customer_id;
      localStorage.setItem("detail_id", JSON.stringify(this.$route.params));
    } else {
      obj = JSON.parse(localStorage.getItem("detail_id"));
      this.my_customer_id = JSON.parse(
        localStorage.getItem("detail_id")
      ).my_customer_id;
      my_customer_id = JSON.parse(localStorage.getItem("detail_id"))
        .my_customer_id;
    }
    let type = obj.type;
    let data = {};
    if (obj.my_customer_service_id) {
      this.my_customer_service_id = obj.my_customer_service_id;
      data = {
        type: obj.type,
        my_customer_id: obj.my_customer_id,
        customer_id: obj.customer_id,
        my_customer_service_id: obj.my_customer_service_id,
      };
    } else {
      data = {
        type: obj.type,
        my_customer_id: obj.my_customer_id,
        customer_id: obj.customer_id,
      };
    }
    detail(data).then((res) => {
      if (res.data.code == 200) {
        if (type == 1) {
          this.customerdetail = res.data.data;
          this.customer_contacts = res.data.data.customer_contacts_list;
          this.my_customer_product = res.data.data.my_customer_product;
        }
        if (type == 2) {
          this.customerdetail = res.data.data[0];
          this.customer_contacts = res.data.data[0].customer_contacts_list;
          this.my_customer_product = res.data.data[0].my_customer_product;
        }
      }
    });
    let arr = [];
    collectionList().then((res) => {
      if (res.data.code == 200) {
        arr = res.data.data;
      }
    });
    performanceCenter({ my_customer_id }).then((res) => {
      if (res.data.code == 200) {
        let list = res.data.data.data;
        list.forEach((item) => {
          arr.forEach((itm) => {
            if (item.collection_id == itm.id) {
              item.collection_text = itm.name;
            }
          });
        });
        this.performance = list;
        this.total = res.data.data.total;
      }
    });
  },
  methods: {
    handleBack() {
      history.go(-1);
    },
    handleAchi_detail(row) {
      //查看详情
      this.isMore = true;
      this.isDetail = true;
      performanceCenterDetails({ order_data_id: row.order_data_id }).then(
        (res) => {
          if (res.data.code == 200) {
            this.performanceCenterDetail = res.data.data;
            // console.log(res.data.data);
          }
        }
      );
    },
    handleCurrentChange(val) {
      performanceCenter({ my_customer_id }).then((res) => {
        if (res.data.code == 200) {
          // console.log(res.data.data.data);
          this.performance = res.data.data.data;
          this.total = res.data.data.total;
        }
      });
    },
    take_big(src) {
      this.imgsrc = src;
      this.big_imgshow = true;
    },
  },
};
</script>

<style lang="scss">
.customer_achi {
  margin: 20px;
  box-sizing: border-box;
  width: 100%;
  .login_mc {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 98;
  }
  .el-main {
    .customer_achi_head {
      padding-left: 35px;
      background: #fff;
      width: 100%;
      height: 90px;
      box-sizing: border-box;
      position: relative;
      .customer_achi_head_name {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 21px;
        color: rgba(0, 0, 0, 0.8);
        opacity: 1;
        padding-top: 13px;
        margin-bottom: 20px;
      }
      .customer_achi_head_info {
        display: flex;
        height: 19px;
        align-items: center;
        div {
          padding-right: 50px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.6);
          opacity: 1;
        }
        div:last-child {
          padding-left: 50px;
          border-left: 1px solid rgba(0, 0, 0, 0.2);
        }
      }
      .customer_achi_head_back {
        width: 90px;
        height: 35px;
        background: #3a85fc;
        border-radius: 2px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 20px;
        right: 80px;
      }
    }
    .customer_achi_table {
      background: #fff;
      margin-top: 10px;
      padding: 10px 30px;
      padding-bottom: 30px;
      .customer_achi_table_all {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: rgba(0, 0, 0, 0.8);
        opacity: 1;
        margin-bottom: 18px;
      }
      // .el-table{
      // .el-table__header-wrapper{
      //     table{
      //         width: 100%!important;
      //         table-layout:auto,
      //     }
      // }
      // .el-table__body-wrapper{
      //     table{
      //         width: 100%!important;
      //         table-layout:auto,
      //     }
      // }
      // }
      .row_status {
        display: flex;
        align-items: center;
        i {
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          margin-right: 5px;
        }
        .pass {
          background: #1dd0bd;
        }
        .shenhe {
          background: #faad14;
        }
        .reject {
          background: #ff4d4f;
        }
        .cxz {
          background: #3a85fc;
        }
      }
      .achi_cz {
        display: flex;
        align-items: center;
        div {
          padding: 0 10px;
          color: #3a85fc;
        }
        div:hover {
          color: #3a85fc;
        }
      }
      .block {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
      }
    }
  }
  .achi_detail_box {
    width: 50%;
    height: 80%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: #fff;
    margin: auto;
    border-radius: 10px;
    min-width: 560px;
    overflow-y: auto;
    .achi_detail_box_head {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      i {
        padding: 10px;
        cursor: pointer;
        position: absolute;
        right: 20px;
      }
    }
    .achi_detail_box_titshow {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.6);
      opacity: 1;
      margin: 10px 0;
      padding: 0 50px;
    }
    .achi_detail_box_head_info {
      padding: 0 20px;
      display: flex;
      box-sizing: border-box;
      .achi_detail_box_head_info_line {
        width: 50%;
        div {
          padding-left: 30px;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.8);
          margin: 10px 0;
          span {
            color: rgba(0, 0, 0, 0.6);
          }
        }
        .achi_detail_box_head_info_line_img_box {
          align-items: flex-start;
          .achi_detail_box_head_info_line_img {
            display: flex;
            padding: 0;
            img {
              width: 60px;
              height: 60px;
              border-radius: 4px;
            }
          }
        }
        .achi_detail_box_head_info_line_tit {
          height: 40px;
          background: #fafafa;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.8);
          i {
            display: block;
            width: 2px;
            height: 12px;
            background: #3a85fc;
            opacity: 1;
            margin-right: 10px;
          }
        }
      }
    }
    .achi_detail_box_remoke {
      display: flex;
      padding: 0 50px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.6);
      div:first-child {
        width: 40px;
        white-space: nowrap;
      }
    }
  }
  .acc_img_shadow {
    height: 100%;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2000;
    height: 100%;
    img {
      display: block;
      width: 500px;
      margin: 200px auto;
      max-height: 600px;
    }
  }
}
</style>