<template>
  <div class="Promotion_audit">
    <el-main>
      <div class="Promotion_audit_tab">
        <div
          :class="
            is_promotion_audit == 1
              ? 'Promotion_audit_check pointer'
              : 'Promotion_audit_no_check pointer'
          "
          @click="handleCheck(1)"
        >
          <div>待审核</div>
          <div class="Promotion_audit_border"></div>
        </div>
        <div
          :class="
            is_promotion_audit == 2
              ? 'Promotion_audit_check pointer'
              : 'Promotion_audit_no_check pointer'
          "
          style="margin-left: 110px"
          @click="handleCheck(2)"
        >
          <div>已通过</div>
          <div class="Promotion_audit_border"></div>
        </div>
        <div
          :class="
            is_promotion_audit == 3
              ? 'Promotion_audit_check pointer'
              : 'Promotion_audit_no_check pointer'
          "
          style="margin-left: 110px"
          @click="handleCheck(3)"
        >
          <div>已驳回</div>
          <div class="Promotion_audit_border"></div>
        </div>
      </div>
      <div class="Promotion_audit_table">
        <el-table
          :data="tableData"
          border
          :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
          style="width: 100%"
        >
          <el-table-column label="公司名称" :key="1">
            <template scope="scope">
              {{ scope.row.customer?scope.row.customer.name:'--' }}
            </template>
          </el-table-column>
          <el-table-column label="客户姓名" :key="4">
            <template scope="scope">
              {{
                scope.row.my_customer_contacts
                  ? scope.row.my_customer_contacts.contacts
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column label="产品名">
            <template scope="scope">
              {{
                scope.row.product_name
                  ? scope.row.product_name.product_name
                  : "--"
              }}
            </template>
          </el-table-column>
          <!-- <div v-if="is_promotion_audit==2"  :key="1"> -->
          <el-table-column label="申请人" :key="3">
            <template scope="scope">
              {{ scope.row.user ? scope.row.user.name : "" }}
            </template>
          </el-table-column>
          <el-table-column label="申请部门" :key="2">
            <template scope="scope">
              {{
              scope.row.user&&scope.row.user.user_auth&&scope.row.user.user_auth.company
                  ? scope.row.user.user_auth.company.name
                  : "--"
              }}
            </template>
          </el-table-column>
          <!-- </div> -->
          <el-table-column label="促销类型" :key="5">
            <template scope="scope">
              {{
                scope.row.type == 1
                  ? "实物"
                  : scope.row.type == 2
                  ? "返现"
                  : scope.row.type == 3
                  ? "打折"
                  : scope.row.type == 4
                  ? "赠送服务时长"
                  : "赠送其它服务"
              }}
            </template>
          </el-table-column>
          <el-table-column label="促销成本（元）" :key="6">
            <template scope="scope">
              {{ scope.row.money }}
            </template>
          </el-table-column>
          <el-table-column label="申请说明" :key="7">
            <template scope="scope">
              <div class="apply_explain">
                <el-popover
                  placement="bottom"
                  trigger="hover"
                  width="400"
                  :content="scope.row.apply_explain"
                >
                  <div
                    slot="reference"
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                    "
                  >
                    {{ scope.row.apply_explain }}
                  </div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="提交时间" :key="8">
            <template scope="scope">
              {{ scope.row.created_at }}
            </template>
          </el-table-column>
          <el-table-column label="状态" :key="9">
            <template scope="scope">
              <i
                :class="
                  scope.row.status == 1
                    ? 'wait_status'
                    : scope.row.status == 3 || scope.row.status == 2
                    ? 'pass_status'
                    : scope.row.status == 4
                    ? 'reject_status'
                    : ''
                "
              ></i>
              {{
                scope.row.status == 1
                  ? "待审核"
                  : scope.row.status == 2
                  ? "通过"
                  : scope.row.status == 3
                  ? "通过"
                  : scope.row.status == 4
                  ? "驳回"
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            v-if="is_promotion_audit == 1"
            :key="11"
          >
            <template scope="scope">
              <div style="display: flex" class="scope_cz" v-if="role_what.edit">
                <div class="pointer" @click="edit(scope.row, 3)">通过</div>
                <div
                  class="pointer"
                  @click="edit(scope.row, 4)"
                  style="margin-left: 10px"
                >
                  驳回
                </div>
              </div>
              <div v-else>----</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="10"
            el-pagination
            layout="total,prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
import { promotionAuditList, auditOperation } from "../../api/User/approval";
export default {
  data() {
    return {
      is_promotion_audit: 1,
      total: 0,
      currentPage: 1,
      tableData: [],
      role_what: {
        edit: false,
        edit_str: "",
        list_str: "",
      },
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      let roleList = JSON.parse(localStorage.getItem("token"))
        .role_permissions;
      roleList.forEach((item) => {
        if (item.permissions == "Finance") {
          item.data.forEach((itm) => {
            if (itm.permissions == "Approval_promotion_edit") {
              this.role_what.edit = true;
              this.role_what.edit_str = itm.permissions_id_string;
            }
            if (itm.permissions == "Approval_promotion_list") {
              this.role_what.list_str = itm.permissions_id_string;
            }
          });
        }
      });
    }

    if (localStorage.getItem("is_promotion_audit")) {
      this.is_promotion_audit = localStorage.getItem("is_promotion_audit");
    } else {
      this.is_promotion_audit = 1;
    }
    this.getList({
      status_type: Number(this.is_promotion_audit),
      permissions_id_string: this.role_what.list_str,
    });
  },
  methods: {
    handleCheck(i) {
      this.is_promotion_audit = i;
      localStorage.setItem("is_promotion_audit", i);
      this.getList({
        status_type: Number(this.is_promotion_audit),
        permissions_id_string: this.role_what.list_str,
      });
    },
    getList(data) {
      promotionAuditList(data).then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
        }
      });
    },
    handleCurrentChange(val) {
      this.getList({
        status_type: Number(this.is_promotion_audit),
        page: val,
        permissions_id_string: this.role_what.list_str,
      });
    },
    handleSizeChange(val) {
      // console.log(val);
    },
    edit(row, i) {
      let tit = "";
      if (i == 3) {
        tit = "您确定通过促销审核吗？";
      }
      if (i == 4) {
        tit = "您确定驳回促销审核吗？";
      }
      this.$confirm(tit, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          auditOperation({
            id: row.id,
            status: i,
            permissions_id_string: this.role_what.edit_str,
          }).then((res) => {
            if (res.data.code == 200) {
              this.getList({
                status_type: Number(this.is_promotion_audit),
                permissions_id_string: this.role_what.list_str,
              });
              this.$message({
                type: "success",
                message: res.data.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
    },
  },
};
</script>

<style lang="scss">
.Promotion_audit {
  height: 100%;
  width: 100%;
  padding: 20px;
  background: #eef5f9;
  flex: 1 1 0%;
  .el-main {
    background: #ffffff;
    height: 100%;
    .Promotion_audit_tab {
      display: flex;
      border-bottom: 1px solid rgba(0, 0, 0, 0.10196078431372549);
      .Promotion_audit_check {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #3a85fc;
        opacity: 1;
        display: flex;
        width: 65px;
        flex-direction: column;
        align-items: center;
        height: 35px;
        justify-content: space-between;
        div:last-child {
          width: 65px;
          height: 3px;
          background: #3a85fc;
          opacity: 1;
        }
      }
      .Promotion_audit_no_check {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: rgba(0, 0, 0, 0.48);
        opacity: 1;
        width: 65px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 35px;
        justify-content: space-between;
        div:last-child {
          width: 65px;
          height: 3px;
          background: #fff;
          opacity: 1;
        }
      }
    }
    .Promotion_audit_table {
      margin-top: 40px;
         .el-table th.gutter{
	    display: table-cell!important;
	  }
      .el-table__header-wrapper {
        .cell {
          white-space: nowrap;
        }
      }
      .block {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
      }
      .wait_status {
        //0  待审核
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #faad14;
        opacity: 1;
        border-radius: 50%;
        margin-right: 5px;
      }
      .pass_status {
        //1 通过
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #1dd0bd;
        border: 1px solid #1dd0bd;
        border-radius: 50%;
        opacity: 1;
        margin-right: 5px;
      }
      .reject_status {
        //2驳回
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #ff5c5c;
        border-radius: 50%;
        opacity: 1;
        margin-right: 5px;
      }
      .scope_cz {
        div:hover {
          color: #3a85fc;
        }
      }
    }
  }
}
</style>