<template>
    <div style="flex: 1" class="prodect_control">
        <el-main>
            <div class="editor_con" v-if="command!=''">
                <h2 style="background: #fff;text-align: center;font-size: 28px;padding: 20px">{{command==1?'产品详情':command==2?'案例展示':'入驻流程'}}</h2>
                <div class="customer_achi_head_back pointer" @click="handleBack">
                    返回上一级
                </div>
                <cloud-editor @onEditor="onEditor" :content="content"></cloud-editor>
            </div>

            <div class="prodect_control_all" v-if="command==''">
                <div class="prodect_left">
                    <div class="prodect_title">
                        <div class="add_kind pointer" @click="show_draw(1, 1)" v-if="all_power.is_add_pro">
                            <i class="el-icon-plus"></i>
                            添加分类
                        </div>
                        <div class="prodect_title_sort" v-if="all_power.is_change_pro">操作</div>
                    </div>
                    <div class="prodect_item">
                        <div
                                class="prodect_item_info pointer"
                                :class="[check_act == item.id ? 'check_act' : '']"
                                v-for="(item, index) in left_list"
                                :key="index"
                                @click="check_kind(item.id)"
                        >
                            <div class="type">{{ item.name }}</div>
                            <div class="setting">
                                <div>
                                    <i
                                            class="el-icon-top"
                                            @click.stop="left_up(item.id, index)"
                                            v-if="index !== 0 &&all_power.is_move_pro"
                                    ></i>
                                    <i
                                            v-if="index !== left_list.length - 1 && all_power.is_move_pro"
                                            class="el-icon-bottom"
                                            @click.stop="left_down(item.id, index)"
                                    ></i
                                    >
                                    <i
                                            class="el-icon-setting"
                                            @click="show_draw(1, 0, item)"
                                            v-if="all_power.is_change_pro"
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="prodect_right">
                    <div class="right_title">
                        <div class="title_right" @click="show_draw(2, 1)" v-if="all_power.is_add_pro">
                            <div class="pointer"><i class="el-icon-plus"></i> 添加产品</div>
                        </div>
                    </div>
                    <div class="right_body">
                        <el-table :data="tableData" height="250" border style="width: 100%">
                            <el-table-column
                                    label="序号"
                                    width="70px">
                                <template slot-scope="scope">
                                    {{scope.$index+1}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="name" label="产品名字">
                            </el-table-column>
                            <el-table-column  label="状态" v-if="all_power.is_change_pro">
                                <template slot-scope="scope">
                                    <el-switch
                                            :active-value="1"
                                            :inactive-value="2"
                                            v-model="scope.row.status"
                                            active-color="#13ce66"
                                            inactive-color="#ccc"
                                            @change="change_swith(scope.row)"
                                    >
                                    </el-switch>
                                </template>
                            </el-table-column>
                            <el-table-column prop="created_time" label="创建时间">
                            </el-table-column>
                            <el-table-column prop="address" width="280" label="操作">
                                <template slot-scope="scope">
                                    <div class="prodect_change">
                                        <div class="pointer" @click="show_draw(2, 0, scope.row)"
                                             v-if="all_power.is_change_pro">
                                            编辑
                                        </div>
                                        <div
                                                class="pointer"
                                                @click="right_up(scope.row)"
                                                v-if="scope.row.index != 0&&all_power.is_move_pro"
                                        >
                                            上移
                                        </div>
                                        <div
                                                class="pointer"
                                                @click="right_down(scope.row)"
                                                v-if="scope.row.index != tableData.length - 1&&all_power.is_move_pro"
                                        >
                                            下移
                                        </div>
                                        <div
                                                class="pointer"
                                                @click="right_top(scope.row)"
                                                v-if="scope.row.index != 0&&all_power.is_move_pro"
                                        >
                                            置顶
                                        </div>
                                        <div
                                                class="pointer"
                                                @click="right_bottom(scope.row)"
                                                v-if="scope.row.index != tableData.length - 1&&all_power.is_move_pro"
                                        >
                                            置底
                                        </div>
                                        <div style="color: #eee">|</div>
                                        <el-dropdown @command="handleCommand">
                                            <span class="el-dropdown-link pointer" style="margin-left:3px;">
                                                 <span style="color:#3A85FC">更多</span>
                                            </span>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item v-for="(item,index) in check_list" :key="index"
                                                                  :command="beforCommand(item.id,scope.row)">
                                                    {{item.name}}
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <el-drawer
                        :visible.sync="drawer"
                        :with-header="false"
                        size="40%"
                        class="human_drawer"
                        :wrapperClosable="false"
                >
                    <!-- 新增分类 -->
                    <div class="drawer_all" v-if="drawer_type == 1">
                        <div class="drawer_title">{{ is_add ? "添加" : "编辑" }}分类</div>
                        <div class="compony_detail">分类信息</div>
                        <div class="drawer_compony_info">
                            <span style="color: #ff0000">*</span>
                            <div class="drawer_nice">分类名称</div>
                            <el-input
                                    type="text"
                                    placeholder="请输入分类名称"
                                    v-model="truename"
                                    maxlength="15"
                                    show-word-limit
                            />
                        </div>
                        <div class="drawer_bottom dd">
                            <div
                                    class="pointer"
                                    style="background: #38adff"
                                    @click="check_detail()"
                                    :class="[add_flag ? 'human_nosent' : '']"
                            >
                                确定
                            </div>
                            <div
                                    class="pointer"
                                    v-if="!is_add"
                                    style="
                                          color: #ff4141;
                                          background: #ffffff;
                                          border: 1px solid #ff4141;" @click="del_compony(2)">
                                删除
                            </div>
                            <div
                                    class="pointer"
                                    style="
                  border: 1px solid #c3c6ce;
                  background: #ffffff;
                  color: #414141;
                "
                                    @click="drawer = false"
                            >
                                取消
                            </div>
                        </div>
                    </div>
                    <!-- 新增产品 -->
                    <div class="drawer_all" v-if="drawer_type == 2">
                        <div class="drawer_title">{{ is_add ? "添加" : "编辑" }}产品</div>

                        <!--<div class="drawer_compony_info">-->
                        <!--<span style="color: #ff0000">*</span>-->
                        <!--<div class="drawer_nice">产品分类</div>-->
                        <!--<el-select v-model="cloud_id" placeholder="请选择">-->
                        <!--<el-option-->
                        <!--v-for="item in kind_list"-->
                        <!--:key="item.id"-->
                        <!--:label="item.name"-->
                        <!--:value="item.id">-->
                        <!--</el-option>-->
                        <!--</el-select>-->
                        <!--</div>-->
                        <!--<div class="drawer_compony_info">-->
                        <!--<span style="color: #ff0000">*</span>-->
                        <!--<div class="drawer_nice">产品名称</div>-->
                        <!--<el-input-->
                        <!--type="text"-->
                        <!--placeholder="请输入产品名称"-->
                        <!--v-model="truename"-->
                        <!--maxlength="15"-->
                        <!--show-word-limit-->
                        <!--/>-->
                        <!--</div>-->
                        <!--<div class="drawer_compony_info">-->
                        <!--<span style="color: #ff0000">*</span>-->
                        <!--<div class="drawer_nice">产品名称</div>-->
                        <!--<el-input type="text" placeholder="请输入产品名称" v-model="truename" maxlength="15" show-word-limit-->
                        <!--/>-->
                        <!--</div>-->
                        <div class="drawer_form">
                            <el-form  label-width="100px" class="demo-dynamic"
                                    :model="productForm" :rules="rules" ref="productForm" >
                                <div class="drawer_form_box">
                                    <el-form-item prop="classify" label="产品分类：">
                                        <el-select v-model="productForm.classify" placeholder="请选择" multiple >
                                            <el-option
                                                    v-for="item in kind_list"
                                                    :key="item.id"
                                                    :label="item.name"
                                                    :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item prop="name" label="产品名称：">
                                        <el-input type="text" placeholder="请输入产品名称" v-model="productForm.name" maxlength="15" show-word-limit></el-input>
                                    </el-form-item>
                                    <el-form-item label="产品介绍：" prop="introduce">
                                        <el-input type="textarea" v-model="productForm.introduce" maxlength="50" show-word-limit></el-input>
                                    </el-form-item>
                                    <el-form-item label="产品图片：" prop="img_url" ref="uploadpic1">
                                        <div style="display: flex; justify-content: flex-start;width: 100%">
                                            <div class="flow_img_listinfo" >
                                                <div class="img_item_info" v-if="productForm.img_url!=''">
                                                    <img :src="productForm.img_url" alt="" class="big_img pointer"
                                                         @click="take_big(productForm.img_url)">
                                                    <i class="el-icon-error del_img pointer" style="color:#ccc;"
                                                       @click="del_img(1)"></i>
                                                </div>
                                                <div class="image_info" @click="chooseFile('1')" v-if="productForm.img_url==''">
                                                    <img src="@/assets/img/hentai.png" alt="" style="width:28px;height:25px">
                                                    <span>请上传图片</span>
                                                    <input type="file" style="display:none;" v-if="upload_img01" ref="choosePhoto01" @change="photoChange($event)" id="upload_file1" >
                                                </div>
                                            </div>
                                        </div>
                                    </el-form-item>
                                    <el-form-item  label="价格：" required>
                                        <div @click="addDomain" class="add_p">添加</div>
                                    </el-form-item>
                                    <el-form-item  required v-for="(domain,index) in productForm.price_json">
                                        <el-col class="line" :span="2"
                                                v-if="productForm.price_json.length!=1"
                                                style="display: flex;justify-content: center;align-items: center">
                                            <i class="el-icon-remove-outline" style="font-size: 24px; color: #DBDBDB"
                                               @click.prevent="removeDomain(domain)"></i>
                                        </el-col>
                                        <el-col :span="11">
                                            <el-form-item  label="名称" label-width="80px" :prop="'price_json.'+index + '.name'" :rules="{ required: true, message: '价格名称不能为空', trigger: 'blur' }">
                                                <el-input type="text" placeholder="请输入名称" v-model="domain.name" show-word-limit></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col class="line" :span="1"></el-col>
                                        <el-col :span="11">
                                            <el-form-item  label="价格" label-width label-width="80px" :prop="'price_json.'+index + '.price'" :rules="{ required: true, message: '价格不能为空', trigger: 'blur' }">
                                                <el-input type="number" placeholder="请输入价格" v-model="domain.price" show-word-limit></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-form-item>
                                    <el-form-item  label="加群方式：" prop="group_mode_url" ref="uploadpic2">
                                        <div style="display: flex; justify-content: flex-start;width: 100%">
                                            <div class="flow_img_listinfo" >
                                                <div class="img_item_info" v-if="productForm.group_mode_url!=''">
                                                    <img :src="productForm.group_mode_url" alt="" class="big_img pointer"
                                                         @click="take_big(productForm.group_mode_url)">
                                                    <i class="el-icon-error del_img pointer" style="color:#ccc;"
                                                       @click="del_img(2)"></i>
                                                </div>
                                                <div class="image_info" @click="chooseFile('2')" v-if="productForm.group_mode_url==''">
                                                    <img src="@/assets/img/hentai.png" alt="" style="width:28px;height:25px">
                                                    <span>请上传图片</span>
                                                    <input type="file" style="display:none;" v-if="upload_img02" ref="choosePhoto02" @change="photoChange($event)" id="upload_file2" >
                                                </div>
                                            </div>
                                        </div>
                                    </el-form-item>
                                    <el-form-item  label="联系电话：">
                                        <div @click="addPhone" class="add_p">添加</div>
                                    </el-form-item>
                                    <el-form-item  required v-for="(item,index) in productForm.tel_json">
                                        <!--<el-col class="line" :span="2"-->
                                                <!--v-if="productForm.tel_json.length!=1"-->
                                                <!--style="display: flex;justify-content: center;align-items: center">-->
                                            <!--<i class="el-icon-remove-outline" style="font-size: 24px; color: #DBDBDB"-->
                                               <!--@click.prevent="removePhone(item)"></i>-->
                                        <!--</el-col>-->
                                        <!--<el-col :span="23">-->
                                            <!--<el-form-item  label="电话" label-width="80px" :prop="'tel_json.'+index + '.phone'" :rules="[{ required: true, message: '联系电话不能为空', trigger: 'blur'}, {  max: 11, message: '联系电话输入错误', trigger: 'blur' }]">-->
                                                <!--<el-input type="number" placeholder="请输入联系电话" v-model="item.phone" show-word-limit max="11"></el-input>-->
                                            <!--</el-form-item>-->
                                        <!--</el-col>-->
                                        <el-col class="line" :span="2" v-if="productForm.tel_json.length!=1"
                                                style="display: flex;justify-content: center;align-items: center">
                                            <i class="el-icon-remove-outline" style="font-size: 24px; color: #DBDBDB"
                                               @click.prevent="removePhone(item)"></i>
                                        </el-col>
                                        <el-col :span="11">
                                            <el-form-item  label="联系人" label-width="80px" :prop="'tel_json.'+index + '.name'" :rules="{ required: true, message: '联系人名字不能为空', trigger: 'blur' }">
                                                <el-input type="text" placeholder="请输入名称" v-model="item.name" show-word-limit></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col class="line" :span="1"></el-col>
                                        <el-col :span="11">
                                            <el-form-item  label="电话" label-width="80px" :prop="'tel_json.'+index + '.phone'" :rules="[{ required: true, message: '联系电话不能为空', trigger: 'blur'}, {  max: 11, message: '联系电话输入错误', trigger: 'blur' }]">
                                                <el-input type="number" placeholder="请输入联系电话" v-model="item.phone" show-word-limit max="11"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-form-item>

                                </div>

                                <el-form-item class="drawer_bottom qq" style="background: #fff;justify-content: space-between;width: 80%">
                                    <el-button type="primary"  @click="check_detail('productForm')" :class="[add_flag ? 'human_nosent' : '']">提交</el-button>
                                    <el-button class="pointer" style=" color: #ff4141; background: #ffffff; border: 1px solid #ff4141; " @click="del_compony(1)" v-if="!is_add">删除</el-button>
                                    <el-button class="pointer" style=" border: 1px solid #c3c6ce;  background: #ffffff; color: #414141;  " @click="cancel">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </el-drawer>
                <div class="acc_img_shadow" v-if="big_imgshow" @click="big_imgshow=false">
                    <img :src="imgsrc" alt="">
                </div>
            </div>
        </el-main>
    </div>
</template>
<script>
    import CloudEditor from "../../components/system/CloudEditor";
    import {
        getProductList,
        editProductBy,
        addProductClass,
        addProductName,
        editProductName,
        editProductClass,
    } from "../../api/User/cloud";
    import {
        uploadFile
    } from "../../api/User/index";
    //判断是否在前面加0
    function getNow(s) {
        return s < 10 ? '0' + s: s;
    }
    var myDate = new Date();
    var year=myDate.getFullYear();        //获取当前年
    var month=myDate.getMonth()+1;   //获取当前月
    var date=myDate.getDate();            //获取当前日
    var h=myDate.getHours();              //获取当前小时数(0-23)
    var m=myDate.getMinutes();          //获取当前分钟数(0-59)
    var s=myDate.getSeconds();
    var now=year+'-'+getNow(month)+"-"+getNow(date)+" "+getNow(h)+':'+getNow(m)+":"+getNow(s);
    export default {
        data() {
            return {
                check_act: null,
                serch_value: "",
                drawer: false,
                drawer_type: "",
                left_list: [],
                tableData: [],
                left_total: "",
                right_list: [],
                is_change: false,
                is_add: false,
                add_flag: false,
                truename: "",
                cloud_platform_classify_id: "",
                status: null,
                all_power: {
                    is_add_pro: false,
                    is_change_pro: false,
                    is_move_pro: false,
                },
                role_alldata: {},
                kind_list: [],
                cloud_id: '',
                content:'',
                check_list: [
                    {name: '产品详情', id: 1},
                    {name: '案例展示', id: 2},
                    {name: '入驻流程', id: 3}
                ],
                productForm: {
                    classify: [],//分类id
                    tel_json: [{
                        "phone":"",
                        "name":""
                    }],//电话
                    group_mode_url: "",//加群方式
                    introduce: "",//简介
                    img_url: "",//产品图片
                    price_json: [{
                        "name": "",
                        "price": ""
                    }],//价格
                    name: "",//产品名称
                },
                big_imgshow: false,    //放大图片显示隐藏
                rules: {
                    group_mode_url:[
                        {required: true, message: '上传加群方式图片' ,trigger: "change"}
                    ],
                    p_name:[
                        {required: true, message: '请输入产品价格名称', trigger: 'blur'}
                    ],
                    img_url:[{required: true, message: '上传产品图片',trigger: "change"}],
                    introduce:[
                        {required: true, message: '请输入产品介绍', trigger: 'blur'}
                    ],
                    name:[
                        {required: true, message: '请输入产品名称', trigger: 'blur'}
                    ],
                    classify:[{required: true, message: '请输入产品类型', trigger: "change"},]
                },
                command:'',
                cloud_platform_product_id:'',//产品id
                upload_img01: true,
                upload_img02: true,
                img_list:[]//图片
            };
        },
        components: {
            CloudEditor
        },
        created() {
            let newdata = JSON.parse(localStorage.getItem("token"));
            let role_permissions = newdata.role_permissions;
            let newpower = {};
            for (let i in role_permissions) {
                if (role_permissions[i].permissions == "system") {
                    newpower = role_permissions[i];
                    this.role_alldata = role_permissions[i];
                }
            }
            if (JSON.stringify(newpower) != "{}") {
                if (newpower.data.length > 0) {
                    for (let i in newpower.data) {
                        if (newpower.data[i].permissions == "system_pro_list_add") {
                            this.all_power.is_add_pro = true;
                        }
                        if (newpower.data[i].permissions == "system_pro_list_edit") {
                            this.all_power.is_change_pro = true;
                        }
                        if (newpower.data[i].permissions == "system_pro_list_move") {
                            this.all_power.is_move_pro = true;
                        }
                    }
                }
            }
            let str = "";
            for (let i in this.role_alldata.data) {
                if (this.role_alldata.data[i].permissions == "system_pro_list") {
                    str = this.role_alldata.data[i].permissions_id_string;
                }
            }

            let id = ''
            if (localStorage.getItem('cloud_id')) {
                id = localStorage.getItem('cloud_id')
            }
            getProductList({
                permissions_id_string: str,
            }).then((res) => {
                this.kind_list = res.data.data
                this.left_list = res.data.data;
                this.check_kind(id ? id : res.data.data[0].id);
                console.log(res.data.data[0].id)
            });
        },
        methods: {
            //取消
            cancel(){
                this.drawer = false
                this.productForm={
                    classify: [],//分类id
                        tel_json: [{
                        "phone":""
                    }],//电话
                        group_mode_url: "",//加群方式
                        introduce: "",//简介
                        img_url: "",//产品图片
                        price_json: [{
                        "name": "",
                        "price": ""
                    }],//价格
                        name: "",//产品名称
                }
            },
            // i 是否添加分类  is_add是否添加  item内容
            show_draw(i, is_add, item) {
                this.cloud_platform_classify_id = "";
                this.truename = "";
                if (is_add) {
                    this.is_add = true;
                } else {
                    this.is_add = false;
                }
                //添加
                if (i == 1) {
                    if (item) {
                        this.truename = item.name;
                        this.cloud_platform_classify_id = item.id;
                    }
                } else {
                    //编辑
                    if (item) {
                        this.productForm=item
                        this.productForm.classify=item.classify.indexOf(',')!=-1?item.classify.split(","):this.digitize(parseInt(item.classify));
                        // this.truename = item.product_name;
                        // this.cloud_platform_classify_id = item.id;
                        this.status = item.value == true ? "1" : "2";
                    }
                }
                let str = "";
                for (let i in this.role_alldata.data) {
                    if (this.role_alldata.data[i].permissions == "system_pro_list") {
                        str = this.role_alldata.data[i].permissions_id_string;
                    }
                }
                this.getClassification(str);
                this.drawer = true;
                this.drawer_type = i;
            },

            digitize(n) {
                    var str = n + "";
                    var arr = [];
                    str.split("").forEach(function (item) {
                        arr.push(parseInt(item));
                    })
                    return arr;
                    },
            check_detail(formName) {
                console.log(formName)
                if (this.add_flag) {
                    return;
                }
                this.add_flag = true;
                if (this.is_add) {
                    if (this.drawer_type == 1) {
                        let str = "";
                        for (let i in this.role_alldata.data) {
                            if (this.role_alldata.data[i].permissions == "system_pro_list_add") {
                                str = this.role_alldata.data[i].permissions_id_string;
                            }
                        }
                        if(this.name==''){
                            this.$message({
                                type: "error",
                                message: '分类名称不能为空',
                            });
                            return false
                        }
                        //添加分类
                        addProductClass({
                            permissions_id_string: str,
                            name: this.truename
                        }).then((res) => {
                            this.add_flag = false;
                            if (res.data.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: res.data.message,
                                });
                                this.drawer = false;
                                let str = "";
                                for (let i in this.role_alldata.data) {
                                    if (this.role_alldata.data[i].permissions == "system_pro_list") {
                                        str = this.role_alldata.data[i].permissions_id_string;
                                    }
                                }
                                this. productForm={
                                    classify: [],//分类id
                                        tel_json: [{
                                        "phone":""
                                    }],//电话
                                        group_mode_url: "",//加群方式
                                        introduce: "",//简介
                                        img_url: "",//产品图片
                                        price_json: [{
                                        "name": "",
                                        "price": ""
                                    }],//价格
                                        name: "",//产品名称
                                },
                                this.getClassification(str)
                            }
                        });

                    } else {
                        let str = "";
                        for (let i in this.role_alldata.data) {
                            if (this.role_alldata.data[i].permissions == "system_pro_list_add") {
                                str = this.role_alldata.data[i].permissions_id_string;
                            }
                        }
                        var data ={
                            name:this.productForm.name,
                            classify:this.productForm.classify.join(','),
                            price_json:JSON.stringify(this.productForm.price_json),
                            group_mode_url:this.productForm.group_mode_url,
                            tel_json:JSON.stringify(this.productForm.tel_json),
                            img_url:this.productForm.img_url,
                            introduce:this.productForm.introduce,
                        }
                        // 去掉rules中对图片的验证
                        if(this.productForm.group_mode_url!=''){
                            delete this.rules['group_mode_url']
                        }
                        if(this.productForm.img_url!=''){
                            delete this.rules['img_url']
                        }
                        this.$nextTick(()=>{
                            this.$refs[formName].validate((valid) => {
                                if (valid) {
                                    console.log('ddddddd')
                                    addProductName(data).then((res) => {
                                        console.log(res)
                                        this.add_flag = false;
                                        if (res.data.code == 200) {
                                            this.$message({
                                                type: "success",
                                                message: res.data.message,
                                            });
                                            this.drawer = false;
                                            let str = ''
                                            for (let i in this.role_alldata.data) {
                                                if (this.role_alldata.data[i].permissions == "system_pro_list") {
                                                    str = this.role_alldata.data[i].permissions_id_string;
                                                }
                                            }
                                            this. productForm={
                                                classify: [],//分类id
                                                tel_json: [{
                                                    "phone":""
                                                }],//电话
                                                group_mode_url: "",//加群方式
                                                introduce: "",//简介
                                                img_url: "",//产品图片
                                                price_json: [{
                                                    "name": "",
                                                    "price": ""
                                                }],//价格
                                                name: "",//产品名称
                                            }
                                            this.check_kind(this.check_act);
                                            this.getClassification(str)
                                        }
                                    });
                                } else {
                                    console.log('error submit!!');
                                    this.add_flag = false;
                                    return false;
                                }
                            });
                        });

                    }
                    //修改
                } else {
                    if (this.drawer_type == 1) {
                        let str = '';
                        for (let i in this.role_alldata.data) {
                            if (this.role_alldata.data[i].permissions == "system_pro_list_edit") {
                                str = this.role_alldata.data[i].permissions_id_string;
                            }
                        }
                        // 修改分类
                        editProductClass({
                            permissions_id_string: str,
                            name: this.truename,
                            cloud_platform_classify_id: this.check_act,
                            status: 1,
                        }).then((res) => {
                            this.add_flag = false;
                            if (res.data.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: res.data.message,
                                });
                                this.drawer = false;
                                let str = ''
                                for (let i in this.role_alldata.data) {
                                    if (this.role_alldata.data[i].permissions == "system_pro_list") {
                                        str = this.role_alldata.data[i].permissions_id_string;
                                    }
                                }
                                this.getClassification(str)
                            }
                        });
                    } else {
                        let str = ''
                        for (let i in this.role_alldata.data) {
                            if (this.role_alldata.data[i].permissions == "system_pro_list_edit") {
                                str = this.role_alldata.data[i].permissions_id_string;
                            }
                        }
                        // 去掉rules中对图片的验证
                        if(this.productForm.group_mode_url!=''){
                            delete this.rules['group_mode_url']
                        }
                        if(this.productForm.img_url!=''){
                            delete this.rules['img_url']
                        }
                        this.$nextTick(()=>{
                                this.$refs[formName].validate((valid) => {
                                    if (valid) {
                                        // 修改产品
                                        var data = {
                                            name: this.productForm.name,
                                            classify: this.productForm.classify.join(','),
                                            price_json: JSON.stringify(this.productForm.price_json),
                                            group_mode_url: this.productForm.group_mode_url,
                                            tel_json: JSON.stringify(this.productForm.tel_json),
                                            img_url: this.productForm.img_url,
                                            introduce: this.productForm.introduce,
                                            cloud_platform_product_id: this.productForm.id,
                                            permissions_id_string: str,
                                            status: this.status,
                                        }
                                        editProductName(data).then((res) => {
                                            if (res.data.code == 200) {
                                                this.$message({
                                                    type: "success",
                                                    message: res.data.message,
                                                });
                                                this.productForm = {};
                                                this.drawer = false;
                                                this.add_flag = false;
                                                this.check_kind(this.check_act);
                                            }
                                        });
                                    } else {
                                        console.log('error submit!!');
                                        this.add_flag = false;
                                        return false;
                                    }
                                })
                        })
                    }
                }

            },
            del_compony(e) {
                if (e == 1) {
                    this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        // 删除产品
                        var data ={
                            deleted_at:now,
                            cloud_platform_product_id: this.productForm.id,
                        }
                        editProductName(data).then((res) => {
                            if (res.data.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: res.data.message,
                                });
                                this.drawer = false;
                                this.add_flag = false;
                                this.check_kind(this.check_act);
                            }
                        });
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });
                    });
                } else {
                    this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        // 删除分类
                        editProductClass({
                            cloud_platform_classify_id: this.check_act,
                            deleted_at:now
                        }).then((res) => {
                            this.add_flag = false;
                            if (res.data.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: res.data.message,
                                });
                                this.drawer = false;
                                let str = ''
                                for (let i in this.role_alldata.data) {
                                    if (this.role_alldata.data[i].permissions == "system_pro_list") {
                                        str = this.role_alldata.data[i].permissions_id_string;
                                    }
                                }
                                this.getClassification(str);
                                this.check_kind(this.kind_list[0].id);
                            }
                        });
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });
                    });

                }

            },

            change_swith(item) {
                console.log(item)
                let str = ''
                for (let i in this.role_alldata.data) {
                    if (this.role_alldata.data[i].permissions == "system_pro_list_edit") {
                        str = this.role_alldata.data[i].permissions_id_string;
                    }
                }
                //产品
                editProductName({
                    permissions_id_string: str,
                    cloud_platform_product_id: item.id,
                    status: item.status
                }).then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            type: "success",
                            message: res.data.message,
                        });
                        this.drawer = false;
                        this.add_flag = false;
                        this.getClassification(str)
                    }
                });

            },
            check_kind(id) {  //点击确定种类
                if (this.is_change) {
                    return;
                }
                console.log(id)
                localStorage.setItem('cloud_id', id)
                this.is_change = true;
                this.check_act = id;
                this.cloud_id = id
                let str = ''
                for (let i in this.role_alldata.data) {
                    if (this.role_alldata.data[i].permissions == "system_pro_list") {
                        str = this.role_alldata.data[i].permissions_id_string;
                    }
                }
                this.getClassification(str, id)
            },
            //移动操作
            left_up(id, index) {
                if (this.is_change) {
                    return;
                }
                this.is_change = true;
                var str = '';
                for (var i in this.role_alldata.data) {
                    if (this.role_alldata.data[i].permissions == "system_pro_list_move") {
                        str = this.role_alldata.data[i].permissions_id_string;
                    }
                }

                editProductBy({
                    type:1,
                    permissions_id_string: str,
                    old_by: this.left_list[index].sort,
                    new_by: this.left_list[index - 1].sort,
                    id: this.left_list[index].id,
                }).then((res) => {
                    this.is_change = false;
                    if (res.data.code == 200) {
                        var h = this.left_list.splice(index, 1);
                        this.left_list.splice(index - 1, 0, h[0]);
                        this.$forceUpdate();
                    }
                });
            },
            left_down(id, index) {
                if (this.is_change) {
                    return;
                }
                this.is_change = true;
                let str = ''
                for (let i in this.role_alldata.data) {
                    if (this.role_alldata.data[i].permissions == "system_pro_list_move") {
                        str = this.role_alldata.data[i].permissions_id_string;
                    }
                }

                editProductBy({
                    type:1,
                    permissions_id_string: str,
                    old_by: this.left_list[index].sort,
                    new_by: this.left_list[index + 1].sort,
                    id: this.left_list[index].id,
                }).then((res) => {
                    this.is_change = false;
                    if (res.data.code == 200) {
                        let h = this.left_list.splice(index, 1);
                        this.left_list.splice(index + 1, 0, h[0]);
                        this.$forceUpdate();
                    }
                });
            },

            //right
            right_up(row) {
                if (this.is_change) {
                    return;
                }
                this.is_change = true;
                let {index} = row;
                if (index == 0) {
                    return;
                }
                let str = ''
                for (let i in this.role_alldata.data) {
                    if (this.role_alldata.data[i].permissions == "system_pro_list_move") {
                        str = this.role_alldata.data[i].permissions_id_string;
                    }
                }

                editProductBy({
                    type:2,
                    permissions_id_string: str,
                    old_by: this.tableData[index].sort,
                    new_by: this.tableData[index - 1].sort,
                    id: this.tableData[index].id,
                }).then((res) => {
                    this.is_change = false;
                    if (res.data.code == 200) {
                        this.check_kind(this.check_act);
                    }
                });
            },
            right_down(row) {
                if (this.is_change) {
                    return;
                }

                let {index} = row;
                this.is_change = true;
                let str = ''
                for (let i in this.role_alldata.data) {
                    if (this.role_alldata.data[i].permissions == "system_pro_list_move") {
                        str = this.role_alldata.data[i].permissions_id_string;
                    }
                }

                editProductBy({
                    type:2,
                    permissions_id_string: str,
                    old_by: this.tableData[index].sort,
                    new_by: this.tableData[index + 1].sort,
                    id: this.tableData[index].id,
                }).then((res) => {
                    this.is_change = false;
                    if (res.data.code == 200) {
                        this.check_kind(this.check_act);
                    }
                });
            },
            right_top(row) {
                let {index} = row;
                if (this.is_change) {
                    return;
                }
                this.is_change = true;
                let str = ''
                for (let i in this.role_alldata.data) {
                    if (this.role_alldata.data[i].permissions == "system_pro_list_move") {
                        str = this.role_alldata.data[i].permissions_id_string;
                    }
                }

                editProductBy({
                    type:2,
                    permissions_id_string: str,
                    old_by: this.tableData[index].sort,
                    new_by: this.tableData[0].sort,
                    id: this.tableData[index].id,
                }).then((res) => {
                    this.is_change = false;
                    if (res.data.code == 200) {
                        this.check_kind(this.check_act);
                    }
                });
            },
            right_bottom(row) {
                let {index} = row;

                if (this.is_change) {
                    return;
                }
                this.is_change = true;
                let str = ''
                for (let i in this.role_alldata.data) {
                    if (this.role_alldata.data[i].permissions == "system_pro_list_move") {
                        str = this.role_alldata.data[i].permissions_id_string;
                    }
                }

                editProductBy({
                    type:2,
                    permissions_id_string: str,
                    old_by: this.tableData[index].sort,
                    new_by: this.tableData[this.tableData.length - 1].sort,
                    id: this.tableData[index].id,
                }).then((res) => {
                    this.is_change = false;
                    if (res.data.code == 200) {
                        this.check_kind(this.check_act);
                    }
                });
            },
            recheck() {
                this.serch_value = "";
                this.check_kind(this.check_act);
            },
            //更多
            handleCommand(i) {
                this.command =i.command;
                this.cloud_platform_product_id = i.row.id
                // this.command=command
                if(i.command==1){
                    this.content=i.row.detail
                    console.log(this.content)
                }else if(i.command==2){
                    this.content=i.row.case_display
                }else if(i.command==3){
                    this.content=i.row.settlement_process
                }

            },
            beforCommand(i, row) {
                //下拉菜单传参
                return {
                    command: i,
                    row: row,
                };
            },
            //分类/产品
            getClassification(str, id) {
                getProductList({
                    permissions_id_string: str,
                    cloud_platform_classify_id: id,
                }).then((res) => {
                    if (id) {
                        this.is_change = false;
                        let data = res.data.data;
                        for (let i in data) {
                            if (!data[i].created_at) {
                                data[i].created_time = "--";
                            } else {
                                data[i].created_time = data[i].created_at;
                            }
                            if (!data[i].deleted_at) {
                                data[i].value = true;
                            } else {
                                data[i].value = false;
                            }
                            data[i].index = i * 1;
                        }
                        this.tableData = data;
                    } else {
                        this.left_list = res.data.data;
                    }
                });
            },
            //添加产品
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        alert('submit!');
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            removeDomain(item) {
                console.log('fvfff')
                var index = this.productForm.price_json.indexOf(item)
                if (index !== -1) {
                    this.productForm.price_json.splice(index, 1)
                }
            },
            //添加价格
            addDomain() {
                this.productForm.price_json.push({
                    name: '',
                    price: ''
                });
            },
            //添加电话
            addPhone(){
                this.productForm.tel_json.push({
                    phone:'',
                    name:''
                });
            },
            //删除电话
            removePhone(item) {
                var index = this.productForm.tel_json.indexOf(item)
                if (index !== -1) {
                    this.productForm.tel_json.splice(index, 1)
                }
            },
            //删除图片e
            del_img(e) {
                if(e==1){
                    this.productForm.img_url=''
                    this.$refs["uploadpic1"].validate();
                }else {
                    this.$refs["uploadpic2"].validate();
                    this.productForm.group_mode_url=''
                }
            },
            dataURItoBlob(dataURI) {
                // base64 解码

                let byteString = window.atob(dataURI.split(',')[1]);
                let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
                let T = mimeString.split('/')[1];
                let ab = new ArrayBuffer(byteString.length);
                let ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                return new Blob([ab], {type: mimeString});
            },
            //选择图片
            photoChange(el) {
                var id=el.path[0].id;
                var file = el.target.files[0];
                var type = file.type.split('/')[0];
                if (type === 'image') {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    var that = this;
                    reader.onloadend = function () {
                        var dataURL = reader.result;
                        var blob = that.dataURItoBlob(dataURL);
                        that.upload(blob,id); //执行上传接口
                    };
                } else {
                    this.$message.error('请上传图片哦~~')
                }
            },
            upload(imgUrl,id) {
                let formData = new FormData();
                this.$message({
                    message: '上传中，请稍后'
                });
                formData.append('file', imgUrl);
                uploadFile(formData).then(res => {
                    if (res.data.code == 200) {
                        if(id=='upload_file1'){
                            this.productForm.img_url=res.data.data.file_url;
                            this.$refs["uploadpic1"].clearValidate();
                        }else{
                            this.productForm.group_mode_url=res.data.data.file_url;
                            this.$refs["uploadpic2"].clearValidate();
                        }
                        this.$message({
                            message: '上传成功',
                            type: 'success'
                        });
                    }
                    // console.log(res);
                })
            },
            //图片放大
            take_big(url) {
                this.imgsrc = url;
                this.big_imgshow = true;
            },
            chooseFile(e) {
                if(e==1){
                    this.$refs.choosePhoto01.dispatchEvent(new MouseEvent('click'));
                    this.upload_img01 = false;
                    setTimeout(() => {
                        this.upload_img01 = true;
                    }, 10);
                }else{
                    this.$refs.choosePhoto02.dispatchEvent(new MouseEvent('click'));
                    this.upload_img02 = false;
                    setTimeout(() => {
                        this.upload_img02= true;
                    }, 10);
                }

            },
            handleBack(){
                this.command=''
            },
            //提交编辑
            onEditor(e){
                console.log(e)
                if(this.command==1){
                   var data={
                        cloud_platform_product_id: this.cloud_platform_product_id,
                        detail:e
                    }
                }else if(this.command==2){
                    var data ={
                        case_display:e,
                        cloud_platform_product_id: this.cloud_platform_product_id,
                    }
                }else if(this.command==3){
                    var data ={
                        cloud_platform_product_id: this.cloud_platform_product_id,
                        settlement_process: e,
                    };
                }
                editProductName(data).then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            type: "success",
                            message: res.data.message,
                        });
                        this.command=''
                        this.drawer = false;
                        let str = ''
                        for (let i in this.role_alldata.data) {
                            if (this.role_alldata.data[i].permissions == "system_pro_list") {
                                str = this.role_alldata.data[i].permissions_id_string;
                            }
                        }
                        this.getClassification(str,this.check_act)
                    }
                });
            }
        },
        beforeDestroy() {
            localStorage.removeItem('cloud_id')
        }
    };
</script>

<style lang="scss">
    .prodect_control_all {
        // min-width: 1200px;
        width: 100%;
        // margin: 0 auto;
        // margin-top: -11px;
        border: 1px solid #dedede;
        overflow: hidden;
        height: 800px;
        box-sizing: border-box;
        .prodect_left {
            width: 20%;
            background: #fff;
            float: left;
            border-right: 1px solid #dedede;
            // min-height: 800px;
            height: 800px;
            overflow: auto;
            box-sizing: border-box;
            .prodect_title {
                padding: 20px 10px 0px 10px;
                font-size: 14px;
                min-height: 54px;
                color: #333;
                box-sizing: border-box;
                padding-right: 0;

                // border-bottom: 1px solid #ccc;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                box-sizing: border-box;
                .add_kind {
                    flex: 1;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    background: #409eff;
                    height: 35px;
                    color: #fff;
                    border-radius: 5px;
                }
                .prodect_title_sort {
                    display: flex;
                    flex: 1;
                    align-items: center;
                    justify-content: center;
                    div {
                        display: flex;
                        flex-direction: column;
                        color: #ccc;
                        justify-content: space-around;
                        margin-left: 5px;
                    }
                }
            }
            .prodect_item {
                height: 35px;
                padding: 0 10px;
                padding-top: 10px;
                box-sizing: border-box;
                height: 745px;
                overflow: auto;
                padding-right: 0;
                .prodect_item_info {
                    width: 100%;
                    border-right: 3px solid #fff;
                    display: flex;
                    height: 40px;
                    align-items: center;
                    .type {
                        flex: 1;
                        padding-left: 10px;
                        overflow: hidden; //超出的文本隐藏
                        text-overflow: ellipsis; //溢出用省略号显示
                        white-space: nowrap; //溢出不换行
                    }
                    .inputinfo {
                        display: block;
                        flex: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        input {
                            height: 20px;
                            text-align: center;
                            width: 50%;
                            // padding-left: 10px;
                            box-sizing: border-box;
                            border: 1px solid #409eff;
                        }
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none !important;
                        }
                        input[type="number"] {
                            -moz-appearance: textfield;
                        }
                    }
                    .setting {
                        flex: 1;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        box-sizing: border-box;
                        // padding: 0 10px;
                        // padding-left: 30px;
                        div {
                            display: flex;
                            width: 60%;
                            justify-content: space-around;
                            margin: 0 auto;
                            i {
                                color: #333;
                            }
                            i:hover {
                                color: #409eff;
                            }
                        }
                    }
                }
                .check_act {
                    border-right: 3px solid #409eff;
                    color: #409eff;
                }
            }
        }
        .prodect_right {
            // width: 938px;
            width: 80%;
            background: #fff;
            padding: 26px;
            // overflow: auto;
            height: 800px;
            box-sizing: border-box;
            float: left;
            .right_title {
                display: flex;
                height: 50px;
                align-items: center;
                .title_left {
                    display: flex;
                    color: #666;
                    align-items: center;
                    height: 100%;
                    input {
                        width: 379px;
                        height: 32px;
                        background: #ffffff;
                        border: 1px solid #dddddd;
                        opacity: 1;
                        padding-left: 10px;
                        border-radius: 4px;
                    }
                    .title_serch {
                        width: 70px;
                        height: 32px;
                        background: #409eff;
                        opacity: 1;
                        border-radius: 2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #ffffff;
                        margin-left: 10px;
                    }
                    .title_reload {
                        width: 70px;
                        height: 32px;
                        background: #ffffff;
                        border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
                        opacity: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 2px;
                        margin-left: 10px;
                    }
                }
                .title_right {
                    // flex: 1.5;
                    // height: 100%;
                    align-items: center;
                    display: flex;
                    flex: 1;
                    justify-content: flex-end;
                    color: #fff;
                    div {
                        border-radius: 5px;
                        color: #fff;
                        align-items: center;
                        display: flex;
                        padding: 0 10px;
                        background: #409eff;
                        height: 32px;
                    }
                }
            }
            .right_body {
                .prodect_change {
                    display: flex;
                    align-items: center;
                    div {
                        margin-left: 10px;
                    }
                    div:hover {
                        color: #409eff;
                    }
                    i:hover {
                        color: #409eff;
                    }
                }
            }
            .right_body .el-table {
                height: 707px !important;
            }
        }
    }

    .drawer_form {
        margin-top: 40px;
        width:93%;
        padding-right: 5%;
        margin-left: 5%;
        .drawer_form_box{
            overflow-y: scroll;
            height:700px;
        }
    }

    .el-form-item__content {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .add_p{
        font-size: 14px;
        color: #3A85FC;
        position: relative;
        margin-left: 90%;
        cursor: pointer;
    }
    .qq div{
        width: 100%;
        background: #fff;
        justify-content: space-around
    }
    .dd div{
        width: 100%!important;
    }
    .customer_achi_head_back {
        width: 90px;
        height: 35px;
        background: #3a85fc;
        border-radius: 2px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 110px;
        right: 80px;
    }
</style>