import request from '@/utils/axios.tool';
//分类列表/产品列表
export const getProductList = (data) => {
    return request.post(`/roleapi/cloudPlatform/list`, data)
}
//添加分类
export const addProductClass = (data) => {
    return request.post(`/roleapi/cloudPlatform/addClassify`, data)
}
// 修改/删除分类
export const editProductClass = (data) => {
    return request.post(`/roleapi/cloudPlatform/editClassify`, data)
}
//添加产品
export const addProductName = (data) => {
    return request.post(`/roleapi/cloudPlatform/addProduct`, data)
}
// 修改/删除产品
export const editProductName = (data) => {
    return request.post(`/roleapi/cloudPlatform/editProduct`, data)
}
// 排序
export const editProductBy = (data) => {
    return request.post(`/roleapi/cloudPlatform/sort`, data)
}
// 页面展示产品列表
export const productListd = (data) => {
    return request.post(`/roleapi/cloudPlatform/productList`, data)
}
// 页面展示产品详情
export const productDetail = (data) => {
    return request.post(`/roleapi/cloudPlatform/productDetail`, data)
}
