import request from '@/utils/axios.tool';

// 总监客户列表
export const ciCustomerList = (data)=>{              
    return request.get(`/roleapi/distribution/ciCustomerList`,data)
}
// 总监搜索人员
export const ciSearch = (data)=>{              
    return request.get(`/roleapi/distribution/ciSearch`,data)
}
// 总监分配客户
export const ciAssignCustomer = (data)=>{              
    return request.post(`/roleapi/distribution/ciAssignCustomer`,data)
}
// 财务客户列表
export const financeCustomerList = (data)=>{              
    return request.get(`/roleapi/distribution/financeCustomerList`,data)
}
// 财务搜索人员
export const financeSearch = (data)=>{              
    return request.get(`/roleapi/distribution/financeSearch`,data)
}
// 财务分配客户   重新分配
export const financeAssignCustomer = (data)=>{              
    return request.post(`/roleapi/distribution/financeAssignCustomer`,data)
}