<template>
  <div class="role">
    <div class="table" v-if="!isAdd">
      <div class="role_add">
        <div @click="handleAdd" v-if="ability.add_role">
          <i class="el-icon-plus" style="margin-right: 5px"></i>新增角色
        </div>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="50"> </el-table-column>
        <el-table-column prop="name" label="角色名"> </el-table-column>
        <el-table-column prop="created_at" label="添加时间"> </el-table-column>
        <el-table-column width="120" label="操作">
          <template slot-scope="scope">
            <div class="cz">
              <div
                @click="handleDetail(scope.row)"
                v-if="ability.change_role"
                class="cz_detail"
              >
                编辑
              </div>
              <div @click="handleDel(scope.row)" v-if="ability.del_role">
                删除
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          layout="total,prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div class="login_mc" v-if="isMc"></div>
    <div class="addRole" v-if="isAdd">
      <div class="addrole_header">
        <div>
          {{ title }}
          <i @click="handleClose" class="el-icon-close"></i>
        </div>
      </div>
      <div class="addRole_input">
        <div class="addRole_input_tit">角色名称：</div>
        <div class="addRole_input_input">
          <input type="text" v-model="addRolename" placeholder="请输入角色名" />
        </div>
      </div>
      <div style="font-weight: bold; color: rgba(0, 0, 0, 0.8);margin-top:40px;margin-left:50px;line-height:19px">权限选择</div>
      <div class="addRole_box">
        <div
          class="addRole_box_line"
          v-for="(item, index) in roleList"
          :key="index"
        >
          <div class="addRole_box_line_left">
            <div class="left_box" @click="handleAllclick(item.id)">
              <div class="checkbox">
                <div
                  :style="item.isCheck ? 'display:block' : 'display:none'"
                ></div>
              </div>
              <div class="checkbox_name">{{ item.permissions_name }}：</div>
            </div>
          </div>
          <div class="addRole_box_line_right">
            <div
              v-for="(itm, idx) in item.data"
              :key="idx"
              class="right_box"
              @click="handleOneclick(item.id, itm.id)"
            >
              <div class="checkbox">
                <div
                  :style="itm.isCheck ? 'display:block' : 'display:none'"
                ></div>
              </div>
              <div class="checkbox_name">{{ itm.permissions_name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="addrole_botton">
        <div class="add_no" @click="handleClose">取消</div>
        <div class="add_ok" @click="handleSave">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import { role } from "..";
import {
  selectRoleList,
  userPermissionsList,
  deleteRole,
  saveRole,
  selectFirstRole,
} from "../../api/User/role";
export default {
  data() {
    return {
      title: "",
      isMc: false, //蒙层
      isAdd: false,
      tableData: [], //已有角色列表
      roleList: [], //角色总数据
      total: 0, //总条数
      page: 1, //页码
      addRolename: "", //添加角色name
      user_role_id: "", //角色id
      status: "", //  1 新增 2 编辑
      ability: {
        add_role: false, //添加角色 权限
        del_role: false, //删除
        change_role: false, //修改
      },
      role_alldata: {},
      add_flag:false,
    };
  },
  created() {
    let newdata = JSON.parse(localStorage.getItem("token"));
    let role_permissions = newdata.role_permissions;
    let newpower = {};
    for (let i in role_permissions) {
      if (role_permissions[i].permissions == "system") {
        newpower = role_permissions[i];
        this.role_alldata = role_permissions[i];
      }
    }
    let roleList = JSON.parse(localStorage.getItem("user")).role_permissions; //权限列表
    let check_id = "";
    if (JSON.parse(localStorage.getItem("check_id"))) {
      check_id = JSON.parse(localStorage.getItem("check_id"));
    }
    roleList.forEach((item) => {
      // console.log(item);
      // if (item.id == check_id) {
      if (item.data) {
        item.data.forEach((itm) => {
          if (itm.permissions == "add_role") {
            this.ability.add_role = true;
          }
          if (itm.permissions == "del_role") {
            this.ability.del_role = true;
          }
          if (itm.permissions == "change_role") {
            this.ability.change_role = true;
          }
        });
      }
      // }
    });

    let permissions_id_string = "";
    for (let i in this.role_alldata.data) {
      if (this.role_alldata.data[i].permissions == "role_list") {
        permissions_id_string = this.role_alldata.data[i].permissions_id_string;
      }
    }
    selectRoleList({ permissions_id_string }).then((res) => {
      if (res.data.code == 200) {
        this.tableData = res.data.data.data;
        this.total = res.data.data.total;
      }
    });
    let permissions_id_string_1 = "";
    for (let i in this.role_alldata.data) {
      if (this.role_alldata.data[i].permissions == "change_role") {
        permissions_id_string_1 = this.role_alldata.data[i]
          .permissions_id_string;
      }
    }
    if (permissions_id_string_1 == "") {
      return;
    }
    userPermissionsList({
      permissions_id_string: permissions_id_string_1,
    }).then((res) => {
      if (res.data.code == 200) {
        let list = res.data.data;
        for (let i = 0; i < list.length; i++) {
          list[i].isCheck = false;
          if (list[i].data) {
            for (let j = 0; j < list[i].data.length; j++) {
              list[i].data[j].isCheck = false;
            }
          }
        }
        this.roleList = list;
      }
    });
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      let permissions_id_string = "";
      for (let i in this.role_alldata.data) {
        if (this.role_alldata.data[i].permissions == "role_list") {
          permissions_id_string = this.role_alldata.data[i]
            .permissions_id_string;
        }
      }
      selectRoleList({ permissions_id_string, page: val }).then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
        }
      });
    },
    handleAdd() {
      //打开添加按钮
      this.isMc = true;
      this.isAdd = true;
      this.title = "添加角色";
      this.status = 1;
      this.addRolename =  ''
      this.roleList = []
      let permissions_id_string_1 = "";
      for (let i in this.role_alldata.data) {
        if (this.role_alldata.data[i].permissions == "change_role") {
          permissions_id_string_1 = this.role_alldata.data[i]
            .permissions_id_string;
        }
      }
      userPermissionsList({
        permissions_id_string: permissions_id_string_1,
      }).then((res) => {
        if (res.data.code == 200) {
          let list = res.data.data;
          for (let i = 0; i < list.length; i++) {
            list[i].isCheck = false;
            if (list[i].data) {
              for (let j = 0; j < list[i].data.length; j++) {
                list[i].data[j].isCheck = false;
              }
            }
          }
          this.roleList = list;
        }
      });
    },
    handleAllclick(id) {
      //全选
      let { roleList } = this;
      roleList.forEach((item) => {
        if (item.id == id) {
          item.isCheck = !item.isCheck;
          if (item.data) {
            item.data.forEach((itm) => {
              itm.isCheck = item.isCheck;
            });
          }
        }
      });
      this.roleList = [...roleList];
    },
    handleOneclick(id, litid) {
      let { roleList } = this;
      roleList.forEach((item) => {
        if (item.id == id) {
          item.data.forEach((itm) => {
            if (itm.id == litid) {
              itm.isCheck = !itm.isCheck;
            }
          });
          for (let i = 0; i < item.data.length; i++) {
            if (item.data[i].isCheck) {
              item.isCheck = true;
              break;
            } else {
              item.isCheck = false;
            }
          }
        }
      });
      this.roleList = [...roleList];
    },
    handleSave() {
      if (this.add_flag) {
        return false
      }
      this.add_flag = true
      let arr = [];
      let { roleList, status, addRolename, user_role_id } = this;
      for (let i = 0; i < roleList.length; i++) {
        if (roleList[i].isCheck) {
          arr.push(roleList[i].id);
          if (roleList[i].data) {
            for (let j = 0; j < roleList[i].data.length; j++) {
              if (roleList[i].data[j].isCheck) {
                arr.push(roleList[i].data[j].id);
              }
            }
          }
        }
      }
      let data = {};
      let str = arr.join(",");
      let permissions_id_string = "";
      for (let i in this.role_alldata.data) {
        if (this.role_alldata.data[i].permissions == "add_role") {
          permissions_id_string = this.role_alldata.data[i]
            .permissions_id_string;
        }
      }
      if (status == 1) {
        data = {
          name: addRolename,
          permissions_id_str: str,
          permissions_id_string,
        };
      }
      if (status == 2) {
        data = {
          name: addRolename,
          permissions_id_str: str,
          user_role_id,
          permissions_id_string,
        };
      }
      saveRole(data).then((res) => {
        this.add_flag = false
        if (res.data.code == 400) {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: res.data.message,
          });
          let permissions_id_string = "";
          for (let i in this.role_alldata.data) {
            if (this.role_alldata.data[i].permissions == "role_list") {
              permissions_id_string = this.role_alldata.data[i]
                .permissions_id_string;
            }
          }
          selectRoleList({ permissions_id_string }).then((ress) => {
            if (res.data.code == 200) {
              this.tableData = ress.data.data.data;
              this.total = ress.data.data.total;
            }
            this.isMc = false;
            this.isAdd = false;
          });
        }

      });
    },
    handleClose() {
      this.addRolename = "";
      this.roleList.forEach((item) => {
        item.isCheck = false;
        if (item.data) {
          item.data.forEach((itm) => {
            itm.isCheck = false;
          });
        }
      });
      this.isMc = false;
      this.isAdd = false;
    },
    handleDetail(row) {
      //编辑
      // console.log(this.roleList);
      // console.log(row);
      this.status = 2;
      this.title = "编辑角色";
      let id = row.id;
      this.user_role_id = id;
      let permissions_id_string_1 = "";
      for (let i in this.role_alldata.data) {
        if (this.role_alldata.data[i].permissions == "change_role") {
          permissions_id_string_1 = this.role_alldata.data[i]
            .permissions_id_string;
        }
      }
      selectFirstRole({
        user_role_id: id,
        permissions_id_string: permissions_id_string_1,
      }).then((res) => {
        if (res.data.code == 200) {
          this.isMc = true;
          this.isAdd = true;
          let list = res.data.data;
          if (list.length == 0) {
            //当前角色没有权限
            //编辑时候 权限全部为未选中
          } else {
            //循环
            let roleList = this.roleList;
            for (let i = 0; i < roleList.length; i++) {
              for (let j = 0; j < list.length; j++) {
                if (roleList[i].id == list[j].id) {
                  roleList[i].isCheck = true;
                  if (roleList[i].data) {
                    for (let k = 0; k < roleList[i].data.length; k++) {
                      for (let n = 0; n < list[j].data.length; n++) {
                        if (roleList[i].data[k].id == list[j].data[n].id) {
                          roleList[i].data[k].isCheck = true;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
      this.addRolename = row.name;
    },
    handleDel(row) {
      //删除
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let permissions_id_string = "";
          for (let i in this.role_alldata.data) {
            if (this.role_alldata.data[i].permissions == "del_role") {
              permissions_id_string = this.role_alldata.data[i]
                .permissions_id_string;
            }
          }
          deleteRole({ user_role_id: row.id, permissions_id_string }).then(
            (res) => {
              if (res.data.code == 200) {
                this.$message({
                  type: "success",
                  message: res.data.message,
                });
                let permissions_id_string = "";
                for (let i in this.role_alldata.data) {
                  if (this.role_alldata.data[i].permissions == "role_list") {
                    permissions_id_string = this.role_alldata.data[i]
                      .permissions_id_string;
                  }
                }
                selectRoleList({ permissions_id_string }).then((ress) => {
                  if (res.data.code == 200) {
                    this.tableData = ress.data.data.data;
                    this.total = ress.data.data.total;
                  }
                });
              }
              if (res.data.code == 400) {
                this.$message({
                  type: "error",
                  message: res.data.message,
                });
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss">
.role {
  width: 100%;
  height: 100%;
  .role_add {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #fff;
    div {
      cursor: pointer;
      width: 100px;
      height: 70%;
      display: flex;
      align-items: center;
      background: #3a85fc;
      border-radius: 4px;
      color: #fff;
      justify-content: center;
      margin-right: 20px;
    }
  }
  .table {
    margin: 20px;
    background: #fff;
    padding-bottom: 20px;
    padding: 20px;
    .block {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      // justify-content: center;
    }
    .cz {
      cursor: default;
      display: flex;
      align-items: center;
      justify-content: space-around;
      div {
        line-height: 14px;
        cursor: pointer;
      }
      div:hover{
        color: #3a85fc;
      }
      .cz_detail {
        border-right: 1px solid #ccc;
        padding-right: 10px;
      }
    }
  }
  .addRole {
    width: 60%;
    height: 80%;
    background: #fff;
    border-radius: 10px;
    z-index: 99;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-width: 834px;
    .addrole_header {
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-bottom: 1px dotted #f5f5f5;
      div {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
        i {
          margin-right: 40px;
          padding: 10px;
          cursor: pointer;
        }
      }
    }
    .addRole_input {
      height: 32px;
      border-radius: 5px;
      margin-top: 20px;
      margin-left: 50px;
      display: flex;
      align-items: center;
      .addRole_input_input {
        border: 1px solid #cccccc;
        border-radius: 5px;
        height: 100%;
        margin-left: 10px;
        input {
          width: 100%;
          height: 100%;
          padding-left: 10px;
        }
      }
    }
    .addRole_box {
      flex: 1;
      margin-left: 50px;
      height: 90%;
      overflow: auto;
      .addRole_box_line {
        display: flex;
        // align-items: center;
        margin-top: 10px;
        min-height: 50px;
        // border-bottom: 1px solid #ccc;
        .checkbox_check {
          border: 1px solid #3a85fc;
        }
        .addRole_box_line_left {
          height: 100%;
          width: 15%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 20px;
          // border-right: 1px solid #ccc;
          .left_box {
            padding: 10px 0;
            display: flex;
            align-items: center;
            margin-top: 10px;
            cursor: pointer;
            .checkbox {
              width: 15px;
              height: 15px;
              border-radius: 50%;
              border: 1px solid #ccc;
              margin-right: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              div {
                width: 50%;
                height: 50%;
                border-radius: 50%;
                background: #3a85fc;
              }
            }
          }
        }
        .addRole_box_line_right {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: 20px;
          flex-wrap: wrap;
          .right_box {
            height: 40%;
            min-width: 15%;
            display: flex;
            align-items: center;
            cursor: pointer;
            // border: 1px solid #f5f5f5;
            margin-right: 20px;
            padding: 10px 0;
            border-radius: 5px;
            margin-bottom: 10px;
            margin-top: 10px;
            .checkbox {
              width: 15px;
              height: 15px;
              border-radius: 50%;
              border: 1px solid #ccc;
              margin-right: 10px;
              margin-left: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              div {
                width: 50%;
                height: 50%;
                border-radius: 50%;
                background: #3a85fc;
              }
            }
          }
          // .el-checkbox-group{
          //     width: 100%;
          //     display: flex;
          //     align-items: center;
          //     flex-wrap: wrap;
          //     label{
          //         width: 13%;
          //         display: inline-block;
          //         // margin: 10px auto;
          //         margin-right: 0;
          //         margin-left: 10px;
          //         margin-top: 10px;
          //         margin-bottom: 10px;
          //     }

          // }
        }
      }
    }
    .addrole_botton {
      height: 40px;
      display: flex;
      justify-content: flex-end;
      margin-right: 100px;
      margin-top: 22px;
      margin-bottom: 26px;
      // position: absolute;
      // right: 20px;
      // bottom: 20px;
      div {
        cursor: pointer;
        width: 80px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        font-size: 14px;
        margin-left: 20px;
      }
      .add_no {
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
        opacity: 1;
        color: rgba(0, 0, 0, 0.5);
      }
      .add_ok {
        background: #3a85fc;
        color: #ffffff;
      }
    }
  }
  .login_mc {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 98;
  }
  .el-table__header-wrapper {
    table {
      width: 100% !important;
      table-layout: auto;
    }
  }
  .el-table__body-wrapper {
    table {
      width: 100% !important;
      table-layout: auto;
    }
  }
}
</style>