<template>
  <div class="refund_audit">
    <el-main>
      <div class="refund_audit_tab">
        <div
          :class="
            is_refund_audit == item.id
              ? 'refund_audit_check pointer'
              : 'refund_audit_no_check pointer'
          "
          :style="item.id == 0 ? 'margin-left:0px;' : ''"
          v-for="(item, index) in tab_arr"
          :key="index"
          @click="handleCheck(item.id)"
        >
          <div>{{ item.name }}</div>
          <div class="refund_audit_border"></div>
        </div>
      </div>
      <div class="refund_audit_table">
        <el-table
          :data="tableData"
          border
          :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
          style="width: 100%"
        >
          <el-table-column label="公司名称" :key="1">
            <template scope="scope">
              {{ scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column label="客户姓名" :key="4">
            <template scope="scope">
              {{ scope.row.contacts }}
            </template>
          </el-table-column>
          <el-table-column label="产品名">
            <template scope="scope">
              {{ scope.row.product_name }}
            </template>
          </el-table-column>
          <el-table-column label="退款金额（元）" :key="3">
            <template scope="scope">
              {{ scope.row.to_money }}
            </template>
          </el-table-column>
          <el-table-column label="申请人" :key="12">
            <template scope="scope"> {{ scope.row.user }} </template>
          </el-table-column>
          <el-table-column label="申请部门" :key="13">
            <template scope="scope">
              {{ scope.row.user_company ? scope.row.user_company : "--" }}
            </template>
          </el-table-column>
          <el-table-column label="业绩时间" :key="16">
            <template scope="scope">
              {{
                scope.row.achievement_time ? scope.row.achievement_time : "--"
              }}
            </template>
          </el-table-column>
          <el-table-column label="分单人" :key="2">
            <template scope="scope">
              <div>
                <div
                  v-for="(item, index) in scope.row.order_data_branch"
                  :key="index"
                >
                  {{ item.user ? item.user.name : "--" }}
                </div>
                <div v-if="scope.row.order_data_branch.length == 0">--</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="分单人部门" :key="5">
            <template scope="scope">
              <div>
                <div
                  v-for="(item, index) in scope.row.order_data_branch"
                  :key="index"
                >
                  {{ item.user ? item.companys.name : "--" }}
                </div>
                <div v-if="scope.row.order_data_branch.length == 0">--</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="分单人金额（元）" :key="6">
            <template scope="scope">
              <div>
                <div
                  v-for="(item, index) in scope.row.order_data_branch"
                  :key="index"
                >
                  {{ item.user ? item.money : "--" }}
                </div>
                <div v-if="scope.row.order_data_branch.length == 0">--</div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="申请时间" :key="14">
            <template scope="scope"> {{ scope.row.created_at }} </template>
          </el-table-column>
          <el-table-column label="申请说明" :key="7">
            <template scope="scope">
              <div class="apply_explain">
                <el-popover
                  placement="bottom"
                  width="400"
                  trigger="click"
                  v-if="scope.row.remarks"
                >
                  <div slot="reference" class="pointer acc_table_company_click">
                    点击查看
                  </div>
                  <div v-html="scope.row.remarks"></div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="状态" :key="15" v-if="is_refund_audit == 1">
            <template scope="scope">
              <div>
                <i
                  :class="
                    scope.row.status == 1
                      ? 'pass_status'
                      : scope.row.status == 2
                      ? 'reject_status'
                      : ''
                  "
                ></i>
                {{
                  scope.row.status == 1
                    ? "通过"
                    : scope.row.status == 2
                    ? "驳回"
                    : "--"
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            :key="11"
            :width="is_refund_audit == 0 ? '130' : ''"
          >
            <template scope="scope">
              <div
                style="display: flex; white-space: nowrap"
                class="scope_cz"
                v-if="role_what.edit"
              >
                <div
                  class="pointer"
                  v-if="is_refund_audit == 0"
                  @click="open(scope.row, 1)"
                >
                  通过
                </div>
                <div
                  class="pointer"
                  @click="open(scope.row, 2)"
                  style="margin-left: 10px; white-space: nowrap"
                  v-if="is_refund_audit == 0"
                >
                  驳回
                </div>
                <div
                  style="white-space: nowrap; margin-left: 10px"
                  class="pointer"
                  @click="edittime(scope.row)"
                >
                  编辑
                </div>
              </div>
              <div v-else>----</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="10"
            el-pagination
            layout="total,prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="refund_audit_voucher_box" v-if="is_edit">
        <div class="voucher">
          <div class="voucher_head">
            修改业绩时间
            <i class="pointer el-icon-close" @click="is_edit = false"></i>
          </div>
          <el-date-picker
            v-model="edit_time"
            type="date"
            prefix-icon="sada"
            placeholder="选择日期时间"
          >
          </el-date-picker>
          <div class="voucher_bottom">
            <div @click="is_edit = false">取消</div>
            <div @click="edit">确定</div>
          </div>
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
import {
  orderRefundList,
  orderRefundUpdate,
  editAchievementTime,
} from "../../api/User/approval";
export default {
  data() {
    return {
      is_refund_audit: "1",
      tableData: [],
      tab_arr: [
        { id: "0", name: "待审核" },
        { id: "1", name: "已通过未办理" },
        { id: "-1", name: "已通过已办理" },
        { id: "2", name: "已驳回" },
      ],
      currentPage: 1,
      total: 0,
      role_what: {
        edit: false,
        edit_str: "",
        list_str: "",
      },
      is_edit: false,
      edit_row: {},
      edit_time: "",
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      let roleList = JSON.parse(localStorage.getItem("token"))
        .role_permissions;
      roleList.forEach((item) => {
        if (item.permissions == "Finance") {
          item.data.forEach((itm) => {
            if (itm.permissions == "Approval_refund_edit") {
              this.role_what.edit = true;
              this.role_what.edit_str = itm.permissions_id_string;
            }
            if (itm.permissions == "Approval_refund_list") {
              this.role_what.list_str = itm.permissions_id_string;
            }
          });
        }
      });
    }
    if (localStorage.getItem("is_refund_audit")) {
      this.is_refund_audit = localStorage.getItem("is_refund_audit");
    } else {
      this.is_refund_audit = 0;
    }

    this.getorderRefundList({
      status: this.is_refund_audit,
      permissions_id_string: this.role_what.list_str,
    });
  },
  methods: {
    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); // let strDate = Y + M + D;
      let strDate = Y + M + D;
      return strDate;
    },
    getorderRefundList(data) {
      orderRefundList(data).then((res) => {
        if (res.data.code == 200) {
          this.total = res.data.data.total;
          this.tableData = res.data.data.data;
        }
      });
    },
    handleCheck(i) {
      this.is_refund_audit = i;
      localStorage.setItem("is_refund_audit", i);
      this.getorderRefundList({
        status: i,
        permissions_id_string: this.role_what.list_str,
      });
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.getorderRefundList({
        status: this.is_refund_audit,
        page: val,
        permissions_id_string: this.role_what.list_str,
      });
    },
    open(row, i) {
      let tit = "";
      if (i == 1) {
        tit = "您确定通过退款审核吗？";
      }
      if (i == 2) {
        tit = "您确定驳回退款审核吗？";
      }
      this.$confirm(tit, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          orderRefundUpdate({
            status: i,
            order_data_id: row.order_data_id,
            permissions_id_string: this.role_what.edit_str,
          }).then((res) => {
            if (res.data.code == 200) {
              this.getorderRefundList({
                status: this.is_refund_audit,
                page: this.currentPage,
                permissions_id_string: this.role_what.list_str,
              });

              this.$message({
                type: "success",
                message: res.data.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    edittime(row) {
      this.edit_row = row;
      this.is_edit = true;
      this.edit_time = row.achievement_time;
    },
    edit() {
      let achievement_time = "";
      let { edit_row, edit_time } = this;
      if (!edit_time) {
          this.$message.error('时间不能为空');
          return
      }
      if (edit_time == edit_row.achievement_time) {
        achievement_time = edit_time;
      } else {
        achievement_time = this.getwaytime(edit_time);
      }
      editAchievementTime({
        achievement_time,
        order_data_id: edit_row.order_data_id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.is_edit = false;
          this.$message.success(res.data.message);
          this.getorderRefundList({
            status: this.is_refund_audit,
            permissions_id_string: this.role_what.list_str,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.refund_audit {
  height: 100%;
  width: 100%;
  padding: 20px;
  background: #eef5f9;
  flex: 1 1 0%;
  .el-table th.gutter {
    display: table-cell !important;
  }
  .el-main {
    background: #ffffff;
    height: 100%;
    .refund_audit_tab {
      display: flex;
      border-bottom: 1px solid rgba(0, 0, 0, 0.10196078431372549);
      .refund_audit_check {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #3a85fc;
        opacity: 1;
        display: flex;
        // width: 165px;
        margin-left: 110px;
        flex-direction: column;
        align-items: center;
        height: 35px;
        justify-content: space-between;
        div:last-child {
          width: 65px;
          height: 3px;
          background: #3a85fc;
          opacity: 1;
        }
      }
      .refund_audit_no_check {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: rgba(0, 0, 0, 0.48);
        opacity: 1;
        // width: 165px;
        display: flex;
        margin-left: 110px;
        flex-direction: column;
        align-items: center;
        height: 35px;
        justify-content: space-between;
        div:last-child {
          width: 65px;
          height: 3px;
          background: #fff;
          opacity: 1;
        }
      }
    }
    .refund_audit_table {
      margin-top: 40px;
      .apply_explain {
        .acc_table_company_click:hover {
          color: #3a85fc;
        }
      }
      .el-table__header-wrapper {
        .cell {
          white-space: nowrap;
        }
      }
      .block {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
      }
      .wait_status {
        //0  待审核
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #faad14;
        opacity: 1;
        border-radius: 50%;
        margin-right: 5px;
      }
      .pass_status {
        //1 通过
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #1dd0bd;
        border: 1px solid #1dd0bd;
        border-radius: 50%;
        opacity: 1;
        margin-right: 5px;
      }
      .reject_status {
        //2驳回
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #ff5c5c;
        border-radius: 50%;
        opacity: 1;
        margin-right: 5px;
      }
      .scope_cz {
        div {
          color: #3a85fc;
        }
      }
    }
    .refund_audit_voucher_box {
      position: fixed;
      z-index: 100;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      .voucher {
        width: 600px;
        min-width: 460px;
        min-height: 240px;
        height: 240px;
        background: #ffffff;
        opacity: 1;
        border-radius: 10px;
        margin: auto;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
        display: flex;
        flex-direction: column;
        .voucher_head {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 60px;
          font-size: 16px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          width: 100%;
          position: relative;
          i {
            position: absolute;
            right: 20px;
            padding: 10px;
          }
        }
        .el-date-editor {
          width: 80%;
          margin: 20px auto;
          height: 32px;
          input {
            height: 32px;
          }
          .el-input__suffix {
            height: 32px;
            display: flex;
            align-items: center;
          }
        }
        .voucher_bottom {
          display: flex;
          width: 80%;
          justify-content: flex-end;
          margin: 0 auto;
          margin-top: 40px;
          div {
            width: 80px;
            height: 40px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 4px;
          }
          div:first-child {
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
            opacity: 1;
          }
          div:last-child {
            background: #3a85fc;
            color: #fff;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
</style>