<template>
  <div class="Layout">
        <index-header/>
  </div>
</template>

<script>
import index_header from '@/components/index_header'
export default {
    
 components:{
    'index-header':index_header
    }
}
</script>

<style >
.Login{
    height: 100%;
}
.Layout{
  height: 100%;
}
</style>