<template>
  <div class="Customer_assignment_box" style="flex: 1">
      <el-main>
        <div class="Customer_assignment_info">
            <div class="serch_top">
                <div class="serch_one">客户名：
                    <el-input
                        size="large"
                        placeholder="请输入公司名称"
                        v-model="compony_name">
                    </el-input>
                </div>
                <div class="serch_one">产品：
                     <el-cascader
                        clearable
                        v-model="product_name"
                        :options="product_options"
                        placeholder="请选择产品"
                        separator=" - "
                    >
              </el-cascader>
                </div>
                <div class="serch_one">
                    合同号：
                    <el-input
                        size="large"
                        placeholder="请输入合同号"
                        v-model="Contract">
                    </el-input>
                </div>
                <div class="serch_one">
                     签单人：
                    <el-input
                        size="large"
                        placeholder="请输入签单人"
                        v-model="Signer">
                    </el-input>
                </div>
                 <div class="serch_one">
                     <div class="account_serch pointer" @click="getlist(1)">查询</div>
                    <div class="account_reload pointer" @click="getlist(0,1)">重置</div>
                </div>
            </div>
            <div class="serch_fp" :style="isPersonal==1 ? '':'justify-content: flex-end;'">  
                <div class="info_fir pointer" v-if="isPersonal==1" @click="handle_shadowshow">
                    <i class="el-icon-share" style="color:#fff"></i>分配
                </div>
                <div class="customer_header_check pointer">
                    <div
                        :class="isPersonal == 1 ? 'isPersonal' : ''"
                        @click="handleisPersonal(1)"
                    >
                        未分配
                    </div>
                    <div
                        :class="isPersonal == 2 ? 'isPersonal' : ''"
                        @click="handleisPersonal(2)"
                    >
                        已分配
                    </div>
                </div>
            </div>
            <!-- v-if="tableData.length>0" -->
             <div class="acc_table" >
                   <!-- :header-cell-style="{ background: '#F5F5F5', color: '#606266' }" -->
                <el-table
                  :data="tableData"
                  style="width: 100%"
                  @selection-change="handleSelectionChange"
                  border
                  :row-key="(row)=>{ return row.id}"
                  :default-sort="{ prop: 'date', order: 'descending' }"
                >
                  <el-table-column
                      type="selection"
                      v-if="isPersonal==1"
                      key="1"
                      :reserve-selection="true"
                  >
                  </el-table-column>
                  <el-table-column prop="name" label="公司名称"
                  key="2"
                  >
                    <template slot-scope="scope">
                      <div class="acc_table_company_name">
                        {{ scope.row.customer? scope.row.customer.name:'--' }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="name" label="产品"
                  key="3">
                    <template slot-scope="scope">
                      <div class="acc_table_company_name">
                        {{ scope.row.product_name?  scope.row.product_name.product_name:'--'}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="name" label="签单人"
                    key="111
                    ">
                      <template slot-scope="scope">
                        <div class="acc_table_company_name">
                          {{ scope.row.user?  scope.row.user.name:'--'}}
                        </div>
                      </template>
                  </el-table-column>
                  <el-table-column prop="address" label="合同号"
                  key="4">
                    <template slot-scope="scope">
                      <div class="acc_table_company_name">
                        {{ scope.row.contract_number?  scope.row.contract_number:'--'}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" label="合同金额"
                  key="5">
                    <template slot-scope="scope">
                      <div class="acc_table_company_name">
                        {{
                          scope.row.contract_money? scope.row.contract_money:'--'
                        }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" label="到账金额"
                  key="6">
                    <template slot-scope="scope">
                      <div class="acc_table_company_name">
                        {{
                          scope.row.to_money
                        }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" label="客服部门" v-if="isPersonal!=1"
                  key="7">
                    <template slot-scope="scope">
                      <div class="acc_table_company_name">
                      {{scope.row.order_distribution.service_info ? scope.row.order_distribution.service_info.company.name:'-'}}-{{scope.row.order_distribution.service_info? scope.row.order_distribution.service_info.name:'-'}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" label="客服部门" v-if="0"
                  key="8">
                    <template slot-scope="scope">
                      <div class="acc_table_company_name ">
                      <!-- {{scope.row.order_distribution.ci_info.company.name}}-{{scope.row.order_distribution.ci_info.name}} -->
                          <div class="have_tk" v-if="scope.row.service_info">
                            已领取
                          </div>
                          <div class="no_tk" v-if="!scope.row.service_info">
                            未领取  <div class="pointer" @click="sentitem(scope.row)">重新分配</div>
                          </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" label="操作人" v-if="isPersonal!=1"
                  key="9">
                    <template slot-scope="scope">
                      <div class="acc_table_company_name">
                        {{scope.row.order_distribution.ci_operate_user.name}} <br/>
                        {{scope.row.order_distribution.ci_operate_time}}
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="page"
                  :page-size="10"
                  layout="total,prev, pager, next"
                  :total="total"
                >
                </el-pagination>
          </div>
        </div>
      </el-main>
    <div
      class="Node_misson_shadow"
      v-if="shadow_show"
      @click.stop="shadow_noshow"
    >
      <div class="Node_shadow" @click.stop="shadow_show = true">
        <div class="Node_shadow_title">
          选择分配人
          <i
            class="el-icon-close pointer"
            @click.stop="shadow_show = false"
            style="position: absolute; right: 19px; color: rgb(153, 153, 153)"
          ></i>
        </div>
        <div class="serch_input">
              <el-autocomplete
                v-model="state"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入分配人名字后选择"
                @select="handleSelect"
              ></el-autocomplete>
        </div>
        <div class="fp_btn pointer" @click="fp">
          分配
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductList } from "../../api/User/customer";
import { ciCustomerList,ciSearch,ciAssignCustomer,financeCustomerList,financeSearch,financeAssignCustomer} from "../../api/User/assignment";

export default {
  data() {
    return {
      compony_name: "",
      product_name: "",
      product_options: [],
      Contract: "", //合同号
      Signer: "", //签单人
      isPersonal:1,//1未分配2已分配
      check_items:[],
      page:1,
      total:0,
      tableData:[],
      shadow_show:false,
      state:'',
      human_check:{},
      role:{
        list_str:'',
        is_fp:false,
        fpstr:''
      }
    };
  },
  created() {
    getProductList().then((res) => {
      if (res.data.code == 200) {
        let list = res.data.data;
        for (let i = 0; i < list.length; i++) {
          list[i].label = list[i].class_name;
          list[i].value = list[i].id;
          list[i].children = list[i].product_name;
          for (let j = 0; j < list[i].product_name.length; j++) {
            list[i].product_name[j].label =
              list[i].product_name[j].product_name;
            list[i].product_name[j].value = list[i].product_name[j].id;
          }
        }
        this.product_options = list;
      }
    });
     let token=JSON.parse(localStorage.getItem("token"));
      for(let i in token.role){
        if(token.role[i].permissions=='chief_human_list'){  //分配列表
          this.role.list_str=token.role[i].permissions_id_string;
          // this.role.is_fp=true;
        }
        if(token.role[i].permissions=='chief_human_edit'){
          this.role.is_fp=true;
          this.role.fpstr=token.role[i].permissions_id_string;
        }
      }
    this.getlist()

  },
  methods: {
    getlist(i, is_reload) {
      if (i) {
        this.page = 1;
      }
      if (is_reload) {
        this.compony_name = "";
        this.page = 1;
        this.product_name = "";
        this.Contract = "";
        this.Signer = "";
      }
      //  console.log(this.product_name);
      let product_name_id=''
      if(this.product_name.length>1){
         product_name_id=this.product_name[1]
      }
      ciCustomerList({
        permissions_id_string:this.role.list_str,

          type:this.isPersonal,
          customer:this.compony_name,
          // product_name_id:this.product_name,
          product_name_id,
          contract_number:this.Contract,
          signer:this.Signer,
          page:this.page
      }).then(res=>{
        //   console.log(res.data.data.data);
        this.tableData=res.data.data.data;
        this.total=res.data.data.total
      })
    },
    handleisPersonal(i){
        this.isPersonal=i;
        this.getlist(1)
        this.check_items=[]
    },
    fp(){
      if(JSON.stringify(this.human_check)=='{}'||this.state==''){
        this.$message.error('请先选择您要分配的人噢')
        return
      }
      this.$confirm("此操作将把您选择的"+this.check_items.length+"个客户分配给"+this.human_check.name+"是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(()=>{
        let haha=[];
        for(let i in this.check_items){
          haha.push(this.check_items[i].id)
        }
        ciAssignCustomer({
            permissions_id_string:this.role.fpstr,
            order_ids:JSON.stringify(haha),
            service_id:this.human_check.id
        }).then(res=>{
            if(res.data.code==200){
                 this.$message({
                    type: "success",
                    message: "分配成功",
                });
                // let h ={
                //     id:this.user_id,
                //     name:this.user_name
                // }
                this.shadow_show=false;
                this.human_check={};
                this.check_items=[];
                this.state=''
                // this.showshadow(h);
                this.getlist();
            }
        })
      }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消分配",
          });
        });
    },
    sentitem(item){
      this.shadow_show=true;
      this.check_items.push(item);
    },
    handleSelect(e){
      // console.log(e);
      this.human_check=e;
    },
    handleSelectionChange(item){
      // console.log(item);
        this.check_items=item;
    },
    handle_shadowshow(){
      if(this.check_items.length==0){
        this.$message.error('请先选择分配的客户噢~')
        return
      }
      this.shadow_show=true;
    },
    handleSizeChange(e){
    },
    handleCurrentChange(e){
        this.page=e;
        this.getlist()
    },
    querySearchAsync(str,cb){
        let data=[]
        ciSearch({
            permissions_id_string:this.role.fpstr,
            name:str
        }).then(res=>{
            for(let i in res.data.data){
                res.data.data[i].value=res.data.data[i].name+'【'+res.data.data[i].position.position+'】'
            }
            data=res.data.data
            cb(data)
        })
    },
  },
};
</script>

<style lang="scss">
.Customer_assignment_box {
  .Customer_assignment_info {
    background: #fff;
    padding: 20px;
    .serch_top {
      // height: 32px;
      display: flex;
      flex-wrap: wrap;
      .serch_one {
        display: flex;
        width: 23%;
        margin-left: 2%;
        white-space: nowrap;
        align-items: center;
        .el-input__inner {
          height: 32px;
          line-height: 32px;
        }
        .el-cascader {
          width: 100%;
        }
        .account_serch {
          width: 70px;
          height: 32px;
          background: #3a85fc;
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          border-radius: 2px;
        }
        .account_reload {
          width: 70px;
          height: 32px;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
          opacity: 1;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 20px;
          color: #666;
        }
      }
      .serch_one:nth-of-type(1) {
        display: flex;
        width: 23%;
        margin-left: 0%;
        white-space: nowrap;
        align-items: center;
      }
      .serch_one:nth-of-type(5) {
        display: flex;
        width: 23%;
        margin-left: 0%;
        white-space: nowrap;
        align-items: center;
        margin-top: 10px;
      }
    }
    .serch_fp {
      display: flex;
      justify-content: space-between;
      margin-top: 39px;
      .info_fir{
        width: 100px;
        height: 36px;
        background: #3A85FC;
        opacity: 1;
        border-radius: 4px;
        display: flex;
        color: #fff;
        align-items: center;
        justify-content: center;
        i{
            margin-right: 3px;
        }
      }
      .customer_header_check {
        width: 280px;
        height: 40px;
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        // margin-bottom: 30px;
        div {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
        }
        .isPersonal {
          background: #3a85fc;
          color: #fff;
        }
      }
    }
    .acc_table {
      margin-top: 20px;
      .el-pagination {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
      }
        .no_tk{
          display: flex;
          align-items: center;
        color: #FF2727;
        white-space: nowrap;
          div{
            color: #3A85FC;
             margin-left: 5px;
            width: 78px;
            height: 28px;
            background: #ECF5FF;
            border: 1px solid #C1DFFF;
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
          }
        }
      .acc_moreinfo{
          color: #3a85fc;
      }
    }
  }
   .Node_misson_shadow {
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    z-index: 2000;
     .el-pagination {
        margin-top: 10px;
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
      }
    .Node_shadow {
        height: 256px;
        overflow: hidden;
        width: 532px;
        background: #ffffff;
        border: 1px solid #707070;
        opacity: 1;
        border-radius: 10px;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin:auto;
      .Node_shadow_title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;
        position: relative;
        height: 55px;
        border-bottom: 1px solid #ccc;
      }
      .serch_input{
        width: 90%;
        margin: 0 auto;
        margin-top: 20px;
        .el-autocomplete{
          width: 100%;
        }
      }
      .fp_btn{
        margin: 0 auto ;
        margin-top: 84px;
        border-radius: 5px;
        width: 120px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: #3a85fc;
        
      }
      .left_add{
                margin-top: 20px;
                margin-left: 5%;
                display: flex;
                width: 208px;
                height: 35px;
                align-items: center;
                justify-content: center;
                color: #FFFFFF;
                border: 1px solid #ccc;
                box-sizing: border-box;
                div{
                    height: 100%;
                     color: #666;
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .act_title{
                    background: #3A85FC;
                    color: #fff;
                }
                border-radius: 4px;
                i{
                    margin-right: 5px;
                }
      }
      .Node_shadow_info{
          width: 90%;
          margin: 0 auto;
          margin-top: 10px;
        //   height: 300px;
          // .el-table__empty-block{
            //   height: 202px !important;
          // }
          
          .prodect_info{
              display: flex;
              align-items: center;
                .quan{
                    margin-right: 5px;
                    width: 6px;
                    height: 6px;
                    background: #42CB94;
                    border-radius: 50%;
                    opacity: 1;
                }
                .black{
                    background: #D6D6D6;
                }
          }
          .fpcheck{
              margin-top: 10px;
            //   width: 90%;
            //   margin: 0 auto;
              display: flex;
              align-items: center;
              height: 40px;
              padding-left: 15px;
              color: #333;
              // background: #ccc;
          }
          .serchbox{
              width: 100%;
              margin-top: 11px;
            .el-autocomplete{
                width: 100%;
            }
          }
         
      }
       .bottom_box_1{
            height: 70px;
            background: #FFFFFF;
            box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.05);
            opacity: 1;
            margin-top: 65px;
            border-radius: 0px 0px 10px 10px;
            display: flex;
            align-items: center;
            padding: 0 29px;
            justify-content: space-between;
            div:nth-of-type(2){
                width: 120px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                background: #3A85FC;
                border-radius: 5px;
            }
        }
      .info {
        padding: 20px;
        .info_i {
          margin-top: 10px;
          .el-input__inner {
            width: 380px;
          }
        }
        .info_i:nth-of-type(1) {
          margin-top: 0;
        }
        .info_last {
          width: 80px;
          height: 40px;
          background: #3a85fc;
          display: flex;

          justify-content: center;
          align-items: center;
          opacity: 1;
          border-radius: 4px;
          color: #fff;
        }
      }
    }
  }
}
</style>