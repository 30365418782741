<template>
  <div class="prodect_box" style="flex: 1">
    <div class="title">
      <div style="height: 70px; display: flex; align-items: center; border-bottom: 1px solid #eef5f9; padding: 0 20px">
        <div class="time_check pointer">
          <div
            v-for="(item, index) in time_arr"
            @click="change_timecheck(item.id)"
            :class="[check_time_id == item.id ? 'acc_time' : '']"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="serch_big_box">
        <div class="info">
          <span class="demonstration">产品：</span>
          <el-cascader
            placeholder="请选择产品名称"
            :options="product_arr"
            ref="myCascader"
            @change="handleProduct"
            :key="isproductShow"
            clearable
            collapse-tags
            :props="{ multiple: true }"
          ></el-cascader>
        </div>
        <div class="info">
          <span class="demonstration">部门：</span>
          <el-cascader
            ref="demonsCascader"
            :props="props"
            @change="handleClick"
            placeholder="请选择部门名称"
            :key="isResouceShow"
          ></el-cascader>
          <!-- <el-cascader
            placeholder="请选择部门名称"
            :options="options"
            ref="myCascader"
            @change="handleClick"
            :key="isResouceShow"
            clearable
            :props="{ checkStrictly: true }"
          ></el-cascader> -->
        </div>
        <div class="info">
          <span class="demonstration">销售：</span>
          <el-select filterable @change="changeSaleUser" v-model="saleUserId" placeholder="请选择销售">
            <el-option v-for="(item, index) in saleList" :key="index" :label="item.label" :value="item.value" v-if="item.is_sales == 1">
            </el-option>
          </el-select>
        </div>
        <div class="info">
          <span class="demonstration">年：</span>
          <el-date-picker v-model="year" :clearable="false" type="year" @change="changeyear" placeholder="选择年"> </el-date-picker>
        </div>
        <div class="info" v-if="check_time_id == 2">
          <span class="demonstration">季度：</span>
          <el-select @change="changequarter" v-model="quarter" placeholder="请选择季度">
            <el-option v-for="(item, index) in quarter_list" :key="index" :label="item.label" :value="item.id"> </el-option>
          </el-select>
        </div>
        <div class="info" v-if="check_time_id == 1">
          <span class="demonstration">月：</span>
          <el-select @change="changemonth" v-model="month" placeholder="请选择月份">
            <el-option v-for="(item, index) in month_options" :key="index" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </div>
        <div class="info">
          <div class="serch pointer" @click="serch">查询</div>
          <div class="reset pointer" @click="reset">重置</div>
        </div>
      </div>
    </div>
    <div class="big_box">
      <div class="left" id="left"></div>
      <div class="no_left" :style="left_data.length == 0 ? 'z-index:0' : 'z-index:-1'">
        <img src="../../assets/img/noPK.png" alt="" />
      </div>
      <div class="right">
        <el-table
          stripe
          ref="multipleTable"
          :data="table"
          tooltip-effect="dark"
          style="width: 100%"
          height="691"
          :header-cell-style="{ background: '#3A85FC', color: '#fff' }"
        >
          <!-- @selection-change="handleSelectionChange" -->
          <!-- <el-table-column
                type="selection"
                >
                </el-table-column> -->
          <!-- scope.row.customer_res ? scope.row.customer_res.name : "--" -->
          <el-table-column align="center" label="产品">
            <template slot-scope="scope">{{ scope.row.product_name ? scope.row.product_name : "--" }}</template>
          </el-table-column>
          <el-table-column prop="name" align="center" label="业绩(元)">
            <template slot-scope="scope">
              {{ scope.row.sum_money ? scope.row.sum_money : "--" }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="占比(%)">
            <template slot-scope="scope">{{ scope.row.proportion + "%" }}</template>
          </el-table-column>
        </el-table>
        <div class="btn_info" @click="getword">
          <div class="btn pointer">
            <i class="el-icon-printer"></i>
            导出
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { selectCompanyAll, select, userList } from "../../api/User/human";
import { productList } from "../../api/User/finance";
import { statistics, export_pro } from "../../api/User/data";
export default {
  data() {
    return {
      time_arr: [
        { id: 1, name: "月" },
        { id: 2, name: "季" },
        { id: 3, name: "年" },
      ],
      check_time_id: "1",
      table: [],
      left_data: [], //左侧数据
      isResouceShow: 1,
      options: [],
      month_options: [],
      month: "", //月份
      product_arr: [],
      search_company_id: "",
      isproductShow: 1,
      quarter: "", //季度
      year: "",
      quarter_list: [
        { label: "第一季度", id: "1" },
        { label: "第二季度", id: "2" },
        { label: "第三季度", id: "3" },
        { label: "第四季度", id: "4" },
      ],
      check_prodect: "",
      list_str: "",
      props: {
        lazy: true,
        lazyLoad: this.getDepartment,
        checkStrictly: true,
      },
      saleList: [],
      role_alldata: {},
      saleUserId: "",
    };
  },
  created() {
    let newdata = JSON.parse(localStorage.getItem("token"));
    let role_permissions = newdata.role_permissions;
    let newpower = {};
    for (let i in role_permissions) {
      if (role_permissions[i].permissions == "resources") {
        newpower = role_permissions[i];
        this.role_alldata = role_permissions[i];
      }
    }
  },
  mounted() {
    let token = JSON.parse(localStorage.getItem("token"));

    for (let i in token.role) {
      if (token.role[i].permissions == "prodect_analysis") {
        //分配列表
        this.list_str = token.role[i].permissions_id_string;
      }
    }
    for (let i = 1; i <= 12; i++) {
      this.month_options.push({ label: i + "月", value: i < 9 ? "0" + i : i });
    }
    this.year = new Date().getFullYear();
    var currQuarter = new Date().getMonth() + 1;
    var month = new Date().getMonth();
    if (month < 3) {
      this.quarter = "1";
    } else if (month < 6) {
      this.quarter = "2";
    } else if (month < 9) {
      this.quarter = "3";
    } else if (month < 12) {
      this.quarter = "4";
    }
    // console.log(month);
    this.month = month + 1;
    this.month = this.month < 9 ? "0" + this.month : this.month;
    this.year = this.year.toString();
    // select().then((res) => {

    //   if (res.data.code == 200) {
    //     function dg(list) {
    //       list.forEach((item) => {
    //         item.label = item.name;
    //         item.value = item.id;
    //         if (item.data) {
    //           item.children = item.data;
    //           dg(item.data);
    //         }
    //       });
    //       return list;
    //     }
    //     // let list = [];
    //     let list = res.data.data.data;
    //     // list.push(res.data.data);
    //     dg(list);
    //     this.options = list;
    //   }
    // });
    productList().then((res) => {
      // console.log(res.data);
      if (res.data.code == 200) {
        function dg(list) {
          list.forEach((item) => {
            item.label = item.class_name;
            item.value = item.id;
            if (item.product_name) {
              for (let i in item.product_name) {
                item.product_name[i].label = item.product_name[i].product_name;
                item.product_name[i].value = item.product_name[i].id;
              }
              item.children = item.product_name;
            }
          });
          return list;
        }
        let list = res.data.data;
        dg(list);
        this.product_arr = list;
      }
    });
    this.serch();
  },
  methods: {
    changeSaleUser() {},
    getDepartment(node, resolve) {
      let parent_id = node.value ? node.value : "";
      select({ parent_id }).then((res) => {
        if (res.data.code == 200) {
          let resData;
          if (parent_id) {
            resData = res.data.data;
          } else {
            resData = res.data.data.data;
          }
          let nodes = resData.map((item) => ({
            label: item.name,
            value: item.id,
            depth: item.depth,
          }));

          resolve(nodes);
        }
      });
    },
    handleClick(value) {
      // this.search_depth = this.$refs[
      // "myCascader"
      // ].getCheckedNodes()[0].data.depth;
      if (value.length == 0) {
        this.search_company_id = "";
        return;
      }
      this.search_company_id = value[value.length - 1];
      let str = "";
      for (let i in this.role_alldata.data) {
        if (this.role_alldata.data[i].permissions == "serch_userList") {
          str = this.role_alldata.data[i].permissions_id_string;
          continue;
        }
      }
      userList({
        company_id: value[value.length - 1],
        permissions_id_string: str,
        depth: this.$refs.demonsCascader.getCheckedNodes()[0].data.depth,
      }).then((res) => {
        this.saleList = res.data.data.data.map((item) => {
          return {
            label: item.name,
            id: item.id,
            is_sales: item.is_sales,
          };
        });
      });
    },
    change_timecheck(id) {
      //切换年月日
      this.check_time_id = id;
      this.serch();
    },
    geteachart() {
      let mytrend = this.$echarts.init(document.getElementById("left"));
      mytrend.resize();
      let data = [];
      for (let i in this.table) {
        if (this.table[i].proportion && this.table[i].proportion != 0) {
          data.push({
            name: this.table[i].product_name,
            value: this.table[i].sum_money,
            proportion: this.table[i].proportion,
          });
        }
      }
      this.left_data = data;
      //   data
      mytrend.setOption({
        title: {
          top: "5%",
          left: "center",
        },
        color: [
          "#FAC031",
          "#4274F5",
          "#11D0B4",
          "#A486F9",
          "#FF7D4C",
          "#eca3f5",
          "#fdbaf8",
          "#b0efeb",
          "#edffa9",
          "#98ddca",
          "#d5ecc2",
          "#ffd3b4",
          "#ffaaa7",
          "#91c788",
          "#bb8082",
          "#93329e",
          "#fbeeac",
          "#e40017",
          "#ff75a0",
          "#eaffd0",
          "#ff75a0",
          "#fce38a",
          "#eaffd0",
          "#c6a9a3",
          "#ccffbd",
          "#f4f9f9",
          "#ffb4b4",
          "#ffb4b4",
          "#f6f5f5",
          "#a98b98",
          "#f7f7e8",
          "#bee5d3",
          "#fdffbc",
          "#ffc1b6",
          "#58391c",
          "#c1a1d3",
          "#e7d9ea",
          "#e7d9ea",
          "#c9cbff",
          "#fdb827",
          "#beca5c",
          "#fdbaf8",
          "#f9f3f3",
          "#beca5c",
          "#4b778d",
          "#ff8882",
          "#ffcc29",
          "#f58634",
          "#d2e69c",
          "#f7f3e9",
          "#f05945",
        ],
        tooltip: {
          trigger: "item",
        },
        legend: {
          bottom: "5%",
          // orient: 'vertical',
          left: "center",
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: "50%",
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            label: {
              normal: {
                show: true,
                formatter: function (a) {
                  return a.name + " " + "【" + a.percent.toFixed(2) + "%" + "】";
                },
              },
            },
          },
        ],
      });
    },
    changeyear(e) {
      // console.log(this.year);
      // console.log(e);
      let heihei = this.getwaytime(e);
      this.year = heihei.toString();
      //   console.log(e);
      //   return;
      if (this.check_time_id == 1) {
        let year = "";
        if (this.year != "") {
          year = this.getwaytime(this.year);
        } else {
          year = "";
        }
      }
    },
    changequarter(e) {
      this.quarter = e;
    },
    changemonth(e) {
      this.month = e;
      return;
      if (this.check_time_id == 1) {
        let year = "";
        if (this.year != "") {
          year = this.getwaytime(this.year);
        } else {
          year = "";
        }
      }
    },
    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear();
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
      var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let strDate = Y;
      return strDate;
    },
    handleProduct(value) {
      if (value.length == 0) {
        this.check_prodect = "";
        return;
      } else {
        let str = "";
        for (let i in value) {
          str += value[i][1] + ",";
        }
        if (str.length > 0) {
          str = str.slice(0, str.length - 1);
          this.check_prodect = str;
        }
      }
    },
    reset() {
      this.isResouceShow++;
      this.isproductShow++;
      this.search_company_id = "";
      this.check_prodect = "";
      this.year = new Date().getFullYear();
      var currQuarter = new Date().getMonth() + 1;
      var month = new Date().getMonth();
      if (month < 3) {
        this.quarter = "1";
      } else if (month < 6) {
        this.quarter = "2";
      } else if (month < 9) {
        this.quarter = "3";
      } else if (month < 12) {
        this.quarter = "4";
      }
      // console.log(month);
      this.month = month + 1;
      this.month = this.month < 9 ? "0" + this.month : this.month;
      this.year = this.year.toString();
      this.serch();
    },
    serch() {
      let data = {};
      let array = [];
      if (this.check_prodect.length > 0) {
        array = this.check_prodect.split(",");
      }
      if (array.length == 1) {
        this.$message.error("请搜索两个及以上的产品噢~");
        return;
      }
      if (this.check_time_id == 1) {
        //月
        statistics({
          permissions_id_string: this.list_str,
          year: this.year,
          month: this.month,
          company_id: this.search_company_id,
          product_name_id: this.check_prodect,
          sale_user_id: this.saleUserId || "",
        }).then((res) => {
          this.table = res.data.data;
          this.geteachart();
        });
      } else if (this.check_time_id == 2) {
        //季度
        statistics({
          permissions_id_string: this.list_str,
          year: this.year,
          //  month:this.month,
          quarter: this.quarter,
          company_id: this.search_company_id,
          sale_user_id: this.saleUserId || "",
          product_name_id: this.check_prodect,
        }).then((res) => {
          //  console.log(res);
          this.table = res.data.data;
          this.geteachart();
        });
      } else if (this.check_time_id == 3) {
        //年
        statistics({
          permissions_id_string: this.list_str,
          year: this.year,
          //  month:this.month,
          // quarter:this.quarter,
          company_id: this.search_company_id,
          sale_user_id: this.saleUserId || "",
          product_name_id: this.check_prodect,
        }).then((res) => {
          //  console.log(res);
          this.table = res.data.data;
          this.geteachart();
        });
      }
      //   this.gettimeStatisticsChart(data);
    },
    getword() {
      if (this.check_time_id == 1) {
        //月
        export_pro({
          permissions_id_string: this.list_str,
          year: this.year,
          month: this.month,
          company_id: this.search_company_id,
          sale_user_id: this.saleUserId || "",
          product_name_id: this.check_prodect,
        }).then((res) => {
          //    console.log(res);
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = res.data.data;
          link.setAttribute("download", "courman" + ".xls");
          document.body.appendChild(link);
          link.click();
        });
      } else if (this.check_time_id == 2) {
        //季度
        export_pro({
          permissions_id_string: this.list_str,
          year: this.year,
          //  month:this.month,
          quarter: this.quarter,
          company_id: this.search_company_id,
          sale_user_id: this.saleUserId || "",
          product_name_id: this.check_prodect,
        }).then((res) => {
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = res.data.data;
          link.setAttribute("download", "courman" + ".xls");
          document.body.appendChild(link);
          link.click();
        });
      } else if (this.check_time_id == 3) {
        //年
        export_pro({
          permissions_id_string: this.list_str,
          year: this.year,
          //  month:this.month,
          // quarter:this.quarter,
          company_id: this.search_company_id,
          product_name_id: this.check_prodect,
          sale_user_id: this.saleUserId || "",
        }).then((res) => {
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = res.data.data;
          link.setAttribute("download", "courman" + ".xls");
          document.body.appendChild(link);
          link.click();
        });
      }
    },
  },
};
</script>

<style lang="scss">
.prodect_box {
  padding: 15px;
  // padding-right: 0;
  box-sizing: border-box;
  .title {
    background: #fff;
    width: 100%;
    // padding: 0 20px ;
    min-height: 140px;
    // display: flex;
    .time_check {
      width: 250px;
      height: 40px;
      display: flex;
      border-radius: 5px;
      border: 1px solid #ccc;
      div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #ccc;
      }
      div:nth-last-of-type(1) {
        border-right: 0px;
      }
      .acc_time {
        background: #3a85fc;
        color: #fff;
      }
    }
    .serch_big_box {
      width: 100%;
      padding: 20px;
      display: flex;
      flex-wrap: wrap;
      min-height: 70px;
      align-items: center;
      .info {
        width: 23%;
        // margin-top: 20px;
        margin-left: 2%;
        display: flex;
        white-space: nowrap;
        align-items: center;
        .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 100%;
        }
        .el-cascader__tags {
          flex-wrap: nowrap;
        }
        .demonstration {
          // margin-right: 10px;
          width: 50px;
          min-width: 42px;
        }
        .el-input__inner {
          height: 32px !important;
          line-height: 32px;
        }
        .el-input__icon {
          line-height: 34px;
        }
        .el-cascader {
          width: 100%;
        }
        .el-input .el-input--suffix {
          width: 100%;
          height: 32px !important;
          .el-input__inner {
            height: 32px !important;
          }
        }
        // .el-input {
        // width: 100%;
        //   width: 240px;el-input
        //   width: 210px;
        // }
        .serch {
          width: 70px;
          height: 32px;
          background: #3a85fc;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
        }
        .reset {
          width: 70px;
          height: 32px;
          background: #fff;
          border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
          color: rgba(0, 0, 0, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
          margin-left: 20px;
        }
      }
      .info:nth-of-type(1) {
        margin-left: 0;
      }
      .info:nth-of-type(5) {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }
  .big_box {
    width: 100%;
    height: 800px;
    margin-top: 10px;
    position: relative;
    .left {
      width: 58%;
      display: inline-block;
      background: #fff;
      height: 100%;
    }
    .no_left {
      display: inline-block;
      background: #fff;
      width: 58%;
      height: 100%;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
    }
    .right {
      width: 41%;
      margin-left: 1%;
      display: inline-block;
      background: #fff;
      max-height: 100%;
      height: 100%;
      padding: 20px;
      .btn_info {
        width: 100%;
        display: flex;
        justify-content: center;
        .btn {
          margin-top: 32px;
          width: 100px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          background: #3a85fc;
          opacity: 1;
          i {
            margin-right: 3px;
          }
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
