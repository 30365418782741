import Vue from "vue";
import axios from "axios";
import QS from "qs";
import router from "../router/index";
import api from "../api/Basicpath/index";
if (process.env.NODE_ENV !== "development") {
  axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
}
// 请求超时时间

axios.defaults.timeout = 60000;
axios.defaults.withCredentials = true;
axios.defaults.baseURL = "/api";
// post请求头
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
// axios.defaults.headers.post['Content-Type'] = 'application/json';
// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.data.code == 401) {
      localStorage.clear();
      Vue.prototype.$message({
        message: response.data.message,
        type: "error",
      });
      setTimeout(() => {
        location.reload();
      }, 1000);
      return;
    }
    if (response.data.code == 400) {
      Vue.prototype.$message({
        message: response.data.message,
        type: "error",
      });
      return;
    }
    if (response.status > 199 && response.status < 300) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    // if (error.response.status) {
    //   switch (error.response.status) {
    //     // 其他错误，直接抛出错误提示
    //     default:
    //     // Toast({
    //     //   message: error.response.data.message,
    //     //   duration: 1500,
    //     //   forbidClick: true
    //     // })
    //   }
    //   return Promise.reject(error.response);
    // }
  }
);

function error(res) {
  // console.log(res);
  return true;
}

function Get(url, params) {
  if (JSON.parse(localStorage.getItem("token"))) {
    let token = JSON.parse(localStorage.getItem("token")).token;
    axios.defaults.headers.get["token"] = token;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        if (error(res)) {
          resolve(res);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function Post(url, params) {
  if (JSON.parse(localStorage.getItem("token"))) {
    let token = JSON.parse(localStorage.getItem("token")).token;
    axios.defaults.headers.post["token"] = token;
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        if (error(res)) {
          resolve(res);
        } else {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

Vue.prototype.$axios = {
  get: Get,
  post: Post,
  axiosProperty: axios,
};

Vue.prototype.$request = axios;

export default { get: Get, post: Post, axiosProperty: axios };
