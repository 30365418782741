<template>
  <div class="customer">
    <el-main>
      <div class="customer_header">
        <div class="customer_header_check pointer" v-if="position != 1">
          <div :class="isPersonal == 1 ? 'isPersonal' : ''" @click="handleisPersonal(1)">我的私海</div>
          <div :class="isPersonal == 2 ? 'isPersonal' : ''" @click="handleisPersonal(2)">团队私海</div>
        </div>
        <div class="customer_show">
          <div :class="['customer_show_box', is_current == 0 ? 'well_customera' : 'well_customer']" @click="handleShow('0')">
            <img
              :src="is_current == 0 ? require('../../assets/img/well_customer_1.png') : require('../../assets/img/well_customer.png')"
              alt=""
            />
            <div class="customer_show_box_num">
              <div>{{ listTotal.expected_arrival_total }}</div>
              <div>有预计到账</div>
            </div>
          </div>
          <div @click="handleShow('1')" :class="['customer_show_box', is_current == 1 ? 'A_customera' : 'A_customer']">
            <img
              :src="is_current == 1 ? require('../../assets/img/A_customer_1.png') : require('../../assets/img/A_customer.png')"
              alt=""
            />
            <div class="customer_show_box_num">
              <div>{{ listTotal.a_total }}</div>
              <div>A类意向产品</div>
            </div>
          </div>
          <div @click="handleShow('2')" :class="['customer_show_box', is_current == 2 ? 'B_customera' : 'B_customer']">
            <img
              :src="is_current == 2 ? require('../../assets/img/B_customer_1.png') : require('../../assets/img/B_customer.png')"
              alt=""
            />
            <div class="customer_show_box_num">
              <div>{{ listTotal.b_total }}</div>
              <div>B类意向产品</div>
            </div>
          </div>
          <div :class="['customer_show_box', is_current == 3 ? 'C_customera' : 'C_customer']" @click="handleShow('3')">
            <img :src="is_current == 3 ? require('../../assets/img/C_customer_1.png') : require('../../assets/img/C_customer.png')" />
            <div class="customer_show_box_num">
              <div>{{ listTotal.wechat_total }}</div>
              <div>加微信客户</div>
            </div>
          </div>
          <div :class="['customer_show_box', is_current == 4 ? 'C_customera' : 'C_customer']" @click="handleShow('4')">
            <img
              :src="is_current == 4 ? require('../../assets/img/A_customer_xing.png') : require('../../assets/img/A_customer_xing02.png')"
            />
            <div class="customer_show_box_num">
              <div>{{ listTotal.is_star_count }}</div>
              <div>标星客户</div>
            </div>
          </div>
          <!--<div class="customer_show_box f_customer" v-if="isPersonal == 2">-->
          <!--<img src="../../assets/img/kr.png" alt="" class="img01"/>-->
          <!--<img src="../../assets/img/kr_1.png" alt="" class="img02"/>-->
          <!--<div class="customer_show_box_num">-->
          <!--<div>{{ have }}/{{ capacity }}</div>-->
          <!--<div>团队总库容</div>-->
          <!--</div>-->
          <!--</div>-->
          <!--<div class="customer_show_box D_customer" @click="handleShow('4')">-->
          <!--<img src="../../assets/img/D_customer.png" alt="" />-->
          <!--<div class="customer_show_box_num">-->
          <!--<div>{{ listTotal.d_total }}</div>-->
          <!--<div>D类意向产品</div>-->
          <!--</div>-->
          <!--</div>-->
        </div>
        <div class="customer_header_screen">
          <div class="customer_header_screen_box" style="margin: 0">
            <div class="customer_header_screen_box_tit">公司名称：</div>
            <div class="screen_input">
              <input type="text" v-model="customer_name" placeholder="请输入公司名称" style="padding-left: 15px" />
            </div>
          </div>
          <div class="customer_header_screen_box">
            <div class="customer_header_screen_box_tit">服务产品：</div>
            <div class="screen_input" style="border: 0">
              <el-cascader clearable v-model="product_name" :options="product_options" placeholder="请选择产品" separator=" - ">
              </el-cascader>
            </div>
          </div>

          <div class="customer_header_screen_box" v-if="isPersonal == 2">
            <div class="customer_header_screen_box_tit">跟进人：</div>
            <div class="screen_input">
              <input type="text" v-model="follow_name" placeholder="请输入跟进人名称" style="padding-left: 15px" />
            </div>
          </div>
          <div class="customer_header_screen_box">
            <div class="customer_header_screen_box_tit">跟进次数：</div>
            <div class="screen_input">
              <el-select v-model="follow_id" placeholder="请选择跟进次数">
                <el-option v-for="item in followoptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </div>
          </div>
          <div class="customer_header_screen_box">
            <div class="customer_header_screen_box_tit">跟进情况：</div>
            <div class="screen_input">
              <el-select v-model="follow_status" placeholder="请选择跟进情况">
                <el-option :label="'有跟进'" :value="1"> </el-option>
                <el-option :label="'无跟进'" :value="2"> </el-option>
              </el-select>
            </div>
          </div>
          <div class="customer_header_screen_box" v-if="isPersonal == 1">
            <div class="customer_header_screen_box_tit">客户建立时间：</div>
            <div class="screen_input" style="border: 0">
              <el-date-picker
                v-model="search_time"
                prefix-icon="saa"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="customer_header_screen" style="margin-top: 5px" v-if="isPersonal == 2">
          <div class="customer_header_screen_box" style="margin: 0">
            <div class="customer_header_screen_box_tit">客户建立时间：</div>
            <div class="screen_input" style="border: 0">
              <el-date-picker
                v-model="search_time"
                prefix-icon="saa"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="customer_header_screen_box" style="margin: 2%">
            <div class="customer_header_screen_box_tit">跟进部门：</div>
            <div class="screen_input" style="border: 0">
              <el-cascader
                placeholder="请选择部门名称"
                :options="depOptions"
                @change="changeDep"
                :key="isResouceShow"
                clearable
                :props="{ checkStrictly: true }"
              ></el-cascader>
            </div>
          </div>
          <div class="customer_header_screen_box" style="margin-left: 0px">
            <div class="customer_header_screen_box_tit">跟进时间：</div>
            <div class="screen_input" style="border: 0">
              <el-date-picker
                v-model="follow_time"
                prefix-icon="saa"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="customer_header_screen_box">
            <div class="customer_header_screen_box_tit">标签筛选：</div>
            <div class="screen_input" style="border: 0" @click="$refs.listTags.showTags()">
              <div class="tags-s-box">
                <div class="tags-s-list" v-for="(item, index) in listTags" :key="index">{{ item.title }}</div>
                <div v-if="listTags.length == 0" style="cursor: pointer">选择标签</div>
              </div>
            </div>
          </div>
        </div>

        <div class="customer_header_screen" v-if="isPersonal == 1">
          <div class="customer_header_screen_box" style="margin-left: 0px">
            <div class="customer_header_screen_box_tit">跟进时间：</div>
            <div class="screen_input" style="border: 0">
              <el-date-picker
                v-model="follow_time"
                prefix-icon="saa"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="customer_header_screen_box" v-if="isPersonal == 1">
            <div class="customer_header_screen_box_tit">标签筛选：</div>
            <div class="screen_input" style="border: 0" @click="$refs.listTags.showTags()">
              <div class="tags-s-box">
                <div class="tags-s-list" v-for="(item, index) in listTags" :key="index">{{ item.title }}</div>
                <div v-if="listTags.length == 0" style="cursor: pointer">选择标签</div>
              </div>
            </div>
          </div>
        </div>
        <customerTagsSwitch ref="listTags" :tag-list="tagList" @confirm="listTags = $event" v-if="tagList.length != 0" />
        <div class="customer_header_bottom">
          <div @click="handleScreen(0)">查询</div>
          <div @click="handleReset">重置</div>
        </div>
        <div class="customer_all">
          <div class="customer_all-btn">
            <div class="customer_add pointer" style="width: 220px" v-if="isPersonal == 1 && have < capacity" @click="handleAdd">
              <i class="el-icon-plus"></i>添加客户
            </div>

            <div class="export">
              <div class="export_button" @click="exporttable">
                <i class="el-icon-printer"></i>
                导出
              </div>
            </div>
            <div class="export">
              <div class="export_button" @click="onHandover">
                <i class="el-icon-printer"></i>
                交接
              </div>
            </div>
          </div>
          <div v-if="isPersonal == 1">库容 : {{ have }}/{{ capacity }}</div>

          <!--<div  v-if="isPersonal==2"> 客户总人数：{{ total }}人</div>-->
          <div v-if="isPersonal == 2">团队总库容：{{ have }}/{{ capacity }}</div>
        </div>
      </div>
      <div style="width: 99.9%">
        <el-table
          :data="tableData"
          border
          :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          ref="multipleTable"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="公司名称" width="250" :key="1">
            <template scope="scope">
              <img
                :src="
                  scope.row.my_customer_type == 1
                    ? require('../../assets/img/new.png')
                    : scope.row.my_customer_type == 2
                    ? require('../../assets/img/old.png')
                    : require('../../assets/img/yi.png')
                "
                class="new"
              />
              <img
                :src="
                  scope.row.wechat == '' || scope.row.wechat == null || scope.row.wechat == '[]'
                    ? require('../../assets/img/wx.png')
                    : require('../../assets/img/wx_ing.png')
                "
                class="wx"
              />
              <img
                :src="scope.row.is_star == '2' ? require('../../assets/img/xing01.png') : require('../../assets/img/xing02.png')"
                class="wxing"
              />
              <div class="gs_name">
                <div class="name">
                  {{ scope.row.customer_res ? scope.row.customer_res.name : "--" }}
                </div>
                <div class="time">
                  <div>
                    {{ scope.row.created_at_time }}
                  </div>
                  <div class="time_type time_type_b" v-if="scope.row.status != 3 && scope.row.intention == 2">
                    B类-{{ scope.row.time }}天
                  </div>
                  <div class="time_type time_type_a" v-if="scope.row.status != 3 && scope.row.intention == 1">
                    A类-{{ scope.row.time }}天
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="客户信息" width="270" :key="2">
            <template scope="scope">
              <div>
                <div>姓名：{{ scope.row.customer_contacts ? scope.row.customer_contacts.contacts : "--" }}</div>
                <div>手机号：{{ scope.row.customer_contacts ? scope.row.customer_contacts.phone : "--" }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="tag_list" label="客户标签" width="180">
            <template scope="scope">
              <div
                class="tags-s-box"
                @click="
                  $refs.listUpdate.showTags(scope.row.tag_ids);
                  indexTagInfo = scope.row;
                "
              >
                <div class="tags-s-list" style="margin-left: 0; margin-top: 5px" v-for="(item, index) in scope.row.tag_list" :key="index">
                  {{ item }}
                </div>
                <div class="tags-s-list" v-if="scope.row.tag_list.length == 0">请选择标签</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="意向产品" :key="3">
            <template scope="scope">
              <div style="display: flex; align-items: center">
                <div class="pro_box" v-for="(item, index) in scope.row.my_customer_product" :key="index">
                  <i :class="item.status == 2 ? 'pro_well' : ''"></i>
                  {{ item.product_name.product_name }}
                  <!--                - -->
                  <!--                {{-->
                  <!--                  item.status == 2-->
                  <!--                    ? "已成交"-->
                  <!--                    : item.intention == 1-->
                  <!--                    ? "A - "-->
                  <!--                    : item.intention == 2-->
                  <!--                    ? "B - "-->
                  <!--                    : item.intention == 3-->
                  <!--                    ? "C - "-->
                  <!--                    : "D - "-->
                  <!--                }}-->
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="跟进人" width="160" v-if="isPersonal == 2" :key="4">
            <template scope="scope">
              <div>
                <div>
                  {{ scope.row.my_user ? scope.row.my_user.name : "--" }}
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="created_at" label="客户建立时间" :key="7" width="170"> </el-table-column>
          <el-table-column prop="created_at" label="客户状态" :key="10" width="120">
            <template scope="scope">
              {{ scope.row.is_handover == 1 ? "交接" : scope.row.is_handover == 2 ? "创建" : "领取" }}
            </template>
          </el-table-column>
          <el-table-column :label="isPersonal == 1 ? '跟进管理' : '最新跟进时间'" width="170" key="11">
            <template scope="scope">
              <i></i>
              <div class="customer_cz pointer" style="display: flex">
                <div @click="add_plan(scope.row)" v-if="isPersonal == 1" style="color: #3a85fc">新增</div>
                <div v-if="isPersonal == 2">
                  {{ scope.row.my_customer_log ? scope.row.my_customer_log.trail_time : "--" }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="跟进次数" width="80" key="12">
            <template scope="scope">
              <div>
                <div>
                  {{ scope.row.follow_num }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="成交管理" width="120" v-if="isPersonal == 1" :key="8">
            <template scope="scope">
              <i></i>
              <div class="customer_cz pointer" style="display: flex">
                <div @click="add_cx_deal(scope.row, 1)">到账</div>
                <span>|</span>
                <div @click="add_cx_deal(scope.row, 2)">促销</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150" fixed="right">
            <template scope="scope">
              <i></i>
              <div class="customer_cz pointer" style="display: flex">
                <div @click="handleClickcz(scope.row, 1)">跟进记录</div>

                <div @click="handleClickcz(scope.row, 2)">详情</div>

                <!--<div @click="handleClickcz(scope.row, 3)">业绩</div>-->
                <!--<span>|</span>-->
                <!-- <div @click="handleClickcz(scope.row, 4)">订单</div> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          el-pagination
          @size-change="handleScreen(0)"
          :page-size.sync="pageSize"
          layout="total,prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 50, 100]"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-main>
    <div class="login_mc" v-if="isMc"></div>

    <Addcustomer v-if="isaddcustomer" @Close="handleClose" />

    <div class="Follow_box" v-if="isFollow">
      <div class="Follow_box_info">
        <div class="Follow_head">
          跟进记录
          <i class="el-icon-close pointer" style="padding: 10px" @click="handleclosePlan"></i>
        </div>
        <div class="Follow_info">
          <div class="Follow_tit"><i></i>客户信息</div>
          <div class="Follow_customer_info">
            <div class="Follow_customer_info_box" style="min-height: 100px">
              <div class="Follow_customer_info_box_line">
                <div class="Follow_customer_info_box_line_one">
                  <div class="Follow_customer_info_box_line_one_tit">公司名称：</div>
                  <div class="Follow_customer_info_box_line_one_info">
                    {{ customerHead_info.customer_res ? customerHead_info.customer_res.name : "--" }}
                  </div>
                </div>
                <div class="Follow_customer_info_box_line_one">
                  <div class="Follow_customer_info_box_line_one_tit">客户名称：</div>
                  <div class="Follow_customer_info_box_line_one_info">
                    {{ customerHead_info.customer_contacts ? customerHead_info.customer_contacts.contacts : "--" }}
                  </div>
                </div>
                <div class="Follow_customer_info_box_line_one">
                  <div class="Follow_customer_info_box_line_one_tit">手机号：</div>
                  <div class="Follow_customer_info_box_line_one_info">
                    {{ customerHead_info.customer_contacts ? customerHead_info.customer_contacts.phone : "--" }}
                  </div>
                </div>
              </div>
              <div class="Follow_customer_info_box_line">
                <div class="Follow_customer_info_box_line_one">
                  <div class="Follow_customer_info_box_line_one_tit">产品意向：</div>
                  <div class="Follow_customer_info_box_line_one_info" style="display: flex; flex-wrap: wrap">
                    <div v-for="(item, index) in customerHead_info.my_customer_product" :key="index" style="margin-right: 10px">
                      {{ item.product_name.product_name }}
                      <!-- - -->
                      <!--{{-->
                      <!--item.status == 2-->
                      <!--? "已成交"-->
                      <!--: item.intention == 1-->
                      <!--? "A - "-->
                      <!--: item.intention == 2-->
                      <!--? "B - "-->
                      <!--: item.intention == 3-->
                      <!--? "C - "-->
                      <!--: "D - "-->
                      <!--}}<span v-if="item.status != 2">{{-->
                      <!--item.time + "天"-->
                      <!--}}</span>-->
                    </div>
                  </div>
                </div>
              </div>

              <div class="Follow_customer_info_box_line">
                <div class="Follow_customer_info_box_line_one">
                  <div class="Follow_customer_info_box_line_one_tit">产品备注：</div>
                  <div class="Follow_customer_info_box_line_one_info">
                    <span v-for="(item, index) in customerHead_info.my_customer_product" :key="index">{{ item.customer_analysis }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-for="(item, index) in follow_List" :key="index" class="Follow_tit_box">
            <div class="Follow_tit"><i></i>{{ index == 0 ? "近期跟进记录" : "往期跟进记录" }}</div>
            <div
              class="Follow_customer_info"
              style="padding-bottom: 40px; box-shadow: 0px 0px 5px 3px rgb(196 196 196 / 10%); margin: 0 auto"
              v-if="follow_List.length > 0"
            >
              <div class="Follow_customer_info_plan">
                <div class="Follow_customer_info_plan_line">
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">跟进产品：</div>
                    <div class="Follow_customer_info_plan_line_one_info">
                      <div style="display: flex; align-items: center; flex-wrap: wrap">
                        <div v-for="(itm, index) in item.log_product" :key="index">
                          {{ itm.product_plan_name.product_name }}
                        </div>
                      </div>
                    </div>
                    <div class="tx_follow" style="margin-left: 30px">
                      <div
                        class="pointer"
                        style="width: 60px; box-sizing: border-box; justify-content: center"
                        @click="handlecomment(item, 1)"
                      >
                        促进
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Follow_customer_info_plan_line" v-if="item.is_valid != 0">
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">是否有效：</div>
                    <div class="Follow_customer_info_plan_line_one_info">
                      <div style="display: flex; align-items: center; flex-wrap: wrap">
                        {{ item.is_valid == 1 ? "有效" : "无效" }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Follow_customer_info_plan_line">
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">计划跟进时间：</div>
                    <div class="Follow_customer_info_plan_line_one_info">
                      {{ item.trail_time }}
                    </div>
                    <div class="Follow_customer_info_plan_line_one_time">
                      {{ item.time }}
                    </div>
                  </div>
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">跟进方式：</div>
                    <div class="Follow_customer_info_plan_line_one_info">
                      {{
                        item.status == 1
                          ? "电话"
                          : item.status == 2
                          ? "微信"
                          : item.status == 3
                          ? "出访"
                          : item.status == 4
                          ? "来访"
                          : item.status == 5
                          ? "其他"
                          : ""
                      }}
                    </div>
                  </div>
                </div>

                <div class="Follow_customer_info_plan_line">
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">跟进小记：</div>
                    <div class="Follow_customer_info_plan_line_one_info" style="white-space: initial">
                      <p style="display: flex; word-wrap: break-word; word-break: normal">{{ item.result }}</p>
                    </div>
                  </div>
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">跟进目的：</div>
                    <div class="Follow_customer_info_plan_line_one_info">
                      {{ item.follow_up_type == 1 ? "服务" : "签约" }}
                    </div>
                  </div>
                </div>
                <div class="Follow_customer_info_plan_line">
                  <div class="Follow_customer_info_plan_line_one" v-if="item.address">
                    <div class="Follow_customer_info_plan_line_one_tit">地址：</div>
                    <div class="Follow_customer_info_plan_line_one_info" style="white-space: initial">
                      {{ item.address }}
                    </div>
                  </div>
                </div>
                <div class="Follow_customer_info_plan_line" v-if="item.file_url && item.file_url.length > 0">
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">图片展示：</div>
                    <div class="Follow_customer_info_plan_line_one_img">
                      <img alt="" v-for="(itm, idx) in item.file_url" :key="idx" :src="itm.file_url" @click="take_big(itm.file_url)" />
                    </div>
                  </div>
                </div>
                <div class="Follow_customer_info_plan_line" v-show="item.comment.length > 0" v-for="(itm, idx) in item.comment" :key="idx">
                  <div class="Follow_customer_info_plan_line_one">
                    <div class="Follow_customer_info_plan_line_one_tit">{{ itm.user_name.name }}促进：</div>
                    <div class="Follow_customer_info_plan_line_one_info" style="white-space: initial">
                      {{ itm.comment }}
                    </div>
                  </div>
                </div>
                <div class="Follow_customer_info_plan_Follow_customer_info_plan_line" style="margin-top: 10px">
                  <!--<div class="Follow_customer_info_plan_line_buttom">-->
                  <!--&lt;!&ndash; <div class="Follow_customer_info_plan_del">删除</div> &ndash;&gt;-->
                  <!--&lt;!&ndash;                    <div&ndash;&gt;-->
                  <!--&lt;!&ndash;                      class="Follow_customer_info_plan_change"&ndash;&gt;-->
                  <!--&lt;!&ndash;                      @click="handleFillPlan(item)"&ndash;&gt;-->
                  <!--&lt;!&ndash;                      v-if="&ndash;&gt;-->
                  <!--&lt;!&ndash;                        !item.plan_log && item.plan_type != 1 && isPersonal == 1&ndash;&gt;-->
                  <!--&lt;!&ndash;                      "&ndash;&gt;-->
                  <!--&lt;!&ndash;                    >&ndash;&gt;-->
                  <!--&lt;!&ndash;                      填写结果&ndash;&gt;-->
                  <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
                  <!--<div-->
                  <!--class="Follow_customer_info_plan_change"-->
                  <!--@click="handleChange(item)"-->
                  <!--v-if="!item.plan_log && isPersonal == 1"-->
                  <!--&gt;-->
                  <!--修改-->
                  <!--</div>-->
                  <!--<div-->
                  <!--class="Follow_customer_info_plan_change"-->
                  <!--@click="handlecomment(item, 1)"-->
                  <!--v-if="isPersonal == 2"-->
                  <!--&gt;-->
                  <!--促进-->
                  <!--</div>-->
                  <!--</div>-->
                </div>
              </div>
            </div>
          </div>
          <div class="Follow_none" v-if="follow_List.length == 0">暂无跟进记录</div>
        </div>
      </div>
    </div>

    <!-- 修改计划 -->
    <div class="acc_shadow" v-if="show_flag">
      <div class="change_info">
        <div class="take_new_title">
          修改计划
          <i
            class="el-icon-close pointer"
            style="color: #999999"
            @click="
              show_flag = false;
              isMore = false;
              isFollow = true;
            "
          ></i>
        </div>
        <div class="take_new_detail">
          <div class="acc_top_compony">
            <div class="get_detail">
              <div class="flow_kind">
                跟进方式：
                <el-select v-model="flow_kind" placeholder="请选择">
                  <el-option v-for="item in prodect_list" :key="item.name" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </div>
              <div class="flow_kind">
                跟进日期：
                <el-date-picker v-model="check_time" type="datetime" placeholder="选择日期时间"> </el-date-picker>
              </div>
              <div class="flow_kind_text">
                跟进计划：
                <el-input type="textarea" :rows="3" placeholder="请输入跟进计划" v-model="flow_plan"> </el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="take_bottom">
          <div class="bottom_sent pointer" @click="sent_new_plan">保存</div>
        </div>
      </div>
    </div>

    <!-- 填写结果 -->
    <div class="acc_shadow" v-if="notes_show">
      <div class="info">
        <div class="take_new_title">
          填写跟进结果
          <i class="el-icon-close pointer" style="color: #999999" @click="(notes_show = false), (isFollow = true)"></i>
        </div>
        <div class="take_new_detail">
          <div class="acc_top_compony">
            <div class="kf_detail">
              <div class="quan"></div>
              客户信息
            </div>
            <div class="kh_detail" v-if="customerHead_info.customer_res">
              <div>公司名称：{{ customerHead_info.customer_res.name }}</div>
              <div>客户名称：{{ customerHead_info.customer_res.contacts }}</div>
              <div>手机号：{{ customerHead_info.customer_res.phone }}</div>
              <div>
                产品意向：<span v-for="(item, index) in customerHead_info.my_customer_product" :key="index" style="margin-right: 10px"
                  >{{ item.product_name.product_name }}
                  <!-- - -->
                  <!--{{-->
                  <!--item.status == 2-->
                  <!--? "已成交"-->
                  <!--: item.intention == 1-->
                  <!--? "A - "-->
                  <!--: item.intention == 2-->
                  <!--? "B - "-->
                  <!--: item.intention == 3-->
                  <!--? "C - "-->
                  <!--: "D - "-->
                  <!--}}<span v-if="item.status != 2">{{-->
                  <!--item.time + "天"-->
                  <!--}}</span>-->
                </span>
              </div>
            </div>
            <div class="get_detail">
              <div class="flow_kind">
                跟进方式：{{
                  info_detail.status == 1
                    ? "电话"
                    : info_detail.status == 2
                    ? "微信"
                    : info_detail.status == 3
                    ? "出访"
                    : info_detail.status == 4
                    ? "来访"
                    : "其他"
                }}
              </div>

              <div class="flow_kind_text" style="width: 100%; align-items: flex-start">
                跟进计划：
                <div style="white-space: break-spaces">
                  {{ info_detail.plan }}
                </div>
              </div>
              <div class="flow_kind">
                跟进时间：
                <el-date-picker
                  v-model="flow_time"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
              <div class="flow_kind" v-if="info_detail.status == 3">
                是否陪访：
                <el-radio v-model="is_tegether" label="1">是</el-radio>
                <el-radio v-model="is_tegether" label="2">否</el-radio>
              </div>
              <div class="flow_kind" v-if="is_tegether == 1">
                <span style="margin-right: 14px">陪访人：</span>
                <el-select v-model="flow_human" placeholder="请选择">
                  <el-option v-for="item in flow_human_list" :key="item.name" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </div>
              <div class="flow_kind" v-if="is_tegether == 1">
                <span style="margin-left: -14px">陪访人姓名：</span>
                <el-input placeholder="请输入陪访人姓名" v-model="tegether_name"> </el-input>
              </div>
              <div class="flow_kind" v-if="info_detail.status == 3 || info_detail.status == 4">
                <span>见面目的：</span>
                <el-select v-model="objective" placeholder="请选择">
                  <el-option v-for="item in objective_list" :key="item.name" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </div>
              <div class="flow_kind" v-if="info_detail.status == 3">
                <span style="margin-left: 28px">地址：</span>
                <el-input placeholder="请输入地址" v-model="address"> </el-input>
              </div>

              <div class="flow_kind_text">
                <span style="margin-top: 6px">跟进结果：</span>
                <el-input type="textarea" placeholder="请输入跟进结果" v-model="plan_end" rows="3" maxlength="1000" show-word-limit>
                </el-input>
              </div>
              <div class="flow_kind_text" v-if="info_detail.status == 2 || info_detail.status == 3 || info_detail.status == 4">
                <span style="margin-top: 6px">图片展示：</span>
                <div class="flow_img_listinfo">
                  <div class="img_item_info" v-for="(item, index) in img_list" :key="index">
                    <img :src="item.file_url" alt="" class="big_img pointer" @click="take_big(item.file_url)" />
                    <i class="el-icon-error del_img pointer" @click="del_img(index)"></i>
                  </div>
                  <div class="image_info pointer" @click="chooseFile" v-if="img_list.length < 2">
                    <img src="@/assets/img/upload_img.png" alt="" style="width: 28px; height: 25px" />
                    <span>请上传图片</span>
                    <input type="file" style="display: none" ref="choosePhoto" id="upload_file" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="take_bottom">
          <div class="bottom_sent pointer" @click="sent_flow">保存</div>
        </div>
      </div>
    </div>
    <!-- 促进 -->
    <div class="acc_shadow" v-if="commen_show">
      <div class="acc_commen">
        <div class="acc_commen_title">
          促进
          <i class="el-icon-close pointer" style="color: #999999" @click="commen_show = false"></i>
        </div>
        <div class="acc_commen_body">
          <el-input type="textarea" :rows="3" maxlength="150" placeholder="请输入促进内容" v-model="commen"> </el-input>
        </div>
        <div class="acc_commen_sent pointer" @click="sent_commen">确定</div>
      </div>
    </div>
    <!-- 放大图片 -->
    <div class="acc_img_shadow" v-if="big_imgshow" @click="big_imgshow = false">
      <img :src="imgsrc" alt="" />
    </div>
    <!-- <Bmap /> -->

    <!-- 新增计划 -->
    <div class="info_add" v-if="add_plan_show">
      <div class="take_new_title">
        新增跟进记录
        <i
          class="el-icon-close pointer"
          style="color: #999999"
          @click="
            add_plan_show = false;
            isMc = false;
            trail_time = '';
            follow_up_type = '';
            add_flow_kind = '';
            result = '';
          "
        ></i>
      </div>
      <div class="take_new_detail">
        <div class="acc_top_compony">
          <div class="kf_detail" style="font-weight: bold; font-size: 16px">
            <div class="quan"></div>
            客户信息
          </div>
          <div class="kh_detail">
            <div style="margin-top: 0">公司名称：{{ add_plan_row.customer_res.name }}</div>
            <div style="margin-top: 0">客户名称：{{ add_plan_row.customer_contacts.contacts }}</div>
            <div>手机号：{{ add_plan_row.customer_contacts.phone }}</div>
            <div>产品意向：{{ add_plan_row.flow_pro }}</div>
          </div>
          <div class="get_detail" style="padding-left: 11px; margin-top: 30px; font-size: 16px">
            <div class="kf_detail" style="font-weight: bold">
              <div class="quan"></div>
              本次跟进信息
            </div>
            <div class="flow_kind">
              <span style="color: #ff2727">*</span> 跟进产品：
              <!--<div v-for="(item, index) in productsarr" :key="index" style="width: 500px">-->
              <!--<div class="add_cutomer_box_info" style="width: 500px">-->
              <!--<el-cascader-->
              <!--:props="props"-->
              <!--v-model="item.product_name_id"-->
              <!--:options="prooptions"-->
              <!--placeholder="请选择产品"-->
              <!--separator=" - "-->
              <!--@visible-change="visiblechange"-->
              <!--@change="handleChanges($event, 2)"-->
              <!--clearable-->
              <!--&gt;-->
              <!--</el-cascader>-->
              <!--<i-->
              <!--v-if="productsarr.length > 1"-->
              <!--class="el-icon-remove-outline pointer"-->
              <!--style="color: #ccc; font-size: 19px; margin-left: 30px"-->
              <!--@click="handleDelpro(index)"-->
              <!--&gt;</i>-->
              <!--</div>-->
              <!--<div>-->
              <!--</div>-->
              <!--</div>-->
              <div style="width: 500px">
                <el-select v-model="product_ids" multiple placeholder="请选择产品">
                  <el-option v-for="item in prooptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </div>
            </div>
            <div class="flow_kind">
              <span style="color: #ff2727">*</span>
              跟进时间：
              <el-date-picker v-model="trail_time" type="datetime" placeholder="选择日期时间" :picker-options="pickerOptionss">
              </el-date-picker>
            </div>
            <div class="flow_kind">
              <span style="color: #ff2727">*</span>
              跟进目的：
              <el-select v-model="follow_up_type" placeholder="请选择">
                <el-option v-for="item in gjobjective_list" :key="item.name" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </div>
            <div class="flow_kind">
              <span style="color: #ff2727">*</span>
              跟进方式：
              <el-select v-model="add_flow_kind" placeholder="请选择">
                <el-option v-for="item in prodect_list" :key="item.name" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </div>
            <div class="flow_kind_text" v-if="add_flow_kind == 2 || add_flow_kind == 3 || add_flow_kind == 4">
              <span style="margin-top: 6px"><span style="color: #ff2727">*</span>图片展示：</span>
              <div class="flow_img_listinfo">
                <div class="img_item_info" v-for="(item, index) in img_list" :key="index">
                  <img :src="item.file_url" alt="" class="big_img pointer" @click="take_big(item.file_url)" />
                  <i class="el-icon-error del_img pointer" @click="del_img(index)"></i>
                </div>
                <div class="image_info pointer" @click="chooseFile" v-if="img_list.length < 2">
                  <img src="@/assets/img/upload_img.png" alt="" style="width: 28px; height: 25px" />
                  <span>请上传图片</span>
                  <input type="file" style="display: none" ref="choosePhoto" id="upload_file" />
                </div>
              </div>
            </div>
            <div class="flow_kind" v-if="add_flow_kind == 3 || add_flow_kind == 4">
              <span style="color: #ff2727">*</span>地址：
              <input placeholder="请输入出访地址" class="form_input" v-model="address" />
            </div>
            <div class="flow_kind_text">
              <span style="color: #ff2727">*</span>
              跟进小结：
              <el-input type="textarea" :rows="3" placeholder="请输入跟进小结" v-model="result"> </el-input>
            </div>
          </div>
          <div class="get_detail" style="padding-left: 11px; margin-top: 30px; font-size: 16px">
            <div class="kf_detail" style="font-weight: bold">
              <div class="quan"></div>
              本次跟进结果
            </div>
            <div class="flow_kind">
              <span style="color: #ff2727">*</span>
              跟进结果：
              <el-radio-group v-model="follow_up_results">
                <el-radio :label="1">有预计</el-radio>
                <el-radio :label="2">无预计，再跟</el-radio>
                <el-radio :label="3">放弃</el-radio>
                <el-radio :label="4">已签约</el-radio>
              </el-radio-group>
            </div>
            <template v-if="follow_up_results == 1">
              <div class="flow_kind">
                <span style="color: #ff2727">*</span>
                预计计划：
                <el-checkbox-group v-model="plan">
                  <el-checkbox :label="1">计划面访</el-checkbox>
                  <el-checkbox :label="2">预计到账</el-checkbox>
                  <el-checkbox :label="3">客户要方案</el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="flow_kind">
                <span style="color: #ff2727">*</span>
                跟进时间：
                <el-date-picker v-model="add_check_time" type="datetime" placeholder="选择日期时间" :picker-options="pickerOptionss_jh">
                </el-date-picker>
              </div>
              <div class="flow_kind">
                <span style="color: #ff2727">*</span>
                跟进方式：
                <el-select v-model="expected_status" placeholder="请选择">
                  <el-option v-for="item in expected_list" :key="item.name" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </div>
              <div class="flow_kind" v-if="plan.includes(2)">
                <span style="color: #ff2727">*</span>
                预计到账：
                <input placeholder="请输入到账金额（元）" class="form_input" v-model="expected_arrival" type="number" />
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="take_bottom">
        <div class="bottom_sent pointer" @click="add_new_plan">保存</div>
      </div>
    </div>

    <!-- 促销申请 -->
    <div class="add_cx_bigbox" v-if="is_CX">
      <div class="add_cx_box">
        <div class="add_box_head">
          促销申请
          <i class="el-icon-close pointer" @click="handleclose_Apply" style="pading: 10px"></i>
        </div>
        <div class="add_cx_box_info">
          <div class="add_cx_box_head"><i></i>公司信息</div>
          <div class="add_cx_box_customer">
            <div class="add_cx_box_customer_line">
              <div class="add_cx_box_customer_line_tit">公司名称：</div>
              <div class="add_cx_box_customer_line_int">
                {{ add_plan_row.customer_res ? add_plan_row.customer_res.name : "--" }}
              </div>
            </div>
            <div class="add_cx_box_customer_line">
              <div class="add_cx_box_customer_line_tit">客户姓名：</div>
              <div class="add_cx_box_customer_line_int">
                {{ add_plan_row.customer_res ? add_plan_row.customer_contacts.contacts : "--" }}
              </div>
            </div>
            <div class="add_cx_box_customer_line">
              <div class="add_cx_box_customer_line_tit">电话：</div>
              <div class="add_cx_box_customer_line_int">
                {{ add_plan_row.customer_res ? add_plan_row.customer_contacts.phone : "--" }}
              </div>
            </div>
          </div>
          <div class="add_cx_box_head"><i></i>申请信息</div>
          <div class="add_cx_box_customer">
            <div class="add_cx_box_customer_line">
              <div class="add_cx_box_customer_line_tit">产品类型：</div>
              <el-select v-model="cx_proname_id" placeholder="请选择促销产品">
                <el-option v-for="item in cx_prooption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </div>
            <div class="add_cx_box_customer_line">
              <div class="add_cx_box_customer_line_tit">促销类型：</div>
              <el-select v-model="cx_typeid" placeholder="请选择促销类型">
                <el-option v-for="item in cx_arr" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </div>
            <div class="add_cx_box_customer_line" v-if="cx_typeid == 4">
              <div class="add_cx_box_customer_line_tit">服务时长：</div>
              <el-date-picker
                v-model="time_section"
                type="daterange"
                prefix-icon="sdal"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
            <div class="add_cx_box_customer_line" style="align-items: flex-start; height: 84px" v-if="cx_typeid == 5">
              <div class="add_cx_box_customer_line_tit">赠送服务：</div>
              <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" placeholder="请输入内容" v-model="service_info"> </el-input>
            </div>
            <div class="add_cx_box_customer_line">
              <div class="add_cx_box_customer_line_tit">促销成本：</div>
              <el-input type="text" v-model="apply_money" placeholder="请输入促销成本" />
            </div>
            <div class="add_cx_box_customer_line">
              <div class="add_cx_box_customer_line_tit">政策类型</div>
              <el-select v-model="cx_zc_typeid" placeholder="请选择政策类型">
                <el-option v-for="item in cx_zc_type" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </div>
            <div class="add_cx_box_customer_line" style="align-items: flex-start">
              <div class="add_cx_box_customer_line_tit">申请说明：</div>
              <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" placeholder="请输入内容" v-model="apply_explain"> </el-input>
            </div>
          </div>
          <div class="add_cx_box_head" style="margin-top: 80px"><i></i>抄送人</div>
          <div class="add_cx_box_customer">
            <div class="add_cx_box_customer_line" style="padding: 10px 0px">
              <div class="add_cx_box_customer_line_tit">
                {{ apply_chaosong ? apply_chaosong.name : "" }}
              </div>
              <div class="add_cx_box_customer_line_img">
                <img src="../../assets/img/more_head.png" alt="" />
                <!-- <i class="el-icon-arrow-right"></i>
                  <img :src="user.head_portrait" alt="" /> -->
              </div>
            </div>
          </div>
          <div class="add_cx_box_bottom">
            <div @click="handleclose_Apply">取消</div>
            <div @click="handle_apply">确认</div>
          </div>
        </div>
      </div>
    </div>
    <AddArrival ref="my_AddArrival" @close="handleClose_AddArrival" :detail="add_plan_row" v-if="isAddArrival" />
    <!--领取-->
    <el-dialog title="交接" :visible.sync="centerDialogVisible" width="620px" center>
      <div class="handover">
        <div>
          <h3>已选择：</h3>
          <div class="handover_l">
            <div class="handover_list">
              <h2>
                已选择客户<span>({{ this.multipleSelection.length }}个)</span>
              </h2>
              <ul>
                <li v-for="(item, index) in multipleSelection" :key="index">
                  {{ item.customer_res ? item.customer_res.name : "--" }}
                  <img src="../../assets/img/close.png" @click="onDel(index, item)" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <h3>选择交接人：</h3>
          <div class="handover_r">
            <div class="search_input">
              <!--<img src="../../assets/img/search.png">-->
              <!--<input placeholder="搜索姓名"/>-->
              <div class="serchbox">
                <el-autocomplete
                  v-model="state"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入(名字+【部门】)"
                  @select="handleSelect"
                ></el-autocomplete>
              </div>
            </div>
            <div class="handover_b">
              库容 (<span>{{ haveer }}</span
              >/{{ capacityer }})
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="onSub">确 定</el-button>
      </span>
    </el-dialog>
    <customerTagsSwitch ref="listUpdate" :tag-list="tagList" @confirm="updateList" v-if="tagList.length != 0" />
  </div>
</template>

<script>
import Bmap from "./Bmap";
import Add_Arrival from "./Add_Arrival";
import { distributionUserList } from "@/api/User/quit";
import Add_customer from "./Add_customer";
import customerTagsSwitch from "./components/customerTagsSwitch.vue";
import {
  listTotal,
  listCustmer,
  getProductList,
  customerHead,
  followList,
  editPlan,
  addPlan,
  abandon,
  getProduct,
  addResult,
  addPlanLog,
  getPromotionVerify,
  searchCompanysList,
  addPromotion,
  customerListTags,
  listExport,
  editCustomerTag,
} from "../../api/User/customer";
import { userCustomerCapacity, intentionHandover } from "../../api/User/data";
import { addComment } from "../../api/User/account";
import { uploadFile } from "../../api/User/index";

export default {
  components: {
    Addcustomer: Add_customer,
    Bmap: Bmap,
    AddArrival: Add_Arrival,
    customerTagsSwitch,
  },
  data() {
    return {
      role: {
        is_fp: false,
        fp_str: "",
        list_str: "",
      },
      target_id: "", //交接人id
      centerDialogVisible: false, //交接弹窗
      state: "", //搜索分配人
      multipleSelection: [], //选中的交接客户
      have: "", //已有客户数量
      capacity: "", //最大客户数量
      haveer: 0, //他的已有客户数量
      capacityer: 0, //他的最大客户数量
      followoptions: [
        { value: 1, label: "1次" },
        { value: 2, label: "2次" },
        { value: 3, label: "3次" },
        { value: 4, label: "4次及以上" },
      ],
      follow_id: "", //跟进次数
      follow_status: "",
      product_ids: [],
      have: "",
      capacity: "",
      follow_up_type: "", //跟进目的
      result: "", //小结
      follow_up_results: 1, //跟进结果
      trail_time: "", //时间
      expected_status: "", //跟进方式
      expected_arrival: "", //预计到账
      plan: [], //预计计划
      props: { multiple: true },
      prooptions: [], //产品列表
      //产品
      productsarr: [
        {
          product_name_id: [],
          intention: "",
          customer_analysis: "",
        },
      ],
      position: "", //职位
      isMc: false, //蒙层
      show_flag: false, //修改计划  新增计划
      isPersonal: 1, //1  个人 2 部门
      customer_name: "", //客户名称
      product_options: [], //搜索产品列表
      product_name: "", //搜索产品名
      intention_options: [
        { value: "1", label: "新客户" },
        { value: "2", label: "老客户" },
        { value: "3", label: "意向客户" },
        // { value: "4", label: "D" },
      ], //意向
      intention: "", //意向 搜素     id
      search_time: "",
      follow_name: "", //跟进人
      isResouceShow: 0,
      search_company_id: "",
      depOptions: [], //筛选部门
      tableData: [],
      total: 0, //列表总数量
      currentPage: 1, //页码
      isaddcustomer: false, //是否新增客户
      isFollow: false, //跟进记录
      listTotal: {}, //个人团队 总客户数量
      customerHead_info: null, //用户头部信息
      follow_List: [], //用户跟进记录
      prodect_list: [
        { id: 1, name: "电话" },
        { id: 2, name: "微信" },
        { id: 3, name: "出访" },
        { id: 4, name: "来访" },
        { id: 5, name: "其他" },
      ],
      expected_list: [
        // {id: 1, name: "面访"},
        { id: 2, name: "来访" },
        { id: 3, name: "出访" },
        { id: 4, name: "网签" },
      ],
      //跟进目的
      purpose: "",
      gjobjective_list: [
        { id: 1, name: "服务" },
        { id: 2, name: "签约" },
      ],
      check_time: "", //修改计划 时间
      flow_plan: "", //计划详情
      flow_kind: "", //计划方式
      plan_id: "", //计划id

      commen_show: false, //促进
      commen: "", //促进结果
      commen_item: {}, //
      permissions_id_string: "",
      is_expected_arriva: "",
      notes_show: false,
      plan_end: "", //跟进结果
      img_list: [],
      info_detail: null,
      imgsrc: "", // 放大图片路径
      big_imgshow: false, //放大图片显示隐藏
      flow_time: "", //跟进时间
      is_tegether: "2", //是否配方人
      flow_human: "", //跟进
      flow_human_list: [
        { id: 1, name: "经理陪访" },
        { id: 2, name: "其他陪访" },
      ], //跟进人职位陪访
      tegether_name: "", //配方人姓名
      objective: "",
      objective_list: [
        { id: 1, name: "洽谈签约" },
        { id: 2, name: "客户服务" },
        { id: 3, name: "要转介绍" },
        { id: 4, name: "邀请参会" },
        { id: 5, name: "送达礼物" },
        { id: 6, name: "其它" },
      ],
      address: "",
      add_flag: false,

      //新增计划
      add_plan_show: false,
      add_plan_row: {},
      //  跟进时间
      pickerOptionss: {
        disabledDate(time) {
          // return time.getTime() > Date.now() - 24 * 60 * 60 * 1000

          return time.getTime() <= Date.now() - 24 * 60 * 60 * 1000 * 3 || time.getTime() > Date.now();
        },
      },
      //计划时间
      pickerOptionss_jh: {
        disabledDate(time) {
          const curDate = new Date().getTime();
          const day = 6 * 24 * 3600 * 1000;
          const dateRegion = curDate + day;
          return time.getTime() < Date.now() - 8.64e7 || time.getTime() > dateRegion;
          // return time.getTime() < Date.now() - 8.64e7 ||
          //     time.getTime() > dateRegion
          // return time.getTime() > Date.now()+ 12*24 * 60 * 60 * 1000;
        },
      },
      to_time: "", //跟进时间
      add_flow_kind: "", //跟进方式
      add_check_time: "", //跟进时间
      add_flow_plan: "", //跟进计划

      //促销申请      //add_plan_row 可以公用一下
      is_CX: false,
      cx_prooption: [], //申请促销产品列表
      cx_proname_id: "", //促销产品id
      cx_arr: [
        { value: 1, label: "实物" },
        { value: 2, label: "返现" },
        { value: 3, label: "打折" },
        { value: 4, label: "赠送服务时长" },
        { value: 5, label: "赠送其它服务" },
        { value: 6, label: "其他" },
      ], //促销类型
      cx_typeid: "", //促销类型id
      apply_money: "", //促销金额
      apply_explain: "", //促销说明
      apply_chaosong: null, //促销申请抄送人
      cx_zc_type: [
        { value: 1, label: "政策性促销" },
        { value: 2, label: "非政策性促销" },
      ],
      cx_zc_typeid: "", //政策类型
      time_section: "", //服务区间
      service_info: "", //服务详情
      //新增到账
      isAddArrival: false,
      is_expected_arrival: "",
      is_current: "8",
      is_star: "", //标星客户
      pageSize: 10,
      follow_time: [],
      tagList: [],
      listTags: [],
      indexTagInfo: null,
    };
  },
  created() {
    let token = JSON.parse(localStorage.getItem("token"));
    this.position = token.position_type;
    for (let i in token.role_permissions) {
      if (token.role_permissions[i].permissions == "Marketing") {
        for (let v in token.role_permissions[i].data) {
          if (token.role_permissions[i].data[v].permissions == "Marketing_Account") {
            this.permissions_id_string = token.role_permissions[i].data[v].permissions_id_string; //促进str
          }
        }
      }
    }

    let obj = {};
    let total_type = "";
    if (localStorage.getItem("isPersonal") && localStorage.getItem("currentPage")) {
      this.isPersonal = localStorage.getItem("isPersonal");
      total_type = localStorage.getItem("isPersonal");
      this.currentPage = localStorage.getItem("currentPage") * 1;
      obj = {
        type: localStorage.getItem("isPersonal"),
        page: localStorage.getItem("currentPage"),
      };
      if (JSON.parse(localStorage.getItem("token")).position_type == 1) {
        this.isPersonal = 1;
        this.currentPage = 1;
        total_type = 1;
      }
    } else {
      this.currentPage = 1;
      this.isPersonal = "1";
      total_type = 1;
      obj = {
        type: 1,
        page: 1,
      };
    }
    listTotal({ type: total_type }).then((res) => {
      if (res.data.code == 200) {
        this.listTotal = res.data.data;
      }
    });
    if (this.isPersonal == 2) {
      searchCompanysList().then((res) => {
        if (res.data.code == 200) {
          function dg(list) {
            list.forEach((item) => {
              item.label = item.name;
              item.value = item.id;
              if (item.data) {
                item.children = item.data;
                dg(item.data);
              }
            });
            return list;
          }

          let list = res.data.data;
          dg(list);
          this.depOptions = list;
        }
      });
    }

    listCustmer(obj).then((res) => {
      //客户列表
      let list = res.data.data.data;
      list.forEach((item) => {
        item.created_at_time = item.created_at.split(" ")[0];
      });
      this.tableData = list;
      this.total = res.data.data.total;
    });

    getProductList().then((res) => {
      if (res.data.code == 200) {
        let list = res.data.data;
        for (let i = 0; i < list.length; i++) {
          list[i].label = list[i].class_name;
          list[i].value = list[i].id;
          list[i].children = list[i].product_name;
          for (let j = 0; j < list[i].product_name.length; j++) {
            list[i].product_name[j].label = list[i].product_name[j].product_name;
            list[i].product_name[j].value = list[i].product_name[j].id;
          }
        }
        this.product_options = list;
      }
    });
    if (this.isPersonal === null) {
      this.isPersonal = "1";
    }
    this.getCapacity(this.isPersonal);

    customerListTags({ num: 99 }).then((res) => {
      this.tagList = res.data.data.list;
    });
  },
  methods: {
    updateList(data) {
      console.log(data, "修改", this.indexTagInfo);
      let idArr = data.map((e) => {
        return e.id;
      });
      this.tableData = [];
      editCustomerTag({
        customer_id: this.indexTagInfo.customer_id,
        tag_ids: idArr,
      })
        .then((res) => {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.handleScreen();
        })
        .catch((e) => {
          this.handleScreen();
        });
    },
    getdealList(data) {
      dealList(data).then((res) => {
        let list = res.data.data.data;
        list.forEach((item) => {
          item.created_at_time = item.created_at.split(" ")[0];
        });
        this.tableData = list;
        this.total = res.data.data.total;
      });
    },
    visiblechange(bool) {
      return;
      if (bool) {
        let { productsarr } = this;
        let arr = this.prooptions;

        for (let i = 0; i < productsarr.length; i++) {
          if (productsarr[i].product_name_id.length > 0) {
            //选中产品
            console.log(productsarr[i].product_name_id);
            console.log(productsarr[i].product_name_id[0]);
            console.log(productsarr[i].product_name_id[1]);
            for (let j = 0; j < arr.length; j++) {
              console.log(arr[j]);
              if (arr[j].id == productsarr[i].product_name_id[0]) {
                console.log(arr[j]);
                console.log(arr[j].class_name);
                for (let k = 0; k < arr[j].children.length; k++) {
                  console.log(arr[j].children[k].product_name);
                  arr[j].children[k].disabled = false;
                  if (arr[j].children[k].id == productsarr[i].product_name_id[1]) {
                    console.log(arr[j].children[k].product_name, "222222222");
                    arr[j].children[k].disabled = true;
                  }
                }
              } else {
                for (let k = 0; k < arr[j].children.length; k++) {
                  console.log(arr[j].children[k].product_name);
                  arr[j].children[k].disabled = false;
                  if (arr[j].children[k].id == productsarr[i].product_name_id[1]) {
                    arr[j].children[k].disabled = true;
                  }
                }
              }
            }
          }
        }
        console.log(arr);
        this.prooptions = [...arr];
        // this.productsarr.forEach(item=>{
        //   console.log(item);
        //   if (item.product_name_id.length>0) {
        //     console.log(item.product_name_id[1]);
        //     arr.forEach(itm=>{
        //       if (itm.id==item.product_name_id[0]) {
        //         itm.children.forEach(attr=>{
        //           attr.disabled = false
        //           if (attr.id==item.product_name_id[1]) {
        //             console.log(attr);
        //             attr.disabled = true
        //           }
        //         })
        //         console.log(itm.children);
        //       }
        //     })
        //   }
        // })
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val);
    },
    //删除
    onDel(index, rows) {
      if (this.multipleSelection.length <= 1) {
        this.$message.error("请选择一个或以上客户后交接噢~");
        return;
      }
      this.multipleSelection.splice(index, 1);

      this.$refs.multipleTable.toggleRowSelection(rows);
    },
    onSub() {
      var customer_ids = this.multipleSelection.map((item) => {
        return item.id;
      });
      this.$confirm("此操作将勾选的" + this.multipleSelection.length + "个客户分配给" + this.check_item.name + "是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          intentionHandover({
            customer_ids: customer_ids.join(","),
            handover_user_id: this.target_id,
            type: this.isPersonal,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "交接成功",
              });
              this.handleScreen(this.currentPage);
              this.state = "";
              this.centerDialogVisible = false;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消分配",
          });
          this.centerDialogVisible = false;
          this.state = "";
        });
    },
    //交接弹窗
    onHandover() {
      if (this.multipleSelection.length < 1) {
        this.$message.error("请选择一个或以上客户后交接噢~");
        return;
      }
      this.centerDialogVisible = true;
    },
    //取消
    onCancel() {
      this.centerDialogVisible = false;
      this.state = "";
    },
    querySearchAsync(str, cb) {
      let data = [];
      distributionUserList({
        permissions_id_string: this.role.list_str,
        name: str,
        all: 1,
      }).then((res) => {
        for (let i in res.data.data) {
          res.data.data[i].value = res.data.data[i].name + "【" + res.data.data[i].position.position + "】";
        }
        // console.log(res);
        data = res.data.data;
        cb(data);
      });
    },
    handleSelect(val) {
      let { contract_fd_list } = this;
      contract_fd_list.forEach((item) => {
        if (item.user_name == val.value) {
          item.user_id = val.user_id;
        }
      });
    },
    //分配人选择
    handleSelect(item) {
      this.check_item = item;
      console.log(item);
      this.target_id = item.id;
      //    扩容
      userCustomerCapacity({
        type: 1,
        target_id: item.id,
      }).then((res) => {
        this.haveer = res.data.data.have;
        this.capacityer = res.data.data.capacity;
      });
      console.log(this.haveer);
      if (Number(this.multipleSelection) + Number(this.haveer) > this.capacityer) {
        this.$message.error("已超过最大库容！");
      }
    },
    handleDelpro(i) {
      //删除产品
      if (this.productsarr.length < 2) {
        return;
      }
      this.productsarr.splice(i, 1);
    },
    getlistCustmer(data) {
      listCustmer(data).then((res) => {
        //客户列表
        let list = res.data.data.data;
        list.forEach((item) => {
          item.created_at_time = item.created_at.split(" ")[0];
        });
        this.tableData = list;
        this.total = res.data.data.total;
      });
    },
    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
      var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let strDate = Y + M + D;
      // let strDate = Y + M + D + h + m + '00';
      return strDate;
    },
    getwaytime_s(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // let strDate = Y + M + D;
      let strDate = Y + M + D + h + m + "00";
      return strDate;
    },
    handleisPersonal(i) {
      //部门个人切换
      this.follow_name = "";
      this.search_company_id = "";
      this.customer_name = "";
      this.product_name = "";
      this.intention = "";
      this.search_time = "";
      this.follow_id = "";
      this.follow_status = "";
      localStorage.setItem("isPersonal", i);
      localStorage.setItem("currentPage", 1);
      this.isPersonal = i;
      listTotal({ type: i }).then((res) => {
        if (res.data.code == 200) {
          this.listTotal = res.data.data;
        }
      });
      this.tableData = [];
      this.getlistCustmer({
        type: i,
      });
      this.getCapacity(i);
      if (i == 2) {
        searchCompanysList().then((res) => {
          if (res.data.code == 200) {
            function dg(list) {
              list.forEach((item) => {
                item.label = item.name;
                item.value = item.id;
                if (item.data) {
                  item.children = item.data;
                  dg(item.data);
                }
              });
              return list;
            }

            let list = res.data.data;
            dg(list);
            this.depOptions = list;
          }
        });
        //    扩容
        this.getCapacity(i);
      }
    },
    handleScreen(p) {
      //查询
      // console.log(this.search_company_id);
      // p = 0 为查询 其他为翻页 带上查询条件
      let search_product_id = "";
      if (this.product_name == "") {
        search_product_id = "";
      } else {
        search_product_id = this.product_name[1];
      }
      let search_start_time = "";
      let search_end_time = "";
      if (!this.search_time) {
        this.search_time = "";
      }
      if (this.search_time != "") {
        search_start_time = this.getwaytime(this.search_time[0]);
        search_end_time = this.getwaytime(this.search_time[1]);
      }
      let follow_start_time = "";
      let follow_end_time = "";
      if (!this.follow_time) {
        this.follow_time = "";
      }
      if (this.follow_time != "") {
        follow_start_time = this.getwaytime(this.follow_time[0]);
        follow_end_time = this.getwaytime(this.follow_time[1]);
      }
      let page = 1;
      if (p == 0) {
        page = 1;
      } else {
        page = p;
      }
      // 标签
      let tag_ids = "";
      if (this.listTags.length != 0) {
        let arr = [];
        for (let i in this.listTags) {
          arr.push(this.listTags[i].id);
        }
        tag_ids = arr.join(",");
      }
      console.log(this.isPersonal);
      let data = {
        type: this.isPersonal,
        search_name: this.customer_name,
        search_intention: this.intention,
        is_star: this.is_star,
        search_start_time,
        search_end_time,
        follow_start_time,
        follow_end_time,
        search_product_id,
        is_expected_arrival: this.is_expected_arrival,
        follow_name: this.follow_name,
        search_company_id: this.search_company_id,
        follow_id: this.follow_id, //跟进次数
        tag_ids,
        page,
        num: this.pageSize,
        follow_status: this.follow_status,
      };
      // console.log(data);
      this.getlistCustmer(data);
    },
    changeDep(value) {
      //部门筛选
      if (value.length > 0) {
        this.search_company_id = value[value.length - 1];
      } else {
        this.search_company_id = "";
      }
    },
    handleReset() {
      //重置
      ++this.isResouceShow;
      this.search_company_id = "";
      this.customer_name = "";
      this.product_name = "";
      this.search_time = "";
      this.intention = "";
      this.follow_name = "";
      this.follow_id = "";
      this.follow_status = "";
      this.listTags = "";
      this.getlistCustmer({ type: this.isPersonal });
    },
    exporttable() {
      //导出
      let search_product_id = "";
      if (this.product_name == "") {
        search_product_id = "";
      } else {
        search_product_id = this.product_name[1];
      }
      let search_start_time = "";
      let search_end_time = "";
      if (!this.search_time) {
        this.search_time = "";
      }
      if (this.search_time != "") {
        search_start_time = this.getwaytime(this.search_time[0]);
        search_end_time = this.getwaytime(this.search_time[1]);
      }
      let data = {
        type: this.isPersonal,
        search_name: this.customer_name,
        search_intention: this.intention,
        is_star: this.is_star,
        search_start_time,
        search_end_time,
        search_product_id,
        follow_name: this.follow_name,
        search_company_id: this.search_company_id,
      };
      listExport(data).then((res) => {
        if (res.data.code == 200) {
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = res.data.data;
          link.setAttribute("download", "courman" + ".xls");
          document.body.appendChild(link);
          link.click();
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
            center: true,
          });
          return;
        }
      });
    },
    handleShow(i) {
      if (this.is_current == i) {
        this.is_current = "8";
      } else {
        this.is_current = i;
      }
      if (this.is_current == 0) {
        this.is_expected_arrival = "is_expected_arrival";
      } else {
        this.is_expected_arrival = "";
      }
      console.log(this.is_current);
      if (this.is_current == 1 || this.is_current == 2 || this.is_current == 3) {
        this.intention = i;
        this.is_star = "";
      } else if (this.is_current == 4) {
        this.is_star = 2;
      } else {
        this.intention = "";
        this.is_star = "";
      }

      // if(this.is_current==8){
      //     this.search_intention=''
      //     this.is_expected_arrival=''
      // }
      //客户总数记录处点击事件
      // this.intention = i;
      this.handleScreen(0);
    },
    handleAdd() {
      this.isaddcustomer = true;
      this.isMc = true;
    },
    handleClose() {
      this.isaddcustomer = false;
      this.isMc = false;
      this.getlistCustmer({
        type: this.isPersonal,
        page: this.currentPage,
      });
    },
    handleclosePlan() {
      this.isFollow = false;
      this.isMc = false;
    },
    handleChange(plan) {
      this.isFollow = false;
      this.show_flag = true;
      this.flow_plan = plan.plan;
      this.flow_kind = plan.status;
      this.check_time = plan.to_time;
      this.plan_id = plan.id;
    },
    handleChanges(e, i) {
      let { cityList, prooptions } = this;
      if (i == 1) {
        //城市
        cityList.forEach((item) => {
          if (item.id == e[0]) {
            this.province_name = item.name;
          }
        });
        // console.log(e);
        getCity({ type: 2, parent_id: e[0] }).then((res) => {
          let list = res.data.data;
          list.forEach((item) => {
            if (item.id == e[1]) {
              this.city_name = item.name;
            }
          });
        });
        getCity({ type: 3, parent_id: e[1] }).then((res) => {
          let list = res.data.data;
          list.forEach((item) => {
            if (item.id == e[2]) {
              this.area_name = item.name;
            }
          });
        });
      }
      if (i == 2) {
        let arr = this.prooptions;
        arr.forEach((item) => {
          if (item.id == e[0]) {
            item.children.forEach((itm) => {
              itm.disabled = false;
              if (itm.id == e[1]) {
                itm.disabled = true;
              }
            });
          } else {
            item.children.forEach((itm) => {
              itm.disabled = false;
              if (itm.id == e[1]) {
                itm.disabled = true;
              }
            });
          }
        });
        this.prooptions = [...arr];
      }
    },
    handlecomment(item, i) {
      console.log(item);
      //促进
      if (i == 2) {
        //列表进入
        if (item.my_customer_log) {
          this.commen_item = item.my_customer_log;
        } else {
          this.$message({
            message: "暂无跟进记录",
            type: "error",
          });
          return;
        }
      } else {
        //跟进记录进入
        this.commen_item = item;
      }
      this.commen = "";
      this.commen_show = true;
    },
    sent_commen() {
      //填写促进结果
      if (this.add_flag == true) {
        return;
      }
      this.add_flag = true;
      console.log(this.commen_item);
      addComment({
        permissions_id_string: this.permissions_id_string,
        my_customer_log_id: this.commen_item.id,
        comment: this.commen,
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "促进成功",
              type: "success",
            });
            followList({ my_customer_id: this.commen_item.my_customer_id }).then((res) => {
              this.follow_List = res.data.data.data;
            });

            this.commen_show = false;
            this.commen = "";
            this.add_flag = false;
          }
        })
        .catch(() => {
          this.add_flag = false;
        });
    },
    handleFillPlan(item) {
      //填写跟进结果
      this.flow_time = ""; //跟进时间
      this.is_tegether = "2"; //是否配方人
      this.flow_human = ""; //跟进
      this.tegether_name = ""; //配方人姓名
      this.objective = "";
      this.address = "";
      this.plan_end = "";
      this.info_detail = item;
      this.isFollow = false;
      this.notes_show = true;
    },
    sent_new_plan() {
      let { plan_id, flow_plan, flow_kind, check_time, customerHead_info } = this;
      let arr = [];
      this.product_ids.forEach((item) => {
        let obj = {
          product_name_id: item,
        };
        arr.push(obj);
      });
      let data = {
        plan_id,
        status: flow_kind,
        my_customer_id: customerHead_info.id,
        to_time: this.getwaytime_s(check_time),
        estimate_plan: flow_plan.join(","),
        product_ids: JSON.stringify(arr),
      };
      editPlan(data).then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.show_flag = false;
          this.isFollow = true;
          followList({ my_customer_id: customerHead_info.id }).then((ress) => {
            let list = ress.data.data.data;
            this.follow_List = list;
          });
        }
      });
    },
    sent_flow() {
      let { info_detail } = this;
      let trail_time = "";
      let trail_end_time = "";
      if (this.flow_time) {
        trail_time = this.getwaytime_s(this.flow_time[0]);
        trail_end_time = this.getwaytime_s(this.flow_time[1]);
      }
      let file_url = [];
      for (let i in this.img_list) {
        file_url.push({ file_url: this.img_list[i].file_url });
      }
      file_url = JSON.stringify(file_url);

      let data = {
        my_customer_id: info_detail.my_customer_id,
        customer_plan_id: info_detail.id,
        status: info_detail.status,
        result: this.plan_end,
        trail_time,
        trail_end_time,
        file_url,
        visit_status: this.is_tegether,
        visit_people: this.flow_human,
        purpose: this.objective, // purpose
        visit_name: this.tegether_name,
        address: this.address,
      };
      addPlanLog(data).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          this.isFollow = true;
          this.notes_show = false;
          followList({ my_customer_id: info_detail.my_customer_id }).then((ress) => {
            let list = ress.data.data.data;
            this.follow_List = list;
          });
        }
      });
    },
    handleClickcz(row, i) {
      //操作
      //1 跟进记录  2详情 3 业绩  4 订单
      if (i == 1) {
        customerHead({ my_customer_id: row.id }).then((res) => {
          this.customerHead_info = res.data.data;
          this.isMc = true;
          this.isFollow = true;
        });
        followList({ my_customer_id: row.id }).then((res) => {
          let list = res.data.data.data;
          this.follow_List = list;
        });
      }
      if (i == 2) {
        this.$router.push({
          name: "customerdetail",
          params: {
            my_customer_id: row.id,
            type: this.isPersonal,
            customer_id: row.customer_id,
          },
        });
      }
      if (i == 3) {
        this.$router.push({
          name: "achievement",
          params: {
            my_customer_id: row.id,
            type: this.isPersonal,
            customer_id: row.customer_id,
          },
        });
      }
      if (i == 4) {
        this.$router.push({
          name: "ordercenter",
          params: {
            my_customer_id: row.id,
            type: this.isPersonal,
            customer_id: row.customer_id,
          },
        });
      }
    },
    add_plan(row) {
      //新增计划栏操作
      this.add_plan_show = true;
      this.isMc = true;
      let text = "";
      let arr = [];
      row.my_customer_product.forEach((item) => {
        if (item.status == 2) {
          text = " - 已成交";
        } else {
          text =
            item.intention == 1
              ? " - A - " + item.time + "天"
              : item.intention == 2
              ? " - B - " + item.time + "天"
              : item.intention == 3
              ? " - C - " + item.time + "天"
              : item.intention == 4
              ? " - D - " + item.time + "天"
              : "";
        }
        arr.push(item.product_name.product_name + text);
        row.flow_pro = arr.join("、");
      });
      this.add_plan_row = row;
      this.add_flow_kind = "";
      this.add_check_time = "";
      this.add_flow_plan = "";
      console.log(row);
      if (row.wechat == "" || row.wechat == null) {
        this.prodect_list = [
          { id: 1, name: "电话" },
          { id: 3, name: "出访" },
          { id: 4, name: "来访" },
          { id: 5, name: "其他" },
        ];
      } else {
        this.prodect_list = [
          { id: 1, name: "电话" },
          { id: 2, name: "微信" },
          { id: 3, name: "出访" },
          { id: 4, name: "来访" },
          { id: 5, name: "其他" },
        ];
      }
      //获取产品列表
      this.prooptions = row.my_customer_product.map((item) => {
        return {
          value: item.product_name.id,
          label: item.product_name.product_name,
        };
      });
      // getProduct({
      //   name: row.customer_res.name,
      //   phone: row.customer_contacts.phone
      // }).then((res) => {
      //   if (res.data.code == 200) {
      //     let list = res.data.data;
      //     for (let i = 0; i < list.length; i++) {
      //       list[i].label = list[i].class_name;
      //       list[i].value = list[i].id;
      //       list[i].children = list[i].product_name;
      //       for (let j = 0; j < list[i].product_name.length; j++) {
      //         list[i].product_name[j].label = list[i].product_name[j].product_name;
      //         list[i].product_name[j].value = list[i].product_name[j].id;
      //       }
      //     }
      //     this.prooptions = list;
      //     console.log()
      //   }
      // });
    },
    add_new_plan() {
      //新增计划
      if (this.add_flag) {
        return;
      }
      let {
        add_plan_row,
        add_flow_kind, //跟进方式
        add_check_time, //跟进时间
        expected_arrival,
        expected_status,
        to_time,
        plan,
        follow_up_results,
        address,
        result,
        status,
        follow_up_type,
        trail_time,
      } = this;
      let file_url = [];
      for (let i in this.img_list) {
        file_url.push({ file_url: this.img_list[i].file_url });
      }
      file_url = JSON.stringify(file_url);
      let arr = [];
      this.product_ids.forEach((item) => {
        let obj = {
          product_name_id: item,
        };
        arr.push(obj);
      });
      if (arr == [] || trail_time == "" || add_flow_kind == "" || follow_up_type == "" || result == "") {
        this.$message({
          message: "请填写必填内容",
          type: "error",
        });
        return;
      }
      if (follow_up_results == 1) {
        if (add_check_time == [] || expected_status == "" || plan == "") {
          this.$message({
            message: "请填写必填内容",
            type: "error",
          });
          return;
        } else if (this.plan.includes(2) && expected_arrival == "") {
          this.$message({
            message: "请填写预计到账",
            type: "error",
          });
          return;
        }
      }
      if (add_flow_kind == 3 || add_flow_kind == 4) {
        if (this.img_list.length == 0 || address == "") {
          this.$message({
            message: "请填写必填内容",
            type: "error",
          });
          return;
        }
      }
      if (add_flow_kind == 2) {
        if (this.img_list.length == 0) {
          this.$message({
            message: "请填写必填内容4",
            type: "error",
          });
          return;
        }
      }
      this.add_flag = true;
      let data = {
        my_customer_id: add_plan_row.id,
        status: add_flow_kind,
        to_time: this.getwaytime_s(add_check_time),
        product_ids: JSON.stringify(arr),
        expected_arrival,
        expected_status,
        estimate_plan: this.plan.join(","),
        follow_up_results,
        address,
        result,
        file_url,
        follow_up_type,
        trail_time: this.getwaytime_s(trail_time),
      };
      if (follow_up_results == 3) {
        this.$confirm("放弃跟进，该客户会流入事业部公海，\n" + "确定要放弃吗？", "确认放弃", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            abandon({
              my_customer_id: add_plan_row.id,
            }).then((res) => {
              this.$message({
                message: res.data.message,
                type: "success",
              });
              this.isMc = false;
              this.add_plan_show = false;
              this.handleScreen(this.currentPage);
              this.clear();
              this.add_flag = false;
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
            this.add_flag = false;
          });
      } else {
        addResult(data).then((res) => {
          this.add_flag = false;
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.isMc = false;
            this.add_plan_show = false;
            this.handleScreen(this.currentPage);
            this.clear();
          }
        });
      }
    },
    clear() {
      this.add_plan_row = {};
      this.add_flow_kind = "";
      this.add_check_time = "";
      this.productsarr = "";
      (this.expected_arrival = ""),
        (this.expected_status = ""),
        (this.plan = []),
        (this.follow_up_results = ""),
        (this.address = ""),
        (this.result = ""),
        (this.follow_up_type = ""),
        (this.trail_time = ""),
        (this.img_list = []),
        (this.product_ids = []),
        (this.prooptions = []);
    },
    handleCurrentChange(val) {
      localStorage.setItem("currentPage", val);
      this.handleScreen(val);
    },
    chooseFile: function () {
      this.$refs.choosePhoto.addEventListener("change", this.photoChange);
      this.$refs.choosePhoto.dispatchEvent(new MouseEvent("click"));
    },
    photoChange(el) {
      var file = el.target.files[0]; //name: "dangqi1.png" || type: "image/png"
      var type = file.type.split("/")[0];
      if (type === "image") {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        var that = this;
        reader.onloadend = function () {
          var dataURL = reader.result;
          var blob = that.dataURItoBlob(dataURL);
          that.upload(blob); //执行上传接口
        };
      } else {
        this.$message.error("请上传图片哦~~");
      }
    },
    del_img(i) {
      this.img_list.splice(i, 1);
    },
    take_big(src) {
      this.imgsrc = src;
      this.big_imgshow = true;
    },
    dataURItoBlob(dataURI) {
      // base64 解码
      let byteString = window.atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let T = mimeString.split("/")[1];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    upload(imgUrl) {
      let formData = new FormData();
      this.$message({
        message: "上传中，请稍后",
      });
      formData.append("file", imgUrl);
      uploadFile(formData).then((res) => {
        if (res.data.code == 200) {
          this.img_list.push(res.data.data);
          this.$message({
            message: "上传成功",
            type: "success",
          });
        } // console.log(res);
      });
    },
    //扩容
    getCapacity(i) {
      userCustomerCapacity({
        type: i,
      }).then((res) => {
        this.have = res.data.data.have;
        this.capacity = res.data.data.capacity;
      });
    },

    //成交管理事件
    add_cx_deal(row, i) {
      if (i == 1) {
        this.isAddArrival = true;
        this.add_plan_row = row;
      }
      if (i == 2) {
        //促销
        this.cx_zc_typeid = "";
        this.is_CX = true;
        this.add_plan_row = row;
        getPromotionVerify().then((res) => {
          this.apply_chaosong = res.data.data;
        });
        let list = row.my_customer_product;
        list.forEach((item) => {
          item.label = item.product_name.product_name;
          item.value = item.product_name_id;
        });
        this.cx_prooption = list;
      }
      this.isMc = true;
    },
    handleclose_Apply() {
      //取消促销
      this.cx_prooption = [];
      this.apply_money = "";
      this.apply_explain = "";
      this.cx_proname_id = "";
      this.isMc = false;
      this.cx_typeid = "";
      this.is_CX = false;
    },
    handle_apply() {
      //确定申请促销
      if (this.add_flag) {
        return;
      }
      let { apply_money, apply_explain, cx_proname_id, cx_typeid, add_plan_row, cx_zc_typeid, time_section, service_info } = this;
      if (isNaN(Number(apply_money))) {
        this.$message.error("请输入正确的促销成本金额");
        return;
      }
      if (cx_typeid == 4) {
        if (time_section.length == 0) {
          this.$message.error("请选择服务时长区间");
          return;
        }
      }
      let start_service_duration = "";
      let end_service_duration = "";
      let other_service_detail = "";
      if (cx_typeid == 4) {
        start_service_duration = this.getwaytime(time_section[0]);
        end_service_duration = this.getwaytime(time_section[1]);
      } else if (cx_typeid == 5) {
        other_service_detail = service_info;
      } else {
        other_service_detail = "";
        start_service_duration = "";
        end_service_duration = "";
      }
      if (cx_typeid == 5) {
        if (service_info == "") {
          this.$message.error("请输入服务详情");
          return;
        }
      }
      this.add_flag = true;
      let data = {
        type: cx_typeid,
        money: apply_money,
        apply_explain,
        my_customer_id: add_plan_row.id,
        product_name_id: cx_proname_id,
        customer_id: add_plan_row.customer_id,
        other_service_detail,
        start_service_duration,
        end_service_duration,
        policy_type: cx_zc_typeid,
      };

      for (const i in data) {
        if (i !== "end_service_duration" && i !== "start_service_duration" && i !== "other_service_detail") {
          if (data[i] == "") {
            this.add_flag = false;
            this.$message.error("请填写完整内容后提交");
            return;
          }
        }
      }
      addPromotion(data).then((res) => {
        this.add_flag = false;
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.handleclose_Apply();
        }
      });
    },

    //到账相关
    handleClose_AddArrival() {
      //关闭新增到账
      this.isAddArrival = false;
      this.isMc = false;
    },
    // 单选框
    //     onRadio(e){
    //         if(e==3){
    //             this.$confirm('放弃跟进，该客户会流入事业部公海，\n' +
    //                 '确定要放弃吗？', '确认放弃', {
    //                 confirmButtonText: '确定',
    //                 cancelButtonText: '取消',
    //                 type: 'warning'
    //             }).then(() => {
    //                 this.$message({
    //                     type: 'success',
    //                     message: '删除成功!'
    //                 });
    //             }).catch(() => {
    //                 this.$message({
    //                     type: 'info',
    //                     message: '已取消删除'
    //                 });
    //             });
    //         }
    //     }
  },
};
</script>

<style lang="scss">
.customer {
  flex: 1 1 0%;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #eef5f9;
  padding: 20px;

  .login_mc {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 98;
  }

  .el-main {
    background: #fff;
    padding: 20px;

    .customer_header {
      .customer_header_check {
        width: 280px;
        height: 40px;
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-bottom: 30px;

        div {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
        }

        .isPersonal {
          background: #3a85fc;
          color: #fff;
        }
      }
    }

    .export {
      width: 100%;
      height: 60px;
      background: #fff !important;
      /*background: rgba(0, 0, 0, 0.04);*/
      opacity: 1;
      display: flex;
      align-items: center;

      .export_button {
        width: 80px;
        height: 32px;
        background: #3a85fc;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        margin-left: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #ffffff;
        opacity: 1;
        border-radius: 4px;
        cursor: pointer;
      }
    }

    .customer_show {
      height: 136px;
      display: flex;
      align-items: center;
      // justify-content: space-between;

      .customer_show_box {
        display: flex;
        align-items: center;
        border-radius: 5px;
        // width: 310px;
        width: 25%;
        // width: 20%;
        height: 136px;
        margin-left: 2%;
        cursor: pointer;
        background: #ffffff;
        border: 1px solid #fafafa;
        box-shadow: 0px 0px 10px #efefef;

        img {
          width: 60px;
          height: 60px;
          margin: 0 7px 0 20px;
        }

        .img02 {
          display: none;
        }

        .customer_show_box_num {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;

          div:first-child {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            line-height: 26px;
            color: rgba(0, 0, 0, 0.85);
            opacity: 1;
          }

          div:last-child {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 21px;
            color: rgba(0, 0, 0, 0.45);
            opacity: 1;
          }
        }
      }

      .well_customer {
        margin-left: 0;
      }

      .well_customera {
        background: #7299f6;
        margin-left: 0px;
        .customer_show_box_num {
          div {
            color: #fff !important;
          }
        }
      }
      .A_customera {
        background: #48cfb1;
        color: #fff !important;
        .customer_show_box_num {
          div {
            color: #fff !important;
          }
        }
      }

      .B_customera {
        background: #ffab19;
        color: #fff !important;
        .customer_show_box_num {
          div {
            color: #fff !important;
          }
        }
      }

      .C_customera {
        background: #3bc87d;
        color: #fff !important;
        .customer_show_box_num {
          div {
            color: #fff !important;
          }
        }
      }

      .D_customera {
        background: #eef5ff;
        color: #fff !important;
        .customer_show_box_num {
          div {
            color: #fff !important;
          }
        }
      }

      .f_customera {
        color: #fff !important;
        background: #3a85fc;
        .customer_show_box_num {
          div {
            color: #fff !important;
          }
        }
      }
    }

    /*.customer_show_box:hover .customer_show_box_num div {*/
    /*color: #fff !important;*/
    /*}*/

    /*.customer_show_box:hover .img01 {*/
    /*display: none;*/
    /*}*/

    /*.customer_show_box:hover .img02 {*/
    /*display: block;*/
    /*}*/

    .customer_header_screen {
      height: 32px;
      width: 100%;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      margin-top: 42px;
      margin-bottom: 20px;

      .isPersonal {
        height: 32px;
        display: flex;
        margin-left: 2%;

        div {
          width: 70px;
          height: 100%;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;
          cursor: pointer;
        }

        div:first-child {
          color: #ffffff;
          background: #3a85fc;
        }

        div:last-child {
          border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
          background: #ffffff;
          color: rgba(0, 0, 0, 0.6);
          margin-left: 20px;
        }
      }

      .customer_header_screen_box {
        display: flex;
        align-items: center;
        width: 23%;
        margin-left: 2%;

        .customer_header_screen_box_tit {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: #595959;
          opacity: 1;
          cursor: default;
          white-space: nowrap;
          max-width: 98px;
        }

        .screen_input {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: space-between;
          height: 32px;
          background: #ffffff;
          border: 1px solid #dddddd;
          opacity: 1;
          border-radius: 4px;

          .el-cascader {
            width: 100%;
          }

          .el-date-editor {
            padding: 0;
            height: 100%;
            width: 100%;

            input {
              width: 100%;
            }

            .saa {
              width: 0;
            }
          }

          .el-input__suffix {
            display: flex;
            align-items: center;
          }

          input {
            width: 100%;
            height: 32px;
            display: flex;
            align-items: center;
            // padding-left: 15px;
          }
          input::placeholder {
            color: #c0c4cc;
          }
          img {
            margin-right: 12px;
          }
        }
      }
    }

    .customer_header_bottom {
      height: 32px;
      width: 100%;
      display: flex;

      div {
        width: 70px;
        height: 100%;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        cursor: pointer;
      }

      div:first-child {
        color: #ffffff;
        background: #3a85fc;
      }

      div:last-child {
        border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
        background: #ffffff;
        color: rgba(0, 0, 0, 0.6);
        margin-left: 20px;
      }
    }

    .customer_all {
      font-size: 14px;
      font-family: Segoe UI;
      font-weight: 400;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.8);
      opacity: 1;
      margin-top: 37px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 9px;
      .customer_all-btn {
        display: flex;
        align-items: center;
      }

      .customer_add {
        width: 99px;
        height: 35px;
        background: #3a85fc;
        opacity: 1;
        border-radius: 4px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 24px;
        color: #ffffff;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .customer_cz {
      justify-content: space-between;
      align-items: center;
      width: 100%;

      div {
        white-space: nowrap;
        padding: 0 5px;
        border-right: none !important;
      }

      div:hover {
        color: #3a85fc;
      }

      div:last-child {
        border-right: none;
      }

      span {
        color: #efefef;
      }
    }

    .pro_box {
      i {
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: 5px;
      }

      .pro_well {
        //产品意向 已成交
        background: #42cb94;
      }

      display: flex;
      align-items: center;
    }

    .el-table .cell .my_customer_plan {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; //多行在这里修改数字即可
      overflow: hidden;
      -webkit-box-orient: vertical;

      .my_customer_plan_totimes {
        display: inline-block;
        // width: 50px;
        line-height: 20px;
        text-align: center;
        border-radius: 10px;
        background: #f2f6ff;
        color: #3a85fc;
        padding: 0px 7px;
      }
    }

    .my_customer_plan_status {
      display: flex;
      align-items: center;

      i {
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        opacity: 1;
        margin-right: 10px;
      }

      .well_status {
        background: rgba(29, 208, 189, 1);
      }

      .wait_status {
        background: #faad14;
      }

      .to_time_status {
        background: #3a85fc;
      }
    }

    .block {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }

  .Follow_box {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .Follow_box_info {
      position: relative;
      z-index: 99;
      width: 40%;
      min-height: 40%;
      background: #ffff;
      margin: auto;
      border-radius: 10px;
      // overflow-y: auto;
      min-width: 700px;
      margin-top: 50px;
      height: 90%;

      .Follow_head {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        font-size: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        width: 100%;
        position: relative;

        i {
          position: absolute;
          right: 20px;
        }
      }

      // .Follow_info::-webkit-scrollbar {
      //   /*隐藏滚轮*/
      //   display: none;
      //   }
      .Follow_info {
        height: 90%;
        overflow: auto;

        .Follow_tit {
          padding: 0 30px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.8);
          opacity: 1;
          display: flex;
          align-items: center;
          margin: 20px 0;

          i {
            display: block;
            width: 6px;
            height: 6px;
            background: #3a85fc;
            border-radius: 50%;
            opacity: 1;
            margin-right: 10px;
          }
        }

        .Follow_none {
          color: #ccc;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .Follow_customer_info {
          padding: 0px 40px;

          .Follow_customer_info_box {
            background: #f8f8f8;
            padding: 0 40px;
            display: flex;
            flex-direction: column;
            // height: 60px;
            justify-content: space-around;

            .Follow_customer_info_box_line {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 19px;
              color: rgba(0, 0, 0, 0.85);
              justify-content: space-between;

              .Follow_customer_info_box_line_one {
                display: flex;
                height: 25px;
                .Follow_customer_info_box_line_one_tit {
                  color: rgba(0, 0, 0, 0.5);
                  white-space: nowrap;
                }

                .Follow_customer_info_box_line_one_info {
                  color: rgba(0, 0, 0, 0.8);
                }
              }
            }
          }

          .Follow_customer_info_plan {
            background: #fff;
            padding: 0 40px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            position: relative;

            .Follow_customer_info_plan_bottom {
              position: absolute;
              bottom: -30px;
              right: 20px;
              width: 80px;
              height: 35px;
              background: #3a85fc;
              opacity: 1;
              border-radius: 4px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 19px;
              color: #ffffff;
              opacity: 1;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .Follow_customer_info_plan_line {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 19px;
              color: rgba(0, 0, 0, 0.85);
              // justify-content: space-between;
              width: 100%;
              margin: 10px 0;

              .Follow_customer_info_plan_line_one {
                display: flex;

                .Follow_customer_info_plan_line_one_tit {
                  color: rgba(0, 0, 0, 0.5);
                  white-space: nowrap;
                }

                .Follow_customer_info_plan_line_one_info {
                  color: rgba(0, 0, 0, 0.8);
                  white-space: nowrap;
                  flex: 1;
                }

                .Follow_customer_info_plan_line_one_img {
                  display: flex;

                  img {
                    width: 60px;
                    height: 60px;
                    margin-right: 20px;
                  }
                }

                .Follow_customer_info_plan_line_one_time {
                  width: 66px;
                  height: 20px;
                  background: rgba(58, 133, 252, 0.1);
                  opacity: 1;
                  border-radius: 20px;
                  font-size: 12px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  line-height: 16px;
                  color: #3a85fc;
                  opacity: 1;
                  margin-left: 12px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }

              .Follow_customer_info_plan_line_one:nth-child(2) {
                margin: 0 9%;
              }
            }

            .Follow_customer_info_plan_line_buttom {
              display: flex;
              justify-content: flex-end;
              height: 35px;

              div {
                height: 100%;
                width: 80px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                margin-left: 10px;
                cursor: pointer;
              }

              .Follow_customer_info_plan_del {
                background: #ffffff;
                border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
                color: rgba(0, 0, 0, 0.6);
              }

              .Follow_customer_info_plan_change {
                background: #3a85fc;
                color: #ffffff;
              }
            }
          }
        }

        .Follow_tit_box {
          .Follow_customer_info {
            width: 94%;
            padding: 5px;
            box-sizing: border-box;
          }
        }

        // .Follow_tit_box:last-child {
        //   .Follow_customer_info {
        //     border-bottom: 1px solid transparent !important;
        //   }
        // }
      }
    }
  }

  // .info {
  //   width: 772px;
  //   height: 600px;
  //   margin: auto;
  //   background: #ffffff;
  //   border: 1px solid #707070;
  //   opacity: 1;
  //   position: fixed;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   border-radius: 5px;
  //   z-index: 999;
  //   box-sizing: border-box;
  //   .take_new_title {
  //     // top: -66px;
  //     left: 0;
  //     z-index: 1;
  //     width: 100%;
  //     height: 66px;
  //     position: relative;
  //     border-bottom: 1px solid #ccc;
  //     text-align: center;
  //     line-height: 66px;
  //     font-size: 18px;
  //     i {
  //       position: absolute;
  //       top: 23px;
  //       right: 23px;
  //     }
  //   }
  //   .flow_kind {
  //     width: 50%;
  //     display: flex;
  //     margin-top: 20px;
  //     align-items: center;
  //     .el-select {
  //       width: 250px;
  //     }
  //     .el-date-editor.el-input {
  //       width: 250px;
  //     }
  //   }
  //   .take_bottom {
  //     height: 66px;
  //     position: relative;
  //     bottom: 0;
  //     width: 100%;
  //     display: flex;
  //     justify-content: flex-end;
  //     box-sizing: border-box;
  //     padding-right: 40px;
  //     align-items: center;
  //     .bottom_sent {
  //       width: 80px;
  //       height: 40px;
  //       background: #3a85fc;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       opacity: 1;
  //       color: #fff;
  //       border-radius: 4px;
  //     }
  //   }
  //   .flow_kind_text {
  //     width: 80%;
  //     display: flex;
  //     margin-top: 20px;
  //     align-items: center;
  //     white-space: nowrap;
  //     .el-textarea {
  //       textarea {
  //         resize: none;
  //       }
  //     }
  //   }
  // }
  .acc_img_shadow {
    height: 100%;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2000;
    height: 100%;

    img {
      display: block;
      width: 300px;
      max-height: 600px;
      margin: 200px auto;
    }
  }

  //新增计划
  .info_add {
    width: 700px;
    height: 800px !important;
    margin: auto;
    background: #ffffff;
    border: 1px solid #707070;
    opacity: 1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    border-radius: 5px;
    // padding-top: 66px;
    box-sizing: border-box;

    .take_new_title {
      // top: -66px;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 66px;
      position: relative;
      border-bottom: 1px solid #ccc;
      text-align: center;
      line-height: 66px;
      font-size: 18px;

      i {
        position: absolute;
        top: 23px;
        right: 23px;
      }
    }

    .take_new_detail {
      width: 100%;
      box-sizing: border-box;
      overflow: auto;
      padding: 20px;
      height: 80%;
      overflow-y: scroll;

      .acc_top_compony {
        .kf_detail {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .quan {
            height: 5px;
            width: 5px;
            border-radius: 2.5px;
            background: #3a85fc;
            margin-right: 6px;
          }
        }

        .kh_detail {
          padding: 20px;
          width: 100%;
          box-sizing: border-box;
          height: 80px;
          margin-top: 10px;
          display: inline-block;
          background: #f8f8f8;

          div {
            overflow: hidden; /*超出部分隐藏*/
            white-space: nowrap; /*不换行*/
            text-overflow: ellipsis; /*超出部分文字以...显示*/
            width: 50%;
            display: inline-block;
            height: 50%;
          }
        }
      }
    }

    .flow_kind {
      width: 100% !important;
      display: flex;
      margin-top: 20px;
      align-items: center;

      .el-select {
        width: 500px !important;
      }

      .el-date-editor.el-input {
        width: 500px !important;
      }
    }

    .take_bottom {
      height: 40px;
      position: relative;
      bottom: 40px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      box-sizing: border-box;
      padding-right: 40px;
      align-items: center;
      margin-top: 60px;

      .bottom_sent {
        width: 80px;
        height: 40px;
        background: #3a85fc;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        color: #fff;
        border-radius: 4px;
      }
    }

    .flow_kind_text {
      width: 90% !important;
      display: flex;
      margin-top: 20px;
      align-items: flex-start;
      white-space: nowrap;

      .el-textarea {
        textarea {
          resize: none;
        }
      }
    }
  }

  //促销申请
  .add_cx_bigbox {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .add_cx_box {
      // width: 40%;
      width: 650px;
      height: 80%;
      position: relative;
      z-index: 99;
      background: #fff;
      margin: auto;
      border-radius: 10px;
      min-width: 560px;
      margin-top: 50px;
      overflow: hidden;

      .add_box_head {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        i {
          padding: 10px;
          cursor: pointer;
          position: absolute;
          right: 20px;
        }
      }

      .add_cx_box_info {
        height: 90%;
        overflow: auto;

        .add_cx_box_head {
          margin: 15px 20px;
          height: 40px;
          background: #fafafa;
          opacity: 1;
          padding-left: 32px;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.8);
          opacity: 1;

          i {
            width: 2px;
            height: 12px;
            background: #3a85fc;
            opacity: 1;
            display: block;
            margin-right: 5px;
          }
        }

        .add_cx_box_customer {
          padding: 0 70px;

          .add_cx_box_customer_line {
            display: flex;
            height: 32px;
            align-items: center;
            margin-bottom: 24px;

            .add_cx_box_customer_line_tit {
              width: 70px;
            }

            .el-date-editor {
              height: 32px;
              line-height: 32px;
              flex: 1;
              display: flex;
              align-items: center;

              .sdal {
                width: 0;
              }

              .el-range-separator {
                height: auto;
              }

              input {
                width: 50%;
              }

              .el-input__icon {
                height: auto;
              }
            }

            .add_cx_box_customer_line_img {
              display: flex;
              align-items: center;
              flex: 1;
              justify-content: flex-end;

              img {
                width: 40px;
                height: 40px;
                border-radius: 4px;
              }

              i {
                margin: 0 10px;
              }
            }

            .add_cx_box_customer_line_int {
              flex: 1;
              height: 100%;
              padding-left: 15px;
              border: 1px solid #dcdfe6;
              border-radius: 4px;
              display: flex;
              align-items: center;
            }

            .el-select {
              height: 100%;
              flex: 1;

              .el-input__suffix {
                display: flex;
                align-items: center;
              }
            }

            .el-textarea {
              flex: 1;

              .el-textarea__inner {
                //el_input中的隐藏属性
                resize: none; //主要是这个样式
              }
            }

            .el-input {
              height: 100%;
              flex: 1;

              input {
                height: 100%;
              }
            }
          }
        }

        .add_cx_box_bottom {
          display: flex;
          justify-content: flex-end;
          right: 50px;
          margin-top: 50px;
          margin-bottom: 30px;
          margin-right: 30px;

          div {
            height: 40px;
            width: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 19px;
            border-radius: 4px;
            cursor: pointer;
          }

          div:first-child {
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
            color: rgba(0, 0, 0, 0.5);
          }

          div:last-child {
            background: #3a85fc;
            color: #fff;
            margin-left: 20px;
          }
        }
      }
    }
  }

  //填写跟进结果
  .acc_shadow {
    position: fixed;
    z-index: 120;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    .el-range-separator {
      width: 30px;
    }

    .info {
      width: 772px;
      height: 600px;
      margin: 100px auto;
      background: #ffffff;
      border: 1px solid #707070;
      opacity: 1;
      position: relative;
      border-radius: 5px;
      z-index: 999;
      // padding-top: 66px;
      box-sizing: border-box;

      .take_new_title {
        // top: -66px;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 66px;
        position: relative;
        border-bottom: 1px solid #ccc;
        text-align: center;
        line-height: 66px;
        font-size: 18px;

        i {
          position: absolute;
          top: 23px;
          right: 23px;
        }
      }

      .take_new_detail {
        height: 468px;
        width: 100%;
        box-sizing: border-box;
        overflow: auto;
        padding: 20px;

        .acc_top_compony {
          .kf_detail {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .quan {
              height: 5px;
              width: 5px;
              border-radius: 2.5px;
              background: #3a85fc;
              margin-right: 6px;
            }
          }

          .kh_detail {
            padding: 20px;
            width: 100%;
            box-sizing: border-box;
            height: 80px;
            margin-top: 10px;
            display: inline-block;
            background: #f8f8f8;

            div {
              overflow: hidden; /*超出部分隐藏*/
              white-space: nowrap; /*不换行*/
              text-overflow: ellipsis; /*超出部分文字以...显示*/
              width: 50%;
              display: inline-block;
              height: 50%;
            }
          }
        }
      }

      .flow_kind {
        width: 50%;
        display: flex;
        margin-top: 20px;
        white-space: nowrap; /*不换行*/
        align-items: center;

        .el-select {
          width: 250px;
        }

        .el-date-editor .el-range-input {
          overflow: hidden; /*超出部分隐藏*/
          white-space: nowrap; /*不换行*/
          text-overflow: ellipsis; /*超出部分文字以...显示*/
        }

        .el-date-editor.el-input {
          width: 250px;
        }
      }

      .take_bottom {
        height: 66px;
        position: relative;
        bottom: 0;
        width: 100%;
        display: flex;
        // align-items: center;
        // justify-content: center;
        box-sizing: border-box;
        justify-content: flex-end;
        padding-right: 40px;
        align-items: center;

        .bottom_sent {
          width: 80px;
          height: 40px;
          background: #3a85fc;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 1;
          color: #fff;
          border-radius: 4px;
        }
      }

      .flow_kind_text {
        width: 80%;
        display: flex;
        margin-top: 20px;
        // align-items: center;
        padding-top: 1px;
        white-space: nowrap;

        .el-textarea {
          textarea {
            resize: none;
          }
        }

        .el-dialog__wrapper {
          z-index: 2010 !important;
        }
      }
    }

    .change_info {
      width: 772px;
      height: 480px;
      min-height: 480px;
      margin: 100px auto;
      background: #ffffff;
      border: 1px solid #707070;
      opacity: 1;
      position: relative;
      border-radius: 5px;
      z-index: 999;
      box-sizing: border-box;

      .take_new_title {
        // top: -66px;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 66px;
        position: relative;
        border-bottom: 1px solid #ccc;
        text-align: center;
        line-height: 66px;
        font-size: 18px;

        i {
          position: absolute;
          top: 23px;
          right: 23px;
        }
      }

      .take_new_detail {
        height: 340px;
        width: 100%;
        box-sizing: border-box;
        overflow: auto;
        padding: 20px;

        .acc_top_compony {
          .kf_detail {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .quan {
              height: 5px;
              width: 5px;
              border-radius: 2.5px;
              background: #3a85fc;
              margin-right: 6px;
            }
          }

          .kh_detail {
            padding: 20px;
            width: 100%;
            box-sizing: border-box;
            height: 80px;
            margin-top: 10px;
            display: inline-block;
            background: #f8f8f8;

            div {
              overflow: hidden; /*超出部分隐藏*/
              white-space: nowrap; /*不换行*/
              text-overflow: ellipsis; /*超出部分文字以...显示*/
              width: 50%;
              display: inline-block;
              height: 50%;
            }
          }
        }
      }

      .flow_kind {
        width: 100%;
        display: flex;
        margin-top: 20px;
        white-space: nowrap; /*不换行*/
        align-items: center;

        .el-select {
          width: 250px;
        }

        .el-input__inner {
          width: 250px;
        }

        .el-date-editor .el-range-input {
          overflow: hidden; /*超出部分隐藏*/
          white-space: nowrap; /*不换行*/
          text-overflow: ellipsis; /*超出部分文字以...显示*/
        }

        .el-textarea__inner {
          width: 320px;
        }

        .el-date-editor.el-input {
          width: 250px;
        }
      }

      .take_bottom {
        height: 66px;
        position: relative;
        bottom: 0;
        width: 100%;
        display: flex;
        // align-items: center;
        // justify-content: center;
        box-sizing: border-box;
        justify-content: flex-end;
        padding-right: 40px;
        align-items: center;

        .bottom_sent {
          width: 80px;
          height: 40px;
          background: #3a85fc;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 1;
          color: #fff;
          border-radius: 4px;
        }
      }

      .flow_kind_text {
        width: 65%;
        display: flex;
        margin-top: 20px;
        // align-items: center;
        padding-top: 1px;
        // white-space: nowrap;
        .el-textarea {
          width: 400px;

          textarea {
            resize: none;
          }
        }

        .el-dialog__wrapper {
          z-index: 2010 !important;
        }
      }
    }

    //促进结果
    .acc_commen {
      width: 445px;
      height: 426px;
      background: #ffffff;
      border: 1px solid #707070;
      opacity: 1;
      border-radius: 10px;
      margin: 100px auto;
      position: relative;

      .acc_commen_title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;
        position: relative;
        height: 40px;
        border-bottom: 1px solid #ccc;

        i {
          position: absolute;
          // top: ;
          right: 10px;
        }
      }

      .acc_commen_body {
        width: 100%;
        resize: none;
        padding: 10% 10%;

        .el-textarea__inner {
          resize: none;
          height: 270px;
        }
      }

      .acc_commen_sent {
        position: absolute;
        right: 10px;
        padding: 0 10px;
        height: 25px;
        line-height: 25px;
        bottom: 10px;
        background: #3a85fc;
        color: #fff;
        border-radius: 5px;
      }
    }
  }
}

.gs_name {
  width: 100%;
  padding-left: 10px;
  height: 100%;

  .name {
    font-size: 14px;
    color: #666666;
  }

  .time {
    display: flex;
    align-items: center;
  }

  .time_type {
    width: 72px;
    height: 22px;
    background: #fff4f3;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 16px;
    color: #ff5656;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    margin-left: 10px;
  }

  .time_type_b {
    width: 72px;
    height: 22px;
    font-size: 12px;
    color: #3a85fc;
    margin-left: 10px;
    background: #f2f6ff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
  }
  .time_type_a {
    width: 72px;
    height: 22px;
    font-size: 12px;
    color: #ff5656;
    margin-left: 10px;
    background: #fff4f3;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
  }
}

.el-table__row td:nth-child(2) {
  position: relative;

  .new {
    position: absolute;
    left: 0;
    top: -2px;
    width: 32px;
    height: 26px;
  }

  .wx {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 32px;
    height: 32px;
  }
  .wxing {
    position: absolute;
    bottom: 0;
    right: 30px;
    width: 20px;
    height: 20px;
  }
}

.el-cascader {
  width: 100%;
}

.el-radio-group,
.el-checkbox-group {
  width: 70%;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.flow_kind .form_input {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 80%;
}

.flow_kind .form_input::placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
}

.img_item_info {
  width: 100px;
  height: 100px;
  position: relative;
  margin-right: 10px;
  color: #fff;
  .big_img {
    width: 100%;
  }

  .del_img {
    position: absolute;
    top: -5px;
    right: -5px;
    color: #ccc;
  }
}

.image_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  width: 100px;
  height: 100px;
  position: relative;
  background: #ececec;
  color: rgba(0, 0, 0, 0.2);
  justify-content: center;
}

.flow_img_listinfo {
  display: flex;
}
.tx_follow {
  float: right;
}

.tx_follow div {
  height: 30px;
  padding: 0 5px;
  color: #fff;
  display: flex;
  align-items: center;
  background: #3a85fc;
  border-radius: 5px;
}
.Follow_customer_info_plan_line_one_info {
  width: 50%;
  flex: unset;
}
.Follow_customer_info_plan_line {
  width: 70%;
}
.Follow_customer_info_plan_line_one_info {
  width: 70%;
}
</style>
