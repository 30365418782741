import Vue from 'vue'

/**权限指令**/
const has = Vue.directive('has', {
    inserted: function (el, binding, vnode) {
        // 获取按钮权限
        // let btnPermissions = vnode.data.staticClass
        if (!Vue.prototype.$_has(binding.expression)) {
            el.parentNode.removeChild(el)
        }
    },
})

const nohas = Vue.directive('nohas', {
    inserted: function (el, binding, vnode) {
        // 获取按钮权限
        let btnPermissions = vnode.data.staticClass

        if (Vue.prototype.$_has(btnPermissions)) {
            el.parentNode.removeChild(el)
        }
    },
})

Vue.prototype.$_has = function (value) {
    if (!(!!value)) return false
    let isExist = false
    let {role_permissions} = JSON.parse(localStorage.getItem("token"))
    if (!role_permissions) {
        return false
    }

    let parr = value.replaceAll('\'','').split(':').filter(i=>!!i)
    // localStorage.setItem('expression',value.replaceAll('\'',''))

    if (parr.length===0)return false
    let ps =role_permissions.map(i=>i.permissions)
    let ddd =ps.indexOf(parr[0])
    let obj={
        'parr[0]':parr[0],
        ps,
        ddd
    }
    // localStorage.setItem('isExist',JSON.stringify(obj))
    role_permissions.map((i,idx)=>{

        if (i.permissions === parr[0]){

            isExist = i.data.some(j=>j.permissions===(parr[1]??-1))
        }
    })


    /*isExist = role.some(i =>
        i.permissions === value
    )*/

    /*for (var i = 0; i < btnPermissionsStr.length; i++) {
      if (btnPermissionsStr[i].children) {
        for (var w = 0; w < btnPermissionsStr[i].children.length; w++) {
          if (btnPermissionsStr[i].children[w].qs) {
            for (var q = 0; q < btnPermissionsStr[i].children[w].qs.length; q++) {
              if (value == btnPermissionsStr[i].children[w].qs[q].name) {
                isExist = true
              }
            }
          }
        }
      }
    }*/



    return isExist
}

Vue.prototype.$_index = function (value) {
    let isExist = false
    let btnPermissionsStr = JSON.parse(localStorage.getItem('permissions'))
    if (btnPermissionsStr == undefined || btnPermissionsStr == null) {
        return false
    }

    for (var i = 0; i < btnPermissionsStr.length; i++) {
        if (btnPermissionsStr[i].children) {
            for (var w = 0; w < btnPermissionsStr[i].children.length; w++) {
                if (value == btnPermissionsStr[i].children[w].name) {
                    isExist = true
                }
            }
        }
    }

    return isExist
}

const index = Vue.directive('index', {
    inserted: function (el, binding, vnode) {
        // 获取按钮权限
        let btnPermissions = vnode.data.staticClass
        if (!Vue.prototype.$_index(btnPermissions)) {
            el.parentNode.removeChild(el)
        }
    },
})
export {has, nohas, index}
