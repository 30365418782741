<template>
  <div class="Analysis_data_box">
    <div class="Analysis_data" v-if="!is_result">
      <div class="Analysis_data_info">
        <div class="Analysis_data_head">
          <div
            class="Analysis_data_head_dep_box"
            v-if="user_position_level > 1"
          >
            <div class="Analysis_data_head_dep">
              <div
                :class="isPersonal == 1 ? 'isPersonal' : ''"
                @click="handleisPersonal(1)"
              >
                我的部门
              </div>
              <div
                :class="isPersonal == 2 ? 'isPersonal' : ''"
                @click="handleisPersonal(2)"
              >
                其他部门
              </div>
            </div>
          </div>
          <div class="Analysis_data_head_search" v-if="type==1">
            <el-input
              placeholder="搜索关键词"
              prefix-icon="el-icon-search"
              v-model="search"
            >
            </el-input>
            <div class="Analysis_data_head_search_buttom" @click="clicksearch">
              查询
            </div>
          </div>
        </div>
        <div class="Analysis_data_screen">
          <div class="Analysis_data_screen_head">
            <div class="Analysis_data_screen_tit">筛选条件：</div>
            <div class="Analysis_data_screen_head_right">
              <div class="Analysis_data_screen_head_one_box">
                <div
                  class="Analysis_data_screen_head_one"
                  v-for="(item, index) in Screening_arr"
                  :key="index"
                >
                  <div>
                    {{ item.name }}
                    <i class="el-icon-close" @click="delScreening(item)"></i>
                  </div>
                </div>
              </div>
              <div
                class="Analysis_data_screen_head_del"
                @click="delScreening_arr"
              >
                <i class="el-icon-delete"></i>
                清空条件选择
              </div>
            </div>
          </div>
          <div class="Analysis_data_screen_line">
            <div class="Analysis_data_screen_tit">对比类型：</div>
            <div class="radio_box">
              <div
                v-for="(item, index) in battle_type_arr"
                :key="index"
                @click="condition(item, 1)"
              >
                <img
                  src="../../assets/img/battle_check.png"
                  v-if="item.is_check"
                  alt=""
                />
                <img src="../../assets/img/battle_no_check.png" v-else alt="" />
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="Analysis_data_screen_line" v-if="type == 1">
            <div class="Analysis_data_screen_tit">分数对比：</div>
            <div class="radio_box">
              <div
                v-for="(item, index) in fraction_radio"
                :key="index"
                @click="condition(item, 2)"
              >
                <img
                  src="../../assets/img/battle_check.png"
                  v-if="item.is_check"
                  alt=""
                />
                <img src="../../assets/img/battle_no_check.png" v-else alt="" />
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="Analysis_data_screen_line">
            <div class="Analysis_data_screen_tit" style="width: 90px">
              事业线对比：
            </div>
            <div class="radio_box">
              <div
                v-for="(item, index) in business_arr"
                :key="index"
                @click="condition(item, 3)"
              >
                <img
                  src="../../assets/img/battle_check.png"
                  v-if="item.is_check"
                  alt=""
                />
                <img src="../../assets/img/battle_no_check.png" v-else alt="" />
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="Analysis_data_screen_box">
          <div
            class="Analysis_data_screen_box_none"
            v-if="line_arr.length == 0"
          >
            <img src="../../assets/img/Analysis_data_none.png" alt="" />
            <div>无比一比数据</div>
          </div>
          <div
            class="line pointer"
            v-for="(item, index) in line_arr"
            :key="index"
            @click="chooseone(item)"
          >
            <div class="line_left">
              <img
                src="../../assets/img/battle_check.png"
                alt=""
                class="line_check"
                v-if="item.is_check"
              />
              <img
                src="../../assets/img/battle_no_check.png"
                alt=""
                class="line_check"
                v-else
              />
              <div class="line_deail">
                <img
                  :src="item.head_portrait"
                  alt=""
                  v-if="item.head_portrait && type == 1"
                />
                <img
                  src="../../assets/img/more_head.png"
                  alt=""
                  v-if="!item.head_portrait && type == 1"
                />
                <div class="line_info">
                  <div class="name">
                    {{ item.name
                    }}<span v-if="type == 1">【{{ item.company_name }}】</span>
                  </div>
                  <div class="year">
                    {{
                      type == 1 ? item.entry_time : "人数：" + item.people_num
                    }}
                  </div>
                  <div class="line_line" v-if="item.business_line_name">
                    事业线：{{ item.business_line_name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="line_right">
              <div>{{ type == 1 ? "上月得分" : "上月业绩（元）" }}</div>
              <div>{{ type == 1 ? item.score : item.achievement }}</div>
            </div>
          </div>
        </div>
        <div class="block" v-if="line_arr.length > 0">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="Analysis_data_personal" v-if="type == 1 && show_left == 1">
        <div class="Analysis_data_personal_tit">我的分数</div>
        <div class="Analysis_data_personal_fraction">
          <div class="img">
            <img src="../../assets/img/my_battle.png" alt="" />
            <div class="img_wuhu">
              <div>
                {{ personalContrast.user ? personalContrast.user.score : "" }}
              </div>
              <div>上月得分</div>
            </div>
          </div>
        </div>
        <div class="Analysis_data_personal_fraction_box">
          <div class="box">
            <div class="box_head">
              <div>上月</div>
              <div>实际</div>
              <div>标准</div>
              <div>权重</div>
              <div>分数</div>
            </div>
            <div
              class="box_line"
              v-for="(item, index) in personalContrast.score_source"
              :key="index"
            >
              <div>{{ item.title }}</div>
              <div class="box_line_width">
                <div>{{ item.actual }}</div>
                <div>/</div>
              </div>
              <div class="box_line_width">
                <div>{{ item.standard }}</div>
                <div>*</div>
              </div>
              <div class="box_line_width">
                <div>{{ item.weight }}%</div>
                <div>=</div>
              </div>
              <div>{{ item ? item.score : "" }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="choose_box" v-if="show_left == 2">
        <div class="choose_box_tit">比一比（{{ choose_num }}/4）</div>
        <div
          class="choose_box_line pointer"
          v-for="(item, index) in choose_arr"
          :key="index"
          :style="index == 0 ? 'margin-top:20px;' : ''"
          @click.stop="wellgobattle(item)"
        >
          <div class="choose_box_line_left">
            <div class="choose_box_line_check">
              <img
                src="../../assets/img/battle_check.png"
                alt=""
                class="line_check"
                v-if="item.is_check"
              />
              <img
                src="../../assets/img/battle_no_check.png"
                alt=""
                class="line_check"
                v-else
              />
            </div>
            <div class="choose_detail">
              <div class="name">
                {{ item.name
                }}<span v-if="type == 1">【{{ item.company_name }}】</span>
              </div>
              <div class="year">
                {{ type == 1 ? item.entry_time : "人数：" + item.people_num }}
              </div>
              <div class="line_line">
                事业线：{{
                  item.business_line_name ? item.business_line_name : "--"
                }}
              </div>
            </div>
          </div>
          <div class="choose_box_line_right" @click.stop="delchoose(item)">
            <i class="el-icon-close pointer"></i>
          </div>
        </div>
        <div class="choose_num">
          已选择{{ choose_num }}{{ this.type == 1 ? "人" : "个部门" }}
        </div>
        <div class="choose_button">
          <div @click="result(2)">上月对比</div>
          <div @click="result(1)">本月对比</div>
        </div>
      </div>
    </div>
    <div class="duibi_box" v-if="is_result">
      <div class="result_box">
        <div class="result">
          <div class="result_head">
            <div class="result_back" @click="back">
              <i class="el-icon-arrow-left"></i>
              返回对比
            </div>
            <div>对比结果</div>
          </div>
          <div class="result_line">
            <div class="result_lefttit">
              <div>{{ result_type == 1 ? "本月对比" : "上月对比" }}</div>
              <div class="result_achi">总业绩(元)</div>
              <div class="result_A" v-if="type == 2">人数(个)</div>
              <div class="result_A">A类意向客户(个)</div>
              <div class="result_v">面访量(次)</div>
              <div class="result_d" v-if="type == 1">成交客户数(个)</div>
              <div class="result_d" v-if="type == 2">到账人数(个)</div>
              <div class="result_ld" v-if="type == 1">销售漏斗</div>
              <div class="result_ld" v-if="type == 2">人均到账(元)</div>
              <!-- <div class="result_j">日均</div>
              <div class="result_j">单均</div> -->
            </div>
            <div class="result_line_one_box">
              <div
                class="result_line_one"
                v-for="(itm, idx) in result_arr"
                :key="idx"
                :style="
                  result_arr.length == 2
                    ? 'width:50%'
                    : result_arr.length == 3
                    ? 'width:33%'
                    : 'width:25%'
                "
              >
                <div class="one_head">
                  <img
                    :src="itm.head_portrait"
                    alt=""
                    v-if="type == 1 && itm.head_portrait"
                  />
                  <img
                    src="../../assets/img/more_head.png"
                    alt=""
                    v-if="type == 1 && !itm.head_portrait"
                  />
                  <div class="one_head_right">
                    <div class="one_info">
                      <div class="one_info_name">
                        {{ type == 1 ? itm.name : itm.company_name }}
                      </div>
                      <div class="one_info_dep">
                        {{
                          type == 1 ? itm.company_name : itm.business_line_name
                        }}
                      </div>
                    </div>
                    <div class="one_fs" v-if="type == 1">{{ itm.score }}分</div>
                  </div>
                </div>
                <div
                  :style="
                    max_array.achievement !== 0 &&
                    max_array.achievement == itm.achievement
                      ? 'color:#FF2323'
                      : ''
                  "
                >
                  {{ itm.achievement }}
                </div>
                <div
                  v-if="type == 2"
                  :style="
                    max_array.people_num !== 0 &&
                    max_array.people_num == itm.people_num
                      ? 'color:#FF2323'
                      : ''
                  "
                >
                  {{ itm.people_num }}
                </div>
                <div
                  :style="
                    max_array.a_intention_customer !== 0 &&
                    max_array.a_intention_customer == itm.a_intention_customer
                      ? 'color:#FF2323'
                      : ''
                  "
                >
                  {{ itm.a_intention_customer }}
                </div>
                <div
                  :style="
                    max_array.visit !== 0 && max_array.visit == itm.visit
                      ? 'color:#FF2323'
                      : ''
                  "
                >
                  {{ itm.visit }}
                </div>
                <div
                  v-if="type == 1"
                  :style="
                    max_array.deal_customer !== 0 &&
                    max_array.deal_customer == itm.deal_customer
                      ? 'color:#FF2323'
                      : ''
                  "
                >
                  {{ itm.deal_customer }}
                </div>
                <div
                  v-if="type == 2"
                  :style="
                    max_array.arrival_num !== 0 &&
                    max_array.arrival_num == itm.arrival_num
                      ? 'color:#FF2323'
                      : ''
                  "
                >
                  {{ itm.arrival_num }}
                </div>
                <div v-if="type == 1">
                  {{
                    itm.proportion.proportion_a +
                    ":" +
                    itm.proportion.proportion_b +
                    ":" +
                    itm.proportion.proportion_c +
                    ":" +
                    itm.proportion.proportion_d
                  }}
                </div>
                <div
                  v-if="type == 2"
                  :style="
                    max_array.average_arrival !== 0 &&
                    max_array.average_arrival == itm.average_arrival
                      ? 'color:#FF2323'
                      : ''
                  "
                >
                  {{ itm.average_arrival }}
                </div>
                <!-- <div>日均</div>
                <div>单均</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  userBusinessLine,
  searchPersonalList,
  comparisonResults,
  companyContrast,
  otherCompany,
  companyComparisonResults,
  allBusiness,
  personalContrast,
} from "../../api/User/battle";
export default {
  data() {
    return {
      battle_type_arr: [],
      isPersonal: "1",
      search: "",
      type: 1, //1 个人 2 团队   初始化根据user_position_level
      Screening_arr: [], //筛选条件框
      fraction_radio: [
        { id: 1, is_check: false, name: "0~30分", type: 2 },
        { id: 2, is_check: false, name: "30分~60分", type: 2 },
        { id: 3, is_check: false, name: "60分~100分", type: 2 },
        { id: 4, is_check: false, name: "100分以上", type: 2 },
      ], //分数对比
      total: 0,
      currentPage: 1,
      show_left: 1, //是否展示左侧
      is_result: false, //展开对比结果
      result_type: 1,
      result_arr: [], //对比结果数据
      max_array: {}, //对比结果最大值
      user_position_level: "", //个人职位1销售 2军团长 3战区总监 4事业部总经理 5暂定事业部以上级别
      business_arr: [], //事业线
      my_business: [], //个人事业线
      other_business: [], //其他事业线
      line_arr: [], //展示数组
      choose_arr: [], //当前页面选中准备比较的数据
      choose_num: 0, //选中人数
      contrast_type: "", //3事业部 4战区 5军团

      personalContrast: {}, //个人左侧信息
      user: {}, //个人信息
      list_str: "", //权限加密串
      is_flag:false,
    };
  },
  created() {
    localStorage.removeItem("is_result");

    let token = JSON.parse(localStorage.getItem("token"));
    for (let i in token.role) {
      if (token.role[i].permissions == "contrast_analysis") {
        //分配列表
        this.list_str = token.role[i].permissions_id_string;
      }
    }
    let battle_type = [
      { id: 4, is_check: false, name: "事业部对比", type: 1 },
      { id: 3, is_check: false, name: "战区对比", type: 1 },
      { id: 2, is_check: false, name: "军团对比", type: 1 },
      { id: 1, is_check: false, name: "个人对比", type: 1 },
    ];
    userBusinessLine().then((res) => {
      if (res.data.code == 200) {
        this.user_position_level = res.data.data.user_position_level;
        let business = res.data.data.business;
        let business_line_id_str_arr = [];

        business.forEach((item) => {
          item.is_check = false;
          item.name = item.business_line.name;
          item.id = item.business_line.id;
          item.type = 3;
          business_line_id_str_arr.push(item.business_line.id);
        });
        this.my_business = business;
        this.business_arr = business;
        // console.log(this.user_position_level);
        if (this.user_position_level > 1) {
          //小b崽子有部门
          if (this.user_position_level == 5 || this.user_position_level == 4) {
            this.contrast_type = 3;
          }
          if (this.user_position_level == 3) {
            this.contrast_type = 4;
            battle_type.splice(0, 1);
          }
          if (this.user_position_level == 2) {
            this.contrast_type = 5;
            battle_type.splice(0, 2);
          }
          this.type = 2;
          this.getcompanyContrast({
            contrast_type: this.contrast_type,
          });
        } else {
          this.type = 1;
          battle_type.splice(0, 3);
          this.getpersonalContrast({
            business_line_id: business_line_id_str_arr.join(","),
          });
          this.getsearchPersonalList({
            business_line_id: business_line_id_str_arr.join(","),
          });
        }
        this.getbattle_type(battle_type);
      }
    });
  },
  methods: {
    condition(item, i) {
      //条件筛选
      this.currentPage = 1;

      let {
        battle_type_arr,
        fraction_radio,
        business_arr,
        Screening_arr,
      } = this;
      let business_line_id = "";
      if (i == 1) {
        //对比类型
        if (item.is_check) {
          return;
        }
        this.choose_arr = [];
        this.choose_num = 0;
        this.show_left = 1;
        battle_type_arr.forEach((itm) => {
          itm.is_check = false;
          if (item.id == itm.id) {
            if (itm.id == 1) {
              if (this.user.id) {
                this.choose_arr.push(this.user);
              }
              this.type = 1;
            } else {
              this.type = 2;
            }
            itm.is_check = true;
            Screening_arr.push(itm);
            // Screening_arr.forEach((attr, idx) => {
            //   if (attr.type == itm.type) {
            //     Screening_arr[idx] = itm;
            //   }
            // });
          }
        });
      }
      this.line_arr = [];
      if (i == 2) {
        //分数对比
        let bool = !item.is_check;
        fraction_radio.forEach((itm) => {
          itm.is_check = false;
          if (item.id == itm.id) {
            itm.is_check = bool;
            if (bool) {
              Screening_arr.push(itm);
            }
          }
        });
      }
      if (i == 3) {
        //事业线对比
        let bool = !item.is_check;
        business_arr.forEach((itm) => {
          itm.is_check = false;
          if (item.id == itm.id) {
            itm.is_check = bool;
            if (bool) {
              Screening_arr.push(itm);
              business_line_id = item.id;
            }
          }
        });
      }
      Screening_arr.forEach((attr, idx) => {
        if (!attr.is_check) {
          Screening_arr.splice(idx, 1);
        }
      });
      this.battle_type_arr = battle_type_arr;
      this.getlist(business_line_id);
    },
    getlist(id) {
      let { type, isPersonal, Screening_arr } = this;
      let contrast_type = "";
      let start_score = "";
      let end_score = "";
      let business_line_id = id;
      // type 1 个人对比 2 部门对比
      // console.log(type);
      //isPersonal 1 我的  2 其他部门
      console.log(isPersonal);
      Screening_arr.forEach((wuhu) => {
        if (wuhu.type == 1) {
          if (wuhu.id == 2) {
            contrast_type = 5;
          } else if (wuhu.id == 3) {
            contrast_type = 4;
          } else if (wuhu.id == 4) {
            contrast_type = 3;
          }
        }
        if (wuhu.type == 2) {
          if (wuhu.id == 1) {
            start_score = 0;
            end_score = 30;
          }
          if (wuhu.id == 2) {
            start_score = 30;
            end_score = 60;
          }
          if (wuhu.id == 3) {
            start_score = 60;
            end_score = 100;
          }
          if (wuhu.id == 4) {
            start_score = 100;
            end_score = "";
          }
        }
        if (wuhu.type == 3) {
          business_line_id = wuhu.id;
        }
      });
      let data = {};
      if (isPersonal == 1) {
        if (type == 1) {
          let business_line_id_str_arr = [];
          this.my_business.forEach((item) => {
            business_line_id_str_arr.push(item.id);
          });
          if (!this.personalContrast.user) {
            this.getpersonalContrast({
              business_line_id: business_line_id_str_arr.join(","),
            });
          }
          this.getsearchPersonalList({
            business_line_id: business_line_id
              ? business_line_id
              : business_line_id_str_arr.join(","),
            start_score,
            end_score,
            page: this.currentPage,
          });
        } else {
          data = {
            business_line_id,
            contrast_type,
            page: this.currentPage,
          };
          this.getcompanyContrast(data);
        }
      } else {
        if (type == 1) {
          let business_line_id_str_arr = [];
          this.my_business.forEach((item) => {
            business_line_id_str_arr.push(item.id);
          });
          if (!this.personalContrast.user) {
            this.getpersonalContrast({
              business_line_id: business_line_id_str_arr.join(","),
            });
          }
          this.getsearchPersonalList({
            business_line_id: business_line_id
              ? business_line_id
              : business_line_id_str_arr.join(","),
            start_score,
            end_score,
            page: this.currentPage,
          });
          // this.show_left = 1;
        } else {
          data = {
            business_line_id,
            contrast_type,
            page: this.currentPage,
          };
          this.getotherCompany(data);
        }
      }
    },
    delScreening(item) {
      //删除单个晒选条件
      this.currentPage = 1;
      if (item.type == 1) {
        this.$message.error("对比类型无法删除");
        return;
      }
      let { fraction_radio, business_arr, Screening_arr } = this;
      if (item.type == 2) {
        fraction_radio.forEach((itm) => {
          itm.is_check = false;
        });
      }
      if (item.type == 3) {
        business_arr.forEach((itm) => {
          itm.is_check = false;
        });
      }
      Screening_arr.forEach((itm, idx) => {
        if (item.type == itm.type) {
          if (itm.id == item.id) {
            Screening_arr.splice(idx, 1);
          }
        }
      });
      let id = "";
      this.getlist(id);
    },
    delScreening_arr() {
      //清空筛选条件
      this.currentPage = 1;
      this.search = "";
      this.Screening_arr = [];
      let {
        battle_type_arr,
        fraction_radio,
        business_arr,
        user_position_level,
      } = this;
      this.getbattle_type(battle_type_arr);
      fraction_radio.forEach((item) => {
        item.is_check = false;
      });
      business_arr.forEach((item) => {
        item.is_check = false;
      });
      if (user_position_level > 1) {
        this.type = 2;
      } else {
        this.type = 1;
      }
      this.getlist();
    },
    getbattle_type(arr) {
      arr.forEach((item) => {
        item.is_check = false;
        item.type == 1;
        if (this.user_position_level == 5 && item.id == 4) {
          item.is_check = true;
          this.Screening_arr.push(item);
        }
        if (this.user_position_level == 4 && item.id == 4) {
          item.is_check = true;
          this.Screening_arr.push(item);
        }
        if (this.user_position_level == 3 && item.id == 3) {
          item.is_check = true;
          this.Screening_arr.push(item);
        }
        if (this.user_position_level == 2 && item.id == 2) {
          item.is_check = true;
          this.Screening_arr.push(item);
        }
        if (this.user_position_level == 1 && item.id == 1) {
          item.is_check = true;
          this.Screening_arr.push(item);
        }
      });
      this.battle_type_arr = arr;
    },
    getpersonalContrast(data) {
      //个人对比页
      personalContrast(data).then((res) => {
        if (res.data.code == 200) {
          this.personalContrast = res.data.data;
          res.data.data.user.is_check = true;
          this.user = res.data.data.user;
          this.user.is_my = true;
          this.choose_arr.push(this.user);
          this.choose_num = 1;
        }
      });
    },
    clicksearch() {
      //搜索事件
      let { search, Screening_arr } = this;
      let data = {};
      if (this.type == 1) {
        let start_score = "";
        let end_score = "";
        let business_line_id_arr = [];
        this.my_business.forEach((item) => {
          business_line_id_arr.push(item.business_line_id);
        });
        let business_line_id = business_line_id_arr.join(",");
        Screening_arr.forEach((item) => {
          if (item.type == 2) {
            //分数对比
            if (item.id == 1) {
              start_score = 0;
              end_score = 30;
            }
            if (item.id == 2) {
              start_score = 30;
              end_score = 60;
            }
            if (item.id == 3) {
              start_score = 60;
              end_score = 100;
            }
            if (item.id == 4) {
              start_score = 100;
              end_score = "";
            }
          }
          if (item.type == 3) {
            business_line_id = item.business_line_id;
          }
        });
        data = {
          name: search,
          start_score,
          end_score,
          business_line_id,
        };
        this.getsearchPersonalList(data);
      }
    },
    getsearchPersonalList(data) {
      //个人对比搜索列表
      let choose_arr = this.choose_arr;
      searchPersonalList(data).then((res) => {
        if (res.data.code == 200) {
          let list = res.data.data.data;
          list.forEach((item) => {
            item.is_check = false;
            choose_arr.forEach((itm) => {
              if (item.id == itm.id) {
                if (itm.is_check) {
                  item.is_check = true;
                }
              }
            });
          });
          this.total = res.data.data.total;
          this.line_arr = JSON.parse(JSON.stringify(list));
        }
      });
    },
    getcompanyContrast(data) {
      //我的部门对比页
      let choose_arr = this.choose_arr;
      companyContrast(data).then((res) => {
        this.is_flag = false
        if (res.data.code == 200) {
          let list = [];
          if (res.data.data.data) {
            list = res.data.data.data;
            list.forEach((item) => {
              item.is_check = false;
              choose_arr.forEach((itm) => {
                if (item.id == itm.id) {
                  if (itm.is_check) {
                    item.is_check = true;
                  }
                }
              });
            });
          }
          this.line_arr = JSON.parse(JSON.stringify(list));
          this.total = res.data.data.total;
        }
      });
    },
    getotherCompany(data) {
      //其他部门
      let choose_arr = this.choose_arr;
      otherCompany(data).then((res) => {
        this.is_flag = false
        if (res.data.code == 200) {
          let list = res.data.data.data;
          list.forEach((item) => {
            item.is_check = false;
            choose_arr.forEach((itm) => {
              if (item.id == itm.id) {
                if (itm.is_check) {
                  item.is_check = true;
                }
              }
            });
          });
          this.total = res.data.data.total;
          this.line_arr = JSON.parse(JSON.stringify(list));
        }
      });
    },
    handleisPersonal(i) {
      //部门切换
      if (this.is_flag) {
        return
      }
      this.is_flag = true
      let { user_position_level } = this;
      if (user_position_level > 1) {
        this.type = 2;
      } else {
        this.type = 1;
      }
      this.currentPage = 1;
      this.search = "";
      this.Screening_arr = [];
      let { battle_type_arr, fraction_radio, business_arr } = this;
      this.getbattle_type(battle_type_arr);
      fraction_radio.forEach((item) => {
        item.is_check = false;
      });
      business_arr.forEach((item) => {
        item.is_check = false;
      });
      if (user_position_level > 1) {
        this.type = 2;
      } else {
        this.type = 1;
      }
      this.choose_arr = [];
      this.line_arr = [];
      this.show_left = 1;
      this.choose_num = 0;
      this.isPersonal = i;
      if (i == 1) {
        this.getcompanyContrast({
          contrast_type: this.contrast_type,
        });
        this.business_arr = this.my_business;
      } else {
        allBusiness().then((res) => {
          if (res.data.code == 200) {
            let business_line_id_arr = [];
            let list = res.data.data;
            list.forEach((item) => {
              item.is_check = false;
              item.type = 3;
              business_line_id_arr.push(item.id);
            });
            this.other_business = list;
            this.business_arr = list;
            this.getotherCompany({
              contrast_type: this.contrast_type,
              business_line_id: business_line_id_arr.join(","),
            });
          }
        });
      }
    },
    chooseone(val) {
      //页面勾选
      let that = this;
      let line_arr = JSON.parse(JSON.stringify(that.line_arr));
      let arr = JSON.parse(JSON.stringify(that.choose_arr));
      let bool = val.is_check;
      let is_my_bool = false;
      if (that.type == 1) {
        if (val.id == that.user.id) {
          that.$message.error("不能取消选中自己");
          is_my_bool = true;
          return;
        }
      }
      if (is_my_bool) {
        return;
      }
      if (!bool) {
        //选中
        if (arr.length == 4) {
          that.$message.error(
            this.type == 1 ? "最多选择4人" : "最多选择4个部门"
          );
          return;
        }
        val.is_check = true;
        arr.push(val);
      } else {
        arr.forEach((itm, idx) => {
          if (itm.id == val.id) {
            arr.splice(idx, 1);
          }
        });
      }
      line_arr.forEach((item) => {
        if (item.id == val.id) {
          item.is_check = !bool;
        }
      });
      if (arr.length > 0) {
        that.show_left = 2;
      } else {
        that.show_left = 1;
      }
      if (that.type == 1) {
        if (arr.length == 1) {
          that.show_left = 1;
        }
      }
      this.choose_arr = JSON.parse(JSON.stringify(arr));
      this.line_arr = JSON.parse(JSON.stringify(line_arr));
      this.choose_num = arr.length;
    },
    wellgobattle(val) {
      //左侧选中
      let i = 0;
      let bool = val.is_check;
      let arr = this.choose_arr;
      arr.forEach((item) => {
        if (item.id == val.id) {
          item.is_check = !bool;
        }
        if (item.is_check) {
          i++;
        }
      });
      this.choose_num = i;
      this.choose_arr = arr;
    },
    delchoose(item) {
      let arr = this.choose_arr;
      let line_arr = JSON.parse(JSON.stringify(this.line_arr));
      let is_my_bool = false;
      arr.forEach((itm) => {
        if (itm.is_my) {
          if (itm.id == item.id) {
            is_my_bool = true;
          }
          return;
        }
      });
      if (is_my_bool) {
        if (this.user_position_level == 1) {
          this.$message.error("不能删除自己");
          return;
        }
      }
      let i = 0;
      arr.forEach((itm, idx) => {
        if (itm.id == item.id) {
          arr.splice(idx, 1);
        }
      });
      line_arr.forEach((itm) => {
        if (itm.id == item.id) {
          itm.is_check = false;
        }
      });
      if (arr.length > 0) {
        this.show_left = 2;
      } else {
        this.show_left = 1;
      }
      if (this.type == 1) {
        if (this.user_position_level == 1) {
          if (arr.length == 1) {
            this.show_left = 1;
          }
        }
      }
      arr.forEach((itm) => {
        if (itm.is_check) {
          i++;
        }
      });
      this.choose_num = i;
      this.line_arr = JSON.parse(JSON.stringify(line_arr));
      this.choose_arr = arr;
    },
    handleCurrentChange(val) {
      this.currentPage = val * 1;
      let id = "";
      this.getlist(id);
    },
    result(i) {
      this.result_arr = [];
      this.result_type = i;
      let arr = this.choose_arr;
      if (this.choose_num < 2) {
        if (this.type == 1) {
          this.$message.error("最少选择两人");
        } else {
          this.$message.error("最少选择两个部门");
        }
        return;
      }
      let data = {};
      if (this.type == 1) {
        //个人对比
        let user_ids_arr = [];
        arr.forEach((item) => {
          if (item.is_check) {
            user_ids_arr.push(item.id);
          }
        });
        data = {
          type: i,
          user_ids: user_ids_arr.join(","),
        };
        this.getcomparisonResults(data);
      } else {
        let company_ids_arr = [];
        arr.forEach((item) => {
          if (item.is_check) {
            company_ids_arr.push(item.id);
          }
        });
        data = {
          type: i,
          company_ids: company_ids_arr.join(","),
          contrast_type: this.contrast_type,
        };
        this.getcompanyComparisonResults(data);
      }
    },
    getcomparisonResults(data) {
      //个人对比结果
      comparisonResults(data).then((res) => {
        this.result_arr = res.data.data.data;
        this.max_array = res.data.data.max_array;
        this.is_result = true;
      });
    },
    getcompanyComparisonResults(data) {
      //部门对比结果
      companyComparisonResults(data).then((res) => {
        if (res.data.code == 200) {
          this.result_arr = res.data.data.data;
          this.max_array = res.data.data.max_array;
          this.is_result = true;
        }
      });
    },
    back() {
      this.is_result = false;
      this.result_arr = [];
    },
  },
};
</script>

<style lang="scss">
.Analysis_data_box {
  background: #eef5f9;
  flex: 1;
  .Analysis_data {
    background: #eef5f9;
    padding: 20px;
    display: flex;
    width: 100%;
    height: 100%;
    .Analysis_data_info {
      flex: 1;
      .Analysis_data_head {
        background: #fff;
        padding-left: 48px;
        .Analysis_data_head_dep_box {
          height: 60px;
          background: #ffffff;
          opacity: 1;
          display: flex;
          align-items: center;
          .Analysis_data_head_dep {
            width: 280px;
            height: 40px;
            display: flex;
            align-items: center;
            border-radius: 5px;
            border: 1px solid #ccc;
            div {
              width: 50%;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              cursor: pointer;
            }
            .isPersonal {
              background: #3a85fc;
              color: #fff;
            }
          }
        }
        .Analysis_data_head_search {
          height: 60px;
          background: #ffffff;
          display: flex;
          align-items: center;
          width: 442px;
          .el-input {
            height: 32px;
            background: #ffffff;
            opacity: 1;
            .el-input__icon {
              line-height: 32px;
            }
            input {
              height: 32px;
            }
          }
          .Analysis_data_head_search_buttom {
            width: 60px;
            height: 32px;
            background: #3a85fc;
            opacity: 1;
            border-radius: 2px;
            line-height: 32px;
            text-align: center;
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            margin-left: 46px;
          }
        }
      }
      .Analysis_data_screen {
        margin-top: 10px;
        padding: 20px 48px;
        // padding-left: 48px;
        background: #ffffff;
        .Analysis_data_screen_head {
          height: 65px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #efefef;
          .Analysis_data_screen_head_right {
            display: flex;
            flex: 1;
            justify-content: space-between;
            flex-wrap: wrap;
            .Analysis_data_screen_head_one_box {
              display: flex;
              .Analysis_data_screen_head_one {
                height: 28px;
                background: #ecf5ff;
                opacity: 1;
                border-radius: 2px;
                padding: 0 10px;
                font-size: 13px;
                font-weight: 400;
                line-height: 17px;
                color: #3a85fc;
                text-align: center;
                line-height: 28px;
                margin-right: 20px;
                i {
                  cursor: pointer;
                }
              }
            }
            .Analysis_data_screen_head_del {
              font-size: 14px;
              font-weight: 400;
              line-height: 19px;
              color: #3a85fc;
              cursor: pointer;
              line-height: 28px;
              text-align: center;
            }
          }
        }
        .Analysis_data_screen_line {
          height: 35px;
          display: flex;
          align-items: center;
        }
        .Analysis_data_screen_tit {
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          width: 70px;
          color: #8c8c8c;
          white-space: nowrap;
        }
        .radio_box {
          display: flex;
          align-items: center;
          cursor: pointer;
          flex-wrap: wrap;
          div {
            margin-right: 30px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            img {
              margin-right: 5px;
            }
          }
        }
      }
      .Analysis_data_screen_box {
        margin-top: 10px;
        background: #fff;
        height: 460px;
        overflow: auto;
        .line {
          height: 110px;
          opacity: 1;
          border-radius: 2px 2px 0px 0px;
          border-bottom: 1px solid #ececec;
          margin-left: 23px;
          margin-right: 68px;
          display: flex;
          .line_left {
            flex: 1;
            display: flex;
            align-items: center;
            .line_check {
              width: 16px;
              height: 16px;
              background: #ffffff;
              opacity: 1;
              border-radius: 2px;
              margin-right: 30px;
            }
            .line_deail {
              display: flex;
              flex: 1;
              align-items: center;
              img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin-right: 20px;
              }
              .line_info {
                flex: 1;
                .name {
                  font-size: 16px;
                  font-weight: 600;
                  color: #262626;
                  font-family: Source Han Sans CN;
                }
                .year {
                  width: 60px;
                  height: 22px;
                  background: #ecf5ff;
                  border-radius: 2px;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 22px;
                  color: #3a85fc;
                  text-align: center;
                  margin: 10px 0;
                }
                .line_line {
                  height: 14px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #595959;
                  opacity: 1;
                }
              }
            }
          }
          .line_right {
            width: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 110px;
            div:first-child {
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              color: #8c8c8c;
              white-space: nowrap;
            }
            div:last-child {
              font-size: 24px;
              font-weight: 500;
              line-height: 41px;
              color: #262626;
              opacity: 1;
            }
          }
        }
        .Analysis_data_screen_box_none {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 100px;
          padding-top: 100px;
          img {
            width: 286px;
            height: 189px;
          }
          div {
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            color: #8c8c8c;
            margin-top: 30px;
          }
        }
      }
      .block {
        background: #fff;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-right: 40px;
      }
    }
    .Analysis_data_personal {
      width: 30%;
      min-width: 300px;
      max-width: 458px;
      background: #ffffff;
      opacity: 1;
      margin-left: 20px;
      .Analysis_data_personal_tit {
        height: 26px;
        font-size: 20px;
        font-weight: bold;
        line-height: 26px;
        color: #262626;
        width: 100%;
        text-align: center;
        padding-top: 22px;
      }
      .Analysis_data_personal_fraction {
        width: 100%;
        height: 240px;
        display: flex;
        justify-content: center;
        .img {
          width: 227px;
          height: 240px;
          margin-top: 120px;
          position: relative;
          img {
            position: absolute;
            top: 0;
            left: 0;
          }
          .img_wuhu {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            div:first-child {
              font-size: 38px;
              font-weight: bold;
              line-height: 50px;
              color: #fff;
              opacity: 1;
            }
            div:last-child {
              font-size: 14px;
              font-weight: 400;
              line-height: 19px;
              color: #fff;
              opacity: 1;
            }
          }
        }
      }
      .Analysis_data_personal_fraction_box {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 210px;
        .box {
          margin: 0 30px;
          width: 380px;
          height: 250px;
          box-shadow: 0px 3px 6px rgba(21, 41, 68, 0.05);
          .box_head {
            width: 100%;
            height: 45px;
            background: linear-gradient(89deg, #3984ff 0%, #50a0ff 100%);
            opacity: 1;
            display: flex;
            div {
              width: 25%;
              text-align: center;
              line-height: 45px;
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
            }
          }
          .box_line {
            width: 100%;
            height: 50px;
            opacity: 1;
            display: flex;
            div {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 25%;
              // line-height: 50px;
              text-align: center;
            }
            .box_line_width {
              width: 25%;
              display: flex;
              justify-content: space-between;
              div:nth-child(1) {
                width: 100%;
              }
              div:last-child {
                width: 1%;
              }
            }
          }
          .box_line:nth-child(2n + 1) {
            background: #f1f8ff;
          }
        }
      }
    }
    .choose_box {
      width: 30%;
      min-width: 300px;
      max-width: 458px;
      background: #ffffff;
      opacity: 1;
      margin-left: 20px;
      .choose_box_tit {
        height: 60px;
        background: #ffffff;
        line-height: 60px;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 60px;
        color: #262626;
        border-bottom: 1px solid #ececec;
      }
      .choose_box_line {
        height: 100px;
        background: #ffffff;
        border-bottom: 1px solid #ececec;
        display: flex;
        align-items: center;
        padding-left: 26px;
        padding-right: 40px;
        .choose_box_line_left {
          flex: 1;
          display: flex;
          align-items: center;
          .choose_box_line_check {
            width: 18px;
            height: 18px;
            opacity: 1;
            cursor: pointer;
            margin-right: 22px;
          }
          .choose_detail {
            flex: 1;
            .name {
              font-size: 16px;
              font-weight: 600;
              color: #262626;
              font-family: Source Han Sans CN;
            }
            .year {
              width: 60px;
              height: 22px;
              background: #ecf5ff;
              border-radius: 2px;
              font-size: 12px;
              font-weight: 400;
              line-height: 22px;
              color: #3a85fc;
              text-align: center;
              margin: 10px 0;
            }
            .line_line {
              height: 14px;
              font-size: 14px;
              font-weight: 400;
              color: #595959;
              opacity: 1;
            }
          }
        }
        .choose_box_line_right {
          width: 24px;
          font-size: 24px;
          color: #8c8c8c;
        }
      }
      .choose_num {
        margin-left: 26px;
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #8c8c8c;
        opacity: 1;
      }
      .choose_button {
        width: 180px;
        margin: 100px auto;
        margin-bottom: 0px;
        div {
          width: 180px;
          height: 50px;
          background: #3a85fc;
          border-radius: 2px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 50px;
          cursor: pointer;
        }
        div:last-child {
          margin-top: 30px;
        }
      }
    }
  }
  .duibi_box {
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);

    left: 0;
    z-index: 2000;
    .result_box {
      height: 511px;
      overflow: hidden;
      background: #ffffff;
      opacity: 1;
      position: absolute;
      border-radius: 4px 4px 0px 0px;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 80%;
      max-width: 1400px;
      min-width: 1200px;
      .result {
        background: #fff;
        width: 100%;
        height: 100%;
        min-width: 1000px;
        .result_head {
          height: 70px;
          background: #3a85fc;
          border-radius: 4px 4px 0px 0px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          .result_back {
            font-size: 18px;
            font-weight: 400;
            line-height: 19px;
            color: #ffffff;
            opacity: 1;
            position: absolute;
            left: 30px;
            cursor: pointer;
          }
          div:last-child {
            font-size: 20px;
            font-weight: 400;
            color: #ffffff;
            opacity: 1;
          }
        }
        .result_line {
          width: 100%;
          display: flex;
          .result_lefttit {
            // float: left;
            width: 180px;
            div {
              height: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              font-weight: 400;
              color: #262626;
              opacity: 1;
              border-left: 1px solid #e5e5e5e5;
              border-bottom: 1px solid #e5e5e5e5;
              text-align: center;
            }
            div:first-child {
              height: 80px;
              font-size: 18px;
              font-weight: bold;
              color: #262626;
              opacity: 1;
            }
          }
          .result_line_one_box {
            flex: 1;
            display: flex;
            justify-content: space-around;
            .result_line_one {
              // float: left;
              div {
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: 400;
                color: #262626;
                opacity: 1;
                border-left: 1px solid #e5e5e5e5;
                border-bottom: 1px solid #e5e5e5e5;
              }
              div:first-child {
                height: 80px;
                font-size: 18px;
                font-weight: bold;
                color: #262626;
                opacity: 1;
              }
              .one_head {
                display: flex;
                img {
                  width: 60px;
                  height: 60px;
                  border-radius: 50%;
                  margin-left: 20px;
                  margin-right: 10px;
                }
                div {
                  border: none;
                }
                .one_head_right {
                  flex: 1;
                  justify-content: space-around;
                  .one_info {
                    display: flex;
                    flex-direction: column;
                    height: auto;
                    align-items: flex-start;
                    .one_info_name {
                      font-size: 18px;
                      font-weight: bold;
                      line-height: 24px;
                      color: #262626;
                      height: auto;
                      white-space: nowrap;
                    }
                    .one_info_dep {
                      font-size: 16px;
                      font-weight: 400;
                      line-height: 21px;
                      color: #8c8c8c;
                      height: auto;
                    }
                  }
                  .one_fs {
                    font-size: 24px;
                    font-weight: bold;
                    color: #3a85fc;
                    opacity: 1;
                  }
                }
              }
            }
            .result_line_one:last-child {
              div {
                border-right: 1px solid #e5e5e5;
              }
              .one_head {
                div {
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>