<template>
  <div class="order_detail_box" style="flex: 1">
    <el-main>
      <div class="order_detail_title" :style="{'justify-content':params.class_type == 2 ? 'flex-start':'space-between'}">
        <div class="left_box">
          <div
            class="fir_box pointer"
            @click="
              is_refund = true;
              openrefund();
            "
            v-if="this.params.class_type == 1 || this.order_info.is_refund_staff==1"
            @mouseover="titimg = '2'"
            @mouseout="titimg = '1'"
          >
            <img
              v-if="titimg == '1'"
              src="../../assets/img/tuikuan_down.png"
              alt=""
            />
            <img
              v-if="titimg == '2'"
              src="../../assets/img/tuikuan_up.png"
              alt=""
            />
            退款申请
          </div>
          <div
            class="fir_box pointer"
            @click="
              is_Addarrival = true;
              open();
            "
         
          >
             <!-- v-if="isAddArrival" -->
          <!--order_info.accounts_receivable.split('.')[0]-->
            <i class="el-icon-s-order"></i>
            新增到账
          </div>
        </div>
        <div class="right_box">
          <div class="fir_box pointer" @click="goback">返回上一级</div>
        </div>
      </div>

      <div class="el-row order-details-center">
        <el-col :span="8">
          <p class="item-tit">客户信息</p>
          <div>
            <ul>
              <li>
                <span class="tit">公司名称:</span>
                <span>{{order_info.customer ? order_info.customer.name : "--"}}</span>
              </li>
              <li>
                <span class="tit">联系人:</span>
                <span>{{ order_info.contacts ? order_info.contacts.contacts : "--"}}</span>
              </li>
              <li>
                <span class="tit">电话:</span>
                <span>{{ order_info.contacts ? order_info.contacts.phone : "--"}}</span>
              </li>
               <li>
                <span class="tit">渠道来源:</span>
                <span>{{transfChannel(channel)}}</span>
              </li>
            </ul>
          </div>
        </el-col>
        <el-col :span="8">
           <p class="item-tit">合同信息</p>
          <div>
            <ul>
              <li>
                <span class="tit">合同编号:</span>
                <span>{{ order_info.contract_number}}</span>
              </li>
              <li>
                <span class="tit">合同金额:</span>
                <span>{{order_info.contract_money}}</span>
              </li>
              <li>
                <span class="tit">签约产品:</span>
                <span>{{ order_info.product_name
              ? order_info.product_name.product_name
              : "--"}}</span>
              </li>
               <li>
                <span class="tit">服务起止时间:</span>
                <span>{{order_info.service_time ? order_info.service_time : '--'}} 至  {{order_info.service_time_end ? order_info.service_time_end : '--'}} </span>
              </li>
               <li>
                <span class="tit">剩余服务天数:</span>
                <span>{{order_info.service_surplus_day}} </span>
              </li>
            </ul>
          </div> 
        </el-col>
        <el-col :span="8">
          <p class="item-tit">订单信息</p>
          <div>
            <ul>
              <li>
                <span class="tit">订&nbsp;单&nbsp;号:</span>
                <span>{{ order_info.order_number }}</span>
              </li>
              <li>
                <span class="tit">订单款项状态:</span>
                <span>{{order_info.order_type == 1 ? '定金' : order_info.order_type == 2 ? '已到全款' : order_info.order_type == 3 ? '绝对全款' : ''}}</span>
              </li>
              <li>
                <span class="tit">下单状态:</span>
                <span>{{  order_info.type == 0 ? '未下单' : '已下单'}}</span>
                <span class="edit-blue" v-show="order_info.type == 0" @click="orderStatusEdit = true">修改</span>
              </li>
               <li>
                <span class="tit">跟进人:</span>
                <span>{{followUser}}</span>
              </li>
            </ul>
          </div>
        </el-col>
      </div>
    
      <div class="table_box">
        <div class="table-top">
          <ul>
            <li class="red">实收金额：{{order_info.achievement}}</li>
            <!-- <li class="blue">应收尾款：{{order_info.accounts_receivable}}</li> -->
          </ul>
        </div>
        <el-table
          :data="tableData"
          border
          :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
          style="width: 100%"
        >
          <el-table-column label="到账时间">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ scope.row.to_time ? scope.row.to_time : "--" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="到账金额">
            <template slot-scope="scope">
              <div class="acc_table_company_name">{{ scope.row.to_money }}</div>
            </template>
          </el-table-column>
          <el-table-column label="到账类型">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{
                  scope.row.type == 1
                    ? "定金"
                    : scope.row.type == 2
                    ? "尾款"
                    : scope.row.type == 3
                    ? "全款"
                    : "退款"
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="收款方式">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{
                  scope.row.collection_id == 1
                    ? "微信"
                    : scope.row.collection_id == 2
                    ? "支付宝"
                    : scope.row.collection_id == 3
                    ? "银行卡"
                    : scope.row.collection_id == 4
                    ? "对公账户"
                    : scope.row.collection_id == 5
                    ? '收钱吧"'
                    : scope.row.collection_id == 6
                    ? "银行私账"
                    : scope.row.collection_id == 7
                    ? "POS机"
                    : scope.row.collection_id == 8
                    ? "现金"
                    : "--"
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="分单人" width="200">
            <template slot-scope="scope">
              <div class="acc_table_company">
                <div
                  v-if="scope.row.order_data_branch.length > 0"
                  v-for="(item, index) in scope.row.order_data_branch"
                  :key="index"
                >
                  {{ item.user? item.user.name:'' }}{{ item.user? item.companys? `【${item.companys.name}】`: '':"" }}
                </div>
                <div v-if="scope.row.order_data_branch.length == 0">--</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="分单金额">
            <template slot-scope="scope">
              <div class="acc_table_company">
                <div v-if="scope.row.order_data_branch.length == 0">--</div>
                <div
                  v-if="scope.row.order_data_branch.length > 0"
                  v-for="(item, index) in scope.row.order_data_branch"
                  :key="index"
                >
                  {{ item.money }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="促销类型">
            <template slot-scope="scope">
              <div class="acc_table_company" v-if="scope.row.promotion">
                {{
                  scope.row.promotion.type == 1
                    ? "实物"
                    : scope.row.promotion.type == 2
                    ? "返现"
                    : scope.row.promotion.type == 3
                    ? "打折"
                    : scope.row.promotion.type == 4
                    ? "赠送服务时长"
                    : "赠送其它服务"
                }}
              </div>
              <div class="acc_table_company" v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="促销成本">
            <template slot-scope="scope">
              <div class="acc_table_company" v-if="scope.row.promotion">
                {{ scope.row.promotion.money }}
              </div>
              <div class="acc_table_company" v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="退款原因说明">
            <template slot-scope="scope">
              <div class="acc_table_company" v-if="scope.row.remarks">
                <el-popover placement="bottom" width="400" trigger="click">
                  <div slot="reference" class="pointer acc_table_company_click">
                    点击查看
                  </div>
                  <div v-html="scope.row.remarks"></div>
                </el-popover>
              </div>
              <div class="acc_table_company" v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="审核状态">
            <template scope="scope">
              <i
                :class="
                  scope.row.status == 0
                    ? 'wait_status'
                    : scope.row.status == 1
                    ? 'pass_status'
                    : scope.row.status == 2
                    ? 'reject_status'
                    : 'revoke_status'
                "
              ></i>
              {{
                scope.row.status == 0
                  ? "待审核"
                  : scope.row.status == 1
                  ? "通过"
                  : scope.row.status == 2
                  ? "驳回"
                  : scope.row.status == 3
                  ? "撤销"
                  : ""
              }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>

    <div
      class="add_Arrival_box"
      style="background: rgba(0, 0, 0, 0.5)"
      v-if="is_Addarrival"
    >
      <div class="add_Arrival">
        <!-- 新增到账 -->
        <div class="add_Arrival_head">
          新增到账
          <i
            class="pointer el-icon-close"
            @click="
              is_Addarrival = false;
              isDetail = true;
            "
          ></i>
        </div>
        <div class="add_Arrival_box_info">
          <div class="add_Arrival_head_tit"><i></i>公司信息</div>
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">公司名称：</div>
            <div class="add_Arrival_line_inp">
              {{ order_info.customer ? order_info.customer.name : "--" }}
            </div>
          </div>
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">客户姓名：</div>
            <div class="add_Arrival_line_inp">
              {{ order_info.contacts.contacts }}
            </div>
          </div>
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">产品名称：</div>
            <el-select v-model="pro_id" placeholder="请选择产品">
              <el-option
                v-for="item in prooption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="add_Arrival_head_tit"><i></i>到账信息</div>
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">本次实收金额：</div>
            <el-input
              type="text"
              v-model="Arrival_money"
              @blur="blurNumber"
              :placeholder="`可输入尾款金额${order_info.accounts_receivable}`"
            />
          </div>
          
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">收款方式：</div>
            <el-select v-model="collection" placeholder="请选择收款方式">
              <el-option
                v-for="item in collectionList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
       
          <div
            class="add_Arrival_line"
            style="align-items: flex-start; height: 110px"
          >
            <div class="add_Arrival_line_tit">收款凭证：</div>
            <div class="contract_img">
              <div class="flow_kind_text">
                <div
                  class="flow_kind_text_img"
                  v-for="(item, index) in collection_file_list"
                  :key="index"
                >
                  <img
                    :src="item.file_url"
                    alt=""
                    style="width: 100px; height: 100px"
                  />
                  <i
                    class="el-icon-circle-close"
                    @click="handleDel_img(index)"
                    style="color: #ccc"
                  ></i>
                </div>
                <div
                  class="image_info"
                  @click="chooseFile"
                  v-if="collection_file_list.length < 3"
                >
                  <img src="@/assets/img/upload_img.png" alt="" />
                  <span>上传相关图片</span>
                  <input
                    type="file"
                    style="display: none"
                    ref="choosePhoto"
                    @change="photoChange($event)"
                    id="upload_file"
                    v-if="upload_img"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="add_Arrival_line"
            style="align-items: flex-start; height: 110px"
          >
            <div class="add_Arrival_line_tit">备注：</div>
            <el-input
              type="textarea"
              :rows="3"
              placeholder="请输入内容"
              v-model="remarks"
            >
            </el-input>
          </div>
          <div class="add_Arrival_line">
            <div class="add_Arrival_line_tit">是否促销：</div>
            <div class="add_Arrival_line_check">
              <el-radio v-model="radio_id" label="1" @change="handleChangePro"
                >否</el-radio
              >
              <el-radio v-model="radio_id" label="2" @change="handleChangePro"
                >是</el-radio
              >
            </div>
          </div>
          <div class="add_Arrival_line" v-if="radio_id == 2">
            <div class="add_Arrival_line_tit">促销类型：</div>
            <el-input
              type="text"
              disabled
              placeholder="暂无促销"
              v-if="PromotionList.length == 0"
            />
            <el-select
              v-model="Promotion_id"
              placeholder="请选择促销类型"
              v-else
            >
              <el-option
                v-for="(item, index) in PromotionList"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="add_Arrival_head_tit"><i></i>分单信息</div>
          <div v-for="(item, index) in contract_fd_list" :key="index">
            <div class="add_Arrival_line" style="padding-right: 160px">
              <div class="add_Arrival_line_tit">分单人：</div>
              <el-autocomplete
                class="inline-input"
                v-model="item.user_name"
                :fetch-suggestions="querySearch"
                placeholder="请输入姓名选择分单人"
                :trigger-on-focus="false"
                @select="handleSelect"
              ></el-autocomplete>
              <i
                v-if="contract_fd_list.length > 1"
                class="el-icon-remove-outline pointer"
                style="font-size: 30px; color: #ccc; margin-left: 20px"
                @click="handleDel_fendan(index)"
              ></i>
            </div>
            <div class="add_Arrival_line" style="padding-right: 160px">
              <div class="add_Arrival_line_tit">分单金额：</div>
              <el-input
                type="text"
                v-model="item.to_money"
                placeholder="请输入金额"
              />
            </div>
          </div>
          <div class="add_Arrival_line_add pointer" @click="handleAdd_fendan">
            <i class="el-icon-plus"></i>
            新增分单人
          </div>
          <div class="add_Arrival_bottom">
            <div
              @click="
                isMc = false;
                is_Addarrival = false;
              "
            >
              取消
            </div>
            <div @click="handleOK">确认</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="refund"
      style="
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        z-index: 100;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      "
      v-if="is_refund"
    >
      <div class="refund_box">
        <div class="refund_box_head">
          申请退款
          <i
            class="pointer el-icon-close"
            @click="
              is_refund = false;
              isDetail = true;
            "
            style="position: absolute; right: 20px; padding: 10px"
          ></i>
        </div>
        <div class="refund_box_info">
          <div class="refund_box_info_tit"><i></i>退款信息</div>

          <div class="refund_box_info_title">
            客户类型：{{ customer_type == 1 ? "新" : "老" }}客户
          </div>
          <div class="refund_box_info_title">
            退款期间类型：{{ refund_period == 1 ? "当期" : "延期" }}退款
          </div>
          <div class="refund_box_info_title">退款金额</div>
          <el-input v-model="to_money" placeholder="请输入金额"></el-input>
          <div class="refund_box_info_title">退款原因说明</div>
          <div @click="wangfocus('1')">
            <Wang
              v-model="tk_remarks"
              :isClear="isClear"
              @change="change"
              key="1"
            />
          </div>
          <div class="refund_box_info_title">退款原因类型</div>
          <el-select v-model="refund_type" placeholder="请选择退款原因类型">
            <el-option
              v-for="item in refund_type_arr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="refund_box_info_title">预计支付日期</div>
          <el-date-picker
            v-model="refund_expect_pay_date"
            type="date"
            prefix-icon="sda"
            placeholder="请选择预计支付日期"
          >
          </el-date-picker>
          <div class="refund_box_info_title">业绩时间</div>
          <el-date-picker
            v-model="refund_achi_date"
            type="date"
            prefix-icon="sda"
            placeholder="请选择业绩时间"
          >
          </el-date-picker>
          <div class="refund_box_info_title">协商支付方式</div>
          <div @click="wangfocus('2')">
            <Wang
              v-model="refund_consult_pay_mode"
              :isClear="isClear"
              @change="change"
              key="2"
            />
          </div>
          <div
            class="refund_box_info_tit"
            style="margin-top: 30px; maigin-bottom: 20px"
          >
            <i></i>分单人信息
          </div>
          <div v-for="(item, index) in tk_fendan_list" :key="index">
            <div class="refund_box_info_title">分单人</div>
            <div
              class="add_Arrival_line"
              style="display: flex; align-items: center"
            >
              <el-autocomplete
                class="inline-input"
                v-model="item.user_name"
                :fetch-suggestions="querySearch"
                placeholder="请输入姓名选择分单人"
                :trigger-on-focus="false"
                @select="Select"
                style="width: 90%"
              ></el-autocomplete>
              <i
                v-if="tk_fendan_list.length > 1"
                class="el-icon-remove-outline pointer"
                style="font-size: 30px; color: #ccc; margin-left: 20px"
                @click="Del_TK_fendan(index)"
              ></i>
            </div>
            <div class="refund_box_info_title">分单人扣除业绩</div>
            <div class="add_Arrival_line">
              <el-input
                type="text"
                v-model="item.money"
                placeholder="请输入金额"
              />
            </div>
          </div>
          <div class="add_Arrival_line_add pointer" @click="add_Tk_fendan">
            <i class="el-icon-plus"></i>
            新增分单人
          </div>
          <div class="add_refund_bottom">
            <div @click="is_refund = false">取消</div>
            <div @click="onorderRefund">确认</div>
          </div>
        </div>
      </div>
    </div>

    <!--下单状态 弹窗-->
    <el-dialog
      title="下单状态"
      :visible.sync="orderStatusEdit"
      width="450px"
      custom-class="order-status-dialog"
    >
      <el-row>
        <el-col :span="24">下单状态:</el-col>
      </el-row>
      <el-row  class="status-row">
        <el-col :span="12">
           <el-radio v-model="orderStatus" label="0">未下单</el-radio>
        </el-col>
        <el-col :span="12">
           <el-radio v-model="orderStatus" label="1">已下单</el-radio>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button  size="small" @click="orderStatusEdit = false">取 消</el-button>
        <el-button type="primary"  size="small" @click="submitEditStatus">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  orderUserList,
  PromotionList,
  getUserData,
  updateOrder,
  searchCompanysList,
} from "../../api/User/customer";
import { collectionList } from "../../api/User/finance";
import { uploadFile } from "../../api/User/index";
import {
  detail,
  addOrderMore,
  orderRefund,
  refundQuery,
  editPlaceOrder
} from "../../api/User/order_list";

import wang from "../../views/wang";
export default {
  components: {
    Wang: wang,
  },
  data() {
    return {
      isAddArrival: false,
      orderStatusEdit: false,
      orderStatus: '',
      channel:'',//渠道来源
      followUser:'',//跟进人
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
      pickerOptionss: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      titimg: "1",
      params: {},
      order_info: {},
      tableData: [],
      is_Addarrival: false,
      prooption: [],
      pro_id: "", //到账产品
      Arrival_money: "", //到账金额
      Arrivaloption: [
        { value: "1", label: "定金" },
        { value: "2", label: "尾款" },
        { value: "3", label: "全款" },
      ],
      Arrival_type: "", //到账类型
      collectionList: [], //收款方式列表
      collection: "", //收款方式
      time: "", //到账时间
      // contract_money:'',              //合同金额
      // contract_number:'',         //合同单号
      radio_id: "1", //是否促销
      PromotionList: [], //促销列表
      Promotion_id: "", //选择促销id
      contract_fd_list: [
        {
          user_id: "",
          user_name: "",
          to_money: "",
        },
      ],
      upload_img: true,
      fluterArr: [], //分单搜索人
      // chooseFile_what:'',                 //1 合同  2 收款凭证
      collection_file_list: [], //收款文件
      remarks: "", //备注
      customer_type: "", //客户类型
      refund_period: "", //退款类型
      is_refund: false, //是否退款申请
      to_money: "", //退款金额
      refund_expect_pay_date: "", //预计支付日期
      refund_achi_date: "", //业绩时间
      refund_consult_pay_mode: "", //支付方式
      refund_type: "",
      refund_type_arr: [
        { value: "1", label: "公账转入退私账" },
        { value: "2", label: "全款转入退定金" },
        { value: "3", label: "投诉协商退款" },
        { value: "4", label: "其他原因" },
      ],
      tk_remarks: "",
      tk_fendan_list: [
        {
          user_id: "",
          company_id: "",
          money: "",
          user_name: "",
        },
      ],
      permissions_id_string: "",
      add_flag: false,
      editor_what: "",
      isClear: false,
    };
  },
  created() {
  
    // console.log(this.$route.params);
    if (JSON.stringify(this.$route.params) != "{}") {
      localStorage.setItem("params", JSON.stringify(this.$route.params));
    }
    // console.log(localStorage.getItem('params'));
    let token = JSON.parse(localStorage.getItem("token"));
    for (let i in token.role_permissions) {
      if (token.role_permissions[i].permissions == "Marketing") {
        for (let v in token.role_permissions[i].data) {
          if (
            token.role_permissions[i].data[v].permissions == "system_order_list"
          ) {
            this.permissions_id_string =
              token.role_permissions[i].data[v].permissions_id_string;
          }
        }
      }
    }
    setTimeout(() => {
      if (localStorage.getItem("params")) {
        this.params = JSON.parse(localStorage.getItem("params"));
        this.getDetails();
      }
    }, 10);
  },
 
  methods: {
    blurNumber(){
      if(!(order_info.accounts_receivable > 0)) return;
      if(this.Arrival_money > this.order_info.accounts_receivable){
         this.$message({
            type: "error",
            message: '尾款金额不能大于合同金额',
          });
          this.Arrival_money = ''
      }
    },
    getDetails(){
       detail({
          permissions_id_string: this.permissions_id_string,
          order_id: JSON.parse(localStorage.getItem("params")).order_id,
          class_type: JSON.parse(localStorage.getItem("params")).class_type,
        }).then((res) => {
         
          let data = res.data.data
          this.order_info = data;
          this.orderStatus  = this.order_info.type.toString();
         
        
          if(this.order_info.accounts_receivable.toString().split('.')[0] > 0){
            this.isAddArrival = true
          }
          else{
            this.isAddArrival = false;
          }
          this.channel =data.customer ?data.customer.customer_source :  '';
          this.followUser = data.user.name;
      
          this.tableData = res.data.data.order_data;
           console.log('details',this.tableData)
        });
    },
    //修改下单状态
    submitEditStatus(){
    
      editPlaceOrder({type:this.orderStatus,order_id:this.order_info.id}).then(res =>{
     
        if(res.data.code == 200){
           this.$message({
              type: "success",
              message: '修改成功',
            });
            this.getDetails();
            this.orderStatusEdit = false
        }
        else{
           this.$message({
              type: "error",
              message: res.data.message,
            });
        }
      })
    },
    transfChannel(val){
      let str;
      switch(val){
        case 1:
          str = '网络查找';
          break;
        case 2:
          str = '阿里资源库';
          break;
        case 3:
          str = '人脉介绍';
          break;
        case 4:
          str = '老客户转介绍';
          break;
        case 5:
          str = '陌生拜访';
          break;
        case 6:
          str = '其他';
          break;
      }
      return str;
    },
    change(val) {
      if (this.editor_what == 1) {
        this.tk_remarks = val;
      } else {
        this.refund_consult_pay_mode = val;
      }
    },
    wangfocus(i) {
      this.editor_what = i;
    },
    goback() {
        console.log('我是小可爱')
      this.$router.go(-1);

    },
    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // let strDate = Y + M + D;
      let strDate = Y + M + D + h + m + "00";
      return strDate;
    },
    openrefund() {
      //退款申请
      this.to_money = "";
      this.tk_remarks = "";
      this.refund_consult_pay_mode = "";
      refundQuery({
        order_id: JSON.parse(localStorage.getItem("params")).order_id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.customer_type = res.data.data.customer_type;
          this.refund_period = res.data.data.refund_period;
        }
      });
    },
    onorderRefund() {
      if (this.add_flag) {
        return;
      }
      let {
        to_money,
        tk_remarks,
        tk_fendan_list,
        order_info,
        refund_expect_pay_date,
        refund_type,
        refund_consult_pay_mode,
        refund_achi_date,
      } = this;
      let arr = [];
      tk_fendan_list.forEach((item) => {
        if (item.user_id != "" && item.money != "" && item.company_id != "") {
          delete item.user_name;
          arr.push(item);
        } else {
          arr = [];
        }
      });
      let data = {
        order_id: order_info.id,
        to_money,
        refund_reason_type: refund_type,
        remarks: tk_remarks,
        refund_expect_pay_date: refund_expect_pay_date
          ? this.getwaytime(refund_expect_pay_date).split(" ")[0]
          : "",
        json: JSON.stringify(arr),
        refund_consult_pay_mode,
        achievement_time: refund_achi_date
          ? this.getwaytime(refund_achi_date).split(" ")[0]
          : "",
      };
      for (const i in data) {
        if (i != "json") {
          if (data[i] == "") {
            this.$message.error("请完善数据");
            console.log(data[i]);
            return;
          }
        }
      }

      if (this.order_info.is_refund_staff) {
        if (this.order_info.is_refund_staff==1) {
          data.is_refund_staff = 1
        }
      }
      this.add_flag = true;
      orderRefund(data).then((res) => {
        this.add_flag = false;
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          detail({
            permissions_id_string: this.permissions_id_string,
            order_id: JSON.parse(localStorage.getItem("params")).order_id,
            class_type: JSON.parse(localStorage.getItem("params")).class_type,
          }).then((res) => {
            this.order_info = res.data.data;
            this.tableData = res.data.data.order_data;
          });
          this.is_refund = false;
        }
      });
    },
    open() {
      let { order_info } = this;
      this.Arrival_money = "";
      this.Arrival_type = "";
      this.collection = "";
      this.time = "";
      this.pro_id = "";
      this.Promotion_id = "";
      this.contract_fd_list = [
        {
          user_id: "",
          user_name: "",
          to_money: "",
        },
      ];
      this.collection_file_list = [];
      this.remarks = "";
      this.prooption = [];
      //   this.order_id = item.id;
      let obj = {
        label: order_info.product_name.product_name,
        value: order_info.product_name.id,
      };
      this.pro_id = order_info.product_name.id;
      this.prooption.push(obj);
      collectionList().then((res) => {
        //收款方式
        if (res.data.code == 200) {
          this.collectionList = res.data.data;
        }
      });
    },
    chooseFile: function () {
      this.$refs.choosePhoto.addEventListener('change',this.photoChange);
this.$refs.choosePhoto.dispatchEvent(new MouseEvent("click"));
      this.upload_img = false;
      setTimeout(() => {
        this.upload_img = true;
      }, 10);
    },
    handleChangePro(i) {
      //获取促销
      if (i == 2) {
        this.PromotionList = [];
        this.Promotion_id = "";
        let { order_info } = this;
        let data = {
          my_customer_id: order_info.my_customer_id,
          customer_id: order_info.customer_id,
          product_name_id: order_info.product_name_id,
          status: 3,
        };
        let cx_arr = [
          { value: 1, label: "实物" },
          { value: 2, label: "返现" },
          { value: 3, label: "打折" },
          { value: 4, label: "赠送服务时长" },
          { value: 5, label: "赠送其它服务" },
          { value: 6, label: "其他" },
        ];
        PromotionList(data).then((res) => {
          if (res.data.code == 200) {
            let list = res.data.data.data;
            list.forEach((item) => {
              cx_arr.forEach((itm) => {
                if (item.type == itm.value) {
                  item.name = itm.label;
                }
              });
            });
            this.PromotionList = list;
          }
        });
      }
    },
    querySearch(queryString, cb) {
      getUserData({ user_name: queryString }).then((res) => {
        let list = res.data.data.data;
        list.forEach((item) => {
          item.value = item.user.name + " 【" + item.company.name + "】";
        });
        this.fluterArr = list;
        var restaurants = this.loadAll();
        var results = queryString
          ? restaurants.filter(this.createFilter(queryString))
          : restaurants;
        // // 调用 callback 返回建议列表的数据
        cb(results);
      });
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    loadAll() {
      return this.fluterArr;
    },
    Select(val) {
      //退款申请添加分单人
      let { tk_fendan_list } = this;
      tk_fendan_list.forEach((item) => {
        if (item.user_name == val.value) {
          item.user_id = val.user_id;
          item.company_id = val.company_id;
        }
      });
    },
    add_Tk_fendan() {
      //退款申请新增分单人
      let bool = false;
      let { tk_fendan_list } = this;
      tk_fendan_list.forEach((item) => {
        if (item.user_name == "" || item.to_money == "") {
          this.$message({
            message: "请完善分单信息",
            type: "error",
          });
          bool = true;
        }
      });
      if (!bool) {
        let obj = {
          user_id: "",
          user_name: "",
          money: "",
          company_id: "",
        };
        tk_fendan_list.push(obj);
      }
    },
    Del_TK_fendan(i) {
      //删除退款分单人
      let { tk_fendan_list } = this;
      if (tk_fendan_list.length < 2) {
        return;
      }
      i;
      tk_fendan_list.splice(i, 1);
    },
    handleSelect(val) {
      let { contract_fd_list } = this;
      contract_fd_list.forEach((item) => {
        if (item.user_name == val.value) {
          item.user_id = val.user_id;
        }
      });
    },

    handleAdd_fendan() {
      //新增分单人
      let bool = false;
      let { contract_fd_list } = this;
      contract_fd_list.forEach((item) => {
        if (item.user_name == "" || item.to_money == "") {
          this.$message({
            message: "请完善分单信息",
            type: "error",
          });
          bool = true;
        }
      });
      if (!bool) {
        let obj = {
          user_id: "",
          user_name: "",
          to_money: "",
        };
        contract_fd_list.push(obj);
      }
    },
    handleDel_fendan(val) {
      //删除分单人
      let { contract_fd_list } = this;
      if (contract_fd_list.length < 2) {
        return;
      }
      contract_fd_list.splice(val, 1);
    },
    photoChange(el) {
      var file = el.target.files[0]; //name: "dangqi1.png" || type: "image/png"
      var type = file.type.split("/")[0];
      if (type === "image") {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        var that = this;
        reader.onloadend = function () {
          var dataURL = reader.result;
          var blob = that.dataURItoBlob(dataURL);
          that.upload(blob); //执行上传接口
        };
      } else {
        this.$message.error("请上传图片哦~~");
      }
    },
    dataURItoBlob(dataURI) {
      // base64 解码
      let byteString = window.atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let T = mimeString.split("/")[1];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    upload(imgUrl) {
      let formData = new FormData();
      this.$message({
        message: "上传中，请稍后",
      });
      formData.append("file", imgUrl);
      uploadFile(formData).then((res) => {
        if (res.data.code == 200) {
          this.collection_file_list.push(res.data.data);
          this.$message({
            message: "上传成功",
            type: "success",
          });
        }
      });
    },
    handleDel_img(num) {
      this.collection_file_list.forEach((item, index) => {
        if (index == num) {
          this.collection_file_list.splice(index, 1);
        }
      });
    },
    handleOK() {
      if (this.add_flag) {
        return;
      }

      let collection_file = []; //收款凭证
      let order_data_branch_json = []; //分单人
      let to_time = "";
      let {
        Arrival_money,
     
        collection,
        time,
        pro_id,
        Promotion_id,
        contract_fd_list,
        collection_file_list,
        remarks,
      } = this;

  
      if (
        Arrival_money == "" ||
     
        collection == "" ||
        pro_id == ""
      ) {
        this.$message({
          message: "请完善数据",
          type: "error",
        });
        return;
      }
      contract_fd_list.forEach((item) => {
        if (item.user_id != "" && item.to_money != "") {
          let obj = {
            user_id: item.user_id,
            to_money: item.to_money,
          };
          order_data_branch_json.push(obj);
        }
      });
      if (order_data_branch_json.length == 0) {
        order_data_branch_json = JSON.stringify([]);
      } else {
        order_data_branch_json = JSON.stringify(order_data_branch_json);
      }
      collection_file_list.forEach((item) => {
        let obj = {
          file_url: item.url,
        };
        collection_file.push(obj);
      });
      if (collection_file.length == 0) {
        this.$message({
          message: "请完善数据",
          type: "error",
        });
        return;
      }
      this.add_flag = true;
      let data = {
        order_id: this.order_info.id,
        product_name_id: pro_id, //产品id
        // contract_money,             //合同金额
        // contract_number,                //合同单号
        to_money: Arrival_money, //到账金额
     
        collection_id: collection, //收款方式
        // to_time, //到账时间
        promotion_id: Promotion_id, //促销id
        order_data_branch_json, //分单人
        collection_file: JSON.stringify(collection_file), //收款文件
        remarks,
      };
      //   console.log(data);
      //   return;
      addOrderMore(data).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          //   window.onload()
          detail({
            permissions_id_string: this.permissions_id_string,
            order_id: JSON.parse(localStorage.getItem("params")).order_id,
            class_type: JSON.parse(localStorage.getItem("params")).class_type,
          }).then((res) => {
            this.order_info = res.data.data;
            this.tableData = res.data.data.order_data;
          });
            this.is_Addarrival = false;
            this.add_flag = false;
        }
      }).catch(()=>{
          this.add_flag = false;
      })
    },
  },
};
</script>

<style lang='scss' scoped>
.order_detail_box {

  box-sizing: border-box;
  .el-main {
    min-height: 847px;
    
    .order_detail_title {
      display: flex;
      justify-content: space-between;
      background: #fff;
      padding: 20px;
      border-radius: 4px;
      .left_box {
        display: flex;
        .fir_box {
          width: 90px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #fff;
          border: 1px solid #3a85fc;
          color: #3a85fc;
          border-radius: 5px;
          margin-right: 40px;
        }
        .fir_box:hover {
          background: #3a85fc;
          border: 1px solid transparent;
          color: #fff;
        }
      }
      .right_box {
        .fir_box {
          width: 90px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #3a85fc;
          color: #fff;
          border-radius: 5px;
        }
      }
    }
    .order-details-center{
      margin-top: 10px;
      background: #fff;
      padding: 20px;
      border-radius: 4px;
      .item-tit{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #595959;
        text-indent: 10px;
        position: relative;
        margin-bottom: 20px;
        &::after{
          content: "";
          width: 2px;
          height: 13px;
          background: #3A85FC;
          position: absolute;
          left: 0;
          top: 5px;
        }
       
      }
       ul {
          li{
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #595959;
            margin-bottom: 13px;
            padding-left: 10px;
            box-sizing: border-box;
          }
          .tit{
            color: #999999;
            margin-right: 10px;
          }
          .edit-blue{
            color: #3A85FC;
            cursor: pointer;
            margin-left: 15px;
          }
        }
    }
    .editor_box {
      .editor {
        width: 100%;
        margin: 0 auto;
        position: relative;
        z-index: 0;
        padding: 0 14px;
      }
      .toolbar {
        border: 1px solid #ccc;
      }
      .text {
        border: 1px solid #ccc;
        min-height: 100px;
      }
    }
    .title {
      width: 100%;
      height: 40px;
      display: flex;
      box-sizing: border-box;
      font-weight: 600;
      border-radius: 5px;
      padding-left: 30px;
      background: #fafafa;
      opacity: 1;
      align-items: center;
      margin-top: 10px;
      .info_lan {
        width: 2px;
        height: 12px;
        background: #3a85fc;
        margin-right: 4px;
      }
    }
    .info_detail {
      padding-left: 30px;
      min-height: 40px;
      align-items: center;
      width: 100%;
      display: flex;
      .info_child {
        width: 33%;
        color: #333;
        .child_span {
          color: #999;
        }
      }
    }
    .table_box {
      margin-top: 10px;
      padding: 20px;
      background: #fff;
      box-sizing: border-box;
      .table-top{
        height: 50px;
        background: rgb(245, 245, 245);
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #707070;
        border: 1px solid #EBEEF5; 
        border-bottom: solid #F5F5F5 1px;
        position: relative;
        ul{
          position: absolute;
          left: 0;
          top: 0;
          padding-left: 20px;
          box-sizing: border-box;
          li{
            float: left;
            line-height: 50px;
            margin-right: 60px;
            position: relative;
            text-indent: 5px;
            &::after{
              content: '';
              position: absolute;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              left: -5px;
              top: 22px;
            }
          }
          .red{
            &::after{
              background: #FF3737;
            }
          }
          .blue{
            &::after{
              background: #3995FF;
            }
          }
          
        }
      }
      .wait_status {
        //0  待审核
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #faad14;
        opacity: 1;
        border-radius: 50%;
        margin-right: 5px;
      }
      .pass_status {
        //1 通过
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #1dd0bd;
        border: 1px solid #1dd0bd;
        border-radius: 50%;
        opacity: 1;
        margin-right: 5px;
      }
      .reject_status {
        //2驳回
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #ff5c5c;
        border-radius: 50%;
        opacity: 1;
        margin-right: 5px;
      }
      .revoke_status {
        //  3 撤销
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #3299eb;
        border-radius: 50%;
        opacity: 1;
        margin-right: 5px;
      }
      .acc_table_company {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .acc_table_company > div {
        border-bottom: 1px solid #ccc;
      }
      .acc_table_company > div:nth-last-of-type(1) {
        border-bottom: 1px solid transparent !important;
      }
      .acc_table_company_click:hover {
        color: #3a85fc;
      }
    }
  }
  .refund_box {
    z-index: 99;
    width: 650px;
    // height: 740px;
    height: 81%;
    min-width: 650px;
    background: #ffff;
    position: relative;
    margin: auto;
    margin-top: 50px;
    border-radius: 10px;
    padding-bottom: 30px;
    overflow: hidden;
    .refund_box_head {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.8);
      opacity: 1;
      border: 1px solid rgba(0, 0, 0, 0.2);
      position: relative;
    }
    .refund_box_info {
      height: 95%;
      overflow: auto;
      padding: 50px;
      padding-top: 30px;
      padding-bottom: 0;
      .refund_box_info_tit {
        display: flex;
        align-items: center;
        i {
          display: block;
          width: 6px;
          height: 6px;
          background: #3a85fc;
          border-radius: 50%;
          opacity: 1;
          margin-right: 8px;
        }
      }
      .refund_box_info_title {
        margin: 20px 0;
        padding: 0 14px;
      }
      .el-date-editor {
        width: 100% !important;
        input {
          width: 100%;
          padding-left: 15px !important;
        }
      }
      .add_Arrival_line_add {
        display: flex;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        height: 32px;
        background: #ffffff;
        border: 1px dashed rgba(0, 0, 0, 0.2);
        opacity: 1;
        border-radius: 2px;
        margin: 0 14px;
        margin-top: 30px;
      }
      .el-input {
        padding: 0 14px;
        height: 32px;
        input {
          height: 32px;
        }
      }
      .el-textarea {
        padding: 0 14px;
        .el-textarea__inner {
          resize: none;
        }
      }
      .el-input__suffix {
        right: 16px;
        display: flex;
        align-items: center;
      }
      .add_refund_bottom {
        width: 100%;
        height: 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
        padding-right: 40px;
        margin-bottom: 25px;
        div {
          width: 80px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          cursor: pointer;
        }
        div:first-child {
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.3019607843);
          opacity: 1;
          color: rgba(0, 0, 0, 0.5);
        }
        div:last-child {
          background: #3a85fc;
          opacity: 1;
          color: #fff;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>