<template>
  <div class="mission_audit_box" style="flex: 1">
    <el-main>
      <div class="time_check pointer">
        <div
          v-for="(item, index) in time_arr"
          @click="change_timecheck(item.id)"
          :class="[check_time_id == item.id ? 'acc_time' : '']"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="kind_info">
        <div class="left_tit">
          <div
            class="pointer"
            @click="status = item.id"
            :class="[status == item.id ? 'active' : '']"
            v-for="(item, index) in status_arr"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
        <div
          class="right_tit pointer"
          v-if="status == 1 && role_what.edit"
          @click="take_more_pass"
        >
          <div class="right_info">
            <i class="el-icon-notebook-2"></i>
            批量通过
          </div>
        </div>
      </div>
      <div class="acc_table">
        <el-table
          ref="multipleTable"
          :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
          :data="tableData"
          style="width: 100%"
          border
          :default-sort="{ prop: 'date', order: 'descending' }"
        >
          <el-table-column v-if="status == 1" type="selection"   key="1">
          </el-table-column>
          <el-table-column label="时间" key="2">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{
                  check_time_id == 1
                    ? scope.row.make_node_id
                    : check_time_id == 2
                    ? scope.row.month
                    : check_time_id == 3
                    ? scope.row.time
                    : scope.row.year + "年"
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="部门" key="3">
            <template slot-scope="scope" >
              <div class="acc_table_company_name">
                {{ scope.row.company ? scope.row.company.name : "--" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="任务额(元)" key="4">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ scope.row.mission == "" ? "--" : scope.row.mission }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="状态" key="5">
            <template slot-scope="scope">
              <div class="acc_table_company_status">
                <i
                  :class="
                    scope.row.status == 1
                      ? 'wait_status'
                      : scope.row.status == 2
                      ? 'well_status'
                      : scope.row.status == 3
                      ? 'warning'
                      : ''
                  "
                ></i>
                {{
                  scope.row.status == 1
                    ? "待审核"
                    : scope.row.status == 2
                    ? "通过"
                    : scope.row.status == 3
                    ? "驳回"
                    : "--"
                }}
              </div>
            </template>
          </el-table-column>
            <el-table-column v-if="status == 3" label="驳回原因" key="6">
            <template slot-scope="scope">
                <el-popover
                    placement="bottom"
                    width="400"
                    trigger="hover"
                    :content="scope.row.reason">
                    <!-- <el-button slot="reference">hover 激活</el-button> -->
                        <div class="acc_table_company_cuse" slot="reference">
                        {{scope.row.reason}}
                        </div>
                </el-popover>
            </template>
          </el-table-column>
          <el-table-column v-if="status == 2 || status == 3" label="审核时间" key="7">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ scope.row.updated_at }}
              </div>
            </template>
          </el-table-column>
         
          <el-table-column v-if="status == 1" label="操作" key="8">
            <template slot-scope="scope">
              <div class="acc_table_company_name" v-if="role_what.edit">
                <span
                  style="color: #3a85fc"
                  class="pointer"
                  @click="takepass(scope.row)"
                  >通过</span
                >
                <span
                  style="color: #3a85fc; margin-left: 10px"
                  class="pointer"
                  @click="takenopass(scope.row)"
                  >驳回</span
                >
              </div>
              <div v-else>----</div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="10"
          layout="total,prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>    
    </el-main>
    <div class="acc_shadow" v-if="no_passshow">
      <div class="acc_commen">
        <div class="acc_commen_title">
          驳回原因
          <i
            class="el-icon-close pointer"
            style="color: #999999"
            @click="no_passshow = false"
          ></i>
        </div>
        <div class="acc_commen_body">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入驳回原因"
            v-model="commen"
          >
          </el-input>
        </div>
        <div class="acc_commen_sent pointer" @click="no_pass">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import { taskAuditList, taskAudit } from "../../api/User/mission";
export default {
  data() {
    return {
      time_arr: [
        { id: 1, name: "周" },
        { id: 2, name: "月" },
        { id: 3, name: "季" },
        { id: 4, name: "年" },
      ],
      check_time_id: 1,
      status_arr: [
        { id: 1, name: "待审核" },
        { id: 2, name: "通过" },
        { id: 3, name: "驳回" },
      ],
      status: 1,
      tableData: [],
      page: 1,
      total: 1,
      no_passshow: false,
      item: {},
      commen: "",

      role_what:{
        edit:false,
        edit_str:'',
        list_str:''
      }
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      let roleList = JSON.parse(localStorage.getItem("token")).role_permissions;
      roleList.forEach((item) => {
        if (item.permissions == "Finance") {
          item.data.forEach((itm) => {
            if (itm.permissions == "Approval_mission_edit") {
              this.role_what.edit = true;
              this.role_what.edit_str = itm.permissions_id_string;
            }
            if (itm.permissions == "Approval_mission_list") {
              this.role_what.list_str = itm.permissions_id_string;
            }
          });
        }
      });
    }
    this.getlist();
  },
  methods: {
    change_timecheck(id) {
      this.check_time_id = id;
    },
    takepass(item) {
      this.$confirm("此操作将通过该任务额度,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let type =
            this.check_time_id == 1
              ? "4"
              : this.check_time_id == 2
              ? "3"
              : this.check_time_id == 3
              ? "2"
              : "1";
          taskAudit({
            status: 2,
            type,
            task_id: item.id,
            reason: "",
            permissions_id_string:this.role_what.edit_str,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: res.data.message,
              });
            }
            this.getlist();
          });
        })
        .catch(() => {
        });
    },
    no_pass() {
      let type =
        this.check_time_id == 1
          ? "4"
          : this.check_time_id == 2
          ? "3"
          : this.check_time_id == 3
          ? "2"
          : "1";
      taskAudit({
        status: 3,
        type,
        task_id: this.item.id,
        reason: this.commen,
        permissions_id_string:this.role_what.edit_str,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: res.data.message,
          });
        }
        this.no_passshow = false;
        this.getlist();
      });
    },
    takenopass(item) {
      this.no_passshow = true;
      this.item = item;
      this.commen = "";
    },
    getlist(is_change) {
      this.tableData = [];
      if (is_change == 1) {
        this.page = 1;
      }
      let type =
        this.check_time_id == 1
          ? "4"
          : this.check_time_id == 2
          ? "3"
          : this.check_time_id == 3
          ? "2"
          : "1";
      taskAuditList({
        type,
        status: this.status,
        num: 10,
        page: this.page,
        permissions_id_string:this.role_what.list_str,
      }).then((res) => {
        if (this.check_time_id == 3) {
          for (let i in res.data.data.data) {
            let haha =
              res.data.data.data[i].quarter == 1
                ? "第一季度"
                : res.data.data.data[i].quarter == 2
                ? "第二季度"
                : res.data.data.data[i].quarter == 3
                ? "第三季度"
                : "第四季度";
            res.data.data.data[i].time =
              res.data.data.data[i].year_id + "年" + "-" + haha;
            // + res.data.data.data[i].quarter==1 ? '第一季度':res.data.data.data[i].quarter==2 ? '第二季度':res.data.data.data[i].quarter==3 ? '第三季度':'第四季度'
          }
        }
        
        
        this.total = res.data.data.total;
        if(res.data.data.data.length==0){
            if(res.data.data.total>0){
                // this.page=this.page-1;
                let page=this.page;
                page--;
                this.page=page;
                this.getlist()
            }
        }
        this.tableData = res.data.data.data;
      });
    },
    handleCurrentChange(e) {
      this.page = e;
      this.getlist();
    },
    take_more_pass() {
        const _selectData = this.$refs.multipleTable.selection
        if(_selectData.length==0){
            this.$message({
            type: "error",
            message: "请勾选一个及以上的任务",
          });
          return
        }
         this.$confirm("此操作将通过多个任务额度,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let type =
            this.check_time_id == 1
              ? "4"
              : this.check_time_id == 2
              ? "3"
              : this.check_time_id == 3
              ? "2"
              : "1";
              let task_id =''
              for(let i in _selectData){
                  if(_selectData.length>0){
                      task_id+=_selectData[i].id+','
                  }
              }
              if(task_id.length>0){
                  task_id=task_id.substr(0,task_id.length-1)
              }
          taskAudit({
            status: 2,
            type,
            task_id,
            reason: "",
            permissions_id_string:this.role_what.edit_str,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: res.data.message,
              });
            }
            this.getlist();
          });
        })
        .catch(() => {
         
        });
    },
  },
  watch: {
    check_time_id() {
      this.getlist(1);
    },
    status() {
      this.getlist(1);
    },
  },
};
</script>

<style lang="scss">
.mission_audit_box {
  padding: 20px;
  box-sizing: border-box;
  .el-main {
    min-height: 847px;
    background: #fff;
    .time_check {
      width: 280px;
      height: 40px;
      display: flex;
      border-radius: 5px;
      border: 1px solid #ccc;
      div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #ccc;
      }
      div:nth-last-of-type(1) {
        border-right: 0px;
      }
      .acc_time {
        background: #3a85fc;
        color: #fff;
      }
    }
    .kind_info {
      width: 100%;
      display: flex;
      border-bottom: 1px solid #e1e1e1;
      margin-top: 20px;
      padding-left: 10px;
      justify-content: space-between;
      line-height: 35px;
      .left_tit {
        width: 32%;
        display: flex;
        // margin-bottom: 5px;
        justify-content: space-between;
        div {
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 2px solid transparent;
        }
        .active {
          color: #3a85fc;
          border-bottom: 2px solid #3a85fc;
        }
      }
      .right_tit {
        padding-bottom: 1px;
        .right_info {
          width: 119px;
          border-radius: 5px;
          border: 1px solid #3a85fc;
          display: flex;
          color: #3a85fc;
          align-items: center;
          justify-content: center;
          margin-bottom: 4px;
        }
      }
    }

    .acc_table_company_cuse{
         text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
 
    }
    .acc_table {
      margin-top: 20px;
      .acc_table_company_status {
        display: flex;
        align-items: center;
        i {
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          opacity: 1;
          margin-right: 10px;
        }
        .well_status {
          background: rgba(29, 208, 189, 1);
        }
        .wait_status {
          background: #faad14;
        }
        .to_time_status {
          background: #3a85fc;
        }
        .warning {
          background: #ff4d4f;
        }
      }
      .el-pagination {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .acc_shadow {
    position: fixed;
    z-index: 120;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    .info {
      width: 772px;
      height: 600px;
      margin: 100px auto;
      background: #ffffff;
      border: 1px solid #707070;
      opacity: 1;
      position: relative;
      border-radius: 5px;
      z-index: 999;
      // padding-top: 66px;
      box-sizing: border-box;
      .take_new_title {
        // top: -66px;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 66px;
        position: relative;
        border-bottom: 1px solid #ccc;
        text-align: center;
        line-height: 66px;
        font-size: 18px;
        i {
          position: absolute;
          top: 23px;
          right: 23px;
        }
      }
      .take_new_detail {
        height: 468px;
        width: 100%;
        box-sizing: border-box;
        overflow: auto;
        padding: 20px;
        .acc_top_compony {
          .kf_detail {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .quan {
              height: 5px;
              width: 5px;
              border-radius: 2.5px;
              background: #3a85fc;
              margin-right: 6px;
            }
          }

          .kh_detail {
            padding: 20px;
            width: 100%;
            box-sizing: border-box;
            height: 80px;
            margin-top: 10px;
            display: inline-block;
            background: #f8f8f8;
            div {
              overflow: hidden; /*超出部分隐藏*/
              white-space: nowrap; /*不换行*/
              text-overflow: ellipsis; /*超出部分文字以...显示*/
              width: 50%;
              display: inline-block;
              height: 50%;
            }
          }
        }
      }
      .flow_kind {
        width: 100%;
        display: flex;
        margin-top: 20px;
        white-space: nowrap; /*不换行*/
        align-items: center;
        .el-select {
          width: 250px;
        }
        .el-input__inner {
          width: 250px;
        }
        .el-date-editor .el-range-input {
          overflow: hidden; /*超出部分隐藏*/
          white-space: nowrap; /*不换行*/
          text-overflow: ellipsis; /*超出部分文字以...显示*/
        }
        .el-textarea__inner {
          width: 320px;
        }
        .el-date-editor.el-input {
          width: 250px;
        }
      }
      .take_bottom {
        height: 66px;
        position: relative;
        bottom: 0;
        width: 100%;
        display: flex;
        // align-items: center;
        // justify-content: center;
        box-sizing: border-box;
        justify-content: flex-end;
        padding-right: 40px;
        align-items: center;
        .bottom_sent {
          width: 80px;
          height: 40px;
          background: #3a85fc;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 1;
          color: #fff;
          border-radius: 4px;
        }
      }
      .flow_kind_text {
        width: 65%;
        display: flex;
        margin-top: 20px;
        // align-items: center;
        padding-top: 1px;
        // white-space: nowrap;
        .el-textarea {
          width: 400px;
          textarea {
            resize: none;
          }
        }

        .el-dialog__wrapper {
          z-index: 2010 !important;
        }
      }
    }
    .acc_commen {
      width: 445px;
      height: 426px;
      background: #ffffff;
      border: 1px solid #707070;
      opacity: 1;
      border-radius: 10px;
      margin: 100px auto;
      position: relative;
      .acc_commen_title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;
        position: relative;
        height: 40px;
        border-bottom: 1px solid #ccc;
        i {
          position: absolute;
          // top: ;
          right: 10px;
        }
      }
      .acc_commen_body {
        width: 100%;
        resize: none;
        padding: 10% 10%;
        .el-textarea__inner {
          resize: none;
          height: 270px;
        }
      }
      .acc_commen_sent {
        position: absolute;
        right: 10px;
        padding: 0 10px;
        height: 25px;
        line-height: 25px;
        bottom: 10px;
        background: #3a85fc;
        color: #fff;
        border-radius: 5px;
      }
    }
  }
}
</style>