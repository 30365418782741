<template>
  <div class="PK_list_box" style="width: 98%">
    <el-main style="padding: 2%">
      <div class="serch_big_box">
        <div class="info">
          <span class="demonstration">部门：</span>
          <el-cascader
            placeholder="请选择部门名称"
            :options="options"
            ref="myCascader"
            @change="handleClick"
            clearable
            :show-all-levels="false"
            collapse-tags
            :props="props"
          ></el-cascader>
        </div>
        <div class="info">
          <span class="demonstration">月：</span>
          <el-date-picker
            v-model="date"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="info" @click="export_list">
          <el-button type="primary"><i class="el-icon-printer" style="margin-right: 5px"></i> 导出</el-button>
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
import { select, personalAccountAnalysis } from "../../api/User/human";
import { selectCompanyTree } from "../../api/User/data";
export default {
  data() {
    return {
      options: [],
      list_str: "",
      business_line_id: "",
      isResouceShow: 0,
      date: "",
      data_arr: [],
      data_arrL: 0,
      company_id: [],
      props: {
        checkStrictly: true,
      },
    };
  },
  created() {
    let token = JSON.parse(localStorage.getItem("token"));
    this.position = token.position_type;
    for (let i in token.role) {
      if (token.role[i].permissions == "PK_list") {
        //分配列表
        this.list_str = token.role[i].permissions_id_string;
      }
    }
    selectCompanyTree({
      depth: 5,
    }).then((res) => {
      if (res.data.code == 200) {
        function dg(list) {
          list.forEach((item) => {
            item.label = item.name;
            item.value = item.id;
            if (item.data) {
              item.children = item.data;
              dg(item.data);
            }
          });
          return list;
        }
        let list = res.data.data;
        dg(list);
        this.options = list;
      }
    });
  },
  methods: {
    export_list() {
      let data = {
        permissions_id_string: this.list_str,
        start_time:this.date[0],
        end_time:this.date[1],
        company_id: this.company_id[this.company_id.length - 1],
        search_company_depth: this.business_line_id,
      };
      personalAccountAnalysis(data).then((res) => {
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = res.data.data;
        link.setAttribute("download", "courman" + ".xls");
        document.body.appendChild(link);
        link.click();
      });
    },

    handleClick(value) {
      this.data_arr = [
        {
          id: "3",
          name: "事业部",
        },
        {
          id: "4",
          name: "战区",
        },
        {
          id: "5",
          name: "军团",
        },
        {
          id: "6",
          name: "个人",
        },
      ];
      this.company_id = value;
      this.data_arrL = value.length;
      if (this.data_arrL > 2) {
        this.data_arr.splice(0, this.data_arrL - 2);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.PK_list_box {
  padding: 20px;
  padding-right: 0;
  .el-main {
    padding: 20px 0;
    background: #fff;
    .serch_big_box {
      width: 50%;
      .info {
        display: flex;
        align-items: center;
        margin-top: 40px;
        span {
          width: 120px;
          color: #282828;
          font-size: 16px;
        }
        & > div {
          flex: 1;
        }
      }
    }
  }
}
</style>
