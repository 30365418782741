<template>
  <div class="broadcast_box">
    <div class="take_money">
      <div class="big_money" v-for="(item, index) in all_array" :key="index">
        <div class="info_fir">
          <div class="big_price"></div>
          {{ item.type == 1 ? "大" : item.type == 2 ? "中" : "小" }}金额设置
        </div>
        <div class="price_set">
          <div class="set_l">
            <div style="width: 200px">
              金额设置：<span v-if="!item.ischeck">{{ item.money }}</span>
              <el-input
                v-model="item.money"
                placeholder="请输入金额"
                type="number"
                v-if="item.ischeck"
              ></el-input>
              <!-- <input type="number" v-if="!small_show"> -->
            </div>
            <div
              class="pointer"
              @click="change(item)"
              style="color: #3a85fc"
              v-if="role_what.is_edit"
            >
              {{ !item.ischeck ? "修改" : "保存" }}
            </div>
          </div>
          <div class="set_z">
            状态<el-switch
              v-model="item.status_check"
              active-color="#13ce66"
              @change="status_change(item)"
              inactive-color="#ccc"
            >
            </el-switch>
          </div>
        </div>
      </div>
      <!-- <div class="big_money">
        <div class="info_fir">
          <div class="big_price"></div>
          中金额设置
        </div>
        <div class="price_set">
            <div class="set_l">
            <div style="width:200px">
              金额设置：<span v-if="middle_show">5000</span>
              <el-input v-model="middle_val" placeholder="请输入金额" v-if="!middle_show"></el-input>
            </div>
            <div class="pointer" @click="middle_show=!middle_show">
              修改
            </div>
          </div>
          <div class="set_z">
            状态<el-switch
                  v-model="middle_check"
                  active-color="#13ce66"
                  inactive-color="#ccc">
                </el-switch>
          </div>
        </div>
      </div> -->
      <!-- <div class="big_money">
        <div class="info_fir">
          <div class="big_price"></div>
          小金额设置
        </div>
        <div class="price_set">
           <div class="set_l">
            <div style="width:200px">
              金额设置：<span v-if="big_show">5000</span>
              <el-input v-model="big_val" placeholder="请输入金额" v-if="!big_show"></el-input>
            </div>
            <div class="pointer" @click="big_show=!big_show">
              修改
            </div>
          </div>
          <div class="set_z">
            状态<el-switch
                  v-model="small_check"
                  active-color="#13ce66"
                  inactive-color="#ccc">
                </el-switch>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import {
  arrivalBroadcastList,
  editArrivalBroadcast,
} from "../../api/User/arrival_broadcast";
export default {
  data() {
    return {
      big_check: false,
      middle_check: false,
      small_check: false,
      small_show: true,
      middle_show: true,
      big_show: true,
      big_val: "",
      small_val: "",
      middle_val: "",
      all_array: [
        {
          ischeck: false,
        },
        { ischeck: false },
        { ischeck: false },
      ],
      role_what: {
        is_edit: false,
        edit_str: "",
      },
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      let roleList = JSON.parse(localStorage.getItem("token")).role;
      roleList.forEach((item) => {
        if (item.permissions == "Poster_Arrival_edit") {
          this.role_what.is_edit = true;
          this.role_what.edit_str = item.permissions_id_string;
        }
      });
    }
    this.getlist();
  },
  methods: {
    getlist() {
      arrivalBroadcastList({
        permissions_id_string: this.role_what.edit_str,
      }).then((res) => {
        for (let i in res.data.data) {
          if (res.data.data[i].status == 1) {
            res.data.data[i].status_check = true;
          } else {
            res.data.data[i].status_check = false;
          }
          res.data.data[i].ischeck = false;
        }
        this.all_array = res.data.data;
      });
    },
    status_change(item) {
      editArrivalBroadcast({
        type: item.type,
        money: item.money,
        status: item.status_check ? "1" : "2",
        arrival_broadcast_id: item.id,
        permissions_id_string: this.role_what.edit_str,
      }).then((res) => {
        this.getlist();
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: res.data.message,
          });
        }
      });
    },
    change(item) {
      // for()
      if (item.ischeck) {
        editArrivalBroadcast({
          type: item.type,
          money: item.money,
          status: item.status,
          arrival_broadcast_id: item.id,
          permissions_id_string: this.role_what.edit_str,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: res.data.message,
            });
            for (let i in this.all_array) {
              if (item.id == this.all_array[i].id) {
                this.all_array[i].ischeck = !this.all_array[i].ischeck;
              }
            }
          }
        });
      }
      if (!item.ischeck) {
        for (let i in this.all_array) {
          if (item.id == this.all_array[i].id) {
            this.all_array[i].ischeck = !this.all_array[i].ischeck;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.broadcast_box {
  .take_money {
    width: 1037px;
    display: flex;
    margin-top: 20px;
    .big_money {
      width: 30%;
      margin-left: 3%;
      height: 219px;
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      opacity: 1;
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      .info_fir {
        display: flex;
        align-items: center;
        width: 100%;
        background: url("../../assets/img/Arrival_1.png") no-repeat;
        background-size: 100% auto;
        height: 50px;
        // height: 100px;
        box-sizing: border-box;
        padding-left: 20px;
        color: #ffffff;
        .big_price {
          background: url("../../assets/img/big_price.png") no-repeat;
          background-size: 100% auto;
          margin-right: 5px;
          height: 30px;
          width: 30px;
        }
      }
      .price_set {
        height: 100%;
        width: 80%;
        // margin-left: 10%;
        margin: 0 auto;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 10% 0;
        padding-right: 0%;
        // justify-content: space-around;
        .set_l {
          height: 42px;
          align-items: center;
          display: flex;
          justify-content: space-between;
          // div:nth-of-type(1) {
          //   // width: 80%;
          //   // width: 230px;
          // }
          .el-input {
            .el-input__inner {
              box-sizing: border-box;
            }
            width: 60%;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          input[type="number"] {
            -moz-appearance: textfield;
          }
        }
        .set_z {
          margin-top: 30px;
          margin-left: 27px;
          .el-switch {
            margin-left: 10px;
          }
        }
      }
    }
    .big_money:nth-of-type(2) {
      .info_fir {
        display: flex;
        align-items: center;
        width: 100%;
        background: url("../../assets/img/Arrival_2.png") no-repeat;
        background-size: 100% auto;
        height: 50px;
        // height: 100px;
        box-sizing: border-box;
        padding-left: 20px;
        color: #ffffff;
      }
    }
    .big_money:nth-of-type(3) {
      .info_fir {
        display: flex;
        align-items: center;
        width: 100%;
        background: url("../../assets/img/Arrival_3.png") no-repeat;
        background-size: 100% auto;
        height: 50px;
        // height: 100px;
        box-sizing: border-box;
        padding-left: 20px;
        color: #ffffff;
      }
    }
    .big_money:nth-of-type(1) {
      margin-left: 0%;
    }
  }
}
</style>