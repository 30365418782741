<template>
  <div class="business_box" style="flex: 1">
    <el-main>
      <div class="add_business">
        <div class="pointer" @click="todetail(null)" v-if="role_what.is_edit">
          <i class="el-icon-plus" style="margin-right: 3px"></i>
          新增事业线
        </div>
      </div>
      <div class="acc_table">
        <el-table
          :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
          :data="tableData"
          style="width: 100%"
          border
          :default-sort="{ prop: 'date', order: 'descending' }"
        >
          <el-table-column label="名称">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ scope.row.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="产品">
            <template slot-scope="scope">
              <div
                class="acc_table_company_name"
                v-for="(item, index) in scope.row.business_line_product_name"
                :key="index"
              >
                <div v-if="item.product_name">{{ item.product_name.product_name }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              <div class="acc_table_company_name">
                {{ scope.row.created_at }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div
                class="acc_table_company_name pointer"
                style="color: #3a85fc !important"
                @click="todetail(scope.row)"
                v-if="role_what.is_edit"
              >
                编辑
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="10"
          layout="total,prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <div class="acc_shadow" v-if="shadow_show">
        <div class="acc_commen">
          <div class="acc_commen_title">
            {{ is_add ? "添加" : "编辑" }}事业线
            <i
              class="el-icon-close pointer"
              style="color: #999999"
              @click="shadow_show = false"
            ></i>
          </div>
          <div class="acc_commen_body">
            <div class="acc_title">
              <div class="yuan"></div>
              事业线管理
            </div>
            <div class="ano">
              事业线名：<el-input
                v-model="business_name"
                type="text"
                placeholder="请输入名称"
              ></el-input>
            </div>
            <div class="ano">
              已选产品
              <div style="border: 1px solid #ddd;margin-left:18px; border-radius: 5px ;display: flex;flex-wrap: wrap;color: #333">
                <div v-for="item in pc_name" :key="index" style="margin-right: 15px;padding: 10px">
                  <div v-if="item.product_name">{{ item.product_name.product_name }}</div>
                </div>
              </div>

            </div>
            <div class="ano">
              选择产品：
              <div v-for="(item, index) in productsarr" :key="index" style="width: 500px">
                <div class="add_cutomer_box_info" style="width:350px">
                  <el-cascader
                          :props="props"
                          v-model="item.product_name_id"
                          :options="prooptions"
                          placeholder="请选择产品"
                          separator=" - "
                          @change="handleChanges($event)"
                          @visible-change="visiblechange"
                          clearable
                  >
                  </el-cascader>
                  <i
                          v-if="productsarr.length > 1"
                          class="el-icon-remove-outline pointer"
                          style="color: #ccc; font-size: 19px; margin-left: 30px"
                          @click="handleDelpro(index)"
                  ></i>
                </div>
                <div>
                </div>
              </div>


              <!--<el-select-->
                <!--v-model="prodect"-->
                <!--multiple-->
                <!--placeholder="请选择产品"-->
              <!--&gt;-->
                <!--<el-option-->
                  <!--v-for="item in options"-->
                  <!--:key="item.id"-->
                  <!--:label="item.class_name"-->
                  <!--:value="item.id"-->
                <!--&gt;-->
                <!--</el-option>-->
              <!--</el-select>-->
            </div>
            <div class="acc_title">
              <div class="yuan"></div>
              事业线对比标准设置
            </div>
            <div class="ano_box">
              <div>业绩：</div>
              <div class="num_info">
                <div class="num_info_one">
                  金额
                  <el-input
                    v-model="achievement"
                    type="text"
                    placeholder="请输入金额"
                  ></el-input>
                </div>
                <div class="num_info_one">
                  权重(%)
                  <el-input
                    v-model="achievement_weight"
                    type="text"
                    placeholder="请输入权重"
                  ></el-input>
                </div>
              </div>
            </div>
            <div class="ano_box">
              <div>A类意向客户数：</div>
              <div class="num_info">
                <div class="num_info_one">
                  数量
                  <el-input
                    v-model="a_intention_customer"
                    type="text"
                    placeholder="请输入数量"
                  ></el-input>
                </div>
                <div class="num_info_one">
                  权重(%)
                  <el-input
                    v-model="a_intention_customer_weight"
                    type="text"
                    placeholder="请输入权重"
                  ></el-input>
                </div>
              </div>
            </div>
            <div class="ano_box">
              <div>面访量：</div>
              <div class="num_info">
                <div class="num_info_one">
                  数量
                  <el-input
                    v-model="visit"
                    type="text"
                    placeholder="请输入数量"
                  ></el-input>
                </div>
                <div class="num_info_one">
                  权重(%)
                  <el-input
                    v-model="visit_weight"
                    type="text"
                    placeholder="请输入权重"
                  ></el-input>
                </div>
              </div>
            </div>
            <div class="ano_box">
              <div>成交量：</div>
              <div class="num_info">
                <div class="num_info_one">
                  数量
                  <el-input
                    v-model="deal_customer"
                    type="text"
                    placeholder="请输入数量"
                  ></el-input>
                </div>
                <div class="num_info_one">
                  权重(%)
                  <el-input
                    v-model="deal_customer_weight"
                    type="text"
                    placeholder="请输入权重"
                  ></el-input>
                </div>
              </div>
            </div>
          </div>
              <div class="bottom">
              <div class="noshadow pointer" @click="shadow_show = false">
                取消
              </div>
              <div class="check_shadow pointer" @click="sent_mission">保存</div>
            </div>
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
import {
  addBusinessLine,
  list,
  editBusinessLine,
    getProductAll
} from "../../api/User/business";
import { getProductList } from "../../api/User/prodect";
export default {
  data() {
    return {
      prooptions: [], //产品列表
      //产品
      productsarr: [
          {
              product_name_id: [],
              intention: "",
              customer_analysis: "",
          },
      ],
      props: {multiple: true},
      tableData: [],
      page: 1,
      total: 0,
      shadow_show: false,
      is_add: false,
      item: {},
      business_name: "",
      options: [],
      prodect: "",
      add_flag: false,
      role_what: {
        is_edit: false,
        list_str: "",
        edit_str: "",
      },
        pc_name:[],
      achievement: "", //业绩金额
      achievement_weight: "", //业绩金额权重
      visit: "", //面访数量
      visit_weight: "", //面访数量权重
      deal_customer: "", // 成交客户数
      deal_customer_weight: "", //成交客户数权重
      a_intention_customer: "", //A类意向客户数
      a_intention_customer_weight: "", //A类意向客户数权重
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      let roleList = JSON.parse(localStorage.getItem("token"))
        .role_permissions;
      roleList.forEach((item) => {
        if (item.permissions == "system") {
          item.data.forEach((itm) => {
            if (itm.permissions == "Business_line_edit") {
              this.role_what.is_edit = true;
              this.role_what.edit_str = itm.permissions_id_string;
            }
            if (itm.permissions == "Business_line_list") {
              this.role_what.list_str = itm.permissions_id_string;
            }
          });
        }
      });
    }
      //获取产品列表
      getProductAll().then((res) => {
          if (res.data.code == 200) {
              let list = res.data.data;
              for (let i = 0; i < list.length; i++) {
                  list[i].label = list[i].class_name;
                  list[i].value = list[i].id;
                  list[i].children = list[i].product_name;
                  for (let j = 0; j < list[i].product_name.length; j++) {
                      list[i].product_name[j].label =
                          list[i].product_name[j].product_name;
                      list[i].product_name[j].value = list[i].product_name[j].id;
                  }
              }
              this.prooptions = list;
          }
      });
    this.getlist();
  },
  methods: {
    handleCurrentChange(e) {
      this.page = e;
      this.getlist();
    },
    todetail(e) {
        if (e) {
            if(e.business_line_product_name){
                for (let i = 0; i < e.business_line_product_name.length; i++) {
                    if (e.business_line_product_name[i].product_name_id.length > 0) {
                        //选中产品
                        console.log(productsarr[i].product_name_id);
                        console.log(productsarr[i].product_name_id[0]);
                        console.log(productsarr[i].product_name_id[1]);
                        for (let j = 0; j < arr.length; j++) {
                            console.log(arr[j]);
                            if (arr[j].id == e.business_line_product_name[i].product_name_id[0]) {
                                console.log(arr[j]);
                                console.log(arr[j].class_name);
                                for (let k = 0; k < arr[j].children.length; k++) {
                                    console.log(arr[j].children[k].product_name);
                                    arr[j].children[k].disabled = false;
                                    if (
                                        arr[j].children[k].id == productsarr[i].product_name_id[1]
                                    ) {
                                        console.log(arr[j].children[k].product_name, "222222222");
                                        arr[j].children[k].disabled = true;
                                    }
                                }
                            } else {
                                for (let k = 0; k < arr[j].children.length; k++) {
                                    console.log(arr[j].children[k].product_name);
                                    arr[j].children[k].disabled = false;
                                    if (
                                        arr[j].children[k].id == productsarr[i].product_name_id[1]
                                    ) {
                                        arr[j].children[k].disabled = true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            this.pc_name = e.business_line_product_name
          this.is_add = false;
          this.item = e;
          let str = [];
          for (let i in e.business_line_product) {
            // str+=e.business_line_product[i].product_id+','
            str.push(e.business_line_product[i].product_id);
          }
          if (str.length > 0) {
            // str=str.substr(0, str.length - 1)
          }
          this.prodect = str;
          this.business_name = e.name;
          this.achievement = e.achievement
          this.achievement_weight =e.achievement_weight
          this.visit =e.visit
          this.visit_weight =e.visit_weight
          this.deal_customer =e.deal_customer
          this.deal_customer_weight =e.deal_customer_weight
          this.a_intention_customer =e.a_intention_customer
          this.a_intention_customer_weight =e.a_intention_customer_weight
        } else {
          this.is_add = true;
          this.item = {};
          this.prodect = "";
          this.business_name = "";
           this.achievement = ""
          this.achievement_weight = ''
          this.visit = ''
          this.visit_weight = ''
          this.deal_customer = ''
          this.deal_customer_weight = ''
          this.a_intention_customer =''
          this.a_intention_customer_weight = ''
        }
        this.shadow_show = true;
    },
    getlist() {
      list({
        page: this.page,
        permissions_id_string: this.role_what.list_str,
      }).then((res) => {
        // console.log(res);
        this.tableData = res.data.data.data;
        this.total = res.data.data.total;
      });
    },
    sent_mission() {
      if (this.add_flag) {
        return;
      }
      // console.log(this.prodect);
      // return
      let str = "";
      // str = this.prodect.join(",");
      if (this.is_add) {
        //添加
        addBusinessLine({
          name: this.business_name,
          product_ids: this.product_ids.join(','),
          permissions_id_string: this.role_what.edit_str,
          achievement:this.achievement,
          achievement_weight:this.achievement_weight,
          visit:this.visit,
          visit_weight:this.visit_weight,
          deal_customer:this.deal_customer,
          deal_customer_weight:this.deal_customer_weight,
          a_intention_customer:this.a_intention_customer,
          a_intention_customer_weight:this.a_intention_customer_weight,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: res.data.message,
            });
            this.shadow_show = false;
            this.add_flag = false;
            this.getlist();
          } else {
            this.add_flag = false;
          }
        });
      } else {
        editBusinessLine({
          name: this.business_name,
          product_ids: this.product_ids.join(','),
          id: this.item.id,
          permissions_id_string: this.role_what.edit_str,
          achievement:Number(this.achievement),
          achievement_weight:this.achievement_weight,
          visit:this.visit,
          visit_weight:this.visit_weight,
          deal_customer:this.deal_customer,
          deal_customer_weight:this.deal_customer_weight,
          a_intention_customer:this.a_intention_customer,
          a_intention_customer_weight:this.a_intention_customer_weight,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: res.data.message,
            });
            this.shadow_show = false;
            this.add_flag = false;
            this.getlist();
          } else {
            this.add_flag = false;
          }
        });
      }
    },
    visiblechange(bool) {
          return;
          if (bool) {
              let {productsarr} = this;
              let arr = this.prooptions;

              for (let i = 0; i < productsarr.length; i++) {
                  if (productsarr[i].product_name_id.length > 0) {
                      //选中产品
                      console.log(productsarr[i].product_name_id);
                      console.log(productsarr[i].product_name_id[0]);
                      console.log(productsarr[i].product_name_id[1]);
                      for (let j = 0; j < arr.length; j++) {
                          console.log(arr[j]);
                          if (arr[j].id == productsarr[i].product_name_id[0]) {
                              console.log(arr[j]);
                              console.log(arr[j].class_name);
                              for (let k = 0; k < arr[j].children.length; k++) {
                                  console.log(arr[j].children[k].product_name);
                                  arr[j].children[k].disabled = false;
                                  if (
                                      arr[j].children[k].id == productsarr[i].product_name_id[1]
                                  ) {
                                      console.log(arr[j].children[k].product_name, "222222222");
                                      arr[j].children[k].disabled = true;
                                  }
                              }
                          } else {
                              for (let k = 0; k < arr[j].children.length; k++) {
                                  console.log(arr[j].children[k].product_name);
                                  arr[j].children[k].disabled = false;
                                  if (
                                      arr[j].children[k].id == productsarr[i].product_name_id[1]
                                  ) {
                                      arr[j].children[k].disabled = true;
                                  }
                              }
                          }
                      }
                  }
              }
              this.prooptions = [...arr];
          }
      },
    handleChanges(e) {
        var product_ids =e.map((item)=>{
            return item[1]
        });
        this.product_ids = product_ids
        // let arr = this.prooptions;
        // arr.forEach((item) => {
        //     if (item.id == e[0]) {
        //         item.children.forEach((itm) => {
        //             itm.disabled = false;
        //             if (itm.id == e[1]) {
        //                 itm.disabled = true;
        //             }
        //         });
        //     } else {
        //         item.children.forEach((itm) => {
        //             itm.disabled = false;
        //             if (itm.id == e[1]) {
        //                 itm.disabled = true;
        //             }
        //         });
        //     }
        // });
        // this.prooptions = [...arr];
    },
    handleDelpro(i) {
        //删除产品
        if (this.productsarr.length < 2) {
            return;
        }
        this.productsarr.splice(i, 1);
    },
  },
};
</script>

<style lang='scss'>
.business_box {
  padding: 20px;
  .el-main {
    background: #fff;
    .add_business {
      display: flex;
      justify-content: flex-end;
      div {
        width: 119px;
        border-radius: 5px;
        border: 1px solid #3a85fc;
        display: flex;
        height: 34px;
        color: #3a85fc;
        align-items: center;
        justify-content: center;
        margin-bottom: 4px;
      }
    }
    .acc_table {
      margin-top: 20px;
      .el-pagination {
        margin-top: 10px;
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
      }
    }
    .acc_shadow {
      position: fixed;
      z-index: 120;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      .info {
        width: 772px;
        height: 600px;
        margin: 100px auto;
        background: #ffffff;
        border: 1px solid #707070;
        opacity: 1;
        position: relative;
        border-radius: 5px;
        z-index: 999;
        // padding-top: 66px;
        box-sizing: border-box;
        .take_new_title {
          // top: -66px;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 66px;
          position: relative;
          border-bottom: 1px solid #ccc;
          text-align: center;
          line-height: 66px;
          font-size: 18px;
          i {
            position: absolute;
            top: 23px;
            right: 23px;
          }
        }
        .take_new_detail {
          height: 468px;
          width: 100%;
          box-sizing: border-box;
          overflow: auto;
          padding: 20px;
          .acc_top_compony {
            .kf_detail {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .quan {
                height: 5px;
                width: 5px;
                border-radius: 2.5px;
                background: #3a85fc;
                margin-right: 6px;
              }
            }

            .kh_detail {
              padding: 20px;
              width: 100%;
              box-sizing: border-box;
              height: 80px;
              margin-top: 10px;
              display: inline-block;
              background: #f8f8f8;
              div {
                overflow: hidden; /*超出部分隐藏*/
                white-space: nowrap; /*不换行*/
                text-overflow: ellipsis; /*超出部分文字以...显示*/
                width: 50%;
                display: inline-block;
                height: 50%;
              }
            }
          }
        }
        .flow_kind {
          width: 100%;
          display: flex;
          margin-top: 20px;
          white-space: nowrap; /*不换行*/
          align-items: center;
          .el-select {
            width: 250px;
          }
          .el-input__inner {
            width: 250px;
          }
          .el-date-editor .el-range-input {
            overflow: hidden; /*超出部分隐藏*/
            white-space: nowrap; /*不换行*/
            text-overflow: ellipsis; /*超出部分文字以...显示*/
          }
          .el-textarea__inner {
            width: 320px;
          }
          .el-date-editor.el-input {
            width: 250px;
          }
        }
        .take_bottom {
          height: 66px;
          position: relative;
          bottom: 0;
          width: 100%;
          display: flex;
          // align-items: center;
          // justify-content: center;
          box-sizing: border-box;
          justify-content: flex-end;
          padding-right: 40px;
          align-items: center;
          .bottom_sent {
            width: 80px;
            height: 40px;
            background: #3a85fc;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 1;
            color: #fff;
            border-radius: 4px;
          }
        }
        .flow_kind_text {
          width: 65%;
          display: flex;
          margin-top: 20px;
          // align-items: center;
          padding-top: 1px;
          // white-space: nowrap;
          .el-textarea {
            width: 400px;
            textarea {
              resize: none;
            }
          }

          .el-dialog__wrapper {
            z-index: 2010 !important;
          }
        }
      }
      .acc_commen {
        width: 520px;
        height: 740px;
        min-height: 311px;
        background: #ffffff;
        border: 1px solid #707070;
        opacity: 1;
        border-radius: 10px;
        margin: 100px auto;
        position: relative;
        .acc_commen_title {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #333;
          position: relative;
          height: 40px;
          border-bottom: 1px solid #ccc;
          i {
            position: absolute;
            // top: ;
            right: 10px;
          }
        }
        .acc_commen_body {
          width: 100%;
          resize: none;
          padding: 5% 10%;
          height: 80%;
          overflow: auto;
          .tt {
            margin-bottom: 15px;
          }
          .ano_box {
            width: 100%;
            height: 70px;
            margin-top: 20px;
            .num_info {
              margin-left: 37px;
              margin-top: 10px;
              display: flex;
              .num_info_one {
                width: 50%;
                white-space: nowrap;
                .el-input {
                  margin-left: 5px;
                  width: 132px;
                  height: 30px;
                  input {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
          .acc_title {
            height: 34px;
            align-items: center;
            display: flex;
            margin-left: -11px;
            font-weight: 600;
            .yuan {
              margin-right: 5px;
              width: 6px;
              height: 6px;
              background: #3a85fc;
              border-radius: 50%;
              opacity: 1;
            }
          }
          .acc_title:nth-of-type(2) {
            margin-top: 20px;
          }
          .ano {
            margin-bottom: 10px;
            display: flex;
            white-space: nowrap;
            align-items: center;
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
            }
            input[type="number"] {
              -moz-appearance: textfield;
            }
          }
    
          .el-textarea__inner {
            resize: none;
            height: 270px;
          }
        }
              .bottom {
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
            padding: 0 10%;
            .noshadow {
              width: 80px;
              height: 40px;
              background: #ffffff;
              color: #3a85fc;
              border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
              opacity: 1;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 20px;
            }
            .check_shadow {
              width: 80px;
              height: 40px;
              background: #3a85fc;
              opacity: 1;
              border-radius: 4px;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        .acc_commen_sent {
          position: absolute;
          right: 10px;
          padding: 0 10px;
          height: 25px;
          line-height: 25px;
          bottom: 10px;
          background: #3a85fc;
          color: #fff;
          border-radius: 5px;
        }
      }
    }
  }
}
</style>