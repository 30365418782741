<template>
  <!-- 晋升说明 -->
  <div style="flex: 1" class="Promotion">
    <el-main>
      <div class="Promotion_add" @click="showbox(null, 1)" v-if="role.is_edit">
        <i class="el-icon-plus"></i>
        添加晋升说明
      </div>
      <div class="Promotion_table">
        <el-table
          :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
          :data="tableData"
          style="width: 100%"
          border
          :default-sort="{ prop: 'date', order: 'descending' }"
        >
          <el-table-column label="排序" type="index" width="50">
          </el-table-column>
          <el-table-column label="职位名" width="180">
            <template slot-scope="scope">
              {{ scope.row.title }}
            </template>
          </el-table-column>
          <el-table-column label="说明">
            <template slot-scope="scope">
              {{ scope.row.explain }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <div class="scope_cz" v-if="role.is_edit">
                <div @click="showbox(scope.row, 2)">编辑</div>
                <div v-if="scope.row.sort != 1" @click="setBy(scope.row, -1)">
                  上移
                </div>
                <div
                  v-if="scope.row.sort != tableData.length"
                  @click="setBy(scope.row, 1)"
                >
                  下移
                </div>
                    <div
                  @click="del(scope.row)"
                >
                  删除
                </div>
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="add_Promotion_big_box" v-if="show_box">
        <div class="add_Promotion_box">
          <div class="add_Promotion_box_head">
            {{ is_add == 1 ? "添加" : "编辑" }}职位
            <i class="el-icon-close" @click="show_box = false"></i>
          </div>
          <div class="add_Promotion_box_body">
            <div class="add_Promotion_box_body_line">
              <div class="add_Promotion_box_body_line_tit">职位名：</div>
              <el-input v-model="name" placeholder="请输入职位名"></el-input>
            </div>
            <div
              class="add_Promotion_box_body_line"
              style="align-items: self-start"
            >
              <div class="add_Promotion_box_body_line_tit">职位说明：</div>
              <el-input
                v-model="textarea"
                type="textarea"
                :rows="5"
                placeholder="请对该职位进行说明..."
                maxlength="200"
              ></el-input>
            </div>
          </div>
          <div class="add_Promotion_box_bottom">
            <div @click="show_box = false">取消</div>
            <div @click="ok">确认</div>
          </div>
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
import {
  promoteExplainList,
  addPromoteExplain,
  promoteExplain,
  setOrderBy,
  delPromoteExplain
} from "../../api/User/promote";
export default {
  data() {
    return {
      tableData: [],
      name: "",
      textarea: "",
      is_add: "1",
      show_box: false,
      promote_explain_id: "", //id
      move_flag: false,
      role:{
        list_str:'',
        is_edit:false,
        edit_str:''
      }
    };
  },
  created() {
    let token = JSON.parse(localStorage.getItem("token"));
    token.role.forEach(item => {
      if (item.permissions=='Promotion_list') {
        this.role.list_str = item.permissions_id_string
      }
      if (item.permissions=='Promotion_edit') {
        this.role.is_edit = true  
        this.role.edit_str = item.permissions_id_string
      }
    });
    this.getlist();
  },
  methods: {
    showbox(row, i) {
      if (i == 1) {
        this.name = "";
        this.textarea = "";
        this.promote_explain_id = "";
      } else {
        this.name = row.title;
        this.textarea = row.explain;
        this.promote_explain_id = row.id;
      }
      this.is_add = i;
      this.show_box = true;
    },
    ok() {
      if (this.name == "" || this.textarea == "") {
        this.$message({
          message: '请完善数据',
          type: "error",
        });
        return;
      }
      if (this.move_flag) {
        return;
      }
      this.move_flag = true;
      if (this.is_add == 1) {
        addPromoteExplain({
          title: this.name,
          explain: this.textarea,
          permissions_id_string:this.role.edit_str
        }).then((res) => {
          this.move_flag = false;
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
              center: true,
            });
            this.show_box = false;
            this.getlist();
          }
        });
      } else {
        promoteExplain({
          title: this.name,
          explain: this.textarea,
          promote_explain_id: this.promote_explain_id,
          permissions_id_string:this.role.edit_str
        }).then((res) => {
          this.move_flag = false;
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
              center: true,
            });
            this.show_box = false;

            this.getlist();
          }
        });
      }
    },
    setBy(row, i) {
      if (this.move_flag) {
        return;
      }
      this.move_flag = true;
      setOrderBy({
        promote_explain_id: row.id,
        old_by: row.sort,
        new_by: row.sort * 1 + i * 1,
        permissions_id_string:this.role.edit_str
      }).then((res) => {
        this.move_flag = false;
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
            center: true,
          });
          this.getlist();
        }
      });
    },
    del(row){
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(()=>{
        delPromoteExplain({
          promote_explain_id:row.id,
          permissions_id_string:this.role.edit_str
        }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.message,
                type: "success",
                center: true,
              });
              this.getlist();
            }
          });
      }).cath(()=>{

      })
    },
    getlist() {
      promoteExplainList({
          permissions_id_string:this.role.list_str
      }).then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.data;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.Promotion {
  margin: 20px;
  .el-main {
    background: #fff;
    .Promotion_add {
      width: 120px;
      height: 35px;
      background: #3a85fc;
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: #ffffff;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      i {
        margin-right: 5px;
      }
    }
    .Promotion_table {
      margin-top: 30px;
      .scope_cz {
        display: flex;
        div {
          padding: 0 5px;
          cursor: pointer;
        }
        div:hover {
          color: #3a85fc;
        }
      }
    }
    .add_Promotion_big_box {
      position: fixed;
      z-index: 100;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      .add_Promotion_box {
        z-index: 99;
        width: 535px;
        height: 376px;
        background: #ffff;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-radius: 10px;
        overflow: hidden;
        .add_Promotion_box_head {
          width: 100%;
          height: 56px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 21px;
          color: rgba(0, 0, 0, 0.8);
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            position: absolute;
            right: 25px;
            padding: 5px;
            cursor: pointer;
          }
        }
        .add_Promotion_box_body {
          padding: 25px 40px;
          .add_Promotion_box_body_line {
            display: flex;
            align-items: center;
            margin-bottom: 25px;
            .add_Promotion_box_body_line_tit {
              width: 70px;
              text-align: right;
              white-space: nowrap;
            }
            .el-input {
              flex: 1;
              height: 30px;
              input {
                height: 30px;
              }
            }
            .el-textarea {
              flex: 1;
              .el-textarea__inner {
                //el_input中的隐藏属性
                resize: none; //主要是这个样式
              }
            }
          }
        }
        .add_Promotion_box_bottom {
          height: 80px;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          padding: 0 40px;
          div {
            width: 80px;
            height: 40px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            cursor: pointer;
          }
          div:first-child {
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.30196078431372547);

            color: rgba(0, 0, 0, 0.5);
          }
          div:last-child {
            background: #3a85fc;
            color: #fff;
            margin-left: 25px;
          }
        }
      }
    }
  }
}
</style>