<template>
  <div class="all_can_see">
    <div class="all_can_see_head">
      <div class="all_can_see_head_fs" v-if="is_sales == 1">
        <div class="all_can_see_head_fs_box">
          <div>我的分数</div>
          <div>{{ my_Score }}</div>
        </div>
        <div class="all_can_see_head_fs_box">
          <div>本月累计业绩</div>
          <div>{{ self_achievement.the_month_achievement }}</div>
        </div>
        <div class="all_can_see_head_fs_box">
          <div>全年累计业绩</div>
          <div>{{ self_achievement.the_year_achievement }}</div>
        </div>
      </div>
      <div class="all_can_see_head_ry" :style="is_sales == 2 ? 'margin-left:50px;' : ''">
        <div>我的荣誉</div>
        <div class="honer_img_box" v-if="my_Honor != 0" style="cursor: pointer" @click="openHonor">
          <img src="../../assets/img/trophy.png" alt="" class="honer_img" v-for="(item, index) in my_Honor" :key="index" />
        </div>
        <div style="color: #333; line-height: 50px" v-else>暂无荣誉</div>
      </div>
    </div>
    <div class="all_can_see_line_one">
      <AllRecord />
      <Today />
    </div>
    <div class="all_can_see_line_two">
      <div class="all_can_see_line_one_two_box">
        <Allmyachi />
      </div>
      <div class="all_can_see_line_one_two_box">
        <Team />
      </div>
      <div class="all_can_see_line_one_two_box">
        <Allagency />
      </div>
    </div>
    <Alltrend />

    <el-dialog title="荣誉" :visible.sync="isShowHonor" width="50%" append-to-body>
      <div class="honorDialog" v-loading="isLoading">
        <div class="top">
          <div :class="honorType == 1 ? 'active' : ''" @click="changeHonor(1)">个人荣誉</div>
          <div :class="honorType == 2 ? 'active' : ''" @click="changeHonor(2)">部门荣誉</div>
        </div>
        <div class="cont">
          <div v-for="(item, index) in honorList" :key="index" class="ite" @click="openPreview(item.honor_img)">
            <el-image class="img" :src="item.honor_img" :fit="'fill'" />
            <div>{{ item.title }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="isShowImage" width="90%" append-to-body>
      <img style="width:100%" :src="imgUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import all_myachi from "./all_myachi";
import today_arrive from "./today_arrive";
import all_Record from "./all_Record";
import all_agency from "./all_agency";
import all_trend from "./all_trend";
import team from "./my_team";
import { myHonor, myScore, achievementReport, centerMyHonor } from "../../api/User/home";

export default {
  components: {
    Today: today_arrive,
    AllRecord: all_Record,
    Allagency: all_agency,
    Alltrend: all_trend,
    Team: team,
    Allmyachi: all_myachi,
  },
  data() {
    return {
      my_Score: "",
      my_Honor: 0,
      is_sales: 1,
      self_achievement: {},
      isShowHonor: false,
      honorType: 1,
      honorList: [],
      isLoading: false,
      isShowImage: false,
      imgUrl: "",
    };
  },
  created() {
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      this.is_sales = user.is_sales;
    }
    myScore().then((res) => {
      if (res.data.code == 200) {
        this.my_Score = res.data.data;
      }
    });
    myHonor().then((res) => {
      if (res.data.code == 200) {
        this.my_Honor = res.data.data;
      }
    });
    achievementReport().then((res) => {
      if (res.data.code == 200) {
        this.self_achievement = res.data.data.self_achievement;
      }
    });
  },
  methods: {
    openPreview(url) {
      this.imgUrl = url;
      this.isShowImage = true;
    },
    changeHonor(v) {
      this.honorType = v;
      this.isLoading = true;
      centerMyHonor({
        type: this.honorType,
      }).then((res) => {
        this.isLoading = false;
        this.honorList = res.data.data.data;
      });
    },
    openHonor() {
      this.isShowHonor = true;
      this.changeHonor(1);
    },
  },
};
</script>

<style lang="scss">
div {
  box-sizing: border-box;
}
.honorDialog {
  .top {
    display: flex;
    justify-content: space-around;
    div {
      cursor: pointer;
    }
    .active {
      color: #169bd5;
    }
  }
  .cont {
    display: flex;
    flex-wrap: wrap;
    .ite {
      margin-left: 10px;
      margin-top: 20px;
    }
    .img {
      width: 240px;
      height: 300px;
      cursor: pointer;
    }
    div {
      text-align: center;
      margin-top: 10px;
    }
  }
}
.all_can_see {
  padding: 15px 25px;
  background: url(https://images.sstdcs.cn/bg.jpg);
  // background: #eef5f9;
  .all_can_see_head {
    position: relative;
    width: 100%;
    height: 120px;
    border: 1px solid #e2edff;
    display: flex;
    border-radius: 4px;
    background: url("../../assets/img/all_can_see_head_bg.png") no-repeat;
    .all_can_see_head_fs {
      width: 60%;
      z-index: 19;
      /*padding-left: 110px;*/
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-top: 12px;
      .all_can_see_head_fs_box {
        /*width: 70px;*/
        div:first-child {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 27px;
          color: rgba(0, 0, 0, 0.8);
          opacity: 1;
          text-align: center;
        }
        div:last-child {
          font-size: 46px;
          font-family: Source Han Sans CN;
          font-weight: 800;
          line-height: 78px;
          color: rgba(0, 0, 0, 0.8);
          opacity: 1;
          text-align: center;
        }
      }
    }
    .all_can_see_head_ry {
      width: 40%;
      padding-top: 12px;
      div:first-child {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 27px;
        color: rgba(0, 0, 0, 0.8);
        opacity: 1;
      }
      .honer_img_box {
        display: flex;
        height: 78px;
        padding-right: 10px;
        align-items: center;
        img {
          width: 32px;
          height: 37px;
          margin-left: 12px;
        }
      }
    }
  }
  .all_can_see_line_one {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
  }
  .all_can_see_line_two {
    width: 100%;
    display: flex;
    margin: 20px 0px;
    .all_can_see_line_one_two_box {
      flex: 1;
      height: 407px;
      background: #ffffff;
      opacity: 1;
      border-radius: 6px;
    }
    .all_can_see_line_one_two_box:nth-child(2) {
      margin: 0 16px;
    }
  }
}
</style>
